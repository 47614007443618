import styled from 'styled-components';

/**
 * center text in this component
 *
 * @name Right
 * @example
 * ```jsx
 * 	<Right>
 * 		Right aligned text!
 * 	</Right>
 * ```
 */
const Right = styled.div`
	text-align: right;
`;

export default Right;
