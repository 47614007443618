import React, { ReactElement } from 'react';
import styled from 'styled-components';

const ListElementWrapper = styled.li`
	vertical-align: top;
	display: flex;
	min-height: 28px;
`;

const COLUMN_ONE_WIDTH = '180px';

const Label = styled.span`
	font-weight: bold;
	font-size: 0.875rem;
	line-height: 200%;
	color: var(--nte-graa-mork);
	width: ${COLUMN_ONE_WIDTH};
	display: inline-block;
	box-sizing: border-box;
`;
const Value = styled.span`
	font-size: 0.875rem;
	line-height: 200%;
	color: var(--nte-graa-mork);
	display: inline-block;
	position: relative;
	width: calc(100% - ${COLUMN_ONE_WIDTH} - 20px);
	box-sizing: border-box;
`;

interface DetailsListElementProps {
	label: string,
	value: string | ReactElement
}
export function DetailsListElement({ label = '', value = '' }: DetailsListElementProps) {
	return (
		<ListElementWrapper>
			<Label>{label}</Label>
			<span> </span>
			<Value>{value}</Value>
		</ListElementWrapper>
	);
}
