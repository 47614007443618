import React, { ReactNode, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import useOnClickOutside from 'web/hooks/useOnClickOutside';

interface StyledButtonProps {
	isBlank?: boolean;
	active?: boolean;
}
const StyledButton = styled.button<StyledButtonProps>`
	display: flex;
	align-items: center;
	color: var(--nte-graa-mork);
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0;
	padding: 5px 25px;
	font-size: 0.875rem;
	line-height: 1.5;
	background: var(--white);
	border: var(--nte-border);
	box-shadow: var(--clickable-box-shadow);
	border-radius: var(--border-radius-small);

	& i {
		margin-left: 6px;
		color: var(--nte-blaa);
	}

	${props =>
		props.isBlank &&
		css`
			border: 1px solid transparent;
			background: none;
			color: var(--nte-blaa-uu);
			box-shadow: none;
			font-weight: 500;
		`}

	${props =>
		props.active &&
		css`
			border: 1px solid var(--nte-blaa);
		`}

	:hover {
		box-shadow: none;
	}
`;

const DropdownWrapper = styled.div`
	background: var(--white);
	border-radius: var(--border-radius);
	box-shadow: var(--clickable-box-shadow);
	min-height: 25px;
	min-width: 100%;
	max-height: 500px;
	overflow: hidden;
	overflow-y: auto;
	position: absolute;
	z-index: 9999;
	top: 100%;
	transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
`;

export enum DropdownAlign {
	CENTER = 'CENTER',
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
}

interface WrapperProps {
	isOpen: boolean;
	align: DropdownAlign;
	pushDropdownX?: number | string;
}

const Wrapper = styled.div<WrapperProps>`
	position: relative;

	${DropdownWrapper} {
		opacity: 0;
		pointer-events: none;
	}

	${props =>
		props.isOpen &&
		css`
			${DropdownWrapper} {
				opacity: 1;
				pointer-events: inherit;
			}
		`}

	${props =>
		props.align === DropdownAlign.CENTER &&
		css`
			${DropdownWrapper} {
				left: 51%;
				transform: translate(-51%, -20px);
			}
		`}
	${props =>
		props.align === DropdownAlign.CENTER &&
		props.isOpen &&
		css`
			${DropdownWrapper} {
				transform: translate(-51%, 0px);
			}
		`}

	${props =>
		props.align === DropdownAlign.LEFT &&
		css`
			${DropdownWrapper} {
				left: 0px;
				transform: translate(${props.pushDropdownX || 0}, -20px);
			}
		`}
	${props =>
		props.align === DropdownAlign.LEFT &&
		props.isOpen &&
		css`
			${DropdownWrapper} {
				transform: translate(${props.pushDropdownX || 0}, 0px);
			}
		`}

	${props =>
		props.align === DropdownAlign.RIGHT &&
		css`
			${DropdownWrapper} {
				right: 0px;
				transform: translate(${props.pushDropdownX || 0}, -20px);
			}
		`}
	${props =>
		props.align === DropdownAlign.RIGHT &&
		props.isOpen &&
		css`
			${DropdownWrapper} {
				transform: translate(${props.pushDropdownX || 0}, 0px);
			}
		`}
`;

interface DropdownIconProps {
	isOpen: boolean;
}

function DropdownIcon(props: DropdownIconProps) {
	if (props.isOpen) {
		return <i className="fa fa-chevron-up" />;
	}

	return <i className="fa fa-chevron-down" />;
}

const StyledItemCount = styled.span`
	margin: 0 4px;
	font-size: 0.75rem;
	line-height: 16px;
	width: 16px;
	height: 16px;
	text-align: center;
	background: var(--nte-blaa-uu);
	border-radius: 50%;
	color: var(--white);
`;

interface DropdownButtonProps {
	label: any;
	isBlank?: boolean;
	active?: boolean;
	selectedCount?: number;
	iconOverride?: any;
	align?: DropdownAlign;
	children?: ReactNode;
	rest?: any;
	style?: React.CSSProperties;
	pushDropdownX?: number | string;
	dropdownWrapperStyle?: React.CSSProperties;
}

export default function DropdownButton(props: DropdownButtonProps) {
	const dropdownElementRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const { style, ...rest } = props;

	useOnClickOutside({
		ref: dropdownElementRef,
		handler: () => {
			if (!isOpen) {
				return;
			}
			setTimeout(() => {
				setIsOpen(false);
			}, 200);
		},
	});

	return (
		<Wrapper isOpen={isOpen} align={props.align || DropdownAlign.CENTER} pushDropdownX={props.pushDropdownX}>
			<StyledButton isBlank={props.isBlank} active={props.active} onClick={() => setIsOpen(!isOpen)} style={style} {...rest}>
				{props.label}
				{!!props.selectedCount && <StyledItemCount>{props.selectedCount}</StyledItemCount>}
				{!props.iconOverride && <DropdownIcon isOpen={isOpen} />}
				{props.iconOverride || null}
			</StyledButton>
			<DropdownWrapper tabIndex={0} ref={dropdownElementRef} style={props.dropdownWrapperStyle}>
				{props.children}
			</DropdownWrapper>
		</Wrapper>
	);
}
