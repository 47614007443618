import { ApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import React from 'react';
import apolloClient from 'web/lib/apolloClient';
import { Melding } from 'web/models/Melding';
import { DeleteDistributedDocumentDocument, GetDistributedDocumentsDocument } from 'web/pages/admin/MeldingerPage.graphql-gen';
import { MeldingerTemplate } from 'web/templates/admin/MeldingerTemplate';

export function removeMeldingFromCache(meldingIdToRemove: string, client: ApolloClient<any>) {
	const data = client.readQuery({
		query: GetDistributedDocumentsDocument,
	});

	const eksisterendeMeldinger = data.getDistributedDocuments.filter((melding: Melding) => melding.id != meldingIdToRemove);

	client.writeQuery({
		query: GetDistributedDocumentsDocument,
		data: {
			getDistributedDocuments: [...eksisterendeMeldinger],
		},
	});
}

export default function MeldingerPage() {
	const client = apolloClient;
	const { data, error, loading } = useQuery(GetDistributedDocumentsDocument, { client: apolloClient });
	const [deleteDistributedMelding, { error: deleteDistributedMeldingError, loading: deleteDistributedMeldingLoading }] = useMutation(
		DeleteDistributedDocumentDocument,
		{ client: apolloClient }
	);
	const meldinger = data?.getDistributedDocuments || [];

	const slettMelding = async (meldingId: string) => {
		return deleteDistributedMelding({
			variables: {
				documentId: meldingId,
			},
		}).then(() => removeMeldingFromCache(meldingId, client));
	};
	return <MeldingerTemplate meldinger={meldinger} slettMelding={slettMelding} error={error} loading={loading} />;
}
