import React, { useEffect } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import useAuthHelpers from 'web/hooks/useAuthHelpers';

export default function LoginFrameContent() {
	const auth = useAuth();
	const { login, silentLogin, setDoIdentifyUserInSegment } = useAuthHelpers();

	useEffect(() => {
		if (auth.isAuthenticated) {
			setDoIdentifyUserInSegment(true);
			window.parent.location.reload();
			return;
		}

		if (auth.isLoading || auth.error) {
			return;
		}

		if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator) {
			silentLogin('login frame', () => login());
		} else if (!auth.isAuthenticated) {
			login();
		}
	}, [auth.isAuthenticated, auth.isLoading, auth.activeNavigator, auth.signinRedirect]);

	return <LoadingOverlay loading={true} text={auth.isAuthenticated ? 'Logger inn' : 'Laster'} />;
}
