import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import Panel, { PanelProps } from 'web/components/atoms/Panel';
import { device } from 'web/GlobalStyling';

const Wrapper = styled(Panel)`
	display: grid;
	grid-template-columns: 35% 1fr;
	grid-template-rows: 200px 1fr;
	border-radius: var(--border-radius-large);
	box-shadow: var(--clickable-box-shadow-app);
	overflow: hidden;
	padding: 0;
	border: none;
	span {
		grid-column: 3;
		grid-row: 1;
		align-self: center;
		margin-right: 2rem;
	}
	@media ${device.mobile} {
		grid-template-columns: 150px 1fr;
		height: max-content;
		grid-auto-flow: row;
	}
`;

interface ImageWrapperProps {
	src: string;
}
const ImageWrapper = styled.div<ImageWrapperProps>`
	background: url(${props => props.src}) no-repeat center center;
	background-size: cover;
	width: 100%;
	height: 100%;
	grid-column: 1;
	grid-row: 1/-1;
	border: none;
	@media ${device.mobile} {
		grid-column: 1/-1;
		grid-row: 1;
	}
`;
const ContentWrapper = styled.div`
	grid-column: 2;
	grid-row: 1/-1;
	padding: 2rem;

	@media ${device.mobile} {
		padding: 1rem;
		grid-column: 1/-1;
		grid-row: 2;
	}
`;
const Title = styled.h2`
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 1.2;
	margin: 0;
	color: var(--nte-blaa-uu);
	margin-bottom: 17px;
	@media ${device.mobile} {
		font-size: 1.125rem;
	}
`;
const Description = styled.div`
	font-weight: normal;
	font-size: 0.9375rem;
	line-height: 1.4;
	color: var(--nte-graa-mork);
`;
export interface ArticlePanelProps extends PanelProps {
	bildeUrl?: string;
	tittel?: string;
	beskrivelse?: string;
}
export default function ArticlePanel(props: ArticlePanelProps) {
	return (
		<Wrapper href={props.href} openNewTab onClick={props.onClick}>
			<ImageWrapper src={props.bildeUrl} />
			<ContentWrapper>
				<Title>{props.tittel}</Title>
				<Description>
					{/* Using truncate since ellipsis does not work good on multliline textblocks */}
					{_.truncate(props.beskrivelse, {
						length: 250,
						separator: /,? +/,
					})}
				</Description>
			</ContentWrapper>
		</Wrapper>
	);
}
