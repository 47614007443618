import React from 'react';
import { useParams } from 'react-router-dom';
import useFjutt from 'web/hooks/SingleAnlegg/useFjutt';
import FjuttTabTemplate from 'web/templates/singleAnlegg/FjuttTabTemplate';

interface FjuttTabProps {
	anleggId: string;
	maalernummer: string;
	doUseOmregningsFaktor: boolean;
	isTabActive?: boolean;
}
export default function FjuttTab(props: FjuttTabProps) {
	const { bedriftId } = useParams<any>();
	const fjuttBindings = useFjutt({
		maalerpunktId: props.anleggId,
		doUseOmregningsFaktor: props.doUseOmregningsFaktor,
		shouldSkip: !props.isTabActive,
		everyHalfHour: true,
	});

	return <FjuttTabTemplate {...fjuttBindings} isTabActive={props.isTabActive} maalernummer={props.maalernummer} bedriftId={bedriftId} />;
}
