// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentKostnadQueryVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
  fraDato?: Types.InputMaybe<Types.Scalars['Date']>;
  tilDato?: Types.InputMaybe<Types.Scalars['Date']>;
  maalere?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  opploesning?: Types.InputMaybe<Types.Opploesning>;
  fakturamerker?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  naeringskoder?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type HentKostnadQuery = { bedrift?: { id?: string, kostnad?: { opploesning?: Types.Opploesning, verdier?: Array<{ fraTid?: string, tilTid?: string, kostnad?: number }> } } };


export const HentKostnadDocument = gql`
    query hentKostnad($orgid: ID!, $fraDato: Date, $tilDato: Date, $maalere: [String], $opploesning: Opploesning, $fakturamerker: [String], $naeringskoder: [String]) {
  bedrift: hentBedrift(orgid: $orgid) {
    id
    kostnad: forbruk(
      fraDato: $fraDato
      tilDato: $tilDato
      maalere: $maalere
      opploesning: $opploesning
      fakturamerker: $fakturamerker
      naeringskoder: $naeringskoder
    ) {
      opploesning
      verdier {
        fraTid
        tilTid
        kostnad
      }
    }
  }
}
    `;

/**
 * __useHentKostnadQuery__
 *
 * To run a query within a React component, call `useHentKostnadQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentKostnadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentKostnadQuery({
 *   variables: {
 *      orgid: // value for 'orgid'
 *      fraDato: // value for 'fraDato'
 *      tilDato: // value for 'tilDato'
 *      maalere: // value for 'maalere'
 *      opploesning: // value for 'opploesning'
 *      fakturamerker: // value for 'fakturamerker'
 *      naeringskoder: // value for 'naeringskoder'
 *   },
 * });
 */
export function useHentKostnadQuery(baseOptions: Apollo.QueryHookOptions<HentKostnadQuery, HentKostnadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentKostnadQuery, HentKostnadQueryVariables>(HentKostnadDocument, options);
      }
export function useHentKostnadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentKostnadQuery, HentKostnadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentKostnadQuery, HentKostnadQueryVariables>(HentKostnadDocument, options);
        }
export type HentKostnadQueryHookResult = ReturnType<typeof useHentKostnadQuery>;
export type HentKostnadLazyQueryHookResult = ReturnType<typeof useHentKostnadLazyQuery>;
export type HentKostnadQueryResult = Apollo.QueryResult<HentKostnadQuery, HentKostnadQueryVariables>;