import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from 'web/components/atoms/Button';
import Center from 'web/components/atoms/Center';
import PageTitle from 'web/components/atoms/PageTitle';
import Paragraph from 'web/components/atoms/Paragraph';
import Push from 'web/components/atoms/Push';
import DataError, { Wrapper as DataErrorWrapper } from 'web/components/molecules/form/DataError';
import TextField from 'web/components/molecules/form/TextField';
import InfoBox from 'web/components/molecules/InfoBox';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import { useAuthorization } from 'web/hooks/useAuthorization';
import Form from 'web/hooks/useForm';
import apolloClient from 'web/lib/apolloClient';
import { isEmailValid } from 'web/lib/validate';
import { EtablerBedriftDocument, useKanEtableresLazyQuery } from 'web/pages/generic/BeOmTilgangPage.graphql-gen';

const InfoFrame = styled.div`
	border: var(--nte-border);
	border-radius: var(--border-radius-smallest);
	margin-bottom: 1rem;
	padding: 1.25rem 1.25rem 0.3125rem;
	box-sizing: border-box;
	text-align: left;
`;

const InfoFrameLineWrapper = styled.div`
	width: 50%;
	display: inline-block;
	box-sizing: border-box;
	margin-bottom: 15px;
`;

const InfoFrameLabel = styled.span`
	clear: both;
	font-size: 0.875rem;
	margin-bottom: 0.375rem;
	color: var(--nte-sort);
	opacity: 0.7;
`;

const InfoFrameValue = styled.span`
	clear: both;
	font-size: 1.0625rem;
	margin-bottom: 0.375rem;
	color: var(--nte-sort);
`;

const ButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

interface InfoFrameLineProps {
	label: string;
	value: string;
}
function InfoFrameLine({ label, value }: InfoFrameLineProps) {
	return (
		<InfoFrameLineWrapper>
			<InfoFrameLabel>{label}</InfoFrameLabel>
			<br />
			<InfoFrameValue>{value}</InfoFrameValue>
		</InfoFrameLineWrapper>
	);
}

const FORM_STEP = {
	ORGNR_KUNDENUMMER: 0,
	EPOST: 1,
	BEDRIFT_OPPRETTET: 2,
	KUNDESENTER_BISTAAR: 3,
	MANUAL_REGISTRATION: 4,
};

export default function BeOmTilgangPage() {
	const { user } = useAuthorization();
	const { t } = useTranslation('generic');
	const [formStep, setFormStep] = useState(FORM_STEP.ORGNR_KUNDENUMMER);
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const defaultFormError = { orgnummer: '', kundenummer: '', epost: '', bedrift: '', telefon: '' };
	const [formError, setFormError] = useState(defaultFormError);
	const [isLoading, setIsLoading] = useState(false);
	const [organizationAlreadyExists, setOrganizationAlreadyExists] = useState(false);
	const [notifyManualRegistrartion, setNotifyManualRegistrartion] = useState(false);

	const [kanEtableres] = useKanEtableresLazyQuery();

	const orgnummerKundenrForm = useRef(null);

	return (
		<div>
			{formStep !== FORM_STEP.KUNDESENTER_BISTAAR && formStep !== FORM_STEP.BEDRIFT_OPPRETTET && <PageTitle isCenter>Registrering</PageTitle>}
			{(formStep === FORM_STEP.KUNDESENTER_BISTAAR || formStep === FORM_STEP.BEDRIFT_OPPRETTET) && <PageTitle isCenter>Registrering fullført</PageTitle>}
			<Push />

			<LoadingOverlay loading={isLoading} />
			{!organizationAlreadyExists && <DataError error={error} />}

			{organizationAlreadyExists && (
				<DataErrorWrapper>
					{data?.bedrift?.navn} er allerede etablert i Bedriftsportalen.
					<br />
					Ta kontakt med din superbruker for å få tilgang.
					<br />
					Trykk <a href="/">her</a> for å fortsette til Bedriftsportalen dersom du allerede har tilgang.
				</DataErrorWrapper>
			)}

			{notifyManualRegistrartion && (
				<DataErrorWrapper>
					Vi fant ikke noe kundeforhold på det organisasjonsnummeret og kundenummeret, dobbeltsjekk at du har tastet inn riktig eller trykk{' '}
					<a
						onClick={e => {
							e.preventDefault();

							setFormStep(FORM_STEP.MANUAL_REGISTRATION);
							setNotifyManualRegistrartion(false);
						}}>
						her
					</a>{' '}
					for å gi oss litt mer informasjon, så får vi en av våre dyktige kundebehandlere til å hjelpe deg.
				</DataErrorWrapper>
			)}

			{formStep === FORM_STEP.ORGNR_KUNDENUMMER && (
				<>
					<Paragraph>Vi trenger bedriftens organisasjonsnummer og kundenummer i NTE. Kundenummeret vil du finne på en fakturakopi.</Paragraph>
					<Push />
					<Form
						formRef={orgnummerKundenrForm}
						onSubmit={async ({ orgnummer, kundenummer }: { orgnummer: string; kundenummer: string }) => {
							let newFormError = defaultFormError;
							let hasFormError = false;

							if (orgnummer?.length !== 9) {
								newFormError.orgnummer = t('BeOmTilgang.error.orgnummer');
								hasFormError = true;
							}

							if (kundenummer?.length === 0) {
								newFormError.orgnummer = t('BeOmTilgang.error.kundenummer');
								hasFormError = true;
							}

							if (hasFormError) {
								setFormError(newFormError);
								return;
							}

							setFormError(defaultFormError);

							setIsLoading(true);

							setData({ orgnummer, kundenummer: kundenummer || null });
							setOrganizationAlreadyExists(false);
							setNotifyManualRegistrartion(false);

							try {
								const { data } = await kanEtableres({
									fetchPolicy: 'network-only',
									variables: {
										orgnummer,
										kundenummer: parseInt(kundenummer, 10),
									},
								});

								const kunde = data.kunde;

								if (kunde) {
									setData(kunde);

									if (kunde?.bedrift?.orgnummer) {
										setOrganizationAlreadyExists(true);
									} else {
										setFormStep(FORM_STEP.EPOST);
									}
								} else {
									setData({ orgnummer, kundenummer: null });

									setNotifyManualRegistrartion(true);
								}
								setError(null);
							} catch (error) {
								console.error(error);
								setError(error);
							}

							setIsLoading(false);
						}}>
						<TextField
							label="Organisasjonsnummer"
							name="orgnummer"
							error={formError.orgnummer}
							onChange={(e: any) => {
								e.target.value = e.target.value.replace(/[^0-9\.]+/g, '');
							}}
							required
						/>
						<TextField
							label="Kundenummer"
							hintText="(finnes på faktura fra NTE)"
							name="kundenummer"
							error={formError.kundenummer}
							onChange={(e: any) => {
								e.target.value = e.target.value.replace(/[^0-9\.]+/g, '');
							}}
							required
						/>
						<div
							style={{
								marginTop: '-15px',
								marginBottom: '25px',
								textAlign: 'left',
							}}>
							<a
								onClick={e => {
									e.preventDefault();
									setData({ ...data, orgnummer: orgnummerKundenrForm.current?.orgnummer?.value ?? '' });
									setFormStep(FORM_STEP.MANUAL_REGISTRATION);
								}}>
								Finner ikke kundenummer
							</a>
						</div>
						<Button>Neste</Button>
					</Form>
				</>
			)}

			{formStep === FORM_STEP.EPOST && (
				<>
					<Paragraph>Vi trenger eposten din for å fullføre registreringen</Paragraph>
					<Push />
					<Form
						values={data}
						onSubmit={async ({ epost }: { epost: string }) => {
							if (!isEmailValid(epost)) {
								setError('E-post er ikke gyldig');

								return;
							}

							setError(null);

							setData({ ...data, epost });

							setIsLoading(true);

							try {
								const response = await apolloClient.mutate({
									mutation: EtablerBedriftDocument,
									variables: {
										body: {
											bedrift: data.navn,
											kundenummer: data.kundenummer,
											orgnummer: data.orgnummer,
											epost,
										},
									},
								});

								const bedriftOpprettet = response?.data?.etablerBedrift ?? false;

								if (bedriftOpprettet) {
									setFormStep(FORM_STEP.BEDRIFT_OPPRETTET);
								} else {
									setFormStep(FORM_STEP.KUNDESENTER_BISTAAR);
								}

								setError(null);
							} catch (error) {
								console.error(error);

								setError(error);
							}

							setIsLoading(false);
						}}>
						{data?.navn && (
							<InfoFrame>
								<InfoFrameLine label="Navn" value={data?.navn} />
								<InfoFrameLine label="Organisasjonsnummer" value={data?.orgnummer} />
								<InfoFrameLine label="Kontaktperson" value={user?.name} />
								<InfoFrameLine label="Kundenummer" value={data?.kundenummer} />
							</InfoFrame>
						)}

						<TextField
							required
							label="E-post"
							name="epost"
							onChange={(e: any) => {
								e.preventDefault();
								setError(null);
							}}
						/>

						<InfoBox>Når du fullfører registreringen sender vi deg en kvittering på denne epostadressen.</InfoBox>

						<Push />

						<Button>Fullfør registrering</Button>
					</Form>
				</>
			)}

			{formStep === FORM_STEP.MANUAL_REGISTRATION && (
				<>
					<Paragraph>Vi trenger eposten din for å fullføre registreringen</Paragraph>
					<Push />
					<Form
						values={data}
						onSubmit={async (formData: any) => {
							let newFormError = defaultFormError;
							let hasFormError = false;

							if (formData.orgnummer?.length !== 9) {
								newFormError.orgnummer = t('BeOmTilgang.error.orgnummer');
								hasFormError = true;
							}

							if (!isEmailValid(formData.epost)) {
								newFormError.epost = 'E-post er ikke gyldig';
								hasFormError = true;
							}

							if (hasFormError) {
								setFormError(newFormError);
								return;
							}

							setFormError(defaultFormError);
							setError(null);
							setData(formData);
							setIsLoading(true);

							try {
								const response = await apolloClient.mutate({
									mutation: EtablerBedriftDocument,
									variables: {
										body: {
											...formData,
										},
									},
								});

								const bedriftOpprettet = response?.data?.postEtablering?.bedriftOpprettet ?? false;

								if (bedriftOpprettet) {
									setFormStep(FORM_STEP.BEDRIFT_OPPRETTET);
								} else {
									setFormStep(FORM_STEP.KUNDESENTER_BISTAAR);
								}
								setError(null);
							} catch (error) {
								console.error(error);

								setError(error);
							}

							setIsLoading(false);
						}}>
						<Paragraph>Gi oss litt mer informasjon, så får vi en av våre dyktige kundebehandlere til å hjelpe deg.</Paragraph>
						<Push />
						<TextField
							required
							label="Organisasjonsnummer"
							name="orgnummer"
							error={formError.orgnummer}
							onChange={(e: any) => {
								e.target.value = e.target.value.replace(/[^0-9\.]+/g, '');
							}}
						/>
						<TextField required label="Bedriftnavn" name="bedrift" error={formError.bedrift} />
						<TextField required label="Telefon" name="telefon" error={formError.telefon} />
						<TextField
							required
							label="E-post"
							name="epost"
							error={formError.epost}
							onChange={(e: any) => {
								e.preventDefault();
								setError(null);
							}}
						/>
						<Button>Fullfør registrering</Button>
					</Form>
				</>
			)}

			{formStep === FORM_STEP.BEDRIFT_OPPRETTET && (
				<Center>
					<Paragraph>Du har nå tilgang til Bedriftsportalen</Paragraph>
					<Form
						onSubmit={() => {
							const getUrl = window.location;
							const baseUrl = `${getUrl.protocol}//${getUrl.host}/`;

							window.location.href = baseUrl;
						}}>
						<Push />
						<ButtonWrapper>
							<Button>Gå videre til Bedriftsportalen</Button>
						</ButtonWrapper>
					</Form>
				</Center>
			)}

			{formStep === FORM_STEP.KUNDESENTER_BISTAAR && (
				<div>
					<h3>En kundebehandler vil kontakte deg så fort som mulig.</h3>
					<Paragraph>Du kan trygt lukke fanen</Paragraph>
				</div>
			)}
		</div>
	);
}
