/* Based on new design system a 01.06.2021 */

import React from 'react';
import styled from 'styled-components';

//#region styling
const Radiobox = styled.div`
	background: var(--white);
	border: var(--nte-border);
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	width: 20px;
	height: 20px;
	text-align: center;
`;
const HiddenInput = styled.input`
	opacity: 0;
	position: absolute;
	left: -9999px;
	&:checked + ${Radiobox} {
		border: 7px solid var(--nte-blaa);
	}
`;
const Label = styled.label`
	display: flex;
	padding: 8px;
	cursor: pointer;
`;
const LabelText = styled.span`
	margin-left: 12px;
	display: flex;
`;
//#endregion

interface RadioBoxProps {
	name: string;
	label?: string;
	value: string;
	placeholder?: string;
	onChange: (e: any) => void;
	defaultChecked?: boolean;
	checked?: boolean;
	props?: any;
}

export default function RadioBox({ name, label, value, placeholder, onChange, defaultChecked, checked, ...props }: RadioBoxProps) {
	return (
		<>
			<Label {...props}>
				<HiddenInput onChange={onChange} name={name} value={value} type="radio" />
				<Radiobox />
				<LabelText>{label || value}</LabelText>
			</Label>
			<br />
		</>
	);
}
