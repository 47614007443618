import { addMinutes, differenceInMinutes, endOfDay, format, isDate, roundToNearestMinutes, startOfDay, startOfHour, subHours, subMinutes } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import React, { useEffect, useRef, useState } from 'react';
import { MeasurementsDocument, RealtimeMeasurementDocument } from 'web/hooks/SingleAnlegg/useFjutt.graphql-gen';
import useAnlegg from 'web/hooks/useAnlegg';
import { useAuthorization } from 'web/hooks/useAuthorization';
import { useHasFjutt } from 'web/hooks/useHasFjutt';
import useNumbers from 'web/hooks/useNumbers';
import { useTenant } from 'web/hooks/useTenant';
import { getHarkClient, getOrCreateHarkClient } from 'web/lib/apolloClientHark';
import { NumberToClosestHypernum, roundUpByN, whTilKwh } from 'web/lib/numbers.utils';
import { HarkUserToken, fjuttMeterId, isFjuttEnabled } from 'web/models/Fjutt';

function calculateAverage(data: number[]) {
	if (data?.length === 0) return 0;

	let value = 0;

	for (let i = 0; i < data?.length; i++) {
		value += data[i];
	}

	if (value === 0) return 0;

	return value / data?.length;
}

interface DataModel {
	measurements: [{ activePowerImport: number; activePowerExport: number; omregnetPowerTotal: number; time: string }];
	reports: [
		{
			activeEnergyExported: number;
			activeEnergyImported: number;
			averageActivePowerExport: number;
			averageActivePowerImport: number;
			from: string;
			to: string;
		}
	];
}

interface useFjuttProps {
	maalerpunktId: string;
	doUseOmregningsFaktor: boolean; // Date from which omregningsfaktor is valid
	shouldSkip?: boolean;
	everyHalfHour?: boolean;
}
export default function useFjutt(props: useFjuttProps) {
	const n = useNumbers();
	const { anlegg } = useAnlegg({ visOpphoert: true });
	const singleAnlegg = anlegg.find((anlegg: any) => anlegg?.maalerpunktId === props?.maalerpunktId);
	const meterId = fjuttMeterId(singleAnlegg);
	const chartMax = useRef(0);
	const chartMin = useRef(0);

	function getFjuttOmregningsFaktor(anleggsId: string): number {
		const tenant = useTenant();
		const fjutt = tenant.fjutter.find(f => {
			return f.anleggsId === anleggsId;
		});
		return fjutt?.omregningsfaktor || 1;
	}

	const omregningsFaktor = props.doUseOmregningsFaktor ? getFjuttOmregningsFaktor(meterId) : 1; // Always multiply with activePowerImport and activeEnergyImported

	const { probeFjuttExists } = useHasFjutt();

	const wattGraphData = useRef<any[]>([]);

	const fjuttChartRef = useRef<any>(null);
	const speedometerMax = useRef(2000);

	const auth = useAuthorization();
	const harkToken = auth.harkToken as HarkUserToken;
	const [isBlurred, _setIsBlurred] = useState(false);
	const isBlurredRef = useRef(isBlurred);

	function setIsBlurred(newValue: boolean) {
		_setIsBlurred(newValue);
		isBlurredRef.current = newValue;
	}
	type FjuttPropsElementTypes = {
		harFjutt: boolean;
		fjuttConfirmed: boolean;
		speedometerPercentage: number;
		fjuttEnabled: boolean;
		valueNow: string;
		forrigeTimeGjennomsnitt: string;
		denneTimeGjennomsnitt: string;
		denneTimeRetning: number;
		forbrukIDag: string;
	};
	const fjuttPropsRef = useRef<FjuttPropsElementTypes>({
		harFjutt: false,
		fjuttConfirmed: false,
		speedometerPercentage: 0,
		fjuttEnabled: isFjuttEnabled(),
		valueNow: '',
		forrigeTimeGjennomsnitt: '',
		denneTimeGjennomsnitt: '',
		denneTimeRetning: 0,
		forbrukIDag: '',
	});

	const [fjuttProps, setFjuttProps] = useState<FjuttPropsElementTypes>();

	const [isLoading, setIsLoading] = useState(true);
	const subscription = useRef(null);

	const getNowIcon = (fjuttProps: FjuttPropsElementTypes) => {
		// might be used to animate icon
		const superLowBreak = 0.1;
		const lowBreak = 0.3;
		const normalBreak = 0.5;
		const highBreak = 0.7;
		const highestBreak = 0.9;

		if (fjuttProps.speedometerPercentage < superLowBreak) {
			return <i className="far fa-tachometer-slowest" />;
		}
		if (fjuttProps.speedometerPercentage <= lowBreak) {
			return <i className="far fa-tachometer-slow" />;
		}
		if (fjuttProps.speedometerPercentage <= normalBreak) {
			return <i className="far fa-tachometer-average" />;
		}
		if (fjuttProps.speedometerPercentage <= highBreak) {
			return <i className="far fa-tachometer-fast" />;
		}
		if (fjuttProps.speedometerPercentage > highestBreak) {
			return <i className="far fa-tachometer-fastest" />;
		}
	};
	const secondaryInfoPanels = (fjuttProps?: FjuttPropsElementTypes) => {
		if (!fjuttProps) {
			return [];
		}
		return [
			{
				value: fjuttProps.valueNow,
				unit: 'kW',
				description: 'Akkurat nå',
				icon: <i className="far fa-tachometer" />,
			},
			{
				value: fjuttProps.forrigeTimeGjennomsnitt,
				unit: 'kWh',
				description: 'Gjennomsnitt forrige time',
				icon: <i className="far fa-heart-rate" />,
			},
			{
				value: fjuttProps.denneTimeGjennomsnitt,
				unit: 'kWh',
				description: `Prognose denne timen \n(${fjuttProps.denneTimeRetning > 0 ? 'Økende' : 'Synkende'} trend)`,
				icon:
					fjuttProps.denneTimeRetning < 0 ? (
						<i>
							<svg width="100%" height="100%" viewBox="0 0 512 384" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M48 336H496C504.84 336 512 343.16 512 352V368C512 376.84 504.84 384 496 384H32C14.33 384 0 369.67 0 352V16C0 7.16 7.16 0 16 0H32C40.84 0 48 7.16 48 16V336Z"
									fill="currentColor"
								/>
								<path
									d="M402.843 95.6863L295.49 203.039C288.718 199.387 280.969 197.314 272.735 197.314C264.502 197.314 256.752 199.387 249.981 203.039L142.627 95.6863C136.379 89.4379 126.248 89.4379 120 95.6863L108.686 107C102.438 113.248 102.438 123.379 108.686 129.627L224.737 245.678C224.932 272.02 246.347 293.314 272.735 293.314C299.124 293.314 320.538 272.02 320.734 245.677L436.784 129.627C443.032 123.379 443.032 113.248 436.784 107L425.47 95.6863C419.222 89.4379 409.091 89.4379 402.843 95.6863Z"
									fill="currentColor"
								/>
							</svg>
						</i>
					) : (
						<i>
							<svg width="100%" height="100%" viewBox="0 0 512 384" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M48 336H496C504.84 336 512 343.16 512 352V368C512 376.84 504.84 384 496 384H32C14.33 384 0 369.67 0 352V16C0 7.16 7.16 0 16 0H32C40.84 0 48 7.16 48 16V336Z"
									fill="currentColor"
								/>
								<path
									d="M142.627 288.627L249.981 181.274C256.752 184.927 264.502 187 272.735 187C280.969 187 288.718 184.927 295.49 181.274L402.843 288.627C409.091 294.876 419.222 294.876 425.47 288.627L436.784 277.314C443.032 271.065 443.032 260.935 436.784 254.686L320.734 138.636C320.538 112.294 299.123 91 272.735 91C246.347 91 224.932 112.294 224.736 138.636L108.686 254.686C102.438 260.935 102.438 271.065 108.686 277.314L120 288.627C126.248 294.876 136.379 294.876 142.627 288.627Z"
									fill="currentColor"
								/>
							</svg>
						</i>
					),
				backgroundColor: fjuttProps.denneTimeRetning < 0 ? 'var(--nte-groenn-20)' : 'var(--nte-korall)',
			},
			{
				value: fjuttProps.forbrukIDag,
				unit: 'kWh',
				description: 'Så langt i dag',
				icon: <i className="far fa-clock" />,
			},
		];
	};
	useEffect(() => {
		if (props.shouldSkip) {
			cleanupSubscription();
			return;
		}

		async function initLoad() {
			const anleggHasFjutt = await probeFjuttExists(meterId);
			if (!anleggHasFjutt) {
				fjuttPropsRef.current = {
					...fjuttPropsRef.current,
					harFjutt: false,
				};
				updateGraphPropState();
				return;
			}
			const rawData = await getLastTwoHourData();
			initialConvertRawDataToWattGraphData(rawData);
			calculateFjuttGridData();
			loadDataIntoGraph(true);
			updateGraphPropState();
			subscribeToRealtimeData();
		}

		initLoad();

		window.onfocus = () => {
			setIsBlurred(false);
			if (fjuttPropsRef.current?.fjuttConfirmed) {
				calculateFjuttGridData();
				loadDataIntoGraph();
				updateGraphPropState();
			}
		};

		window.onblur = () => {
			setIsBlurred(true);
		};

		return () => {
			cleanupSubscription();
			window.onfocus = null;
			window.onblur = null;
		};
	}, [props.shouldSkip]);

	function cleanupSubscription() {
		const harkClient = getHarkClient();
		if (harkClient) {
			harkClient.stop();
		}
		if (subscription.current) {
			subscription.current?.unsubscribe?.();
			subscription.current = null;
		}
	}

	async function getLastTwoHourData(): Promise<DataModel> {
		setIsLoading(true);
		fjuttPropsRef.current = {
			...fjuttPropsRef.current,
			harFjutt: true,
		};
		updateGraphPropState();
		const harkClient = getOrCreateHarkClient(harkToken.accessToken);
		const { data, errors } = await harkClient.query({
			query: MeasurementsDocument,
			variables: {
				meterId: meterId,
				from: subHours(new Date(), 2),
				to: new Date(),
				iDagStart: addMinutes(startOfDay(new Date()), 120),
				iDagStop: endOfDay(new Date()),
			},
		});
		if (errors) {
			setIsLoading(false);
			return null;
		}
		const omregnetMeasurements = data?.measurements?.map((m: any) => {
			return { ...m, omregnetPowerImport: (m.activePowerImport - (m.activePowerExport || 0)) * omregningsFaktor };
		});
		return { ...data, measurements: omregnetMeasurements };
	}

	function updateGraphPropState() {
		setFjuttProps(fjuttPropsRef.current);
	}

	function initialConvertRawDataToWattGraphData(data: DataModel) {
		wattGraphData.current = [];

		data?.measurements?.forEach((m: any) => {
			if (m.activePowerImport === 0 && m.activePowerExport === null) {
				// Don't add to graph if no data, to prevent small spikes for exported data
				return;
			}
			const currentTime = new Date(m.time);
			if (!isDate(currentTime) && !isNaN(currentTime as any)) {
				return;
			}

			wattGraphData.current?.push(m);

			if (speedometerMax.current < m.omregnetPowerImport) {
				speedometerMax.current = m.omregnetPowerImport;
			}
			if (chartMax.current < m.omregnetPowerImport) {
				chartMax.current = m.omregnetPowerImport;
			}
			if (chartMin.current > m.omregnetPowerImport) {
				chartMin.current = m.omregnetPowerImport;
			}
		});

		const forbrukIDag = n(whTilKwh(data?.reports?.[0]?.activeEnergyImported * omregningsFaktor));

		fjuttPropsRef.current = {
			...fjuttPropsRef.current,
			forbrukIDag,
			harFjutt: true,
			fjuttConfirmed: true,
		};
	}

	function calculateFjuttGridData() {
		if (!wattGraphData.current) {
			return;
		}

		const forbruk: any = [];

		const forrigeTimeVerdier: number[] = [];
		const denneTimeVerdier: number[] = [];

		const forrigeTimeStart = startOfHour(subHours(new Date(), 1));
		const denneTimeStart = startOfHour(new Date());

		wattGraphData.current?.forEach(measurement => {
			const currentTime = new Date(measurement.time);

			if (!isDate(currentTime) && !isNaN(currentTime as any)) return;

			if (forrigeTimeStart <= currentTime && denneTimeStart > currentTime) {
				forrigeTimeVerdier.push(measurement.omregnetPowerImport);
			}

			if (denneTimeStart <= currentTime) {
				denneTimeVerdier.push(measurement.omregnetPowerImport);
			}

			forbruk.push(measurement.omregnetPowerImport);

			if (speedometerMax.current < measurement.omregnetPowerImport) {
				speedometerMax.current = measurement.omregnetPowerImport;
			}

			const sisteForbruk = forbruk[forbruk?.length - 1];
			const denneTimeAverage = calculateAverage(denneTimeVerdier);

			fjuttPropsRef.current = {
				...fjuttPropsRef.current,
				valueNow: n(whTilKwh(sisteForbruk)),
				speedometerPercentage: sisteForbruk / speedometerMax.current,
				denneTimeGjennomsnitt: n(whTilKwh(denneTimeAverage)),
				forrigeTimeGjennomsnitt: n(whTilKwh(calculateAverage(forrigeTimeVerdier))),
				denneTimeRetning: (denneTimeAverage > sisteForbruk && -1) || (denneTimeAverage < sisteForbruk && 1) || 0,
			};
		});
	}

	function subscribeToRealtimeData() {
		const harkClient = getOrCreateHarkClient(harkToken.accessToken);

		subscription.current = harkClient
			.subscribe({
				query: RealtimeMeasurementDocument,
				variables: {
					meterId: meterId,
				},
			})
			.subscribe(
				(res: any) => {
					const { data } = res;
					if (!data || !data.realtimeMeasurement) {
						return;
					}

					if (data.realtimeMeasurement.activePowerImport === 0 && data.realtimeMeasurement.activePowerExport === null) {
						// Don't add to graph if no data, to prevent small spikes for exported data
						return;
					}

					const omregnetPowerTotal =
						(data.realtimeMeasurement.activePowerImport - (data.realtimeMeasurement.activePowerExport || 0)) * omregningsFaktor;

					if (chartMax.current < omregnetPowerTotal) {
						chartMax.current = omregnetPowerTotal;
					}
					if (chartMin.current > omregnetPowerTotal) {
						chartMin.current = omregnetPowerTotal;
					}

					// Overstyrer tid fra hark, da vi ikke har noen garanti for at dataen kommer i riktig rekkefølge.
					data.realtimeMeasurement.time = new Date();

					wattGraphData.current?.push({ ...data.realtimeMeasurement, omregnetPowerImport: omregnetPowerTotal });
					wattGraphData.current?.shift();

					if (!isBlurredRef.current && !props.shouldSkip) {
						speedometerMax.current = Math.max(speedometerMax.current, omregnetPowerTotal);
						calculateFjuttGridData();

						const time = new Date(data.realtimeMeasurement.time);
						const forbruk = omregnetPowerTotal;
						flowDataIntoGraph(time, forbruk);
						updateGraphPropState();
					}
				},
				(error: any) => {
					console.error(error);
				}
			);
	}

	function loadDataIntoGraph(doZoom: boolean = false) {
		const time: any = [];
		const forbruk: any = [];
		const from = subMinutes(new Date(), 15);
		const to = new Date();

		if (fjuttChartRef.current) {
			wattGraphData.current?.forEach((measurement: any) => {
				const currentTime = new Date(measurement.time);
				if (!isDate(currentTime) && !isNaN(currentTime as any)) return;

				time.push(currentTime);
				forbruk.push(measurement.omregnetPowerImport);
			});

			fjuttChartRef.current?.axis.max(roundUpByN(chartMax.current, NumberToClosestHypernum(chartMax.current)));
			fjuttChartRef.current?.axis.min(roundUpByN(chartMin.current, NumberToClosestHypernum(chartMin.current)));

			fjuttChartRef.current?.load({
				columns: [
					['x', ...time],
					['Forbruk', ...forbruk],
				],
				done: () => {
					setIsLoading(false);
				},
			});
			if (doZoom) {
				fjuttChartRef.current?.zoom([from, to]);
			}
		}
	}

	function flowDataIntoGraph(time: any, forbruk: any) {
		if (fjuttChartRef.current) {
			fjuttChartRef.current.internal.config.axis_x_tick_values = calculateXAxis();
			fjuttChartRef.current?.axis.max(roundUpByN(chartMax.current, NumberToClosestHypernum(chartMax.current)));
			fjuttChartRef.current?.axis.min(roundUpByN(chartMin.current, NumberToClosestHypernum(chartMin.current)));
			fjuttChartRef.current?.flow({
				columns: [
					['x', time],
					['Forbruk', forbruk],
				],
			});
		}
	}

	function calculateXAxis() {
		let roundTo = props.everyHalfHour ? 30 : 15;
		let arr: Date[] = [];
		let now = new Date();
		let nearestQuarter = roundToNearestMinutes(now, { nearestTo: roundTo });
		nearestQuarter = nearestQuarter > now ? subMinutes(nearestQuarter, roundTo) : nearestQuarter;
		let oldest = subHours(nearestQuarter, 2);
		let diff = differenceInMinutes(now, oldest);
		for (let i = 0; i < Math.ceil(diff / roundTo); i++) {
			let newDate = addMinutes(oldest, roundTo * i);
			if (differenceInMinutes(now, newDate) > roundTo / 3) {
				arr.push(newDate);
			}
		}

		arr.push(now);
		now.getMinutes();

		return arr;
	}

	return {
		fjuttLoading: isLoading, // || !fjuttPropsRef.current?.fjuttConfirmed,
		fjuttBlurred: isBlurred,
		fjuttProps: fjuttProps,
		secondaryInfoPanels: secondaryInfoPanels(fjuttProps),
		fjuttChartRef,
		fjuttChart: {
			data: {
				x: 'x',
				columns: [['x'], ['Forbruk']],
				types: {
					Forbruk: 'area-spline',
				},
				axes: {
					Forbruk: 'y2',
				},
				colors: {
					Forbruk: 'var(--nte-blaa)',
				},
				color: function (color: string, d: any) {
					return d.value < 0 ? 'var(--nte-groenn)' : color;
				},
			},
			legend: {
				show: false,
			},
			subchart: {
				show: true,
			},
			point: {
				show: false,
			},
			line: {
				connectNull: true,
			},
			padding: { right: 32 },
			axis: {
				y: { show: false },
				y2: {
					show: true,
					min: 0,
					label: 'kW',
					tick: {
						format: function (value: number) {
							return n(whTilKwh(value), false, '0,0.0');
						},
					},
				},
				x: {
					type: 'timeseries',
					tick: {
						values: calculateXAxis(),
						format: (x: Date) => {
							if (!isDate(x)) return null;
							return format(new Date(x), 'HH:mm', { locale: nbLocale });
						},
					},
					//extent: [subMinutes(new Date(), 30), new Date()],
				},
			},
			grid: {
				x: {
					show: false,
				},
				y: {
					show: true,
					lines: [{ value: 0, axis: 'y2', text: '', class: 'dotted-line' }],
				},
			},
			tooltip: {
				format: {
					title: (value: any) => {
						if (!isDate(value)) return null;
						return `kl. ${format(value, 'HH:mm:ss', { locale: nbLocale })}`;
					},
					value: (value: any) => {
						return `${n(whTilKwh(value))} kW`;
					},
				},
			},
			transition: {
				duration: null,
			},
		},
	} as any;
}
