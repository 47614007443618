// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentFakturaerQueryVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
  antall?: Types.InputMaybe<Types.Scalars['Int']>;
  fakturamerker?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  fraDato?: Types.InputMaybe<Types.Scalars['Date']>;
  maalere?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  naeringskoder?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  side?: Types.InputMaybe<Types.Scalars['Int']>;
  tilDato?: Types.InputMaybe<Types.Scalars['Date']>;
  filter?: Types.InputMaybe<Types.FakturaFilter>;
}>;


export type HentFakturaerQuery = { bedrift?: { id?: string, fakturaer?: { antallSider?: number, fakturaer?: Array<{ guid?: string, fakturanummer?: string, fakturatype?: Types.InvoiceType, fakturastatus?: Types.InvoiceStatus, beloepInklMoms?: number, pdf?: string, fakturadato?: string, forbruk?: number, saldo?: number, forfallsdato?: string, kundeid?: string, url?: string, maalerpunktIder?: Array<string> }> } } };

export type FakturaDataFragment = { guid?: string, fakturanummer?: string, fakturatype?: Types.InvoiceType, fakturastatus?: Types.InvoiceStatus, beloepInklMoms?: number, pdf?: string, fakturadato?: string, forbruk?: number, saldo?: number, forfallsdato?: string, kundeid?: string, url?: string, maalerpunktIder?: Array<string> };

export const FakturaDataFragmentDoc = gql`
    fragment FakturaData on Faktura {
  guid
  fakturanummer
  fakturatype
  fakturastatus
  beloepInklMoms
  pdf
  fakturadato
  forbruk
  saldo
  forfallsdato
  kundeid
  url
  maalerpunktIder
}
    `;
export const HentFakturaerDocument = gql`
    query hentFakturaer($orgid: ID!, $antall: Int, $fakturamerker: [String], $fraDato: Date, $maalere: [String], $naeringskoder: [String], $side: Int, $tilDato: Date, $filter: FakturaFilter) {
  bedrift: hentBedrift(orgid: $orgid) {
    id
    fakturaer(
      antall: $antall
      fakturamerker: $fakturamerker
      fraDato: $fraDato
      maalere: $maalere
      naeringskoder: $naeringskoder
      side: $side
      tilDato: $tilDato
      filter: $filter
    ) {
      antallSider
      fakturaer {
        ...FakturaData
      }
    }
  }
}
    ${FakturaDataFragmentDoc}`;

/**
 * __useHentFakturaerQuery__
 *
 * To run a query within a React component, call `useHentFakturaerQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentFakturaerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentFakturaerQuery({
 *   variables: {
 *      orgid: // value for 'orgid'
 *      antall: // value for 'antall'
 *      fakturamerker: // value for 'fakturamerker'
 *      fraDato: // value for 'fraDato'
 *      maalere: // value for 'maalere'
 *      naeringskoder: // value for 'naeringskoder'
 *      side: // value for 'side'
 *      tilDato: // value for 'tilDato'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHentFakturaerQuery(baseOptions: Apollo.QueryHookOptions<HentFakturaerQuery, HentFakturaerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentFakturaerQuery, HentFakturaerQueryVariables>(HentFakturaerDocument, options);
      }
export function useHentFakturaerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentFakturaerQuery, HentFakturaerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentFakturaerQuery, HentFakturaerQueryVariables>(HentFakturaerDocument, options);
        }
export type HentFakturaerQueryHookResult = ReturnType<typeof useHentFakturaerQuery>;
export type HentFakturaerLazyQueryHookResult = ReturnType<typeof useHentFakturaerLazyQuery>;
export type HentFakturaerQueryResult = Apollo.QueryResult<HentFakturaerQuery, HentFakturaerQueryVariables>;