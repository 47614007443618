import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from 'web/components/atoms/Button';
import Push from 'web/components/atoms/Push';
import CheckboxField from 'web/components/molecules/CheckboxField';
import DataError from 'web/components/molecules/form/DataError';
import FormLabel from 'web/components/molecules/form/FormLabel';
import TextField from 'web/components/molecules/form/TextField';
import Modal from 'web/components/molecules/Modal';
import { HentBedriftFraKundesystemDocument, SlaaoppBedriftDocument, useOpprettBedriftMutation } from 'web/components/organisms/modals/NyKundeModal.graphql-gen';
import ModulSelect from 'web/components/organisms/ModulSelect';
import Form, { useForm, useFormError } from 'web/hooks/useForm';
import { useTracking } from 'web/hooks/useTracking';
import apolloClient from 'web/lib/apolloClient';
import { isEmailValid } from 'web/lib/validate';

const GearIcon = styled.i`
	font-size: 16px;
`;
interface NyKundeModalProps {
	onClose: () => void;
}
export default function NyKundeModal({ onClose }: NyKundeModalProps) {
	const { track } = useTracking();
	const [bedrift, setBedrift] = useState(null);
	const [isOrganizationLoading, setIsOrganizationLoading] = useState(false);
	const { t } = useTranslation('admin');
	const [canSubmitOrgnrForm, setCanSubmitOrgnrForm] = useState(false);
	const [formError, setFormError] = useState(null);
	const [createBedrift, { loading, error }] = useOpprettBedriftMutation({
		awaitRefetchQueries: true,
		refetchQueries: ['hentBedrifter'],
	});

	return (
		<Modal title="Legg til ny bedrift" onClose={onClose}>
			{!bedrift && (
				<Form
					loading={isOrganizationLoading}
					onSubmit={async (value: any) => {
						if (!value.orgnummer) {
							setFormError({ orgnummer: t('NyKundeModal.orgnummerMissingError') });
							return;
						}

						value.orgnummer = value.orgnummer.replace(/[^0-9]/g, '');

						if (value.orgnummer?.length !== 9) {
							setFormError({ orgnummer: t('NyKundeModal.orgnummerError') });
							return;
						}

						setIsOrganizationLoading(true);
						setFormError(null);

						const {
							data: { slaaoppBedrift },
						} = await apolloClient.query({
							query: SlaaoppBedriftDocument,
							fetchPolicy: 'network-only',
							variables: {
								orgnummer: value.orgnummer,
							},
						});

						if (slaaoppBedrift?.kilde === 'Bedriftsportalen') {
							setIsOrganizationLoading(false);
							setFormError({ orgnummer: 'Bedriften er allerede registrert' });
							return;
						}

						apolloClient
							.query({
								query: HentBedriftFraKundesystemDocument,
								fetchPolicy: 'network-only',
								variables: {
									orgnummer: value.orgnummer,
								},
							})
							.then(({ data: { bedrift } }) => {
								setIsOrganizationLoading(false);
								if (bedrift) {
									setBedrift(bedrift);
								} else {
									setFormError({ orgnummer: 'Fant ikke Bedriften' });
								}
							})
							.catch(error => {
								setFormError({ orgnummer: 'Fant ikke Bedriften' });
								setIsOrganizationLoading(false);
							});
					}}>
					<TextField
						required
						name="orgnummer"
						error={formError?.orgnummer}
						label={t('Kunder.element.orgnummer')}
						onChange={(e: any) => {
							e.target.value = e.target.value.replace(/[^0-9]/g, '');

							if (!e.target.value) {
								return setCanSubmitOrgnrForm(false);
							}

							setFormError({ orgnummer: t(null) });
							return setCanSubmitOrgnrForm(true);
						}}
					/>
					<Button disabled={!canSubmitOrgnrForm}>{t('NyKundeModal.next')}</Button>
				</Form>
			)}
			{bedrift && (
				<Form
					loading={loading}
					onSubmit={(value: any) => {
						if (!value.orgnummer) {
							setFormError({ orgnummer: t('NyKundeModal.orgnummerMissingError') });
							return;
						}

						value.orgnummer = value.orgnummer.replace(/[^0-9]/g, '');

						if (value.orgnummer?.length !== 9) {
							setFormError({ orgnummer: t('NyKundeModal.orgnummerError') });
							return;
						}

						if (!isEmailValid(value.kontaktEpost)) {
							setFormError({ kontaktEpost: t('NyKundeModal.emailError') });
							return;
						}

						createBedrift({ variables: { opprettBedrift: value } }).then(() => {
							if (value.sendInvitasjon) {
								track('Invite Sent', {
									label: 'Ny bedrift',
								});
							}
							onClose && onClose();
						});
					}}>
					<DataError error={error} />
					<Push />

					<TextField defaultValue={bedrift.navn} required name="navn" label={t('Kunder.element.navn')} />
					<TextField
						defaultValue={bedrift.orgnummer}
						required
						name="orgnummer"
						error={formError?.orgnummer}
						label={t('Kunder.element.orgnummer')}
						onChange={(e: any) => {
							e.preventDefault();
							setFormError({ orgnummer: t(null) });
						}}
					/>
					<TextField name="kontaktperson" required label={t('Kunder.element.kontaktperson')} />
					<TextField
						defaultValue={bedrift?.kontaktinfo?.epost}
						required
						name="kontaktEpost"
						error={formError?.kontaktEpost}
						label={t('Kunder.element.kontaktEpost')}
						onChange={(e: any) => {
							e.preventDefault();
							setFormError({ kontaktEpost: t(null) });
						}}
					/>

					<ModalSelectField defaultValue={['Bedriftsadmin', 'Strøm']} name="moduler" label={t('Kunder.element.moduler')} required />
					<CheckboxField defaultChecked name="sendInvitasjon" label={t('NyKundeModal.sendInviteEmail')} />
					<Push />
					<Button>{t('NyKundeModal.save')}</Button>
				</Form>
			)}
		</Modal>
	);
}

const StyledButton = styled.button`
	color: var(--nte-sort);
	background: var(--white);
	border: var(--nte-border);
	border-radius: 3px;
	padding: 9px 16px;
	box-sizing: border-box;
	font-size: 16px;
	width: 100%;
	text-align: left;
	position: relative;
`;

const StyledGearIcon = styled(GearIcon)`
	color: var(--nte-blaa-logo);
	position: absolute;
	top: 10px;
	right: 20px;
`;

interface ModalSelectFieldProps {
	name: string;
	label: string;
	error?: string;
	defaultValue?: string[];
	required?: boolean;
}
function ModalSelectField({ name, label, error, defaultValue, required }: ModalSelectFieldProps) {
	const { t } = useTranslation('admin');
	const [tempValue, setTempValue] = useState(defaultValue || []);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const formError = useFormError(name);
	useForm(name, { isJson: true });

	return (
		<>
			<FormLabel label={label} required={required} error={error || formError}>
				<StyledButton type="button" onClick={() => setIsModalOpen(true)}>
					{tempValue.join(', ') || 'Ingen moduler valgt'}
					<StyledGearIcon className="fas fa-cog" />
				</StyledButton>
				<input type="hidden" name={name} value={JSON.stringify(tempValue)} />
			</FormLabel>
			{isModalOpen && (
				<Modal title={t('ModulSelect.title')} onClose={() => setIsModalOpen(false)}>
					<ModulSelect
						key={tempValue.join('')}
						defaultValue={tempValue}
						saveLabel={t('NyKundeModal.selectModuler')}
						onSelect={(value: string[]) => {
							setTempValue(value);
							setIsModalOpen(false);
						}}
					/>
				</Modal>
			)}
		</>
	);
}
