import { useEffect } from 'react';
import { isMobile } from 'web/GlobalStyling';
import { parsedToken } from 'web/hooks/useAuthHelpers';
import { useTenant } from 'web/hooks/useTenant';

export enum SegmentCategory {
	Hovedsiden = 'Hovedsiden',
	Anlegg = 'Anlegg',
	SingleAnlegg = 'SingleAnlegg',
	Faktura = 'Faktura',
	SingleFaktura = 'SingleFaktura',
	Forbruk = 'Forbruk',
	Kostnad = 'Kostnad',
	Spotpris = 'Spotpris',
	Klimaregnskap = 'Klimaregnskap',
	Innboks = 'Innboks',
	Bedriftsadmin = 'Bedriftsadmin',
	MeldInnAnleggBatch = 'MeldInnAnleggBatch',
	MineBedrifter = 'MineBedrifter',
}

export enum SegmentAction {
	clicked = 'clicked',
	exported = 'exported',
	read = 'read',
	ClickRegisterAnlegg = 'ClickRegisterAnlegg',
}

function getOs(userAgent: string) {
	const os = ['Windows', 'Linux', 'Mac', 'X11', 'iOS', 'android'];
	return os.find(v => userAgent.indexOf(v) >= 0) || 'Unknown';
}

export function useTracking() {
	/**
	 * @see src/web/index.tsx | 10
	 * @see https://segment.com/docs/getting-started/02-simple-install/
	 */
	const tenant = useTenant();

	function track(event: string, properties?: any, options?: any, callback?: any) {
		return analytics.track(
			event,
			{
				...properties,
				isMobile: isMobile(),
			},
			{
				...options,
				context: {
					groupId: tenant?.orgnummer,
					os: {
						name: getOs(window.navigator.userAgent),
					},
				},
			},
			callback
		);
	}

	function trackSignIn(trackingId: string, parsedToken: parsedToken) {
		if (trackingId) {
			analytics.identify(
				trackingId,
				{
					'Bedriftsportalen Last Opened': parsedToken?.auth_time || parsedToken?.iat,
				},
				() => {
					track('User Signed In', { email: parsedToken?.email });
				}
			);
		} else {
			// Automatically generates an anonymous id
			analytics.identify(
				{
					'Bedriftsportalen Last Opened': parsedToken?.auth_time || parsedToken?.iat,
				},
				() => {
					track('User Signed In', { email: parsedToken?.email });
				}
			);
		}
	}

	function trackGroup(groupId: string, orgName?: string) {
		analytics.group(groupId, { orgName: orgName });
	}

	function trackPageView(category: SegmentCategory, name?: string, properties?: any) {
		useEffect(() => {
			analytics.page(category, name, { ...properties, isMobile: isMobile() });
		}, [false]);
	}

	return {
		analytics,
		track,
		trackSignIn,
		trackGroup,
		trackPageView,
	};
}
