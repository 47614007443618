import React from 'react';
import styled from 'styled-components';
import Center from 'web/components/atoms/Center';
import Push from 'web/components/atoms/Push';
import { ANLEGG_SKJEMA_STAGES } from 'web/components/organisms/modals/MeldInnAnleggModal';
import { AnleggInnmeldingsskjemaStepProps } from 'web/components/organisms/modals/MeldInnAnleggModal/Step1';

const ChooseButton = styled.button`
	font-size: 20px;
	background: none;
	border: none;
`;
const ArrowRightIcon = styled.i`
	margin-left: 0.375rem;
	font-size: 1rem;
`;

export default function AnleggInnmeldingsskjemaStep0({ state, setState }: AnleggInnmeldingsskjemaStepProps) {
	return (
		<Center>
			<ChooseButton
				type="button"
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					setState({
						...state,
						stage: ANLEGG_SKJEMA_STAGES.MALERPUNKT_ID,
					});
				}}>
				Registrer med målepunkt-id (18 siffer)
				<ArrowRightIcon className="fas fa-long-arrow-right" />
			</ChooseButton>
			<Push />
			<ChooseButton
				type="button"
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					setState({
						...state,
						stage: ANLEGG_SKJEMA_STAGES.MALERNUMMER,
					});
				}}>
				Registrer med målernummer og postnummer
				<ArrowRightIcon className="fas fa-long-arrow-right" />
			</ChooseButton>
			<Push />
			<ChooseButton
				type="button"
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					setState({
						...state,
						stage: ANLEGG_SKJEMA_STAGES.STREET,
					});
				}}>
				Registrer med gateadresse
				<ArrowRightIcon className="fas fa-long-arrow-right" />
			</ChooseButton>
		</Center>
	);
}
