import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonWrapper, SecondaryButton } from 'web/components/atoms/Button';
import Push from 'web/components/atoms/Push';
import ErrorList from 'web/components/molecules/form/ErrorList';
import { ErrorIcon, LabelError } from 'web/components/molecules/form/FormLabel';
import TextField, { StyledInput } from 'web/components/molecules/form/TextField';
import { Forbruksvarsel } from 'web/components/organisms/Forbruksvarsel';
import { device } from 'web/GlobalStyling';
import Form from 'web/hooks/useForm';

export const FormInputTitle = styled.span`
	display: inline-block;
	font-weight: bold;
	margin-bottom: 0.5rem;
`;

const ForbrukInputWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	gap: 0.2rem;
	& * {
		max-width: 100px;
		min-width: 50px;
	}

	& label {
		margin: 0;
	}
`;

export const Description = styled.p`
	font-size: 1.25rem;
	padding: 0;
	margin: 0;
	@media ${device.mobile} {
		font-size: 1.1rem;
	}
`;

const RequiredStar = styled.span`
	color: var(--varsel-roed);
`;

interface InputProps {
	error: boolean;
}

export const PriceInput = styled(StyledInput)<InputProps>``;

interface ForbruksvarselSkjemaProps {
	defaultVarsel?: Forbruksvarsel;
	orgId: string;
	anleggsId: string;
	loading?: boolean;
	description?: any;
	onSubmit: (varsel: Forbruksvarsel) => void;
	onRemove?: (varsel: Forbruksvarsel) => void;
}
export default function ForbruksvarselSkjema(props: ForbruksvarselSkjemaProps) {
	const [formError, setFormError] = useState(null);

	const defaultVarselKriterie = props.defaultVarsel?.grenseIWatt || null;
	const [grense, setGrense] = useState(defaultVarselKriterie || 0);

	const checkIfContainsErrors = () => {
		let errors = false;
		if (formError) {
			const a = Object.keys(formError);
			a?.forEach(error => (errors = formError[error]?.length > 0 ? true : errors));
		}
		return errors;
	};

	useEffect(() => {
		if (grense) {
			const currentBeskrivelse = (document?.getElementById('varselBeskrivelse') as HTMLInputElement)?.value || '';
			if (currentBeskrivelse) {
				const newBeskrivelse = currentBeskrivelse.replace(/[0-9]+/g, grense.toString());
				(document.getElementById('varselBeskrivelse') as HTMLInputElement).value = newBeskrivelse;
			}
		}
	}, [grense]);

	return (
		<div>
			{props.description && <Description>{props.description}</Description>}
			<Push />
			<Form
				values={props.defaultVarsel}
				loading={props.loading}
				onSubmit={(vars: any, e: any) => {
					e.preventDefault();
					// Check if input is ok, if not set form error and return
					let errorElement = null;
					if (!e.target.grense.value) {
						errorElement = { grense: 'Feltet som inneholder Wh må fylles ut' };
					}

					if (!e.target.epost.value) {
						errorElement = { ...errorElement, epost: 'E-post er påkrevd' };
					}

					setFormError({ ...formError, ...errorElement });
					if (!e.target.grense.value || !e.target.epost.value || checkIfContainsErrors()) {
						return;
					}
					setFormError(null);

					const nyVarsel: Forbruksvarsel = {
						...props.defaultVarsel,
						beskrivelse: vars.beskrivelse,
						grenseIWatt: parseInt(e.target.grense.value, 10),
						epost: vars.epost,
						anleggsId: props.anleggsId,
						orgId: props.orgId,
					};
					props.onSubmit(nyVarsel);
				}}>
				<>
					<FormInputTitle>
						Hva skal varselet inneholde? <RequiredStar>*</RequiredStar>
					</FormInputTitle>

					<ForbrukInputWrapper>
						Varsle meg når strømforbruket ser ut til å overstige &nbsp;
						<div style={{ display: 'inherit', alignItems: 'inherit' }}>
							<PriceInput
								name="grense"
								type="number"
								placeholder="2000"
								defaultValue={grense || ''}
								min={0}
								error={formError?.grense || ''}
								max={99999}
								style={{ marginRight: '0.2rem' }}
								onChange={event => {
									setFormError({ ...formError, grense: '' });
									setGrense(parseInt(event.target.value, 10));
								}}
							/>{' '}
							Wh
						</div>
					</ForbrukInputWrapper>
					{formError?.grense && (
						<LabelError>
							<ErrorIcon />
							{formError?.grense}
						</LabelError>
					)}
					<Push height="2rem" />

					<FormInputTitle>Hva ønsker du at det skal stå på varselet? </FormInputTitle>
					<TextField
						id="varselBeskrivelse"
						name="beskrivelse"
						placeholder="Forbruk ser ut til å overstige 2000Wh"
						defaultValue={props.defaultVarsel?.beskrivelse || grense ? `Forbruk ser ut til å overstige ${grense} Wh` : ''}
					/>

					<FormInputTitle>
						Hvor ønsker du å motta varslingen? <RequiredStar>*</RequiredStar>
					</FormInputTitle>

					<TextField
						name="epost"
						placeholder="navn@adresse.no"
						type="email"
						error={formError?.epost || formError?.emailValidated}
						onChange={() => {
							setFormError({ ...formError, emailValidated: '', epost: '' });
						}}
					/>
				</>
				{checkIfContainsErrors() && <ErrorList formErrors={formError} />}
				<ButtonWrapper>
					<Button type="submit" disabled={props.loading} style={{ marginBottom: '0' }}>
						{props.defaultVarsel ? (
							<>
								<i className="far fa-pen" /> Oppdater varsel
							</>
						) : (
							<>
								<i className="far fa-save" /> Lagre varsel
							</>
						)}
					</Button>
					{props.defaultVarsel && (
						<SecondaryButton type="button" onClick={() => props.onRemove(props.defaultVarsel)}>
							Slett varsel
						</SecondaryButton>
					)}
				</ButtonWrapper>
			</Form>
		</div>
	);
}
