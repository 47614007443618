import { Anlegg, Anleggsstatus } from 'generated/graphql-types';
import { useTranslation } from 'react-i18next';
import useProfile from 'web/hooks/useProfile';
import { Energisone } from 'web/hooks/useSpotpris';
import { useTenant } from 'web/hooks/useTenant';
import { alleHarFjutt } from 'web/models/Fjutt';

type FilterValue = {
	value: string;
	label: string;
	subLabel?: string;
	count: number;
	countActive?: number;
	anlegg: Anlegg[];
};

type FilterValueMap = { [key: string]: FilterValue };

type UseAnleggFilter = {
	fakturamerker?: string[] | string;
	naeringskoder?: string[] | string;
	malerpunkter?: string[] | string;
	kategorier?: string[] | string;
	status?: string[] | string;
	notStatus?: string[] | string;
	sortering?: { direction: number; value: string };
	visOpphoert?: boolean;
	sorter?: string;
	search?: string;
};

export default function useAnlegg(filter: UseAnleggFilter = {}, isOnAnleggPage?: boolean) {
	const tenant = useTenant();
	const { t } = useTranslation('forbruk');
	const { kategorier } = useProfile();

	const anleggMedFjutt = tenant?.fjutter?.map(f => f.anleggsId);
	// Make a copy of the array, to make sure the cache wont be changed.
	let anlegg = [...(tenant?.anlegg || [])];

	let fakturamerkerMap: FilterValueMap = {};
	let naeringskodeMap: FilterValueMap = {};
	let energisoneMap: FilterValueMap = {};

	let energisoneMedFlestAnlegg;

	const aktiveAnlegg = anlegg.filter(element => element.anleggsstatus !== Anleggsstatus.OPPHOERT);
	const oppHoerteAnleggCount = anlegg?.length - aktiveAnlegg?.length || 0;

	Object.keys(Energisone)?.forEach(
		energisone => (energisoneMap[energisone] = { value: energisone, label: energisone, count: 0, anlegg: [], countActive: 0 })
	);
	for (let i = 0; i < anlegg?.length; i++) {
		if (anlegg[i].fakturamerke) {
			if (!fakturamerkerMap[anlegg[i].fakturamerke]) {
				fakturamerkerMap[anlegg[i].fakturamerke] = {
					value: anlegg[i].fakturamerke,
					label: anlegg[i].fakturamerke,
					count: 1,
					countActive: anlegg[i].anleggsstatus !== Anleggsstatus.OPPHOERT ? 1 : 0,
					anlegg: [],
				};
			} else {
				fakturamerkerMap[anlegg[i].fakturamerke].count++;
				fakturamerkerMap[anlegg[i].fakturamerke].countActive += anlegg[i].anleggsstatus !== Anleggsstatus.OPPHOERT ? 1 : 0;
			}
		}

		if (anlegg[i].naeringskodeID && anlegg[i].naeringskode) {
			if (!naeringskodeMap[anlegg[i].naeringskodeID]) {
				naeringskodeMap[anlegg[i].naeringskodeID] = {
					value: anlegg[i].naeringskode,
					label: anlegg[i].naeringskode,
					count: 1,
					countActive: anlegg[i].anleggsstatus !== Anleggsstatus.OPPHOERT ? 1 : 0,
					anlegg: [],
				};
			} else {
				naeringskodeMap[anlegg[i].naeringskodeID].count++;
				naeringskodeMap[anlegg[i].naeringskodeID].countActive += anlegg[i].anleggsstatus !== Anleggsstatus.OPPHOERT ? 1 : 0;
			}
		}

		if (anlegg[i].prisOmrade) {
			if (!energisoneMap[anlegg[i].prisOmrade]) {
				energisoneMap[anlegg[i].prisOmrade] = {
					value: anlegg[i].prisOmrade,
					label: anlegg[i].prisOmrade,
					count: 1,
					anlegg: [],
				};
			} else {
				energisoneMap[anlegg[i].prisOmrade].count++;
			}
		}
	}

	let fakturamerker: FilterValue[] = Object.keys(fakturamerkerMap).map(key => {
		let subLabel = '';
		if (fakturamerkerMap[key].count > 0) {
			subLabel =
				fakturamerkerMap[key].countActive == 0
					? 'Ingen aktive'
					: `${fakturamerkerMap[key].countActive} aktiv${fakturamerkerMap[key].countActive == 1 ? 't' : 'e'}`;

			subLabel += fakturamerkerMap[key].countActive < fakturamerkerMap[key].count ? ` av totalt ${fakturamerkerMap[key].count} anlegg` : ' anlegg';
		}

		return {
			...fakturamerkerMap[key],
			value: key,
			label: key,
			subLabel: subLabel,
		};
	});

	let naeringskoder: FilterValue[] = Object.keys(naeringskodeMap).map(key => {
		let subLabel = '';
		if (naeringskodeMap[key].count > 0) {
			subLabel =
				naeringskodeMap[key].countActive == 0
					? 'Ingen aktive'
					: `${naeringskodeMap[key].countActive} aktiv${naeringskodeMap[key].countActive == 1 ? 't' : 'e'}`;

			subLabel += naeringskodeMap[key].countActive < naeringskodeMap[key].count ? ` av totalt ${naeringskodeMap[key].count} anlegg` : ' anlegg';
		}

		return {
			...naeringskodeMap[key],
			value: key,
			label: key,
			subLabel: `${naeringskodeMap[key].label}${subLabel?.length > 0 ? ', ' : ''}${subLabel}`,
		};
	});

	let anleggCount = 0;
	let energisoner: FilterValue[] = Object.keys(energisoneMap)
		.map(key => {
			if (energisoneMap[key].count > anleggCount) {
				anleggCount = energisoneMap[key].count;
				energisoneMedFlestAnlegg = key;
			}
			return {
				...energisoneMap[key],
				value: key,
				label: `${t(`Spotpris.energisone.${key}`, key)}`,
				subLabel: key + (energisoneMap[key].count > 0 ? ', ' + energisoneMap[key].count + ' anlegg' : ''),
			};
		})
		.sort((a, b) => b.count - a.count);

	if (!anlegg?.length) {
		energisoneMedFlestAnlegg = Energisone.NO3;
	}

	if (filter.fakturamerker?.length) {
		if (Array.isArray(filter.fakturamerker)) {
			anlegg = anlegg.filter(element => filter.fakturamerker.indexOf(element.fakturamerke) > -1);
		} else {
			anlegg = anlegg.filter(element => element.fakturamerke === filter.fakturamerker);
		}
	}

	if (filter.naeringskoder?.length) {
		if (Array.isArray(filter.naeringskoder)) {
			anlegg = anlegg.filter(element => filter.naeringskoder.indexOf(element.naeringskodeID) > -1);
		} else {
			anlegg = anlegg.filter(element => element.naeringskodeID === filter.naeringskoder);
		}
	}

	if (!filter.visOpphoert) {
		anlegg = anlegg.filter(element => element.anleggsstatus !== Anleggsstatus.OPPHOERT);
	}

	let mappedMaalerKategorier: { [key: string]: { value: string; label: string }[] } = {};

	let idIn: string[] = [];

	kategorier?.forEach(element => {
		if (filter.kategorier?.indexOf(element.id) > -1) {
			idIn = [...idIn, ...(element.maalerpunkter || [])];
		}

		element?.malerpunkter?.forEach(malerpunkt => {
			if (!mappedMaalerKategorier[malerpunkt]) {
				mappedMaalerKategorier[malerpunkt] = [];
			}
			mappedMaalerKategorier[malerpunkt].push({
				value: element.id,
				label: element.navn,
			});
		});
	});

	if (idIn && idIn?.length > 0 && idIn.indexOf('') < 0) {
		anlegg = anlegg.filter(element => idIn.indexOf(element?.maalerpunktId) > -1);
	}

	const statusesSet = new Set();

	anlegg =
		anlegg
			.map(anlegg => {
				statusesSet.add(anlegg.anleggsstatus);

				return {
					...anlegg,
					kategorier: mappedMaalerKategorier[anlegg?.maalerpunktId],
					harFjutt: anleggMedFjutt.includes(anlegg.maalernummer) || alleHarFjutt(),
				};
			})
			.sort((a, b) => {
				if (a.tilDato < b.tilDato) {
					return 1;
				}
				if (a.tilDato > b.tilDato) {
					return -1;
				}
				return 0;
			}) ?? [];

	const statuses = Array.from(statusesSet).map(status => {
		return {
			value: status,
			label: status,
		};
	});

	if (filter.search && isOnAnleggPage) {
		anlegg = anlegg.map(element => {
			element._search =
				Object.keys(element)
					// @ts-ignore
					.map(key => element[key] + ' ')
					.join(' ')
					.toLowerCase() +
				' ' +
				element.kategorier
					?.map(e => e.label)
					.join(' ')
					.toLowerCase();

			return element;
		});

		const searchArray = filter.search.toLowerCase().split(' ');

		anlegg = anlegg.filter(element => {
			for (let i = 0; i < searchArray?.length; i++) {
				if (searchArray[i] && element._search.indexOf(searchArray[i]) < 0) {
					return false;
				}
			}

			return true;
		});
	}

	if (filter.status?.length) {
		if (Array.isArray(filter.status)) {
			anlegg = anlegg.filter(element => filter.status.indexOf(element.anleggsstatus) > -1);
		} else {
			anlegg = anlegg.filter(element => element.anleggsstatus === filter.status);
		}
	}

	if (filter.notStatus?.length) {
		if (Array.isArray(filter.notStatus)) {
			anlegg = anlegg.filter(element => filter.notStatus.indexOf(element.anleggsstatus) === -1);
		} else {
			anlegg = anlegg.filter(element => element.anleggsstatus !== filter.notStatus);
		}
	}

	const sorterPaaTallListe = ['aarsforbruk', 'maalerpunktId', 'maalernummer'];
	if (filter.sorter) {
		const sorterSomTall = sorterPaaTallListe.indexOf(filter.sorter) > -1;

		anlegg.sort((a, b) => {
			// @ts-ignore
			let aValue = (sorterSomTall && Number(a[filter.sorter])) || a[filter.sorter];
			// @ts-ignore
			let bValue = (sorterSomTall && Number(b[filter.sorter])) || b[filter.sorter];

			if (aValue < bValue) {
				return -1;
			}
			if (bValue < aValue) {
				return 1;
			}

			return 1;
		});
	}

	if (filter.sortering) {
		const sorterSomTallDireksjonell = sorterPaaTallListe.indexOf(filter.sortering.value) > -1;

		anlegg.sort((a, b) => {
			// @ts-ignore
			let aValue = (sorterSomTallDireksjonell && Number(a[filter.sortering.value])) || a[filter.sortering.value];
			// @ts-ignore
			let bValue = (sorterSomTallDireksjonell && Number(b[filter.sortering.value])) || b[filter.sortering.value];

			if (aValue < bValue) {
				return -filter.sortering.direction;
			}
			if (bValue < aValue) {
				return filter.sortering.direction;
			}

			return filter.sortering.direction;
		});
	}

	let isAllAnleggSelected = false;

	if (filter.visOpphoert && anlegg.length === tenant?.anlegg.length) {
		isAllAnleggSelected = true;
	} else if (anlegg.length === tenant?.anlegg.filter((a: Anlegg) => a.anleggsstatus !== Anleggsstatus.OPPHOERT).length) {
		isAllAnleggSelected = true;
	}

	return {
		anlegg,
		meterPointIds: isAllAnleggSelected ? null : anlegg.map(a => a?.maalerpunktId),
		oppHoerteAnleggCount,
		fakturamerker,
		naeringskoder,
		statuses,
		energisoner,
		energisoneMedFlestAnlegg,
	};
}
