import numeral from 'numeral';

numeral.register('locale', 'no', {
	delimiters: {
		thousands: ' ',
		decimal: ',',
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't',
	},
	ordinal: function (number: number) {
		return '.';
	},
	currency: {
		symbol: 'kr',
	},
});

numeral.locale('no');

export default function useNumbers() {
	return (number: number, currency: boolean = false, format: string = null) => {
		let value = numeral(number);

		if (format) {
			return value.format(format);
		}

		if (currency) {
			return value.format('0,0.00');
		}

		return value.format('0,0.00');
	};
}
