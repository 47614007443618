// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeldUtAnleggMutationVariables = Types.Exact<{
  anleggsutmelding: Types.AnleggsutmeldingInput;
  orgid: Types.Scalars['ID'];
}>;


export type MeldUtAnleggMutation = { meldUtAnlegg?: string };

export type HentKundenavnQueryVariables = Types.Exact<{
  kundeId: Types.Scalars['ID'];
}>;


export type HentKundenavnQuery = { hentKundenavn?: string };

export type AnleggslisteFragment = { __typename: 'Bedrift', anlegg?: Array<{ __typename: 'Anlegg', maalernummer?: string, maalerpunktId?: string, longitude?: number, latitude?: number, fakturamerke?: string, leveringspunkt?: string, netteier?: string, anleggsstatus?: Types.Anleggsstatus, naeringskode?: string, naeringskodeID?: string, poststed?: string, postnummer?: string, adresse?: string, tittel?: string, egenAnleggsbeskrivelse?: string, prisOmrade?: string, salgsordrer?: Array<{ __typename: 'Salgsordre', salgsordrenummer?: string, abonnementgruppe?: string, fraDato?: string, avregnetTil?: string, virksomhet?: string, fakturamerke?: string, forbrukskode?: string, tilDato?: string, beskrivelse?: string, status?: string, tariffVersjoner?: Array<{ __typename: 'TariffVersjon', aarskostnad?: number, fradato?: string, produkt?: string, tariff?: string, tildato?: string }> }> }> };

export const AnleggslisteFragmentDoc = gql`
    fragment anleggsliste on Bedrift {
  __typename
  anlegg {
    maalernummer
    maalerpunktId
    longitude
    latitude
    fakturamerke
    leveringspunkt
    netteier
    anleggsstatus
    naeringskode
    naeringskodeID
    poststed
    postnummer
    adresse
    tittel
    egenAnleggsbeskrivelse
    prisOmrade
    __typename
    salgsordrer {
      salgsordrenummer
      abonnementgruppe
      fraDato
      avregnetTil
      virksomhet
      fakturamerke
      forbrukskode
      tilDato
      beskrivelse
      status
      __typename
      tariffVersjoner {
        aarskostnad
        fradato
        aarskostnad
        produkt
        tariff
        tildato
        __typename
      }
    }
  }
}
    `;
export const MeldUtAnleggDocument = gql`
    mutation meldUtAnlegg($anleggsutmelding: AnleggsutmeldingInput!, $orgid: ID!) {
  meldUtAnlegg(anleggsutmelding: $anleggsutmelding, orgid: $orgid)
}
    `;
export type MeldUtAnleggMutationFn = Apollo.MutationFunction<MeldUtAnleggMutation, MeldUtAnleggMutationVariables>;

/**
 * __useMeldUtAnleggMutation__
 *
 * To run a mutation, you first call `useMeldUtAnleggMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMeldUtAnleggMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [meldUtAnleggMutation, { data, loading, error }] = useMeldUtAnleggMutation({
 *   variables: {
 *      anleggsutmelding: // value for 'anleggsutmelding'
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useMeldUtAnleggMutation(baseOptions?: Apollo.MutationHookOptions<MeldUtAnleggMutation, MeldUtAnleggMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MeldUtAnleggMutation, MeldUtAnleggMutationVariables>(MeldUtAnleggDocument, options);
      }
export type MeldUtAnleggMutationHookResult = ReturnType<typeof useMeldUtAnleggMutation>;
export type MeldUtAnleggMutationResult = Apollo.MutationResult<MeldUtAnleggMutation>;
export type MeldUtAnleggMutationOptions = Apollo.BaseMutationOptions<MeldUtAnleggMutation, MeldUtAnleggMutationVariables>;
export const HentKundenavnDocument = gql`
    query hentKundenavn($kundeId: ID!) {
  hentKundenavn(kundeId: $kundeId)
}
    `;

/**
 * __useHentKundenavnQuery__
 *
 * To run a query within a React component, call `useHentKundenavnQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentKundenavnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentKundenavnQuery({
 *   variables: {
 *      kundeId: // value for 'kundeId'
 *   },
 * });
 */
export function useHentKundenavnQuery(baseOptions: Apollo.QueryHookOptions<HentKundenavnQuery, HentKundenavnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentKundenavnQuery, HentKundenavnQueryVariables>(HentKundenavnDocument, options);
      }
export function useHentKundenavnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentKundenavnQuery, HentKundenavnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentKundenavnQuery, HentKundenavnQueryVariables>(HentKundenavnDocument, options);
        }
export type HentKundenavnQueryHookResult = ReturnType<typeof useHentKundenavnQuery>;
export type HentKundenavnLazyQueryHookResult = ReturnType<typeof useHentKundenavnLazyQuery>;
export type HentKundenavnQueryResult = Apollo.QueryResult<HentKundenavnQuery, HentKundenavnQueryVariables>;