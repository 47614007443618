import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Portal from 'web/components/molecules/Portal';

const TooltipWrapper = styled.div`
	position: fixed;
	background: black;
	padding: 4px 8px;
	z-index: 9999;
	border-radius: var(--border-radius-smallest);
	max-width: 320px;
`;

const TooltipText = styled.span`
	color: var(--white);
`;

const TooltipPointer = styled.span`
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid black;
	position: absolute;
	bottom: -10px;
	left: calc(50% - 10px);
`;

interface TooltipProps {
	text: string;
	children?: ReactNode;
	style?: React.CSSProperties;
}
export default function Tooltip({ text, children, ...props }: TooltipProps) {
	const [isOpen, setIsOpen] = useState(false);
	const wrapperElement = useRef(null);
	const tooltipElement = useRef(null);

	useEffect(() => {
		if (!isOpen) {
			return;
		}

		const boundingRect = wrapperElement.current?.getBoundingClientRect();
		tooltipElement.current.style.bottom = window.innerHeight - boundingRect.top + 10 + 'px';
		tooltipElement.current.style.left = boundingRect.left + wrapperElement.current.offsetWidth / 2 - tooltipElement.current.offsetWidth / 2 + 'px';
	}, [isOpen]);

	return (
		<>
			<div
				ref={wrapperElement}
				onFocus={() => setIsOpen(true)}
				onBlur={() => setIsOpen(false)}
				onMouseEnter={() => setIsOpen(true)}
				onMouseLeave={() => setIsOpen(false)}
				{...props}>
				{children}
			</div>
			{isOpen && (
				<Portal>
					<TooltipWrapper ref={tooltipElement}>
						<TooltipText>{text}</TooltipText>
						<TooltipPointer />
					</TooltipWrapper>
				</Portal>
			)}
		</>
	);
}
