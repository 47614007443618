import React, { createContext, ReactNode, useContext } from 'react';
import { Tenant } from 'web/models/Tenant';

const tenantContext = createContext<Tenant>(null);

interface TenantProviderProps {
	tenant: Tenant;
	children: ReactNode;
}
export function TenantProvider({ tenant, children }: TenantProviderProps) {
	return (
		<tenantContext.Provider value={tenant} key={tenant?.orgnummer}>
			{children}
		</tenantContext.Provider>
	);
}

/**
 * Wrapper for getting the current tenant.
 */
export function useTenant() {
	return useContext(tenantContext);
}
