// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentAnleggAarsDetaljerQueryVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
  fraDato?: Types.InputMaybe<Types.Scalars['Date']>;
  maalere?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  tilDato?: Types.InputMaybe<Types.Scalars['Date']>;
  opploesning?: Types.InputMaybe<Types.Opploesning>;
  retning?: Types.InputMaybe<Types.Retning>;
}>;


export type HentAnleggAarsDetaljerQuery = { bedrift?: { id?: string, forbruk?: { opploesning?: Types.Opploesning, retning?: Types.Retning, verdier?: Array<{ mengde?: number, importert?: number, eksportert?: number, fraTid?: string, tilTid?: string }> }, kostnad?: { verdier?: Array<{ fraTid?: string, tilTid?: string, kostnad?: number, status?: string }> } } };


export const HentAnleggAarsDetaljerDocument = gql`
    query hentAnleggAarsDetaljer($orgid: ID!, $fraDato: Date, $maalere: [String], $tilDato: Date, $opploesning: Opploesning, $retning: Retning) {
  bedrift: hentBedrift(orgid: $orgid) {
    id
    forbruk: forbruksdetaljer(
      fraDato: $fraDato
      maalere: $maalere
      tilDato: $tilDato
      opploesning: $opploesning
      retning: $retning
    ) {
      opploesning
      retning
      verdier {
        mengde
        importert
        eksportert
        fraTid
        tilTid
      }
    }
    kostnad: forbruk(fraDato: $fraDato, maalere: $maalere, tilDato: $tilDato) {
      verdier {
        fraTid
        tilTid
        kostnad
        status
      }
    }
  }
}
    `;

/**
 * __useHentAnleggAarsDetaljerQuery__
 *
 * To run a query within a React component, call `useHentAnleggAarsDetaljerQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentAnleggAarsDetaljerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentAnleggAarsDetaljerQuery({
 *   variables: {
 *      orgid: // value for 'orgid'
 *      fraDato: // value for 'fraDato'
 *      maalere: // value for 'maalere'
 *      tilDato: // value for 'tilDato'
 *      opploesning: // value for 'opploesning'
 *      retning: // value for 'retning'
 *   },
 * });
 */
export function useHentAnleggAarsDetaljerQuery(baseOptions: Apollo.QueryHookOptions<HentAnleggAarsDetaljerQuery, HentAnleggAarsDetaljerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentAnleggAarsDetaljerQuery, HentAnleggAarsDetaljerQueryVariables>(HentAnleggAarsDetaljerDocument, options);
      }
export function useHentAnleggAarsDetaljerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentAnleggAarsDetaljerQuery, HentAnleggAarsDetaljerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentAnleggAarsDetaljerQuery, HentAnleggAarsDetaljerQueryVariables>(HentAnleggAarsDetaljerDocument, options);
        }
export type HentAnleggAarsDetaljerQueryHookResult = ReturnType<typeof useHentAnleggAarsDetaljerQuery>;
export type HentAnleggAarsDetaljerLazyQueryHookResult = ReturnType<typeof useHentAnleggAarsDetaljerLazyQuery>;
export type HentAnleggAarsDetaljerQueryResult = Apollo.QueryResult<HentAnleggAarsDetaljerQuery, HentAnleggAarsDetaljerQueryVariables>;