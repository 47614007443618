import { addMonths, endOfToday, format, startOfToday, sub, subMonths, subYears } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import { Anleggsstatus, Bedrift, Innstilling } from 'generated/graphql-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isMobile } from 'web/GlobalStyling';
import { useHasGroup } from 'web/hooks/useAuthorization';
import useBloggSak from 'web/hooks/useBloggSak';
import useFilter, { DEFAULT_FILTER } from 'web/hooks/useFilter';
import useMineBedrifter from 'web/hooks/useMineBedrifter';
import useNumbers from 'web/hooks/useNumbers';
import useProfile, { INFOBOX_AVTALER_ISCLOSED } from 'web/hooks/useProfile';
import useSpotpris from 'web/hooks/useSpotpris';
import { useTenant } from 'web/hooks/useTenant';
import { SegmentCategory, useTracking } from 'web/hooks/useTracking';
import { Opploesninger, getHourRangeString, getIntervalFromOpploesning } from 'web/lib/dates.utils';
import { getSpotprisFilterData } from 'web/lib/filter.utils';
import { energiEnhetTilLabel, kwhTilBesteEnhet, kwhTilBesteVerdi, kwhTilEnhet, kwhTilLabel, roundInsignificantNumbersTozero } from 'web/lib/numbers.utils';
import { Anlegg } from 'web/models/Anlegg';
import HovedsidenTemplate from 'web/templates/HovedsidenTemplate';

function groupBy(array: any[], func: any) {
	return array.reduce(function (rv, x) {
		if (rv[func(x)] === undefined) {
			rv[func(x)] = [x];
		} else {
			rv[func(x)].push(x);
		}

		return rv;
	}, {});
}

export function getColumnsForForbrukYearsGroupedByMonths(forbruk: any, monthsToShow: any) {
	let years = groupBy(forbruk?.verdier ?? [], (value: any) => new Date(value.fraTid).getFullYear());
	let months: any[] = [];

	for (let i = 0; i < 12; i++) {
		months.push({
			name: format(new Date(1, i), 'MMMM', { locale: nbLocale }),
		});
	}

	forbruk?.verdier?.forEach((value: any) => {
		let startDate = new Date(value.fraTid);

		if (!value.fraTid) {
			return;
		}

		months[startDate.getMonth()][startDate.getFullYear()] = [value.importert];
	});

	let monthNameMap: { [key: string]: any } = {};

	months?.forEach((month: any) => {
		monthNameMap[month.name] = month;
	});

	let yearMap: any = {};

	Object.keys(years)?.forEach((year: string) => {
		yearMap[year] = [];

		monthsToShow?.forEach((month: any) => {
			yearMap[year].push(monthNameMap[month]?.[year]?.[0] || 0);
		});
	});

	return Object.keys(yearMap).map(year => {
		return [year, ...yearMap[year]];
	}) as any;
}

export default function HovedsidenPage() {
	const { track, trackGroup, trackPageView } = useTracking();
	trackPageView(SegmentCategory.Hovedsiden);
	const history = useHistory<any>();
	const { brukerprofil, lagreInnstilling } = useProfile();
	const hasStroemAccess = useHasGroup({ group: 'Strøm' });
	const { bedrifter } = useMineBedrifter();

	const [infoBoxAvtalerIsClosed, _setInfoBoxAvtalerIsClosed] = useState(true);
	function setInfoBoxAvtalerIsClosed(infoBoxIsClosed: boolean) {
		_setInfoBoxAvtalerIsClosed(infoBoxIsClosed);
		lagreInnstilling({ navn: INFOBOX_AVTALER_ISCLOSED, verdi: infoBoxIsClosed.toString() });
	}

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		let fromInvitation = params.get('fromInvitation');
		if (fromInvitation) {
			history.push(history.location.pathname);
			setTimeout(() => {
				toast(
					<span>
						Du har fått tilgang til <b>{tenant.navn || 'bedriften'}</b>
					</span>,
					{
						position: toast.POSITION.BOTTOM_CENTER,
					}
				);
			}, 1000);
		}
	}, []);

	useEffect(() => {
		if (brukerprofil) {
			let avtalerSetting: Innstilling;
			brukerprofil?.innstillinger?.forEach((i: Innstilling) => {
				if (i.navn == INFOBOX_AVTALER_ISCLOSED) {
					avtalerSetting = i;
				}
			});
			if (avtalerSetting) {
				_setInfoBoxAvtalerIsClosed(avtalerSetting?.verdi === 'true');
			} else if (!avtalerSetting) {
				_setInfoBoxAvtalerIsClosed(false);
			}
		}
	}, [brukerprofil]);

	const tenant = useTenant();
	const n = useNumbers();
	const [filter, setFilter] = useFilter();
	const soneVelgerData = getSpotprisFilterData(filter, setFilter);
	const { anlegg, totalSaldoUtestaaende, antallForfalteFakturaer, forbrukSisteToAar } = tenant;
	const aktiveAnlegg = anlegg.filter((a: Anlegg) => a.anleggsstatus !== Anleggsstatus.OPPHOERT);

	const monthsToShow = getIntervalFromOpploesning(subMonths(new Date(), 2), addMonths(new Date(), 2), Opploesninger.maned);

	const monthsToShowString: string[] = monthsToShow.map((d: Date) => {
		return format(d, 'MMMM', { locale: nbLocale }).toString();
	});

	let monthForbrukMap: { [key: string]: number } = {};
	forbrukSisteToAar?.verdier?.forEach((forbruk: any) => {
		const date = new Date(forbruk.fraTid);
		monthForbrukMap[format(date, 'yyyy-MM', { locale: nbLocale })] = forbruk.importert;
	});

	const lastMonth = sub(new Date(), { months: 1 });
	const lastMonthLabel = format(lastMonth, 'yyyy-MM', { locale: nbLocale });

	const { varsler, saveVarsel, deleteVarsel, currentSpotpris, loading, ...spotprisBindings } = useSpotpris({
		from: startOfToday(),
		to: endOfToday(),
		energisone: filter.energisone,
		orgId: tenant?.id,
		roundUpToClosestNumber: isMobile() ? 1 : 10,
		doLimitYTick: isMobile() ? false : true,
		hideVarslerLines: isMobile() ? true : false,
	});

	const columns = getColumnsForForbrukYearsGroupedByMonths(forbrukSisteToAar, monthsToShowString);

	const { bloggSak } = useBloggSak();

	let saldoWidgetData = {
		value: totalSaldoUtestaaende?.toLocaleString(['nb-NO', 'fr-CA'], { maximumFractionDigits: 0 }),
		unit: 'kr',
		description: 'til betaling',
		icon: <i className="fa fa-coin" />,
		to: 'faktura',
		onClick: () => {
			track('Button Clicked', { label: 'Til betaling' });
		},
	};

	/*
	if (antallForfalteFakturaer) {
		saldoWidgetData = {
			value: antallForfalteFakturaer?.toString(),
			unit: '',
			description: (antallForfalteFakturaer > 1 ? 'forfalte' : 'forfalt') + ' faktura',
			icon: <i className="fa fa-exclamation" />,
			to: 'faktura',
			onClick: () => {
				setFilter({ ...filter, fakturaFilter: 'overdue' });
				track('Button Clicked', { label: 'Forfalte faktura' });
			},
		};
	}
	*/
	let hoyesteForbruk = 0;

	forbrukSisteToAar?.verdier?.forEach((verdi: any) => {
		if (hoyesteForbruk < verdi.importert) {
			hoyesteForbruk = verdi.importert;
		}
	});

	const enhet = kwhTilBesteEnhet(hoyesteForbruk);

	const thisYearString = new Date().getFullYear();
	const lastYearString = subYears(new Date(), 1).getFullYear();
	const lastLastyearString = subYears(new Date(), 2).getFullYear();

	return (
		<HovedsidenTemplate
			hasStroemAccess={hasStroemAccess}
			infoBoxAvtalerIsClosed={infoBoxAvtalerIsClosed}
			setInfoBoxAvtalerIsClosed={setInfoBoxAvtalerIsClosed}
			bedrifter={bedrifter}
			onClickBedrift={(bedrift: Bedrift) => {
				trackGroup(bedrift.orgnummer, bedrift.navn);
				setFilter(DEFAULT_FILTER);
				history.push(`/${bedrift.id}/hovedsiden/`);
			}}
			bedriftsNavn={tenant.navn}
			article={{ ...bloggSak, href: `https://nte.no/blogg/${bloggSak?.slug}/` }}
			valueInfoPanels={[
				{
					value: n(kwhTilBesteVerdi(monthForbrukMap[lastMonthLabel] || 0)),
					unit: kwhTilLabel(monthForbrukMap[lastMonthLabel] || 0),
					description: `forbruk i ${format(new Date(lastMonthLabel), 'MMMM', { locale: nbLocale }) || 'siste måned'}`,
					icon: <i className="fa fa-calendar" />,
					to: 'forbruk',
					onClick: () => {
						track('Button Clicked', { label: 'Forbruk gjeldende måned' });
					},
				},
				saldoWidgetData,
				{
					value: aktiveAnlegg?.length?.toString(),
					unit: 'anlegg',
					description: 'aktive anlegg',
					icon: <i className="fa fa-building" />,
					to: 'anlegg',
					onClick: () => {
						track('Button Clicked', { label: 'Aktive anlegg' });
					},
				},
			]}
			spotprisData={{ ...spotprisBindings, energisone: filter.energisone, soneVelgerData: soneVelgerData }}
			spotprisInfoPanel={{
				icon: <i className="fa fa-coins" />,
				value: loading ? 'Laster...' : `${n(currentSpotpris, false, '0')} øre`,
				description: `Spotpris kl. ${getHourRangeString(new Date(), 1, 'HH')}`,
				subSubTitle: loading
					? 'Laster...'
					: spotprisBindings.numOfAktiveVarsler === 1
					? '1 aktivt varsel'
					: `${spotprisBindings.numOfAktiveVarsler || 'ingen'} aktive varsler`,
				to: 'spotpris',
				onClick: () => {
					track('Button Clicked', { label: 'Spotpris' });
				},
			}}
			chartData={{
				data: {
					columns: roundInsignificantNumbersTozero(columns, enhet),
					type: 'bar',
					colors: {
						[thisYearString]: 'var(--nte-blaa)',
						[lastYearString]: 'var(--nte-groenn)',
						[lastLastyearString]: 'var(--nte-korall)',
					},
				},
				legend: {
					position: 'right',
				},
				axis: {
					x: {
						type: 'category',
						categories: monthsToShowString,
					},
					y: {
						label: energiEnhetTilLabel(enhet),
						tick: {
							format: (d: number | any) => {
								return n(kwhTilEnhet(d, enhet));
							},
						},
					},
				},
				grid: {
					x: {
						show: true,
					},
					y: {
						show: true,
					},
				},
				bar: {
					width: {
						ratio: 0.9,
					},
					space: 0.1,
				},
				tooltip: {
					format: {
						value: (value: any) => {
							return `${n(kwhTilEnhet(value as number, enhet))} ${enhet}`;
						},
					},
				},
			}}
		/>
	);
}
