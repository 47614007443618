// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetDocumentReadMutationVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  documentId: Types.Scalars['ID'];
  read: Types.Scalars['Boolean'];
}>;


export type SetDocumentReadMutation = { setDocumentRead?: { id?: string, contentType?: string, created?: string, customTitle?: string, description?: string, read: boolean, title?: string, url?: string } };


export const SetDocumentReadDocument = gql`
    mutation setDocumentRead($companyId: ID!, $documentId: ID!, $read: Boolean!) {
  setDocumentRead(companyId: $companyId, documentId: $documentId, read: $read) {
    id
    contentType
    created
    customTitle
    description
    read
    title
    url
  }
}
    `;
export type SetDocumentReadMutationFn = Apollo.MutationFunction<SetDocumentReadMutation, SetDocumentReadMutationVariables>;

/**
 * __useSetDocumentReadMutation__
 *
 * To run a mutation, you first call `useSetDocumentReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDocumentReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDocumentReadMutation, { data, loading, error }] = useSetDocumentReadMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      documentId: // value for 'documentId'
 *      read: // value for 'read'
 *   },
 * });
 */
export function useSetDocumentReadMutation(baseOptions?: Apollo.MutationHookOptions<SetDocumentReadMutation, SetDocumentReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDocumentReadMutation, SetDocumentReadMutationVariables>(SetDocumentReadDocument, options);
      }
export type SetDocumentReadMutationHookResult = ReturnType<typeof useSetDocumentReadMutation>;
export type SetDocumentReadMutationResult = Apollo.MutationResult<SetDocumentReadMutation>;
export type SetDocumentReadMutationOptions = Apollo.BaseMutationOptions<SetDocumentReadMutation, SetDocumentReadMutationVariables>;