import { useMutation, useQuery } from '@apollo/react-hooks';
import React from 'react';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import { SegmentCategory, useTracking } from 'web/hooks/useTracking';
import { HentBedriftForMeldInnAnleggDocument, MeldInnAnleggBatchDocument } from 'web/pages/admin/MeldInnAnleggBatchPage.graphql-gen';
import MeldInnAnleggBatchTemplate from 'web/templates/admin/MeldInnAnleggBatchTemplate';

export class BatchInnmeldingAnlegg {
	constructor(readonly kunde: Kunde, readonly innmeldinger: Innmelding[]) {}
}

export class Kontaktinfo {
	constructor(
		readonly fornavn: string,
		readonly etternavn: string,
		readonly telefonnummer: string,
		readonly epost: string,
		readonly gatenavn: string,
		readonly gatenummer: string,
		readonly postnummer: string,
		readonly poststed: string
	) {}
}

export class Kunde {
	constructor(
		readonly fornavn: string,
		readonly etternavn: string,
		readonly telefonnummer: string,
		readonly epost: string,
		readonly gate: string,
		readonly nummer: string,
		readonly postnummer: string,
		readonly poststed: string
	) {}
}

export class Innmelding {
	constructor(
		readonly tariffId: string,
		readonly fakturamerke: string,
		readonly maalepunkter: string[],
		readonly oppstartdato: string
	) {}
}

export default function MeldInnAnleggBatchPage() {
	const { trackPageView } = useTracking();
	trackPageView(SegmentCategory.MeldInnAnleggBatch);
	const { bedriftId } = useParams<{ bedriftId: string }>();

	const [meldInnAnlegg, { loading: meldInnAnleggLoading, error: meldInnAnleggError }] = useMutation(MeldInnAnleggBatchDocument);
	const handleSubmitForm = async (data: Promise<BatchInnmeldingAnlegg>) => {
		return data.then(innmeldingAnlegg => {
			return meldInnAnlegg({
				variables: {
					innmeldingAnlegg,
					orgid: bedriftId,
				},
			})
				.then(r => {
					return r.data?.batchMeldInnAnlegg;
				})
				.catch(e => {
					return {
						error: e.message,
					};
				});
		});
	};
	const {
		data: { bedrift } = {},
		loading: bedriftLoading,
		error: queryError,
	} = useQuery(HentBedriftForMeldInnAnleggDocument, {
		fetchPolicy: 'cache-and-network',
		variables: {
			orgid: bedriftId,
		},
	});
	const ki = bedrift?.kontaktinformasjon;
	const kontaktinfo =
		ki &&
		new Kontaktinfo(
			ki.kontaktperson.split(' ')[0],
			ki.kontaktperson.split(' ')[1],
			ki.telefonnummer,
			ki.epost,
			ki.adresse.replace(/^(.*?)\s?(\d+.*)$/, '$1'),
			ki.adresse.replace(/^(.*?)\s?(\d+.*)$/, '$2'),
			ki.postnummer,
			ki.poststed
		);

	return (
		<>
			<LoadingOverlay loading={meldInnAnleggLoading || bedriftLoading} text={meldInnAnleggLoading ? 'Melder inn anlegg' : 'Laster inn bedrift'} />
			{kontaktinfo && (
				<MeldInnAnleggBatchTemplate
					tariffer={bedrift.godkjenteTariffer}
					submitForm={handleSubmitForm}
					bedriftsnavn={bedrift?.navn}
					kontaktinfo={kontaktinfo}
				/>
			)}
		</>
	);
}
