import {
	addDays,
	addHours,
	addMonths,
	addYears,
	eachDayOfInterval,
	eachHourOfInterval,
	eachMonthOfInterval,
	endOfDay,
	endOfMonth,
	endOfYear,
	format,
	isSameHour,
	startOfDay,
	startOfMonth,
	startOfYear,
} from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import { Opploesning as Opploesninger } from 'generated/graphql-types';

export const months = ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'];

export { Opploesninger };

export interface Periode {
	fra: Date;
	til: Date;
}

export function opploesningToString(opploesning: Opploesninger, isPlural?: boolean) {
	switch (opploesning) {
		case Opploesninger.maned:
			return isPlural ? 'måneder' : 'måned';
		case Opploesninger.dag:
			return isPlural ? 'dager' : 'dag';
		case Opploesninger.time:
			return isPlural ? 'timer' : 'time';
		default:
			return isPlural ? 'måneder' : 'måned';
	}
}

export function getPeriodeFromOpploesning(dato: Date, opploesning: Opploesninger) {
	switch (opploesning) {
		case Opploesninger.maned:
			return {
				fra: startOfYear(dato),
				til: endOfYear(dato),
			};
		case Opploesninger.dag:
			return {
				fra: startOfMonth(dato),
				til: endOfMonth(dato),
			};
		case Opploesninger.time:
			return {
				fra: startOfDay(dato),
				til: endOfDay(dato),
			};
		default:
			return {
				fra: startOfYear(dato),
				til: endOfYear(dato),
			};
	}
}

export function getIntervalFromOpploesning(fraDato: Date, tilDato: Date, opploesning: Opploesninger) {
	let timeInterval;
	switch (opploesning) {
		case Opploesninger.maned:
			timeInterval = eachMonthOfInterval({
				start: fraDato,
				end: tilDato,
			});
			break;
		case Opploesninger.dag:
			timeInterval = eachDayOfInterval({
				start: fraDato,
				end: tilDato,
			});
			break;
		case Opploesninger.time:
			timeInterval = eachHourOfInterval({
				start: fraDato,
				end: tilDato,
			});
			break;
		default:
			timeInterval = eachMonthOfInterval({
				start: fraDato,
				end: tilDato,
			});
			break;
	}
	return timeInterval;
}

export function getNesteOpploesning(opploesning: Opploesninger) {
	switch (opploesning) {
		case Opploesninger.maned:
			return Opploesninger.dag;
		default:
			return Opploesninger.time;
	}
}

//https://stackoverflow.com/questions/37069186/calculate-working-days-between-two-dates-in-javascript-excepts-holidays/37069478
export function getBusinessDatesCount(startDate: Date, endDate: Date) {
	let count = 0;
	const curDate = new Date(startDate.getTime());
	while (curDate <= endDate) {
		const dayOfWeek = curDate.getDay();
		if (!(dayOfWeek in [0, 6])) {
			count++;
		}
		curDate.setDate(curDate.getDate() + 1);
	}
	return count;
}

// Based on Method #2 in this https://www.geeksforgeeks.org/python-make-a-list-of-intervals-with-sequential-numbers/
export function dateListToIntervalString(dateList: Date[]) {
	let intervalString = '';
	let startDate = dateList[0];
	let previousDate = dateList[0];

	for (let i = 1; i < dateList?.length; i++) {
		const date = dateList[i];
		const isFinalIteration = i == dateList?.length - 1;
		if (isSameHour(date, addHours(previousDate, 1)) && !isFinalIteration) {
			previousDate = date;
		} else {
			intervalString +=
				format(startDate, 'HH:mm', { locale: nbLocale }) +
				'-' +
				format(addHours(previousDate, isFinalIteration ? 2 : 1), 'HH:mm', { locale: nbLocale }) +
				(isFinalIteration ? '' : ', ');
			previousDate = date;
			startDate = date;
		}
	}
	return intervalString == '00:00-00:00' ? 'hele dagen' : intervalString;
}

export function getHourRangeString(date: Date, numberOfHours: number = 1, formatString: string = 'HH:mm') {
	return `${format(date, formatString, { locale: nbLocale })}-${format(addHours(date, numberOfHours), formatString, { locale: nbLocale })}`;
}

export function toLocalDate(date: Date | string) {
	let dateToFormat = date;
	if (typeof date == 'string') {
		dateToFormat = new Date(date);
	}
	// Possible formats are 'no-NO', 'nb-NO', 'nn-NO', if undefined will fallback to browsers local standard
	return dateToFormat.toLocaleString(undefined, { dateStyle: 'short' });
}

export function getDatoPeriodeFormat(opploesning: Opploesninger) {
	switch (opploesning) {
		case Opploesninger.maned:
			return 'yyyy';
		case Opploesninger.dag:
			return 'MMMM yyyy';
		case Opploesninger.time:
			return 'd. MMMM yyyy';
		default:
			return 'd. MMMM yyyy';
	}
}

export function getDatoFormat(opploesning: Opploesninger) {
	switch (opploesning) {
		case Opploesninger.maned:
			return 'MMMM';
		case Opploesninger.dag:
			return 'd.';
		case Opploesninger.time:
			return 'H';
		default:
			return 'd.';
	}
}

export function getShortDatoFormat(opploesning: Opploesninger) {
	switch (opploesning) {
		case Opploesninger.maned:
			return 'MMM';
		case Opploesninger.dag:
			return 'd.';
		case Opploesninger.time:
			return 'H';
		default:
			return 'd.';
	}
}

export function getForbrukTooltipFormattedDate(date: Date, opploesning: Opploesninger) {
	switch (opploesning) {
		case Opploesninger.maned:
			return `${format(date, 'MMMM yyyy', { locale: nbLocale })}`;
		case Opploesninger.dag:
			return `${format(date, 'd. MMMM', { locale: nbLocale })}`;
		case Opploesninger.time:
			return `kl. ${format(date, 'H', { locale: nbLocale })}-${format(addHours(date, 1), 'H', { locale: nbLocale })}`;
		default:
			return `${format(date, 'd. MMMM', { locale: nbLocale })}`;
	}
}

export function getIndexDatoFormat(opploesning: Opploesninger) {
	switch (opploesning) {
		case Opploesninger.maned:
			return 'yyyy-MMMM';
		case Opploesninger.dag:
			return 'yyyy.MM.dd';
		case Opploesninger.time:
			return 'yyyy.MM.dd.HH';
		default:
			return 'yyyy.MM.dd';
	}
}

export function getPeriodeDatoFormat(opploesning: Opploesninger) {
	switch (opploesning) {
		case Opploesninger.dag:
			return 'MMMM yyyy';
		case Opploesninger.time:
			return 'd. MMMM yyyy';
		default:
			return 'dd.MM.yyyy';
	}
}

export function addTimeFraOpploesning(date: Date, toAdd: number = 0, opploesning: Opploesninger) {
	switch (opploesning) {
		case Opploesninger.maned:
			return addYears(date, toAdd);
		case Opploesninger.dag:
			return addMonths(date, toAdd);
		case Opploesninger.time:
			return addDays(date, toAdd);
		default:
			return addDays(date, toAdd);
	}
}

export interface GrafDataElement {
	importert?: number;
	eksportert?: number;
	fraTid?: string;
	tilTid?: string;
	kostnad?: number;
}

interface fyllInnManglendeVerdierProps {
	data: GrafDataElement[];
	fra: Date;
	til: Date;
}

interface fyllInnManglendeVerdierMedOpploesningProps {
	data: GrafDataElement[];
	opploesning: Opploesninger;
	fra: Date;
	til: Date;
}

export function fyllInnManglendeVerdierKostnad({ data, fra, til }: fyllInnManglendeVerdierProps): GrafDataElement[] {
	let valueMap: { [key: string]: GrafDataElement } = {};

	let datoer: Date[] = [];
	const datoformat = getIndexDatoFormat(Opploesninger.maned);

	data?.forEach(element => {
		const fraDato = new Date(element.fraTid);
		valueMap[format(fraDato, datoformat, { locale: nbLocale })] = element;
	});

	datoer = eachMonthOfInterval({
		start: fra,
		end: til,
	});

	let verdier: GrafDataElement[] = [];

	datoer?.forEach(dato => {
		verdier.push({
			fraTid: dato.toString(),
			tilTid: '',
			kostnad: valueMap[format(dato, datoformat, { locale: nbLocale })]?.kostnad || 0,
		});
	});

	return verdier;
}

export function fyllInnManglendeVerdierForbruk({ data, opploesning, fra, til }: fyllInnManglendeVerdierMedOpploesningProps): GrafDataElement[] {
	let valueMap: { [key: string]: GrafDataElement } = {};

	let datoer: Date[] = [];
	const datoformat = getIndexDatoFormat(opploesning);

	data?.forEach(element => {
		const fraDato = new Date(element.fraTid);

		valueMap[format(fraDato, datoformat, { locale: nbLocale })] = element;
	});

	datoer = getIntervalFromOpploesning(fra, til, opploesning);

	let verdier: GrafDataElement[] = [];

	datoer?.forEach(dato => {
		verdier.push({
			importert: valueMap[format(dato, datoformat, { locale: nbLocale })]?.importert || 0,
			eksportert: valueMap[format(dato, datoformat, { locale: nbLocale })]?.eksportert || 0,
			fraTid: dato.toString(),
			tilTid: '',
		});
	});

	return verdier;
}
