// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KanEtableresQueryVariables = Types.Exact<{
  kundenummer: Types.Scalars['Int'];
  orgnummer: Types.Scalars['ID'];
}>;


export type KanEtableresQuery = { kunde?: { epost?: string, kundenummer?: string, navn?: string, orgnummer?: string, bedrift?: { id?: string, navn?: string, orgnummer?: string } } };

export type EtablerBedriftMutationVariables = Types.Exact<{
  body: Types.NyetableringInput;
}>;


export type EtablerBedriftMutation = { etablerBedrift?: { bedrift: string, epost: string, kundenummer?: number, orgnummer: string } };


export const KanEtableresDocument = gql`
    query kanEtableres($kundenummer: Int!, $orgnummer: ID!) {
  kunde: kanEtableres(kundenummer: $kundenummer, orgnummer: $orgnummer) {
    bedrift {
      id
      navn
      orgnummer
    }
    epost
    kundenummer
    navn
    orgnummer
  }
}
    `;

/**
 * __useKanEtableresQuery__
 *
 * To run a query within a React component, call `useKanEtableresQuery` and pass it any options that fit your needs.
 * When your component renders, `useKanEtableresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKanEtableresQuery({
 *   variables: {
 *      kundenummer: // value for 'kundenummer'
 *      orgnummer: // value for 'orgnummer'
 *   },
 * });
 */
export function useKanEtableresQuery(baseOptions: Apollo.QueryHookOptions<KanEtableresQuery, KanEtableresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KanEtableresQuery, KanEtableresQueryVariables>(KanEtableresDocument, options);
      }
export function useKanEtableresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KanEtableresQuery, KanEtableresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KanEtableresQuery, KanEtableresQueryVariables>(KanEtableresDocument, options);
        }
export type KanEtableresQueryHookResult = ReturnType<typeof useKanEtableresQuery>;
export type KanEtableresLazyQueryHookResult = ReturnType<typeof useKanEtableresLazyQuery>;
export type KanEtableresQueryResult = Apollo.QueryResult<KanEtableresQuery, KanEtableresQueryVariables>;
export const EtablerBedriftDocument = gql`
    mutation etablerBedrift($body: NyetableringInput!) {
  etablerBedrift(body: $body) {
    bedrift
    epost
    kundenummer
    orgnummer
  }
}
    `;
export type EtablerBedriftMutationFn = Apollo.MutationFunction<EtablerBedriftMutation, EtablerBedriftMutationVariables>;

/**
 * __useEtablerBedriftMutation__
 *
 * To run a mutation, you first call `useEtablerBedriftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEtablerBedriftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [etablerBedriftMutation, { data, loading, error }] = useEtablerBedriftMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useEtablerBedriftMutation(baseOptions?: Apollo.MutationHookOptions<EtablerBedriftMutation, EtablerBedriftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EtablerBedriftMutation, EtablerBedriftMutationVariables>(EtablerBedriftDocument, options);
      }
export type EtablerBedriftMutationHookResult = ReturnType<typeof useEtablerBedriftMutation>;
export type EtablerBedriftMutationResult = Apollo.MutationResult<EtablerBedriftMutation>;
export type EtablerBedriftMutationOptions = Apollo.BaseMutationOptions<EtablerBedriftMutation, EtablerBedriftMutationVariables>;