import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Push from 'web/components/atoms/Push';
import Logo from 'web/components/atoms/svgs/Logo';
import { env } from 'web/env';
import { device } from 'web/GlobalStyling';

const FooterWrapper = styled.div`
	max-width: 1200px;
	width: 100%;
	clear: both;
	margin: 2rem auto 2rem auto;
	@media ${device.mobile} {
		margin: 1rem auto 1rem auto;
	}
`;

const FooterLine = styled.hr`
	border-top: 2px solid var(--nte-border-color);
	margin-bottom: 2rem;
	@media ${device.mobile} {
		margin-bottom: 1rem;
	}
`;

const FooterRow = styled.div`
	width: 100%;
	clear: both;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;
`;

const StyledLogo = styled(Logo)`
	width: 125px;
	@media ${device.mobile} {
		width: 70px;
	}
`;
const FooterTextWrapper = styled.div`
	text-align: center;
	@media ${device.mobile} {
	}
`;

export interface FooterProps {
	style?: React.CSSProperties;
	onClickLogout?: () => void;
	authenticated?: boolean;
}

export default function Footer({ style, authenticated, onClickLogout }: FooterProps) {
	const { t } = useTranslation('generic');

	return (
		<FooterWrapper style={style}>
			<FooterLine />
			<FooterRow>
				<StyledLogo />
				<FooterTextWrapper>
					<Push height="15px" />
					<span>{t('Footer.copyright')}</span>
					<br />
					<a href={env.REACT_APP_PERSONVERN_URL} target="blank">
						{t('Footer.privacy')}
					</a>
					<span> | </span>
					<a href={env.REACT_APP_AVTALEVILKAAR} target="blank">
						{t('Footer.conditions')}
					</a>

					{authenticated && (
						<>
							<br />
							<a
								href="#"
								onClick={e => {
									e.preventDefault();

									onClickLogout?.();
								}}>
								{t('Footer.logout')}
							</a>
						</>
					)}
				</FooterTextWrapper>
			</FooterRow>
		</FooterWrapper>
	);
}

export function FooterWithoutLinks({ style }: FooterProps) {
	const { t } = useTranslation('generic');

	return (
		<FooterWrapper style={style}>
			<FooterLine />
			<FooterRow>
				<StyledLogo />
				<FooterTextWrapper>
					<Push height="15px" />
					<span>{t('Footer.copyright')}</span>
				</FooterTextWrapper>
			</FooterRow>
		</FooterWrapper>
	);
}
