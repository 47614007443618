import React from 'react';
import useMineBedrifter from 'web/hooks/useMineBedrifter';
import { SegmentCategory, useTracking } from 'web/hooks/useTracking';
import MineBedrifterTemplate from 'web/templates/MineBedrifterTemplate';

export default function MineBedrifterPage() {
	const { trackPageView } = useTracking();
	trackPageView(SegmentCategory.MineBedrifter);
	const { bedrifter, loading, error } = useMineBedrifter();

	return <MineBedrifterTemplate bedrifter={bedrifter} loading={loading} error={error} />;
}
