import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ClearButton from 'web/components/atoms/ClearButton';
import { device, isMobile } from 'web/GlobalStyling';
import useOnClickOutside from 'web/hooks/useOnClickOutside';

interface PageTitleProps {
	isCenter?: boolean;
}
const PageTitle = styled.h1<PageTitleProps>`
	justify-content: ${props => (props.isCenter ? 'center' : 'normal')};

	align-items: center;
	display: flex;
	padding: 0;
	margin: 0;

	line-height: 1.2;
	font-size: 2rem;
	font-weight: bold;
	color: var(--nte-sort);

	@media ${device.mobile} {
		font-size: 1.5rem;
		font-size: 1.5rem;
		font-weight: 700;
	}
`;

export const PageTitleButton = styled(ClearButton)`
	line-height: 1;
	color: var(--nte-blaa-logo);
	padding: 0.8rem;
	i {
		margin-right: 0.5rem;
	}

	@media ${device.tablet} {
		padding: 0.5rem;
	}
	@media ${device.mobile} {
		border-radius: 5rem;
		i {
			margin-right: 0.2rem;
		}
	}
`;

export default PageTitle;

//#region styling
const EditableTitleTextWrapper = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: max-content max-content;
	gap: 0.5rem;
	width: 100%;
	max-width: 100%;
	font-size: 2rem;
	font-weight: bold;
	color: var(--nte-sort);
	@media ${device.tablet} {
		font-size: 1.5rem;
	}
`;
const StyledInput = styled.textarea`
	grid-column: 1;
	resize: none;
	max-width: 100%;
	width: 100%;
	height: 100%;
	margin: 0;
	overflow: hidden;
	padding: 0;
	line-height: inherit;
	overflow-wrap: break-word;
	border: none;
	font-size: inherit;
	font-weight: inherit;
	white-space: pre-wrap;
	color: inherit;
	pointer-events: ${props => (props.readOnly ? 'none' : 'default')};
`;
const DesktopButtonContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: 0.5rem;
	@media ${device.tablet} {
		display: none;
	}
`;
const MobileButtonContent = styled.div`
	display: none;
	i {
		color: inherit;
	}
	@media ${device.tablet} {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
`;

//#endregion

interface PageTitleEditableProps {
	value?: string;
	style?: React.CSSProperties;
	titleTextStyle?: React.CSSProperties;
	inputStyle?: React.CSSProperties;
	editButtonStyle?: React.CSSProperties;
	onChanged?: (value: string, revert: (value: string) => void) => void;
}
export function PageTitleEditable({ value = '', style, onChanged = (value, revert) => {} }: PageTitleEditableProps) {
	const [isEditing, setIsEditing] = useState(false);
	const inputElement = useRef(null);
	const containerRef = useRef(null);
	const storedValue = useRef(value);
	const [currentValue, setCurrentValue] = useState(value);
	const [maxCols, setMaxCols] = useState<number | undefined>();
	const [rows, setRows] = useState(1);
	const [cols, setCols] = useState(20);
	const MINCOLSIZE = 10;

	useEffect(() => {
		const calculateMaxCols = () => {
			const rect = containerRef?.current.getBoundingClientRect();
			const width = rect.width && Math.floor(rect.width);
			if (isMobile()) {
				setMaxCols(Math.floor(width / 17));
			} else {
				setMaxCols(Math.floor(width / 20));
			}
		};
		calculateMaxCols();
		window.addEventListener('resize', calculateMaxCols);
		return () => {
			window.removeEventListener('resize', calculateMaxCols);
		};
	}, []);

	useEffect(() => {
		if (!maxCols) {
			return;
		}
		const element = inputElement.current;
		let maxTextLength = element.value?.length;
		const faktor = 1.25;
		if (maxTextLength > 0 && maxCols && maxTextLength > MINCOLSIZE) {
			if (maxTextLength > maxCols) {
				const newRows = Math.ceil(maxTextLength / maxCols + 0.4);
				const newCols = Math.floor(maxCols);
				setRows(newRows);
				setCols(newCols);
			} else {
				const newCols = Math.floor(maxTextLength * faktor);
				setRows(1);
				setCols(newCols);
			}
		} else {
			setRows(1);
			setCols(MINCOLSIZE);
		}
	}, [currentValue, maxCols]);

	function save() {
		storedValue.current = currentValue;
		if (currentValue && isEditing) {
			onChanged(currentValue, () => {
				setCurrentValue(value);
				storedValue.current = value;
			});
		}
		setIsEditing(false);
	}

	useOnClickOutside({
		ref: containerRef,
		handler: () => {
			inputElement?.current?.blur();
			setIsEditing(false);
			setCurrentValue(storedValue.current);
		},
	});

	return (
		<EditableTitleTextWrapper style={style} ref={containerRef}>
			<StyledInput
				readOnly={isEditing ? false : true}
				value={currentValue}
				ref={inputElement}
				onChange={e => setCurrentValue(e.target.value)}
				rows={rows}
				cols={cols}
				onKeyDown={e => {
					switch (e.key) {
						case 'Escape':
							inputElement?.current?.blur();
							setIsEditing(false);
							setCurrentValue(storedValue.current);
							break;
						case 'Enter':
						case 'Tab':
							inputElement?.current?.blur();
							save();
							break;
						default:
							break;
					}
				}}
			/>
			{(isEditing && (
				<PageTitleButton
					type="button"
					style={{ gridColumn: '2', alignSelf: 'baseline' }}
					onClick={() => {
						setTimeout(() => {
							save();
						}, 1);
					}}>
					<DesktopButtonContent>
						<i className="fas fa-check" />
						Lagre navn
					</DesktopButtonContent>
					<MobileButtonContent>
						<i className="fas fa-check" />
					</MobileButtonContent>
				</PageTitleButton>
			)) || (
				<PageTitleButton
					type="button"
					style={{ gridColumn: '2', alignSelf: 'baseline' }}
					onClick={() => {
						setIsEditing(true);
						setTimeout(() => {
							inputElement.current?.focus();
						}, 1);
					}}>
					<DesktopButtonContent>
						<i className="fas fa-pencil" />
						Rediger navn
					</DesktopButtonContent>
					<MobileButtonContent>
						<i className="fas fa-pencil" />
					</MobileButtonContent>
				</PageTitleButton>
			)}
		</EditableTitleTextWrapper>
	);
}
