import { addMinutes, format } from 'date-fns';
import { Retning } from 'generated/graphql-types';
import { LatLngTuple } from 'leaflet';
import React, { useState } from 'react';
import { useMeldUtAnleggModal } from 'web/components/organisms/modals/MeldUtAnleggModal';
import useForbruksTopp from 'web/hooks/SingleAnlegg/useForbruksTopp';
import useKategorier from 'web/hooks/SingleAnlegg/useKategorier';
import useAddressSearch from 'web/hooks/useAddressSearch';
import { useTenant } from 'web/hooks/useTenant';
import { Opploesninger, getPeriodeFromOpploesning } from 'web/lib/dates.utils';
import { Anlegg } from 'web/models/Anlegg';
import { useHentAnleggAarsDetaljerQuery } from 'web/pages/SingleAnleggTabs/OversiktTab.graphql-gen';
import OversiktTabTemplate from 'web/templates/singleAnlegg/OversiktTabTemplate';

export function determineLatLng(anlegg: any): LatLngTuple | null {
	const {
		loading,
		error,
		data: adressSearchData,
	} = useAddressSearch(
		{ sok: anlegg.adresse, postnummer: anlegg.postnummer },
		{ skip: !anlegg?.maalerpunktId || (anlegg.latitude && anlegg.longitude) }
	) as any;
	const punkt = adressSearchData?.adresser?.[0]?.representasjonspunkt ?? null;

	if (anlegg.latitude && anlegg.longitude) {
		return [anlegg.latitude, anlegg.longitude];
	}
	if (punkt && punkt.lat && punkt.lon) {
		return [punkt.lat, punkt.lon];
	}
	return null;
}

interface OversiktTabProps {
	anlegg: Anlegg;
}

export default function OversiktTab({ anlegg }: OversiktTabProps) {
	const tenant = useTenant();
	const [forbruksToppDate, setForbruksToppDate] = useState(new Date());

	let anleggAarsDetaljerTotaltForbruk = 0;
	let anleggAarsDetaljerTotaltKostnad = 0;

	const anleggAarsDetaljerDatoRange = getPeriodeFromOpploesning(new Date(), Opploesninger.maned);
	const anleggAarsDetaljer = useHentAnleggAarsDetaljerQuery({
		skip: !anlegg?.maalerpunktId,
		variables: {
			orgid: tenant.id,
			maalere: [anlegg?.maalerpunktId],
			fraDato: format(anleggAarsDetaljerDatoRange.fra, 'yyyy-MM-dd'),
			tilDato: format(addMinutes(anleggAarsDetaljerDatoRange.til, 1), 'yyyy-MM-dd'),
			opploesning: Opploesninger.maned,
			retning: Retning.forbruk,
		},
	});

	const singleAnleggForbruksToppGrafData = useForbruksTopp({
		maalere: [anlegg?.maalerpunktId],
		selectedDate: forbruksToppDate,
		useShortXLabel: true,
		graphZoom: true,
	});

	const singleAnleggForbruksTopp = {
		...singleAnleggForbruksToppGrafData,
		datePickerData: {
			selectedDate: forbruksToppDate,
			onChange: setForbruksToppDate,
			opploesning: Opploesninger.dag,
		},
	};

	if (anleggAarsDetaljer?.data?.bedrift) {
		anleggAarsDetaljer.data.bedrift?.forbruk?.verdier?.forEach((forbruk: any) => {
			anleggAarsDetaljerTotaltForbruk += forbruk?.importert || 0;
		});

		anleggAarsDetaljer.data.bedrift?.kostnad?.verdier?.forEach((kostnad: any) => {
			anleggAarsDetaljerTotaltKostnad += kostnad?.kostnad || 0;
		});
	}

	const anleggLokasjon = determineLatLng(anlegg);

	const { kategorierOptions, kategorierValues, slettKategoriFraMaaler, addKategoriTilMaaler, lagreKategori } = useKategorier({
		maalerpunktId: anlegg?.maalerpunktId,
	});

	const { open: openMeldUtAnleggModal } = useMeldUtAnleggModal();

	return (
		<OversiktTabTemplate
			anlegg={anlegg}
			anleggDetails={{ kostnadForNow: anleggAarsDetaljerTotaltKostnad, forbrukForNow: anleggAarsDetaljerTotaltForbruk }}
			anleggDetailsLoading={anleggAarsDetaljer.loading}
			anleggLokasjon={anleggLokasjon}
			kategorierOptions={kategorierOptions}
			kategorierValues={kategorierValues}
			singleAnleggForbruksTopp={singleAnleggForbruksTopp}
			slettKategoriFraMaaler={async tag => {
				await slettKategoriFraMaaler({ kategoriId: tag.value, maalerId: anlegg?.maalerpunktId });
			}}
			addKategoriTilMaaler={async tag => {
				await addKategoriTilMaaler({ kategoriId: tag.value, maalerId: anlegg?.maalerpunktId });
			}}
			lagreKategori={async name => {
				if (name?.trim()?.length == 0) {
					return;
				}
				await lagreKategori({ navn: name, orgnummer: tenant?.orgnummer, maalerpunkter: [anlegg?.maalerpunktId] }, anlegg?.maalerpunktId);
			}}
			onClickMeldUtAnlegg={() =>
				openMeldUtAnleggModal({
					bedriftId: tenant?.id,
					anlegg: anlegg,
					callback: () => {
						tenant.refetchTenant();
					},
				})
			}
		/>
	);
}
