import useProfile from 'web/hooks/useProfile';

interface useKategorierProps {
	maalerpunktId: string;
}
export default function useKategorier({ maalerpunktId }: useKategorierProps) {
	const { kategorier, slettKategoriFraMaaler, addKategoriTilMaaler, lagreKategori } = useProfile();

	let kategorierValues = [];
	let kategorierOptions = [];

	for (let i = 0; i < kategorier?.length ?? 0; i++) {
		let malerpunktIndex = kategorier[i]?.maalerpunkter?.indexOf(maalerpunktId) ?? -1;

		if (malerpunktIndex > -1) {
			kategorierValues.push(kategorier[i]);
		} else {
			kategorierOptions.push(kategorier[i]);
		}
	}

	return {
		kategorierOptions: kategorierOptions.map(kategori => ({
			value: kategori.id,
			label: kategori.navn,
		})),
		kategorierValues: kategorierValues.map(kategori => ({
			value: kategori.id,
			label: kategori.navn,
		})),
		slettKategoriFraMaaler,
		addKategoriTilMaaler,
		lagreKategori,
	};
}
