import { Faktura } from 'generated/graphql-types';
import React from 'react';
import FakturaIcon from 'web/components/molecules/FakturaIcon';
import { getSingleFakturaLink } from 'web/components/molecules/FakturaListElement';
import LoadingElement from 'web/components/molecules/LoadingElement';
import { toLocalDate } from 'web/lib/dates.utils';
import { shouldGetUnknownStatus } from 'web/lib/faktura.utils';
import { DetailsLinkButton } from 'web/templates/AnleggTemplate';

interface FakturaListDesktopProps {
	loading?: boolean;
	loadingFetchMore?: boolean;
	fakturaer: Faktura[];
	isTabView?: boolean;
}
export default function FakturaListDesktop({ loading, loadingFetchMore, fakturaer, isTabView }: FakturaListDesktopProps) {
	const loadingDateChange = loading && !loadingFetchMore;

	return (
		<>
			<table>
				<thead>
					<tr>
						<td className="center">Status</td>
						<td>Faktureringsdato</td>
						<td>Forfallsdato</td>
						<td className="center">Fakturanummer</td>
						<td className="right">Fakturert</td>
						<td className="right">Utestående</td>
						<td>
							<div style={{ width: '72px' }} />
						</td>
					</tr>
				</thead>
				<tbody>
					{(!fakturaer || fakturaer?.length === 0) && !loading && (
						<tr>
							<td className="center" colSpan={7}>
								{(!isTabView && (
									<>
										Fant ingen fakturaer,
										<br />
										prøv et annet filter eller en annen periode.
									</>
								)) || <>Fant ingen fakturaer.</>}
							</td>
						</tr>
					)}
					{!loadingDateChange &&
						fakturaer?.map(f => (
							<tr key={f.guid}>
								<td className="center">
									<FakturaIcon faktura={f} style={{ fontSize: '1.125rem' }} />
								</td>
								<td>{toLocalDate(f.fakturadato)}</td>
								<td>{toLocalDate(f.forfallsdato)}</td>
								<td className="center">{f.fakturanummer}</td>
								<td className="right">{f.beloepInklMoms?.toLocaleString(['nb-NO', 'fr-CA'], { minimumFractionDigits: 2 })} kr</td>
								<td className="right">
									{!shouldGetUnknownStatus(f) ? f.saldo?.toLocaleString(['nb-NO', 'fr-CA'], { minimumFractionDigits: 2 }) + ' kr' : ''}
								</td>
								<td className="right">
									<DetailsLinkButton to={getSingleFakturaLink(f.guid)}>
										Detaljer <i className="fa fa-chevron-right" />
									</DetailsLinkButton>
								</td>
							</tr>
						))}
				</tbody>
			</table>
			<LoadingElement loading={loading} text={'Laster inn faktura'} />
		</>
	);
}
