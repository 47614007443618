import React from 'react';
import styled from 'styled-components';
import { Direction, Flex, Justify } from 'web/components/atoms/Flex';
import Panel, { PanelProps } from 'web/components/atoms/Panel';
import { device } from 'web/GlobalStyling';

const Wrapper = styled(Panel)`
	display: flex;
	gap: 1.5rem;
	@media ${device.mobile} {
		flex-direction: column;
		align-items: center;
		text-align: center;
		gap: 0.5rem;
		padding: 1.25rem 0.625rem 1.5rem;
		border-radius: var(--border-radius-large);
	}
`;

const ValueWrapper = styled.div`
	font-weight: bold;
	font-size: 2rem;
	color: var(--nte-blaa-mork);
	margin-bottom: 0.5rem;
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
	gap: 0.4rem;
	justify-content: start;
	align-items: center;
	@media ${device.tablet} {
		font-size: 1.5rem;
		margin-top: 0.3rem;
	}
	@media ${device.mobile} {
		justify-content: center;
		gap: 0.2rem;
		align-self: center;
		font-size: 1rem;
		margin-top: 0.3rem;
	}
`;

const Value = styled.span`
	white-space: nowrap;
`;

const Unit = styled.span`
	font-weight: normal;
	font-size: 1.25rem;

	@media ${device.mobile} {
		font-size: 0.75rem;
	}
`;

const Icon = styled.div`
	font-weight: 900;
	font-size: 2rem;
	color: var(--nte-blaa);
	align-items: center;

	@media ${device.mobile} {
		display: flex;
		justify-content: center;
		font-weight: normal;
		font-size: 1.5rem;
	}
	i {
		margin: auto;
	}
`;

const Description = styled.div`
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2;
	color: var(--nte-graa-mork);
	@media ${device.mobile} {
		font-weight: normal;
		font-size: 0.75rem;
		line-height: 0.875rem;
		color: var(--nte-graa-medium);
	}
`;

export interface ValueInfoPanelProps extends PanelProps {
	value: string;
	unit?: string;
	description: string;
	icon: any;
}
export function ValueInfoPanel(props: ValueInfoPanelProps) {
	return (
		<Wrapper style={props.style} className={props.className} onClick={props.onClick as any} to={props.to} withBorder={props.withBorder}>
			<Icon>{props.icon}</Icon>
			<Flex direction={Direction.column} alignItems={Justify.start} tablet={{ alignItems: Justify.center }}>
				<ValueWrapper>
					<Value>{props.value}</Value>
					{props.unit && <Unit>{props.unit}</Unit>}
				</ValueWrapper>

				<Description>{props.description}</Description>
			</Flex>
		</Wrapper>
	);
}
