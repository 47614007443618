import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RedigerMeldingPage from 'web/pages/admin/EditMeldingPage';
import KundeListePage from 'web/pages/admin/KundeListePage';
import MeldingerPage from 'web/pages/admin/MeldingerPage';
import MeldInnAnleggBatchPage from 'web/pages/admin/MeldInnAnleggBatchPage';
import MeldingerNyPage from 'web/pages/admin/NyMeldingPage';
import SingleMeldingPage from 'web/pages/admin/SingleMeldingPage';
import AdminPage from 'web/pages/AdminPage';

export default function AdminRoutes() {
	return (
		<>
			<Route path="/admin/" exact>
				<Redirect to="/admin/kunder/" />
			</Route>
			<Route path="/admin/kunder/" exact>
				<KundeListePage />
			</Route>

			<Route path={['/admin/meldinger/', '/admin/meldinger/ny']} exact>
				<MeldingerPage />
			</Route>
			<Switch>
				<Route path="/admin/meldinger/ny" exact>
					<MeldingerNyPage backUrl="../" />
				</Route>
				<Route path="/admin/meldinger/:meldingId">
					<SingleMeldingPage />
				</Route>
			</Switch>
			<Route path="/admin/meldinger/:meldingId/rediger/">
				<RedigerMeldingPage backUrl="../" />
			</Route>

			<Route path="/admin/kunder/:bedriftId/" exact>
				<AdminPage isSysAdminPage={true} />
			</Route>
			<Route path="/admin/kunder/:bedriftId/batchmeldinnanlegg/" exact>
				<MeldInnAnleggBatchPage />
			</Route>
		</>
	);
}
