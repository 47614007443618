// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeldInnAnleggMutationVariables = Types.Exact<{
  anleggsinnmelding: Types.AnleggsinnmeldingInput;
  orgid: Types.Scalars['ID'];
}>;


export type MeldInnAnleggMutation = { meldInnAnlegg?: string };

export type MeldInnAnleggForBedriftMutationVariables = Types.Exact<{
  anleggsinnmelding: Types.Anleggsinnmelding2Input;
}>;


export type MeldInnAnleggForBedriftMutation = { meldInnAnleggForBedrift?: string };

export type HentKunderForBedriftFraKundesystemQueryVariables = Types.Exact<{
  orgId: Types.Scalars['ID'];
}>;


export type HentKunderForBedriftFraKundesystemQuery = { bedriftinfo?: { id?: string, kontakt?: string, kontaktEpost?: string, godkjenteTariffer?: Array<string> }, tarifferKundesystem?: Array<{ beskrivelse?: string, tariffId?: string }> };


export const MeldInnAnleggDocument = gql`
    mutation meldInnAnlegg($anleggsinnmelding: AnleggsinnmeldingInput!, $orgid: ID!) {
  meldInnAnlegg(anleggsinnmelding: $anleggsinnmelding, orgid: $orgid)
}
    `;
export type MeldInnAnleggMutationFn = Apollo.MutationFunction<MeldInnAnleggMutation, MeldInnAnleggMutationVariables>;

/**
 * __useMeldInnAnleggMutation__
 *
 * To run a mutation, you first call `useMeldInnAnleggMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMeldInnAnleggMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [meldInnAnleggMutation, { data, loading, error }] = useMeldInnAnleggMutation({
 *   variables: {
 *      anleggsinnmelding: // value for 'anleggsinnmelding'
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useMeldInnAnleggMutation(baseOptions?: Apollo.MutationHookOptions<MeldInnAnleggMutation, MeldInnAnleggMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MeldInnAnleggMutation, MeldInnAnleggMutationVariables>(MeldInnAnleggDocument, options);
      }
export type MeldInnAnleggMutationHookResult = ReturnType<typeof useMeldInnAnleggMutation>;
export type MeldInnAnleggMutationResult = Apollo.MutationResult<MeldInnAnleggMutation>;
export type MeldInnAnleggMutationOptions = Apollo.BaseMutationOptions<MeldInnAnleggMutation, MeldInnAnleggMutationVariables>;
export const MeldInnAnleggForBedriftDocument = gql`
    mutation meldInnAnleggForBedrift($anleggsinnmelding: Anleggsinnmelding2Input!) {
  meldInnAnleggForBedrift(anleggsinnmelding: $anleggsinnmelding)
}
    `;
export type MeldInnAnleggForBedriftMutationFn = Apollo.MutationFunction<MeldInnAnleggForBedriftMutation, MeldInnAnleggForBedriftMutationVariables>;

/**
 * __useMeldInnAnleggForBedriftMutation__
 *
 * To run a mutation, you first call `useMeldInnAnleggForBedriftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMeldInnAnleggForBedriftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [meldInnAnleggForBedriftMutation, { data, loading, error }] = useMeldInnAnleggForBedriftMutation({
 *   variables: {
 *      anleggsinnmelding: // value for 'anleggsinnmelding'
 *   },
 * });
 */
export function useMeldInnAnleggForBedriftMutation(baseOptions?: Apollo.MutationHookOptions<MeldInnAnleggForBedriftMutation, MeldInnAnleggForBedriftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MeldInnAnleggForBedriftMutation, MeldInnAnleggForBedriftMutationVariables>(MeldInnAnleggForBedriftDocument, options);
      }
export type MeldInnAnleggForBedriftMutationHookResult = ReturnType<typeof useMeldInnAnleggForBedriftMutation>;
export type MeldInnAnleggForBedriftMutationResult = Apollo.MutationResult<MeldInnAnleggForBedriftMutation>;
export type MeldInnAnleggForBedriftMutationOptions = Apollo.BaseMutationOptions<MeldInnAnleggForBedriftMutation, MeldInnAnleggForBedriftMutationVariables>;
export const HentKunderForBedriftFraKundesystemDocument = gql`
    query hentKunderForBedriftFraKundesystem($orgId: ID!) {
  bedriftinfo: hentBedrift(orgid: $orgId) {
    id
    kontakt
    kontaktEpost
    godkjenteTariffer
  }
  tarifferKundesystem: hentTariffer(orgid: $orgId) {
    beskrivelse
    tariffId
  }
}
    `;

/**
 * __useHentKunderForBedriftFraKundesystemQuery__
 *
 * To run a query within a React component, call `useHentKunderForBedriftFraKundesystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentKunderForBedriftFraKundesystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentKunderForBedriftFraKundesystemQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useHentKunderForBedriftFraKundesystemQuery(baseOptions: Apollo.QueryHookOptions<HentKunderForBedriftFraKundesystemQuery, HentKunderForBedriftFraKundesystemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentKunderForBedriftFraKundesystemQuery, HentKunderForBedriftFraKundesystemQueryVariables>(HentKunderForBedriftFraKundesystemDocument, options);
      }
export function useHentKunderForBedriftFraKundesystemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentKunderForBedriftFraKundesystemQuery, HentKunderForBedriftFraKundesystemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentKunderForBedriftFraKundesystemQuery, HentKunderForBedriftFraKundesystemQueryVariables>(HentKunderForBedriftFraKundesystemDocument, options);
        }
export type HentKunderForBedriftFraKundesystemQueryHookResult = ReturnType<typeof useHentKunderForBedriftFraKundesystemQuery>;
export type HentKunderForBedriftFraKundesystemLazyQueryHookResult = ReturnType<typeof useHentKunderForBedriftFraKundesystemLazyQuery>;
export type HentKunderForBedriftFraKundesystemQueryResult = Apollo.QueryResult<HentKunderForBedriftFraKundesystemQuery, HentKunderForBedriftFraKundesystemQueryVariables>;