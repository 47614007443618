// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentBedrifterQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HentBedrifterQuery = { bedrifter?: Array<{ id?: string, navn?: string, orgnummer?: string, kontakt?: string, kontaktEpost?: string, brukere?: Array<{ id?: string, fornavn?: string, etternavn?: string, moduler?: Array<string>, epost?: string }>, moduler?: Array<{ id?: string, navn?: string }> }> };

export type BedriftDetaljerMinimumFragment = { id?: string, navn?: string, orgnummer?: string, kontakt?: string, kontaktEpost?: string, brukere?: Array<{ id?: string, fornavn?: string, etternavn?: string, moduler?: Array<string>, epost?: string }>, moduler?: Array<{ id?: string, navn?: string }> };

export const BedriftDetaljerMinimumFragmentDoc = gql`
    fragment bedriftDetaljerMinimum on Bedrift {
  id
  navn
  orgnummer
  kontakt
  kontaktEpost
  brukere {
    id
    fornavn
    etternavn
    moduler
    epost
  }
  moduler {
    id
    navn
  }
}
    `;
export const HentBedrifterDocument = gql`
    query hentBedrifter {
  bedrifter: hentBedrifter {
    ...bedriftDetaljerMinimum
  }
}
    ${BedriftDetaljerMinimumFragmentDoc}`;

/**
 * __useHentBedrifterQuery__
 *
 * To run a query within a React component, call `useHentBedrifterQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentBedrifterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentBedrifterQuery({
 *   variables: {
 *   },
 * });
 */
export function useHentBedrifterQuery(baseOptions?: Apollo.QueryHookOptions<HentBedrifterQuery, HentBedrifterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentBedrifterQuery, HentBedrifterQueryVariables>(HentBedrifterDocument, options);
      }
export function useHentBedrifterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentBedrifterQuery, HentBedrifterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentBedrifterQuery, HentBedrifterQueryVariables>(HentBedrifterDocument, options);
        }
export type HentBedrifterQueryHookResult = ReturnType<typeof useHentBedrifterQuery>;
export type HentBedrifterLazyQueryHookResult = ReturnType<typeof useHentBedrifterLazyQuery>;
export type HentBedrifterQueryResult = Apollo.QueryResult<HentBedrifterQuery, HentBedrifterQueryVariables>;