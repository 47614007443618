import styled from 'styled-components';
import { device } from 'web/GlobalStyling';

const PanelTitle = styled.h2`
	font-weight: bold;
	font-size: 1.375rem;
	line-height: 1.2;
	margin: 0;
	color: var(--nte-sort);
	@media ${device.mobile} {
		font-size: 1.25rem;
	}
`;

export default PanelTitle;
