import { Tilstand } from 'generated/graphql-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Button from 'web/components/atoms/Button';
import Paragraph from 'web/components/atoms/Paragraph';
import Push from 'web/components/atoms/Push';
import DataError from 'web/components/molecules/form/DataError';
import ModulChecboxList from 'web/components/molecules/form/ModulCheckboxList';
import TextField from 'web/components/molecules/form/TextField';
import InfoBox, { INFOBOX_COLOR } from 'web/components/molecules/InfoBox';
import Modal from 'web/components/molecules/Modal';
import { useHentBedriftSendInvitasjonQuery, useOpprettInvitasjonMutation } from 'web/components/organisms/modals/SendInvitasjonModal.graphql-gen';
import Form from 'web/hooks/useForm';
import { useModalSystem } from 'web/hooks/useModalSystem';
import { useTracking } from 'web/hooks/useTracking';
import { isEmailValid } from 'web/lib/validate';
import { useHentInvitasjonerQuery, useResendInvitasjonMutation } from 'web/pages/AdminPage.graphql-gen';
import { CheckMarkIcon } from 'web/pages/generic/InvitasjonAvslagPage';

const MODAL_NAME = 'SendInvitasjonModal';

export function useSendInvitasjonModal() {
	const { openModal, closeModal } = useModalSystem() as any;
	return {
		open: (props: any) => {
			openModal(MODAL_NAME, props);
		},
		close: () => {
			closeModal(MODAL_NAME);
		},
	};
}

interface SendInvitasjonModalProps {
	orgid: string;
	callback?: () => void;
}
export function SendInvitasjonModal({ orgid, callback }: SendInvitasjonModalProps) {
	const { track } = useTracking();
	const { close } = useSendInvitasjonModal();
	const [formError, setFormError] = useState(null);
	const [isAlreadySent, setIsAlreadySent] = useState(false);
	const { t } = useTranslation('admin');

	const {
		loading: invitasjonerLoading,
		error: invitasjonerError,
		data: { invitasjoner } = { invitasjoner: [] },
	} = useHentInvitasjonerQuery({
		variables: {
			orgid,
			filter: Tilstand.Ubesvart,
		},
		fetchPolicy: 'cache-and-network',
	});

	const {
		loading: bedriftLoading,
		error: bedriftError,
		data: { bedrift } = { bedrift: null },
		refetch: refetchBedrift,
	} = useHentBedriftSendInvitasjonQuery({
		variables: {
			orgid,
		},
		fetchPolicy: 'cache-and-network',
	});

	const [opprettInvitasjon, { error: opprettInvitasjonError, loading: opprettInvitasjonLoading }] = useOpprettInvitasjonMutation();
	const [resendInvitasjon, { error: resendInvitasjonError, loading: resendInvitasjonLoading }] = useResendInvitasjonMutation();

	return (
		<Modal
			title={t('InviteBrukerModal.title')}
			onClose={() => {
				close();
			}}>
			<Paragraph>{t('InviteBrukerModal.message')}</Paragraph>
			<Push />
			<DataError error={opprettInvitasjonError || resendInvitasjonError} />
			<Form
				disabled={opprettInvitasjonLoading || resendInvitasjonLoading}
				onSubmit={(variables: any, e: any) => {
					setIsAlreadySent(false);
					const email = variables.email.trim();
					if (opprettInvitasjonLoading || resendInvitasjonLoading || !email) {
						return;
					}

					if (!isEmailValid(email)) {
						setFormError({ email: t('E-post er ikke gyldig') });
						return;
					}

					const existingInviteIndex = invitasjoner.map((invite: any) => invite.epost.toLowerCase()).indexOf(email.toLowerCase());
					const inviteAlreadyExists = existingInviteIndex > -1;

					if (inviteAlreadyExists) {
						setIsAlreadySent(true);
					}

					let valgteModuler: string[] = [];
					for (let index = 0; index < e.target.modulCheckbox?.length; index++) {
						const element = e.target.modulCheckbox[index];
						if (element.checked) {
							let modul = bedrift?.moduler.find(mod => mod.id === element.value);
							modul && valgteModuler.push(modul.navn);
						}
					}

					(inviteAlreadyExists
						? resendInvitasjon({
								variables: {
									orgid,
									invitasjonId: invitasjoner[existingInviteIndex].id,
								},
						  })
						: opprettInvitasjon({
								variables: {
									invitasjon: {
										epost: email,
										moduler: valgteModuler,
									},
									orgid,
								},
						  })
					)
						.then(() => {
							if (!inviteAlreadyExists) {
								close();
							}
							callback?.();
							track('Invite Sent', {
								label: inviteAlreadyExists ? 'Re-sent' : 'Sent first time',
							});
							toast(inviteAlreadyExists ? 'Invitasjonen ble sendt på nytt' : 'Invitasjonen ble sendt!', {
								position: toast.POSITION.BOTTOM_CENTER,
							});
						})
						.catch((error: any) => {
							console.error(error);
							toast('Noe gikk galt, invitasjonen ble ikke sendt', {
								position: toast.POSITION.BOTTOM_CENTER,
							});
						});
				}}>
				<TextField
					name="email"
					label={t('InviteBrukerModal.email')}
					error={formError?.email}
					required={true}
					onChange={(e: any) => {
						e.preventDefault();
						setFormError({ email: t(null) });
					}}
				/>

				<label style={{ display: 'block', fontSize: '17px', marginBottom: '0.5rem' }}>{t('InviteBrukerModal.moduler')}</label>

				<ModulChecboxList moduler={bedrift?.moduler} checkedModuler={['Strøm', 'Bedriftsadmin']} />

				{(isAlreadySent && (
					<>
						<InfoBox title="Invitasjon til denne eposten finnes allerede" color={INFOBOX_COLOR.green}>
							<CheckMarkIcon className="fas fa-check-circle" />
							Vi har nå sendt invitasjonen på nytt, du kan trygt lukke vinduet.
						</InfoBox>
						<Push />
						<Button type="button" disabled={opprettInvitasjonLoading} onClick={() => close()}>
							Lukk
						</Button>
					</>
				)) || (
					<Button type="submit" disabled={opprettInvitasjonLoading}>
						{t('InviteBrukerModal.invite')}
					</Button>
				)}
			</Form>
		</Modal>
	);
}

export default {
	component: SendInvitasjonModal,
	name: MODAL_NAME,
};
