import c3 from 'c3';
import 'c3/c3.css';
import * as d3 from 'd3';
import React, { ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';

const GraphWrapper = styled.div`
	width: 100%;
	height: 400px;

	text,
	text tspan {
		font-size: 0.75rem;
		fill: var(--nte-graa-mork);
	}
	.dotted-line > line {
		stroke-dasharray: 5, 5;
	}

	.c3-xgrid-line {
		line {
			stroke: currentColor;
		}
		text {
			fill: currentColor;
		}
		tspan {
			fill: currentColor;
			font-size: 1.125rem;
		}
	}
	/* TODO: Fix line */
	.c3-xgrid-line.redLineBlackTekst {
		line {
			stroke: var(--varsel-roed);
		}
		text {
			fill: var(--nte-graa-mork);
		}
	}

	.c3-xgrid-line.red {
		color: var(--varsel-roed);
	}
	.c3-xgrid-line.coral {
		color: var(--nte-korall-moerkere);
	}
	.c3-xgrid-line.green {
		color: var(--varsel-groenn);
	}
	.c3-xgrid-line.blue {
		color: var(--nte-blaa);
	}

	.c3-ygrid-line {
		line {
			stroke: currentColor;
		}
		text {
			fill: currentColor;
		}
		tspan {
			fill: currentColor;
			font-size: 1.125rem;
		}
	}

	.c3-ygrid-line.red {
		color: var(--varsel-roed);
	}
	.c3-ygrid-line.coral {
		color: var(--nte-korall-moerkere);
	}
	.c3-ygrid-line.green {
		color: var(--varsel-groenn);
	}
	.c3-ygrid-line.blue {
		color: var(--nte-blaa);
	}

	.c3-axis line,
	.c3-axis .domain {
		stroke: var(--nte-graa-mork);
	}
`;

interface SimpleGraphProps {
	style?: React.CSSProperties;
	className?: any;
	chart: c3.ChartConfiguration;
	unloadIds?: string[];
	chartRef?: React.MutableRefObject<c3.ChartAPI>;
	children?: ReactNode;
	loadingData?: boolean;
}

export default function SimpleGraph(props: SimpleGraphProps) {
	const chartElement = useRef(null);

	const rootChartObject = useRef<c3.ChartAPI>(null);
	const { style, className, chart, chartRef } = props;

	let newChart = chart;

	if (!chart.data?.colors && !chart.color && !chart.data?.color) {
		chart.color = { pattern: ['var(--nte-blaa)', 'var(--nte-blaa-50)'] };
	}

	useEffect(() => {
		if (!chartElement.current) {
			return;
		}

		if (!rootChartObject.current) {
			rootChartObject.current = c3.generate({
				bindto: chartElement.current,
				...newChart,
			});
			d3.selectAll('g.c3-areas path').style('fill-opacity', '1');
			d3.selectAll('g.c3-areas path').style('fill', 'url(#gradient)');
			let gradient = d3
				.select(chartElement.current)
				.select('svg')
				.select('defs')
				.append('linearGradient')
				.attr('id', 'gradient')
				.attr('x1', '50%')
				.attr('y1', '0%')
				.attr('x2', '50%')
				.attr('y2', '100%');
			gradient.append('stop').attr('offset', '0%').attr('stop-color', 'var(--nte-blaa)').attr('stop-opacity', '1');

			gradient.append('stop').attr('offset', '100%').attr('stop-color', 'var(--nte-blaa)').attr('stop-opacity', '0');
		}

		chartRef && (chartRef.current = rootChartObject.current);
	}, [chartElement.current]);

	return (
		<div style={{ position: 'relative', width: '100%', height: '100%' }}>
			<LoadingOverlay loading={props.loadingData} text="Laster inn graf" />

			<GraphWrapper ref={chartElement} style={style} className={className}>
				{props.children}
			</GraphWrapper>
		</div>
	);
}
