import c3 from 'c3';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Direction, Flex } from 'web/components/atoms/Flex';
import FlexPageWrapper from 'web/components/atoms/FlexPageWrapper';
import PageTitle, { PageTitleButton } from 'web/components/atoms/PageTitle';
import Panel from 'web/components/atoms/Panel';
import Push from 'web/components/atoms/Push';
import BubbleTabSelect, { BubbleTabSelectProps } from 'web/components/molecules/BubbleTabSelect';
import DatePicker, { DatePickerProps } from 'web/components/molecules/DatePicker';
import InfoBox, { INFOBOX_COLOR } from 'web/components/molecules/InfoBox';
import SimpleGraph from 'web/components/molecules/SimpleGraph';
import { ValueInfoPanel, ValueInfoPanelProps } from 'web/components/molecules/ValueInfoPanel';
import FilterBar from 'web/components/organisms/FilterBar';
import { MobileDesktopSwitcher } from 'web/components/organisms/MobileDesktopSwitcher';
import MobileFilter from 'web/components/organisms/MobileFilter';
import { FilterDataArray } from 'web/lib/filter.utils';

export const ValuePanelWrapper = styled.div`
	display: flex;
	gap: 1rem;
`;

export const BubbleTabSelectWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const SecondaryValuePanelWrapper = styled(ValuePanelWrapper)`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	justify-content: space-evenly;
	align-self: center;
	width: 100%;
	flex-wrap: wrap;
`;

export const SecondaryStyledValueInfoPanel = styled(ValueInfoPanel)`
	padding: 0;
	border: none;
`;

export const MissingDataOverlay = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: var(--overlay-white);
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	z-index: 1;
`;

export const GraphWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
`;

export interface GraphTemplateProps {
	title: string;
	onClickExport?: () => void;
	loading?: boolean;

	primaryInfoPanels?: ValueInfoPanelProps[];
	secondaryInfoPanels?: ValueInfoPanelProps[];

	filterData: FilterDataArray;
	datePickerData: DatePickerProps;

	chartData?: c3.ChartConfiguration;
	chartRef?: React.MutableRefObject<c3.ChartAPI>;
	unloadIds?: string[];

	isMissingData?: boolean;
	missingDataElement?: any;

	hasSecondaryGraph?: boolean;
	tabSelectData?: BubbleTabSelectProps;

	children?: ReactNode;
}

export default function GraphTemplate(props: GraphTemplateProps) {
	return (
		<FlexPageWrapper>
			<PageTitle style={{ position: 'relative' }}>
				{props.title}
				{props.onClickExport && (
					<PageTitleButton
						type="button"
						onClick={() => {
							props.onClickExport();
						}}>
						<i className="fa fa-file-csv" /> {`Eksporter ${props.title.toLowerCase()}sdata`}
					</PageTitleButton>
				)}
			</PageTitle>

			<Flex gap="1rem" mobile={{ direction: Direction.row, gap: '0.5rem' }}>
				{props.primaryInfoPanels?.map((infoPanelprops, index) => (
					<ValueInfoPanel key={index} {...infoPanelprops} withBorder={true} />
				))}
			</Flex>
			<Panel>
				<Flex direction={Direction.column} gap="1rem">
					<MobileDesktopSwitcher
						desktop={() => <FilterBar filterData={props.filterData} />}
						mobile={() => (
							<MobileFilter
								filterData={props.filterData}
								mobileFilterId={`GraphFilter - ${props.title}`}
								closeButtonText={`Vis ${props.title.toLowerCase()}`}
							/>
						)}
					/>

					<DatePicker {...props.datePickerData} />

					{(props.hasSecondaryGraph && (
						<BubbleTabSelectWrapper>
							<BubbleTabSelect {...props.tabSelectData} style={{ width: '12.5rem' }} />
						</BubbleTabSelectWrapper>
					)) || <Push height="2rem" />}

					<GraphWrapper>
						{props.isMissingData && !props.loading && (
							<MissingDataOverlay>
								{props.missingDataElement || (
									<span>
										Fant ikke noe {props.title.toLowerCase()}sdata å vise,
										<br />
										prøv et annet filter eller en annen periode.
									</span>
								)}
							</MissingDataOverlay>
						)}

						<SimpleGraph
							chartRef={props.chartRef}
							style={{
								opacity: props.chartData ? '1' : '0',
							}}
							loadingData={props.loading}
							unloadIds={props.unloadIds}
							chart={
								props.chartData || {
									data: {
										columns: [],
										type: 'bar',
									},
								}
							}
						/>
					</GraphWrapper>
				</Flex>
			</Panel>
			{props.children}
		</FlexPageWrapper>
	);
}
