import React, { CSSProperties, useRef } from 'react';
import styled from 'styled-components';

//#region styling
const Wrapper = styled.div`
	position: relative;
	float: right;
	width: 245px;
	background: var(--white);
	box-shadow: var(--clickable-box-shadow);
	border-radius: var(--border-radius-smallest);
`;
const StyledInput = styled.input`
	padding: 13px;
	box-sizing: border-box;
	width: 100%;
	border: none;
	border-radius: var(--border-radius-smallest);
`;
const SearchButton = styled.button`
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	display: block;
	width: 42px;
	height: 42px;
	border: none;
	background: none;
	i {
		font-size: 1rem;
	}
`;
//#endregion
interface AdminSearchBarProps {
	onSearchChange: (searhc: string) => void;
	label?: string;
	style?: CSSProperties;
	buttonStyle?: CSSProperties;
}
export default function AdminSearchBar({ onSearchChange = () => {}, label, style, buttonStyle }: AdminSearchBarProps) {
	const inputElement = useRef(null);

	return (
		<Wrapper style={style}>
			<StyledInput placeholder={label} ref={inputElement} onChange={e => onSearchChange(e.target.value)} />
			<SearchButton
				style={buttonStyle}
				onClick={e => {
					e.stopPropagation();
					e.preventDefault();

					onSearchChange('');
					inputElement.current.value = '';
				}}>
				<i className="fas fa-search" style={{ color: 'var(--nte-blaa)' }} />
			</SearchButton>
		</Wrapper>
	);
}
