export enum EnergiEnheter {
	KWH = 'kWh',
	GWH = 'GWh',
}

export function roundUpByN(inNumber: number, n: number) {
	return inNumber !== 0 ? inNumber + (n - (inNumber % n || n)) : n;
}

export function NumberToClosestHypernum(max: number) {
	// https://stackoverflow.com/questions/14879691/get-number-of-digits-with-javascript
	function getNumDigits(x: number) {
		return (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + 1;
	}

	function getClosestHypernym(x: number) {
		let number = '1';
		for (let i = 1; i <= x - 1; i++) {
			number += '0';
		}
		return parseInt(number, 10);
	}
	if (max > 0) {
		return getClosestHypernym(getNumDigits(max));
	} else {
		return -getClosestHypernym(getNumDigits(max));
	}
}

export function kwhTilBesteEnhet(kwh: number) {
	if (kwh > 1000000) {
		return EnergiEnheter.GWH;
	}
	return EnergiEnheter.KWH;
}

export function kwhTilLabel(kwh: number) {
	return energiEnhetTilLabel(kwhTilBesteEnhet(kwh));
}

export function energiEnhetTilLabel(enhet: EnergiEnheter) {
	return enhet as string;
}

export function kwhTilBesteVerdi(kwh: number) {
	switch (kwhTilBesteEnhet(kwh)) {
		case EnergiEnheter.GWH:
			return kwhTilGwh(kwh);
		case EnergiEnheter.KWH:
			return kwh;
		default:
			return kwh;
	}
}

export function kwhTilEnhet(kwh: number, enhet: EnergiEnheter) {
	switch (enhet) {
		case EnergiEnheter.GWH:
			return kwhTilGwh(kwh);
		case EnergiEnheter.KWH:
			return kwh;
		default:
			return kwh;
	}
}

// kwhTilGwh(1000000) === 1;
export function kwhTilGwh(kwh: number) {
	if (kwh < 10) return 0;
	return kwh * 0.000001;
}

export function whTilKwh(wh: number) {
	return wh * 0.001;
}

// To prevent numbers so small they mess up the graph
export function roundInsignificantNumbersTozero(columns: any, enhet: EnergiEnheter) {
	const smallNumberAdjustedColumns: any[] = [];
	columns.map((entries: any[], i: number) => {
		const newEntries: any[] = [];
		entries.map((entry: any, j: number) => {
			if (typeof entry == 'number' && enhet == EnergiEnheter.GWH && entry < 10000) {
				newEntries[j] = 0;
			} else {
				newEntries[j] = entry;
			}
		});
		smallNumberAdjustedColumns[i] = newEntries;
	});
	return smallNumberAdjustedColumns;
}
