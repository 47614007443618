import { ApolloProvider } from '@apollo/react-hooks';
import nbLocale from 'date-fns/locale/nb';
import React, { Suspense } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import GlobalStyling from 'web/GlobalStyling';
import { AuthorizationProvider } from 'web/hooks/useAuthorization.provider';
import { ExportStatusProvider } from 'web/hooks/useEksportStatus';
import { ModalSystemProvider } from 'web/hooks/useModalSystem';
import { PrefsProvider } from 'web/hooks/usePrefs';
import apolloClient from 'web/lib/apolloClient';
import 'web/lib/i18n';

registerLocale('nb', nbLocale);
setDefaultLocale('nb');

// TODO: consider if we need all of these
export default function Providers({ children }) {
	return (
		<Router>
			<GlobalStyling />
			<PrefsProvider>
				<ApolloProvider client={apolloClient}>
					<Suspense fallback={<LoadingOverlay loading={true} isTemplate />}>
						<AuthorizationProvider>
							<ExportStatusProvider>
								<ModalSystemProvider>{children}</ModalSystemProvider>
							</ExportStatusProvider>
						</AuthorizationProvider>
					</Suspense>
				</ApolloProvider>
			</PrefsProvider>
		</Router>
	);
}
