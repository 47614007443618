import React, { createContext, ReactNode, useContext } from 'react';

export const PrefsContext = createContext({
	setItem: (key: string, value: any) => {},
	getItem: (key: string, defaultValue: any) => defaultValue,
	clear: () => {},
});

function getMatchingLocalStorageKeys(match: string) {
	let keys = [];
	for (let key in localStorage) {
		if (key.indexOf(match) === 0) {
			keys.push(key);
		}
	}

	return keys;
}

interface PrefsProviderProps {
	prefix?: string;
	children?: ReactNode;
}
export function PrefsProvider({ prefix = 'app.prefs', children }: PrefsProviderProps) {
	// TODO: check if we really need this context
	function mergeKey(key: string) {
		return `${prefix}.${key}`;
	}

	return (
		<PrefsContext.Provider
			value={{
				setItem: (key: string, value: any) => {
					const fullKey = mergeKey(key);

					localStorage.setItem(fullKey, JSON.stringify(value));
				},
				getItem: (key: string, defaultValue: any) => {
					const fullKey = mergeKey(key);
					const data = localStorage.getItem(fullKey);

					if (!data) {
						return defaultValue;
					}

					return JSON.parse(data);
				},
				clear: () => {
					const keys = getMatchingLocalStorageKeys(prefix);

					keys?.forEach(key => {
						localStorage.removeItem(key);
					});
				},
			}}>
			{children}
		</PrefsContext.Provider>
	);
}

export default function usePrefs() {
	return useContext(PrefsContext);
}
