import React, { useState } from 'react';
import Button from 'web/components/atoms/Button';
import Center from 'web/components/atoms/Center';
import Push from 'web/components/atoms/Push';
import TextField from 'web/components/molecules/form/TextField';
import { ANLEGG_SKJEMA_STAGES } from 'web/components/organisms/modals/MeldInnAnleggModal';
import {
	AnleggInnmeldingsskjemaStepProps,
	ArrowLeftIcon,
	FoundAnleggButton,
	StyledArrowButton,
} from 'web/components/organisms/modals/MeldInnAnleggModal/Step1';
import { useHentElhubAnleggLazyQuery } from 'web/components/organisms/modals/MeldInnAnleggModal/Step1.graphql-gen';
import Form from 'web/hooks/useForm';

export default function AnleggInnmeldingsskjemaStep2({ tenant, anlegg, state, setState, onFinish }: AnleggInnmeldingsskjemaStepProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [foundAnlegg, setFoundAnlegg] = useState(null);

	const [malerIdError, setMalerIdError] = useState(null);
	const [postNummerError, setPostNummerError] = useState(null);

	const [malerIdCount, setMalerIdCount] = useState(0);
	const [postnummerCount, setPostnummerCount] = useState(0);

	const canSubmit = postnummerCount > 0 && malerIdCount > 0;

	function checkIfMaalernummerAlreadyExists(maalernummer: string, postnummer: string) {
		return anlegg.find(anlegg => anlegg.maalernummer == maalernummer && anlegg.postnummer == postnummer);
	}

	const [hentElhubAnlegg] = useHentElhubAnleggLazyQuery({
		fetchPolicy: 'network-only',
	});

	return (
		<Form
			loading={isLoading}
			onSubmit={async (submitData: any) => {
				if (!submitData.maalernummer || !submitData.postnummer) {
					return;
				}

				if (checkIfMaalernummerAlreadyExists(submitData.maalernummer, submitData.postnummer)) {
					setMalerIdError('Dette målernummeret er allerede registrert');
					return;
				}

				if (malerIdCount < 5) {
					setMalerIdError('Et målernummer må bestå av 5 eller flere siffer');
					return;
				}

				if (postnummerCount < 4) {
					setPostNummerError('Et postnummer må bestå av 4 siffer');
					return;
				}

				setIsLoading(true);

				const { data } = await hentElhubAnlegg({
					variables: {
						orgid: tenant?.id,
						maalernummer: submitData.maalernummer,
						postnummer: submitData.postnummer,
					},
				}).catch(error => {
					setIsLoading(false);
					if (error.message?.indexOf('HTTP 50') != -1) {
						setMalerIdError(
							'Vi fikk ikke hentet informasjon om dette anlegget på grunn av teknisk feil. Du kan prøve igjen senere eller kontakte kundeservice.'
						);
					} else {
						setMalerIdError('Fant ikke anlegget. Sjekk om du har tastet riktig verdi');
					}
					setFoundAnlegg(null);
					return null;
				});

				setIsLoading(false);

				if (data?.anlegg) {
					setFoundAnlegg(data.anlegg);
				} else {
					setMalerIdError('Fant ikke anlegget. Sjekk om du har tastet riktig verdi');
				}
			}}>
			<Center>Fyll inn målernummer. Her må du også fylle inn postnummeret til anleggsadressen for at vi skal kunne søke fram anlegget.</Center>

			<Push />

			<TextField
				name="maalernummer"
				label="Målernummer"
				error={malerIdError}
				onChange={(e: any) => {
					let value = e.target.value;
					value = value.replace(/[^0-9\.]+/g, '');

					setMalerIdCount(value?.length);
					setMalerIdError(null);

					e.target.value = value;
				}}
			/>

			<TextField
				name="postnummer"
				label="Postnummer"
				error={postNummerError}
				onChange={(e: any) => {
					let value = e.target.value;
					value = value.replace(/[^0-9\.]+/g, '');

					if (value?.length > 4) {
						value = value.slice(0, 4);
					}

					setPostnummerCount(value?.length);
					setPostNummerError(null);

					e.target.value = value;
				}}
			/>

			{foundAnlegg && (
				<FoundAnleggButton
					anlegg={foundAnlegg}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						setState({ ...state, stage: ANLEGG_SKJEMA_STAGES.PROJECT_INPUT, elhubAnlegg: foundAnlegg });
					}}
				/>
			)}

			{!foundAnlegg && (
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<StyledArrowButton
						type="button"
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							setState({ ...state, stage: ANLEGG_SKJEMA_STAGES.START });
						}}>
						<ArrowLeftIcon className="fas fa-long-arrow-left" />
						Tilbake
					</StyledArrowButton>
					<Button disabled={!canSubmit}>Søk</Button>
				</div>
			)}
		</Form>
	);
}
