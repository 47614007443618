// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentFakturaDetaljerQueryVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
  guid?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type HentFakturaDetaljerQuery = { bedrift?: { faktura?: { guid?: string, pdfUrl?: string, detaljer?: { fakturanummer?: string, fakturadato?: string, forfallsdato?: string, kid?: string, fakturastatus?: Types.InvoiceStatus, fakturatype?: Types.InvoiceType, belopEksMoms?: number, belopMoms?: number, saldo?: number, distribusjonsDato?: string, distribusjonsKanal?: string, erpCustomerId?: string, source?: Types.Source } } } };


export const HentFakturaDetaljerDocument = gql`
    query hentFakturaDetaljer($orgid: ID!, $guid: String) {
  bedrift: hentBedrift(orgid: $orgid) {
    faktura(guid: $guid) {
      guid
      pdfUrl
      detaljer {
        fakturanummer
        fakturadato
        forfallsdato
        kid
        fakturastatus
        fakturatype
        belopEksMoms
        belopMoms
        saldo
        distribusjonsDato
        distribusjonsKanal
        erpCustomerId
        source
      }
    }
  }
}
    `;

/**
 * __useHentFakturaDetaljerQuery__
 *
 * To run a query within a React component, call `useHentFakturaDetaljerQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentFakturaDetaljerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentFakturaDetaljerQuery({
 *   variables: {
 *      orgid: // value for 'orgid'
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useHentFakturaDetaljerQuery(baseOptions: Apollo.QueryHookOptions<HentFakturaDetaljerQuery, HentFakturaDetaljerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentFakturaDetaljerQuery, HentFakturaDetaljerQueryVariables>(HentFakturaDetaljerDocument, options);
      }
export function useHentFakturaDetaljerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentFakturaDetaljerQuery, HentFakturaDetaljerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentFakturaDetaljerQuery, HentFakturaDetaljerQueryVariables>(HentFakturaDetaljerDocument, options);
        }
export type HentFakturaDetaljerQueryHookResult = ReturnType<typeof useHentFakturaDetaljerQuery>;
export type HentFakturaDetaljerLazyQueryHookResult = ReturnType<typeof useHentFakturaDetaljerLazyQuery>;
export type HentFakturaDetaljerQueryResult = Apollo.QueryResult<HentFakturaDetaljerQuery, HentFakturaDetaljerQueryVariables>;