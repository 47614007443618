import { format } from 'date-fns';
import { default as nb, default as nbLocale } from 'date-fns/locale/nb';
import React, { useRef, useState } from 'react';
import ReactDatepicker, { registerLocale } from 'react-datepicker';
import styled from 'styled-components';
import FormLabel from 'web/components/molecules/form/FormLabel';
import { StyledInput } from 'web/components/molecules/form/TextField';
import Portal from 'web/components/molecules/Portal';
import { useForm, useFormError, useFormValue } from 'web/hooks/useForm';
import useOnClickOutside from 'web/hooks/useOnClickOutside';

const Dropdown = styled.div`
	position: fixed;
	z-index: 99999;
	background: var(--white);
	padding: 12px;
	box-sizing: border-box;
	opacity: 0;
	pointer-events: none;
	left: -900px;
	box-shadow: var(--clickable-box-shadow);
`;

const HiddenFocusButton = styled.button`
	position: absolute;
	background: var(--white);
	border: none;
	position: absolute;
	top: 20px;
	right: calc(50% - 6px);
	width: 12px;
	height: 12px;
`;

const HiddenCloseButton = styled.button`
	position: absolute;
	background: var(--white);
	border: none;
	position: absolute;
	top: -80px;
	right: calc(50% - 6px);
	width: 12px;
	height: 12px;
`;

const Backdrop = styled.div`
	position: fixed;
	z-index: 99999;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;

const InlineDateFieldWrapper = styled.div`
	position: relative;
	.react-datepicker {
		font-family: Roboto;
		border: none;
		&__header {
			background: none;
			border: none;
			margin-bottom: 12px;
		}
		&__month-text {
			padding: 6px 0;
		}
	}
`;

registerLocale('nb', nb);

interface DatepickerFieldProps {
	label?: string;
	required?: boolean;
	hintText?: string;
	error?: string;
	placeholder?: string;
	labelFormat?: string;
	name: string;
	minDate?: Date;
}
export default function DatepickerField({ label, required, hintText, error, placeholder, labelFormat = 'dd.MM.yyyy', name, ...props }: DatepickerFieldProps) {
	const form = useForm(name, { isJson: true });
	const formError = useFormError(name);
	const formValue = useFormValue(name);

	const inputElementRef = useRef(null);
	const dropdownElementRef = useRef(null);
	const eatFocusOnReturnRef = useRef(false);
	const focusButtonRef = useRef(null);

	const [currentValue, setCurrentValue] = useState(null);
	const [isOpen, setIsOpen] = useState(false);

	useOnClickOutside({
		ref: dropdownElementRef,
		handler: (e: any) => {
			if (!isOpen) return;

			close();
			setIsOpen(false);
		},
	});

	function open() {
		const inputElementRect = inputElementRef.current.getBoundingClientRect();
		const clipsWithBottom = inputElementRect.top + dropdownElementRef.current.offsetHeight > window.innerHeight;

		if (clipsWithBottom) {
			dropdownElementRef.current.style.top = inputElementRect.top - dropdownElementRef.current.offsetHeight - 12 + 'px';
		} else {
			dropdownElementRef.current.style.top = inputElementRect.top + inputElementRef.current.offsetHeight + 12 + 'px';
		}

		const dropdownCenterPosition = inputElementRect.left + inputElementRef.current.offsetWidth / 2 - dropdownElementRef.current.offsetWidth / 2;

		dropdownElementRef.current.style.left =
			Math.min(Math.max(dropdownCenterPosition, 0), window.innerWidth - dropdownElementRef.current.offsetWidth) + 'px';
		dropdownElementRef.current.style.opacity = 1;
		dropdownElementRef.current.style.pointerEvents = 'all';
		// Force focus / close button to be focus, so one can quickly close if opening was a mistake
		setTimeout(() => {
			focusButtonRef.current.focus();
		}, 1);

		setIsOpen(true);
	}

	function close() {
		dropdownElementRef.current.style.left = '-900px';
		dropdownElementRef.current.style.opacity = 0;
		dropdownElementRef.current.style.pointerEvents = 'none';

		eatFocusOnReturnRef.current = true;

		setTimeout(() => {
			inputElementRef.current.focus();
		}, 1);
		setIsOpen(false);
	}

	return (
		<FormLabel label={label} required={required} hintText={hintText} error={error || formError}>
			<input type="hidden" name={name} value={JSON.stringify(currentValue)} />
			<StyledInput
				ref={inputElementRef}
				defaultValue={formValue}
				disabled={form?.disabled}
				required={required}
				onFocus={e => {
					if (eatFocusOnReturnRef.current) {
						eatFocusOnReturnRef.current = false;

						return;
					}
					open();
				}}
				placeholder={placeholder || label}
				value={currentValue ? format(new Date(currentValue), labelFormat, { locale: nbLocale }) : undefined}
				onKeyDown={e => {
					if (e.key == 'Tab' || e.key == 'Control' || e.key == 'Shift') return;

					e.preventDefault();
					e.stopPropagation();

					open();
				}}
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();

					open();
				}}
			/>
			<Portal>
				{isOpen && <Backdrop />}
				<Dropdown ref={dropdownElementRef}>
					<HiddenFocusButton
						type="button"
						ref={focusButtonRef}
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();

							close();
						}}>
						Lukk datovelger
					</HiddenFocusButton>
					<InlineDateFieldWrapper>
						<ReactDatepicker
							{...props}
							inline
							locale="nb"
							value={currentValue}
							onChange={value => {
								setCurrentValue(value);

								close();
							}}
						/>
					</InlineDateFieldWrapper>
					<HiddenCloseButton
						onFocus={() => {
							close();
						}}
					/>
				</Dropdown>
			</Portal>
		</FormLabel>
	);
}
