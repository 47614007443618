import { isToday, isTomorrow } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Push from 'web/components/atoms/Push';
import { AktivtVarsel } from 'web/hooks/useSpotpris';
import { dateListToIntervalString } from 'web/lib/dates.utils';
import { VarselIcon } from 'web/templates/SpotprisTemplate';

const Wrapper = styled.div`
	margin-left: 1rem;
`;

const Title = styled.h3`
	font-weight: bold;
	font-size: 1.25rem;
	line-height: 1.2;
	margin: 0;
	color: var(--nte-sort);
`;

export const HendelserListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.7rem;
`;

export const HenderlserListElement = styled.div`
	display: flex;
	align-items: center;
	line-height: 1.5rem;
	color: var(--nte-sort);
`;

interface HendelserListProps {
	numOfAktiveVarsler: number;
	aktiveVarsler: AktivtVarsel[];
	selectedDate: Date;
	style?: React.CSSProperties;
}

export default function HendelserList(props: HendelserListProps) {
	const { t } = useTranslation('forbruk');
	const isDateToday = isToday(props.selectedDate);
	const isDateTomorrow = isTomorrow(props.selectedDate);

	return (
		<Wrapper style={props.style}>
			<Push height="1.5rem" />
			<Title>Hendelser</Title>
			<Push height="0.25rem" />
			<HendelserListWrapper>
				{props.aktiveVarsler
					.filter((varsel: AktivtVarsel) => varsel.timeList?.length)
					.map((varsel: any, i: number) => {
						return (
							varsel.timeList?.length && (
								<HenderlserListElement key={i}>
									<VarselIcon className="fas fa-bell" />
									<div>
										<b>
											Spotpris {isDateToday ? 'er' : isDateTomorrow ? 'blir' : 'var'}{' '}
											{t('Spotpris.operator.' + varsel.operator, varsel.operator)} {varsel.verdi} øre
											{isDateToday ? ' i dag' : ''}
										</b>
										: {dateListToIntervalString(varsel.timeList)}
									</div>
								</HenderlserListElement>
							)
						);
					})}
			</HendelserListWrapper>
		</Wrapper>
	);
}
