import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'web/components/atoms/Button';
import Push from 'web/components/atoms/Push';
import TextField from 'web/components/molecules/form/TextField';
import { Description, FormInputTitle } from 'web/components/organisms/forms/SpotprisVarselSkjema';
import Form from 'web/hooks/useForm';

const RequiredStar = styled.span`
	color: var(--varsel-roed);
`;

interface ValidateEmailProps {
	onSubmit: (kode: string) => void;
	description?: any;
	loading?: boolean;
	emailValidationError?: string;
}

export function ValidateEmailSkjema({ onSubmit, description, loading, emailValidationError }: ValidateEmailProps) {
	const [formError, setFormError] = useState(null);

	useEffect(() => {
		if (emailValidationError && emailValidationError?.length > 0) {
			setFormError({ ...formError, valideringskode: emailValidationError });
		}
	}, [emailValidationError]);

	return (
		<div>
			{description && <Description>{description}</Description>}
			<Push />
			<Form
				loading={loading}
				onSubmit={(vars: any, e: any) => {
					e.preventDefault();

					if (!e.target.valideringskode.value) {
						setFormError({ valideringskode: 'Oppgi valideringskoden som du fikk tilsendt på epost' });
						return;
					}
					onSubmit(e.target.valideringskode.value);
				}}>
				<FormInputTitle>
					Skriv inn bekreftelseskoden du fikk tilsendt på epost<RequiredStar>*</RequiredStar>
				</FormInputTitle>
				<TextField required={false} error={formError?.valideringskode || emailValidationError} name="valideringskode" placeholder="Kode fra epost" />
				<Button type="submit" disabled={loading} style={{ marginBottom: '0' }}>
					<>
						<i className="far fa-save" /> Lagre varsel{' '}
					</>
				</Button>
			</Form>
		</div>
	);
}
