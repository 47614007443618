import { ExportStatuses, useExportStatus } from 'web/hooks/useEksportStatus';
import { HentFakturaerDocument } from 'web/hooks/useFakturaer.graphql-gen';
import { useTracking } from 'web/hooks/useTracking';
import apolloClient from 'web/lib/apolloClient';
import asyncWait from 'web/lib/asyncWait';
import { utils, writeFile } from 'xlsx';

interface FakturaEksportProps {
	orgid: string;
	fraDato: string;
	tilDato: string;
	maalere: string[];
	filter: string;
	fakturamerker: string[];
	naeringskoder: string[];
}
export default function useFakturaEksport() {
	const { track } = useTracking();
	const { state, setState } = useExportStatus();

	async function eksporter({ orgid, fraDato, tilDato, maalere, filter, fakturamerker, naeringskoder }: FakturaEksportProps) {
		setState({ ...state, status: ExportStatuses.DOWNLOADING, progress: 0, statusText: 'Starter nedlasting' });

		function handleError(error: any) {
			console.error(error);
			setState({
				...state,
				status: ExportStatuses.HIDDEN,
			});
		}

		const firstFakturaResponse = (await apolloClient
			.query({
				query: HentFakturaerDocument,
				variables: {
					orgid,
					fraDato,
					tilDato,
					antall: 9999,
					side: 1,
					filter,
					maalere,
					fakturamerker,
					naeringskoder,
				},
				fetchPolicy: 'no-cache',
			})
			.catch(handleError)) as any;

		let fakturaer = firstFakturaResponse?.data.bedrift?.fakturaer?.fakturaer ?? [];

		const totalFakturaPages = firstFakturaResponse?.data.bedrift?.fakturaer?.antallSider ?? 1;
		const paginatedResponses = [];

		for (let i = 2; i < totalFakturaPages + 1; i++) {
			paginatedResponses.push(
				await apolloClient
					.query({
						query: HentFakturaerDocument,
						variables: {
							orgid,
							fraDato,
							tilDato,
							antall: 9999,
							side: i,
							filter,
							maalere,
							fakturamerker,
							naeringskoder,
						},
						fetchPolicy: 'no-cache',
					})
					.catch(handleError)
			);
			setState({
				...state,
				status: ExportStatuses.DOWNLOADING,
				statusText: `Laster ned side ${i} av ${totalFakturaPages}`,
				progress: i / (totalFakturaPages + 1),
			});
		}

		paginatedResponses?.forEach((response: any) => {
			const { data, error } = response;

			if (error) {
				console.error(error);
				return;
			}

			fakturaer = [...fakturaer, ...(data?.bedrift?.fakturaer?.fakturaer || [])];
		});

		let maalerLabel = 'for alle målere';
		let filterLabels = [];

		if (maalere && maalere?.length) {
			filterLabels.push(maalere.join(', '));
		}
		if (fakturamerker && fakturamerker?.length) {
			filterLabels.push(fakturamerker.join(', '));
		}
		if (naeringskoder && naeringskoder?.length) {
			filterLabels.push(naeringskoder.join(', '));
		}
		if (filterLabels?.length) {
			maalerLabel = filterLabels.join(', ');
		}

		// TODO: use new UC call for more details
		const filename = `Faktura-eksport-${fraDato}-${tilDato})}`;
		let fakturaSheetData = [[`Fakturaer fra ${fraDato} til ${tilDato} ${maalerLabel}`], []];
		let fakturaTableDescriptionData = [['Forfallsdato', 'Fakturanummer', 'Fakturert', 'Utestående']];

		let fakturaerRawData: any[] = [];
		fakturaer?.forEach((faktura: any) => {
			fakturaerRawData.push([faktura.forfallsdato, faktura.fakturanummer, faktura.beloepInklMoms, faktura.saldo]);
		});

		fakturaSheetData = [...fakturaSheetData, ...fakturaTableDescriptionData, ...fakturaerRawData];

		setState({
			...state,
			status: ExportStatuses.DOWNLOADING,
			statusText: `Fullfører nedlasting`,
			progress: 1,
		});

		await asyncWait(1500);

		const book = utils.book_new();
		const fakturaSheet = utils.aoa_to_sheet(fakturaSheetData);

		utils.book_append_sheet(book, fakturaSheet, 'faktura');

		writeFile(book, `${filename}.xls`);

		track('Data Exported', { label: 'fakturadata' });

		setState({
			...state,
			status: ExportStatuses.HIDDEN,
		});
	}

	return { eksporter, state, setState };
}
