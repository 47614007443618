import React from 'react';
import Center from 'web/components/atoms/Center';
import Paragraph from 'web/components/atoms/Paragraph';
import Push from 'web/components/atoms/Push';
import Button, { ButtonWrapper } from 'web/components/atoms/Button';

export function OnboardingStep03(){
	return (
		<Center>
			<Paragraph>Du har nå tilgang til Bedriftsportalen</Paragraph>
			<Push />
			<ButtonWrapper>
				<Button
					onClick={() => {
						const getUrl = window.location;
						const baseUrl = `${getUrl.protocol}//${getUrl.host}/`;

						window.location.href = baseUrl;
					}}>
					Gå videre til Bedriftsportalen
				</Button>
			</ButtonWrapper>
		</Center>
	);
}