import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import Center from 'web/components/atoms/Center';
import PageTitle from 'web/components/atoms/PageTitle';
import { device } from 'web/GlobalStyling';
import { useTracking } from 'web/hooks/useTracking';

//region styling
const Wrapper = styled.div`
	position: fixed;
	z-index: 9999;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;
const Overlay = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: var(--overlay-gray);
`;
const ModalWrapper = styled.div`
	margin: auto;
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 40rem;
`;
const ModalDiv = styled.div`
	z-index: 1;
	position: relative;
	max-height: calc(100vh - 5rem);
	width: 100%;
	margin: 0 0.5rem;
	background: var(--white);
	border-radius: var(--border-radius);
`;
const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 80px);
	padding: 3.5rem 2.7rem 2.7rem;
	gap: 2rem;
	@media ${device.mobile} {
		padding: 3rem 1rem 1.5rem;
		gap: 1.2rem;
	}
`;
const CloseModalButton = styled.button`
	z-index: 2;
	position: absolute;
	top: 0;
	right: 0;
	padding: 1.5rem;
	border: none;
	background: none;
	box-sizing: border-box;
	i {
		color: var(--nte-blaa-uu);
		font-size: 2rem;
		@media ${device.mobile} {
			font-size: 1.5rem;
		}
	}
`;

const ModalTitle = styled(PageTitle)`
	justify-content: center;
`;

const ChildrenWrapper = styled.div`
	overflow: auto;
	padding: 2px;
`;
//endregion

interface Modal {
	modalRef?: React.MutableRefObject<any>;
	title?: string;
	children?: ReactNode;
	onClose?: () => void;
	style?: React.CSSProperties;
	dontTrack?: boolean;
	rest?: any;
}
export default function Modal({ modalRef, title, children, onClose, style, dontTrack, ...rest }: Modal) {
	const { track } = useTracking();
	/*
	document.addEventListener('keydown', function (e) {
		console.log('key down');
		if (e.key === 'Escape') {
			const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
			if (isNotCombinedKey) {
				console.log('Escape key was pressed with out any group keys');
			}
		}
	});

	useEffect(() => {
		function keyPress(e: any) {
			if (e.key === 'Escape') {
				const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
				if (isNotCombinedKey) {
					console.log('Escape key was pressed with out any group keys');
				}
			}
		}

		window.addEventListener('keyPress', keyPress);

		return () => {
			window.removeEventListener('keyPress', keyPress);
		};
	}, [false]);
	*/

	useEffect(() => {
		if (!dontTrack) {
			track('Button Clicked', { description: title });
		}
	}, [false]);

	return (
		<Wrapper ref={modalRef} {...rest}>
			<ModalWrapper>
				<ModalDiv style={style}>
					{onClose && (
						<CloseModalButton type="button" onClick={onClose}>
							<i className="far fa-times" />
						</CloseModalButton>
					)}
					<ContentWrapper>
						{title && (
							<Center>
								<ModalTitle>{title}</ModalTitle>
							</Center>
						)}
						<ChildrenWrapper>{children}</ChildrenWrapper>
					</ContentWrapper>
				</ModalDiv>

				<Overlay onClick={onClose} />
			</ModalWrapper>
		</Wrapper>
	);
}
