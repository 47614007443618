import { Faktura } from 'generated/graphql-types';
import React from 'react';
import styled, { css } from 'styled-components';
import Center from 'web/components/atoms/Center';
import FakturaListElement from 'web/components/molecules/FakturaListElement';
import LoadingElement from 'web/components/molecules/LoadingElement';

const ScrollToTopWrapper = styled.div<ScrollToTopButtonInterface>`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0) 100%);

	display: flex;
	justify-content: center;
	align-items: center;

	height: 6.25rem;
	transition: all 0.2s linear;

	${({ visible }) =>
		!visible &&
		css`
			height: 0;
		`}
`;
interface ScrollToTopButtonInterface {
	visible: boolean;
}
const ScrollToTopButton = styled.button<ScrollToTopButtonInterface>`
	position: fixed;
	bottom: 16px;
	border-radius: 50px;
	border: none;
	padding: 0.25rem 1.5rem;
	background: var(--nte-blaa);
	color: var(--white);
	font-size: 14px;
	font-weight: bold;

	i {
		margin-left: 0.25rem;
	}

	transition: transform 0.2s cubic-bezier(0.47, 1.64, 0.41, 0.8), opacity 0.2s ease-in-out;

	pointer-events: all;
	transform: scale(1);
	opacity: 1;
	&:hover {
		opacity: 1;
	}

	${({ visible }) =>
		!visible &&
		css`
			pointer-events: none;
			transform: scale(0);
			opacity: 0;
			&:hover {
				opacity: 0;
			}
		`}
`;

interface FakturaListMobileProps {
	loading?: boolean;
	loadingFetchMore?: boolean;
	fakturaer: Faktura[];
	isTabView?: boolean;
	displayScrollToTopButton?: boolean;
	onClickGoToTop?: () => void;
}

export default function FakturaListMobile({
	loading,
	loadingFetchMore,
	fakturaer,
	isTabView,
	displayScrollToTopButton,
	onClickGoToTop,
}: FakturaListMobileProps) {
	const loadingDateChange = loading && !loadingFetchMore;

	return (
		<>
			<div>
				{!loadingDateChange && fakturaer?.map((faktura, i) => <FakturaListElement key={i} faktura={faktura} />)}
				{!fakturaer?.length && !loading && (
					<Center style={{ marginTop: '1rem' }}>
						{(!isTabView && (
							<>
								Fant ingen fakturaer,
								<br />
								prøv et annet filter eller en annen periode.
							</>
						)) || <>Fant ingen fakturaer.</>}
					</Center>
				)}
				<LoadingElement loading={loading} text={'Laster inn faktura'} />
			</div>

			<ScrollToTopWrapper visible={displayScrollToTopButton}>
				<ScrollToTopButton onClick={onClickGoToTop} visible={displayScrollToTopButton}>
					Gå til toppen <i className="fa fa-arrow-up" />
				</ScrollToTopButton>
			</ScrollToTopWrapper>
		</>
	);
}
