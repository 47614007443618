import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

function createHarkClient(harkToken: string) {
	const cache = new InMemoryCache();
	const authLink = setContext((_, { headers }) => {
		return {
			headers: {
				...headers,
				Authorization: `Bearer ${harkToken}`,
			},
		};
	});

	const wsLink = new GraphQLWsLink(
		createClient({
			url: 'wss://api.ecomon.no/subscriptions',
			connectionParams: {
				Authorization: `Bearer ${harkToken}`,
				lazy: true,
			},
		})
	);

	const httpLink = ApolloLink.from([
		new RetryLink({
			delay: {
				initial: 300,
				max: Infinity,
				jitter: true,
			},
			attempts: (count, operation, error) => {
				return !!error && count < 6 && error.message === 'Unexpected token E in JSON at position 0';
			},
		}),
		onError(({ graphQLErrors, networkError }) => {
			let doSendNetworkError = false;
			if (graphQLErrors) {
				graphQLErrors.forEach(({ message, locations, path }) => {
					if (message === 'User not Authenticated') {
						doSendNetworkError = true;
					}

					console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
				});
			}

			if (networkError) {
				doSendNetworkError = true;
				console.error(networkError);
			}

			if (doSendNetworkError) {
				window.onNetworkError && window.onNetworkError(networkError);
			}
		}),
		authLink,
		new HttpLink({
			uri: `https://api.ecomon.no/`,
		}),
	]);
	const splitLink = split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
		},
		wsLink,
		httpLink
	);

	return new ApolloClient({
		link: splitLink,
		cache,
		defaultOptions: {
			watchQuery: {
				fetchPolicy: 'cache-and-network',
			},
		},
	});
}

let previousHarkToken: string = null;
let harkClient: ApolloClient<NormalizedCacheObject> = null;
export function getOrCreateHarkClient(harkToken: string) {
	if (previousHarkToken !== harkToken) {
		if (harkClient) {
			harkClient.stop();
		}
		previousHarkToken = harkToken;
		harkClient = createHarkClient(harkToken);
	}
	return harkClient;
}
export function getHarkClient() {
	return harkClient;
}
