import { debounce } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import { Route, Switch } from 'react-router-dom';
import useAuthHelpers from 'web/hooks/useAuthHelpers';
import { AdminRoute, AuthorizedRoute } from 'web/hooks/useAuthorization';
import { AdminLayout } from 'web/layouts/AdminLayout';
import { TenantLayout } from 'web/layouts/TenantLayout';
import LoginFrameContent from 'web/pages/LoginFrameContent';
import BeOmTilgangLoginPage from 'web/pages/generic/BeOmTilgangLoginPage';
import BeOmTilgangPage from 'web/pages/generic/BeOmTilgangPage';
import InvitasjonAvslagPage from 'web/pages/generic/InvitasjonAvslagPage';
import InvitasjonPage from 'web/pages/generic/InvitasjonPage';
import LandingPage from 'web/pages/generic/LandingPage';
import LoginPage from 'web/pages/generic/LoginPage';
import MineBedrifterPage from 'web/pages/generic/MineBedrifterPage';
import AdminRoutes from 'web/routes/AdminRoutes';
import TenantRoutes from 'web/routes/TenantRoutes';
import { GenericLayout, GenericLayoutLogoLeft } from './layouts/GenericLayout';
import { OnboardingStep01 } from 'web/pages/onboarding/OnboardingStep01';
import { OnboardingStep02 } from 'web/pages/onboarding/OnboardingStep02';
import { OnboardingStep03 } from 'web/pages/onboarding/OnboardingStep03';
import { OnboardingVilkaar } from 'web/pages/onboarding/OnboardingVilkaar';

export function App() {
	const auth = useAuth();
	const { silentLogin } = useAuthHelpers();

	const doBlockSilentLogin = useRef(false);
	const setDoBlockSilentLogin = debounce((doBlock: boolean) => (doBlockSilentLogin.current = doBlock), 1000);

	useEffect(() => {
		function udateTokenSilently(e: any) {
			if (e.data === 'udateTokenSilently' && e.origin == window.location.origin && !doBlockSilentLogin.current) {
				doBlockSilentLogin.current = true;
				silentLogin('network error');
				setDoBlockSilentLogin(false);
			}
		}

		window.addEventListener('message', udateTokenSilently);
		return () => {
			window.removeEventListener('message', udateTokenSilently);
		};
	}, []);

	return (
		<Switch>
			<Route path="/invitasjon/:invitationId/:invitationToken" exact>
				<GenericLayout>
					<InvitasjonPage />
				</GenericLayout>
			</Route>
			<Route path="/invitasjon/:invitationId/:invitationToken/avslag">
				<GenericLayout>
					<InvitasjonAvslagPage />
				</GenericLayout>
			</Route>
			<Route path="/login-frame">
				<LoginFrameContent />
			</Route>

			<Route path="/register">
				<GenericLayoutLogoLeft hasLongContent>
					<OnboardingStep01 />
				</GenericLayoutLogoLeft>
			</Route>

			<Route path="/register-login">
				<GenericLayoutLogoLeft hasLongContent>
					<OnboardingStep02 />
				</GenericLayoutLogoLeft>
			</Route>

			<Route path="/register-done">
				<GenericLayoutLogoLeft hasLongContent>
					<OnboardingStep03 />
				</GenericLayoutLogoLeft>
			</Route>

			<Route path="/register-vilkaar">
				<GenericLayoutLogoLeft hasLongContent>
					<OnboardingVilkaar />
				</GenericLayoutLogoLeft>
			</Route>

			{auth.isAuthenticated && (
				<>
					<Switch>
						<AuthorizedRoute ignorerTilgangsfeil path="/be-om-tilgang">
							<GenericLayout>
								<BeOmTilgangPage />
							</GenericLayout>
						</AuthorizedRoute>
						<AuthorizedRoute path="/" exact>
							<GenericLayout>
								<LandingPage />
							</GenericLayout>
						</AuthorizedRoute>
						<AuthorizedRoute path="/bedrifter/" exact>
							<GenericLayout hasLongContent>
								<MineBedrifterPage />
							</GenericLayout>
						</AuthorizedRoute>
						<AdminRoute path="/admin/">
							<AdminLayout>
								<AdminRoutes />
							</AdminLayout>
						</AdminRoute>
						<AuthorizedRoute path="/:bedriftId/">
							<TenantLayout>
								<TenantRoutes />
							</TenantLayout>
						</AuthorizedRoute>
					</Switch>
				</>
			)}
			{!auth.isAuthenticated && (
				<GenericLayout hasLongContent>
					<Switch>
						<Route path="/be-om-tilgang">
							<BeOmTilgangLoginPage />
						</Route>

						<Route path="/">
							<LoginPage />
						</Route>
					</Switch>
				</GenericLayout>
			)}
		</Switch>
	);
}
