import { env } from 'web/env';

export function isFjuttEnabled(): boolean {
	return env.REACT_APP_FJUTT_ENABLED === 'true';
}

// TODO: move this? It doesn't really belong. Possibly split this file into Fjutt.ts and Varsel.utils
export function isForbruksvarselEnabled(): boolean {
	return env.REACT_APP_FORBRUKSVARSEL_ENABLED === 'true';
}

export function fjuttMeterId(anlegg: any): string {
	if (env.REACT_APP_FJUTT_FORCE_METER) {
		console.log(`Forcing fjutt for ${env.REACT_APP_FJUTT_FORCE_METER}`);
		return env.REACT_APP_FJUTT_FORCE_METER;
	} else {
		return anlegg?.maalernummer;
	}
}

export function alleHarFjutt(): boolean {
	return !!env.REACT_APP_FJUTT_FORCE_METER;
}

export type Fjutt = {
	deviceId?: string;
	anleggsId?: string;
	omregningsfaktor?: number;
};

export interface FjuttProps {
	forrigeTimeGjennomsnitt: string;
	denneTimeGjennomsnitt: string;
	forbrukIDag?: string;
	harFjutt?: boolean;
	fjuttConfirmed?: boolean;
	fjuttEnabled: boolean;
	denneTimeRetning: number;
	valueNow: number;
	speedometerPercentage: number;
}

export type HarkUserToken = {
	accessToken: string;
	devices: Fjutt[];
};

export type AddFjuttError = {
	message: string;
	type: 'FJUTT_ALREADY_REGISTERED' | 'FJUTT_NOT_FOUND' | 'UNKNOWN';
};
export type AddFjutt = {
	error: AddFjuttError;
	fjutt: Fjutt;
	harkUserToken: HarkUserToken;
};
export type AddFjuttResponse = {
	addFjutt: AddFjutt;
};
