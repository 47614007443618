import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { CheckboxDiv } from 'web/components/atoms/Checkbox';
import BubbleTabSelect from 'web/components/molecules/BubbleTabSelect';
import FilterBarSelect from 'web/components/molecules/filter/FilterBarSelect';
import { StyledSearchInput } from 'web/components/molecules/FilterBarDropdownSelect';
import FilterDrawerSection from 'web/components/molecules/FilterDrawerSection';
import { FilterDataSelectElement, SelectItem } from 'web/lib/filter.utils';

const SearchWrapper = styled.div`
	position: relative;
`;
const ReStyledSearchInput = styled(StyledSearchInput)`
	height: 34px;
`;
const ClearSearchButton = styled.button`
	position: absolute;
	right: 16px;
	top: 15px;
	width: 34px;
	height: 34px;
	background: none;
	border: none;
	line-height: 34px;
	font-size: 16px;
	text-align: center;
`;

interface SelectElementWrapperProps {
	selected?: boolean;
}
const SelectElementWrapper = styled.label<SelectElementWrapperProps>`
	width: 100%;
	padding: 14px;
	display: flex;
	cursor: pointer;
	${props =>
		props.selected &&
		css`
			font-weight: 800;
		`}
`;

const SelectElementLabel = styled.span`
	font-size: 1rem;
	line-height: 1.2;
`;

const SelectElementSubLabel = styled.span<{ hasFixedWidth?: boolean }>`
	font-size: 0.75rem;
	line-height: 1.2;
	${props =>
		props.hasFixedWidth &&
		css`
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		`}
`;

export interface SelectElementProps {
	item: SelectItem;
	selected?: boolean;
	checkbox?: boolean;
	onClick?: (item: SelectItem) => void;
	style?: React.CSSProperties;
	hasFixedWidth?: boolean;
}

function SelectElement(props: SelectElementProps) {
	return (
		<div style={{ display: 'inline-block' }}>
			<SelectElementWrapper selected={props.selected} onClick={() => props.onClick?.(props.item)} style={props.style}>
				{props.checkbox && <CheckboxDiv>{props.selected && <i className="fa fa-check" />}</CheckboxDiv>}
				<div
					style={{
						width: props.hasFixedWidth && '10rem',
						whiteSpace: (props.hasFixedWidth && 'initial') || 'nowrap',
					}}>
					<SelectElementLabel>{props.item.label}</SelectElementLabel>
					{props.item.subLabel && (
						<>
							<br />
							<SelectElementSubLabel hasFixedWidth={props.hasFixedWidth}>{props.item.subLabel}</SelectElementSubLabel>
						</>
					)}
				</div>
			</SelectElementWrapper>
		</div>
	);
}

interface FilterDrawerSearchProps {
	onChange?: (search: string) => void;
	value?: string;
	searchPlaceholder?: string;
}
export function FilterDrawerSearch(props: FilterDrawerSearchProps) {
	const [search, setSearch] = useState(props.value || '');

	function onSearch(value: string) {
		setSearch(value);
		props.onChange?.(value);
	}

	return (
		<SearchWrapper>
			<ReStyledSearchInput placeholder={props.searchPlaceholder || 'Søk...'} onChange={e => onSearch(e.target.value)} value={props.value} />
			{(props.value?.length && (
				<ClearSearchButton
					type="button"
					onClick={() => {
						onSearch('');
					}}>
					<i className="fa fa-times" />
				</ClearSearchButton>
			)) || (
				<ClearSearchButton
					type="button"
					onClick={() => {
						onSearch('');
					}}>
					<i className="fa fa-search" style={{ color: 'var(--nte-blaa)' }} />
				</ClearSearchButton>
			)}
		</SearchWrapper>
	);
}

interface ContentWrapperProps {
	isMultiselect: boolean;
	isSortSelect: boolean;
}

const ContentWrapper = styled.div<ContentWrapperProps>`
	display: flex;
	width: 100%;
	max-width: 100%;
	overflow-x: auto;
	white-space: nowrap;
	${props =>
		!props.isMultiselect &&
		css`
			justify-content: center;
			padding: 0 1rem;
		`}
	${props =>
		props.isSortSelect &&
		css`
			justify-content: flex-start;
		`}
`;

export default function FilterDrawerSelect(props: FilterDataSelectElement) {
	const [search, setSearch] = useState('');

	let isSelected = (item: SelectItem) => {
		return item.value === props.selected;
	};

	if (props.isMultiselect) {
		isSelected = (item: SelectItem) => {
			if (!props.selected || props.selected?.length === 0) return;

			return props.selected.indexOf(item.value) > -1;
		};
	}

	return props.isDropdown ? (
		<FilterBarSelect {...props} isNarrowDropdown={true} />
	) : (
		<FilterDrawerSection label={props.title} style={props.filterDrawerStyle}>
			{props.useSearch && (
				<FilterDrawerSearch
					value={search}
					onChange={setSearch}
					searchPlaceholder={props.searchPlaceholder || (props.title ? `Søk i ${props.title.toLowerCase()}er...` : 'Søk...')}
				/>
			)}
			<ContentWrapper isMultiselect={props.isMultiselect} isSortSelect={props.isSortSelect}>
				{(!props.options?.length && props.emptyMsg && (
					<SelectElement key={props.emptyMsg.value} item={props.emptyMsg} style={{ cursor: 'default' }} />
				)) ||
					(props.useFilterDrawerTabSelect && !props.isMultiselect && (
						<BubbleTabSelect
							options={props.options}
							selected={typeof props.selected == 'string' ? props.selected : props.selected[0]}
							onChange={props.onChange}
							style={{ minWidth: '20rem', padding: '1rem 0' }}
						/>
					)) ||
					props.options
						?.filter(option => {
							if (!search) return true;

							const _searchIndex = `${option.value?.toLowerCase()} ${option.label?.toLowerCase()} ${option.subLabel?.toLowerCase()}`;

							return _searchIndex.indexOf(search.toLowerCase()) > -1;
						})
						?.map(item => (
							<SelectElement
								key={item.value}
								selected={isSelected(item)}
								item={item}
								checkbox={props.isMultiselect}
								hasFixedWidth={props.hasFixedWidth}
								onClick={() => {
									if (!props.onChange) return;
									if (!props.isMultiselect) {
										if (props.selected === item.value && props.isDeselectable) return props.onChange('');

										return props.onChange(item.value);
									}

									let selectedIndex = props.selected?.indexOf(item.value) ?? -1;

									if (selectedIndex > -1) {
										let selected = [...props.selected];

										selected.splice(selectedIndex, 1);

										props.onChange([...selected]);

										return;
									}
									props.onChange([...(props.selected || []), item.value]);
								}}
							/>
						))}
			</ContentWrapper>
		</FilterDrawerSection>
	);
}
