import React from 'react';
import styled, { css } from 'styled-components';
import { device } from 'web/GlobalStyling';
import { useTracking } from 'web/hooks/useTracking';

export const StyledTabWrapper = styled.div`
	padding: 0.125rem 0 4rem;
	height: max-content;
	min-height: 70vh;
	width: 100%;
`;

const TabBarWrapper = styled.div`
	width: max-content;
	display: flex;
	background: var(--nte-blaa-10);
	padding: 4px;
	border-radius: 3rem;
	@media ${device.tablet} {
		width: 100%;
	}
`;

interface TabButtonProps {
	active?: boolean;
}
const TabButton = styled.button<TabButtonProps>`
	flex-grow: 1;
	background: none;
	border: none;
	border-radius: 3rem;
	background: var(--nte-blaa-10);
	transition: background-color 250ms ease;
	transition: color 250ms ease;
	padding: 0.4rem 1.5rem;
	margin: 1px;
	${props =>
		props.active &&
		css`
			background: var(--nte-blaa-uu);
			color: var(--white);
			font-weight: bold;
		`}
	@media ${device.mobile} {
		padding: 0.2rem 0.1rem;
	}
`;

export interface Tab {
	value: string;
	label: string;
	component?: any;
}
export interface TabBarProps {
	tabs: Tab[];
	currentTab: string;
	onChangeTab?: (newTab: string) => void;
	wrapperStyle?: React.CSSProperties;
	buttonStyle?: React.CSSProperties;
}
export default function TabBar(props: TabBarProps) {
	const { track } = useTracking();
	return (
		<TabBarWrapper>
			{props.tabs.map(tab => (
				<TabButton
					key={tab.value}
					active={tab.value === props.currentTab}
					onClick={() => {
						props.onChangeTab?.(tab.value);
						track('Button Clicked', {
							label: tab.label,
						});
					}}>
					{tab.label}
				</TabButton>
			))}
		</TabBarWrapper>
	);
}
