import React, { createContext, ReactNode, useContext, useState } from 'react';
import styled from 'styled-components';
import Button from 'web/components/atoms/Button';
import Center from 'web/components/atoms/Center';
import DataError from 'web/components/molecules/form/DataError';
import { BlankAlertModal } from 'web/components/organisms/ConfirmModalButton';

const ProgressBarWrapper = styled.div`
	margin-top: 1.25rem;
	position: inline-block;
	background: var(--overlay-gray);
	border-radius: 1.5rem;
	height: 1.5rem;
	padding: 0.125rem;
	box-sizing: border-box;
`;

const ProgressBarBar = styled.div<{ progress: number }>`
	border-radius: 1.5rem;
	height: 1.25rem;
	background: var(--nte-blaa-logo);
	width: ${props => props.progress * 100}%;
`;

function ProgressBar({ progress }: { progress: number }) {
	return (
		<ProgressBarWrapper>
			<ProgressBarBar progress={progress} />
		</ProgressBarWrapper>
	);
}

export enum ExportStatuses {
	HIDDEN,
	PREPARING,
	DOWNLOADING,
	ASSEMBLING,
	PACKAGING,
	ERROR,
}

interface ExportStatusProviderState {
	status: ExportStatuses;
	progress: number;
	error: string;
	statusText: string;
}

interface exportStatusContextProps {
	state: ExportStatusProviderState;
	setState: (newState: ExportStatusProviderState) => void;
}

const exportStatusContext = createContext<exportStatusContextProps>(null);

interface ExportStatusProviderProps {
	children: ReactNode;
}

const StatusText = styled.span``;

const StatusComponentMap: { [key: string]: any } = {
	[ExportStatuses.PREPARING]: () => (
		<Center>
			<StatusText>Forbereder eksport</StatusText>
		</Center>
	),
	[ExportStatuses.DOWNLOADING]: ({ progress, statusText }: { progress: number; statusText: any }) => (
		<Center>
			{statusText && <StatusText>{statusText}</StatusText>}
			{!statusText && <StatusText>Laster ned {(progress * 100).toFixed(2)}%</StatusText>}

			<ProgressBar progress={progress} />
		</Center>
	),
	[ExportStatuses.ASSEMBLING]: () => (
		<Center>
			<StatusText>Syr sammen</StatusText>
		</Center>
	),
	[ExportStatuses.PACKAGING]: () => (
		<Center>
			<StatusText>Pakker fil</StatusText>
		</Center>
	),
	[ExportStatuses.ERROR]: ({ error, requestClose }: { error: any; requestClose: () => void }) => (
		<Center>
			<DataError error={error} />
			<Button onClick={requestClose}>Lukk</Button>
		</Center>
	),
};

function ExportStatusModal({ status = ExportStatuses.PREPARING, ...rest }) {
	const StatusComponent = StatusComponentMap[status];
	return (
		<BlankAlertModal>
			<StatusComponent {...rest} />
		</BlankAlertModal>
	);
}

export function ExportStatusProvider(props: ExportStatusProviderProps) {
	const [state, setState] = useState<ExportStatusProviderState>({
		status: ExportStatuses.HIDDEN,
		progress: 0,
		error: '',
		statusText: '',
	});

	return (
		<exportStatusContext.Provider value={{ state, setState }}>
			{props.children}

			{state.status !== ExportStatuses.HIDDEN && <ExportStatusModal {...state} />}
		</exportStatusContext.Provider>
	);
}

export function useExportStatus() {
	return useContext(exportStatusContext);
}
