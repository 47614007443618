import React, { useEffect, useState } from 'react';
import { isMobile } from 'web/GlobalStyling';
import useForbruk from 'web/hooks/useForbruk';
import useKostnad from 'web/hooks/useKostnad';
import { Opploesninger } from 'web/lib/dates.utils';
import { Anlegg } from 'web/models/Anlegg';
import KostnadOgForbrukTabTemplate from 'web/templates/singleAnlegg/KostnadOgForbrukTabTemplate';

interface KostnadOgForbrukTabProps {
	anlegg: Anlegg;
}
export default function KostnadOgForbrukTab({ anlegg }: KostnadOgForbrukTabProps) {
	const [kostnadsDate, setKostnadsDate] = useState(new Date());
	const [forbruksDate, setForbruksDate] = useState(new Date());
	const [forbruksOpploesning, setForbruksOpploesning] = useState(Opploesninger.maned);

	const [visProduksjonsGraf, setVisProduksjonsGraf] = useState(false);

	const kostnadGrafData = useKostnad({
		maalere: [anlegg?.maalerpunktId],
		selectedDate: kostnadsDate,
		useShortXLabel: isMobile(),
	});
	const forbrukGrafData = useForbruk({
		maalere: [anlegg?.maalerpunktId],
		selectedDate: forbruksDate,
		opploesning: forbruksOpploesning,
		setDatePickerData: (selectedDate, opploesning) => {
			setForbruksDate(selectedDate);
			setForbruksOpploesning(opploesning);
		},
		useShortXLabel: isMobile(),
		visProduksjonsGraf: visProduksjonsGraf,
	});

	useEffect(() => {
		if (!forbrukGrafData.hasProduksjonsData) {
			setVisProduksjonsGraf(false);
		}
	}, [forbrukGrafData.hasProduksjonsData]);

	const kostnadProps = {
		...kostnadGrafData,
		datePickerData: {
			selectedDate: kostnadsDate,
			onChange: setKostnadsDate,
			opploesning: Opploesninger.maned,
		},
	};

	const forbrukProps = {
		...forbrukGrafData,
		datePickerData: {
			selectedDate: forbruksDate,
			onChange: setForbruksDate,
			opploesning: forbruksOpploesning,
			onChangeOpploesning: setForbruksOpploesning,
		},
	};

	return (
		<KostnadOgForbrukTabTemplate
			kostnadProps={kostnadProps}
			forbrukProps={forbrukProps}
			hasSecondaryGraph={forbrukGrafData.hasProduksjonsData}
			tabSelectData={{
				options: [
					{ label: 'Kjøpt', value: 'forbruk' },
					{ label: 'Solgt', value: 'produksjon' },
				],
				selected: visProduksjonsGraf ? 'produksjon' : 'forbruk',
				onChange: newValue => {
					if (newValue == 'produksjon') {
						setVisProduksjonsGraf(true);
					} else {
						setVisProduksjonsGraf(false);
					}
				},
			}}
		/>
	);
}
