import { Invitasjon, Tilstand } from 'generated/graphql-types';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAddBedriftUserModal } from 'web/components/organisms/modals/AddBedriftUserModal';
import { useSendInvitasjonModal } from 'web/components/organisms/modals/SendInvitasjonModal';
import { HentBedrifterDocument } from 'web/hooks/useMineBedrifter.graphql-gen';
import { SegmentCategory, useTracking } from 'web/hooks/useTracking';
import apolloClient from 'web/lib/apolloClient';
import { Tenant } from 'web/models/Tenant';
import {
	useFjernMedlemMutation,
	useFjernModulMutation,
	useHentBedriftQuery,
	useHentInvitasjonerQuery,
	useResendInvitasjonMutation,
	useSettBrukermodulerMutation,
	useSettGodkjenteTarifferMutation,
	useSlettBedriftMutation,
	useSlettInvitasjonMutation,
} from 'web/pages/AdminPage.graphql-gen';
import AdminTemplate from 'web/templates/AdminTemplate';

interface AdminPageProps {
	isSysAdminPage?: boolean;
}
export default function AdminPage({ isSysAdminPage }: AdminPageProps) {
	const { track, trackPageView } = useTracking();
	if (!isSysAdminPage) {
		trackPageView(SegmentCategory.Bedriftsadmin);
	}
	const { open: openSendInvitasjonModal } = useSendInvitasjonModal();
	const { open: openAddUserModal } = useAddBedriftUserModal();
	const [filter, _] = useState({ sorter: 'navn' } as { sorter?: string });
	let { bedriftId } = useParams() as any;

	const {
		loading: invitasjonerLoading,
		error: invitasjonerError,
		data: { invitasjoner } = { invitasjoner: [] },
		refetch: refetchInvitasjoner,
	} = useHentInvitasjonerQuery({
		variables: {
			orgid: bedriftId,
			filter: Tilstand.Ubesvart,
		},
		fetchPolicy: 'cache-and-network',
	});

	const {
		loading: bedriftLoading,
		error: bedriftError,
		data: { bedrift } = { bedrift: null },
		refetch: refetchBedrift,
	} = useHentBedriftQuery({
		variables: {
			orgid: bedriftId,
		},
		fetchPolicy: 'cache-and-network',
	});

	const [settBrukerModuler] = useSettBrukermodulerMutation();

	const [fjernBruker] = useFjernMedlemMutation();

	const [fjernInvitasjon] = useSlettInvitasjonMutation();

	const [sendInvitasjonPaaNytt] = useResendInvitasjonMutation();

	const [settGodkjenteTariffer] = useSettGodkjenteTarifferMutation();

	const [fjernModul] = useFjernModulMutation();

	const [slettBedrift] = useSlettBedriftMutation();

	const users = [
		...(invitasjoner?.map((invitasjon: Invitasjon) => ({
			id: invitasjon.id,
			epost: invitasjon.epost,
			opprettet: invitasjon.opprettet,
			invitasjon: true,
		})) || []),
		...(bedrift?.brukere || []),
	];

	if (filter.sorter) {
		users.sort((a: any, b: any) => {
			let aValue = a.epost || '';
			let bValue = b.epost || '';
			if (filter.sorter === 'navn') {
				aValue = `${a.fornavn} ${a.etternavn}`;
				bValue = `${b.fornavn} ${b.etternavn}`;
			}

			if (aValue > bValue) return 1;
			if (aValue < bValue) return -1;

			return 0;
		});
	}

	return (
		<AdminTemplate
			isSysAdminPage={isSysAdminPage}
			loading={bedriftLoading || invitasjonerLoading}
			bedrift={bedrift}
			users={users as any}
			refetchBedrift={() => refetchBedrift()}
			onSendInvitasjonClick={() => {
				openSendInvitasjonModal({
					orgid: bedriftId,
					callback: () => {
						refetchInvitasjoner();
					},
				});
			}}
			onLeggTilBrukerClick={() => {
				openAddUserModal({
					orgid: bedriftId,
					callback: () => {
						refetchBedrift();
					},
				});
			}}
			settBrukerModuler={async (brukerId, modulIder) => {
				try {
					await settBrukerModuler({
						variables: {
							brukerId,
							modulIder,
							orgid: bedriftId,
						},
					}).then(() => refetchBedrift());

					toast('Brukerens tilganger er endret', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				} catch (error) {
					console.error(error);
					toast('Et problem oppstod under endring av tilganger', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				}
			}}
			fjernBruker={async brukerId => {
				try {
					await fjernBruker({
						variables: {
							brukerId,
							orgid: bedriftId,
						},
					}).then(() => refetchBedrift());

					track('Account Removed User');

					toast('Brukeren ble fjernet', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				} catch (error) {
					console.error(error);
					toast('Et problem oppstod under fjerningen av brukere', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				}
			}}
			fjernInvitasjon={async invitasjonId => {
				try {
					await fjernInvitasjon({
						variables: {
							invitasjonId,
							orgid: bedriftId,
						},
					}).then(() => refetchInvitasjoner());

					toast('Invitasjon ble fjernet', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				} catch (error) {
					console.error(error);
					toast('Et problem oppstod under fjerningen av invitasjonen', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				}
			}}
			sendInvitasjonPaaNytt={async invitasjonId => {
				try {
					await sendInvitasjonPaaNytt({
						variables: {
							invitasjonId,
							orgid: bedriftId,
						},
					});
					track('Invite Sent', {
						label: 'Re-sent',
					});
					toast('Invitasjon sendt på nytt', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				} catch (error) {
					toast('Et problem oppstod under re-sendingen av invitasjonen', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				}
			}}
			fjernModul={async modulId => {
				try {
					await fjernModul({
						variables: {
							modulId: modulId,
							orgid: bedriftId,
						},
						awaitRefetchQueries: true,
						refetchQueries: ['hentBedrift'],
					});
					toast('Modul er fjernet', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				} catch (error) {
					toast('Et problem oppstod ved fjerning av modul', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				}
			}}
			settGodkjenteTariffer={async godkjenteTariffer => {
				try {
					await settGodkjenteTariffer({
						variables: {
							tariffIder: godkjenteTariffer,
							orgid: bedriftId,
						},
					});
					toast('Godkjente tariffer er nå oppdatert', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				} catch (error) {
					toast('Et problem oppstod ved oppdatering av godkjente tariffer', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				}
			}}
			fjernBedrift={async () => {
				try {
					await slettBedrift({
						variables: {
							orgid: bedriftId,
						},
						update: cache => {
							const { bedrifter } = apolloClient.readQuery({ query: HentBedrifterDocument });

							apolloClient.writeQuery({
								query: HentBedrifterDocument,
								data: {
									bedrifter: bedrifter.filter((bedrift: Tenant) => bedrift.id != bedriftId),
								},
							});
						},
					});
					toast('Bedriften er nå fjernet', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				} catch (error) {
					toast('Et problem oppstod ved fjerning av bedrift', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				}
			}}
		/>
	);
}
