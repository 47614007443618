import React from 'react';
import styled from 'styled-components';
import Panel from 'web/components/atoms/Panel';
import PanelTitle from 'web/components/atoms/PanelTitle';
import Push from 'web/components/atoms/Push';
import BubbleTabSelect, { BubbleTabSelectProps } from 'web/components/molecules/BubbleTabSelect';
import DatePicker, { DatePickerProps } from 'web/components/molecules/DatePicker';
import SimpleGraph from 'web/components/molecules/SimpleGraph';
import { BubbleTabSelectWrapper } from 'web/templates/GraphTemplate';
import { TabWrapper } from 'web/templates/singleAnlegg/OversiktTabTemplate';

const GraphWrapper = styled.div`
	position: relative;
`;

export interface GrafProps {
	data: any;
	loading: boolean;
	ref: React.MutableRefObject<c3.ChartAPI>;
	datePickerData: DatePickerProps;
}

interface KostnadOgForbrukTabTemplateProps {
	forbrukProps?: GrafProps;
	kostnadProps?: GrafProps;
	tabSelectData?: BubbleTabSelectProps;
	hasSecondaryGraph?: boolean;
}
export default function KostnadOgForbrukTabTemplate({ forbrukProps, kostnadProps, tabSelectData, hasSecondaryGraph }: KostnadOgForbrukTabTemplateProps) {
	return (
		<TabWrapper>
			<Panel style={{ border: 'none', padding: '0', marginTop: '2rem' }}>
				<PanelTitle
					style={{
						opacity: (kostnadProps.data && '1') || '0',
					}}>
					Kostnad
				</PanelTitle>
				<Push height="1rem" />
				<div style={{ position: 'relative' }}>
					<DatePicker {...kostnadProps.datePickerData} />
					<Push height="1rem" />
					<GraphWrapper>
						<SimpleGraph
							loadingData={kostnadProps.loading}
							chartRef={kostnadProps.ref}
							style={{
								opacity: (kostnadProps.data && '1') || '0',
							}}
							chart={
								kostnadProps.data || {
									data: {
										columns: [],
										type: 'bar',
									},
								}
							}
						/>
					</GraphWrapper>
				</div>
			</Panel>

			<Panel style={{ border: 'none', padding: '0', marginTop: '2rem' }}>
				<PanelTitle
					style={{
						opacity: (forbrukProps.data && '1') || '0',
					}}>
					Forbruk
				</PanelTitle>
				<Push height="1rem" />
				<div style={{ position: 'relative' }}>
					<DatePicker {...forbrukProps.datePickerData} />
					{(hasSecondaryGraph && (
						<BubbleTabSelectWrapper>
							<BubbleTabSelect {...tabSelectData} style={{ width: '12.5rem' }} />
						</BubbleTabSelectWrapper>
					)) || <Push height="2rem" />}
					<GraphWrapper>
						<SimpleGraph
							loadingData={forbrukProps.loading}
							chartRef={forbrukProps.ref}
							style={{
								opacity: (forbrukProps.data && '1') || '0',
							}}
							chart={
								forbrukProps.data || {
									data: {
										columns: [],
										type: 'bar',
									},
								}
							}
						/>
					</GraphWrapper>
				</div>
			</Panel>
		</TabWrapper>
	);
}
