import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import FormLabel from 'web/components/molecules/form/FormLabel';
import { useForm, useFormError, useFormValue } from 'web/hooks/useForm';

export const StyledSelect = styled.select`
	color: var(--nte-sort);
	background: var(--white);
	border: var(--nte-border);
	border-radius: 3px;
	padding: 9px 16px;
	box-sizing: border-box;
	font-size: 16px;
	width: 100%;
	&:disabled {
		background: var(--nte-graa-lys-50);
	}
`;

interface SelectFieldProps {
	name: string;
	label?: string;
	value: any;
	defaultValue?: any;
	children: ReactNode;
	required?: boolean;
	error?: boolean;
	onChange?: (value: any) => void;
}
export default function SelectField({ name, label, value, defaultValue, children, required, error, onChange, ...props }: SelectFieldProps) {
	const form = useForm(name, { isJson: true });
	const formError = useFormError(name);
	const formValue = useFormValue(name);
	const [selected, setSelected] = useState(value || formValue || []);

	return (
		<FormLabel label={label} required={required} error={error || formError}>
			<input type="hidden" name={name} value={selected || defaultValue || ''} />
			<StyledSelect
				value={selected}
				disabled={form?.disabled}
				required={required}
				{...props}
				onChange={e => {
					const options = e.target.options;
					const value = [];
					for (let i = 0, l = options?.length; i < l; i++) {
						if (options[i].selected) {
							value.push(options[i].value);
						}
					}
					setSelected(value);
					onChange && onChange(value);
				}}>
				{children}
			</StyledSelect>
		</FormLabel>
	);
}
