// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompaniesForDocumentMutationVariables = Types.Exact<{
  update?: Types.InputMaybe<Types.UpdateDocumentCompaniesInput>;
}>;


export type UpdateCompaniesForDocumentMutation = { updateCompaniesForDocument?: { contentType?: string, created?: string, customTitle?: string, description?: string, id: string, title?: string, url?: string, companies?: Array<{ kundeNavn: string, orgNr: string }> } };

export type CompaniesArrayFragment = { __typename: 'DistributedDocument', companies?: Array<{ __typename: 'Company', kundeNavn: string, orgNr: string }> };

export const CompaniesArrayFragmentDoc = gql`
    fragment companiesArray on DistributedDocument {
  __typename
  companies {
    kundeNavn
    orgNr
    __typename
  }
}
    `;
export const UpdateCompaniesForDocumentDocument = gql`
    mutation updateCompaniesForDocument($update: UpdateDocumentCompaniesInput) {
  updateCompaniesForDocument(update: $update) {
    companies {
      kundeNavn
      orgNr
    }
    contentType
    created
    customTitle
    description
    id
    title
    url
  }
}
    `;
export type UpdateCompaniesForDocumentMutationFn = Apollo.MutationFunction<UpdateCompaniesForDocumentMutation, UpdateCompaniesForDocumentMutationVariables>;

/**
 * __useUpdateCompaniesForDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateCompaniesForDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompaniesForDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompaniesForDocumentMutation, { data, loading, error }] = useUpdateCompaniesForDocumentMutation({
 *   variables: {
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateCompaniesForDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompaniesForDocumentMutation, UpdateCompaniesForDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompaniesForDocumentMutation, UpdateCompaniesForDocumentMutationVariables>(UpdateCompaniesForDocumentDocument, options);
      }
export type UpdateCompaniesForDocumentMutationHookResult = ReturnType<typeof useUpdateCompaniesForDocumentMutation>;
export type UpdateCompaniesForDocumentMutationResult = Apollo.MutationResult<UpdateCompaniesForDocumentMutation>;
export type UpdateCompaniesForDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateCompaniesForDocumentMutation, UpdateCompaniesForDocumentMutationVariables>;