import React, { useState } from 'react';
import styled from 'styled-components';
import FormLabel from 'web/components/molecules/form/FormLabel';
import { useForm, useFormError } from 'web/hooks/useForm';

const StyledHiddenFileInput = styled.input`
	opacity: 0;
	position: absolute;
	width: 0;
	height: 0;
	left: 0;
`;
const FileInputSpan = styled.span`
	flex: 1;

	box-sizing: border-box;
	float: left;
	padding: 9px 16px;
	margin-right: 10px;
	width: 345px;
	height: 40px;

	color: var(--nte-sort);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	background: var(--nte-graa-lys-50);
	border: var(--nte-border);
	border-radius: 3px;
`;
const SpanButton = styled.span`
	box-sizing: border-box;
	float: right;
	padding: 9px 25px;

	font-size: 16px;
	font-weight: 500;

	color: var(--nte-blaa-logo);
	background: var(--white);

	border: 2px solid var(--nte-blaa-logo);
	border-radius: 6px;

	cursor: pointer;

	&:hover,
	&:active,
	&:focus {
		color: var(--nte-graa-lys-50);
		background: var(--nte-blaa-logo);
		border: 2px solid var(--nte-blaa-logo);
	}
	&:disabled {
		color: var(--nte-graa-mork);
		background: var(--white);
		border: 2px solid var(--nte-graa-mork);
	}
`;

interface FileFieldProps {
	name: string;
	label: string;
	hintText?: string;
	error?: boolean;
	required?: boolean;
	buttonText: string;
	setFileName?: (name: string) => void;
	fileSelectHandler: (file: File) => void;
}
export default function FileField({
	name,
	label,
	hintText,
	error,
	required,
	buttonText = 'Last opp vedlegg',
	setFileName,
	fileSelectHandler,
	...props
}: FileFieldProps) {
	const form = useForm(name);
	const formError = useFormError(name);
	const [selectedFile, setSelectedFile] = useState(null);
	const selectFile = (file: any) => {
		setSelectedFile(file);
		fileSelectHandler && fileSelectHandler(file);
	};
	return (
		<FormLabel label={label} required={required} hintText={hintText} error={error || formError}>
			<StyledHiddenFileInput
				type="file"
				disabled={form?.disabled}
				name={name}
				{...props}
				onChange={e => {
					selectFile(e.target.files[0]);
					if (setFileName && e.target.files[0]?.name) {
						setFileName(e.target.files[0].name);
					}
				}}
			/>
			<div style={{ display: 'flex' }}>
				<FileInputSpan>{selectedFile?.name || ''}</FileInputSpan>
				<SpanButton tabIndex={0}>{buttonText}</SpanButton>
			</div>
		</FormLabel>
	);
}
