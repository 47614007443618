import { useHentEntriesFraContentfulQuery } from 'web/hooks/useBloggSak.graphql-gen';

export default function useBloggSak() {
	const {
		data: { poster } = { poster: null },
		loading,
		error,
	} = useHentEntriesFraContentfulQuery({
		variables: {
			limit: 1,
			gruppeId: '655XciKxC50dXZLQwIN5X7',
			contentType: 'blogginnlegg',
		},
		fetchPolicy: 'cache-and-network',
	});
	const bloggSak = poster?.[0] || null;

	return {
		error,
		loading,
		bloggSak,
	};
}
