import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
	width: 100%;
	display: inline-block;
	margin-bottom: 2rem;
	text-align: left;
`;

const LabelText = styled.span`
	position: relative;
	color: var(--nte-sort);
	font-size: 17px;
	clear: both;
	display: inline-block;
	margin-bottom: 0.5rem;
`;

export const RequiredNotice = styled.span`
	position: absolute;
	color: red;
`;

export const LabelError = styled.span`
	background-color: var(--varsel-roed-10);
	border-radius: var(--border-radius-smallest);
	padding: 0.5rem 1rem;
	position: relative;
	font-size: 1rem;
	clear: both;
	display: block;
	margin-top: 0.5rem;
	width: max-content;
	max-width: 100%;
	svg {
		margin-right: 0.5rem;
	}
`;

const HintText = styled.span`
	display: inline-block;
	font-size: 12px;
	margin-left: 0.5rem;
`;

export function ErrorIcon() {
	return <i style={{ color: 'var(--varsel-roed)', marginRight: '0.5rem' }} className="fas fa-exclamation-circle" />;
}

interface FormLabelProps {
	label: string;
	hintText?: string;
	required?: boolean;
	children?: ReactNode;
	error?: string;
	style?: React.CSSProperties;
}
export default function FormLabel({ children, label, hintText, required, error, ...rest }: FormLabelProps) {
	return (
		<StyledLabel {...rest}>
			{label && (
				<LabelText>
					{label}
					{required && <RequiredNotice>*</RequiredNotice>}
					{hintText && <HintText>{hintText}</HintText>}
				</LabelText>
			)}
			{children}
			{error && (
				<LabelError>
					<ErrorIcon />
					{error}
				</LabelError>
			)}
		</StyledLabel>
	);
}
