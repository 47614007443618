import { format } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import { Bedrift, Bruker } from 'generated/graphql-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button, { ButtonWrapper, SecondaryButton } from 'web/components/atoms/Button';
import ClearButton from 'web/components/atoms/ClearButton';
import FlexPageWrapper from 'web/components/atoms/FlexPageWrapper';
import PageSubTitle from 'web/components/atoms/PageSubTitle';
import PageTitle, { PageTitleButton } from 'web/components/atoms/PageTitle';
import PanelTitle from 'web/components/atoms/PanelTitle';
import Push from 'web/components/atoms/Push';
import Right from 'web/components/atoms/Right';
import ModulList, { ModulListElement } from 'web/components/molecules/ModulList';
import ConfirmModalButton, { ClearConfirmModalButton } from 'web/components/organisms/ConfirmModalButton';
import { MobileDesktopSwitcher } from 'web/components/organisms/MobileDesktopSwitcher';
import { EndreTilgangModal } from 'web/components/organisms/modals/EndreTilgangModal';
import { LeggTilTariffModal } from 'web/components/organisms/modals/LeggTilTariffModal';
import { NyModulModal } from 'web/components/organisms/modals/NyModulModal';
import RedigerKundeModal from 'web/components/organisms/modals/RedigerKundeModal';
import { useIsAdmin, useIsSystem } from 'web/hooks/useAuthorization';
import { useTracking } from 'web/hooks/useTracking';
import useUserEksport from 'web/lib/exports/useUserEksport';

const ExportButton = styled.button`
	background: none;
	border: none;
	color: var(--nte-blaa-logo);
	font-weight: 500;
	padding: 7px;
	margin-top: 10px;
	i {
		font-size: 1rem;
		margin-right: 0.5rem;
	}
`;
const MobileTable = styled.table`
	border-collapse: separate;
	border-spacing: inherit;
	td {
		font-size: 1rem;
		padding: 1rem 0;
	}
`;
const Icon = styled.i`
	font-size: 1.25rem;
`;
const RemoveButton = styled(ConfirmModalButton)`
	padding: 0.5rem;
	border: none;
	background: none;
	text-align: center;
	color: var(--nte-blaa-uu);
	margin-left: 0.5rem;
	i {
		margin-right: 0.5rem;
	}
	&:hover,
	&:active,
	&:focus {
		opacity: 0.9;
	}
`;
const TariffItem = styled.li`
	list-style: none;
`;
const RemoveBedriftButton = styled(ConfirmModalButton)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 1.5rem;

	background: var(--varsel-roed);
	border: 1px solid var(--varsel-roed);
	box-sizing: border-box;
	border-radius: var(--border-radius-small);

	color: var(--white);
	font-size: 1rem;
	font-weight: normal;

	&:hover,
	&:active,
	&:focus {
		opacity: 0.9;
	}
`;
interface Invitasjon extends Bruker {
	invitasjon?: boolean;
	opprettet?: Date;
}

interface AdminTemplateProps {
	isSysAdminPage?: boolean; // isSysAdminPage endrer visuelle elementer, isAdmin endrer rettigheter
	bedrift: Bedrift;
	users?: Invitasjon[];
	loading?: boolean;
	refetchBedrift?: () => Promise<any>;
	onLeggTilBrukerClick?: () => void;
	onSendInvitasjonClick?: () => void;
	settBrukerModuler?: (brukerId: string, modulIder: string[]) => void;
	fjernBruker?: (brukerId: string) => void;
	sendInvitasjonPaaNytt?: (invitasjonId: string) => void;
	fjernInvitasjon?: (invitasjonId: string) => void;
	fjernModul?: (modulId: string) => void;
	settGodkjenteTariffer?: (tariffer: string[]) => void;
	fjernBedrift?: () => Promise<any>;
}

export default function AdminTemplate({
	isSysAdminPage,
	bedrift,
	users,
	loading,
	refetchBedrift,
	onLeggTilBrukerClick,
	onSendInvitasjonClick,
	settBrukerModuler,
	fjernBruker,
	sendInvitasjonPaaNytt,
	fjernInvitasjon,
	fjernModul,
	settGodkjenteTariffer,
	fjernBedrift,
}: AdminTemplateProps) {
	const history = useHistory<any>();
	const isAdmin: boolean = useIsAdmin() || useIsSystem(); // isAdmin endrer rettigheter, isSysAdminPage endrer visuelle elementer
	const isBulkInnmeldingAnleggEnabled = true;
	const { t } = useTranslation('admin');
	const { track } = useTracking();

	const brukere = users.filter(u => !u.invitasjon);
	const invitasjoner = users.filter(u => u.invitasjon);
	const [valgtBruker, setValgtBruker] = useState<Bruker>(null);

	const [visEndreTilgangModal, setVisEndreTilgangModal] = useState(false);
	const [visRedigerKundeModal, setVisRedigerKundeModal] = useState(false);
	const [visNyModulModal, setVisNyModulModal] = useState(false);
	const [visNyTariffModal, setVisNyTariffModal] = useState(false);

	return (
		<FlexPageWrapper>
			<PageTitle>
				{isSysAdminPage ? bedrift?.navn : 'Bedriftsadmin'}
				{isAdmin && (
					<PageTitleButton
						type="button"
						onClick={() => {
							setVisRedigerKundeModal(true);
						}}>
						<i className="fas fa-pencil" /> {t('SingleKunde.edit')}
					</PageTitleButton>
				)}
			</PageTitle>

			<PageSubTitle>
				{isSysAdminPage
					? 'Har kan du som systemadministrator redigere brukere, tilganger og mye mer'
					: 'Her kan du som administrator bestemme hvilke brukere som skal ha tilgang til deres bedriftsportal.'}
			</PageSubTitle>

			<ButtonWrapper>
				<Button
					onClick={() => {
						onSendInvitasjonClick();
					}}>
					<i className="far fa-paper-plane" />
					Send invitasjon
				</Button>
				{isAdmin && (
					<SecondaryButton
						onClick={() => {
							onLeggTilBrukerClick();
						}}>
						Legg til bruker
					</SecondaryButton>
				)}
			</ButtonWrapper>

			<Push height="0.5rem" />

			<PanelTitle>Brukere</PanelTitle>

			<MobileDesktopSwitcher
				desktop={() => (
					<>
						<table>
							<thead>
								<tr>
									<td>Navn</td>
									<td className="right">Tilganger</td>
									<td></td>
								</tr>
							</thead>
							<tbody>
								{(!brukere || brukere?.length === 0) && !loading && (
									<tr>
										<td className="center" colSpan={4}>
											Fant ingen brukere
										</td>
									</tr>
								)}
								{brukere.map(b => (
									<tr key={b.id}>
										<td>
											{b.fornavn} {b.etternavn}
										</td>

										<td className="right">
											{(b.moduler?.length &&
												b.moduler.map(m => {
													if (b.moduler.indexOf(m) < b.moduler?.length - 1) {
														return m + ', ';
													}
													return m;
												})) ||
												'Ingen tilganger'}
											<ClearButton
												onClick={() => {
													setValgtBruker(b);
													setVisEndreTilgangModal(true);
												}}>
												<i className="fas fa-pencil" />
											</ClearButton>
										</td>

										<td className="right">
											<ClearConfirmModalButton
												title="Fjern bruker"
												message={`Sikker på at du ønsker å fjerne ${b.fornavn} ${b.etternavn}?`}
												confirm="Fjern bruker"
												onClick={() => {
													fjernBruker?.(b.id);
												}}>
												<Icon className="fa fa-trash" />
											</ClearConfirmModalButton>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						{isAdmin && brukere?.length > 0 && (
							<Right>
								<ExportButton
									onClick={async () => {
										await useUserEksport({
											invitasjoner: invitasjoner,
											brukere: bedrift?.brukere,
										}).then(() => {
											track('Data Exported', { label: 'brukerdata' });
										});
									}}>
									<i className="fas fa-file-excel" />
									Eksporter
								</ExportButton>
							</Right>
						)}
					</>
				)}
				mobile={() => (
					<MobileTable>
						<tbody>
							{(!brukere || brukere?.length === 0) && !loading && (
								<tr>
									<td className="center" colSpan={2}>
										Fant ingen brukere
									</td>
								</tr>
							)}
							{brukere.map(b => (
								<tr key={b.id}>
									<td>
										<b>
											{b.fornavn} {b.etternavn}
										</b>

										{(b.moduler?.length &&
											b.moduler.map(m => {
												if (b.moduler.indexOf(m) < b.moduler?.length - 1) {
													return m + ', ';
												}
												return m;
											})) ||
											'Ingen tilganger'}
										<ClearButton
											style={{
												padding: '0.5rem 0 0 0.5rem',
											}}
											onClick={() => {
												setValgtBruker(b);
												setVisEndreTilgangModal(true);
											}}>
											<i className="fas fa-pencil" />
										</ClearButton>
									</td>
									<td className="right">
										<ClearConfirmModalButton
											title="Fjern bruker"
											message={`Sikker på at du ønsker å fjerne ${b.fornavn} ${b.etternavn}?`}
											confirm="Fjern bruker"
											onClick={() => {
												fjernBruker?.(b.id);
											}}>
											<Icon className="fa fa-trash" />
										</ClearConfirmModalButton>
									</td>
								</tr>
							))}
						</tbody>
					</MobileTable>
				)}
			/>

			{(invitasjoner?.length && (
				<>
					<Push height="0.5rem" />
					<PanelTitle>Invitasjoner</PanelTitle>

					<MobileDesktopSwitcher
						desktop={() => (
							<table>
								<thead>
									<tr>
										<td>E-post</td>
										<td>Invitert</td>
										<td></td>
									</tr>
								</thead>
								<tbody>
									{invitasjoner.map(i => (
										<tr key={i.id}>
											<td>{i.epost}</td>

											<td>{format(new Date(i.opprettet), 'd. MMMM yyyy', { locale: nbLocale })}</td>

											<td className="right">
												<ClearButton
													title="Send invitasjon på nytt"
													onClick={() => {
														sendInvitasjonPaaNytt?.(i.id);
													}}>
													<Icon className="fa fa-redo" />
												</ClearButton>
												<ClearConfirmModalButton
													title="Fjern invitasjon"
													message={`Sikker på at du ønsker å fjerne invitasjonen til ${i.epost}?`}
													confirm="Fjern invitasjon"
													onClick={() => {
														fjernInvitasjon?.(i.id);
													}}>
													<Icon className="fa fa-trash" />
												</ClearConfirmModalButton>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						)}
						mobile={() => (
							<MobileTable>
								<tbody>
									{invitasjoner.map(i => (
										<tr key={i.id}>
											<td>
												<>
													{i.epost}
													<br />
													Invitert {format(new Date(i.opprettet), 'd. MMMM yyyy', { locale: nbLocale })}
												</>
											</td>

											<td className="right">
												<ClearButton
													title="Send invitasjon på nytt"
													onClick={() => {
														sendInvitasjonPaaNytt?.(i.id);
													}}>
													<Icon className="fa fa-redo" />
												</ClearButton>
												<ClearConfirmModalButton
													title="Fjern invitasjon"
													message={`Sikker på at du ønsker å fjerne invitasjonen til ${i.epost}?`}
													confirm="Fjern invitasjon"
													onClick={() => {
														fjernInvitasjon?.(i.id);
													}}>
													<Icon className="fa fa-trash" />
												</ClearConfirmModalButton>
											</td>
										</tr>
									))}
								</tbody>
							</MobileTable>
						)}
					/>
				</>
			)) || <></>}

			<Push height="0.5rem" />
			<PanelTitle>Moduler</PanelTitle>
			<ModulList>
				{bedrift?.moduler?.map((modul, i) => (
					<ModulListElement key={i} name={modul.navn} isRemovable={isAdmin} onRemove={() => fjernModul(modul.id)} />
				))}
				{isAdmin && (
					<ModulListElement
						name="Legg til ny"
						icon="plus"
						onClick={() => {
							setVisNyModulModal(true);
						}}
					/>
				)}
			</ModulList>

			{isAdmin && isBulkInnmeldingAnleggEnabled && (
				<>
					<Push height="0.5rem" />
					<PanelTitle>
						Tariffer for innmelding av anlegg
						<PageTitleButton
							onClick={() => {
								setVisNyTariffModal(true);
							}}>
							<i className="fas fa-plus" style={{ marginRight: '0.5rem' }} />
							Legg til tariff
						</PageTitleButton>
					</PanelTitle>

					{bedrift?.godkjenteTariffer?.length > 0 ? (
						<>
							<ul>
								{bedrift?.godkjenteTariffer?.map((tariff: any, i: number) => (
									<TariffItem key={i}>
										{tariff}
										<RemoveButton
											title="Fjern tariff"
											message={`Sikker på at du ønsker å fjerne ${tariff}?`}
											confirmIcon="far fa-trash"
											confirm="Fjern tariff"
											decline="Avbryt"
											type="button"
											onClick={() => {
												settGodkjenteTariffer(bedrift?.godkjenteTariffer?.filter((t: any) => t !== tariff));
											}}>
											<>
												<i className="fas fa-trash" />
												Fjern
											</>
										</RemoveButton>
									</TariffItem>
								))}
							</ul>
							<Link to={`/admin/kunder/${bedrift.id}/batchmeldinnanlegg/`}>Bulkinnmelding</Link>
						</>
					) : (
						<ul>
							<TariffItem>Ingen tariffer</TariffItem>
						</ul>
					)}
				</>
			)}

			{isAdmin && (
				<ButtonWrapper>
					<RemoveBedriftButton
						title={t('SingleKunde.removeBedrift.title', { name: bedrift?.navn })}
						message={t('SingleKunde.removeBedrift.message', { name: bedrift?.navn })}
						confirm={t('SingleKunde.removeBedrift.confirm', { name: bedrift?.navn })}
						decline={t('SingleKunde.removeBedrift.decline')}
						onClick={() => {
							fjernBedrift().then(() => history.push('/admin/kunder/'));
						}}>
						<i className="far fa-trash" style={{ marginRight: '0.5rem' }} />
						{t('SingleKunde.removeBedriftButton', { name: bedrift?.navn })}
					</RemoveBedriftButton>
				</ButtonWrapper>
			)}

			{visRedigerKundeModal && <RedigerKundeModal orgId={bedrift?.id} onClose={() => setVisRedigerKundeModal(false)} />}
			{visEndreTilgangModal && (
				<EndreTilgangModal
					bruker={valgtBruker}
					moduler={bedrift?.moduler}
					setVisEndreTilgangModal={setVisEndreTilgangModal}
					settBrukerModuler={settBrukerModuler}
				/>
			)}
			{visNyModulModal && (
				<NyModulModal orgId={bedrift?.id} moduler={bedrift?.moduler} onClose={() => setVisNyModulModal(false)} callback={refetchBedrift} />
			)}
			{visNyTariffModal && (
				<LeggTilTariffModal
					godkjenteTariffer={bedrift?.godkjenteTariffer}
					setIsNewTariffModalOpen={setVisNyTariffModal}
					leggTilTariff={tariff => {
						settGodkjenteTariffer(bedrift?.godkjenteTariffer?.concat(tariff) || [tariff]);
					}}
				/>
			)}
		</FlexPageWrapper>
	);
}
