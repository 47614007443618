import { useState } from 'react';
import { useBeOmValideringskodeMutation, useValiderEpostMutation } from 'web/hooks/useEmailValidation.graphql-gen';
import useProfile from 'web/hooks/useProfile';

interface Valideringsstatus {
	status: 'IKKE_FUNNET' | 'FEIL_KODE' | 'OK';
}

export default function useEmailValidation() {
	const [beOmValideringskode, { error: errorBeOmKode, loading: loadingBeOmKode }] = useBeOmValideringskodeMutation();
	const [validerEpost, { error: errorValider, loading: loadingValider }] = useValiderEpostMutation();

	const { brukerprofil, queryLoading, leggTilValidertEpost } = useProfile();
	const [emailValidationError, setEmailValidationError] = useState('');

	function requestValidationCode(epost: string) {
		return beOmValideringskode({
			variables: {
				epost: epost,
			},
		})
			.then(response => {
				return true;
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}

	function validateEmail(code: string, epost: string) {
		return validerEpost({
			variables: {
				epost: epost,
				kode: code,
			},
		})
			.then(response => {
				const status = response.data.validerEpost.status;
				if (status == 'OK') {
					return true;
				} else if (status == 'FEIL_KODE' || status == 'IKKE_FUNNET') {
					return false;
				}
			})
			.catch(error => {
				console.error('Error:', error);
				return false;
			});
	}

	function checkIfEmailIsValidated(inputEmail: string) {
		if (inputEmail?.length > 0) {
			const isValidated = brukerprofil.validerteEposter.includes(inputEmail);
			return !!isValidated;
		} else {
			return false;
		}
	}

	const loading = loadingValider || loadingBeOmKode || queryLoading;
	const error = errorBeOmKode || errorValider;
	return {
		loading,
		error,
		requestValidationCode,
		validateEmail,
		checkIfEmailIsValidated,
		leggTilValidertEpost,
		emailValidationError,
		setEmailValidationError,
	};
}
