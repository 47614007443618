import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export const modalSystemContext = createContext({ openModals: [], openModal: (name: string, props: any) => {}, closeModal: (name: string) => {} });

/**
 * Create a new modal by adding a new file in the modals folder:
 * Example:
 * const MODAL_NAME = 'ModalName';
 *
 * export useModal(){
 * 		const { openModal, closeModal } = useModalSystem();
 *
 * 		return {
 * 			open: props => {
 * 				openModal(MODAL_NAME, props);
 * 			},
 * 			close: => {
 * 				closeModal(MODAL_NAME);
 * 			}
 * 		};
 * }
 *
 * export default {
 * 		component: () => <Modal title="Title" onClose={()=>{}}>
 *			<div>These are the children</div>
 * 		</Modal>
 * 		name: MODAL_NAME
 * };
 */

export interface Modal {
	name: string;
	component: any;
}

export function useModalSystem() {
	return useContext(modalSystemContext);
}

interface ModalSystemProviderProps {
	children: ReactNode;
}
export function ModalSystemProvider({ children }: ModalSystemProviderProps) {
	const modalsMap = useMemo(() => {
		let context: any = {};
		let modals = [];
		let modalsMap: any = {};
		if (process.env.NODE_ENV !== 'test') {
			context = require.context('web/components/organisms/modals', true, /\.(tsx|js)$/);

			modals = context.keys().map((key: string) => {
				return context(key).default;
			});

			modals?.forEach((modal: Modal) => {
				modalsMap[modal?.name] = modal?.component;
			});
		}

		return modalsMap;
	}, []);

	const [openModals, setOpenModals] = useState([]);

	return (
		<modalSystemContext.Provider
			value={{
				openModals,
				openModal: (name: string, props: any) => {
					setOpenModals([...openModals, { name, props }]);
				},
				closeModal: (name: string) => {
					setOpenModals([...openModals.filter(modal => modal.name != name)]);
				},
			}}>
			{children}

			{openModals.map((modal, index) => {
				const Element = modalsMap[modal.name];

				return <Element key={modal?.name + index} {...modal.props} />;
			})}
		</modalSystemContext.Provider>
	);
}
