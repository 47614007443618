import React, { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';
import { SecondaryButton } from 'web/components/atoms/Button';
import ClearButton from 'web/components/atoms/ClearButton';
import PanelTitle from 'web/components/atoms/PanelTitle';
import { device } from 'web/GlobalStyling';

export enum INFOBOX_ALIGN {
	left = 'flex-start',
	center = 'center',
	right = 'flex-end',
}

export enum INFOBOX_COLOR {
	green = 'green',
	yellow = 'yellow',
	blue = 'blue',
	white = 'white',
	red = 'red',
}

const StyledTitle = styled(PanelTitle)`
	width: 100%;
	text-align: left;
`;

const StyledClearCloseButton = styled(ClearButton)`
	position: absolute;
	top: 0;
	right: 0;
	padding: 1rem;
	display: flex;
	align-items: center;
	text-align: center;
	font-size: 1rem;
	margin-left: 1rem;
	i {
		font-weight: 400;
		color: var(--nte-sort);
	}
`;

interface InfoBoxWrapperProps {
	color?: INFOBOX_COLOR;
	align?: string;
	isCollapsed?: boolean;
}
const Wrapper = styled.div<InfoBoxWrapperProps>`
	--button-color: var(--nte-blaa);
	display: flex;
	flex-direction: row;
	position: relative;
	max-width: 100%;
	gap: 1.5rem;
	align-self: ${props => props.align};
	align-items: center;
	justify-content: flex-start;
	color: var(--nte-sort);
	line-height: 150%;
	letter-spacing: 0.005em;
	padding: 2rem 1.625rem;
	box-shadow: var(--clickable-box-shadow-app);
	border-radius: var(--border-radius-large);
	i:first-of-type {
		font-size: 1.5rem;
		padding: 0;
		color: var(--button-color);
	}

	@media ${device.mobile} {
		padding: 1.8rem 1rem;
		gap: 1rem;
		overflow: hidden;
		transition: all 0.3s ease;
		max-height: 46rem; // Using animation with max height is not ideal, but less clunky than most solutions: https://css-tricks.com/using-css-transitions-auto-dimensions/
		i:first-of-type {
			align-self: baseline;
		}

		${props =>
			props.isCollapsed &&
			css`
				max-height: 16rem;
			`}
	}

	${props =>
		props.color === INFOBOX_COLOR.blue &&
		css`
			background-color: var(--nte-blaa-20);
			--button-color: var(--nte-blaa-uu);
		`}
	${props =>
		props.color === INFOBOX_COLOR.yellow &&
		css`
			background-color: var(--varsel-lys-gul);
			--button-color: var(--varsel-gul);
		`}
	 ${props =>
		props.color === INFOBOX_COLOR.green &&
		css`
			background-color: var(--nte-groenn-20);
			--button-color: var(--nte-groenn-moerk);
		`}
	${props =>
		props.color === INFOBOX_COLOR.white &&
		css`
			background-color: var(--white);
			--button-color: var(--nte-blaa-uu);
		`}
	${props =>
		props.color === INFOBOX_COLOR.red &&
		css`
			background-color: var(--nte-korall);
			--button-color: var(--nte-korall-800);
		`}
`;

const InnerWrapper = styled.div<InfoBoxWrapperProps>`
	display: flex;
	flex-direction: column;
	gap: 1rem;

	@media ${device.mobile} {
		transition: all 0.3s ease;
		${props =>
			props.isCollapsed &&
			css`
				max-height: 12rem;
			`}
	}
`;

const ContentWrapper = styled.div`
	font-size: 1rem;

	@media ${device.mobile} {
		font-size: 0.9375rem;
	}
`;

const StyledSecondaryButton = styled(SecondaryButton)`
	background-color: var(--white);
	width: max-content;
	border: 2px solid var(--button-color);
	margin: 0;
	color: currentColor;
	font-weight: normal;
	&:hover {
		background-color: var(--button-color);
		color: var(--white);
	}
`;

interface StyledExpandButtonProps {
	color?: INFOBOX_COLOR;
}
const StyledExpandButton = styled.button<StyledExpandButtonProps>`
	display: none;
	position: absolute;
	bottom: 0;
	padding: 1.5rem 0 1rem 0;
	align-items: center;
	justify-content: center;
	width: 100%;
	background: linear-gradient(transparent, var(--nte-blaa-20), var(--nte-blaa-20), var(--nte-blaa-20), var(--nte-blaa-20));
	border: none;
	font-size: 2rem;
	color: var(--nte-sort);

	i {
		font-weight: 400;
	}

	@media ${device.mobile} {
		display: flex;
	}

	${props =>
		props.color === INFOBOX_COLOR.blue &&
		css`
			background: linear-gradient(transparent, var(--nte-blaa-20), var(--nte-blaa-20), var(--nte-blaa-20), var(--nte-blaa-20));
		`}
	${props =>
		props.color === INFOBOX_COLOR.yellow &&
		css`
			background: linear-gradient(transparent, var(--varsel-lys-gul), var(--varsel-lys-gul), var(--varsel-lys-gul), var(--varsel-lys-gul));
		`}
	 ${props =>
		props.color === INFOBOX_COLOR.green &&
		css`
			background: linear-gradient(transparent, var(--nte-groenn-20), var(--nte-groenn-20), var(--nte-groenn-20), var(--nte-groenn-20));
		`}
	${props =>
		props.color === INFOBOX_COLOR.white &&
		css`
			background: linear-gradient(transparent, var(--white), var(--white), var(--white), var(--white));
		`}
	${props =>
		props.color === INFOBOX_COLOR.red &&
		css`
			background: linear-gradient(transparent, var(--nte-korall), var(--nte-korall), var(--nte-korall), var(--nte-korall));
		`}
`;

export interface InfoBoxProps {
	title?: string;
	children?: ReactNode;
	setInfoBoxIsClosed?: (infoBoxIsClosed: boolean) => void;
	buttonText?: string;
	onButtonClicked?: () => void;
	color?: INFOBOX_COLOR;
	align?: INFOBOX_ALIGN;
	isCollapsible?: boolean;
	style?: React.CSSProperties;
	icon?: boolean;
}
export default function InfoBox(props: InfoBoxProps) {
	const [isCollapsed, setIsCollapsed] = useState(true);

	return (
		<Wrapper
			color={props.color || INFOBOX_COLOR.blue}
			align={props.align || INFOBOX_ALIGN.center}
			isCollapsed={props.isCollapsible && isCollapsed}
			style={props.style}>
			{props.icon && (props.color == INFOBOX_COLOR.red || props.color == INFOBOX_COLOR.yellow) && <i className="far fa-exclamation-triangle" />}
			{props.icon && props.color == INFOBOX_COLOR.blue && <i className="far fa-info-circle" />}
			<InnerWrapper isCollapsed={props.isCollapsible && isCollapsed}>
				{props.title && <StyledTitle>{props.title}</StyledTitle>}
				{props.setInfoBoxIsClosed && (
					<StyledClearCloseButton onClick={() => props.setInfoBoxIsClosed(true)}>
						<i className="fa fa-times" />
					</StyledClearCloseButton>
				)}
				<ContentWrapper>{props.children}</ContentWrapper>
				{props.buttonText && props.onButtonClicked && <StyledSecondaryButton onClick={props.onButtonClicked}>{props.buttonText}</StyledSecondaryButton>}
			</InnerWrapper>{' '}
			{props.isCollapsible && isCollapsed && (
				<StyledExpandButton color={props.color || INFOBOX_COLOR.blue} onClick={() => setIsCollapsed(false)}>
					<i className="fa fa-chevron-down" />
				</StyledExpandButton>
			)}
		</Wrapper>
	);
}
