// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentBedriftsIdOrgnrOgNavnQueryVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
}>;


export type HentBedriftsIdOrgnrOgNavnQuery = { bedrift?: { id?: string, orgnummer?: string, navn?: string } };


export const HentBedriftsIdOrgnrOgNavnDocument = gql`
    query hentBedriftsIdOrgnrOgNavn($orgid: ID!) {
  bedrift: hentBedrift(orgid: $orgid) {
    id
    orgnummer
    navn
  }
}
    `;

/**
 * __useHentBedriftsIdOrgnrOgNavnQuery__
 *
 * To run a query within a React component, call `useHentBedriftsIdOrgnrOgNavnQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentBedriftsIdOrgnrOgNavnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentBedriftsIdOrgnrOgNavnQuery({
 *   variables: {
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useHentBedriftsIdOrgnrOgNavnQuery(baseOptions: Apollo.QueryHookOptions<HentBedriftsIdOrgnrOgNavnQuery, HentBedriftsIdOrgnrOgNavnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentBedriftsIdOrgnrOgNavnQuery, HentBedriftsIdOrgnrOgNavnQueryVariables>(HentBedriftsIdOrgnrOgNavnDocument, options);
      }
export function useHentBedriftsIdOrgnrOgNavnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentBedriftsIdOrgnrOgNavnQuery, HentBedriftsIdOrgnrOgNavnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentBedriftsIdOrgnrOgNavnQuery, HentBedriftsIdOrgnrOgNavnQueryVariables>(HentBedriftsIdOrgnrOgNavnDocument, options);
        }
export type HentBedriftsIdOrgnrOgNavnQueryHookResult = ReturnType<typeof useHentBedriftsIdOrgnrOgNavnQuery>;
export type HentBedriftsIdOrgnrOgNavnLazyQueryHookResult = ReturnType<typeof useHentBedriftsIdOrgnrOgNavnLazyQuery>;
export type HentBedriftsIdOrgnrOgNavnQueryResult = Apollo.QueryResult<HentBedriftsIdOrgnrOgNavnQuery, HentBedriftsIdOrgnrOgNavnQueryVariables>;