// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminBedriftDetaljerFragment = { id?: string, navn?: string, orgnummer?: string, kontakt?: string, kontaktEpost?: string, godkjenteTariffer?: Array<string>, brukere?: Array<{ id?: string, fornavn?: string, etternavn?: string, moduler?: Array<string>, epost?: string }>, moduler?: Array<{ id?: string, navn?: string }> };

export type HentBedriftQueryVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
}>;


export type HentBedriftQuery = { bedrift?: { id?: string, navn?: string, orgnummer?: string, kontakt?: string, kontaktEpost?: string, godkjenteTariffer?: Array<string>, brukere?: Array<{ id?: string, fornavn?: string, etternavn?: string, moduler?: Array<string>, epost?: string }>, moduler?: Array<{ id?: string, navn?: string }> } };

export type HentBedriftAdminQueryVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
}>;


export type HentBedriftAdminQuery = { bedrift?: { id?: string, orgnummer?: string, navn?: string, godkjenteTariffer?: Array<string>, brukere?: Array<{ id?: string, fornavn?: string, etternavn?: string, epost?: string, moduler?: Array<string> }>, moduler?: Array<{ id?: string, navn?: string }> } };

export type SettBrukermodulerMutationVariables = Types.Exact<{
  brukerId: Types.Scalars['ID'];
  orgid: Types.Scalars['ID'];
  modulIder: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type SettBrukermodulerMutation = { settBrukermoduler?: { id?: string } };

export type FjernMedlemMutationVariables = Types.Exact<{
  brukerId: Types.Scalars['ID'];
  orgid: Types.Scalars['ID'];
}>;


export type FjernMedlemMutation = { fjernMedlem?: { id?: string } };

export type ResendInvitasjonMutationVariables = Types.Exact<{
  invitasjonId: Types.Scalars['ID'];
  orgid: Types.Scalars['ID'];
}>;


export type ResendInvitasjonMutation = { resendInvitasjon?: { id: string, epost?: string } };

export type SlettInvitasjonMutationVariables = Types.Exact<{
  invitasjonId: Types.Scalars['ID'];
  orgid: Types.Scalars['ID'];
}>;


export type SlettInvitasjonMutation = { slettInvitasjon?: string };

export type HentInvitasjonerQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Tilstand>;
  orgid: Types.Scalars['ID'];
}>;


export type HentInvitasjonerQuery = { invitasjoner?: Array<{ id: string, epost?: string, opprettet?: string }> };

export type LeggTilModulMutationVariables = Types.Exact<{
  modul: Types.Scalars['String'];
  orgid: Types.Scalars['ID'];
}>;


export type LeggTilModulMutation = { leggTilModul?: { id?: string } };

export type FjernModulMutationVariables = Types.Exact<{
  modulId: Types.Scalars['ID'];
  orgid: Types.Scalars['ID'];
}>;


export type FjernModulMutation = { fjernModul?: { id?: string, navn?: string, orgnummer?: string, kontakt?: string, kontaktEpost?: string, godkjenteTariffer?: Array<string>, brukere?: Array<{ id?: string, fornavn?: string, etternavn?: string, moduler?: Array<string>, epost?: string }>, moduler?: Array<{ id?: string, navn?: string }> } };

export type HentTarifferQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HentTarifferQuery = { tariffer?: Array<{ tariffId?: string, tarifftype?: Types.Tarifftype, navn?: string, beskrivelse?: string }> };

export type SettGodkjenteTarifferMutationVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
  tariffIder: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type SettGodkjenteTarifferMutation = { settGodkjenteTariffer?: { id?: string, godkjenteTariffer?: Array<string> } };

export type SlettBedriftMutationVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
}>;


export type SlettBedriftMutation = { slettBedrift?: string };

export const AdminBedriftDetaljerFragmentDoc = gql`
    fragment adminBedriftDetaljer on Bedrift {
  id
  navn
  orgnummer
  kontakt
  kontaktEpost
  godkjenteTariffer
  brukere {
    id
    fornavn
    etternavn
    moduler
    epost
  }
  moduler {
    id
    navn
  }
}
    `;
export const HentBedriftDocument = gql`
    query hentBedrift($orgid: ID!) {
  bedrift: hentBedrift(orgid: $orgid) {
    ...adminBedriftDetaljer
  }
}
    ${AdminBedriftDetaljerFragmentDoc}`;

/**
 * __useHentBedriftQuery__
 *
 * To run a query within a React component, call `useHentBedriftQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentBedriftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentBedriftQuery({
 *   variables: {
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useHentBedriftQuery(baseOptions: Apollo.QueryHookOptions<HentBedriftQuery, HentBedriftQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentBedriftQuery, HentBedriftQueryVariables>(HentBedriftDocument, options);
      }
export function useHentBedriftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentBedriftQuery, HentBedriftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentBedriftQuery, HentBedriftQueryVariables>(HentBedriftDocument, options);
        }
export type HentBedriftQueryHookResult = ReturnType<typeof useHentBedriftQuery>;
export type HentBedriftLazyQueryHookResult = ReturnType<typeof useHentBedriftLazyQuery>;
export type HentBedriftQueryResult = Apollo.QueryResult<HentBedriftQuery, HentBedriftQueryVariables>;
export const HentBedriftAdminDocument = gql`
    query hentBedriftAdmin($orgid: ID!) {
  bedrift: hentBedrift(orgid: $orgid) {
    id
    orgnummer
    navn
    brukere {
      id
      fornavn
      etternavn
      epost
      moduler
    }
    moduler {
      id
      navn
    }
    godkjenteTariffer
  }
}
    `;

/**
 * __useHentBedriftAdminQuery__
 *
 * To run a query within a React component, call `useHentBedriftAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentBedriftAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentBedriftAdminQuery({
 *   variables: {
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useHentBedriftAdminQuery(baseOptions: Apollo.QueryHookOptions<HentBedriftAdminQuery, HentBedriftAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentBedriftAdminQuery, HentBedriftAdminQueryVariables>(HentBedriftAdminDocument, options);
      }
export function useHentBedriftAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentBedriftAdminQuery, HentBedriftAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentBedriftAdminQuery, HentBedriftAdminQueryVariables>(HentBedriftAdminDocument, options);
        }
export type HentBedriftAdminQueryHookResult = ReturnType<typeof useHentBedriftAdminQuery>;
export type HentBedriftAdminLazyQueryHookResult = ReturnType<typeof useHentBedriftAdminLazyQuery>;
export type HentBedriftAdminQueryResult = Apollo.QueryResult<HentBedriftAdminQuery, HentBedriftAdminQueryVariables>;
export const SettBrukermodulerDocument = gql`
    mutation settBrukermoduler($brukerId: ID!, $orgid: ID!, $modulIder: [String!]!) {
  settBrukermoduler(brukerId: $brukerId, orgid: $orgid, modulIder: $modulIder) {
    id
  }
}
    `;
export type SettBrukermodulerMutationFn = Apollo.MutationFunction<SettBrukermodulerMutation, SettBrukermodulerMutationVariables>;

/**
 * __useSettBrukermodulerMutation__
 *
 * To run a mutation, you first call `useSettBrukermodulerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettBrukermodulerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settBrukermodulerMutation, { data, loading, error }] = useSettBrukermodulerMutation({
 *   variables: {
 *      brukerId: // value for 'brukerId'
 *      orgid: // value for 'orgid'
 *      modulIder: // value for 'modulIder'
 *   },
 * });
 */
export function useSettBrukermodulerMutation(baseOptions?: Apollo.MutationHookOptions<SettBrukermodulerMutation, SettBrukermodulerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettBrukermodulerMutation, SettBrukermodulerMutationVariables>(SettBrukermodulerDocument, options);
      }
export type SettBrukermodulerMutationHookResult = ReturnType<typeof useSettBrukermodulerMutation>;
export type SettBrukermodulerMutationResult = Apollo.MutationResult<SettBrukermodulerMutation>;
export type SettBrukermodulerMutationOptions = Apollo.BaseMutationOptions<SettBrukermodulerMutation, SettBrukermodulerMutationVariables>;
export const FjernMedlemDocument = gql`
    mutation fjernMedlem($brukerId: ID!, $orgid: ID!) {
  fjernMedlem(brukerId: $brukerId, orgid: $orgid) {
    id
  }
}
    `;
export type FjernMedlemMutationFn = Apollo.MutationFunction<FjernMedlemMutation, FjernMedlemMutationVariables>;

/**
 * __useFjernMedlemMutation__
 *
 * To run a mutation, you first call `useFjernMedlemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFjernMedlemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fjernMedlemMutation, { data, loading, error }] = useFjernMedlemMutation({
 *   variables: {
 *      brukerId: // value for 'brukerId'
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useFjernMedlemMutation(baseOptions?: Apollo.MutationHookOptions<FjernMedlemMutation, FjernMedlemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FjernMedlemMutation, FjernMedlemMutationVariables>(FjernMedlemDocument, options);
      }
export type FjernMedlemMutationHookResult = ReturnType<typeof useFjernMedlemMutation>;
export type FjernMedlemMutationResult = Apollo.MutationResult<FjernMedlemMutation>;
export type FjernMedlemMutationOptions = Apollo.BaseMutationOptions<FjernMedlemMutation, FjernMedlemMutationVariables>;
export const ResendInvitasjonDocument = gql`
    mutation resendInvitasjon($invitasjonId: ID!, $orgid: ID!) {
  resendInvitasjon(invitasjonId: $invitasjonId, orgid: $orgid) {
    id
    epost
  }
}
    `;
export type ResendInvitasjonMutationFn = Apollo.MutationFunction<ResendInvitasjonMutation, ResendInvitasjonMutationVariables>;

/**
 * __useResendInvitasjonMutation__
 *
 * To run a mutation, you first call `useResendInvitasjonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitasjonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitasjonMutation, { data, loading, error }] = useResendInvitasjonMutation({
 *   variables: {
 *      invitasjonId: // value for 'invitasjonId'
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useResendInvitasjonMutation(baseOptions?: Apollo.MutationHookOptions<ResendInvitasjonMutation, ResendInvitasjonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendInvitasjonMutation, ResendInvitasjonMutationVariables>(ResendInvitasjonDocument, options);
      }
export type ResendInvitasjonMutationHookResult = ReturnType<typeof useResendInvitasjonMutation>;
export type ResendInvitasjonMutationResult = Apollo.MutationResult<ResendInvitasjonMutation>;
export type ResendInvitasjonMutationOptions = Apollo.BaseMutationOptions<ResendInvitasjonMutation, ResendInvitasjonMutationVariables>;
export const SlettInvitasjonDocument = gql`
    mutation slettInvitasjon($invitasjonId: ID!, $orgid: ID!) {
  slettInvitasjon(invitasjonId: $invitasjonId, orgid: $orgid)
}
    `;
export type SlettInvitasjonMutationFn = Apollo.MutationFunction<SlettInvitasjonMutation, SlettInvitasjonMutationVariables>;

/**
 * __useSlettInvitasjonMutation__
 *
 * To run a mutation, you first call `useSlettInvitasjonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSlettInvitasjonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [slettInvitasjonMutation, { data, loading, error }] = useSlettInvitasjonMutation({
 *   variables: {
 *      invitasjonId: // value for 'invitasjonId'
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useSlettInvitasjonMutation(baseOptions?: Apollo.MutationHookOptions<SlettInvitasjonMutation, SlettInvitasjonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SlettInvitasjonMutation, SlettInvitasjonMutationVariables>(SlettInvitasjonDocument, options);
      }
export type SlettInvitasjonMutationHookResult = ReturnType<typeof useSlettInvitasjonMutation>;
export type SlettInvitasjonMutationResult = Apollo.MutationResult<SlettInvitasjonMutation>;
export type SlettInvitasjonMutationOptions = Apollo.BaseMutationOptions<SlettInvitasjonMutation, SlettInvitasjonMutationVariables>;
export const HentInvitasjonerDocument = gql`
    query hentInvitasjoner($filter: Tilstand, $orgid: ID!) {
  invitasjoner: hentInvitasjoner(filter: $filter, orgid: $orgid) {
    id
    epost
    opprettet
  }
}
    `;

/**
 * __useHentInvitasjonerQuery__
 *
 * To run a query within a React component, call `useHentInvitasjonerQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentInvitasjonerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentInvitasjonerQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useHentInvitasjonerQuery(baseOptions: Apollo.QueryHookOptions<HentInvitasjonerQuery, HentInvitasjonerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentInvitasjonerQuery, HentInvitasjonerQueryVariables>(HentInvitasjonerDocument, options);
      }
export function useHentInvitasjonerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentInvitasjonerQuery, HentInvitasjonerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentInvitasjonerQuery, HentInvitasjonerQueryVariables>(HentInvitasjonerDocument, options);
        }
export type HentInvitasjonerQueryHookResult = ReturnType<typeof useHentInvitasjonerQuery>;
export type HentInvitasjonerLazyQueryHookResult = ReturnType<typeof useHentInvitasjonerLazyQuery>;
export type HentInvitasjonerQueryResult = Apollo.QueryResult<HentInvitasjonerQuery, HentInvitasjonerQueryVariables>;
export const LeggTilModulDocument = gql`
    mutation leggTilModul($modul: String!, $orgid: ID!) {
  leggTilModul(modul: $modul, orgid: $orgid) {
    id
  }
}
    `;
export type LeggTilModulMutationFn = Apollo.MutationFunction<LeggTilModulMutation, LeggTilModulMutationVariables>;

/**
 * __useLeggTilModulMutation__
 *
 * To run a mutation, you first call `useLeggTilModulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeggTilModulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leggTilModulMutation, { data, loading, error }] = useLeggTilModulMutation({
 *   variables: {
 *      modul: // value for 'modul'
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useLeggTilModulMutation(baseOptions?: Apollo.MutationHookOptions<LeggTilModulMutation, LeggTilModulMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeggTilModulMutation, LeggTilModulMutationVariables>(LeggTilModulDocument, options);
      }
export type LeggTilModulMutationHookResult = ReturnType<typeof useLeggTilModulMutation>;
export type LeggTilModulMutationResult = Apollo.MutationResult<LeggTilModulMutation>;
export type LeggTilModulMutationOptions = Apollo.BaseMutationOptions<LeggTilModulMutation, LeggTilModulMutationVariables>;
export const FjernModulDocument = gql`
    mutation fjernModul($modulId: ID!, $orgid: ID!) {
  fjernModul: fjernModul(modulId: $modulId, orgid: $orgid) {
    ...adminBedriftDetaljer
  }
}
    ${AdminBedriftDetaljerFragmentDoc}`;
export type FjernModulMutationFn = Apollo.MutationFunction<FjernModulMutation, FjernModulMutationVariables>;

/**
 * __useFjernModulMutation__
 *
 * To run a mutation, you first call `useFjernModulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFjernModulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fjernModulMutation, { data, loading, error }] = useFjernModulMutation({
 *   variables: {
 *      modulId: // value for 'modulId'
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useFjernModulMutation(baseOptions?: Apollo.MutationHookOptions<FjernModulMutation, FjernModulMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FjernModulMutation, FjernModulMutationVariables>(FjernModulDocument, options);
      }
export type FjernModulMutationHookResult = ReturnType<typeof useFjernModulMutation>;
export type FjernModulMutationResult = Apollo.MutationResult<FjernModulMutation>;
export type FjernModulMutationOptions = Apollo.BaseMutationOptions<FjernModulMutation, FjernModulMutationVariables>;
export const HentTarifferDocument = gql`
    query hentTariffer {
  tariffer: hentTarifferStrombestilling {
    tariffId
    tarifftype
    navn
    beskrivelse
  }
}
    `;

/**
 * __useHentTarifferQuery__
 *
 * To run a query within a React component, call `useHentTarifferQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentTarifferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentTarifferQuery({
 *   variables: {
 *   },
 * });
 */
export function useHentTarifferQuery(baseOptions?: Apollo.QueryHookOptions<HentTarifferQuery, HentTarifferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentTarifferQuery, HentTarifferQueryVariables>(HentTarifferDocument, options);
      }
export function useHentTarifferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentTarifferQuery, HentTarifferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentTarifferQuery, HentTarifferQueryVariables>(HentTarifferDocument, options);
        }
export type HentTarifferQueryHookResult = ReturnType<typeof useHentTarifferQuery>;
export type HentTarifferLazyQueryHookResult = ReturnType<typeof useHentTarifferLazyQuery>;
export type HentTarifferQueryResult = Apollo.QueryResult<HentTarifferQuery, HentTarifferQueryVariables>;
export const SettGodkjenteTarifferDocument = gql`
    mutation settGodkjenteTariffer($orgid: ID!, $tariffIder: [String!]!) {
  settGodkjenteTariffer(orgid: $orgid, tariffIder: $tariffIder) {
    id
    godkjenteTariffer
  }
}
    `;
export type SettGodkjenteTarifferMutationFn = Apollo.MutationFunction<SettGodkjenteTarifferMutation, SettGodkjenteTarifferMutationVariables>;

/**
 * __useSettGodkjenteTarifferMutation__
 *
 * To run a mutation, you first call `useSettGodkjenteTarifferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettGodkjenteTarifferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settGodkjenteTarifferMutation, { data, loading, error }] = useSettGodkjenteTarifferMutation({
 *   variables: {
 *      orgid: // value for 'orgid'
 *      tariffIder: // value for 'tariffIder'
 *   },
 * });
 */
export function useSettGodkjenteTarifferMutation(baseOptions?: Apollo.MutationHookOptions<SettGodkjenteTarifferMutation, SettGodkjenteTarifferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettGodkjenteTarifferMutation, SettGodkjenteTarifferMutationVariables>(SettGodkjenteTarifferDocument, options);
      }
export type SettGodkjenteTarifferMutationHookResult = ReturnType<typeof useSettGodkjenteTarifferMutation>;
export type SettGodkjenteTarifferMutationResult = Apollo.MutationResult<SettGodkjenteTarifferMutation>;
export type SettGodkjenteTarifferMutationOptions = Apollo.BaseMutationOptions<SettGodkjenteTarifferMutation, SettGodkjenteTarifferMutationVariables>;
export const SlettBedriftDocument = gql`
    mutation slettBedrift($orgid: ID!) {
  slettBedrift(orgid: $orgid)
}
    `;
export type SlettBedriftMutationFn = Apollo.MutationFunction<SlettBedriftMutation, SlettBedriftMutationVariables>;

/**
 * __useSlettBedriftMutation__
 *
 * To run a mutation, you first call `useSlettBedriftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSlettBedriftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [slettBedriftMutation, { data, loading, error }] = useSlettBedriftMutation({
 *   variables: {
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useSlettBedriftMutation(baseOptions?: Apollo.MutationHookOptions<SlettBedriftMutation, SlettBedriftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SlettBedriftMutation, SlettBedriftMutationVariables>(SlettBedriftDocument, options);
      }
export type SlettBedriftMutationHookResult = ReturnType<typeof useSlettBedriftMutation>;
export type SlettBedriftMutationResult = Apollo.MutationResult<SlettBedriftMutation>;
export type SlettBedriftMutationOptions = Apollo.BaseMutationOptions<SlettBedriftMutation, SlettBedriftMutationVariables>;