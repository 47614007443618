import { format, startOfYear } from 'date-fns';
import { subMonths } from 'date-fns/esm';
import nbLocale from 'date-fns/locale/nb';
import React, { useState } from 'react';
import { isMobile } from 'web/GlobalStyling';
import useAnlegg from 'web/hooks/useAnlegg';
import useFilter from 'web/hooks/useFilter';
import useKostnad from 'web/hooks/useKostnad';
import { useTenant } from 'web/hooks/useTenant';
import { SegmentCategory, useTracking } from 'web/hooks/useTracking';
import { Opploesninger, getPeriodeFromOpploesning } from 'web/lib/dates.utils';
import useKostnadsEksport from 'web/lib/exports/useKostnadseksport';
import { getKostnadFilterData } from 'web/lib/filter.utils';
import GraphTemplate from 'web/templates/GraphTemplate';

export default function KostnadPage() {
	const { trackPageView } = useTracking();
	trackPageView(SegmentCategory.Kostnad);
	const { eksporter } = useKostnadsEksport();
	const tenant = useTenant();
	const { totalSaldoUtestaaende, kostnadSisteAar } = tenant;

	const [filter, setFilter] = useFilter();
	const filterData = getKostnadFilterData(filter, setFilter);

	const [selectedDate, setSelectedDate] = useState(new Date());
	const datoRange = getPeriodeFromOpploesning(new Date(selectedDate), Opploesninger.maned);

	let monthKostnadMap: { [key: string]: number } = {};
	let totalThisYear = 0;
	let verdier = kostnadSisteAar?.verdier || [];

	let kostnadsData = [...verdier].slice().sort((a: any, b: any) => {
		const aTime = new Date(a.fraTid);
		const bTime = new Date(b.fraTid);
		if (aTime > bTime) return 1;
		if (bTime > aTime) return -1;
		return 0;
	});

	kostnadsData?.forEach((kostnad: any) => {
		if (new Date(kostnad.fraTid) >= startOfYear(new Date())) {
			totalThisYear += kostnad.kostnad || 0;
		}
		const fromDate = new Date(kostnad.fraTid);
		monthKostnadMap[format(fromDate, 'yyyy-MM', { locale: nbLocale })] = kostnad.kostnad;
	});

	const lastMonth =
		(Object.keys(monthKostnadMap)[Object.keys(monthKostnadMap)?.length - 1] &&
			new Date(Object.keys(monthKostnadMap)[Object.keys(monthKostnadMap)?.length - 1])) ||
		subMonths(new Date(), 1);
	const lastMonthLabel = format(lastMonth, 'yyyy-MM', { locale: nbLocale });

	const { meterPointIds } = useAnlegg(filter);

	const { ref, data, loading, error, isMissingData } = useKostnad({
		maalere: meterPointIds,
		filter: filter,
		selectedDate: selectedDate,
		useShortXLabel: isMobile(),
	});

	return (
		<GraphTemplate
			title="Kostnad"
			loading={loading}
			isMissingData={isMissingData}
			missingDataElement={error ? <span>Beklager, det oppstod en teknisk feil ved henting av data</span> : ''}
			filterData={filterData}
			datePickerData={{
				selectedDate: selectedDate,
				onChange: setSelectedDate,
				opploesning: Opploesninger.maned,
			}}
			onClickExport={() => {
				eksporter({
					orgid: tenant.id,
					fraDato: format(datoRange.fra, 'yyyy-MM-dd', { locale: nbLocale }),
					tilDato: format(datoRange.til, 'yyyy-MM-dd', { locale: nbLocale }),
					maalere: meterPointIds,
					fakturamerker: filter.fakturamerker,
					naeringskoder: filter.naeringskoder,
				});
			}}
			chartRef={ref}
			chartData={data as any}
			primaryInfoPanels={[
				{
					value: monthKostnadMap[lastMonthLabel]?.toLocaleString(['nb-NO', 'fr-CA'], { maximumFractionDigits: 0 }) || '0',
					unit: 'kr',
					description: `kostnader i ${format(lastMonth, 'MMMM', { locale: nbLocale }) || 'forrige måned'}`,
					icon: <i className="fa fa-calendar" />,
				},
				{
					value: totalSaldoUtestaaende?.toLocaleString(['nb-NO', 'fr-CA'], { maximumFractionDigits: 0 }),
					unit: 'kr',
					description: 'til betaling',
					icon: <i className="fa fa-coin" />,
				},
				{
					value: totalThisYear.toLocaleString(['nb-NO', 'fr-CA'], { maximumFractionDigits: 0 }),
					unit: 'kr',
					description: `kostnader så langt i ${new Date().getFullYear()}`,
					icon: <i className="fa fa-chart-pie-alt" />,
				},
			]}
		/>
	);
}
