import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'web/components/atoms/Button';
import DataError from 'web/components/molecules/form/DataError';
import TextField from 'web/components/molecules/form/TextField';
import Modal from 'web/components/molecules/Modal';
import { useOppdaterBedriftMutation } from 'web/components/organisms/modals/RedigerKundeModal.graphql-gen';
import Form from 'web/hooks/useForm';
import { isEmailValid } from 'web/lib/validate';
import { useHentBedriftQuery } from 'web/pages/AdminPage.graphql-gen';

interface RedigerKundeModalProps {
	orgId: string;
	onClose: () => void;
}
export default function RedigerKundeModal({ orgId, onClose }: RedigerKundeModalProps) {
	const { t } = useTranslation('admin');
	const [formError, setFormError] = useState(null);
	const [updateBedrift, { loading: updateBedriftLoading, error: updateBedriftError }] = useOppdaterBedriftMutation();
	const {
		loading: bedriftLoading,
		error: bedriftError,
		data: { bedrift } = { bedrift: null },
	} = useHentBedriftQuery({
		variables: {
			orgid: orgId,
		},
		fetchPolicy: 'cache-and-network',
	});

	const loading = bedriftLoading || updateBedriftLoading;
	const error = bedriftError || updateBedriftError;

	return (
		<Modal title={t('RedigerKundeModal.title')} onClose={onClose}>
			<Form
				loading={loading}
				onSubmit={(value: any) => {
					if (!value.orgnummer) {
						setFormError({ orgnummer: t('RedigerKundeModal.orgnummerMissingError') });
						return;
					}

					value.orgnummer = value.orgnummer.replace(/[^0-9]/g, '');

					if (value.orgnummer?.length !== 9) {
						setFormError({ orgnummer: t('RedigerKundeModal.orgnummerError') });
						return;
					}

					if (!isEmailValid(value.kontaktEpost)) {
						setFormError({ kontaktEpost: t('RedigerKundeModal.emailError') });
						return;
					}

					setFormError(null);

					updateBedrift({ variables: { oppdaterBedrift: value } }).then(() => onClose && onClose());
				}}>
				<DataError error={error} />
				<TextField defaultValue={bedrift?.navn} required name="navn" label={t('Kunder.element.navn')} />
				<TextField
					defaultValue={bedrift?.orgnummer}
					required
					disabled
					error={formError?.orgnummer}
					name="orgnummer"
					label={t('Kunder.element.orgnummer')}
					onChange={(e: any) => {
						e.preventDefault();
						setFormError({ orgnummer: t(null) });
					}}
				/>
				<TextField defaultValue={bedrift?.kontakt} required name="kontaktperson" label={t('Kunder.element.kontaktperson')} />
				<TextField
					defaultValue={bedrift?.kontaktEpost}
					required
					error={formError?.kontaktEpost}
					name="kontaktEpost"
					label={t('Kunder.element.kontaktEpost')}
					onChange={(e: any) => {
						e.preventDefault();
						setFormError({ kontaktEpost: t(null) });
					}}
				/>
				<Button>{t('RedigerKundeModal.save')}</Button>
			</Form>
		</Modal>
	);
}
