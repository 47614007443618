import React, { ReactNode } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { device } from 'web/GlobalStyling';

interface PanelWrapperProps {
	withBorder?: boolean;
}
const Wrapper = styled.div<PanelWrapperProps>`
	flex: 1;
	background: var(--white);
	position: relative;
	align-items: center;

	${props =>
		props.withBorder &&
		css`
			border: 1px solid var(--nte-graa-lys);
			border-radius: var(--border-radius);
			padding: 2rem;
			@media ${device.mobile} {
				padding: 1rem;
			}
		`}
`;

const Arrow = styled.span`
	height: 2.625rem;
	width: 2.625rem;
	min-width: 2.625rem;
	border-radius: 50%;

	background: var(--nte-graa-lys);
	color: var(--nte-graa-uu);

	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;

	cursor: pointer;

	@media ${device.tablet} {
		display: none;
	}
`;

const StyledButtonLink = styled(Link)`
	display: flex;
	flex: 1;
	padding: 2rem;
	background: var(--white);
	border: 1px solid var(--nte-graa-lys);
	position: relative;
	border-radius: var(--border-radius);
	text-decoration: none;
	box-shadow: var(--clickable-box-shadow-app);
	align-items: center;
	:hover {
		text-decoration: none;
		box-shadow: none;
		opacity: 1;
		& > span {
			background: var(--nte-blaa-10);
		}
	}
`;

const StyledExternalLink = styled.a`
	display: flex;
	flex: 1;
	padding: 2rem;
	background: var(--white);
	border: 1px solid var(--nte-graa-lys);
	position: relative;
	border-radius: var(--border-radius);
	text-decoration: none;
	box-shadow: var(--clickable-box-shadow-app);
	:hover {
		text-decoration: none;
		box-shadow: none;
		opacity: 1;
		& > span {
			background: var(--nte-blaa-10);
		}
	}
`;

export interface PanelProps {
	to?: string;
	href?: string;
	openNewTab?: boolean;
	className?: any;
	style?: React.CSSProperties;
	arrowStyle?: React.CSSProperties;
	children?: ReactNode;
	withBorder?: boolean;
	rest?: any;
	onClick?: (e?: any) => void;
}

export default function Panel({ to, href, openNewTab, withBorder, arrowStyle, children, ...rest }: PanelProps) {
	let { bedriftId } = useParams() as any;
	if (to) {
		return (
			<StyledButtonLink to={`/${bedriftId}/${to}/`} {...rest}>
				{children}
				<Arrow style={arrowStyle}>
					<i className="fal fa-chevron-right" />
				</Arrow>
			</StyledButtonLink>
		);
	} else if (href) {
		return (
			<StyledExternalLink href={href} target={openNewTab ? '_blank' : '_self'} {...rest}>
				{children}
				<Arrow style={arrowStyle}>
					<i className="fal fa-chevron-right" />
				</Arrow>
			</StyledExternalLink>
		);
	} else {
		return (
			<Wrapper {...rest} withBorder={withBorder}>
				{children}
			</Wrapper>
		);
	}
}
