import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import LargeBoxLink from 'web/components/atoms/LargeBoxLink';
import PageTitle from 'web/components/atoms/PageTitle';
import Paragraph from 'web/components/atoms/Paragraph';
import Push from 'web/components/atoms/Push';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import useFilter, { DEFAULT_FILTER } from 'web/hooks/useFilter';
import { useTracking } from 'web/hooks/useTracking';
import ErrorTemplate from 'web/templates/ErrorTemplate';

const BedriftWrapper = styled.div`
	max-width: 600px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1rem;
`;

interface BedriftElement {
	id?: string;
	navn?: string;
	orgnummer?: string;
}

interface MineBedrifterProps {
	bedrifter?: BedriftElement[];
	loading?: boolean;
	error?: any;
}
export default function MineBedrifterTemplate({ bedrifter = [], loading, error }: MineBedrifterProps) {
	const { t } = useTranslation('generic');
	const [_, setFilter] = useFilter();
	const { trackGroup } = useTracking();

	if (loading) {
		return <LoadingOverlay loading={loading} text="Henter dine bedrifter" isTemplate />;
	}

	if (error) {
		return <ErrorTemplate message={error.toString()} />;
	}

	if (bedrifter?.length === 0) {
		return (
			<ErrorTemplate
				title={t('MineBedrifter.error.title')}
				message={<span dangerouslySetInnerHTML={{ __html: t('MineBedrifter.error.message') }} />}
				hideBackButton
			/>
		);
	} else if (bedrifter?.length === 1) {
		const bedrift = bedrifter[0];
		trackGroup(bedrift?.orgnummer, bedrift?.navn);

		return <Redirect to={`/${bedrift.id}/`} />;
	} else {
		return (
			<div>
				<PageTitle isCenter>{t('MineBedrifter.title')}</PageTitle>
				<Push height="1rem" />
				<Paragraph>
					<span dangerouslySetInnerHTML={{ __html: t('MineBedrifter.message') }} />
				</Paragraph>
				<Push />
				<BedriftWrapper>
					{bedrifter?.map(b => (
						<LargeBoxLink
							key={b.id}
							label={b.navn}
							to={`/${b.id}/hovedsiden/`}
							onClick={() => {
								trackGroup(b.orgnummer, b.navn);
								setFilter(DEFAULT_FILTER);
							}}
						/>
					))}
				</BedriftWrapper>
			</div>
		);
	}
}
