import { motion, PanInfo, useDragControls } from 'framer-motion';
import React, { useRef } from 'react';
import FlexPageWrapper from 'web/components/atoms/FlexPageWrapper';
import PageTitle, { PageTitleEditable } from 'web/components/atoms/PageTitle';
import TabBar, { Tab } from 'web/components/molecules/TabBar';
import { isMobile } from 'web/GlobalStyling';
import { Anlegg } from 'web/models/Anlegg';

export interface AnleggDetails extends Anlegg {
	fraDato?: string;
	description?: string;
	product?: string;
	harFjutt?: boolean;
	forbrukForNow?: number;
	kostnadForNow?: number;
}
export interface Tag {
	value: string;
	label: string;
}

export interface TabProps extends SingleAnleggTemplateProps {
	isTabActive?: boolean;
}
interface SingleAnleggTemplateProps {
	anlegg?: Anlegg;
	hasTitleEditingAccess?: boolean;
	onUpdateTitle?: (newtitle: string, revert: (value: string) => void) => void;
	tabs?: Tab[];
	currentTab?: string;
	onChangeTab?: (newTab: string) => void;
}

export default function SingleAnleggTemplate({ anlegg, hasTitleEditingAccess, onUpdateTitle, tabs, currentTab, onChangeTab }: SingleAnleggTemplateProps) {
	const direction = useRef(1);

	const variants = {
		enter: (direction: number) => {
			return {
				x: [direction > 0 ? 100 : -100, 0],
				zIndex: 1,
				opacity: 1,
				display: 'flex',
			};
		},
		hidden: (direction: number) => {
			return {
				x: 0,
				opacity: 0,
				zIndex: 0,
				display: 'none',
			};
		},
	};

	const controls = useDragControls();

	function handleOnDrag(info: PanInfo, tabIndex: number) {
		if (info.offset.x > 100 || info.offset.x < -100) {
			if (info.offset.x > 0) {
				direction.current = -1;
				const newIndex = tabIndex >= 1 ? tabIndex - 1 : tabIndex;
				return onChangeTab(tabs[newIndex].value);
			} else {
				direction.current = 1;
				const newIndex = tabIndex < tabs?.length - 1 ? tabIndex + 1 : tabIndex;
				return onChangeTab(tabs[newIndex]?.value);
			}
		}
	}

	return (
		<FlexPageWrapper>
			{(hasTitleEditingAccess && <PageTitleEditable value={anlegg?.egenAnleggsbeskrivelse || anlegg?.tittel} onChanged={onUpdateTitle} />) || (
				<PageTitle>{anlegg?.egenAnleggsbeskrivelse || anlegg?.tittel}</PageTitle>
			)}

			<TabBar
				onChangeTab={newTab => {
					const indexNewTab = tabs.findIndex(t => t.value === newTab);
					const indexCurrent = tabs.findIndex(t => t.value === currentTab);
					const newDirection = indexNewTab > indexCurrent ? 1 : -1;
					direction.current = newDirection;
					onChangeTab(newTab);
				}}
				currentTab={currentTab}
				tabs={tabs}
			/>
			<div style={{ width: '100%' }}>
				{tabs?.map((Tab, i) => (
					<motion.div
						key={i}
						custom={direction.current}
						initial={false}
						animate={Tab.value === currentTab ? 'enter' : 'hidden'}
						variants={variants}
						transition={{
							x: { type: 'spring', duration: 0.2 },
							opacity: { duration: 0.3 },
						}}
						drag={isMobile()}
						dragConstraints={{ left: -100, right: 100 }}
						dragElastic={1}
						dragSnapToOrigin={true}
						dragControls={controls}
						onDrag={(e, info) => isMobile() && handleOnDrag(info, i)}
						onDragStart={(event: any, info) => {
							if (event.target.closest('.c3-brush') || event.target.closest('.oversikt-graf')) {
								(controls as any).componentControls?.forEach((entry: any) => {
									entry.stop(event, info);
								});
							}
						}}>
						<Tab.component key={i} isTabActive={Tab.value === currentTab} />
					</motion.div>
				))}
			</div>
		</FlexPageWrapper>
	);
}
