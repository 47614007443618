import { ElhubAnlegg } from 'generated/graphql-types';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import Center from 'web/components/atoms/Center';
import Push from 'web/components/atoms/Push';
import TextField from 'web/components/molecules/form/TextField';
import { ANLEGG_SKJEMA_STAGES, MeldInnAnleggModalState } from 'web/components/organisms/modals/MeldInnAnleggModal';
import { useHentElhubAnleggLazyQuery } from 'web/components/organisms/modals/MeldInnAnleggModal/Step1.graphql-gen';
import Form from 'web/hooks/useForm';
import { Anlegg } from 'web/models/Anlegg';
import { Tenant } from 'web/models/Tenant';

export const StyledArrowButton = styled.button`
	line-height: 1.25rem;
	background: none;
	border: none;
`;

export const ArrowLeftIcon = styled.i`
	margin-right: 0.375rem;
	font-size: 1rem;
`;

const StyledFoundAnleggButton = styled.button`
	padding: 12px;
	background: var(--white);
	border: 2px solid var(--nte-border-color);
	border-radius: 6px;
	text-align: left;
	position: relative;
	width: 100%;
`;

const FoundAnleggButtonLabel = styled.span`
	width: 100%;
	clear: both;
	display: inline-block;
`;

const ArrowRightIconFoundAnlegg = styled(ArrowLeftIcon)`
	position: absolute;
	right: 0.75rem;
	top: calc(50% - 7px);
	margin-right: 0;
	margin-left: 0.375rem;
`;

const FoundAnleggButtonSubLine = styled.span`
	width: 100%;
	clear: both;
	font-size: 0.75rem;
	display: inline-block;
`;

interface FoundAnleggButtonProps {
	anlegg: ElhubAnlegg;
	onClick: (e: any) => void;
}
export function FoundAnleggButton({ anlegg, ...rest }: FoundAnleggButtonProps) {
	const label = !anlegg.husnummer
		? `${anlegg.gateadresse}, ${anlegg.postnummer} ${anlegg.poststed}`
		: `${anlegg.gateadresse} ${anlegg.husnummer}, ${anlegg.poststed}`;
	return (
		<StyledFoundAnleggButton type="button" {...rest}>
			<FoundAnleggButtonLabel>{label}</FoundAnleggButtonLabel>

			<ArrowRightIconFoundAnlegg className="fas fa-long-arrow-right" />

			<FoundAnleggButtonSubLine>{anlegg.maalernummer}</FoundAnleggButtonSubLine>
		</StyledFoundAnleggButton>
	);
}

const checkAndUpdateLengthError = debounce(({ value, isLoading, error, setError }) => {
	if (value?.length < 18 && !isLoading) {
		setError('Målepunkt-ID må bestå av 18 siffer');
		return;
	}
	if (error == 'Målepunkt-ID må bestå av 18 siffer') {
		setError(null);
	}
}, 2000);

export interface AnleggInnmeldingsskjemaStepProps {
	tenant: Tenant;
	anlegg: Anlegg[];
	state: MeldInnAnleggModalState;
	setState: (state: MeldInnAnleggModalState) => void;
	onFinish: () => void;
}
export default function AnleggInnmeldingsskjemaStep1({ tenant, anlegg, state, setState }: AnleggInnmeldingsskjemaStepProps) {
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const [foundAnlegg, setFoundAnlegg] = useState(null);
	const [hentElhubAnlegg] = useHentElhubAnleggLazyQuery({});

	function checkIfMalerpunktIdAlreadyExists(maalerpunktId: string) {
		return anlegg.find(anlegg => anlegg?.maalerpunktId == maalerpunktId);
	}

	return (
		<Form loading={isLoading}>
			<Center>Har du anleggets Målepunkt-ID (18 siffer) så kan du skrive den inn her. Forrige eier kan ha dette på en faktura.</Center>
			<Push />
			<TextField
				label="Målepunkt-ID"
				name="maalepunktId"
				disabled={isLoading}
				error={error}
				onChange={async (e: any) => {
					setError(null);
					setIsLoading(false);

					let value = e.target.value;
					value = value.replace(/[^0-9\.]+/g, '');

					if (value?.length > 18) {
						value = value.slice(0, 18);
					}
					e.target.value = value;

					checkAndUpdateLengthError({
						value,
						isLoading,
						error,
						setError,
					});

					if (value?.length < 18) {
						return;
					}

					if (checkIfMalerpunktIdAlreadyExists(value)) {
						setError("Denne Målepunkt-ID'en er allerede registrert.");
						return;
					}

					setIsLoading(true);

					const { data } = await hentElhubAnlegg({
						variables: {
							orgid: tenant?.id,
							maalepunktId: value,
						},
					}).catch(error => {
						setIsLoading(false);
						if (error.message?.indexOf('HTTP 50') != -1) {
							setError(
								'Vi fikk ikke hentet informasjon om dette anlegget på grunn av teknisk feil. Du kan prøve igjen senere eller kontakte kundeservice.'
							);
						} else {
							setError('Fant ikke anlegget. Sjekk om du har tastet riktig verdi');
						}
						setFoundAnlegg(null);
						return null;
					});

					setIsLoading(false);

					if (data?.anlegg) {
						setFoundAnlegg(data.anlegg);
					} else {
						setError('Fant ikke anlegget. Sjekk om du har tastet riktig verdi');
					}
				}}
			/>

			{foundAnlegg && (
				<FoundAnleggButton
					anlegg={foundAnlegg}
					onClick={(e: any) => {
						e.preventDefault();
						e.stopPropagation();
						setState({
							...state,
							stage: ANLEGG_SKJEMA_STAGES.PROJECT_INPUT,
							elhubAnlegg: foundAnlegg,
						});
					}}
				/>
			)}
			{!foundAnlegg && (
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						<StyledArrowButton
							type="button"
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();

								// Jump to next step, for manuel registrartion stead.
								setState({ ...state, stage: ANLEGG_SKJEMA_STAGES.START });
							}}>
							<ArrowLeftIcon className="fas fa-long-arrow-left" />
							Tilbake
						</StyledArrowButton>
					</div>
					<div>Oppgi målepunkt-id for å gå videre</div>
				</div>
			)}
		</Form>
	);
}
