// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDistributedDocumentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDistributedDocumentsQuery = { getDistributedDocuments?: Array<{ contentType?: string, created?: string, customTitle?: string, description?: string, id: string, title?: string, url?: string, companies?: Array<{ kundeNavn: string, orgNr: string }> }> };

export type DeleteDistributedDocumentMutationVariables = Types.Exact<{
  documentId: Types.Scalars['ID'];
}>;


export type DeleteDistributedDocumentMutation = { deleteDistributedDocument?: string };


export const GetDistributedDocumentsDocument = gql`
    query getDistributedDocuments {
  getDistributedDocuments {
    companies {
      kundeNavn
      orgNr
    }
    contentType
    created
    customTitle
    description
    id
    title
    url
  }
}
    `;

/**
 * __useGetDistributedDocumentsQuery__
 *
 * To run a query within a React component, call `useGetDistributedDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistributedDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistributedDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDistributedDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetDistributedDocumentsQuery, GetDistributedDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDistributedDocumentsQuery, GetDistributedDocumentsQueryVariables>(GetDistributedDocumentsDocument, options);
      }
export function useGetDistributedDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDistributedDocumentsQuery, GetDistributedDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDistributedDocumentsQuery, GetDistributedDocumentsQueryVariables>(GetDistributedDocumentsDocument, options);
        }
export type GetDistributedDocumentsQueryHookResult = ReturnType<typeof useGetDistributedDocumentsQuery>;
export type GetDistributedDocumentsLazyQueryHookResult = ReturnType<typeof useGetDistributedDocumentsLazyQuery>;
export type GetDistributedDocumentsQueryResult = Apollo.QueryResult<GetDistributedDocumentsQuery, GetDistributedDocumentsQueryVariables>;
export const DeleteDistributedDocumentDocument = gql`
    mutation deleteDistributedDocument($documentId: ID!) {
  deleteDistributedDocument(documentId: $documentId)
}
    `;
export type DeleteDistributedDocumentMutationFn = Apollo.MutationFunction<DeleteDistributedDocumentMutation, DeleteDistributedDocumentMutationVariables>;

/**
 * __useDeleteDistributedDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDistributedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDistributedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDistributedDocumentMutation, { data, loading, error }] = useDeleteDistributedDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDeleteDistributedDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDistributedDocumentMutation, DeleteDistributedDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDistributedDocumentMutation, DeleteDistributedDocumentMutationVariables>(DeleteDistributedDocumentDocument, options);
      }
export type DeleteDistributedDocumentMutationHookResult = ReturnType<typeof useDeleteDistributedDocumentMutation>;
export type DeleteDistributedDocumentMutationResult = Apollo.MutationResult<DeleteDistributedDocumentMutation>;
export type DeleteDistributedDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDistributedDocumentMutation, DeleteDistributedDocumentMutationVariables>;