import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';
import CircleLoader from 'web/components/atoms/CircleLoader';
import Logo from 'web/components/atoms/svgs/Logo';
import { device } from 'web/GlobalStyling';

interface LoadingProps {
	isTemplate?: boolean;
}
const LoadingOverlayWrapper = styled(motion.div)<LoadingProps>`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	pointer-events: none;
	z-index: 9999;
	border-radius: var(--border-radius-smallest);
	overflow: hidden;
	pointer-events: inherit;
	background: var(--overlay-white);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	${props =>
		props.isTemplate &&
		css`
			position: fixed;
			background: white;
		`}
`;

const LoadingContainer = styled(motion.div)<LoadingProps>`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: var(--nte-blaa);
	margin-bottom: 2rem;
	gap: 1rem;
	padding: 0 1rem;
	.loading-text {
		font-size: ${props => (props.isTemplate ? '1.5rem' : '1rem')};
		font-weight: bold;
		text-align: center;
	}
	${props =>
		props.isTemplate &&
		css`
			gap: 1.5rem;
		`}
`;
const StyledLogo = styled(Logo)`
	width: 100px;
	position: absolute;
	top: 2rem;
	@media ${device.mobile} {
		width: 50px;
	}
`;

interface LoadingOverlayProps {
	loading: boolean;
	loadingTextEnding?: string;
	text?: string;
	isTemplate?: boolean;
}
/** Parent should have position:relative for the overflow to work**/
export default function LoadingOverlay({ loading, loadingTextEnding, text, isTemplate }: LoadingOverlayProps) {
	const lodingVariants = {
		hidden: { scale: 0.5, opacity: 0 },
		visible: { scale: 1, opacity: 1 },
	};
	const loadingTransition = {
		scale: { duration: 0.2, type: 'spring' },
		opacity: { duration: 0.1, delay: 0.1 },
	};
	return (
		<AnimatePresence>
			{loading && (
				<LoadingOverlayWrapper
					variants={lodingVariants}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1, transition: { when: 'beforeChildren', duration: 0 } }}
					exit={{ opacity: 0, transition: { when: 'afterChildren', duration: 0.2 } }}
					isTemplate={isTemplate}>
					{isTemplate && <StyledLogo />}
					<LoadingContainer
						variants={lodingVariants}
						initial="hidden"
						animate="visible"
						exit="hidden"
						transition={loadingTransition}
						isTemplate={isTemplate}>
						<CircleLoader size={isTemplate ? '6rem' : null} />
						<span className="loading-text">{text ? text : `Laster inn ${loadingTextEnding ? loadingTextEnding : 'data'}...`}</span>
					</LoadingContainer>
				</LoadingOverlayWrapper>
			)}
		</AnimatePresence>
	);
}
