import { Anleggsstatus } from 'generated/graphql-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AnleggBadge } from 'web/components/atoms/Badge';
import Center from 'web/components/atoms/Center';
import FlexPageWrapper from 'web/components/atoms/FlexPageWrapper';
import PageTitle, { PageTitleButton } from 'web/components/atoms/PageTitle';
import AnleggListElement from 'web/components/molecules/AnleggListElement';
import SistBesokte from 'web/components/molecules/SistBesokte';
import FilterBar from 'web/components/organisms/FilterBar';
import { MobileDesktopSwitcher } from 'web/components/organisms/MobileDesktopSwitcher';
import MobileFilter from 'web/components/organisms/MobileFilter';
import { useHasGroup, useIsAdmin } from 'web/hooks/useAuthorization';
import { FilterDataArray } from 'web/lib/filter.utils';
import { Anlegg } from 'web/models/Anlegg';

export const DetailsLinkButton = styled(Link)`
	white-space: nowrap;
	padding: 1rem;
`;

interface AnleggTemplateProps {
	sisteBesokte?: Anlegg[];
	anlegg?: Anlegg[];
	onClickExport?: () => void;
	onClickRegisterAnlegg?: () => void;
	filterData: FilterDataArray;
	visOpphoerte?: boolean;
}

export default function AnleggTemplate({ anlegg, sisteBesokte, onClickExport, onClickRegisterAnlegg, filterData, visOpphoerte }: AnleggTemplateProps) {
	const isAdmin = useIsAdmin();
	const isBedriftsadmin = useHasGroup({ group: 'Bedriftsadmin' });

	return (
		<FlexPageWrapper>
			<MobileDesktopSwitcher
				desktop={() => (
					<>
						{!!sisteBesokte?.length && (
							<SistBesokte sistBesokte={sisteBesokte.map(anlegg => ({ item: anlegg, to: `/anlegg/${anlegg?.maalerpunktId}` }))} />
						)}

						<PageTitle style={{ position: 'relative' }}>
							Anlegg ({anlegg?.length ?? ''})
							<div
								style={{
									display: 'inline-flex',
								}}>
								<PageTitleButton
									type="button"
									onClick={() => {
										onClickExport();
									}}>
									<i className="fa fa-file-csv" /> Eksporter anlegg
								</PageTitleButton>
								{(isBedriftsadmin || isAdmin) && (
									<PageTitleButton
										type="button"
										onClick={() => {
											onClickRegisterAnlegg();
										}}>
										<i className="fa fa-plus" /> Registrer anlegg
									</PageTitleButton>
								)}
							</div>
						</PageTitle>

						<FilterBar filterData={filterData} />

						<table className="stripe">
							<thead>
								<tr>
									<td>Status</td>
									<td>Navn</td>
									<td>Målepunkt-ID</td>
									<td>Målernummer</td>
									<td className="right" style={{ whiteSpace: 'nowrap' }}>
										Estimert årsforbruk
									</td>
									<td></td>
								</tr>
							</thead>
							<tbody>
								{(!anlegg || anlegg?.length === 0) && (
									<tr>
										<td className="center" colSpan={6}>
											Fant ingen {visOpphoerte ? 'opphørte' : 'aktive'} anlegg,
											<br />
											prøv et annet filter
										</td>
									</tr>
								)}

								{anlegg.map((a, i) => (
									<tr key={i}>
										<td>
											<AnleggBadge status={a.anleggsstatus} />
										</td>
										{(a.anleggsstatus !== Anleggsstatus.UNDER_OVERTAKELSE && (
											<>
												<td className="bold">{a.egenAnleggsbeskrivelse || a.tittel}</td>
												<td>{a?.maalerpunktId}</td>
												<td>{a.maalernummer}</td>
												<td className="right">{a?.aarsforbruk?.toLocaleString(['no-NO', 'fr-CA']) ?? 'ukjent'} kWh</td>
												<td className="right">
													<DetailsLinkButton to={`${a?.maalerpunktId}/`}>
														Detaljer <i className="fa fa-chevron-right" />
													</DetailsLinkButton>
												</td>
											</>
										)) || <td colSpan={5}>{a?.maalerpunktId || 'Anlegget'} blir tilgjengelig når det er meldt inn til Elhub</td>}
									</tr>
								))}
							</tbody>
						</table>
					</>
				)}
				mobile={() => (
					<>
						<PageTitle>
							Anlegg
							{(isBedriftsadmin || isAdmin) && (
								<PageTitleButton
									type="button"
									onClick={() => {
										onClickRegisterAnlegg();
									}}>
									<i className="fa fa-plus" /> Registrer anlegg
								</PageTitleButton>
							)}
						</PageTitle>
						<MobileFilter filterData={filterData} mobileFilterId="anleggFilter" closeButtonText="Vis anlegg" />
						{(!anlegg || !anlegg?.length) && (
							<Center>
								Fant ingen {visOpphoerte ? 'opphørte' : 'aktive'} anlegg,
								<br />
								prøv et annet filter
							</Center>
						)}
						{anlegg?.map((anlegg, i) => (
							<AnleggListElement key={i} to={`./${anlegg?.maalerpunktId}/`} anlegg={anlegg} />
						))}
					</>
				)}
			/>
		</FlexPageWrapper>
	);
}
