import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Button, { ButtonWrapper, SecondaryButton } from 'web/components/atoms/Button';
import PageTitle from 'web/components/atoms/PageTitle';
import Paragraph from 'web/components/atoms/Paragraph';
import Push from 'web/components/atoms/Push';
import InfoBox, { INFOBOX_COLOR } from 'web/components/molecules/InfoBox';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import useAuthHelpers from 'web/hooks/useAuthHelpers';
import { useAuthorization } from 'web/hooks/useAuthorization';
import useFilter, { DEFAULT_FILTER } from 'web/hooks/useFilter';
import { useTracking } from 'web/hooks/useTracking';
import { useAksepterInvitasjonMutation, useHentInvitasjonsstatusQuery } from 'web/pages/generic/InvitasjonPage.graphql-gen';
import { IFrame, LoginPageWrapper } from 'web/pages/generic/LoginPage';

const InvitationPageWrapper = styled(LoginPageWrapper)`
	justify-content: flex-start;
`;

const ConfirmationPageWrapper = styled.div`
	background: var(--nte-blaa-himmel);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 0.5rem;

	display: flex;
	align-items: center;
`;

const ConfirmationPageModal = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	width: max-content;
	max-width: 100%;
	background: var(--white);
	padding: 1.2rem 1.6rem;
	margin: 0 auto;
	border-radius: var(--border-radius);
	border: 1px solid var(--nte-graa-lys);
	font-size: 1rem;
	color: var(--nte-sort);
	& button {
		font-size: 0.875rem;
	}
`;

export const StyledHeaderBackButton = styled.button`
	top: 1rem;
	left: 1rem;
	position: absolute;

	color: var(--nte-blaa-uu);
	font-size: 0.875rem;
	font-weight: 500;
	padding: 16px 0;
	background: none;
	border: none;
	display: inline-block;
	i {
		color: var(--nte-blaa-logo);
		margin-right: 6px;
		font-size: 1rem;
	}
`;

const StyledHomeButton = styled(StyledHeaderBackButton)`
	position: initial;
	align-self: flex-start;
	padding: 0;
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.125rem;
	font-size: 1rem;
	line-height: 1.5;
	align-self: center;
	max-width: 37.5rem;
	& p {
		margin: 0;
	}
`;

interface InvitasjonParamProps {
	invitationId: string;
	invitationToken: string;
}
interface LoggedInConfirmationPageProps {
	orgName: string;
	userName: string;
	acceptInvitation: () => void;
	logout: any;
}
const LoggedInConfirmationPage = ({ orgName, userName, acceptInvitation, logout }: LoggedInConfirmationPageProps) => {
	return (
		<ConfirmationPageWrapper>
			<ConfirmationPageModal>
				<StyledHomeButton
					type="button"
					onClick={() => {
						window.location.href = '/';
					}}>
					<i className="far fa-arrow-left" />
					Bedriftsportalen
				</StyledHomeButton>
				<TextWrapper>
					<PageTitle>Fullfør registrering i Bedriftsportalen</PageTitle>
					<p>
						Du er pålogget i Bedriftsportalen som <b>{userName}</b> og vil få tilgang til <b>{orgName}</b> dersom du nå godtar invitasjonen.
						<br />
						<br />
						Om du ønsker å registrere tilgangen på en annen bruker må du trykke "Bytt bruker".
						<br />
						Gå tilbake til Bedriftsportalen om du ønsker å avbryte registreringen og fullføre senere.
					</p>
				</TextWrapper>
				<ButtonWrapper>
					<Button
						onClick={() => {
							acceptInvitation();
						}}
						style={{ margin: 0 }}>
						<i className="far fa-check-circle" />
						Godta invitasjon
					</Button>
					<SecondaryButton onClick={() => logout(window.location.href)} style={{ margin: 0 }}>
						Bytt bruker
					</SecondaryButton>
				</ButtonWrapper>
			</ConfirmationPageModal>
		</ConfirmationPageWrapper>
	);
};

interface AksepterInvitasjonProps {
	orgName: string;
	loading?: boolean;
	setLoading?: (loading: boolean) => void;
}
function AksepterInvitasjon({ orgName, loading, setLoading }: AksepterInvitasjonProps) {
	const { user, tilgangerLoading, refetchTilganger } = useAuthorization();
	const { logout, logInFramePath } = useAuthHelpers();
	const { isAuthenticated, isLoading } = useAuth();
	const { silentLogin } = useAuthHelpers();

	const [hasError, setHasError] = useState(false);
	const { invitationId, invitationToken } = useParams<InvitasjonParamProps>();
	const [aksepterInvitasjon, { loading: aksepterInvitasjonLoading }] = useAksepterInvitasjonMutation();
	const [_, setFilter] = useFilter();
	const { trackGroup } = useTracking();

	useEffect(() => {
		if (aksepterInvitasjonLoading) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [aksepterInvitasjonLoading]);

	async function acceptInvitation() {
		setLoading(true);
		try {
			const { data } = await aksepterInvitasjon({
				variables: {
					aksepterInvitasjon: {
						invitasjonId: invitationId,
						token: invitationToken,
					},
				},
			});
			if (data) {
				const invitasjon = data?.invitasjon;
				silentLogin('accept invitation').then((result: boolean) => {
					refetchTilganger();
					if (invitasjon?.orgId) {
						trackGroup(invitasjon.orgnummer, orgName);
						setFilter(DEFAULT_FILTER);

						const url = `${invitasjon.orgId}/hovedsiden/?fromInvitation=true`;
						const getUrl = window.location;
						const baseUrl = `${getUrl.protocol}//${getUrl.host}/`;

						window.location.href = baseUrl + url;
						return;
					} else {
						setHasError(true);
						console.error(invitasjon);
					}
				});
			}
		} catch (error) {
			console.error(error);
			if (error.toString().indexOf('Denne invitasjonen har allerede blitt akseptert') > -1) {
				window.location.href = '/';
				return;
			}
		}
	}

	const loginServiceMessage = '';

	if (hasError) {
		return (
			<InvitationPageWrapper>
				<PageTitle isCenter>Beklager, en feil har oppstått</PageTitle>

				<Paragraph>
					Trykk <a href="/">her</a> for å gå tilbake til Bedriftsportalen
				</Paragraph>
				<Push />
			</InvitationPageWrapper>
		);
	}
	if (loading || aksepterInvitasjonLoading || tilgangerLoading) {
		return <LoadingOverlay isTemplate loading text="Aksepterer invitasjon" />;
	}

	if (isAuthenticated && !isLoading) {
		return (
			<LoggedInConfirmationPage
				userName={user.name}
				orgName={orgName}
				acceptInvitation={acceptInvitation}
				logout={(redirectUrl: string) => logout(redirectUrl)}
			/>
		);
	}
	// OK
	return (
		<InvitationPageWrapper>
			<div>
				<PageTitle isCenter>Invitasjon</PageTitle>

				<Push />

				{loginServiceMessage && <Paragraph dangerouslySetInnerHTML={{ __html: loginServiceMessage }} />}
				<Paragraph>Logg på med BankID for å godta invitasjon</Paragraph>
			</div>
			{!isAuthenticated && <IFrame frameBorder="none" src={logInFramePath} />}
		</InvitationPageWrapper>
	);
}

enum INVITASJONSSTATUSER {
	LOADING = 'LOADING',
	AKSEPTERT = 'AKSEPTERT',
	IKKE_AKSEPTERT = 'IKKE_AKSEPTERT',
	FINNES_IKKE = 'FINNES_IKKE',
	AVSLAATT = 'AVSLAATT',
}

export default function InvitasjonPage() {
	const { invitationId, invitationToken } = useParams<InvitasjonParamProps>();
	const { tilgangerLoading } = useAuthorization();

	const [invitasjonsStatus, setInvitasjonsStatus] = useState(INVITASJONSSTATUSER.LOADING);
	const [aksepterInvitasjonLoading, setAksepterInvitasjonLoading] = useState(false);

	const {
		data: { hentInvitasjonsstatus: { organisasjonsnavn, status } } = {
			hentInvitasjonsstatus: { organisasjonsnavn: '', status: INVITASJONSSTATUSER.LOADING },
		},
		loading: hentInvitasjonsstatusLoading,
		error,
	} = useHentInvitasjonsstatusQuery({
		variables: {
			invitasjonId: invitationId,
			token: invitationToken,
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!status) {
			return;
		}
		setInvitasjonsStatus(INVITASJONSSTATUSER[status as INVITASJONSSTATUSER] || INVITASJONSSTATUSER.FINNES_IKKE);
	}, [status, organisasjonsnavn]);

	if (invitasjonsStatus === INVITASJONSSTATUSER.LOADING || aksepterInvitasjonLoading || hentInvitasjonsstatusLoading || tilgangerLoading) {
		return <LoadingOverlay isTemplate loading text="Henter invitasjon" />;
	}

	if (invitasjonsStatus === INVITASJONSSTATUSER.AVSLAATT) {
		return (
			<InvitationPageWrapper>
				<PageTitle isCenter>Invitasjon</PageTitle>
				<InfoBox>Beklager, denne invitasjonen er allerede avslått. Ønsker du tilgang må du be om ny invitasjon.</InfoBox>
				<Paragraph>
					Trykk <a href="/">her</a> for å gå tilbake til Bedriftsportalen
				</Paragraph>
			</InvitationPageWrapper>
		);
	}

	if (invitasjonsStatus === INVITASJONSSTATUSER.AKSEPTERT) {
		return (
			<InvitationPageWrapper>
				<PageTitle isCenter>Invitasjon</PageTitle>
				<InfoBox color={INFOBOX_COLOR.green}>Denne invitasjonen er allerede akseptert.</InfoBox>
				<Paragraph>
					Trykk <a href="/">her</a> for å gå tilbake til Bedriftsportalen
				</Paragraph>
			</InvitationPageWrapper>
		);
	}

	if (invitasjonsStatus === INVITASJONSSTATUSER.IKKE_AKSEPTERT) {
		return <AksepterInvitasjon orgName={organisasjonsnavn} loading={aksepterInvitasjonLoading} setLoading={setAksepterInvitasjonLoading} />;
	}

	return (
		<InvitationPageWrapper>
			<PageTitle isCenter>Invitasjon</PageTitle>
			<InfoBox>Beklager, invitasjonen finnes ikke. Ta kontakt med den som inviterte deg for å få tilgang.</InfoBox>
			<Paragraph>
				Trykk <a href="/">her</a> for å gå tilbake til Bedriftsportalen
			</Paragraph>
		</InvitationPageWrapper>
	);
}
