import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MobilFilterButton, MobilFilterButtonCounter } from 'web/components/atoms/Button';
import Portal from 'web/components/molecules/Portal';
import FilterDrawerContent from 'web/components/organisms/FilterDrawerContent';
import MobileDrawer from 'web/components/organisms/MobileDrawer';
import SelectedFilterTags from 'web/components/organisms/SelectedFilterTags';
import { FilterDataArray, FilterElements } from 'web/lib/filter.utils';

function countActiveFilters(filterData: FilterDataArray) {
	let counter = 0;
	for (let index = 0; index < filterData?.length; index++) {
		const dataElement = filterData[index];
		switch (dataElement.type) {
			case FilterElements.SELECT:
				if (dataElement.isMultiselect) {
					counter += dataElement.selected?.length;
				} else if (dataElement.selected && !dataElement.isSortSelect) {
					counter += 1;
				}
				break;
			case FilterElements.CHECKBOX:
				if (dataElement.checked) {
					counter += 1;
				} else {
					break;
				}
				break;
			case FilterElements.TEXTINPUT:
				if (dataElement.value?.length) {
					counter += 1;
				} else {
					break;
				}
				break;
			default:
				break;
		}
	}
	return counter;
}

const FilterWrapper = styled.div`
	text-align: right;
	width: 100%;
	display: flex;
	flex-direction: column;
`;

interface MobileFilterProps {
	mobileFilterId: string;
	closeButtonText?: string;
	filterData?: FilterDataArray;
}
export default function MobileFilter(props: MobileFilterProps) {
	const history = useHistory<any>();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [isBottom, setIsBottom] = useState(false);
	const drawerContentRef = useRef(null);

	useEffect(() => {
		if (!(history?.location?.state?.drawerOpenId == props.mobileFilterId) && isDrawerOpen) {
			setIsDrawerOpen(false);
		}

		if (history?.location?.state?.drawerOpenId == props.mobileFilterId && !isDrawerOpen) {
			setIsDrawerOpen(true);
		}
	}, [history?.location?.state?.drawerOpenId]);

	const selectedFilterLength = countActiveFilters(props.filterData);

	return (
		<>
			<FilterWrapper>
				<MobilFilterButton
					onClick={() => {
						if (!isDrawerOpen) {
							history.push(history.location.pathname, { drawerOpenId: props.mobileFilterId });
						}

						setIsDrawerOpen(true);
					}}
					style={{ padding: '0 17px' }}>
					filter {!!selectedFilterLength && <MobilFilterButtonCounter>{selectedFilterLength}</MobilFilterButtonCounter>}
				</MobilFilterButton>
				<SelectedFilterTags
					filterData={props.filterData}
					onOpenFilter={() => {
						if (!isDrawerOpen) {
							history.push(history.location.pathname, { drawerOpenId: props.mobileFilterId });
						}
						setIsDrawerOpen(true);
					}}
					{...props}
				/>
			</FilterWrapper>
			<Portal>
				<MobileDrawer
					open={isDrawerOpen}
					onClose={() => {
						setIsDrawerOpen(false);
					}}
					setIsBottom={val => setIsBottom(val)}
					drawerContentRef={drawerContentRef}>
					<FilterDrawerContent
						drawerContentRef={drawerContentRef}
						filterData={props.filterData}
						onClose={() => {
							setIsDrawerOpen(false);
						}}
						closeButtonText={props.closeButtonText}
						isBottom={isBottom}
					/>
				</MobileDrawer>
			</Portal>
		</>
	);
}
