import { FjuttlisteFragmentDoc } from 'web/hooks/SingleAnlegg/useUpdateAnleggMedFjutt.graphql-gen';
import apolloClient from 'web/lib/apolloClient';

export function updateAnleggMedFjutt(anleggsId = '', bedriftsid = '') {
	apolloClient.cache.updateFragment(
		{
			id: `Bedrift:${bedriftsid}`,
			fragment: FjuttlisteFragmentDoc,
			overwrite: true,
		},
		(bedrift: any) => {
			return {
				...bedrift,
				fjutter: [
					...bedrift.fjutter,
					{
						anleggsId,
						__typename: 'Fjutt',
					},
				],
			};
		}
	);
}
