import React from 'react';
import styled from 'styled-components';

const LabelName = styled.span`
	margin-left: 0.5rem;
`;

interface ModulCheckboxListProps {
	moduler: any[];
	checkedModuler?: any[];
	labelStyle?: React.CSSProperties;
}
export default function ModulChecboxList({ moduler, checkedModuler, labelStyle }: ModulCheckboxListProps) {
	return (
		<>
			{moduler.map((m: any, i: number) => (
				<div style={{ marginBottom: '1rem' }} key={i}>
					<label>
						<input
							type="checkbox"
							value={m.id}
							name="modulCheckbox"
							defaultChecked={checkedModuler ? checkedModuler?.indexOf(m.navn) > -1 : false}
						/>
						<LabelName style={labelStyle}>{m.navn}</LabelName>
					</label>
				</div>
			))}
		</>
	);
}
