import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'web/components/atoms/Button';
import Push from 'web/components/atoms/Push';
import DataError from 'web/components/molecules/form/DataError';
import InfoBox from 'web/components/molecules/InfoBox';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import ModulList, { ModulListElement } from 'web/components/molecules/ModulList';
import { useHentTilgjengeligeModulerQuery } from 'web/components/organisms/ModulSelect.graphql-gen';
import { useIsAuthorized } from 'web/hooks/useAuthorization';

interface ModulSelectProps {
	onSelect: (value: string[]) => void;
	loading?: boolean;
	notIn?: string[];
	defaultValue?: string[];
	saveLabel?: string;
}
export default function ModulSelect({ onSelect, loading: asyncLoading, notIn, defaultValue, saveLabel }: ModulSelectProps) {
	const isAdmin = useIsAuthorized({ resource: 'kundeadm:ADMIN' });
	const [selected, setSelected] = useState(defaultValue || []);
	const { t } = useTranslation('admin');
	const {
		loading,
		error,
		data: { moduler } = { moduler: [] },
	} = useHentTilgjengeligeModulerQuery({
		fetchPolicy: 'network-only',
	});

	let filteredModuler = moduler.filter(navn => {
		if (notIn) {
			return notIn.indexOf(navn) < 0;
		}

		return true;
	});

	if (!isAdmin) {
		filteredModuler = filteredModuler.filter(navn => navn !== 'Systemadmin');
	}

	return (
		<div style={{ position: 'relative' }}>
			<LoadingOverlay loading={loading || asyncLoading} text="Laster inn moduler" />

			{!(loading || asyncLoading) && (
				<>
					<DataError error={error} />
					{(filteredModuler?.length === 0 && <InfoBox>{t('ModulSelect.empty.message')}</InfoBox>) || (
						<>
							<ModulList>
								{filteredModuler.map((navn, i) => (
									<ModulListElement
										key={i}
										name={navn}
										selected={selected.indexOf(navn) > -1}
										onClick={() => {
											let index = selected.indexOf(navn);

											if (index > -1) {
												selected.splice(index, 1);
												return setSelected([...selected]);
											}

											setSelected([...selected, navn]);
										}}
									/>
								))}
							</ModulList>
							<Push />
							<Button disabled={!selected?.length} type="button" onClick={() => onSelect(selected)}>
								{saveLabel || t('ModulSelect.save')}
							</Button>
						</>
					)}
				</>
			)}
		</div>
	);
}
