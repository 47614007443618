import jwt_decode from 'jwt-decode';
import { User } from 'oidc-client-ts';
import { useAuth } from 'react-oidc-context';
import { logError } from 'web/hooks/useErrorLogging';
import { useTracking } from 'web/hooks/useTracking';
import apolloClient from 'web/lib/apolloClient';

export interface parsedToken {
	acr: string;
	aud: string[];
	auth_time: number;
	azp: string;
	birthdate: string;
	email_verified: boolean;
	email: string;
	exp: number;
	family_name: string;
	given_name: string;
	iat: number;
	iss: string;
	jti: string;
	name: string;
	nationalID: string;
	nonce: string;
	orgnr: string[];
	preferred_username: string;
	providerId: string;
	resource_access: any[];
	scope: string;
	session_state: string;
	sid: string;
	sub: string;
	typ: string;
}

export default function useAuthHelpers(): {
	logout: (redirectUrl?: string) => void;
	login: () => void;
	silentLogin: (source: string, onErrorCallback?: () => void) => Promise<boolean>;
	accessToken?: string;
	idToken?: string;
	refreshToken?: string;
	accessTokenParsed?: parsedToken;
	refreshTokenParsed?: parsedToken;
	isAuthenticated: boolean;
	logInFramePath: string;
	doIdentifyUserInSegment: boolean;
	setDoIdentifyUserInSegment: (val: boolean) => void;
} {
	const auth = useAuth();
	const { track } = useTracking();
	const getUrl = window.location;
	const logInFramePath = `${getUrl.protocol}//${getUrl.host}/login-frame`;
	const DO_IDENTIFY_USER = 'data.doIdentifyUserInSegment';

	const setDoIdentifyUser = (value: boolean) => {
		localStorage.setItem(DO_IDENTIFY_USER, `${value}`);
	};

	const doIdentifyUserInSegment = () => {
		return localStorage.getItem(DO_IDENTIFY_USER) === 'true';
	};

	const isAuthenticated = () => {
		return auth.isAuthenticated;
	};
	const getAccessToken = () => {
		return auth.user?.access_token;
	};

	const getAccessTokenParsed = (): parsedToken => {
		const token = getAccessToken();
		return token && token !== null ? jwt_decode(token) : null;
	};

	const getRefreshToken = () => {
		return auth.user?.refresh_token;
	};

	const getRefreshTokenParsed = (): parsedToken => {
		const token = getRefreshToken();
		return token && token !== null ? jwt_decode(token) : null;
	};

	const getIdToken = () => {
		return auth.user?.id_token;
	};

	const logout = (redirectUrl?: string) => {
		track('User Signed Out');

		const getUrl = window.location;
		const baseUrl = `${getUrl.protocol}//${getUrl.host}`;
		auth.signoutRedirect({ post_logout_redirect_uri: redirectUrl || baseUrl, id_token_hint: getIdToken() });
		localStorage.clear();
		apolloClient.clearStore();
	};

	const isAdUser = getAccessTokenParsed()?.providerId === 'ActiveDirectory';

	// Custom signin callback, in accordance with https://github.com/authts/react-oidc-context
	const onSigninCallback = (user?: User) => {
		window.history.replaceState({}, document.title, window.location.pathname);
	};

	function login() {
		auth.signinRedirect({ redirect_uri: window.location.href, extraQueryParams: { ui_locales: 'no' } })
			.then(() => {
				onSigninCallback();
				console.log('Login successful');
			})
			.catch(e => {
				console.error('Login failed: ', e);
				if (!isAdUser) {
					logError(`Login failed: ${e}`);
				}
			});
	}

	async function silentLogin(source: string, onErrorCallback?: () => void) {
		return auth
			.signinSilent({ redirect_uri: window.location.href, extraQueryParams: { ui_locales: 'no' }, prompt: 'none' })
			.then(user => {
				if (!user) {
					console.error('Silent login failed, user is null');
					if (onErrorCallback) {
						onErrorCallback();
					} else {
						window.location.reload();
					}
					return false;
				}
				onSigninCallback(user);
				console.log('Silent login successful');
				return true;
			})
			.catch(e => {
				console.error('Silent login failed: ', e);
				if (onErrorCallback) {
					onErrorCallback();
				} else {
					window.location.reload();
				}
				if (e.error !== 'login_required' && !isAdUser) {
					logError(`Silent login from source ${source || 'unknown'} failed: ${e}`);
				}
				return false;
			});
	}

	return {
		login,
		silentLogin,
		logout,
		accessToken: getAccessToken(),
		idToken: getIdToken(),
		refreshToken: getRefreshToken(),
		accessTokenParsed: getAccessTokenParsed(),
		refreshTokenParsed: getRefreshTokenParsed(),
		isAuthenticated: isAuthenticated(),
		logInFramePath,
		setDoIdentifyUserInSegment: val => setDoIdentifyUser(val),
		doIdentifyUserInSegment: doIdentifyUserInSegment(),
	};
}
