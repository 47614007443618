import React from 'react';

export default function Logo(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 168 79" xmlns="http://www.w3.org/2000/svg" {...props}>
			<defs>
				<linearGradient x1="61.529%" y1="46.55%" x2="39.333%" y2="53.962%" id="a">
					<stop stopColor="#FFF" stopOpacity="0" offset="0%" />
					<stop stopColor="#FFF" offset="100%" />
				</linearGradient>
				<linearGradient x1="34.692%" y1="54.218%" x2="65.325%" y2="45.795%" id="b">
					<stop stopColor="#FFF" stopOpacity="0" offset="0%" />
					<stop stopColor="#FFF" offset="100%" />
				</linearGradient>
			</defs>
			<g fillRule="nonzero" fill="none">
				<path
					d="M75.3 25.4s6.6-12 8.6-15.1c2-3.1 7.4-9.7 13.2-9.7 5.8-.1 9.3 3.2 11.9 6.5 2.6 3.2 8 12.8 9.7 15.3 1.7 2.5 5 7 8.6 9.2 3.6 2.2 5.4 2.2 5.4 2.2h-10.1c-3.7 0-10.8-1.1-14.5-3.2-3.7-2.1-7.6-5.2-12.1-7.9s-7.8-2.9-10.3-2.7c-2.5.2-5.8 1.7-7.3 2.9s-3.1 2.5-3.1 2.5z"
					fill="#FFF"
				/>
				<path d="M76.5 24.4c-9.3 7.5-17.6 22-30 22C29.2 46.4 29.9 34 8.2 34H7.1c22.4 0 20.4 30 41.5 30 12.2 0 19.7-21.5 27.9-39.6z" fill="#7BC2E3" />
				<g opacity=".38">
					<path fill="url(#a)" d="M12.7 20.1l3.7-1.2 3.2-2.8L16.7.5l-7.8 2z" transform="translate(38 44)" />
					<path fill="url(#b)" d="M12.8 20.1H8.7l-3.9-1L.8 2l8 .3z" transform="translate(38 44)" />
				</g>
				<path
					d="M48.6 64.6C38 64.6 33 57 28.2 49.7c-4.9-7.4-10-15.1-21.1-15.1H2.9v-.9h4.2c11.6 0 16.8 7.9 21.8 15.5 4.9 7.4 9.5 14.5 19.6 14.5 10.2 0 17.3-16.1 24.1-31.6C79.6 16.3 86.8 0 97.6 0c8.3 0 12.9 7.7 17.8 15.9 5.2 8.7 10.5 17.8 20.9 17.8h26.9v.9h-26.9c-10.9 0-16.4-9.3-21.7-18.2-4.7-8-9.2-15.5-17-15.5C87.4.9 80.3 17 73.5 32.5c-6.9 15.7-14.1 32.1-24.9 32.1z"
					fill="#6CBFE2"
				/>
				<path
					d="M48.1 60.3c-10.1 0-14.9-6.4-19.6-12.5-4.9-6.4-9.9-13.1-21.1-13.1H2.9v-1.1h4.5c11.7 0 16.9 6.9 21.9 13.5 4.7 6.2 9.2 12.1 18.8 12.1 9.7 0 16.6-13.8 23.3-27.2C78.1 18.6 85 4.8 94.9 4.8c7.9 0 12.3 6.4 17 13.2 5.3 7.6 10.7 15.5 21.6 15.5h29.7v1.1h-29.7c-11.5 0-17.3-8.5-22.5-16-4.5-6.6-8.8-12.8-16.1-12.8-9.3 0-16 13.5-22.5 26.6-6.9 13.7-13.9 27.9-24.3 27.9z"
					fill="#41B4DE"
				/>
				<path
					d="M47.6 56c-9.5 0-14.3-5.2-18.9-10.3-4.9-5.4-9.9-11-21.1-11H2.9v-1.3h4.8c11.7 0 16.9 5.8 22 11.4 4.6 5.1 8.9 9.8 17.9 9.8 9.2 0 16-11.6 22.6-22.8 6.7-11.4 13-22.3 22.1-22.3 7.5 0 11.8 5.2 16.3 10.7 5.3 6.5 10.8 13.1 22.3 13.1h32.5v1.3h-32.5c-12.1 0-18-7.2-23.3-13.6-4.5-5.5-8.4-10.2-15.3-10.2-8.3 0-14.4 10.5-20.9 21.6C64.5 44.1 57.5 56 47.6 56z"
					fill="#00AAD4"
				/>
				<path
					d="M47.1 51.7c-9 0-13.6-4.1-18.1-8-4.9-4.3-9.9-8.8-21.1-8.8h-5v-1.5h5c11.7 0 17 4.7 22.1 9.2 4.4 3.9 8.6 7.7 17 7.7 8.8 0 15.4-9.3 21.8-18.3s12.4-17.4 20.6-17.4c7 0 11.1 4 15.5 8.2 5.2 5 11 10.6 23 10.6h35.2v1.5H128c-12.6 0-18.7-5.9-24.1-11.1-4.3-4.2-8-7.8-14.4-7.8-7.4 0-13.2 8.2-19.4 16.8-6.6 9.3-13.5 18.9-23 18.9z"
					fill="#009FD3"
				/>
				<path
					d="M46.6 47.4c-8.4 0-12.7-2.3-17.2-5.3-4.9-3.3-9.9-6-21.1-6H2.4c-2.5 0-2.5-2.9 0-2.9h5.9c11.7 0 17.2 3.7 22.1 6.9 4.3 2.9 8.3 5.6 16.2 5.6 8.3 0 14.8-7 21.1-13.7 6.1-6.5 11.8-12.7 19.1-12.7 6.6 0 10.5 2.9 14.7 5.9 5.2 3.8 11.1 8 23.8 8h40.1c2.3 0 2.3 2.9 0 2.9h-40.1c-13.2 0-19.3-5-24.8-8.9-4.1-3-7.7-6.1-13.7-6.1-6.5 0-12 5.9-17.8 12.1-6.6 6.9-13.4 14.2-22.4 14.2z"
					fill="#0097CF"
				/>
				<g fill="#000">
					<path d="M162.6 51.6c2.5 0 4-1.9 4-5v-.4h-20.4c-2.4 0-3.6 1.1-3.6 3.6v28.9h19.8c3.1 0 3.9-3.3 4-5v-.4h-18.1v-8.5h11.5c1.2 0 2.5-.9 2.5-2.7 0-1.8-1.2-2.7-2.5-2.7h-11.5v-7.7h14.3v-.1zM112.4 46.1c-2.5 0-4 1.9-4 5v.4h12.3v27h6v-27h8.2c2.5 0 4-1.9 4-5v-.4h-26.5zM104.2 46.1c-3.3 0-5.2 1.6-5.2 4.2v17.8c-3.3-4-15.9-19.5-16.6-20.3-1.3-1.6-2.8-1.6-4.2-1.6h-1v32.4h5.7V56.7L99.6 77c1.3 1.6 2.8 1.6 4.2 1.6h1V46.1h-.6z" />
				</g>
			</g>
		</svg>
	);
}
