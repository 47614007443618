import { GraphQLError } from 'graphql';
import jwt_decode from 'jwt-decode';
import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import useAuthHelpers, { parsedToken } from 'web/hooks/useAuthHelpers';
import { authContext } from 'web/hooks/useAuthorization';
import { useHentDataForInnloggetBrukerQuery } from 'web/hooks/useAuthorization.provider.graphql-gen';
import { useTracking } from 'web/hooks/useTracking';
import apolloClient from 'web/lib/apolloClient';

export function AuthorizationProvider({ children }: any) {
	const { trackSignIn } = useTracking();
	const { doIdentifyUserInSegment, setDoIdentifyUserInSegment } = useAuthHelpers();
	const auth = useAuth();

	const {
		loading: tilgangerLoading,
		error: tilgangerError,
		data: { innloggetBruker } = { innloggetBruker: { tilganger: [], harkToken: null, id: '', trackingId: null } },
		refetch: refetchTilganger,
	} = useHentDataForInnloggetBrukerQuery({
		skip: auth.isLoading || (!auth.isLoading && !auth.isAuthenticated),
		client: apolloClient,
		errorPolicy: 'all',
	});

	useEffect(() => {
		// Track login
		if (doIdentifyUserInSegment) {
			trackSignIn(innloggetBruker?.trackingId, getAccessTokenParsed());
			setDoIdentifyUserInSegment(false);
		}
	}, [innloggetBruker]);

	// Prevent crash if Hark returns nothing
	let reducedTilgangerError = tilgangerError;
	if (tilgangerError && tilgangerError?.graphQLErrors && tilgangerError?.graphQLErrors?.length < 2) {
		reducedTilgangerError =
			tilgangerError.graphQLErrors?.find((error: GraphQLError) => error.path[1] === 'harkToken') && tilgangerError?.graphQLErrors?.length <= 1
				? null
				: tilgangerError;
	}

	const getAccessTokenParsed = (): parsedToken => {
		const token = auth.user?.access_token;
		return token && token !== null ? jwt_decode(token) : null;
	};
	return (
		<authContext.Provider
			value={{
				user: auth.user?.access_token && {
					...getAccessTokenParsed(),
					resourceAccess: getAccessTokenParsed()?.resource_access,
				},
				refetchTilganger,
				harkToken: innloggetBruker?.harkToken,
				tilganger: innloggetBruker?.tilganger,
				tilgangerLoading: tilgangerLoading,
				tilgangerError: reducedTilgangerError,
			}}>
			{children}
		</authContext.Provider>
	);
}
