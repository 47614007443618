import { FakturaFilter } from 'generated/graphql-types';
import { useTranslation } from 'react-i18next';
import { DropdownAlign } from 'web/components/molecules/DropdownButton';
import useAnlegg from 'web/hooks/useAnlegg';
import useProfile from 'web/hooks/useProfile';
import { Energisone } from 'web/hooks/useSpotpris';

export interface SelectItem {
	value: string;
	label: string;
	subLabel?: string;
}
export interface Filter {
	kategorier?: string[];
	naeringskoder?: string[];
	fakturamerker?: string[];
	fakturaFilter?: string;
	sorter?: string;
	visOpphoert?: boolean;
	search?: string;
	energisone?: Energisone;
}

export enum FilterElements {
	SELECT = 'SELECT',
	CHECKBOX = 'CHECKBOX',
	TEXTINPUT = 'TEXTINPUT',
}

export type FilterDataElement = {
	title?: string;
	onChange: (data: any) => void;
	props?: any;
	isBlank?: boolean; // Desktop only
	filterBarStyle?: React.CSSProperties; // Desktop only
	filterDrawerStyle?: React.CSSProperties; // Mobile only
};

export type FilterDataSelectElement = FilterDataElement & {
	type: FilterElements.SELECT;
	options: SelectItem[];
	selected?: string | string[];
	emptyMsg?: any;
	active?: boolean;
	useSearch?: boolean;
	searchPlaceholder?: string;
	isSortSelect?: boolean; // TODO: remove when sort is moved
	isMultiselect?: boolean;
	useFilterDrawerTabSelect?: boolean; // Mobile only, can only be used with !isMultiSelect
	isDeselectable?: boolean;
	dropdownAlign?: DropdownAlign; // Desktop only
	hasFixedWidth?: boolean; // Mobile only
	isNarrowDropdown?: boolean;
	isDropdown?: boolean; // Mobile only
};

export type FilterDataCheckBoxElement = FilterDataElement & {
	type: FilterElements.CHECKBOX;
	label?: string;
	checked: boolean;
};

export type FilterDataTextInputElement = FilterDataElement & {
	type: FilterElements.TEXTINPUT;
	value: string;
	searchPlaceholder?: string;
};

export type FilterDataArray = Array<FilterDataSelectElement | FilterDataCheckBoxElement | FilterDataTextInputElement>;

const kategoriFilterElement = (filter: Filter, setFilter: (newFilter: Filter) => void): FilterDataSelectElement => {
	const { kategorierOptions } = useProfile();

	const kategoriEmptyMsg: SelectItem = {
		value: 'kategoriEmptyMsg',
		label: 'Du har ingen kategorier',
		subLabel: 'Du kan opprette en ny kategori under anleggsdetaljer',
	};

	return {
		type: FilterElements.SELECT,
		title: 'Kategori',
		options: kategorierOptions,
		selected: filter.kategorier,
		emptyMsg: kategoriEmptyMsg,
		dropdownAlign: DropdownAlign.LEFT,
		useSearch: true,
		isMultiselect: true,
		onChange: (kategorier: string[]) => {
			setFilter({ ...filter, kategorier });
		},
	};
};

const fakturamerkeFilterElement = (filter: Filter, setFilter: (newFilter: Filter) => void): FilterDataSelectElement => {
	const { fakturamerker } = useAnlegg({ ...filter });

	return (
		fakturamerker?.length && {
			type: FilterElements.SELECT,
			title: 'Fakturamerke',
			options: fakturamerker,
			selected: filter.fakturamerker,
			useSearch: true,
			searchPlaceholder: 'Søk i fakturamerker...',
			isMultiselect: true,
			onChange: (fakturamerker: string[]) => {
				setFilter({ ...filter, fakturamerker });
			},
		}
	);
};

const naeringskodeFilterElement = (filter: Filter, setFilter: (newFilter: Filter) => void): FilterDataSelectElement => {
	const { naeringskoder } = useAnlegg({ ...filter });

	return (
		naeringskoder?.length && {
			type: FilterElements.SELECT,
			title: 'Næringskode',
			options: naeringskoder,
			selected: filter?.naeringskoder,
			useSearch: true,
			searchPlaceholder: 'Søk i næringskoder...',
			isMultiselect: true,
			hasFixedWidth: true,
			onChange: (naeringskoder: string[]) => {
				setFilter({ ...filter, naeringskoder });
			},
		}
	);
};

export function getAnleggFilterData(filter: Filter, setFilter: (newFilter: Filter) => void): FilterDataArray {
	const { oppHoerteAnleggCount } = useAnlegg({ ...filter });
	const sorter: SelectItem[] = [
		{
			value: 'tittel',
			label: 'Navn',
		},
		{
			value: 'maalerpunktId',
			label: 'Målepunkt-ID',
		},
		{
			value: 'maalernummer',
			label: 'Målernummer',
		},
		{
			value: 'aarsforbruk',
			label: 'Estimert årsforbruk',
		},
	];

	return [
		kategoriFilterElement(filter, setFilter),
		fakturamerkeFilterElement(filter, setFilter),
		naeringskodeFilterElement(filter, setFilter),
		oppHoerteAnleggCount && {
			type: FilterElements.CHECKBOX,
			title: 'Opphørte anlegg',
			label: 'Vis opphørte',
			checked: filter?.visOpphoert,
			filterDrawerStyle: { order: -1 },
			onChange: (visOpphoert: boolean) => {
				setFilter({ ...filter, visOpphoert });
			},
		},
		{
			type: FilterElements.SELECT,
			title: 'Sorter',
			options: sorter,
			selected: filter?.sorter || 'maalerpunktId',
			isSortSelect: true,
			isBlank: true,
			onChange: (sorter: string) => {
				setFilter({ ...filter, sorter });
			},
		},
		{
			type: FilterElements.TEXTINPUT,
			title: 'anlegg',
			value: filter.search,
			filterBarStyle: { marginLeft: 'auto', alignSelf: 'flex-end' },
			filterDrawerStyle: { order: -2 },
			onChange: (search: string) => {
				setFilter({ ...filter, search });
			},
		},
	];
}

export const FakturaFilterOptions = [
	{
		value: FakturaFilter.all,
		label: 'Alle',
	},
	{
		value: FakturaFilter.unpaid,
		label: 'Ubetalt',
	},
	{
		value: FakturaFilter.overdue,
		label: 'Forfalt',
	},
];

export function getFakturaFilterData(filter: Filter, setFilter: (newFilter: Filter) => void): FilterDataArray {
	return [
		kategoriFilterElement(filter, setFilter),
		fakturamerkeFilterElement(filter, setFilter),
		naeringskodeFilterElement(filter, setFilter),
		{
			type: FilterElements.SELECT,
			title: 'Fakturastatus',
			options: FakturaFilterOptions,
			selected: filter?.fakturaFilter || 'all',
			useFilterDrawerTabSelect: true,
			isBlank: true,
			filterDrawerStyle: { order: -1 },
			onChange: (fakturaFilter: string) => {
				setFilter({ ...filter, fakturaFilter });
			},
		},
	];
}

export function getKostnadFilterData(filter: Filter, setFilter: (newFilter: Filter) => void): FilterDataArray {
	return [kategoriFilterElement(filter, setFilter), fakturamerkeFilterElement(filter, setFilter), naeringskodeFilterElement(filter, setFilter)];
}

export function getForbrukFilterData(filter: Filter, setFilter: (newFilter: Filter) => void): FilterDataArray {
	return [kategoriFilterElement(filter, setFilter), fakturamerkeFilterElement(filter, setFilter), naeringskodeFilterElement(filter, setFilter)];
}

export function getSpotprisFilterData(filter: Filter, setFilter: (newFilter: Filter) => void): FilterDataSelectElement {
	const { energisoner } = useAnlegg();
	const { t } = useTranslation('forbruk');

	return {
		type: FilterElements.SELECT,
		title: t('Spotpris.energisone.' + filter?.energisone),
		options: energisoner,
		selected: filter?.energisone,
		onChange: (energisone: Energisone) => {
			setFilter({ ...filter, energisone: energisone });
		},
	};
}
