export const env = {
	REACT_APP_KEYCLOAK_AUTH_SERVER_URL: window?.env?.["REACT_APP_KEYCLOAK_AUTH_SERVER_URL"] || process.env?.["REACT_APP_KEYCLOAK_AUTH_SERVER_URL"] || "",
	REACT_APP_KEYCLOAK_AUTH_RESOURCE: window?.env?.["REACT_APP_KEYCLOAK_AUTH_RESOURCE"] || process.env?.["REACT_APP_KEYCLOAK_AUTH_RESOURCE"] || "",
	REACT_APP_KEYCLOAK_REALM: window?.env?.["REACT_APP_KEYCLOAK_REALM"] || process.env?.["REACT_APP_KEYCLOAK_REALM"] || "",
	REACT_APP_BP_BACKEND: window?.env?.["REACT_APP_BP_BACKEND"] || process.env?.["REACT_APP_BP_BACKEND"] || "",
	REACT_APP_FJUTT_ENABLED: window?.env?.["REACT_APP_FJUTT_ENABLED"] || process.env?.["REACT_APP_FJUTT_ENABLED"] || "true",
	REACT_APP_FORBRUKSVARSEL_ENABLED: window?.env?.["REACT_APP_FORBRUKSVARSEL_ENABLED"] || process.env?.["REACT_APP_FORBRUKSVARSEL_ENABLED"] || "true",
	REACT_APP_SEGMENT_WRITE_KEY: window?.env?.["REACT_APP_SEGMENT_WRITE_KEY"] || process.env?.["REACT_APP_SEGMENT_WRITE_KEY"] || "",
	REACT_APP_PERSONVERN_URL: window?.env?.["REACT_APP_PERSONVERN_URL"] || process.env?.["REACT_APP_PERSONVERN_URL"] || "https://nte.no/privat/personvernerklaering",
	REACT_APP_AVTALEVILKAAR: window?.env?.["REACT_APP_AVTALEVILKAAR"] || process.env?.["REACT_APP_AVTALEVILKAAR"] || "https://nte.no/bedrift/kundeservice/avtaler-og-vilkar-bedrift",
	REACT_APP_KLIMAREGNSKAP_URL: window?.env?.["REACT_APP_KLIMAREGNSKAP_URL"] || process.env?.["REACT_APP_KLIMAREGNSKAP_URL"] || "https://nte.avinet.no/organizations/",
	REACT_APP_FJUTT_FORCE_METER: window?.env?.["REACT_APP_FJUTT_FORCE_METER"] || process.env?.["REACT_APP_FJUTT_FORCE_METER"] || "",
};
