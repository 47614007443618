// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OpprettBediftFraKundereferanseMutationVariables = Types.Exact<{
  kunderef: Types.KundereferanseInput;
}>;


export type OpprettBediftFraKundereferanseMutation = { opprettBediftFraKundereferanse?: { id?: string, orgnummer?: string } };


export const OpprettBediftFraKundereferanseDocument = gql`
    mutation opprettBediftFraKundereferanse($kunderef: KundereferanseInput!) {
  opprettBediftFraKundereferanse(kunderef: $kunderef) {
    id
    orgnummer
  }
}
    `;
export type OpprettBediftFraKundereferanseMutationFn = Apollo.MutationFunction<OpprettBediftFraKundereferanseMutation, OpprettBediftFraKundereferanseMutationVariables>;

/**
 * __useOpprettBediftFraKundereferanseMutation__
 *
 * To run a mutation, you first call `useOpprettBediftFraKundereferanseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpprettBediftFraKundereferanseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [opprettBediftFraKundereferanseMutation, { data, loading, error }] = useOpprettBediftFraKundereferanseMutation({
 *   variables: {
 *      kunderef: // value for 'kunderef'
 *   },
 * });
 */
export function useOpprettBediftFraKundereferanseMutation(baseOptions?: Apollo.MutationHookOptions<OpprettBediftFraKundereferanseMutation, OpprettBediftFraKundereferanseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OpprettBediftFraKundereferanseMutation, OpprettBediftFraKundereferanseMutationVariables>(OpprettBediftFraKundereferanseDocument, options);
      }
export type OpprettBediftFraKundereferanseMutationHookResult = ReturnType<typeof useOpprettBediftFraKundereferanseMutation>;
export type OpprettBediftFraKundereferanseMutationResult = Apollo.MutationResult<OpprettBediftFraKundereferanseMutation>;
export type OpprettBediftFraKundereferanseMutationOptions = Apollo.BaseMutationOptions<OpprettBediftFraKundereferanseMutation, OpprettBediftFraKundereferanseMutationVariables>;