import React from 'react';

export default function MobilGrafikk(props: any) {
	return (
		<svg width="169" height="53" viewBox="0 0 169 53" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M63 52.95C63 52.95 76.5485 35.8857 95.0648 35.0506C113.581 34.2154 119.753 43.9966 127.288 43.9966C134.822 43.9966 139.331 40.4603 147.701 40.4603C156.071 40.4603 166.27 46.7052 168.972 52.95H63Z"
				fill="white"
			/>
			<path
				d="M0 10.6906C0 10.6906 8.06135 0.518257 19.0959 0.0292003C30.1304 -0.459856 33.8035 5.35616 38.2971 5.35616C42.7907 5.35616 45.4703 3.24945 50.4532 3.24945C55.436 3.24945 61.5178 6.92866 63.1286 10.6906H0Z"
				fill="white"
			/>
		</svg>
	);
}
