import { Anleggsstatus } from 'generated/graphql-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { device } from 'web/GlobalStyling';

export enum BadgeColors {
	BLUE = 'BLUE',
	GREEN = 'GREEN',
	YELLOW = 'YELLOW',
	RED = 'RED',
}

function getBadgeColor(status: Anleggsstatus): BadgeColors {
	switch (status) {
		case Anleggsstatus.AKTIV:
			return BadgeColors.BLUE;
		case Anleggsstatus.PENDING:
			return BadgeColors.YELLOW;
		case Anleggsstatus.OPPHOERT:
			return BadgeColors.YELLOW;
		case Anleggsstatus.UNDER_OPPHOER:
			return BadgeColors.YELLOW;
		case Anleggsstatus.UNDER_OVERTAKELSE:
			return BadgeColors.YELLOW;
		default:
			return BadgeColors.YELLOW;
	}
}

export function getBadgeString(status: Anleggsstatus): String {
	switch (status) {
		case Anleggsstatus.AKTIV:
			return 'Aktiv';
		case Anleggsstatus.PENDING:
			return 'Avventer';
		case Anleggsstatus.OPPHOERT:
			return 'Opphørt';
		case Anleggsstatus.UNDER_OPPHOER:
			return 'Til opphør';
		case Anleggsstatus.UNDER_OVERTAKELSE:
			return 'Til overtakelse';
		default:
			return 'Ukjent';
	}
}

interface BadgeProps {
	color: BadgeColors;
}

const Badge = styled.span<BadgeProps>`
	border-radius: var(--border-radius-smallest);
	border: 1px solid transparent;
	font-weight: 500;
	font-size: 0.75rem;
	line-height: 1.3;
	padding: 2px 10px;
	${props =>
		props.color === BadgeColors.BLUE &&
		css`
			border-color: var(--nte-blaa);
			color: var(--nte-blaa-uu);
			background: var(--nte-blaa-10);
		`}
	${props =>
		props.color === BadgeColors.GREEN &&
		css`
			border-color: var(--nte-groenn);
			color: var(--varsel-groenn);
			background: var(--varsel-lys-groenn);
		`}
	${props =>
		props.color === BadgeColors.YELLOW &&
		css`
			border-color: var(--varsel-gul);
			color: var(--varsel-gul);
			background: var(--varsel-lys-gul);
		`}
	${props =>
		props.color === BadgeColors.RED &&
		css`
			border-color: var(--nte-roed);
			color: var(--varsel-roed);
			background: var(--nte-korall);
		`}
	@media ${device.mobile} {
		position: absolute;
		top: 0;
		left: 0;
		max-width: calc(100% + 24px);
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

export default Badge;

interface AnleggBadgeProps {
	status: Anleggsstatus;
}
export function AnleggBadge({ status }: AnleggBadgeProps) {
	if (!status) {
		return <></>;
	}
	return <Badge color={getBadgeColor(status)}>{getBadgeString(status)}</Badge>;
}
