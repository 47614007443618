import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { CheckboxDiv } from 'web/components/atoms/Checkbox';
import { SelectItem } from 'web/lib/filter.utils';

interface WrapperProps {
	isNarrowDropdown?: boolean;
}
const Wrapper = styled.div<WrapperProps>`
	${props =>
		(props.isNarrowDropdown &&
			css`
				width: 10rem;
			`) ||
		css`
			width: max-content;
			max-width: 100%;
		`}
`;

const SearchWrapper = styled.div`
	position: relative;
`;
export const StyledSearchInput = styled.input`
	width: calc(100% - 32px);
	height: 2.8rem;
	padding: 0.5rem;
	padding-left: 1rem;
	border: var(--nte-border);
	border-radius: 34px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 0.875rem;
	line-height: 1.5rem;
	color: var(--nte-graa-mork);
	margin: 1rem 1rem 0.5rem;
`;
const ClearSearchButton = styled.button`
	position: absolute;
	right: 16px;
	top: 16px;
	width: 45px;
	height: 45px;
	background: none;
	border: none;
	line-height: 45px;
	text-align: center;
`;

interface SelectElementWrapperProps {
	selected?: boolean;
}
const SelectElementWrapper = styled.label<SelectElementWrapperProps>`
	width: 100%;
	max-width: 18rem;
	padding: 14px;
	display: flex;
	cursor: pointer;
	${props =>
		props.selected &&
		css`
			font-weight: 800;
		`}
`;

const SelectElementLabel = styled.span`
	font-size: 1rem;
	line-height: 1.2;
`;

const SelectElementSubLabel = styled.span`
	font-size: 0.75rem;
	line-height: 1.2;
`;
interface SelectElementProps {
	item: SelectItem;
	selected?: boolean;
	checkbox?: boolean;
	onClick?: (item: SelectItem) => void;
	style?: React.CSSProperties;
}

function SelectElement(props: SelectElementProps) {
	return (
		<SelectElementWrapper selected={props.selected} onClick={() => props.onClick?.(props.item)} style={props.style}>
			{props.checkbox && <CheckboxDiv>{props.selected && <i className="fa fa-check" />}</CheckboxDiv>}
			<div>
				<SelectElementLabel>{props.item.label}</SelectElementLabel>
				{props.item.subLabel && (
					<>
						<br />
						<SelectElementSubLabel>{props.item.subLabel}</SelectElementSubLabel>
					</>
				)}
			</div>
		</SelectElementWrapper>
	);
}

interface FilterBarDropdownSelectProps {
	options: SelectItem[];
	emptyMsg?: SelectItem;
	selected: string | string[];
	useSearch?: boolean;
	searchPlaceholder?: string;
	isMultiselect?: boolean;
	isDeselectable?: boolean;
	isNarrowDropdown?: boolean;
	style?: React.CSSProperties;
	onChange: (selected: string | string[]) => void;
}

export default function FilterBarDropdownSelect(props: FilterBarDropdownSelectProps) {
	const [search, setSearch] = useState('');

	let isSelected = (item: SelectItem) => {
		return item.value === props.selected;
	};

	if (props.isMultiselect) {
		isSelected = (item: SelectItem) => {
			if (!props.selected || props.selected?.length === 0) return;

			return props.selected.indexOf(item.value) > -1;
		};
	}

	return (
		<Wrapper style={props.style} isNarrowDropdown={props.isNarrowDropdown}>
			{props.useSearch && (
				<SearchWrapper>
					<StyledSearchInput placeholder={props.searchPlaceholder || 'Søk...'} onChange={e => setSearch(e.target.value)} value={search} />
					{(search?.length && (
						<ClearSearchButton
							type="button"
							onClick={() => {
								setSearch('');
							}}>
							<i className="fa fa-times" />
						</ClearSearchButton>
					)) || (
						<ClearSearchButton
							type="button"
							onClick={() => {
								setSearch('');
							}}>
							<i className="fa fa-search" style={{ color: 'var(--nte-blaa)' }} />
						</ClearSearchButton>
					)}
				</SearchWrapper>
			)}
			{(!props.options?.length && props.emptyMsg && <SelectElement key={props.emptyMsg.value} item={props.emptyMsg} style={{ cursor: 'default' }} />) ||
				props.options
					?.filter(item => {
						if (!search) return true;

						const _searchIndex = `${item.value?.toLowerCase()} ${item.label?.toLowerCase()} ${item.subLabel?.toLowerCase()}`;

						return _searchIndex.indexOf(search.toLowerCase()) > -1;
					})
					?.map(item => (
						<SelectElement
							key={item.value}
							selected={isSelected(item)}
							item={item}
							checkbox={props.isMultiselect}
							onClick={() => {
								if (!props.onChange) return;
								if (!props.isMultiselect) {
									if (props.selected === item.value && props.isDeselectable) return props.onChange('');

									return props.onChange(item.value);
								}

								let selectedIndex = props.selected?.indexOf(item.value) ?? -1;

								if (selectedIndex > -1) {
									let selected = [...props.selected];

									selected.splice(selectedIndex, 1);

									props.onChange([...selected]);

									return;
								}
								props.onChange([...(props.selected || []), item.value]);
							}}
						/>
					))}
		</Wrapper>
	);
}
