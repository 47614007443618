import { ApolloError } from '@apollo/client';
import { format } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import FlexPageWrapper from 'web/components/atoms/FlexPageWrapper';
import PageTitle, { PageTitleButton } from 'web/components/atoms/PageTitle';
import AdminSearchBar from 'web/components/molecules/AdminSearchBar';
import DataError from 'web/components/molecules/form/DataError';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import ConfirmModalButton from 'web/components/organisms/ConfirmModalButton';

const DeleteButton = styled(ConfirmModalButton)`
	background: none;
	border: none;
	text-align: center;
	color: var(--nte-blaa);
	i {
		font-size: 1rem;
	}
`;

function filtrerOgSorter(meldinger: MeldingElement[], filter: string): MeldingElement[] {
	const compareFn = (a: MeldingElement, b: MeldingElement) => new Date(b.created).getTime() - new Date(a.created).getTime();

	if (!filter) {
		return [...(meldinger || [])].sort(compareFn);
	}

	const searchArray = filter.toLowerCase().split(' ');
	return [...(meldinger || [])]
		.filter(d => {
			const companyValues = d.companies.map(e => e.kundeNavn.toLocaleLowerCase().split(' ').concat([e.orgNr]));
			const meldingValues = [d.title.toLocaleLowerCase(), d.customTitle.toLocaleLowerCase(), d.description];
			const values = companyValues.reduce((a, b) => a.concat(b), meldingValues);

			let filterMatched = false;

			for (let i = 0; i < values?.length; i++) {
				const value = values[i];
				for (let j = 0; j < searchArray?.length; j++) {
					const searchWord = searchArray[j];
					if (value.includes(searchWord)) {
						filterMatched = true;
					}
				}
			}
			return filterMatched;
		})
		.sort(compareFn);
}

function generateSendtTilString(bedrifter: Bedrift[]): string {
	if (bedrifter?.length === 1 && bedrifter[0].kundeNavn?.length > 25) {
		return bedrifter[0].kundeNavn.slice(0, 25) + '...';
	}

	let bedrifterString = '';
	for (let i = 0; i < bedrifter?.length; i++) {
		const bedriftNavn = bedrifter[i].kundeNavn || '';
		if (bedrifterString?.length + bedriftNavn?.length <= 25) {
			bedrifterString += (i != 0 ? ', ' : '') + bedriftNavn;
		} else {
			bedrifterString += ' og ' + (bedrifter?.length - i) + ' til';
			return bedrifterString;
		}
	}
	return bedrifterString;
}
export interface Bedrift {
	kundeNavn: string;
	orgNr: string;
}
export interface MeldingElement {
	id: string;
	customTitle: string;
	contentType?: string;
	description?: string;
	title: string;
	url?: string;
	created: string;
	companies: Bedrift[];
}

interface MeldingerTemplateProps {
	meldinger: MeldingElement[];
	slettMelding: (meldingId: string) => void;
	loading: boolean;
	error: ApolloError;
}
export function MeldingerTemplate({ meldinger = [], loading, error, slettMelding }: MeldingerTemplateProps) {
	const { t } = useTranslation('admin');
	const [search, setSearch] = useState('');
	const updateSearch = debounce(setSearch, 300);
	const history = useHistory();

	const filteredMeldinger = filtrerOgSorter(meldinger, search);
	return (
		<FlexPageWrapper>
			<PageTitle>
				Meldinger
				<PageTitleButton
					type="button"
					onClick={() => {
						history.push(window.location.pathname + 'ny/');
					}}>
					<i className="fa fa-plus" />
					Send ny melding
				</PageTitleButton>
			</PageTitle>

			<AdminSearchBar onSearchChange={updateSearch} label="Søk på meldinger eller mottakere" style={{ width: '100%' }} />

			<LoadingOverlay loading={loading} text="Laster inn meldinger" />
			<DataError error={error} />

			<table className="stripe">
				<thead>
					<tr>
						<td>{t('Meldinger.navn')}</td>
						<td>{t('Meldinger.element.tid')}</td>
						<td>{t('Meldinger.element.sendtTil')}</td>
						<td></td>
					</tr>
				</thead>
				<tbody>
					{(!filteredMeldinger || filteredMeldinger?.length === 0) && (
						<tr>
							<td className="center" colSpan={4}>
								Fant ingen meldinger
							</td>
						</tr>
					)}
					{filteredMeldinger.map(m => (
						<tr key={m.id}>
							<td>
								<Link to={`/admin/meldinger/${m.id}/`}>{m.customTitle}</Link>
							</td>

							<td>{format(new Date(m.created), 'dd.MM.yyyy', { locale: nbLocale })}</td>

							<td>{generateSendtTilString(m.companies)}</td>

							<td className="right">
								<DeleteButton
									title="Sikker på at du vil slette denne meldingen?"
									message="Når du sletter meldingen fjernes den fra innboksen til alle mottakere. Dette kan virke forvirrende for mottakere som allerede har lest meldingen. Handlingen kan ikke angres."
									confirmIcon="far fa-trash"
									confirm="Slett meldingen"
									decline="Avbryt"
									type="button"
									onClick={async () => {
										try {
											await slettMelding(m.id);

											toast('Melding slettet!', {
												position: toast.POSITION.BOTTOM_CENTER,
											});
										} catch (error) {
											console.error(error);
										}
									}}>
									<i className="fas fa-trash" />
								</DeleteButton>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</FlexPageWrapper>
	);
}
