import React from 'react';
import styled, { css } from 'styled-components';
import Button from 'web/components/atoms/Button';
import FilterDrawerCheckBox from 'web/components/molecules/filter/FilterDrawerCheckBox';
import FilterDrawerSelect from 'web/components/molecules/filter/FilterDrawerSelect';
import FilterDrawerTextInput from 'web/components/molecules/filter/FilterDrawerTextInput';
import { FilterDataArray, FilterElements } from 'web/lib/filter.utils';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	& .filter-element {
		background: var(--graa-bg-lys);
	}
`;

interface ButtonWrapperProps {
	isBottom?: boolean;
}
const ButtonWrapper = styled.div<ButtonWrapperProps>`
	padding: 1rem;
	position: sticky;
	bottom: 0;

	background-color: var(--graa-bg-lys);
	box-shadow: 0px -2px 15px -4px rgba(0, 0, 0, 0.5);
	transition: box-shadow 300ms cubic-bezier(0.36, 0.69, 0.25, 0.68);
	${props =>
		props.isBottom &&
		css`
			box-shadow: 0px 0px 0px -4px;
		`}
`;

const StyledButton = styled(Button)`
	width: 100%;
`;

const FilterDrawerElementsMap = {
	[FilterElements.SELECT]: FilterDrawerSelect,
	[FilterElements.CHECKBOX]: FilterDrawerCheckBox,
	[FilterElements.TEXTINPUT]: FilterDrawerTextInput,
	default: () => <span>Ukjent</span>,
} as any;

export interface FilterDrawerContentProps {
	drawerContentRef?: React.MutableRefObject<any>;
	filterData?: FilterDataArray;
	closeButtonText?: string;
	onClose: () => void;
	isBottom?: boolean;
}

export default function FilterDrawerContent(props?: FilterDrawerContentProps) {
	return (
		<Wrapper ref={props.drawerContentRef}>
			{props.filterData
				?.filter(d => !!d)
				.map((data, i) => {
					const Element = FilterDrawerElementsMap[data.type] || FilterDrawerElementsMap.default;
					return <Element key={i} {...data} />;
				})}

			<ButtonWrapper isBottom={props.isBottom}>
				<StyledButton onClick={props.onClose}>{props.closeButtonText || 'Se'}</StyledButton>
			</ButtonWrapper>
		</Wrapper>
	);
}
