// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllCompanyDocumentsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
}>;


export type GetAllCompanyDocumentsQuery = { getAllCompanyDocuments?: { unread: number, documents?: Array<{ id?: string, title?: string, customTitle?: string, description?: string, url?: string, contentType?: string, created?: string, read: boolean }> } };


export const GetAllCompanyDocumentsDocument = gql`
    query getAllCompanyDocuments($companyId: ID!) {
  getAllCompanyDocuments(companyId: $companyId) {
    unread
    documents {
      id
      title
      customTitle
      description
      url
      contentType
      created
      read
    }
  }
}
    `;

/**
 * __useGetAllCompanyDocumentsQuery__
 *
 * To run a query within a React component, call `useGetAllCompanyDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompanyDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompanyDocumentsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetAllCompanyDocumentsQuery(baseOptions: Apollo.QueryHookOptions<GetAllCompanyDocumentsQuery, GetAllCompanyDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCompanyDocumentsQuery, GetAllCompanyDocumentsQueryVariables>(GetAllCompanyDocumentsDocument, options);
      }
export function useGetAllCompanyDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCompanyDocumentsQuery, GetAllCompanyDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCompanyDocumentsQuery, GetAllCompanyDocumentsQueryVariables>(GetAllCompanyDocumentsDocument, options);
        }
export type GetAllCompanyDocumentsQueryHookResult = ReturnType<typeof useGetAllCompanyDocumentsQuery>;
export type GetAllCompanyDocumentsLazyQueryHookResult = ReturnType<typeof useGetAllCompanyDocumentsLazyQuery>;
export type GetAllCompanyDocumentsQueryResult = Apollo.QueryResult<GetAllCompanyDocumentsQuery, GetAllCompanyDocumentsQueryVariables>;