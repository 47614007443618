import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PageTitle from 'web/components/atoms/PageTitle';
import Paragraph from 'web/components/atoms/Paragraph';
import Push from 'web/components/atoms/Push';
import useAuthHelpers from 'web/hooks/useAuthHelpers';

export const LoginPageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 2rem;
`;

export const IFrame = styled.iframe`
	box-sizing: border-box;
	margin: 2px auto 32px;
	max-width: 100%;
	width: 600px;
	min-height: 330px;
	border: none;
	border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
	padding: 2px;
`;

export default function LoginPage() {
	const { t } = useTranslation('generic');
	const { logInFramePath } = useAuthHelpers();

	const auth = useAuth();

	return (
		<LoginPageWrapper>
			<div>
				<PageTitle isCenter>{t('Login.title')}</PageTitle>
				<Push />
				<Paragraph>{t('Login.loginTip')}</Paragraph>
			</div>

			{!auth.isAuthenticated && <IFrame frameBorder="none" src={logInFramePath} />}

			<div>
				<Paragraph key="be-om-tilgang">
					Om du ikke har registrert bedriften din i Bedriftsportalen, kan du{' '}
					<strong>
						<Link to="/be-om-tilgang">be om tilgang her</Link>
					</strong>
					.
				</Paragraph>

				<Paragraph key="bruk">
					<a target="_blank" rel="noopener" href="https://nte.no/blogg/innlegg/slik-bruker-du-bedriftsportalen">
						Hva er Bedriftsportalen?
					</a>
				</Paragraph>
			</div>
		</LoginPageWrapper>
	);
}
