import { Modul } from 'generated/graphql-types';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import DataError from 'web/components/molecules/form/DataError';
import Modal from 'web/components/molecules/Modal';
import ModulSelect from 'web/components/organisms/ModulSelect';
import { useLeggTilModulMutation } from 'web/pages/AdminPage.graphql-gen';

interface NyModulModalProps {
	moduler: Modul[];
	orgId: string;
	onClose: () => void;
	callback?: () => Promise<any>;
}
export function NyModulModal({ moduler, orgId, onClose, callback }: NyModulModalProps) {
	const [customAddModuleError, setCustomAddModuleError] = useState(null);
	const [leggTilModul] = useLeggTilModulMutation();
	return (
		<Modal
			title="Legg til moduler"
			onClose={() => {
				onClose();
				setCustomAddModuleError(null);
			}}>
			<DataError error={customAddModuleError} />
			<ModulSelect
				notIn={moduler.map(modul => modul.navn) || []}
				onSelect={(moduler: any) => {
					setCustomAddModuleError(null);
					Promise.all(
						moduler?.map((navn: string) => {
							return leggTilModul({
								variables: {
									orgid: orgId,
									modul: navn,
								},
							}).catch(error => ({ error: error, isError: true }));
						})
					).then(async data => {
						let isError = false;
						let lastError = null;

						data?.forEach(item => {
							if (item.isError) {
								isError = true;
								lastError = item.error;
							}
						});

						if (!isError) {
							setCustomAddModuleError(null);
							return await callback?.().then(() => {
								onClose();
								toast('Modul er lagt til', {
									position: toast.POSITION.BOTTOM_CENTER,
								});
							});
						}

						setCustomAddModuleError(lastError);
					});
				}}
			/>
		</Modal>
	);
}
