import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { persistCache } from 'apollo3-cache-persist';
import { User } from 'oidc-client-ts';
import { env } from 'web/env';
import { logError } from 'web/hooks/useErrorLogging';
import { TraceContext, generateTraceContext } from 'web/lib/RandomIdGenerator';

const cache = new InMemoryCache({
	typePolicies: {
		Faktura: {
			keyFields: ['guid'],
		},
		Anlegg: {
			keyFields: ['maalerpunktId'],
		},
	},
});
export const persistCacheHook = persistCache({ cache, storage: window.localStorage });

const retryLink = new RetryLink({
	delay: {
		initial: 300,
		max: Infinity,
		jitter: true,
	},
	attempts: {
		max: 5,
		retryIf: (error, _operation) => {
			return !!error;
		},
	},
});

const onErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
	if (graphQLErrors) {
		const context = operation.getContext();
		const tracing = context.traceContext as TraceContext;
		const s = JSON.stringify(graphQLErrors);
		logError(`${tracing.traceId} GraphQL error in apolloClient.tsx: ${s}`);
		for (let err of graphQLErrors) {
			switch (err.extensions.code) {
				case 'unauthorized':
					window.postMessage('udateTokenSilently', window.location.origin);
			}
		}
	}

	if (networkError) {
		if (networkError.toString().toLowerCase().indexOf('json') > -1 && networkError.toString().toLowerCase().indexOf('unexpected') > -1) {
			// i.e. not authenticated
			window.postMessage('udateTokenSilently', window.location.origin);
		}
		console.error(`[Network error]: ${networkError}`);
	}
});

async function getUser() {
	const oidcStorage = await localStorage.getItem(
		`oidc.user:${env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL}/realms/${env.REACT_APP_KEYCLOAK_REALM}:${env.REACT_APP_KEYCLOAK_AUTH_RESOURCE}`
	);

	if (!oidcStorage) {
		return null;
	}

	return User.fromStorageString(oidcStorage);
}

const loggedOutRequestNames = ['hentInvitasjonsstatus', 'avslaaInvitasjon', 'logFrontend']; // Requests that can be called while logged out

const traceLink = setContext(async (request, prevContext) => {
	const { headers } = prevContext;
	const traceContext = generateTraceContext();
	return {
		...prevContext,
		traceContext,
		headers: {
			...headers,
			traceparent: traceContext.toHeader(),
		},
	};
});

const asyncAuthLink = setContext(async (request, { headers }) => {
	if (loggedOutRequestNames.indexOf(request.operationName) > -1) {
		return {
			headers: {
				...headers,
				authorization: '',
			},
		};
	}
	const user = await getUser();
	const token = user?.access_token;
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	};
});

const apolloClient = new ApolloClient({
	link: ApolloLink.from([
		traceLink,
		retryLink,
		onErrorLink,
		asyncAuthLink,
		new HttpLink({
			uri: env.REACT_APP_BP_BACKEND + '/bp-api/graphql/',
		}),
	]),
	cache,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
		},
	},
});

export default apolloClient;
