// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentBedriftForMeldInnAnleggQueryVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
}>;


export type HentBedriftForMeldInnAnleggQuery = { bedrift?: { id?: string, orgnummer?: string, navn?: string, godkjenteTariffer?: Array<string>, kontaktinformasjon?: { kontaktperson?: string, telefonnummer?: string, epost?: string, adresse?: string, postnummer?: string, poststed?: string } } };

export type MeldInnAnleggBatchMutationVariables = Types.Exact<{
  innmeldingAnlegg: Types.BatchInnmeldingAnleggInput;
  orgid: Types.Scalars['ID'];
}>;


export type MeldInnAnleggBatchMutation = { batchMeldInnAnlegg?: { innmeldingStatuser?: Array<{ error?: string, ordreId?: string, fakturamerke?: string, maalepunkter?: Array<string>, status?: string }> } };


export const HentBedriftForMeldInnAnleggDocument = gql`
    query hentBedriftForMeldInnAnlegg($orgid: ID!) {
  bedrift: hentBedrift(orgid: $orgid) {
    id
    orgnummer
    navn
    kontaktinformasjon {
      kontaktperson
      telefonnummer
      epost
      adresse
      postnummer
      poststed
    }
    godkjenteTariffer
  }
}
    `;

/**
 * __useHentBedriftForMeldInnAnleggQuery__
 *
 * To run a query within a React component, call `useHentBedriftForMeldInnAnleggQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentBedriftForMeldInnAnleggQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentBedriftForMeldInnAnleggQuery({
 *   variables: {
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useHentBedriftForMeldInnAnleggQuery(baseOptions: Apollo.QueryHookOptions<HentBedriftForMeldInnAnleggQuery, HentBedriftForMeldInnAnleggQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentBedriftForMeldInnAnleggQuery, HentBedriftForMeldInnAnleggQueryVariables>(HentBedriftForMeldInnAnleggDocument, options);
      }
export function useHentBedriftForMeldInnAnleggLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentBedriftForMeldInnAnleggQuery, HentBedriftForMeldInnAnleggQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentBedriftForMeldInnAnleggQuery, HentBedriftForMeldInnAnleggQueryVariables>(HentBedriftForMeldInnAnleggDocument, options);
        }
export type HentBedriftForMeldInnAnleggQueryHookResult = ReturnType<typeof useHentBedriftForMeldInnAnleggQuery>;
export type HentBedriftForMeldInnAnleggLazyQueryHookResult = ReturnType<typeof useHentBedriftForMeldInnAnleggLazyQuery>;
export type HentBedriftForMeldInnAnleggQueryResult = Apollo.QueryResult<HentBedriftForMeldInnAnleggQuery, HentBedriftForMeldInnAnleggQueryVariables>;
export const MeldInnAnleggBatchDocument = gql`
    mutation meldInnAnleggBatch($innmeldingAnlegg: BatchInnmeldingAnleggInput!, $orgid: ID!) {
  batchMeldInnAnlegg(innmeldingAnlegg: $innmeldingAnlegg, orgid: $orgid) {
    innmeldingStatuser {
      error
      ordreId
      fakturamerke
      maalepunkter
      status
    }
  }
}
    `;
export type MeldInnAnleggBatchMutationFn = Apollo.MutationFunction<MeldInnAnleggBatchMutation, MeldInnAnleggBatchMutationVariables>;

/**
 * __useMeldInnAnleggBatchMutation__
 *
 * To run a mutation, you first call `useMeldInnAnleggBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMeldInnAnleggBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [meldInnAnleggBatchMutation, { data, loading, error }] = useMeldInnAnleggBatchMutation({
 *   variables: {
 *      innmeldingAnlegg: // value for 'innmeldingAnlegg'
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useMeldInnAnleggBatchMutation(baseOptions?: Apollo.MutationHookOptions<MeldInnAnleggBatchMutation, MeldInnAnleggBatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MeldInnAnleggBatchMutation, MeldInnAnleggBatchMutationVariables>(MeldInnAnleggBatchDocument, options);
      }
export type MeldInnAnleggBatchMutationHookResult = ReturnType<typeof useMeldInnAnleggBatchMutation>;
export type MeldInnAnleggBatchMutationResult = Apollo.MutationResult<MeldInnAnleggBatchMutation>;
export type MeldInnAnleggBatchMutationOptions = Apollo.BaseMutationOptions<MeldInnAnleggBatchMutation, MeldInnAnleggBatchMutationVariables>;