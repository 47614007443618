import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Anlegg } from 'web/models/Anlegg';

interface Linkable<T> {
	item: T;
	to: string;
}

interface Clickable<T> {
	item: T;
	onClick: (item: T) => void;
}

const Wrapper = styled.div``;
const Title = styled.div`
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.2;
	color: var(--nte-sort);
	margin-bottom: 1rem;
`;

const StyledLink = styled(Link)`
	font-weight: bold;
	font-size: 0.875rem;
	line-height: 3;
	color: var(--nte-blaa-uu);
	background: var(--nte-blaa-10);
	padding: 0 12px;
	display: inline-block;
	margin-right: 0.5rem;
`;

interface SistBesokteProps {
	sistBesokte: Linkable<Anlegg>[];
}

export default function SistBesokte(props: SistBesokteProps) {
	return (
		<Wrapper>
			<Title>Sist besøkte</Title>

			{props?.sistBesokte?.map(linkable => (
				<StyledLink to={linkable.to} key={linkable.item.maalerpunktId}>
					{linkable.item.tittel}
				</StyledLink>
			))}
		</Wrapper>
	);
}
