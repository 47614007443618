import { useGetAllCompanyDocumentsQuery } from 'web/hooks/useMeldinger.graphql-gen';
import { useTenant } from 'web/hooks/useTenant';

export default function useMeldinger(orgnummer?: string) {
	const tenant = useTenant();

	const {
		data: { getAllCompanyDocuments } = {},
		error,
		loading,
	} = useGetAllCompanyDocumentsQuery({
		fetchPolicy: 'cache-and-network',
		variables: {
			companyId: tenant?.orgnummer || orgnummer,
		},
		skip: !(tenant?.orgnummer || orgnummer),
	});

	const ulesteMeldinger = getAllCompanyDocuments?.documents?.filter((melding: any) => !melding.read) ?? [];

	return {
		meldingerLoading: loading,
		meldingerError: error,
		meldinger: getAllCompanyDocuments?.documents,
		ulesteMeldingerCount: getAllCompanyDocuments?.unread,
		ulesteMeldinger: ulesteMeldinger,
	};
}
