// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentElhubAnleggQueryVariables = Types.Exact<{
  maalepunktId?: Types.InputMaybe<Types.Scalars['String']>;
  maalernummer?: Types.InputMaybe<Types.Scalars['String']>;
  orgid: Types.Scalars['ID'];
  postnummer?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type HentElhubAnleggQuery = { anlegg?: { avlesning?: Types.Avlesning, avlest?: string, avregning?: Types.Avregning, blokkert?: boolean, eierId?: string, etasje?: string, gateadresse?: string, husnummer?: string, maalepunktId?: string, maalernummer?: string, nettselskapId?: string, postnummer?: string, poststed?: string } };


export const HentElhubAnleggDocument = gql`
    query hentElhubAnlegg($maalepunktId: String, $maalernummer: String, $orgid: ID!, $postnummer: String) {
  anlegg: hentElhubAnlegg(
    maalepunktId: $maalepunktId
    maalernummer: $maalernummer
    orgid: $orgid
    postnummer: $postnummer
  ) {
    avlesning
    avlest
    avregning
    blokkert
    eierId
    etasje
    gateadresse
    husnummer
    maalepunktId
    maalernummer
    nettselskapId
    postnummer
    poststed
  }
}
    `;

/**
 * __useHentElhubAnleggQuery__
 *
 * To run a query within a React component, call `useHentElhubAnleggQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentElhubAnleggQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentElhubAnleggQuery({
 *   variables: {
 *      maalepunktId: // value for 'maalepunktId'
 *      maalernummer: // value for 'maalernummer'
 *      orgid: // value for 'orgid'
 *      postnummer: // value for 'postnummer'
 *   },
 * });
 */
export function useHentElhubAnleggQuery(baseOptions: Apollo.QueryHookOptions<HentElhubAnleggQuery, HentElhubAnleggQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentElhubAnleggQuery, HentElhubAnleggQueryVariables>(HentElhubAnleggDocument, options);
      }
export function useHentElhubAnleggLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentElhubAnleggQuery, HentElhubAnleggQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentElhubAnleggQuery, HentElhubAnleggQueryVariables>(HentElhubAnleggDocument, options);
        }
export type HentElhubAnleggQueryHookResult = ReturnType<typeof useHentElhubAnleggQuery>;
export type HentElhubAnleggLazyQueryHookResult = ReturnType<typeof useHentElhubAnleggLazyQuery>;
export type HentElhubAnleggQueryResult = Apollo.QueryResult<HentElhubAnleggQuery, HentElhubAnleggQueryVariables>;