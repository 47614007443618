import styled from 'styled-components';
import { device } from 'web/GlobalStyling';

/* Used in Admin and Sportpris pages */
const PageSubTitle = styled.p`
	font-size: 1.125rem;
	color: var(--nte-sort);
	margin: 0;
	max-width: var(--max-textbox-width);

	@media ${device.mobile} {
		margin-bottom: 1rem;
	}
`;

export default PageSubTitle;
