import React from 'react';
import FlexPageWrapper from 'web/components/atoms/FlexPageWrapper';
import PageTitle from 'web/components/atoms/PageTitle';
import Push from 'web/components/atoms/Push';
import InnboksElement from 'web/components/molecules/InnboksElement';
import { Melding } from 'web/models/Melding';

function findClickCaptureParentElement(element: HTMLElement): HTMLElement {
	if (element.parentElement && element.parentElement.className.indexOf('click-capture') > -1) {
		return element.parentElement;
	}
	return findClickCaptureParentElement(element.parentElement);
}

interface InnboksTemplateProps {
	meldinger?: Melding[];
	setRead?: (meldingId: string, read: boolean) => void;
}
export default function InnboksTemplate({ meldinger, setRead }: InnboksTemplateProps) {
	return (
		<FlexPageWrapper>
			<PageTitle>Innboks</PageTitle>

			<Push height="0" />

			{(meldinger?.length &&
				meldinger?.map((melding, key) => (
					<InnboksElement key={key} className="click-capture" href={melding.url} {...(melding as any)} setRead={setRead} />
				))) || (
				<InnboksElement
					title=""
					customTitle=""
					description="Du har ingen meldinger i innboksen akkurat nå. Her kan du få meldinger og rapporter fra NTE."
					created=""
					hideIcon={true}
					read={false}
				/>
			)}
		</FlexPageWrapper>
	);
}
