// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDistributedDocumentQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetDistributedDocumentQuery = { getDistributedDocument?: { contentType?: string, created?: string, customTitle?: string, description?: string, id: string, title?: string, url?: string, companies?: Array<{ kundeNavn: string, orgNr: string }> } };


export const GetDistributedDocumentDocument = gql`
    query getDistributedDocument($id: ID!) {
  getDistributedDocument(id: $id) {
    companies {
      kundeNavn
      orgNr
    }
    contentType
    created
    customTitle
    description
    id
    title
    url
  }
}
    `;

/**
 * __useGetDistributedDocumentQuery__
 *
 * To run a query within a React component, call `useGetDistributedDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistributedDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistributedDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDistributedDocumentQuery(baseOptions: Apollo.QueryHookOptions<GetDistributedDocumentQuery, GetDistributedDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDistributedDocumentQuery, GetDistributedDocumentQueryVariables>(GetDistributedDocumentDocument, options);
      }
export function useGetDistributedDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDistributedDocumentQuery, GetDistributedDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDistributedDocumentQuery, GetDistributedDocumentQueryVariables>(GetDistributedDocumentDocument, options);
        }
export type GetDistributedDocumentQueryHookResult = ReturnType<typeof useGetDistributedDocumentQuery>;
export type GetDistributedDocumentLazyQueryHookResult = ReturnType<typeof useGetDistributedDocumentLazyQuery>;
export type GetDistributedDocumentQueryResult = Apollo.QueryResult<GetDistributedDocumentQuery, GetDistributedDocumentQueryVariables>;