import React from 'react';
import { useAuth } from 'react-oidc-context';
import PageTitle from 'web/components/atoms/PageTitle';
import Paragraph from 'web/components/atoms/Paragraph';
import Push from 'web/components/atoms/Push';
import useAuthHelpers from 'web/hooks/useAuthHelpers';
import { IFrame, LoginPageWrapper } from 'web/pages/generic/LoginPage';

export default function BeOmTilgangLoginPage() {
	const auth = useAuth();
	const { logInFramePath } = useAuthHelpers();

	return (
		<LoginPageWrapper>
			<div>
				<PageTitle isCenter>Få tilgang til Bedriftsportalen</PageTitle>
				<Push />
				<Paragraph>
					Har du ikke logget inn i Bedriftsportalen før kan du enkelt få tilgang ved å registrere deg med BankID. Vi bruker BankID som
					identifiseringsmetode slik at du kan være sikker på at bedriftens informasjon er i trygge hender.
				</Paragraph>
			</div>

			{!auth.isAuthenticated && <IFrame frameBorder="none" src={logInFramePath} />}

			<Paragraph>
				Har du allerede tilgang? Da kan du{' '}
				<a href="/" style={{ fontWeight: 'bold' }}>
					logge inn
				</a>{' '}
				uten å be om tilgang.
			</Paragraph>
		</LoginPageWrapper>
	);
}
