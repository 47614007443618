import { Bedrift } from 'generated/graphql-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { SecondaryButton } from 'web/components/atoms/Button';
import FormLabel from 'web/components/molecules/form/FormLabel';
import { StyledInput } from 'web/components/molecules/form/TextField';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import Tooltip from 'web/components/molecules/Tooltip';
import { useForm, useFormError, useFormValue } from 'web/hooks/useForm';

//#region styling
const BedriftSelectInput = styled(StyledInput)`
	margin-right: 10px;
`;

const ErrorBedrifterWrapper = styled.div`
	padding: 20px;
	background: var(--varsel-roed-10);
	border-radius: 3px;
	margin-bottom: 24px;
`;

const ErrorBedrifterTitle = styled.div`
	font-weight: 500;
	font-size: 17px;
	line-height: 24px;
	color: var(--nte-sort);
	text-align: center;
	margin-bottom: 8px;
	i {
		margin-right: 8px;
		font-size: 26px;
		line-height: 26px;
		color: var(--varsel-roed);
	}
`;

const ErrorBedrift = styled.div`
	margin-bottom: 6px;
`;

const BedrifterWrapper = styled.div`
	margin-bottom: 1.5rem;
	margin-top: 0.8rem;
`;

const Bedrift = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const BedriftLabel = styled.span`
	width: calc(100% - 62px);
	display: block;
`;

const SearchStatusIcon = styled.i`
	color: var(--nte-blaa-logo);
	margin-right: 12px;
`;

const RemoveBedriftButton = styled.button`
	color: var(--nte-blaa-logo);
	font-size: 14px;
	border: none;
	background: none;
	width: 62px;
	display: block;
	&:hover,
	&:active,
	&:focus {
		opacity: 0.7;
	}
`;

const RemoveBedriftIcon = styled.i`
	margin-right: 6px;
`;

const BedriftSeparator = styled.hr`
	margin: 10px 0;
`;
//#endregion
interface BedriftMultiSelect {
	name: string;
	label: string;
	hintText?: string;
	error: string;
	placeholder: string;
	required?: boolean;
	slaaoppBedrift: (orgNr: string) => any;
}
export default function BedriftMultiSelect({ name, label, hintText, error, placeholder, required, slaaoppBedrift, ...props }: BedriftMultiSelect) {
	const form = useForm(name, { isJson: true });
	const formError = useFormError(name);
	const formValue = useFormValue(name);
	const importElementRef = useRef(null);
	const [state, setState] = useState({
		isLoading: false,
		progress: 0,
		bedrifter: formValue || [],
		errorBedrifter: [],
		error: null,
	});

	// Importer kilde for bedrifter dersom dette ikke finnes
	useEffect(() => {
		if (state.bedrifter?.length && !state.bedrifter[0].kilde) {
			const bedrifter = state.bedrifter.map((bedrift: any) => bedrift.orgNr);
			ImporterBedrifter(bedrifter, false);
		}
	}, [false]);

	function ParseBedrifterInput(rawBedrifter: string) {
		const orgNrRawStringArray = rawBedrifter?.split(/[^\d]/g)?.filter(verdi => !!verdi);
		return [...new Set(orgNrRawStringArray)];
	}

	async function ImporterBedrifter(orgNumre: string[], isAddingNewBedrifter: boolean) {
		const existingSuccessOrgnr = state.bedrifter.map((bedrift: any) => bedrift.orgNr);
		const existingErrorOrgnr = state.errorBedrifter.map(bedrift => bedrift.orgNr);
		const existingOrgnr = [...existingSuccessOrgnr, ...existingErrorOrgnr];

		let tempSuccessBedrifter = [];
		let tempErrorBedrifter = [];

		setState({ ...state, isLoading: true, progress: 0, error: '' });

		for (let i in orgNumre) {
			const orgNr = orgNumre[i];
			if (isAddingNewBedrifter) {
				if (existingOrgnr.indexOf(orgNr) > -1) {
					continue;
				}
				if (orgNr?.length !== 9) {
					tempErrorBedrifter.push({ kundeNavn: '', orgNr, error: 'Organisasjonsnummer må bestå av 9 siffer' });
					continue;
				}
				existingOrgnr.push(orgNr);
			}

			try {
				const bedriftOppslag = await slaaoppBedrift(orgNr);
				if (!bedriftOppslag) {
					tempErrorBedrifter.push({ kundeNavn: '', orgNr, kilde: null, error: 'Dette nummeret finnes ikke registrert på en bedrift' });
				} else {
					tempSuccessBedrifter.push({ kundeNavn: bedriftOppslag.navn, orgNr, kilde: bedriftOppslag.kilde, error: null });
				}
			} catch (e) {
				setState({
					...state,
					isLoading: false,
					progress: 1,
					bedrifter: isAddingNewBedrifter ? [...state.bedrifter, ...tempSuccessBedrifter] : [...tempSuccessBedrifter],
					errorBedrifter: isAddingNewBedrifter ? [...state.errorBedrifter, ...tempErrorBedrifter] : [...tempErrorBedrifter],
					error: 'Problemer med å sjekke organisasjonsnummer',
				});

				return;
			}
		}
		setState({
			...state,
			isLoading: false,
			progress: 1,
			bedrifter: isAddingNewBedrifter ? [...state.bedrifter, ...tempSuccessBedrifter] : [...tempSuccessBedrifter],
			errorBedrifter: isAddingNewBedrifter ? [...state.errorBedrifter, ...tempErrorBedrifter] : [...tempErrorBedrifter],
			error: null,
		});
	}

	return (
		<>
			<LoadingOverlay loading={state.isLoading} text="Laster inn bedrifter" />
			<FormLabel label={label} required={required} hintText={hintText} error={state.error || error || formError} style={{ marginBottom: '0' }}>
				<input type="hidden" name={name} value={JSON.stringify(state.bedrifter)} />
				<div style={{ display: 'flex', marginBottom: '10px' }}>
					<BedriftSelectInput
						disabled={form?.disabled || state.isLoading}
						error={error || formError}
						placeholder={placeholder || label}
						name={name + '_import'}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								e.preventDefault();
								e.stopPropagation();

								const orgNumre = ParseBedrifterInput(importElementRef.current?.value);
								ImporterBedrifter(orgNumre, true);
								importElementRef.current.value = '';
							}
						}}
						ref={importElementRef}
						{...props}
					/>
					<SecondaryButton
						style={{ whiteSpace: 'nowrap' }}
						disabled={state.isLoading}
						type="button"
						onClick={e => {
							const orgNumre = ParseBedrifterInput(importElementRef.current?.value);
							ImporterBedrifter(orgNumre, true);
							importElementRef.current.value = '';
						}}>
						Legg til mottakere
					</SecondaryButton>
				</div>
			</FormLabel>
			{(state.errorBedrifter?.length && (
				<ErrorBedrifterWrapper>
					<ErrorBedrifterTitle>
						<i className="fas fa-exclamation-circle" />
						{state.errorBedrifter?.length} organisasjonsnummer ble ikke lagt til
					</ErrorBedrifterTitle>
					{state.errorBedrifter.map(bedrift => {
						return (
							<ErrorBedrift key={bedrift.orgNr}>
								{bedrift.orgNr}
								{bedrift.error && <span>{' - ' + bedrift.error}</span>}
							</ErrorBedrift>
						);
					})}
				</ErrorBedrifterWrapper>
			)) || <></>}
			<BedrifterWrapper>
				{state.bedrifter.map((bedrift: any) => {
					return (
						<div key={bedrift.orgNr}>
							<Bedrift>
								<BedriftLabel>
									{bedrift.kilde === 'Bisnode' && (
										<Tooltip style={{ display: 'inline-block' }} text="Bedriften finnes ikke i portalen.">
											<SearchStatusIcon style={{ color: 'var(--varsel-orange)' }} className="far fa-check-circle" />
										</Tooltip>
									)}
									{bedrift.kilde !== 'Bisnode' && <SearchStatusIcon className="far fa-check-circle" />}
									<b>{bedrift.kundeNavn || bedrift.orgNr}</b>
								</BedriftLabel>
								<RemoveBedriftButton
									type="button"
									onClick={() => {
										setState({ ...state, bedrifter: state.bedrifter.filter((b: any) => b.orgNr != bedrift.orgNr) });
									}}>
									<RemoveBedriftIcon className="fas fa-trash" />
									Fjern
								</RemoveBedriftButton>
							</Bedrift>
							<BedriftSeparator />
						</div>
					);
				})}
			</BedrifterWrapper>
		</>
	);
}
