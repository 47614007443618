import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Center from 'web/components/atoms/Center';
import PageTitle from 'web/components/atoms/PageTitle';
import Paragraph from 'web/components/atoms/Paragraph';

export default function ModulAccessErrorPage({ returnTo }: { returnTo: string }) {
	const { t } = useTranslation('generic');

	return (
		<Center>
			<PageTitle>{t('ModulAccessErrorPage.title')}</PageTitle>
			<Paragraph style={{ marginTop: '2rem', textAlign: 'left' }}>
				<span dangerouslySetInnerHTML={{ __html: t('ModulAccessErrorPage.message') }} />
				<br />
				<Link to={returnTo}>{t('ModulAccessErrorPage.back')}</Link>
			</Paragraph>
		</Center>
	);
}
