import { endOfMonth, format, startOfMonth, subYears } from 'date-fns';
import { Bedrift } from 'generated/graphql-types';
import React, { ReactNode } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import ClearButton from 'web/components/atoms/ClearButton';
import { StyledSelect } from 'web/components/molecules/form/SelectField';
import UserHeaderLabel from 'web/components/molecules/UserHeaderLabel';
import { DashboardLayout } from 'web/components/organisms/DashboardLayout';
import { env } from 'web/env';
import { device } from 'web/GlobalStyling';
import useAuthHelpers from 'web/hooks/useAuthHelpers';
import { useAuthorization, useHasGroup, useIsAdmin } from 'web/hooks/useAuthorization';
import useFilter, { DEFAULT_FILTER } from 'web/hooks/useFilter';
import useMeldinger from 'web/hooks/useMeldinger';
import useMineBedrifter from 'web/hooks/useMineBedrifter';
import { TenantProvider } from 'web/hooks/useTenant';
import { processAnlegg } from 'web/hooks/useTenant.provider';
import { useHentHeleBedriftTenantQuery } from 'web/hooks/useTenant.provider.graphql-gen';
import { useTracking } from 'web/hooks/useTracking';

export const LogoutButton = styled(ClearButton)``;

export const LogoutButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const LayoutHeader = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 1rem 0;
	@media ${device.tablet} {
		display: none;
	}
`;

const HighlightedText = styled.span`
	font-weight: bold;
	color: var(--nte-blaa-logo);
`;

export function TenantLayout({ children }: { children: ReactNode }) {
	const { bedriftId } = useParams() as any;
	const { bedrifter } = useMineBedrifter();
	const { logout } = useAuthHelpers();
	const { user } = useAuthorization();
	const history = useHistory();

	// TODO: move to useTenant or something; we call this every time we enter a page
	const {
		loading,
		error,
		data: { bedrift } = { bedrift: null },
		refetch: refetchTenant,
	} = useHentHeleBedriftTenantQuery({
		variables: {
			orgid: bedriftId,
			sisteAarFraDato: format(startOfMonth(subYears(new Date(), 1)), 'yyyy-MM-dd'),
			sisteToAarFraDato: format(startOfMonth(subYears(new Date(), 2)), 'yyyy-MM-dd'),
			tilDato: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
		},
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'ignore',
	});

	let tenant = null;

	//stringify and parse do do deep copy
	if (bedrift) {
		tenant = {
			...bedrift,
			anlegg: bedrift.anlegg?.map((anlegg: any) => processAnlegg(JSON.parse(JSON.stringify(anlegg)))) ?? [],
			refetchTenant,
		};
	}

	const { url } = useRouteMatch();
	const orgnr = tenant?.orgnummer;

	const isAdmin = useIsAdmin();
	const hasKlimaregnskapAccess = useHasGroup({ group: 'Klimaregnskap', defaultTenant: tenant });
	const hasStroemAccess = useHasGroup({ group: 'Strøm', defaultTenant: tenant });
	const isBedriftsadmin = useHasGroup({ group: 'Bedriftsadmin', defaultTenant: tenant }) || isAdmin;
	const { trackGroup } = useTracking();
	const [_, setFilter] = useFilter();

	const { meldingerLoading, ulesteMeldingerCount } = useMeldinger(orgnr);

	return (
		<DashboardLayout
			mainMenuItems={[
				{
					label: 'Hovedsiden',
					icon: <i className="far fa-house fa-fw" />,
					to: `${url}/hovedsiden/`,
					exact: true,
				},
				{
					label: 'Spotpris',
					icon: <i className="far fa-bullseye-pointer" />,
					to: `${url}/spotpris/`,
					exact: true,
				},
				hasStroemAccess && {
					label: 'Anlegg',
					icon: <i className="far fa-building fa-fw" />,
					to: `${url}/anlegg/`,
					exact: false,
				},
				hasStroemAccess && {
					label: 'Faktura',
					icon: <i className="far fa-file fa-fw" />,
					to: `${url}/faktura/`,
					exact: false,
				},
				hasStroemAccess && {
					label: 'Kostnad',
					icon: <i className="far fa-coins fa-fw" />,
					to: `${url}/kostnad/`,
					exact: true,
				},
				hasStroemAccess && {
					label: 'Forbruk',
					icon: <i className="far fa-signal fa-fw" />,
					to: `${url}/forbruk/`,
					exact: true,
				},
				hasKlimaregnskapAccess && {
					label: 'Klimaregnskap',
					icon: <i className="far fa-hand-holding-seedling" />,
					href: env.REACT_APP_KLIMAREGNSKAP_URL ? `${env.REACT_APP_KLIMAREGNSKAP_URL}${orgnr}` : `https://nte.avinet.no/organizations/${orgnr}`,
					exact: true,
				},
			].filter(e => !!e)}
			secondaryMenuItems={[
				{
					label: 'Innboks',
					subLabel: meldingerLoading ? (
						'Laster...'
					) : ulesteMeldingerCount == 0 ? (
						'Ingen uleste meldinger'
					) : (
						<div>
							<HighlightedText>{ulesteMeldingerCount}</HighlightedText> {ulesteMeldingerCount > 1 ? 'uleste meldinger' : 'ulest melding'}
						</div>
					),
					icon: ulesteMeldingerCount ? <i className="fa fa-envelope fa-fw" /> : <i className="far fa-envelope-open fa-fw" />,
					to: `${url}/innboks/`,
					exact: true,
				},
				isBedriftsadmin && {
					label: 'Bedriftsadmin',
					subLabel: 'Administrer tilganger',
					icon: <i className="far fa-user-cog fa-fw" />,
					to: `${url}/brukere/`,
					exact: true,
				},
				isAdmin && {
					label: 'Systemadmin',
					subLabel: 'Administrer bedrifter',
					icon: <i className="far fa-cog fa-fw" />,
					to: `/admin/kunder/`,
					exact: true,
				},
			].filter(e => !!e)}>
			<LayoutHeader>
				<UserHeaderLabel hasManyTenants={bedrifter?.length > 1} key="global-header" name={user?.name} tenantName={tenant?.navn}>
					<div>
						{bedrifter?.length > 1 && (
							<StyledSelect
								onClick={e => e.stopPropagation()}
								value={tenant?.id}
								onChange={e => {
									const orgId = e.target.value;
									const newBedrift = bedrifter.find(b => b.id === orgId);

									trackGroup(newBedrift?.orgnummer, newBedrift?.navn);
									setFilter(DEFAULT_FILTER);

									const currentLocationList = history?.location?.pathname?.split('/') || [];
									history.push(`/${orgId}/${currentLocationList[2] ? currentLocationList[2] + '/' : ''}`);
								}}>
								{bedrifter?.map((b: Bedrift, i) => (
									<option key={i} value={b.id}>
										{b.navn}
									</option>
								))}
							</StyledSelect>
						)}
						<LogoutButtonWrapper>
							<LogoutButton
								onClick={() => {
									logout();
								}}
								type="button">
								Logg ut
							</LogoutButton>
						</LogoutButtonWrapper>
					</div>
				</UserHeaderLabel>
			</LayoutHeader>
			<TenantProvider tenant={tenant}>{children}</TenantProvider>
		</DashboardLayout>
	);
}
