import React from 'react';
import { FilterDrawerSearch } from 'web/components/molecules/filter/FilterDrawerSelect';
import FilterDrawerSection from 'web/components/molecules/FilterDrawerSection';
import { FilterDataTextInputElement } from 'web/lib/filter.utils';

export default function FilterDrawerTextInput(props: FilterDataTextInputElement) {
	return (
		<FilterDrawerSection label="Søk" style={props.filterDrawerStyle}>
			<FilterDrawerSearch
				searchPlaceholder={props.searchPlaceholder || props.title ? `Søk blant ${props.title.toLowerCase()}...` : 'Søk...'}
				value={props.value}
				onChange={props.onChange}
			/>
		</FilterDrawerSection>
	);
}
