import { format } from 'date-fns';
import { groupBy } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'web/components/atoms/Button';
import FlexPageWrapper from 'web/components/atoms/FlexPageWrapper';
import PageTitle from 'web/components/atoms/PageTitle';
import FileField from 'web/components/molecules/form/FileField';
import TariffVelger2 from 'web/components/molecules/form/TariffVelger2';
import TextField from 'web/components/molecules/form/TextField';
import InfoBox, { INFOBOX_ALIGN } from 'web/components/molecules/InfoBox';
import Form from 'web/hooks/useForm';
import { BatchInnmeldingAnlegg, Innmelding, Kontaktinfo, Kunde } from 'web/pages/admin/MeldInnAnleggBatchPage';
import { read, utils } from 'xlsx';

const MAALEPUNKT_HEADER = 'MålepunktID';
const MAALEPUNKT_HEADER_LOW = MAALEPUNKT_HEADER.toLowerCase();
const FAKTURAMERKE_HEADER = 'Prosjekt';
const FAKTURAMERKE_HEADER_LOW = FAKTURAMERKE_HEADER.toLowerCase();
const REQUIRED_COLUMN_NAMES = [MAALEPUNKT_HEADER, FAKTURAMERKE_HEADER];
const REQUIRED_COLUMN_NAMES_LOW = [MAALEPUNKT_HEADER_LOW, FAKTURAMERKE_HEADER_LOW];

const PageLayout = styled.div`
	max-width: 1200px;
	margin: auto;
`;

function lowercase(object: any): any {
	let key,
		keys = Object.keys(object);
	let n = keys?.length;
	const newobj = {};
	while (n--) {
		key = keys[n];
		// @ts-ignore
		newobj[key.toLowerCase()] = object[key];
	}
	return newobj;
}
const AZ = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'Y', 'Z'];
async function handleXlsx(file: File, tariffId: string, oppstartdato: string): Promise<Innmelding[]> {
	return new Promise(async (resolve, reject) => {
		const data = await file.arrayBuffer();
		if (!data) {
			reject('Mangler fil');
		}
		const workbook = read(data);
		const first_sheet_name = workbook.SheetNames[0];
		const worksheet = workbook.Sheets[first_sheet_name];
		const headers = AZ.map(a => `${a}1`)
			.map(row => worksheet[row])
			.filter(v => !!v)
			.map(column => column.v as string);
		const lowHeaders = headers.map(h => h.toLowerCase());
		const allHeadersPresent = REQUIRED_COLUMN_NAMES_LOW.every(h => lowHeaders.includes(h.toLowerCase()));
		if (!allHeadersPresent) {
			reject(`Nødvendige headere (${REQUIRED_COLUMN_NAMES.join(', ')}) ikke tilstede. Tilstede: ${headers.join(', ')}`);
		}
		const json = utils.sheet_to_json(worksheet);

		const lowercased = json.map(row => lowercase(row));
		const dictionary = groupBy(lowercased, (o: any) => `${o[FAKTURAMERKE_HEADER_LOW]}`);
		resolve(
			Object.values(dictionary).flatMap(group => {
				const first = group[0];
				const maalerpunkter = group.map(m => m[MAALEPUNKT_HEADER_LOW] as string);
				const chunkSize = 10;
				const partitions = [];
				for (let i = 0; i < maalerpunkter.length; i += chunkSize) {
					const chunk = maalerpunkter.slice(i, i + chunkSize);
					partitions.push(new Innmelding(tariffId, first[FAKTURAMERKE_HEADER_LOW], chunk, oppstartdato));
				}
				return partitions;
			})
		);
	});
}

async function getInnmeldinger(form: any, inputType: InnmeldingInputType, file: File): Promise<Innmelding[]> {
	const oppstartdato = format(new Date(form.oppstartdato), 'yyyy-MM-dd');
	const tariffId = form['tariffId'];
	if (inputType == 'form') {
		return [new Innmelding(tariffId, form['fakturamerke'], form['maalepunktId'].split(','), oppstartdato)];
	} else {
		return handleXlsx(file, tariffId, oppstartdato);
	}
}

async function createBatchInnmeldingAnlegg(form: any, inputType: InnmeldingInputType, file: File): Promise<BatchInnmeldingAnlegg> {
	return new BatchInnmeldingAnlegg(
		new Kunde(form.fornavn, form.etternavn, form.mobil, form.epost, form.gatenavn, form.gatenummer, form.postnummer, form.poststed),
		await getInnmeldinger(form, inputType, file)
	);
}
interface BatchInnmeldingStatus {
	innmeldingStatuser?: InnmeldingStatus[];
	error?: any;
}
interface InnmeldingStatus {
	ordreId: string;
	fakturamerke: string;
	maalepunkter: string[];
	status: string;
	error: string;
}

function ShowInnmeldingStatus({ status }: { status: BatchInnmeldingStatus }) {
	const { error, innmeldingStatuser } = status;
	return (
		<div>
			{error && <div>Feil ved innsending: {error}</div>}
			{innmeldingStatuser &&
				innmeldingStatuser.map(s => (
					<div key={s.maalepunkter.toString()}>
						<h3>
							Status fakturamerke {s.fakturamerke}: {s.status}
						</h3>
						{s.ordreId && <h2>Strømbestilling {s.ordreId}</h2>}
						{s.error && <div>{s.error}</div>}
					</div>
				))}
		</div>
	);
}

type InnmeldingInputType = 'form' | 'xlsx';

interface MeldInnAnleggBatchTemplateProps {
	tariffer?: string[];
	submitForm: (data: Promise<BatchInnmeldingAnlegg>) => Promise<any>;
	bedriftsnavn: string;
	kontaktinfo: Kontaktinfo;
}
export default function MeldInnAnleggBatchTemplate({ tariffer, submitForm, bedriftsnavn, kontaktinfo }: MeldInnAnleggBatchTemplateProps) {
	const [status, setStatus] = useState<BatchInnmeldingStatus | undefined>(undefined);
	const [inputType, setInputType] = useState<InnmeldingInputType | undefined>(undefined);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const submitHandler = async (value: any) =>
		submitForm(createBatchInnmeldingAnlegg(value, inputType, selectedFile))
			.then(status => setStatus(status))
			.catch(rejected => setStatus({ error: rejected }));
	const radioChangeHandler = (e: any) => setInputType(e.target.value as InnmeldingInputType);

	if (tariffer?.length) {
		return (
			<FlexPageWrapper>
				<PageTitle style={{ position: 'relative' }}>Meld inn mange anlegg til {bedriftsnavn}</PageTitle>

				<div>
					<label>
						<input type="radio" value="form" checked={inputType === 'form'} onChange={radioChangeHandler} />
						Bruk skjema
					</label>
					<label>
						<input type="radio" value="xlsx" checked={inputType === 'xlsx'} onChange={radioChangeHandler} />
						Last opp .xlsx-fil
					</label>
				</div>
				<hr />

				<Form onSubmit={submitHandler}>
					<TextField required name="fornavn" label="Fornavn" defaultValue={kontaktinfo.fornavn} />
					<TextField required name="etternavn" label="Etternavn" defaultValue={kontaktinfo.etternavn} />
					<TextField required name="mobil" label="Mobil" defaultValue={kontaktinfo.telefonnummer} />
					<TextField required name="epost" label="Epost" defaultValue={kontaktinfo.epost} />
					<TextField required name="gatenavn" label="Gatenavn" defaultValue={kontaktinfo.gatenavn} />
					<TextField required name="gatenummer" label="Gatenummer" defaultValue={kontaktinfo.gatenummer} />
					<TextField required name="postnummer" label="Postnummer" defaultValue={kontaktinfo.postnummer} />
					<TextField required name="poststed" label="Poststed" defaultValue={kontaktinfo.poststed} />

					<hr />
					<TariffVelger2 required={inputType === 'form'} tariffer={tariffer} name={`tariffId`} error={false} />
					{inputType === 'form' && (
						<>
							<TextField name="fakturamerke" label="Fakturamerke" />
							<TextField
								required={inputType === 'form'}
								name="maalepunktId"
								placeholder="Målepunkter"
								label="Målepunkter"
								hintText="Kan være kommaseparert liste med målepunktID'er"
								pattern="\d{18}(,\d{18})*"
							/>
						</>
					)}
					{inputType === 'xlsx' && (
						<FileField
							required={inputType === 'xlsx'}
							name="file"
							label=".xlsx-fil"
							buttonText="Last opp fil"
							hintText={`Filen må ha kolonnene ${REQUIRED_COLUMN_NAMES.join(', ')}`}
							fileSelectHandler={setSelectedFile}
						/>
					)}

					<TextField required name={`oppstartdato`} label="Oppstartsdato (yyyy-mm-dd)" pattern="\d{4}-\d{2}-\d{2}" />

					{status && <ShowInnmeldingStatus status={status} />}
					<Button>Send inn</Button>
				</Form>
			</FlexPageWrapper>
		);
	} else {
		return (
			<FlexPageWrapper>
				<PageTitle style={{ position: 'relative' }}>Kan ikke melde inn anlegg</PageTitle>
				<InfoBox align={INFOBOX_ALIGN.left}>Kan ikke melde inn anlegg til {bedriftsnavn}, bedriften mangler godkjente tariffer</InfoBox>
			</FlexPageWrapper>
		);
	}
}
