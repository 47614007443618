import { useHentBedrifterQuery } from 'web/hooks/useMineBedrifter.graphql-gen';

export default function useMineBedrifter() {
	const {
		loading,
		error,
		data: { bedrifter } = { bedrifter: [] },
	} = useHentBedrifterQuery({
		fetchPolicy: 'cache-and-network',
	});

	return {
		loading,
		error,
		bedrifter,
	};
}
