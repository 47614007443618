import { updateAnleggMedFjutt } from 'web/hooks/SingleAnlegg/useUpdateAnleggMedFjutt';
import { useAuthorization } from 'web/hooks/useAuthorization';
import { useAddFjuttMutation } from 'web/hooks/useHasFjutt.graphql-gen';
import { useTenant } from 'web/hooks/useTenant';
import { HarkUserToken } from 'web/models/Fjutt';

export function useHasFjutt() {
	const tenant = useTenant();
	const auth = useAuthorization();
	const harkToken = auth.harkToken as HarkUserToken;
	const anleggMedFjutt = tenant.fjutter?.map((f: any) => f.anleggsId);

	const [addFjutt] = useAddFjuttMutation();

	async function probeFjuttExists(anleggsId: string): Promise<boolean> {
		if (!anleggsId || !harkToken) {
			return false;
		}

		const brukerensFjuttAnlegg = harkToken.devices?.map(d => d.anleggsId);
		if (anleggMedFjutt.includes(anleggsId) && brukerensFjuttAnlegg.includes(anleggsId)) {
			return true;
		} else {
			const { data, errors } = await addFjutt({
				variables: {
					anleggsId: anleggsId,
					orgId: tenant.id,
				},
			});
			const { error, harkUserToken, fjutt } = data?.addFjutt;

			if (error) {
				if (error.type !== 'FJUTT_NOT_FOUND' && !error.message.includes('Fant ingen fjutt på anlegg')) {
					console.error(error);
				}
				if (error.type === 'FJUTT_ALREADY_REGISTERED') {
					return true;
				} else {
					return false;
				}
			} else {
				updateAnleggMedFjutt(anleggsId, tenant.id);
				// update harkUserToken?
				return true;
			}
		}
	}

	return { probeFjuttExists };
}
