import React from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { FilterDataTextInputElement } from 'web/lib/filter.utils';

export const SearchWrapper = styled.div`
	position: relative;
	align-self: flex-end;
	&::placeholder {
		color: var(--nte-graa-medium);
	}
	i {
		position: absolute;
		right: 12px;
		font-size: 1rem;
		line-height: 2;
		font-weight: normal;
		color: var(--nte-blaa);
	}
`;

const SearchInput = styled.input`
	height: 2rem;
	padding: 0 22px 0 12px;
	background: var(--white);
	border: var(--nte-border);
	border-radius: var(--border-radius-small);
`;

export default function FilterBarTextInput(props: FilterDataTextInputElement) {
	const debounced = useDebouncedCallback(value => {
		props.onChange(value);
	}, 300);

	return (
		<SearchWrapper style={props.filterBarStyle}>
			<SearchInput
				placeholder={props.searchPlaceholder || props.title ? `Søk blant ${props.title.toLowerCase()}...` : 'Søk...'}
				defaultValue={props.value}
				onChange={e => debounced(e.target.value)}
			/>
			<i className="fa fa-search" />
		</SearchWrapper>
	);
}
