import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button, { ButtonWrapper, SecondaryButton } from 'web/components/atoms/Button';
import Modal from 'web/components/molecules/Modal';
import { device } from 'web/GlobalStyling';

const MessageWrapper = styled.div`
	max-width: 32.5rem;
	text-align: left;
	font-size: 1.375rem;
	margin: 2rem 0;
	@media ${device.mobile} {
		font-size: 1rem;
		margin: 1rem 0;
	}
`;

interface AlertProps {
	title?: string;
	message?: any;
	confirm?: string;
	removeConfirm?: boolean;
	onClick?: (e?: any) => void;
}
export function Alert({ title, message, confirm, removeConfirm = false, onClick }: AlertProps) {
	return (
		<Modal title={title}>
			<MessageWrapper>{message}</MessageWrapper>
			{!removeConfirm && (
				<Button type="button" onClick={onClick}>
					{confirm}
				</Button>
			)}
		</Modal>
	);
}

interface BlankAlertModalProps {
	modalRef?: React.MutableRefObject<any>;
	onClose?: (e?: any) => void;
	modalStyle?: React.CSSProperties;
	children?: ReactNode;
}
export function BlankAlertModal({ modalRef, onClose, modalStyle, children, ...props }: BlankAlertModalProps) {
	return (
		<Modal modalRef={modalRef} style={modalStyle} onClose={onClose} dontTrack={true} {...props}>
			<MessageWrapper>{children}</MessageWrapper>
		</Modal>
	);
}

interface ConfirmModalButtonProps extends React.HTMLProps<HTMLButtonElement> {
	onClick: (e?: any) => void;
	onModalOpened?: () => void;
	onModalClosed?: () => void;
	title?: string;
	message?: string;
	confirmIcon?: string;
	confirm?: string;
	declineIcon?: string;
	decline?: string;
	children?: ReactNode;
}
export default function ConfirmModalButton({
	onClick,
	onModalOpened,
	onModalClosed,
	title,
	message,
	confirmIcon,
	confirm,
	declineIcon,
	decline,
	...rest
}: ConfirmModalButtonProps) {
	const { t } = useTranslation('generic');
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<button
				{...rest}
				title={title}
				type="button"
				onClick={() => {
					onModalOpened && onModalOpened();
					setIsOpen(true);
				}}
			/>
			{isOpen && (
				<Modal
					title={title}
					onClose={() => {
						setIsOpen(false);
						onModalClosed && onModalClosed();
					}}>
					<MessageWrapper>{message}</MessageWrapper>
					<ButtonWrapper>
						<Button
							type="button"
							onClick={() => {
								setIsOpen(false);
								onClick();
							}}>
							{confirmIcon && <i className={confirmIcon} />}
							{confirm}
						</Button>

						<SecondaryButton
							type="button"
							onClick={() => {
								setIsOpen(false);
								onModalClosed && onModalClosed();
							}}>
							{declineIcon && <i className={declineIcon} />}
							{decline || t('ConfirmModalButton.cancel')}
						</SecondaryButton>
					</ButtonWrapper>
				</Modal>
			)}
		</>
	);
}

export const ClearConfirmModalButton = styled(ConfirmModalButton)`
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.5;
	background: none;
	border: none;
	color: var(--nte-blaa-uu);
	padding: 0.5rem;
`;
