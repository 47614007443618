import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'web/components/atoms/Button';
import Paragraph from 'web/components/atoms/Paragraph';
import Push from 'web/components/atoms/Push';
import DataError from 'web/components/molecules/form/DataError';
import ModulChecboxList from 'web/components/molecules/form/ModulCheckboxList';
import TextField from 'web/components/molecules/form/TextField';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import Modal from 'web/components/molecules/Modal';
import { useFinnBrukereLazyQuery, useLeggTilMedlemMutation } from 'web/components/organisms/modals/AddBedriftUserModal.graphql-gen';
import Form from 'web/hooks/useForm';
import { useModalSystem } from 'web/hooks/useModalSystem';
import { useTracking } from 'web/hooks/useTracking';
import { useHentBedriftAdminQuery, useSettBrukermodulerMutation } from 'web/pages/AdminPage.graphql-gen';

const StyledSearchWrapper = styled.div`
	display: flex;
`;
const UserList = styled.div`
	max-height: 300px;
	overflow: auto;
	margin-top: 12px;
	border: var(--nte-border);
	border-radius: 3px;
`;
const UserListElement = styled.button`
	display: block;
	width: 100%;
	background: transparent;
	border: none;
	padding: 12px;
	text-align: left;
	&:nth-child(even) {
		background: var(--nte-graa-lys-50);
	}
`;

const MODAL_NAME = 'AddBedriftUserModal';

interface useModalSystemProps {
	openModal?: (name: string, props: any) => void;
	closeModal?: (name: string) => void;
	modals?: any[];
}
export function useAddBedriftUserModal() {
	const { openModal, closeModal }: useModalSystemProps = useModalSystem();
	return {
		open: (props: any) => {
			openModal(MODAL_NAME, props);
		},
		close: () => {
			closeModal(MODAL_NAME);
		},
	};
}

interface AddBedriftUserModalProps {
	orgid: string;
	callback: () => void;
}
export function AddBedriftUserModal({ orgid, callback }: AddBedriftUserModalProps) {
	const { track } = useTracking();
	const { close } = useAddBedriftUserModal();
	const [selectedUser, setSelectedUser] = useState(null);

	const {
		loading: bedriftLoading,
		error: bedriftError,
		data: { bedrift } = { bedrift: null },
		refetch: refetchBedrift,
	} = useHentBedriftAdminQuery({
		variables: {
			orgid,
		},
		fetchPolicy: 'cache-and-network',
	});

	const [finnBruker, { loading: userSearchLoading, error: userSearchError, data }] = useFinnBrukereLazyQuery();

	const [addBedriftBruker, { loading: addBedriftBrukerLoading, error: addBedriftBrukerError }] = useLeggTilMedlemMutation();

	const [addBedriftModulBrukerer, { loading: addBedriftModulBrukerLoading, error: addBedriftModulBrukerError }] = useSettBrukermodulerMutation();

	const loading = bedriftLoading || userSearchLoading || addBedriftBrukerLoading || addBedriftModulBrukerLoading;

	if (selectedUser) {
		return (
			<Modal title={selectedUser.fornavn + ' ' + selectedUser.etternavn} onClose={() => setSelectedUser(null)}>
				<LoadingOverlay loading={loading} />
				<Paragraph>
					Hvilke tilganger skal {selectedUser.fornavn} {selectedUser.etternavn} ha?
				</Paragraph>

				<Push />

				<DataError error={bedriftError || addBedriftBrukerError || addBedriftModulBrukerError} />
				<Form
					key="module-form"
					onSubmit={async (_: any, e: any) => {
						let valgteModulerIder: string[] = [];

						for (let index = 0; index < e.target.modulCheckbox?.length; index++) {
							const element = e.target.modulCheckbox[index];
							if (element.checked) {
								valgteModulerIder.push(element.value);
							}
						}

						await addBedriftBruker({
							variables: {
								orgid,
								brukerId: selectedUser.id,
							},
						});
						await addBedriftModulBrukerer({
							variables: {
								modulIder: valgteModulerIder,
								orgid,
								brukerId: selectedUser.id,
							},
						});

						const chosenModulesNames = bedrift.moduler.filter(m => valgteModulerIder.includes(m.id)).map(m => m.navn);

						track('Account Added User', { role: chosenModulesNames });

						callback && callback();

						close();
					}}>
					<ModulChecboxList moduler={bedrift?.moduler} checkedModuler={['Strøm', 'Bedriftsadmin']} />
					<Push height="1rem" />
					<Button>Legg til bruker</Button>
				</Form>
			</Modal>
		);
	} else {
		return (
			<Modal title="Legg til bruker" onClose={() => close()}>
				<LoadingOverlay loading={loading} />

				<Paragraph>Her kan du legge til brukere som allerede er registrert i Bedriftsportalen.</Paragraph>

				<DataError error={userSearchError} />

				<Form
					key="user-form"
					loading={loading}
					onSubmit={({ query }: any) => {
						finnBruker({
							variables: {
								query,
							},
						});
					}}>
					<Push height="1rem" />
					<StyledSearchWrapper>
						<TextField name="query" placeholder="Navn..." />
						<Button style={{ height: 'fit-content' }}>Søk</Button>
					</StyledSearchWrapper>
				</Form>

				{data && data?.brukere && (
					<UserList>
						{(data?.brukere?.length &&
							data?.brukere?.map(bruker => (
								<UserListElement
									key={bruker.id}
									onClick={e => {
										e.preventDefault();

										setSelectedUser(bruker);
									}}>
									{`${bruker.fornavn} ${bruker.etternavn}`}
								</UserListElement>
							))) || <div style={{ padding: '1rem', color: 'var(--nte-sort)' }}>Fant ingen brukere</div>}
					</UserList>
				)}
			</Modal>
		);
	}
}

export default {
	component: AddBedriftUserModal,
	name: MODAL_NAME,
};
