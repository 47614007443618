// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentHeleBedriftTenantQueryVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
  sisteAarFraDato: Types.Scalars['Date'];
  sisteToAarFraDato: Types.Scalars['Date'];
  tilDato: Types.Scalars['Date'];
}>;


export type HentHeleBedriftTenantQuery = { bedrift?: { id?: string, navn?: string, orgnummer?: string, kontakt?: string, kontaktEpost?: string, antallForfalteFakturaer?: any, totalSaldoUtestaaende?: number, anlegg?: Array<{ maalernummer?: string, maalerpunktId?: string, longitude?: number, latitude?: number, naeringskode?: string, fakturamerke?: string, leveringspunkt?: string, netteier?: string, aarsforbruk?: any, anleggsstatus?: Types.Anleggsstatus, naeringskodeID?: string, omregningsfaktor?: number, omregningsfaktorFradato?: string, poststed?: string, postnummer?: string, prisOmrade?: string, adresse?: string, tittel?: string, egenAnleggsbeskrivelse?: string, salgsordrer?: Array<{ salgsordrenummer?: string, abonnementgruppe?: string, fraDato?: string, avregnetTil?: string, virksomhet?: string, fakturamerke?: string, forbrukskode?: string, tilDato?: string, beskrivelse?: string, status?: string, tariffVersjoner?: Array<{ aarsforbruk?: number, fradato?: string, aarskostnad?: number, produkt?: string, tariff?: string, tildato?: string }> }> }>, fjutter?: Array<{ anleggsId?: string, deviceId?: string, omregningsfaktor?: number }>, dokumenter?: { unread: number, documents?: Array<{ id?: string, title?: string, read: boolean, created?: string, description?: string, customTitle?: string, contentType?: string, url?: string }> }, forbrukSisteToAar?: { retning?: Types.Retning, opploesning?: Types.Opploesning, verdier?: Array<{ mengde?: number, importert?: number, eksportert?: number, fraTid?: string, tilTid?: string }> }, kostnadSisteAar?: { verdier?: Array<{ fraTid?: string, tilTid?: string, kostnad?: number, status?: string }> }, brukere?: Array<{ id?: string, fornavn?: string, etternavn?: string, moduler?: Array<string>, epost?: string }>, moduler?: Array<{ id?: string, navn?: string }> } };

export type BedriftDetaljerFragment = { id?: string, navn?: string, orgnummer?: string, kontakt?: string, kontaktEpost?: string, antallForfalteFakturaer?: any, totalSaldoUtestaaende?: number, brukere?: Array<{ id?: string, fornavn?: string, etternavn?: string, moduler?: Array<string>, epost?: string }>, moduler?: Array<{ id?: string, navn?: string }> };

export type AnleggDetaljerFragment = { maalernummer?: string, maalerpunktId?: string, longitude?: number, latitude?: number, naeringskode?: string, fakturamerke?: string, leveringspunkt?: string, netteier?: string, aarsforbruk?: any, anleggsstatus?: Types.Anleggsstatus, naeringskodeID?: string, omregningsfaktor?: number, omregningsfaktorFradato?: string, poststed?: string, postnummer?: string, prisOmrade?: string, adresse?: string, tittel?: string, egenAnleggsbeskrivelse?: string, salgsordrer?: Array<{ salgsordrenummer?: string, abonnementgruppe?: string, fraDato?: string, avregnetTil?: string, virksomhet?: string, fakturamerke?: string, forbrukskode?: string, tilDato?: string, beskrivelse?: string, status?: string, tariffVersjoner?: Array<{ aarsforbruk?: number, fradato?: string, aarskostnad?: number, produkt?: string, tariff?: string, tildato?: string }> }> };

export type FjuttDetaljerFragment = { anleggsId?: string, deviceId?: string, omregningsfaktor?: number };

export type DokumentDetaljerFragment = { id?: string, title?: string, read: boolean, created?: string, description?: string, customTitle?: string, contentType?: string, url?: string };

export type FakturaDetaljerFragment = { fakturanummer?: string, fakturatype?: Types.InvoiceType, fakturastatus?: Types.InvoiceStatus, beloepInklMoms?: number, pdf?: string, fakturadato?: string, forbruk?: number, saldo?: number, forfallsdato?: string, kundeid?: string, url?: string, maalerpunktIder?: Array<string> };

export type ForbruksdetaljeVerdierDetailsFragment = { mengde?: number, importert?: number, eksportert?: number, fraTid?: string, tilTid?: string };

export type ForbrukVerdierDetailsFragment = { fraTid?: string, tilTid?: string, kostnad?: number, status?: string };

export const BedriftDetaljerFragmentDoc = gql`
    fragment bedriftDetaljer on Bedrift {
  id
  navn
  orgnummer
  kontakt
  kontaktEpost
  antallForfalteFakturaer
  totalSaldoUtestaaende
  brukere {
    id
    fornavn
    etternavn
    moduler
    epost
  }
  moduler {
    id
    navn
  }
}
    `;
export const AnleggDetaljerFragmentDoc = gql`
    fragment anleggDetaljer on Anlegg {
  maalernummer
  maalerpunktId
  longitude
  latitude
  naeringskode
  fakturamerke
  leveringspunkt
  netteier
  aarsforbruk
  anleggsstatus
  naeringskode
  naeringskodeID
  omregningsfaktor
  omregningsfaktorFradato
  poststed
  postnummer
  prisOmrade
  adresse
  tittel
  egenAnleggsbeskrivelse
  salgsordrer {
    salgsordrenummer
    abonnementgruppe
    fraDato
    avregnetTil
    virksomhet
    fakturamerke
    forbrukskode
    tilDato
    beskrivelse
    status
    tariffVersjoner {
      aarsforbruk
      fradato
      aarskostnad
      produkt
      tariff
      tildato
    }
  }
}
    `;
export const FjuttDetaljerFragmentDoc = gql`
    fragment fjuttDetaljer on Fjutt {
  anleggsId
  deviceId
  omregningsfaktor
}
    `;
export const DokumentDetaljerFragmentDoc = gql`
    fragment dokumentDetaljer on CompanyDocument {
  id
  title
  read
  created
  description
  customTitle
  contentType
  url
}
    `;
export const FakturaDetaljerFragmentDoc = gql`
    fragment fakturaDetaljer on Faktura {
  fakturanummer
  fakturatype
  fakturastatus
  beloepInklMoms
  pdf
  fakturadato
  forbruk
  saldo
  forfallsdato
  kundeid
  url
  maalerpunktIder
}
    `;
export const ForbruksdetaljeVerdierDetailsFragmentDoc = gql`
    fragment forbruksdetaljeVerdierDetails on ForbruksdetaljerVerdi {
  mengde
  importert
  eksportert
  fraTid
  tilTid
}
    `;
export const ForbrukVerdierDetailsFragmentDoc = gql`
    fragment forbrukVerdierDetails on Forbruksverdi {
  fraTid
  tilTid
  kostnad
  status
}
    `;
export const HentHeleBedriftTenantDocument = gql`
    query hentHeleBedriftTenant($orgid: ID!, $sisteAarFraDato: Date!, $sisteToAarFraDato: Date!, $tilDato: Date!) {
  bedrift: hentBedrift(orgid: $orgid) {
    ...bedriftDetaljer
    anlegg {
      ...anleggDetaljer
    }
    fjutter {
      ...fjuttDetaljer
    }
    dokumenter {
      unread
      documents {
        ...dokumentDetaljer
      }
    }
    forbrukSisteToAar: forbruksdetaljer(
      fraDato: $sisteToAarFraDato
      tilDato: $tilDato
    ) {
      retning
      opploesning
      verdier {
        ...forbruksdetaljeVerdierDetails
      }
    }
    kostnadSisteAar: forbruk(fraDato: $sisteAarFraDato, tilDato: $tilDato) {
      verdier {
        ...forbrukVerdierDetails
      }
    }
  }
}
    ${BedriftDetaljerFragmentDoc}
${AnleggDetaljerFragmentDoc}
${FjuttDetaljerFragmentDoc}
${DokumentDetaljerFragmentDoc}
${ForbruksdetaljeVerdierDetailsFragmentDoc}
${ForbrukVerdierDetailsFragmentDoc}`;

/**
 * __useHentHeleBedriftTenantQuery__
 *
 * To run a query within a React component, call `useHentHeleBedriftTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentHeleBedriftTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentHeleBedriftTenantQuery({
 *   variables: {
 *      orgid: // value for 'orgid'
 *      sisteAarFraDato: // value for 'sisteAarFraDato'
 *      sisteToAarFraDato: // value for 'sisteToAarFraDato'
 *      tilDato: // value for 'tilDato'
 *   },
 * });
 */
export function useHentHeleBedriftTenantQuery(baseOptions: Apollo.QueryHookOptions<HentHeleBedriftTenantQuery, HentHeleBedriftTenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentHeleBedriftTenantQuery, HentHeleBedriftTenantQueryVariables>(HentHeleBedriftTenantDocument, options);
      }
export function useHentHeleBedriftTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentHeleBedriftTenantQuery, HentHeleBedriftTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentHeleBedriftTenantQuery, HentHeleBedriftTenantQueryVariables>(HentHeleBedriftTenantDocument, options);
        }
export type HentHeleBedriftTenantQueryHookResult = ReturnType<typeof useHentHeleBedriftTenantQuery>;
export type HentHeleBedriftTenantLazyQueryHookResult = ReturnType<typeof useHentHeleBedriftTenantLazyQuery>;
export type HentHeleBedriftTenantQueryResult = Apollo.QueryResult<HentHeleBedriftTenantQuery, HentHeleBedriftTenantQueryVariables>;