import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Direction, Flex, Justify } from 'web/components/atoms/Flex';
import { device } from 'web/GlobalStyling';

const Label = styled.span`
	font-size: 1.25rem;
	line-height: 1.15;
	font-weight: 500;
`;

const SubLabel = styled.span`
	font-size: 1rem;
	line-height: 1.2;
	font-weight: normal;
`;

const IconWrapper = styled.span`
	color: var(--nte-blaa);
	font-size: 1.25rem;
	line-height: 1;
	height: 20px;
`;

export const StyledNavLink = styled(NavLink)`
	display: flex;
	align-content: center;
	min-height: 4.6rem;
	padding: 1rem 2rem;
	box-sizing: border-box;
	text-decoration: none;
	border-right: 6px solid transparent;
	color: var(--nte-sort);
	font-weight: normal;
	position: relative;
	transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
	grid-column: 2;
	svg {
		color: var(--nte-graa-mork);
		width: 18px;
	}

	&.active,
	&:hover,
	&:focus {
		text-decoration: none;
		font-weight: bold;
		border-right: 6px solid var(--nte-blaa);
		color: var(--nte-blaa-uu);
	}

	@media ${device.tablet} {
		min-height: 4rem;
		padding: 0.5rem 2rem;
		border-right: none;
		&.active,
		&:hover,
		&:focus {
			border-right: none;
		}
	}
`;

export const StyledATag = styled.a`
	width: 100%;
	display: flex;
	align-content: center;
	min-height: 4.6rem;
	padding: 1rem 2rem;
	box-sizing: border-box;
	text-decoration: none;
	border-right: 6px solid transparent;
	color: var(--nte-sort);
	font-weight: normal;
	position: relative;
	transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
	grid-column: 2;
	svg {
		color: var(--nte-graa-mork);
		width: 18px;
	}

	&.active,
	&:hover,
	&:focus {
		text-decoration: none;
		font-weight: bold;
		border-right: 6px solid var(--nte-blaa);
		color: var(--nte-blaa-uu);
	}

	@media ${device.tablet} {
		min-height: 4rem;
		padding: 0.5rem 2rem;
		border-right: none;
		&.active,
		&:hover,
		&:focus {
			border-right: none;
		}
	}
`;
export interface MenuItemProps {
	label: ReactNode;
	subLabel?: ReactNode;
	to?: string;
	href?: string;
	icon: ReactNode;
	exact?: boolean;
}
export function MenuItem(props: MenuItemProps) {
	const content = (
		<Flex direction={Direction.row} gap="1rem" alignContent={Justify.center}>
			<Flex direction={Direction.column} justifyContent={Justify.center} style={{ width: '25px', textAlign: 'center' }}>
				<IconWrapper>{props.icon}</IconWrapper>
			</Flex>
			<Flex direction={Direction.column} justifyContent={Justify.center}>
				<Label>{props.label}</Label>
				{props.subLabel && <SubLabel>{props.subLabel}</SubLabel>}
			</Flex>
		</Flex>
	);

	if (props.href) {
		return <StyledATag href={props.href}>{content}</StyledATag>;
	}

	return (
		<StyledNavLink to={props.to} exact={props.exact}>
			{content}
		</StyledNavLink>
	);
}
