import React from 'react';
import { useMeldInnAnleggModal } from 'web/components/organisms/modals/MeldInnAnleggModal';
import useAnlegg from 'web/hooks/useAnlegg';
import useFilter from 'web/hooks/useFilter';
import useProfile from 'web/hooks/useProfile';
import { useTenant } from 'web/hooks/useTenant';
import { SegmentCategory, useTracking } from 'web/hooks/useTracking';
import useAnleggsEksport from 'web/lib/exports/useAnleggseksport';
import { getAnleggFilterData } from 'web/lib/filter.utils';
import AnleggTemplate from 'web/templates/AnleggTemplate';

export default function AnleggPage() {
	const { trackPageView } = useTracking();
	trackPageView(SegmentCategory.Anlegg);
	const tenant = useTenant();
	const { open: openMeldInnAnleggModal } = useMeldInnAnleggModal();
	const [filter, setFilter] = useFilter();
	const filterData = getAnleggFilterData(filter, setFilter);
	const { eksporter } = useAnleggsEksport();
	const { anlegg } = useAnlegg({ ...filter }, true);
	const { anlegg: alleAnlegg } = useAnlegg({});
	const { kategorier } = useProfile();

	return (
		<AnleggTemplate
			sisteBesokte={[]}
			anlegg={anlegg}
			filterData={filterData}
			onClickExport={() => {
				eksporter({ anlegg, kategorier });
			}}
			onClickRegisterAnlegg={() => {
				openMeldInnAnleggModal({
					anlegg: alleAnlegg,
					tenant,
				});
			}}
			visOpphoerte={filter.visOpphoert}
		/>
	);
}
