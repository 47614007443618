import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'web/GlobalStyling';

const StyledLink = styled(Link)`
	text-decoration: none;
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
`;

const Wrapper = styled.div`
	width: 17.5rem;
	min-height: 125px;
	padding: 2.5rem;

	border: var(--nte-border);
	border-radius: var(--border-radius-large);
	box-shadow: var(--clickable-box-shadow-app);

	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	&:hover,
	&:active,
	&:focus {
		box-shadow: none;
	}
	@media ${device.mobile} {
		flex-direction: column;
	}
`;

const IconWrapper = styled.div`
	margin-top: 40px;
	margin-bottom: 40px;
`;

const Label = styled.div`
	font-size: 18px;
	color: var(--nte-blaa-uu);
	word-break: break-word;
`;

interface LargeBoxLinkProps {
	to: string;
	onClick?: () => void;
	label?: string;
	icon?: string;
}
export default function LargeBoxLink({ to, onClick, label, icon }: LargeBoxLinkProps) {
	return (
		<StyledLink to={to} onClick={onClick}>
			<Wrapper>
				{icon && <IconWrapper>{icon}</IconWrapper>}
				{label && <Label>{label}</Label>}
			</Wrapper>
		</StyledLink>
	);
}
