import React from 'react';
import styled from 'styled-components';
import Panel, { PanelProps } from 'web/components/atoms/Panel';

const Wrapper = styled(Panel)`
	position: relative;
	padding: 0;
	width: 100%;
	border: none;
	box-shadow: var(--clickable-box-shadow-app);
	border-radius: var(--border-radius-large);

	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	gap: 0;
`;
const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	font-weight: normal;
	font-size: 1.125rem;
	color: var(--nte-blaa);
`;
const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 0.5rem;
	flex-grow: 0;
	text-align: left;
	margin: 1.5rem 0 1rem 1rem;
`;
const Title = styled.div`
	font-weight: bold;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: var(--nte-blaa-mork);
`;
const SubTitle = styled.div`
	font-weight: normal;
	font-size: 0.75rem;
	line-height: 0.875rem;
	color: var(--nte-graa-medium);
`;
const ChildWrapper = styled.div`
	position: relative;
	flex-grow: 4;
	overflow: hidden;
	height: 7.875rem;
	border-radius: 0 var(--border-radius-large) var(--border-radius-large) 0;
`;
const FoggyOverlay = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 9;
	background: linear-gradient(90deg, white 8%, transparent 100%, transparent 100%);
`;
const GraphWrapper = styled.div`
	position: absolute;
	bottom: 0;
	right: -1rem;
	width: 100%;
`;

export interface WideValueInfoPanelProps extends PanelProps {
	value: string;
	subSubTitle?: string;
	unit?: string;
	description: string;
	icon: any;
}
export default function WideValueInfoPanel(props: WideValueInfoPanelProps) {
	return (
		<Wrapper
			style={props.style}
			className={props.className}
			onClick={props.onClick as any}
			to={props.to}
			href={props.href}
			openNewTab={props.openNewTab}
			{...props.rest}>
			<TextWrapper>
				<IconWrapper>{props.icon}</IconWrapper>
				<Title>{props.value}</Title>
				{(props.description || props.subSubTitle) && (
					<div>
						{props.description && <SubTitle>{props.description}</SubTitle>}
						{props.subSubTitle && <SubTitle>{props.subSubTitle}</SubTitle>}
					</div>
				)}
			</TextWrapper>
			{props.children && (
				<ChildWrapper>
					<FoggyOverlay />
					<GraphWrapper>{props.children}</GraphWrapper>
				</ChildWrapper>
			)}
		</Wrapper>
	);
}
