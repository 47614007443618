import React from 'react';
import styled from 'styled-components';

import FilterBarCheckBox from 'web/components/molecules/filter/FilterBarCheckBox';
import FilterBarSelect from 'web/components/molecules/filter/FilterBarSelect';
import FilterBarTextInput from 'web/components/molecules/filter/FilterBarTextInput';
import { FilterDataArray, FilterElements } from 'web/lib/filter.utils';

const Wrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	flex-wrap: wrap;
`;

export const SearchWrapper = styled.div`
	position: relative;
	align-self: flex-end;
	&::placeholder {
		color: var(--nte-graa-medium);
	}
	i {
		position: absolute;
		right: 12px;
		font-size: 1rem;
		line-height: 2;
		font-weight: normal;
		color: var(--nte-blaa);
	}
`;

const FilterBarElementsMap = {
	[FilterElements.SELECT]: FilterBarSelect,
	[FilterElements.CHECKBOX]: FilterBarCheckBox,
	[FilterElements.TEXTINPUT]: FilterBarTextInput,
	default: () => <span>Ukjent</span>,
} as any;

export interface FilterBarProps {
	filterData: FilterDataArray;
}
export default function FilterBar(props?: FilterBarProps) {
	return (
		<Wrapper>
			{props.filterData
				?.filter(d => !!d)

				.map((data, i) => {
					const Element = FilterBarElementsMap[data.type] || FilterBarElementsMap.default;
					return <Element key={i} {...data} />;
				})}
		</Wrapper>
	);
}
