import React, { ReactNode, useContext, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { SecondaryButton } from 'web/components/atoms/Button';
import Logo from 'web/components/atoms/svgs/Logo';
import { MenuItem, MenuItemProps } from 'web/components/molecules/MenuItem';
import { device } from 'web/GlobalStyling';
import useAuthHelpers from 'web/hooks/useAuthHelpers';

export const StyledLogo = styled(Logo)`
	@media ${device.tablet} {
		width: 50px;
	}
	width: 70px;
`;

const SidebarHeader = styled.div`
	display: flex;
	align-items: center;
	min-height: 4rem;
	padding: 2rem;
	@media ${device.tablet} {
		justify-content: center;
		padding: 1rem;
		margin-bottom: 0;
	}
`;

const MobileHeader = styled(SidebarHeader)`
	display: none;
	@media ${device.tablet} {
		display: flex;
	}
`;

export const LeftHeaderElement = styled.div`
	position: absolute;
	left: 1rem;
	display: none;
	@media ${device.tablet} {
		display: block;
	}
`;

const RightHeaderElement = styled.div`
	position: absolute;
	right: 1rem;
	display: none;
	@media ${device.tablet} {
		display: block;
	}
`;

const MainMenu = styled.aside`
	transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
	background: white;
	position: fixed;
	padding: 0;
	top: 0;
	bottom: 0;
	left: 0;
	border-right: 1px solid var(--nte-graa-lys);
	overflow-y: auto;
	overflow-x: hidden;
	width: var(--sidebar-width);
	@media ${device.tablet} {
		z-index: 99;
		border-right: initial;
		width: 100vw;
		background: var(--nte-blaa-himmel);
		box-shadow: 0px 0px 10px 0px var(--overlay-gray);
	}
`;

const MenuWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr minmax(max-content, 100%) 0fr;
	margin-bottom: 2.5rem;
	@media ${device.tablet} {
		grid-template-columns: 1fr max-content 1fr;
	}
`;

const StyledLogoutButton = styled(SecondaryButton)`
	grid-column: 2;
	margin: 1rem auto;
	width: fit-content;
	display: none;
	@media ${device.tablet} {
		display: inline-block;
	}
`;

const MenuItemsWrapper = styled.div`
	display: contents;
`;

const StyledDivider = styled.hr`
	grid-column: 2;
	@media ${device.tablet} {
		margin: 0.5rem;
	}
`;

export const ClickableHoveringButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border: none;
	outline: none;

	width: 2.5rem;
	height: 2.5rem;
	background: var(--white);

	box-shadow: var(--clickable-box-shadow-float);
	border-radius: 50%;

	font-size: 1.125rem;
	line-height: 1.125rem;

	color: var(--nte-blaa);

	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
`;

export const StyledHeaderBackButton = styled(ClickableHoveringButton)`
	color: var(--nte-blaa);
	background: none;
	border: none;
	box-shadow: none;
`;

const ContentWrapper = styled.div`
	padding: 0 2rem 8rem;
	@media ${device.mobile} {
		padding: 2rem 1rem 4rem;
	}
`;

interface WithBackground {
	background?: string;
	isAtHovedsiden?: boolean;
}
const StyledScrollView = styled.div<WithBackground>`
	background: ${props => (props.background ? props.background : 'white')};
	overflow: auto;
	position: fixed;
	padding: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: var(--sidebar-width);
	${props =>
		props.isAtHovedsiden &&
		css`
			background: var(--nte-blaa-himmel);
		`};
	@media ${device.tablet} {
		left: 0;
		box-shadow: 0px 0px 10px 0px var(--overlay-gray);
	}
`;

const Wrapper = styled.div`
	@media ${device.tablet} {
		${MainMenu} {
			transform: translateX(calc(100vw + 11px));
		}

		&.menu-open ${MainMenu} {
			pointer-events: initial;
			transform: translateX(0);
		}
	}
`;

interface DashboardLayoutProps {
	children: ReactNode;
	preMainMenuItems?: ReactNode;
	afterScroll?: ReactNode;
	mainMenuItems: MenuItemProps[];
	secondaryMenuItems: MenuItemProps[];
}

interface DashboadLayoutContextValue {
	scollElementRef?: React.MutableRefObject<any>;
}

const DashboardLayoutContext = React.createContext<DashboadLayoutContextValue>({});

export function useDashboardLayoutScrollElementRef() {
	return useContext(DashboardLayoutContext).scollElementRef;
}

export function DashboardLayout(props: DashboardLayoutProps & WithBackground) {
	const history = useHistory<any>();
	let { bedriftId } = useParams() as any; // TODO: we use both useTenant and useParams here. Maybe just use one?
	const { logout } = useAuthHelpers();

	const isAtHovedsiden = history.location.pathname.includes('/hovedsiden');

	const closeButtonRef = useRef(null);
	const openButtonRef = useRef(null);
	const scollElementRef = useRef(null);

	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	if (isMenuOpen) {
		// push to history when modal opens
		window.history.pushState(null, '', window.location.href);

		// close modal on 'back'
		window.onpopstate = () => {
			window.onpopstate = () => {};
			window.history.back();
			setIsMenuOpen(false);
		};
	}

	return (
		<Wrapper className={isMenuOpen && 'menu-open'}>
			<MainMenu>
				<SidebarHeader>
					{(bedriftId && (
						<Link to={`/${bedriftId}/hovedsiden/`}>
							<StyledLogo />
						</Link>
					)) || <StyledLogo />}
					<RightHeaderElement>
						<ClickableHoveringButton
							ref={closeButtonRef}
							onClick={() => {
								openButtonRef.current.focus();

								if (isMenuOpen) {
									setIsMenuOpen(false);
								}
							}}>
							<i className="far fa-times" />
						</ClickableHoveringButton>
					</RightHeaderElement>
				</SidebarHeader>
				{props.preMainMenuItems}
				<MenuWrapper>
					<MenuItemsWrapper onClick={() => setIsMenuOpen(false)}>
						{props.mainMenuItems.map((item, i) => (
							<MenuItem {...item} key={i} />
						))}
					</MenuItemsWrapper>

					{!!props.secondaryMenuItems?.length && <StyledDivider />}

					<MenuItemsWrapper onClick={() => setIsMenuOpen(false)}>
						{props.secondaryMenuItems.map((item, i) => (
							<MenuItem {...item} key={i} />
						))}

						<StyledLogoutButton
							onClick={() => {
								logout();
							}}
							type="button">
							Logg ut
						</StyledLogoutButton>
					</MenuItemsWrapper>
				</MenuWrapper>
			</MainMenu>
			<StyledScrollView background={props.background} isAtHovedsiden={isAtHovedsiden} ref={scollElementRef}>
				<MobileHeader>
					{!isAtHovedsiden && (
						<LeftHeaderElement>
							<StyledHeaderBackButton
								onClick={() => {
									history.goBack();
								}}>
								<i className="far fa-arrow-left" />
							</StyledHeaderBackButton>
						</LeftHeaderElement>
					)}
					{(bedriftId && (
						<Link to={`/${bedriftId}/hovedsiden/`}>
							<StyledLogo />
						</Link>
					)) || <StyledLogo />}
					<RightHeaderElement>
						<ClickableHoveringButton
							ref={openButtonRef}
							onClick={() => {
								closeButtonRef.current.focus();

								if (!isMenuOpen) {
									setIsMenuOpen(true);
								}
							}}>
							<i className="far fa-bars" />
						</ClickableHoveringButton>
					</RightHeaderElement>
				</MobileHeader>
				<ContentWrapper>
					<DashboardLayoutContext.Provider value={{ scollElementRef }}>{props.children}</DashboardLayoutContext.Provider>
				</ContentWrapper>
			</StyledScrollView>
			{props.afterScroll}
		</Wrapper>
	);
}
