import React from 'react';
import styled, { css } from 'styled-components';
import { SelectElementProps } from 'web/components/molecules/filter/FilterDrawerSelect';
import { SelectItem } from 'web/lib/filter.utils';

const StyledWrapper = styled.div`
	background: inherit;
`;

interface SelectElementWrapperProps {
	selected?: boolean;
}
const SelectElementWrapper = styled.button<SelectElementWrapperProps>`
	width: 100%;
	border: none;
	padding: 0 0.875rem;
	display: flex;
	cursor: pointer;
	height: 2.25rem;
	line-height: 2.25rem;
	border-radius: 2.25rem;
	flex-grow: 1;
	justify-content: center;
	color: var(--nte-sort);
	background: var(--nte-graa-lys);
	${props =>
		props.selected &&
		css`
			color: var(--white);
			background: var(--nte-blaa-uu);
		`};
`;
const ContentWrapper = styled.div`
	width: 100%;
	height: 2.25rem;
	border-radius: 2.25rem;
	display: flex;
	background: var(--nte-graa-lys);
	gap: 0.1rem;
`;

function SelectElement(props: SelectElementProps) {
	return (
		<SelectElementWrapper selected={props.selected} onClick={() => props.onClick?.(props.item)} style={props.style}>
			{props.item.label}
		</SelectElementWrapper>
	);
}

export interface BubbleTabSelectProps {
	options: SelectItem[];
	selected: string;
	onChange: (selected: string) => void;
	style?: React.CSSProperties;
}
export default function BubbleTabSelect(props: BubbleTabSelectProps) {
	let isSelected = (item: SelectItem) => {
		return item.value === props.selected;
	};

	return (
		<StyledWrapper style={props.style}>
			<ContentWrapper>
				{props.options?.map(item => (
					<SelectElement
						key={item.value}
						selected={isSelected(item)}
						item={item}
						onClick={() => {
							if (!props.onChange) return;

							return props.onChange(item.value);
						}}
					/>
				))}
			</ContentWrapper>
		</StyledWrapper>
	);
}
