// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LogFrontendMutationVariables = Types.Exact<{
  message: Types.MessageInput;
}>;


export type LogFrontendMutation = { logFrontend?: string };


export const LogFrontendDocument = gql`
    mutation logFrontend($message: MessageInput!) {
  logFrontend(message: $message)
}
    `;
export type LogFrontendMutationFn = Apollo.MutationFunction<LogFrontendMutation, LogFrontendMutationVariables>;

/**
 * __useLogFrontendMutation__
 *
 * To run a mutation, you first call `useLogFrontendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogFrontendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logFrontendMutation, { data, loading, error }] = useLogFrontendMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useLogFrontendMutation(baseOptions?: Apollo.MutationHookOptions<LogFrontendMutation, LogFrontendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogFrontendMutation, LogFrontendMutationVariables>(LogFrontendDocument, options);
      }
export type LogFrontendMutationHookResult = ReturnType<typeof useLogFrontendMutation>;
export type LogFrontendMutationResult = Apollo.MutationResult<LogFrontendMutation>;
export type LogFrontendMutationOptions = Apollo.BaseMutationOptions<LogFrontendMutation, LogFrontendMutationVariables>;