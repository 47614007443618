import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Button from 'web/components/atoms/Button';
import Paragraph from 'web/components/atoms/Paragraph';
import Push from 'web/components/atoms/Push';
import BedriftMultiSelect from 'web/components/molecules/form/BedriftMultiSelect';
import FileField from 'web/components/molecules/form/FileField';
import TextAreaField from 'web/components/molecules/form/TextAreaField';
import TextField from 'web/components/molecules/form/TextField';
import Modal from 'web/components/molecules/Modal';
import Form from 'web/hooks/useForm';
import { MeldingElement } from 'web/templates/admin/MeldingerTemplate';

export const ModalWrapper = styled.div`
	box-sizing: border-box;
	padding: 0 10px;
	overflow: hidden;
	margin: auto;
`;

export const StyledHeader = styled.div`
	font-weight: 500;
	font-size: 1.0625rem;
	margin-bottom: 10px;
`;

const MODAL_NAME = 'NyMeldingModal';

interface NyMeldingModalProps {
	backUrl: string;
	slaaoppBedrift: (orgnummer: string) => Promise<{ navn: string; kilde: string }>;
	lagreMelding: (navn: string, melding: string, orgnummer: string[], fil: File) => Promise<MeldingElement>;
}
export function NyMeldingModal(props: NyMeldingModalProps) {
	const scrollWindowElement = useRef(null);
	const { backUrl, lagreMelding, slaaoppBedrift } = props;
	const [formError, setFormError] = useState({});
	const [fileName, setFileName] = useState('');
	const history = useHistory();
	return (
		<Modal
			title="Send melding til bedrifter"
			modalRef={scrollWindowElement}
			onClose={() => history.push(backUrl)}
			style={{ maxWidth: 'calc(100vw - 60px)', maxHeight: 'calc(100vh - 60px)', boxSizing: 'border-box' }}>
			<ModalWrapper>
				<Paragraph>Her kan du laste opp vedlegg og sende dem ut til bedrifter</Paragraph>

				<Push height="16px" />

				<Form
					errors={formError}
					// @ts-ignore
					onSubmit={async (values, e) => {
						let newErrors = {};
						let hasErrors = false;

						if (!e.target.vedlegg.files[0]) {
							// @ts-ignore
							newErrors.vedlegg = 'Mangler vedlegg';
							hasErrors = true;
						}

						if (!values.orgNummer || values.orgNummer?.length === 0) {
							// @ts-ignore
							newErrors.orgNummer = 'Mangler mottakere';
							hasErrors = true;
							// @ts-ignore
						} else if (values.orgNummer.filter(orgNummer => !!orgNummer.error)?.length > 0) {
							// @ts-ignore
							newErrors.orgNummer = 'Inneholder organisasjonsnummer som ikke ble funnet, eller som har feil';
							hasErrors = true;
						}

						setFormError(newErrors);

						if (hasErrors) {
							return;
						}

						try {
							await lagreMelding(values.navn, values.melding, values.orgNummer, e.target.vedlegg.files[0]);
							toast('Melding sendt', {
								position: toast.POSITION.BOTTOM_CENTER,
							});
							history.push(backUrl);
						} catch (e) {
							console.error(e);
							toast('Et problem oppstod', {
								position: toast.POSITION.BOTTOM_CENTER,
							});
						}
					}}>
					{/* @ts-ignore*/}
					<FileField required name="vedlegg" label={<b>Vedlegg</b>} error={formError.vedlegg} setFileName={setFileName} />

					<StyledHeader>Hva skal tittelen være?</StyledHeader>
					<TextField
						required
						name="navn"
						label={'Tittel'}
						defaultValue={fileName.replace(/\.[^.]+$/, '').replace('_', ' ') || ''}
						placeholder={'Porteføljerapport_Bedriften_Måned_2021'}
						// @ts-ignore
						error={formError.navn}
					/>

					<StyledHeader>Hva skal meldingsteksten være?</StyledHeader>
					<TextAreaField
						name="melding"
						label={'Meldingstekst'}
						placeholder={'Denne meldingen inneholder porteføljerapport for april 2021'}
						style={{ minHeight: '88px' }}
					/>

					<StyledHeader>Hvem skal motta meldingen?</StyledHeader>
					<BedriftMultiSelect
						required={true}
						name="orgNummer"
						label="Organisasjonsnummer separert med mellomrom eller komma"
						placeholder={'895895658 985654785, 84568745'}
						slaaoppBedrift={slaaoppBedrift}
						// @ts-ignore
						error={formError.orgNr}
					/>

					<Button>Send melding</Button>
				</Form>
			</ModalWrapper>
		</Modal>
	);
}

export default {
	component: NyMeldingModal,
	name: MODAL_NAME,
};
