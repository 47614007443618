// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AvslaaInvitasjonMutationVariables = Types.Exact<{
  avslaa: Types.AvslaaInvitasjonInput;
}>;


export type AvslaaInvitasjonMutation = { invitasjon?: { invitasjonId: string } };


export const AvslaaInvitasjonDocument = gql`
    mutation avslaaInvitasjon($avslaa: AvslaaInvitasjonInput!) {
  invitasjon: avslaaInvitasjon(avslaa: $avslaa) {
    invitasjonId
  }
}
    `;
export type AvslaaInvitasjonMutationFn = Apollo.MutationFunction<AvslaaInvitasjonMutation, AvslaaInvitasjonMutationVariables>;

/**
 * __useAvslaaInvitasjonMutation__
 *
 * To run a mutation, you first call `useAvslaaInvitasjonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvslaaInvitasjonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [avslaaInvitasjonMutation, { data, loading, error }] = useAvslaaInvitasjonMutation({
 *   variables: {
 *      avslaa: // value for 'avslaa'
 *   },
 * });
 */
export function useAvslaaInvitasjonMutation(baseOptions?: Apollo.MutationHookOptions<AvslaaInvitasjonMutation, AvslaaInvitasjonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AvslaaInvitasjonMutation, AvslaaInvitasjonMutationVariables>(AvslaaInvitasjonDocument, options);
      }
export type AvslaaInvitasjonMutationHookResult = ReturnType<typeof useAvslaaInvitasjonMutation>;
export type AvslaaInvitasjonMutationResult = Apollo.MutationResult<AvslaaInvitasjonMutation>;
export type AvslaaInvitasjonMutationOptions = Apollo.BaseMutationOptions<AvslaaInvitasjonMutation, AvslaaInvitasjonMutationVariables>;