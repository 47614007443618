import React from 'react';
import FormLabel from 'web/components/molecules/form/FormLabel';
import { StyledSelect } from 'web/components/molecules/form/SelectField';
import { useForm, useFormError, useFormValue } from 'web/hooks/useForm';

interface TariffVelgerProps {
	name: string;
	tariffer: any[];
	required?: boolean;
	error?: boolean;
}
export function TariffVelger({ name, tariffer, required, error, ...props }: TariffVelgerProps) {
	const form = useForm(name);
	const formValue = useFormValue(name);
	const formError = useFormError(name);

	const selected = tariffer.find(tariff => formValue === tariff.tariffId);
	return (
		<FormLabel label="Tariff" error={error || formError} required={required}>
			<StyledSelect title={name} name={name} value={selected} disabled={form?.disabled} {...props}>
				{tariffer?.map((tariff, i) => (
					<option value={tariff.tariffId} key={i}>
						{tariff.beskrivelse}
					</option>
				))}
			</StyledSelect>
		</FormLabel>
	);
}
