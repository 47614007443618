import * as d3 from 'd3';
import { addHours, format, isSameHour, isThisHour, startOfDay } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import { NordpoolArea as Energisone, Spotpristime, Varslingskriterie } from 'generated/graphql-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useNumbers from 'web/hooks/useNumbers';
import { useHentSpotprisQuery, useHentVarslerQuery, useLagreVarslingMutation, useSlettVarslingMutation } from 'web/hooks/useSpotpris.graphql-gen';
import { getHourRangeString } from 'web/lib/dates.utils';
import { roundUpByN } from 'web/lib/numbers.utils';
import { Varsel } from 'web/templates/SpotprisTemplate';

export { Energisone };

export interface AktivtVarsel extends Varslingskriterie {
	timeList: Date[];
}

interface useSpotprisProps {
	orgId: string;
	from: Date;
	to: Date;
	energisone: Energisone;

	roundUpToClosestNumber?: number;
	doLimitYTick?: boolean;
	hideVarslerLines?: boolean;
	legendOnBottom?: boolean;
}
export default function useSpotpris({ orgId, from, to, energisone, roundUpToClosestNumber, doLimitYTick, hideVarslerLines, legendOnBottom }: useSpotprisProps) {
	const n = useNumbers();
	const { t } = useTranslation('forbruk');

	const chartRef = useRef(null);
	const [spotpriser, setSpotpriser] = useState([]);
	const [currentSpotpris, setCurrentSpotpris] = useState(null);
	const [isMissingData, setIsMissingData] = useState(false);
	const [tileData, setTileData] = useState({ max: '', maxTime: null, min: '', minTime: null, average: '' });

	const [varsler, setVarsler] = useState<Varsel[]>([]); // Varsler for this zone
	const [alleVarsler, setAlleVarsler] = useState<Varsel[]>([]);
	const [aktiveVarsler, setAktiveVarsler] = useState<AktivtVarsel[]>([]);

	const [lagreVarsel, { error: errorLagreVarsel, loading: loadingLagreVarsel }] = useLagreVarslingMutation();
	const [slettVarsel, { error: errorSlettVarsel, loading: loadingSlettVarsel }] = useSlettVarslingMutation();

	const { error: errorVarsler, loading: loadingVarsler } = useHentVarslerQuery({
		fetchPolicy: 'no-cache',
		variables: {
			orgId: orgId,
		},
		onCompleted: data => {
			setAlleVarsler(data.varslingerForBrukerIOrganisasjon || []);
		},
	});

	useEffect(() => {
		const varslerForValgtSone = alleVarsler?.filter((v: Varsel) => v.energisone === energisone);
		setVarsler(varslerForValgtSone || []);
		chartRef?.current?.ygrids(mapVarslerTilGridLinjer(varslerForValgtSone));
	}, [energisone, alleVarsler]);

	useEffect(() => {
		convertDataToAktiveVarsler(varsler, spotpriser);
	}, [varsler, spotpriser]);

	function mapVarslerTilGridLinjer(varsler: Varsel[]) {
		if (!hideVarslerLines) {
			return varsler?.map(varsel => ({
				text: '',
				value: varsel.varslingskriterie.verdi,
				icon: '',
				tooltip: `Spotpris er ${t('Spotpris.operator.' + varsel.varslingskriterie.operator)} ${varsel.varslingskriterie.verdi} øre`,
				class: 'coral',
			}));
		} else {
			return [];
		}
	}

	function saveVarsel(varsel: Varsel) {
		return lagreVarsel({
			variables: {
				varsling: {
					id: varsel.id,
					orgId: varsel.orgId,
					beskrivelse: varsel.beskrivelse,
					varslingskriterie: {
						operator: varsel.varslingskriterie.operator,
						verdi: varsel.varslingskriterie.verdi,
					},
					epost: varsel.epost,
					energisone: varsel.energisone,
				},
			},
		})
			.then(response => {
				const lagreVarsling = response.data.lagreVarsling;
				const updated =
					varsel.id === undefined
						? [...alleVarsler, lagreVarsling] // ny
						: alleVarsler?.map(v => (v.id === varsel.id ? lagreVarsling : v)); // oppdatert
				setAlleVarsler(updated);
				const varslerForValgtSone = updated.filter(varsel => varsel.energisone === energisone);
				chartRef.current?.ygrids(mapVarslerTilGridLinjer(varslerForValgtSone));
			})
			.catch(error => {
				console.error(error);
			});
	}

	function deleteVarsel(varsel: Varsel) {
		return slettVarsel({
			variables: {
				id: varsel.id,
			},
		})
			.then(response => {
				const id = response.data.slettVarsling.id;
				const updated = alleVarsler.filter(v => v.id !== id);
				setAlleVarsler(updated);
				const varslerForValgtSone = updated.filter(varsel => varsel.energisone === energisone);
				chartRef.current?.ygrids(mapVarslerTilGridLinjer(varslerForValgtSone));
			})
			.catch(error => {
				console.error(error);
			});
	}

	const { loading: loadingSpotpris, error: errorSpotpris } = useHentSpotprisQuery({
		variables: {
			from: from,
			to: to,
			energisone: energisone,
		},
		skip: !energisone,
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			setSpotpriser(data.spotpris);
			convertDataToGraphData(data.spotpris || []);

			const convertedTileData = convertDataToTileData(data.spotpris || []);
			setTileData(convertedTileData.tileData);

			const currentSpotpris = getCurrentSpotprisData(data.spotpris || []);
			setCurrentSpotpris(currentSpotpris.value);

			if (convertedTileData.maxSpotpris) {
				if (roundUpToClosestNumber) {
					const yMaxValue = roundUpByN(convertedTileData.maxSpotpris, roundUpToClosestNumber);
					chartRef.current?.axis.max(yMaxValue);
				} else {
					chartRef.current?.axis.max(roundUpByN(convertedTileData.maxSpotpris * 2, 10));
				}
			}
			setIsMissingData((data.spotpris?.length ?? 0) === 0);
		},
		onError: error => {
			console.error(error);

			setIsMissingData(true);
			chartRef.current?.load({
				unload: true,
			});
		},
	});

	function getCurrentSpotprisData(spotpris: Spotpristime[]) {
		let currentValue = { value: 0, time: new Date() };
		spotpris.map(entry => {
			const time = new Date(entry.fra);
			if (isSameHour(time, new Date())) {
				currentValue = { value: entry.pris, time: time };
			}
		});
		return currentValue;
	}

	function convertDataToGraphData(spotpris: Spotpristime[]) {
		if (chartRef.current) {
			const time: Date[] = [];
			const spotprisValues: number[] = [];
			const spotprisNow: number[] = [];

			if (spotpris?.length) {
				spotpris.forEach(entry => {
					const price = entry.pris || null;
					const x = new Date(entry.fra) || null;
					spotprisValues.push(price);
					time.push(x);

					if (isSameHour(x, new Date())) {
						spotprisNow.push(price);
					} else {
						spotprisNow.push(null);
					}
				});
			} else {
				spotprisValues.push(null);
				spotprisNow.push(null);
				time.push(null);
			}

			//Adding extra hour so graph displays last hour

			const lastHour = time[time?.length - 1];
			time.push(lastHour ? addHours(lastHour, 1) : null);
			spotprisValues.push(spotprisValues[spotprisValues?.length - 1]);

			if (varsler?.length > 0 && spotpris?.length > 0) {
				chartRef.current?.ygrids(mapVarslerTilGridLinjer(varsler));
			} else {
				chartRef.current?.ygrids([]);
			}

			chartRef.current?.load({
				unload: true,
				columns: [
					['x', ...time],
					['Spotpris', ...spotprisValues],
					['Denne_timen', ...spotprisNow],
				],
			});
		}
	}

	async function convertDataToAktiveVarsler(varsler: Varsel[], spotpris: Spotpristime[]) {
		if (varsler?.length > 0 && spotpriser?.length > 0) {
			let newAktiveVarsler: AktivtVarsel[] = varsler.map(v => {
				return { ...v.varslingskriterie, timeList: [] };
			});

			spotpris?.forEach(spotprisEntry => {
				const price = spotprisEntry.pris;
				const fromTime = spotprisEntry.fra;

				newAktiveVarsler.map(varsel => {
					switch (varsel.operator) {
						case 'GT':
							if (price >= varsel.verdi) {
								varsel.timeList.push(new Date(fromTime));
							}
							break;
						case 'LT':
							if (price <= varsel.verdi) {
								varsel.timeList.push(new Date(fromTime));
							}
							break;
						default:
							break;
					}
				});
			});
			setAktiveVarsler(newAktiveVarsler);
		} else {
			setAktiveVarsler([]);
		}
	}

	function convertDataToTileData(spotpris: Spotpristime[]) {
		let max = 0;
		let min = spotpris[0]?.pris || null;
		let sum = 0;
		let maxTime: Date = null;
		let minTime = spotpris?.[0]?.fra ? new Date(spotpris?.[0]?.fra) : undefined;
		spotpris.map(entry => {
			const price = entry.pris;
			const time = new Date(entry.fra);

			sum += price;
			if (price > max) {
				max = price;
				maxTime = time;
			}
			if (min === null || price < min) {
				min = price;
				minTime = time;
			}
		});

		return {
			tileData: {
				max: max !== 0 ? n(max) : '--',
				maxTime: maxTime,
				min: min !== null ? n(min) : '--',
				minTime: minTime,
				average: sum !== 0 ? n(sum / spotpris?.length) : '--',
			},
			maxSpotpris: max !== 0 ? max : undefined,
			currentSpotpris: getCurrentSpotprisData(spotpris),
		};
	}

	const secondaryValueInfoPanels = (tileData: any) => [
		{
			value: tileData.max,
			unit: 'øre',
			description: tileData.maxTime ? `høyeste pris kl. ${getHourRangeString(tileData.maxTime)}` : 'høyeste pris',
			icon: (
				<i>
					<svg width="100%" height="100%" viewBox="0 0 512 384" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M48 336H496C504.84 336 512 343.16 512 352V368C512 376.84 504.84 384 496 384H32C14.33 384 0 369.67 0 352V16C0 7.16 7.16 0 16 0H32C40.84 0 48 7.16 48 16V336Z"
							fill="currentColor"
						/>
						<path
							d="M142.627 288.627L249.981 181.274C256.752 184.927 264.502 187 272.735 187C280.969 187 288.718 184.927 295.49 181.274L402.843 288.627C409.091 294.876 419.222 294.876 425.47 288.627L436.784 277.314C443.032 271.065 443.032 260.935 436.784 254.686L320.734 138.636C320.538 112.294 299.123 91 272.735 91C246.347 91 224.932 112.294 224.736 138.636L108.686 254.686C102.438 260.935 102.438 271.065 108.686 277.314L120 288.627C126.248 294.876 136.379 294.876 142.627 288.627Z"
							fill="currentColor"
						/>
					</svg>
				</i>
			),
		},
		{
			value: tileData.min,
			unit: 'øre',
			description: tileData.minTime ? `laveste pris kl. ${getHourRangeString(tileData.minTime)}` : 'laveste pris',
			icon: (
				<i>
					<svg width="100%" height="100%" viewBox="0 0 512 384" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M48 336H496C504.84 336 512 343.16 512 352V368C512 376.84 504.84 384 496 384H32C14.33 384 0 369.67 0 352V16C0 7.16 7.16 0 16 0H32C40.84 0 48 7.16 48 16V336Z"
							fill="currentColor"
						/>
						<path
							d="M402.843 95.6863L295.49 203.039C288.718 199.387 280.969 197.314 272.735 197.314C264.502 197.314 256.752 199.387 249.981 203.039L142.627 95.6863C136.379 89.4379 126.248 89.4379 120 95.6863L108.686 107C102.438 113.248 102.438 123.379 108.686 129.627L224.737 245.678C224.932 272.02 246.347 293.314 272.735 293.314C299.124 293.314 320.538 272.02 320.734 245.677L436.784 129.627C443.032 123.379 443.032 113.248 436.784 107L425.47 95.6863C419.222 89.4379 409.091 89.4379 402.843 95.6863Z"
							fill="currentColor"
						/>
					</svg>
				</i>
			),
		},
		{
			value: tileData.average,
			unit: 'øre',
			description: 'gjennomsnittlig pris',
			icon: <i className="far fa-heart-rate" />,
		},
	];

	function formatXTick(x: any) {
		return format(new Date(x), 'HH:mm', { locale: nbLocale });
	}
	const loading = !energisone || loadingSpotpris || loadingVarsler || loadingLagreVarsel || loadingSlettVarsel;
	const error = errorSpotpris || errorVarsler || errorLagreVarsel || errorSlettVarsel;
	const missingDataText =
		startOfDay(new Date()) < startOfDay(to) ? 'Spotprisdata for i morgen blir tilgjengelig rundt klokken 14:00' : 'Ingen data å vise for valgt dag';
	const missingDataElement = (
		<span style={{ margin: '0 4rem' }}>{!errorSpotpris ? missingDataText : 'Beklager, det oppstod en feil ved innhenting av spotprisdata'}</span>
	);

	return {
		varsler,
		aktiveVarsler,
		numOfAktiveVarsler: aktiveVarsler.filter(v => {
			return v.timeList?.length;
		})?.length,
		saveVarsel,
		deleteVarsel,
		currentSpotpris,
		chartRef,
		loading,
		error,
		missingDataElement: missingDataElement,
		isMissingData,
		secondaryInfoPanels: secondaryValueInfoPanels(tileData),
		chartData: {
			data: {
				x: 'x',
				columns: [['x'], ['Spotpris'], ['Denne_timen']],
				types: {
					Spotpris: 'area-step',
					Denne_timen: 'bar',
				},
				colors: {
					Spotpris: 'var(--nte-blaa)',
					Denne_timen: 'var(--nte-blaa)',
				},
				names: {
					Spotpris: 'Spotpris',
					Denne_timen: 'Denne timen',
				},
			},
			line: {
				step: {
					type: 'step-after',
				},
			},
			legend: {
				position: legendOnBottom ? 'bottom' : 'inset',
				inset: {
					anchor: 'top-right',
					x: 20,
					y: -50,
					step: 2,
				},
				item: {
					onclick: () => {},
				},
			},
			bar: {
				width: {
					ratio: 1,
				},
			},
			axis: {
				x: {
					type: 'timeseries',
					tick: {
						format: formatXTick,
					},
					padding: {
						left: 0,
					},
				},
				y: {
					label: 'øre',
					padding: { top: 0 },

					tick: {
						count: doLimitYTick ? 5 : 0,
						format: (d: number) => {
							return d.toFixed();
						},
					},
				},
			},
			tooltip: {
				format: {
					title: function (date: Date) {
						return `kl. ${getHourRangeString(date)}${isThisHour(date) ? ', denne timen' : ''}
						`;
					},
					value: (value: any, ratio: any, id: string) => {
						if (id === 'Spotpris') {
							return `${n(value, true)} øre`;
						}
					},
				},
			},
			grid: {
				x: {
					show: true,
				},
				y: {
					show: true,
				},
			},
			padding: {
				top: 50,
			},
			onrendered: function () {
				// Move current hour bar to the right, to align it with step-after
				var thisChart = d3.select(this.config.bindto);
				var barWidth = thisChart.select('.c3-bar-0').node() ? (thisChart.select('.c3-bar-0').node() as any).getBoundingClientRect().width / 2 : 0;
				thisChart.selectAll('.c3-chart-bar, .c3-event-rect').style('transform', 'translate(' + barWidth + 'px, 0px)');

				// Fix legent item tile color for Spotpris (make it a lighter color)
				d3.selectAll('.c3-legend-item-Spotpris').select('.c3-legend-item-tile').style('stroke', 'var(--nte-blaa-20)');
				d3.selectAll('.c3-legend-item').style('pointer-events', 'none');

				if (!hideVarslerLines) {
					// Add varsler lines
					let yg = d3.selectAll('.c3-ygrid-lines text');
					yg.each(function (d: any, i: number) {
						let tspan = d3.select(this).text(' ').select('tspan');
						if (tspan.empty()) {
							tspan = d3.select(this).append('tspan');
							tspan.attr('class', 'fa');
						}
						tspan.text(d.icon);

						let title: any = d3.select(this).select('title');
						if (title.empty()) {
							title = yg.append('title');
						}
						title.text(function (d: any) {
							return d.tooltip;
						});
					});

					// Move varsler icon to right of graph
					d3.selectAll('.c3-ygrid-line text').style('transform', 'translate(4px, 12px)');
				}
			},
		} as any,
	};
}
