import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from 'web/components/atoms/Button';
import Paragraph from 'web/components/atoms/Paragraph';
import Push from 'web/components/atoms/Push';
import BedriftMultiSelect from 'web/components/molecules/form/BedriftMultiSelect';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import Modal from 'web/components/molecules/Modal';
import { ModalWrapper, StyledHeader } from 'web/components/organisms/modals/NyMeldingModal';
import Form from 'web/hooks/useForm';
import { Bedrift, MeldingElement } from 'web/templates/admin/MeldingerTemplate';

const MODAL_NAME = 'EditMeldingReceiversModal';

interface EditMeldingReceiversModalProps {
	backUrl: string;
	melding: MeldingElement;
	loading?: boolean;
	updateCompaniesForMelding: (companies: Bedrift[], meldingId: string) => void;
	slaaoppBedrift: (orgnummer: string) => Promise<{ navn?: string; kilde?: string }>;
}

export function EditMeldingReceiversModal({ backUrl, melding, loading, updateCompaniesForMelding, slaaoppBedrift }: EditMeldingReceiversModalProps) {
	const scrollWindowElement = useRef(null);
	const history = useHistory();
	const [formError, setFormError] = useState({});

	return (
		<Modal
			title="Rediger mottakere"
			modalRef={scrollWindowElement}
			onClose={() => history.push(backUrl)}
			style={{ maxWidth: 'calc(100vw - 60px)', maxHeight: 'calc(100vh - 60px)', boxSizing: 'border-box' }}>
			<ModalWrapper>
				<Paragraph>
					Her kan du legge til eller fjerne bedrifter som skal ha meldingen i sin innboks. Når du fjerner en mottaker fra lista vil meldingen fjernes
					fra innboksen til mottakeren.
				</Paragraph>

				<Push height="1rem" />

				<LoadingOverlay loading={loading} text="Laster inn mottakere" />

				<Form
					errors={formError}
					values={melding}
					key={melding?.id}
					onSubmit={async (values: MeldingElement, e: Event) => {
						let newErrors = {};
						let hasErrors = false;

						// @ts-ignore
						if (values.companies.filter(company => !!company.error)?.length > 0) {
							// @ts-ignore
							newErrors.companies = 'Inneholder organisasjonsnummer som ikke ble funnet, eller som har feil';
							hasErrors = true;
						}

						setFormError(newErrors);

						if (hasErrors) {
							return;
						}

						const newCompaniesArray =
							values.companies?.map(company => ({
								kundeNavn: company.kundeNavn,
								orgNr: company.orgNr,
							})) ?? [];

						await updateCompaniesForMelding(newCompaniesArray, melding?.id);

						toast('Melding oppdatert', {
							position: toast.POSITION.BOTTOM_CENTER,
						});

						setTimeout(() => {
							history.push(backUrl);
						}, 1);
					}}>
					<StyledHeader>Hvem skal motta meldingen?</StyledHeader>
					<BedriftMultiSelect
						required={true}
						name="companies"
						label="Organisasjonsnummer separert med mellomrom eller komma"
						placeholder={'895895658 985654785, 84568745'}
						// @ts-ignore

						error={formError.companies}
						slaaoppBedrift={slaaoppBedrift}
					/>

					<Button>Oppdater mottakere</Button>
				</Form>
			</ModalWrapper>
		</Modal>
	);
}

export default {
	component: EditMeldingReceiversModal,
	name: MODAL_NAME,
};
