// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentForbrukQueryVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
  fraDato?: Types.InputMaybe<Types.Scalars['Date']>;
  tilDato?: Types.InputMaybe<Types.Scalars['Date']>;
  maalere?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  opploesning?: Types.InputMaybe<Types.Opploesning>;
  retning?: Types.InputMaybe<Types.Retning>;
  fakturamerker?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  naeringskoder?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type HentForbrukQuery = { bedrift?: { id?: string, forbruksdetaljer?: { opploesning?: Types.Opploesning, retning?: Types.Retning, verdier?: Array<{ mengde?: number, importert?: number, eksportert?: number, fraTid?: string, tilTid?: string }> } } };


export const HentForbrukDocument = gql`
    query hentForbruk($orgid: ID!, $fraDato: Date, $tilDato: Date, $maalere: [String], $opploesning: Opploesning, $retning: Retning, $fakturamerker: [String], $naeringskoder: [String]) {
  bedrift: hentBedrift(orgid: $orgid) {
    id
    forbruksdetaljer(
      fraDato: $fraDato
      tilDato: $tilDato
      maalere: $maalere
      opploesning: $opploesning
      retning: $retning
      fakturamerker: $fakturamerker
      naeringskoder: $naeringskoder
    ) {
      opploesning
      retning
      verdier {
        mengde
        importert
        eksportert
        fraTid
        tilTid
      }
    }
  }
}
    `;

/**
 * __useHentForbrukQuery__
 *
 * To run a query within a React component, call `useHentForbrukQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentForbrukQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentForbrukQuery({
 *   variables: {
 *      orgid: // value for 'orgid'
 *      fraDato: // value for 'fraDato'
 *      tilDato: // value for 'tilDato'
 *      maalere: // value for 'maalere'
 *      opploesning: // value for 'opploesning'
 *      retning: // value for 'retning'
 *      fakturamerker: // value for 'fakturamerker'
 *      naeringskoder: // value for 'naeringskoder'
 *   },
 * });
 */
export function useHentForbrukQuery(baseOptions: Apollo.QueryHookOptions<HentForbrukQuery, HentForbrukQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentForbrukQuery, HentForbrukQueryVariables>(HentForbrukDocument, options);
      }
export function useHentForbrukLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentForbrukQuery, HentForbrukQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentForbrukQuery, HentForbrukQueryVariables>(HentForbrukDocument, options);
        }
export type HentForbrukQueryHookResult = ReturnType<typeof useHentForbrukQuery>;
export type HentForbrukLazyQueryHookResult = ReturnType<typeof useHentForbrukLazyQuery>;
export type HentForbrukQueryResult = Apollo.QueryResult<HentForbrukQuery, HentForbrukQueryVariables>;