import { default as React, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button, { ButtonWrapper } from 'web/components/atoms/Button';
import Push from 'web/components/atoms/Push';
import InputField from 'web/components/molecules/form/InputField';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import MobileTextarea from 'web/components/molecules/MobileTextarea';
import { env } from 'web/env';
import { device } from 'web/GlobalStyling';
import useAuthHelpers from 'web/hooks/useAuthHelpers';
import { useTracking } from 'web/hooks/useTracking';

const Wrapper = styled.div`
	position: fixed;
	bottom: 32px;
	right: 32px;
	z-index: 9999;
`;

const CloseOverlay = styled.div`
	position: fixed;
	z-index: -1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	@media ${device.mobile} {
		display: none;
	}
`;

const DesktopFeedbackButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media ${device.tablet} {
		display: none;
	}
`;

const FeedbackButtonLink = styled(Link)`
	border-radius: 50%;
	width: 55px;
	height: 55px;
	font-size: 24px;
	line-height: 55px;
	text-align: center;
	padding: 0;
	color: var(--white);
	background: var(--nte-blaa);
	border: none;
	margin-bottom: 12px;
`;

const FeedbackLabel = styled.div`
	clear: both;
	color: var(--nte-blaa-uu);
	font-size: 16px;
	line-height: 19px;
`;

const FormWrapper = styled.div`
	background: var(--white);
	border: 1px solid var(--nte-graa-lys);
	box-sizing: border-box;
	border-radius: var(--border-radius-small);
	width: 400px;
	min-height: 280px;
	position: absolute;
	overflow-y: auto;
	padding: 16px;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	right: 0;
	bottom: 0;
	@media ${device.mobile} {
		p {
			margin: 0.7rem 0;
		}
		position: fixed;
		width: 100%;
		border: none;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding-top: 3rem;
	}
`;

const CloseButton = styled.a`
	position: absolute;
	top: 0;
	right: 0;
	background: none;
	border: none;
	color: var(--nte-blaa);
	padding: 1rem;
	margin: 0;
	i {
		font-size: 1.2rem;
	}
`;

const StyledForm = styled.form`
	font-size: 0.875rem;
	display: flex;
	flex-direction: column;

	@media ${device.mobile} {
	}
`;

const ThankYouWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	height: 240px;
`;

const ErrorMessage = styled.p`
	margin: 0.3rem 0;
	color: var(--varsel-roed);
	height: 1rem;
	border: 1px solid transparent;
`;

const StyledUploadLabel = styled.label`
	background: var(--nte-graa-lys-50);
	box-sizing: border-box;
	width: 100%;
	height: 45px;
	display: block;
	line-height: 45px;
	overflow-x: hidden;
	padding: 0 12px;
	position: relative;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: var(--nte-graa-medium);

	&:focus-within {
		box-shadow: 0 0 2px 1px var(--nte-blaa-logo);
	}
	i {
		color: var(--nte-blaa);
		position: absolute;
		top: 12px;
		right: 12px;
		font-size: 1.2rem;
	}
`;

const StyledUploadInput = styled.input`
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

const MobileFeedbackWrapper = styled.div`
	display: none;
	justify-content: center;
	align-items: center;

	@media ${device.tablet} {
		display: flex;
	}
`;

const StyledHeaderLink = styled(Link)`
	position: absolute;
	top: 1rem;
	right: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	width: 2.5rem;
	height: 2.5rem;
	background: var(--white);

	box-shadow: var(--clickable-box-shadow-float);
	border-radius: 50%;

	font-size: 1.125rem;
	line-height: 1.125rem;

	color: var(--nte-blaa);

	text-decoration: none;
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
`;

const StyledBottomLink = styled(StyledHeaderLink)`
	position: fixed;
	top: auto;
	bottom: 1rem;
`;

interface FileUploadFieldProps {
	name: string;
}
function FileUploadField(props: FileUploadFieldProps) {
	const [fileName, setFileName] = useState('');

	return (
		<StyledUploadLabel>
			<span>{fileName || 'Skjermbilde'}</span>
			<i className="far fa-upload" />
			<StyledUploadInput
				onChange={e => {
					setFileName(e.target.files[0]?.name);
				}}
				type="file"
				name={props.name}
			/>
		</StyledUploadLabel>
	);
}

export interface SkjemaData {
	feedback: string;
	epost: string;
	samtykke: boolean;
	skjermbilde: File;
	url: string;
}
export interface SkjemaProps {
	loading?: boolean;
	error?: string;
	takkForTilbakemelding?: boolean;
	onSubmit?: (skjemaData: SkjemaData) => void;
	onClose?: () => void;
}
export function Skjema(props: SkjemaProps) {
	const setIsOpen = (val: boolean) => {
		if (history?.location?.state?.tilbakemeldingOpen !== val) {
			history.push(history.location.pathname, { tilbakemeldingOpen: val });
		}
	};
	const history = useHistory<any>();

	if (props.takkForTilbakemelding && history?.location?.state?.tilbakemeldingOpen) {
		return (
			<Wrapper>
				<CloseOverlay onClick={() => setIsOpen(false)} />

				<FormWrapper>
					<CloseButton
						type="button"
						onClick={() => {
							setIsOpen(false);
							props?.onClose?.();
						}}>
						<i className="far fa-times" />
					</CloseButton>
					<ThankYouWrapper>
						<h1 style={{ display: 'flex', alignContent: 'center', fontSize: '1.5rem' }}>
							Takk for tilbakemeldingen <i style={{ padding: '0 0.5rem' }} className="far fa-thumbs-up" />
						</h1>
						<ButtonWrapper>
							<Button
								onClick={() => {
									setIsOpen(false);
									props?.onClose?.();
								}}>
								Lukk
							</Button>
						</ButtonWrapper>
					</ThankYouWrapper>
				</FormWrapper>
			</Wrapper>
		);
	}

	if (history?.location?.state?.tilbakemeldingOpen) {
		return (
			<Wrapper>
				<CloseOverlay onClick={() => setIsOpen(false)} />

				<FormWrapper>
					<CloseButton
						type="button"
						onClick={() => {
							setIsOpen(false);
							props?.onClose?.();
						}}>
						<i className="far fa-times" />
					</CloseButton>
					<LoadingOverlay loading={props.loading} text="Laster inn skjema" />
					<h1 style={{ margin: '0.6rem 0 0', fontSize: '1.5rem' }}>Gi tilbakemelding</h1>

					<StyledForm
						onSubmit={(e: any) => {
							e.preventDefault();
							e.stopPropagation();

							const data = {
								feedback: e.target.feedback?.value ?? '',
								epost: e.target.epost?.value ?? '',
								samtykke: e.target.epost?.value ? true : false,
								skjermbilde: e.target.skjermbilde.files[0] ?? null,
								url: window.location.pathname || '',
							};

							props?.onSubmit?.(data);
						}}>
						<p>
							Vi setter pris på dine innspill, enten det er stort eller smått. Tilbakemeldingene dine kommer direkte til oss som utvikler denne
							løsningen.
						</p>

						<div>
							<MobileTextarea placeholder="Din melding *" name="feedback" hasError={props?.error?.length > 0}></MobileTextarea>
							<ErrorMessage style={{ margin: '0' }}>{props.error}</ErrorMessage>
						</div>
						<Push height="0.3rem" />

						<p>Her kan du laste opp et skjermbilde som legges ved meldingen din</p>
						<FileUploadField name="skjermbilde" />
						<Push height="0.5rem" />

						<p>Hvor kan vi kontakte deg og eventuelt svare på henvendelsen din?</p>
						<InputField name="epost" type="email" placeholder="Din e-postadresse" />
						<Push height="0.3rem" />

						<div style={{ textAlign: 'right', width: '100%', margin: '1rem 0 0' }}>
							<Button style={{ margin: '0.5rem 0', display: 'inline-block' }}>Send tilbakemelding</Button>
						</div>
					</StyledForm>
				</FormWrapper>
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<MobileFeedbackWrapper>
				<StyledBottomLink
					to={'./'}
					onClick={e => {
						e.preventDefault();

						if (history?.location?.state?.tilbakemeldingOpen) return;

						history.push(history.location.pathname, { ...history.location.state, tilbakemeldingOpen: true });
					}}>
					<i className="far fa-comment-dots" />
				</StyledBottomLink>
			</MobileFeedbackWrapper>
			<DesktopFeedbackButtonWrapper>
				<FeedbackButtonLink
					to={'./'}
					onClick={e => {
						e.preventDefault();

						if (history?.location?.state?.tilbakemeldingOpen) return;

						history.push(history.location.pathname, { ...history.location.state, tilbakemeldingOpen: true });
					}}>
					<i className="far fa-comments" />
				</FeedbackButtonLink>
				<FeedbackLabel>Gi tilbakemelding</FeedbackLabel>
			</DesktopFeedbackButtonWrapper>
		</Wrapper>
	);
}

export default function TilbakemeldingSkjema() {
	const { track } = useTracking();
	const { accessToken } = useAuthHelpers();

	const [isSendingFeedback, setIsSendingFeedback] = useState(false);
	const [isShowingFeedbackReceipt, setIsShowingFeedbackReceipt] = useState(false);
	const [feedbackError, setFeedbackError] = useState('');
	return (
		<Skjema
			loading={isSendingFeedback}
			takkForTilbakemelding={isShowingFeedbackReceipt}
			error={feedbackError}
			onClose={() => {
				setIsSendingFeedback(false);
				setIsShowingFeedbackReceipt(false);
				setFeedbackError('');
			}}
			onSubmit={async data => {
				if (data.feedback.trim() === '') {
					setFeedbackError('Tilbakemeldingen kan ikke være tom');
					return;
				}
				setIsSendingFeedback(true);

				const formData = new FormData();
				if (data.skjermbilde) {
					formData.append('file', data.skjermbilde);
				}
				formData.append('userEmail', data.epost);
				formData.append('emailConsent', data.samtykke.toString());
				formData.append('feedbackText', data.feedback);
				formData.append('location', data.url);

				try {
					const response = await fetch(env.REACT_APP_BP_BACKEND + '/bp-api/v1/feedback/feedback', {
						headers: {
							authorization: accessToken ? `Bearer ${accessToken}` : '',
						},
						method: 'POST',
						body: formData,
					});

					if (!response.ok) {
						setIsSendingFeedback(false);
						console.error(response);
						throw new Error('Feedback response not ok');
					}
				} catch (error) {
					setIsSendingFeedback(false);
					console.error(error);
					setFeedbackError('Et problem oppstod, vennligst prøv igjen senere...');
				}

				track('Feedback Given');
				setIsSendingFeedback(false);
				setIsShowingFeedbackReceipt(true);
			}}
		/>
	);
}
