import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ClearButton from 'web/components/atoms/ClearButton';
import FlexPageWrapper from 'web/components/atoms/FlexPageWrapper';
import PageSubTitle from 'web/components/atoms/PageSubTitle';
import PageTitle, { PageTitleButton } from 'web/components/atoms/PageTitle';
import Panel from 'web/components/atoms/Panel';
import PanelTitle from 'web/components/atoms/PanelTitle';
import Push from 'web/components/atoms/Push';
import DatePicker, { DatePickerProps } from 'web/components/molecules/DatePicker';
import FilterBarSelect from 'web/components/molecules/filter/FilterBarSelect';
import HendelserList from 'web/components/molecules/HendelserList';
import InfoBox, { INFOBOX_ALIGN } from 'web/components/molecules/InfoBox';
import Modal from 'web/components/molecules/Modal';
import { SecondaryValueInfoPanel } from 'web/components/molecules/SecondaryValueInfoPanel';
import SimpleGraph from 'web/components/molecules/SimpleGraph';
import SpotprisVarselSkjema from 'web/components/organisms/forms/SpotprisVarselSkjema';
import { ValidateEmailSkjema } from 'web/components/organisms/forms/ValidateEmailSkjema';
import { AktivtVarsel, Energisone } from 'web/hooks/useSpotpris';
import { FilterDataSelectElement } from 'web/lib/filter.utils';
import { GraphWrapper, MissingDataOverlay, SecondaryValuePanelWrapper } from 'web/templates/GraphTemplate';

const SpotPrisWrapper = styled.div``;

const VarslerWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const VarselElement = styled.div`
	display: flex;

	background-color: var(--white);
	border: 1px solid var(--nte-graa-lys);
	border-radius: var(--border-radius-smallest);

	padding: 16px;
	margin-bottom: 0.5rem;
`;

export const VarselIcon = styled.i`
	margin-right: 0.5rem;
	font-size: 1.125rem;
	color: var(--nte-korall-moerkere);
`;

export const SoneVelger = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	color: var(--nte-graa-mork);
	font-size: 0.875rem;
`;

export const StyledPanelTitle = styled(PanelTitle)`
	display: flex;
	align-items: center;

	& button {
		margin: 0;
		margin-left: 0.5rem;
	}
`;

export enum Operator {
	GT = 'GT',
	LT = 'LT',
}

export interface Varsel {
	id?: string;
	orgId?: string;
	epost?: string;
	varslingskriterie?: { operator: Operator; verdi: number };
	beskrivelse?: string;
	energisone?: Energisone;
}

interface spotprisBindingProps {
	loading: boolean;
	chartRef: any;
	chartData: any;
	isMissingData?: boolean;
	missingDataElement?: any;
	secondaryInfoPanels: any;

	varsler?: Varsel[];
	aktiveVarsler?: AktivtVarsel[];
	numOfAktiveVarsler?: number;
}

interface SpotprisTemplateProps {
	orgId: string;
	energisone: Energisone;
	soneVelgerData: FilterDataSelectElement;
	datePickerData: DatePickerProps;

	spotprisBindings: spotprisBindingProps;

	onAddVarsel: (varsel: Varsel) => void;
	onUpdateVarsel: (varsel: Varsel) => void;
	onValidateEmail: (varsel: Varsel, kode: string) => void;
	emailValidationError?: string;
	onRemoveVarsel: (varsel: Varsel) => void;
	onOpenEditVarsel?: (varsel: Varsel) => void;

	setVisVarselModal?: (visLagVarselModal: boolean) => void;
	visVarselModal?: boolean;
	varselElement: any;
}

export default function SpotprisTemplate({
	orgId,
	energisone,
	soneVelgerData,
	datePickerData,

	spotprisBindings,

	onAddVarsel,
	onUpdateVarsel,
	onValidateEmail,
	emailValidationError,
	onRemoveVarsel,
	onOpenEditVarsel,

	setVisVarselModal,
	visVarselModal,
	varselElement,
}: SpotprisTemplateProps) {
	const { t } = useTranslation('forbruk');
	return (
		<FlexPageWrapper>
			<PageTitle>Spotpris</PageTitle>
			<PageSubTitle>
				Her finner du både historiske spotpriser og priser for i dag og i morgen. Du kan også legge til varsler på dine egne ønskede verdier nederst på
				denne siden.
			</PageSubTitle>

			<SoneVelger>
				<span style={{ fontWeight: 'bold' }}>Velg prisområde</span>
				<FilterBarSelect {...soneVelgerData} />
			</SoneVelger>

			<Panel>
				<DatePicker {...datePickerData} />
				<GraphWrapper>
					<SimpleGraph
						loadingData={spotprisBindings.loading}
						chartRef={spotprisBindings.chartRef}
						style={{
							opacity: (spotprisBindings.chartData && '1') || '0',
						}}
						chart={
							spotprisBindings.chartData || {
								data: {
									columns: [],
									type: 'bar',
								},
							}
						}>
						{spotprisBindings.isMissingData && !spotprisBindings.loading && (
							<MissingDataOverlay>
								{spotprisBindings.missingDataElement || (
									<span>
										Fant ikke noe spotprisdata å vise,
										<br />
										prøv et annet filter eller en annen periode.
									</span>
								)}
							</MissingDataOverlay>
						)}
					</SimpleGraph>

					{spotprisBindings.secondaryInfoPanels && (
						<SecondaryValuePanelWrapper>
							{spotprisBindings.secondaryInfoPanels?.map((infoPanelprops: any, index: number) => (
								<SecondaryValueInfoPanel key={index} {...infoPanelprops} />
							))}
						</SecondaryValuePanelWrapper>
					)}

					{(spotprisBindings.numOfAktiveVarsler && (
						<HendelserList
							numOfAktiveVarsler={spotprisBindings.numOfAktiveVarsler}
							aktiveVarsler={spotprisBindings.aktiveVarsler}
							selectedDate={datePickerData.selectedDate}
						/>
					)) || <></>}
				</GraphWrapper>
			</Panel>

			<Push height="0" />

			<SpotPrisWrapper>
				<StyledPanelTitle>
					Varsler
					<PageTitleButton
						type="button"
						onClick={() => {
							setVisVarselModal(true);
						}}>
						<i className="fa fa-plus" /> Legg til varsel
					</PageTitleButton>
				</StyledPanelTitle>
				<Push height="1rem" />
				<VarslerWrapper>
					{(spotprisBindings.varsler?.length &&
						spotprisBindings.varsler?.map(varsel => (
							<VarselElement key={varsel.id}>
								<div
									style={{
										flexGrow: 1,
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}>
									<VarselIcon className="fas fa-bell" />
									<span>
										{varsel.beskrivelse
											? `${varsel.beskrivelse}`
											: `Spotpris er ${t('Spotpris.operator.' + varsel.varslingskriterie.operator)} ${
													varsel.varslingskriterie.verdi
											  } øre`}
									</span>
								</div>
								<ClearButton
									onClick={() => {
										onOpenEditVarsel(varsel);
									}}
									style={{
										flexGrow: 0,
										alignSelf: 'flex-end',
										fontWeight: 'bold',
										padding: 0,
										whiteSpace: 'nowrap',
									}}>
									Rediger <i className="fa fa-chevron-right" />
								</ClearButton>
							</VarselElement>
						))) || (
						<InfoBox
							align={INFOBOX_ALIGN.left}
							buttonText={'Legg til varsel'}
							onButtonClicked={() => {
								setVisVarselModal(true);
							}}>
							Du har ikke satt opp noen varsler for {t('Spotpris.energisone.' + energisone, energisone)}, ønsker du å motta varsler på e-post?
						</InfoBox>
					)}
				</VarslerWrapper>
				{visVarselModal &&
					varselElement &&
					!varselElement?.isEditing && ( //New Varsel
						<Modal
							title={`Legg til varsel i ${t('Spotpris.energisone.' + energisone, energisone)}`}
							onClose={() => {
								setVisVarselModal(false);
							}}>
							{!varselElement.varsel && (
								<SpotprisVarselSkjema
									onSubmit={onAddVarsel}
									description="Her kan du legge til varsel slik at du får epost når spotprisen går over eller under en grense for det valgte prisområdet."
									energisone={energisone}
									orgId={orgId}
									loading={spotprisBindings.loading}
								/>
							)}
							{varselElement.varsel && !varselElement.isValidated && (
								<ValidateEmailSkjema
									onSubmit={(kode: string) => onValidateEmail(varselElement.varsel, kode)}
									description="Før vi kan opprette varselet ditt, må du bekrefte e-postadressen din. Vi har sendt deg en kode på e-postadressen du fylte inn i forrige steg."
									emailValidationError={emailValidationError}
									loading={spotprisBindings.loading}
								/>
							)}
						</Modal>
					)}

				{visVarselModal &&
					varselElement &&
					varselElement?.isEditing && ( //Edit Varsel
						<Modal
							title={
								varselElement.varsel.beskrivelse ||
								`Spotprisen er ${t('Spotpris.operator.' + varselElement.varsel.varslingskriterie.operator)} ${
									varselElement.varsel.varslingskriterie.verdi
								} øre`
							}
							onClose={() => {
								setVisVarselModal(false);
							}}>
							{varselElement.varsel && varselElement.isValidated && (
								<SpotprisVarselSkjema
									onSubmit={onUpdateVarsel}
									defaultVarsel={varselElement.varsel}
									onRemove={onRemoveVarsel}
									energisone={energisone}
									orgId={orgId}
									loading={spotprisBindings.loading}
								/>
							)}
							{!varselElement.isValidated && (
								<ValidateEmailSkjema
									onSubmit={(kode: string) => onValidateEmail(varselElement.varsel, kode)}
									description="Du endret e-postadressen på varselet til en som ikke er bekreftet. Vi har sendt deg en kode på e-postadressen du fylte inn i forrige steg."
									emailValidationError={emailValidationError}
									loading={spotprisBindings.loading}
								/>
							)}
						</Modal>
					)}
			</SpotPrisWrapper>
		</FlexPageWrapper>
	);
}
