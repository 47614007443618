import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, useParams } from 'react-router-dom';
import TilbakemeldingSkjema from 'web/components/organisms/forms/TilbakemeldingSkjema';
import { Authorized, UnAuthorized } from 'web/hooks/useAuthorization';
import TenantLayout from 'web/hooks/useTenant.provider';
import AdminPage from 'web/pages/AdminPage';
import AnleggPage from 'web/pages/AnleggPage';
import FakturaPage from 'web/pages/FakturaPage';
import ForbrukPage from 'web/pages/ForbrukPage';
import BedriftAccessErrorPage from 'web/pages/generic/BedriftAccessErrorPage';
import ModulAccessErrorPage from 'web/pages/generic/ModulAccessErrorPage';
import HovedsidenPage from 'web/pages/HovedsidenPage';
import InnboksPage from 'web/pages/InnboksPage';
import KostnadPage from 'web/pages/KostnadPage';
import SingleAnleggPage from 'web/pages/SingleAnleggPage';
import SingleFakturaPage from 'web/pages/SingleFakturaPage';
import SpotprisPage from 'web/pages/SpotprisPage';

interface ModuleAccessProps {
	children?: ReactNode;
	group?: string;
	returnTo: string;
}

export function ModuleAccess({ children, group, returnTo }: ModuleAccessProps) {
	return (
		<>
			<Authorized group={group}>{children}</Authorized>
			<UnAuthorized group={group}>
				<ModulAccessErrorPage returnTo={returnTo} />
			</UnAuthorized>
		</>
	);
}

export default function TenantRoutes() {
	const { t } = useTranslation('generic');
	let { bedriftId } = useParams() as any;

	// Clears the state so history state based modals wont show on refresh
	window.history.replaceState({}, '');

	return (
		<TenantLayout key="tenant">
			<Route path={`/:bedriftId/`} exact>
				<Authorized group="Bedriftsadmin">
					<Redirect to={`/${bedriftId}/brukere/`} />
				</Authorized>

				<Authorized>
					<Redirect to={`/${bedriftId}/hovedsiden/`} />
				</Authorized>
				<BedriftAccessErrorPage />
			</Route>

			<Route path={`/:bedriftId/dashboard/`} exact>
				<Redirect to={`/${bedriftId}/hovedsiden/`} />
			</Route>
			<Route path={`/:bedriftId/sammendrag/`} exact>
				<Redirect to={`/${bedriftId}/hovedsiden/`} />
			</Route>

			<Route path={`/:bedriftId/hovedsiden/`} exact>
				<ModuleAccess returnTo={`/${bedriftId}/`}>
					<HovedsidenPage />
				</ModuleAccess>
			</Route>

			<Route path={`/:bedriftId/anlegg/`} exact>
				<ModuleAccess group="Strøm" returnTo={`/${bedriftId}/`}>
					<AnleggPage />
				</ModuleAccess>
			</Route>

			<Route path={`/:bedriftId/anlegg/:anleggId/`} exact>
				<ModuleAccess group="Strøm" returnTo={`/${bedriftId}/anlegg/`}>
					<SingleAnleggPage />
				</ModuleAccess>
			</Route>

			<Route path={`/:bedriftId/anlegg/:anleggId/:fakturaId/`} exact>
				<ModuleAccess group="Strøm" returnTo={`/${bedriftId}/anlegg/:anleggId/`}>
					<SingleFakturaPage />
				</ModuleAccess>
			</Route>

			<Route path={`/:bedriftId/faktura/`} exact>
				<ModuleAccess group="Strøm" returnTo={`/${bedriftId}/`}>
					<FakturaPage />
				</ModuleAccess>
			</Route>

			<Route path={`/:bedriftId/faktura/:fakturaId/`} exact>
				<ModuleAccess group="Strøm" returnTo={`/${bedriftId}/faktura/`}>
					<SingleFakturaPage />
				</ModuleAccess>
			</Route>

			<Route path={`/:bedriftId/kostnad/`} exact>
				<ModuleAccess group="Strøm" returnTo={`/${bedriftId}/`}>
					<KostnadPage />
				</ModuleAccess>
			</Route>

			<Route path={`/:bedriftId/forbruk/`} exact>
				<ModuleAccess group="Strøm" returnTo={`/${bedriftId}/`}>
					<ForbrukPage />
				</ModuleAccess>
			</Route>

			<Route path={`/:bedriftId/spotpris/`} exact>
				<ModuleAccess returnTo={`/${bedriftId}/`}>
					<SpotprisPage />
				</ModuleAccess>
			</Route>

			<Route path={`/:bedriftId/innboks/`} exact>
				<ModuleAccess returnTo={`/${bedriftId}/`}>
					<InnboksPage />
				</ModuleAccess>
			</Route>

			<Route path={`/:bedriftId/brukere/`}>
				<ModuleAccess group="Bedriftsadmin" returnTo={`/${bedriftId}/`}>
					<Route path={`/:bedriftId/brukere/`} exact>
						<AdminPage isSysAdminPage={false} />
					</Route>
				</ModuleAccess>
			</Route>
			<TilbakemeldingSkjema />
		</TenantLayout>
	);
}
