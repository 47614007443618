import { useEffect, useState } from 'react';
import useAnlegg from 'web/hooks/useAnlegg';
import usePrefs from 'web/hooks/usePrefs';
import { useTracking } from 'web/hooks/useTracking';
import { Filter } from 'web/lib/filter.utils';

export const DEFAULT_FILTER = {
	kategorier: [],
	naeringskoder: [],
	fakturamerker: [],
	sorter: '',
	search: '',
	energisone: null,
} as Filter;

export default function useFilter(): [Filter, (newFilter: Filter) => void] {
	const { track } = useTracking();
	const prefs = usePrefs();
	const KEY = 'user.filter';
	const { energisoneMedFlestAnlegg } = useAnlegg();
	const [filter, _setFilter] = useState(prefs.getItem(KEY, DEFAULT_FILTER));
	function setFilter(newFilter: Filter) {
		if (newFilter !== DEFAULT_FILTER) {
			trackFilterChange(filter, newFilter);
		}
		prefs.setItem(KEY, newFilter);
		_setFilter(newFilter);
	}

	useEffect(() => {
		if (energisoneMedFlestAnlegg && !filter.energisone) {
			setFilter({ ...filter, energisone: energisoneMedFlestAnlegg });
		}
	}, [energisoneMedFlestAnlegg]);

	function trackFilterChange(oldFilter: Filter, newFilter: Filter, props?: any) {
		const trackingProps = { oldFilter: oldFilter, newFilter: newFilter, ...props, label: 'Filter endret' };
		track('Filter changed', trackingProps);
	}

	return [filter, setFilter];
}
