import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'web/components/atoms/Button';
import Center from 'web/components/atoms/Center';
import PageTitle from 'web/components/atoms/PageTitle';
import Paragraph from 'web/components/atoms/Paragraph';
import RadioBox from 'web/components/molecules/form/RadioBox';
import TextAreaField from 'web/components/molecules/form/TextAreaField';
import InfoBox, { INFOBOX_COLOR } from 'web/components/molecules/InfoBox';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import { useAvslaaInvitasjonMutation } from 'web/pages/generic/InvitasjonAvslagPage.graphql-gen';

//#region styling
const AvslagForm = styled.form`
	margin: 0 auto;
	max-width: 520px;
`;
const AvslagSubTitle = styled.h2`
	text-align: left;
	font-weight: bold;
	font-size: 1.125rem;
	line-height: 2rem;
	color: var(--nte-sort);
`;
const RadioButtonWrapper = styled.div`
	padding: 25px 0;
	text-align: left;
`;
const StyledTextAreaField = styled(TextAreaField)`
	min-height: 100px;
	height: 100px;
	margin: 0 auto;
`;
const AvslagGodkjentWrapper = styled.div`
	margin: 0 auto;
	max-width: 520px;
`;
export const CheckMarkIcon = styled.i`
	color: var(--varsel-groenn);
	padding-right: 0.875rem;
`;
const AvslagGodkjentInfo = styled.div`
	text-align: left;
	line-height: 25px;
	color: var(--nte-sort);
	margin-bottom: 20px;
`;
//#endregion

interface InvitasjonParamProps {
	invitationId: string;
	invitationToken: string;
}

export default function InvitasjonAvslagPage() {
	const { invitationId, invitationToken } = useParams<InvitasjonParamProps>();
	const [isAddingMelding, setIsAddingMelding] = useState(false);
	const [isFinished, setIsFinished] = useState(false);

	const [avslaaInvitasjon, { loading, error }] = useAvslaaInvitasjonMutation();

	function handleRadioboxChange(e: any) {
		if (e.target.value == 'Annet (fyll inn beskrivelse under)') {
			setIsAddingMelding(true);
		} else {
			setIsAddingMelding(false);
		}
	}

	return (
		<div>
			<LoadingOverlay loading={loading} text="Avslår invitasjon" />

			{(error && !(error.toString().indexOf('ble avvist') > -1) && (
				<>
					<Center>
						<PageTitle isCenter>Beklager, en feil har oppstått</PageTitle>
					</Center>
					<Paragraph>
						<a href="/">Gå til Bedriftsportalen</a>
					</Paragraph>
				</>
			)) || (
				<>
					{(!isFinished && (
						<AvslagForm
							onSubmit={(e: any) => {
								e.preventDefault();
								avslaaInvitasjon({
									variables: {
										avslaa: {
											invitasjonId: invitationId,
											token: invitationToken,
											aarsak: e.target.aarsak?.value + (e.target.melding?.value ? ': ' + e.target.melding?.value : ''),
										},
									},
								}).then(() => {
									setIsFinished(true);
								});
							}}>
							<PageTitle isCenter>Ønsker du å avslå invitasjonen?</PageTitle>
							<p>Det skal du selvfølgelig få lov til, vi håper likevel du har mulighet til å gi oss en tilbakemelding på hvorfor:</p>
							<RadioButtonWrapper>
								<RadioBox onChange={handleRadioboxChange} name="aarsak" value="Jeg ønsker ikke å bruke Bedriftsportalen" />
								<RadioBox onChange={handleRadioboxChange} name="aarsak" value="Jeg vet ikke hva Bedriftportalen er" />
								<RadioBox onChange={handleRadioboxChange} name="aarsak" value="Jeg har ikke bedt om å få denne invitasjonen" />
								<RadioBox onChange={handleRadioboxChange} name="aarsak" value="Annet (fyll inn beskrivelse under)" />
							</RadioButtonWrapper>
							{(isAddingMelding && (
								<StyledTextAreaField required={isAddingMelding} name="melding" placeholder="Jeg ønsker ikke å bli medlem fordi..." />
							)) || <></>}
							<Button>Avslå invitasjon</Button>
						</AvslagForm>
					)) || (
						<AvslagGodkjentWrapper>
							<InfoBox title="Du har avslått invitasjonen" color={INFOBOX_COLOR.green}>
								<CheckMarkIcon className="fas fa-check-circle" />
								Du kommer ikke til å få flere påminnelser om å registrere deg på Bedriftsportalen.
							</InfoBox>
							<AvslagSubTitle>Har du ombestemt deg?</AvslagSubTitle>
							<AvslagGodkjentInfo>Ønsker du likevel til tilgang til Bedriftsportalen, må du be om en ny invitasjon.</AvslagGodkjentInfo>
							<AvslagGodkjentInfo>Du kan trygt lukke denne siden nå.</AvslagGodkjentInfo>
						</AvslagGodkjentWrapper>
					)}
				</>
			)}
		</div>
	);
}
