import React, { ReactNode, useRef } from 'react';
import styled from 'styled-components';
import useOnClickOutside from 'web/hooks/useOnClickOutside';

const DropdownOverlay = styled.div`
	position: relative;
	margin-left: -32px;
	z-index: 9999;
`;

export const DropdownWrapper = styled.div`
	min-height: 10px;
	background: var(--white);
	display: block;
	position: absolute;
	border: var(--nte-border);
	box-sizing: border-box;
	box-shadow: var(--clickable-box-shadow);
	border-radius: 9px;
`;

interface DropdownProps {
	className?: string;
	position?: { left: number; top: number };
	onClose: () => void;
	children?: ReactNode;
	buttonRef?: React.MutableRefObject<any>;
	style?: React.CSSProperties;
}

export default function Dropdown({ className = '', position = { left: 0, top: 0 }, onClose = () => {}, children, buttonRef, style = {} }: DropdownProps) {
	const dropdownElement = useRef(null);

	useOnClickOutside({
		ref: dropdownElement,
		buttonRef: buttonRef,
		handler: () => {
			onClose();
		},
	});

	// Prevent dropdown from flowing over window. Will only work after click :/
	const left = Math.min(position.left, window.innerWidth - (dropdownElement.current?.offsetWidth || 0));

	return (
		<DropdownOverlay>
			<DropdownWrapper
				ref={dropdownElement}
				className={className}
				style={{
					left: left,
					top: position.top,
					...style,
				}}>
				{children}
			</DropdownWrapper>
		</DropdownOverlay>
	);
}
