import React from 'react';
import { SlaaoppBedriftDocument } from 'web/components/organisms/modals/NyKundeModal.graphql-gen';
import { NyMeldingModal } from 'web/components/organisms/modals/NyMeldingModal';
import { env } from 'web/env';
import useAuthHelpers from 'web/hooks/useAuthHelpers';
import apolloClient from 'web/lib/apolloClient';
import { GetDistributedDocumentsDocument } from 'web/pages/admin/MeldingerPage.graphql-gen';
import { MeldingElement } from 'web/templates/admin/MeldingerTemplate';

interface NyMeldingPageProps {
	backUrl: string;
}
export default function NyMeldingPage(props: NyMeldingPageProps) {
	const { accessToken } = useAuthHelpers();
	function updateMeldingCache(nyMelding: MeldingElement) {
		const data = apolloClient.readQuery({
			query: GetDistributedDocumentsDocument,
		});

		nyMelding.companies = nyMelding.companies.map(company => ({
			...company,
			__typename: 'DistributedDocumentCompany',
		}));

		apolloClient.writeQuery({
			query: GetDistributedDocumentsDocument,
			data: {
				getDistributedDocuments: [
					...data.getDistributedDocuments,
					{
						...nyMelding,
						__typename: 'DistributedDocument',
					},
				],
			},
		});
	}

	const slaaoppBedrift = async (orgnummer: string): Promise<{ navn: string; kilde: string }> => {
		const { data } = await apolloClient.query({
			query: SlaaoppBedriftDocument,
			variables: { orgnummer: orgnummer },
		});
		return data.slaaoppBedrift;
	};
	const lagreMelding = (navn: string, melding: string, orgnummer: string[], fil: File) => {
		let formData = new FormData();

		formData.append(
			'companies',
			new Blob([JSON.stringify(orgnummer)], {
				type: 'application/json',
			})
		);

		formData.append('customFileName', navn);
		formData.append('description', melding);
		formData.append('file', fil);
		return new Promise(async (resolve, reject) => {
			const request = new XMLHttpRequest();
			request.open('POST', env.REACT_APP_BP_BACKEND + '/bp-api/v1/documents/upload');
			request.setRequestHeader('Authorization', 'Bearer ' + accessToken);

			request.onload = function () {
				resolve(JSON.parse(request.response));
			};
			request.onerror = function () {
				reject();
			};

			request.send(formData);
		}).then((d: MeldingElement) => {
			updateMeldingCache(d);
			return d;
		});
	};
	return <NyMeldingModal backUrl={props.backUrl} lagreMelding={lagreMelding} slaaoppBedrift={slaaoppBedrift} />;
}
