import { addDays, format } from 'date-fns';
import { Anleggsstatus } from 'generated/graphql-types';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import Button, { ButtonWrapper, SecondaryButton } from 'web/components/atoms/Button';
import Paragraph from 'web/components/atoms/Paragraph';
import Push from 'web/components/atoms/Push';
import DataError from 'web/components/molecules/form/DataError';
import FormLabel from 'web/components/molecules/form/FormLabel';
import TextAreaField from 'web/components/molecules/form/TextAreaField';
import TextField from 'web/components/molecules/form/TextField';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import Modal from 'web/components/molecules/Modal';
import { AnleggslisteFragmentDoc, useHentKundenavnLazyQuery, useMeldUtAnleggMutation } from 'web/components/organisms/modals/MeldUtAnleggModal.graphql-gen';
import { useModalSystem } from 'web/hooks/useModalSystem';
import apolloClient from 'web/lib/apolloClient';
import { Anlegg } from 'web/models/Anlegg';

const StyledForm = styled.form`
	.react-datepicker-wrapper {
		width: 100%;
	}
`;

const StyledDatePicker = styled(DatePicker)`
	display: inline-block;
	color: var(--nte-sort);
	background: var(--white);
	border: var(--nte-border);
	border-radius: var(--border-radius-smallest);
	padding: 9px 16px;
	box-sizing: border-box;
	font-size: 1rem;

	width: 100%;
	&:disabled {
		background: var(--nte-graa-lys-50);
	}
`;

const KundenavnLabel = styled.div`
	padding: 12px;
	background: var(--nte-graa-medium);
	border: var(--nte-border);
	border-radius: var(--border-radius-smallest);
	margin-top: -26px;
	margin-bottom: 20px;
`;

function oppdaterAnleggssliste({ maalerpunktId = '', bedriftId = '' }) {
	apolloClient.cache.updateFragment(
		{
			id: `Bedrift:${bedriftId}`,
			fragment: AnleggslisteFragmentDoc,
			overwrite: true,
		},
		(bedrift: any) => {
			return {
				...bedrift,
				anlegg: bedrift.anlegg.map((anlegg: any) => {
					if (maalerpunktId == anlegg?.maalerpunktId) {
						return { ...anlegg, anleggsstatus: Anleggsstatus.UNDER_OPPHOER };
					} else {
						return anlegg;
					}
				}),
			};
		}
	);
}

const MODAL_NAME = 'MeldUtAnleggModal';

export function useMeldUtAnleggModal() {
	const { openModal, closeModal } = useModalSystem() as any;
	return {
		open: (props: any) => {
			openModal(MODAL_NAME, props);
		},
		close: () => {
			closeModal(MODAL_NAME);
		},
	};
}

interface MeldUtAnleggModalProps {
	bedriftId: string;
	anlegg: Anlegg;
	callback: () => void;
}
export function MeldUtAnleggModal(props: MeldUtAnleggModalProps) {
	const { close } = useMeldUtAnleggModal();
	const [error, setError] = useState(null);
	const [currentDate, setCurrentDate] = useState(null);

	const { anlegg } = props;
	const [foundKundenavn, setFoundKundenavn] = useState(null);

	const [meldUtAnlegg, { loading: meldUtAnleggLoading, error: meldUtAnleggError }] = useMeldUtAnleggMutation();
	const [hentKundenavn, { loading: kundenavnLoading, error: kundenavnError }] = useHentKundenavnLazyQuery();

	async function handleSubmit(data: any) {
		try {
			await meldUtAnlegg({
				variables: {
					orgid: props.bedriftId,
					anleggsutmelding: {
						utmeldingsdato: format(new Date(currentDate), 'yyyy-MM-dd'),
						maalepunktId: anlegg?.maalerpunktId,
						...data,
					},
				},
			} as any);

			oppdaterAnleggssliste({ maalerpunktId: anlegg?.maalerpunktId, bedriftId: props.bedriftId });

			props.callback?.();
			close();
		} catch (error) {
			console.error(error);
		}
	}

	let daysToSkip = 2;
	const currentDayIndex = new Date().getDay();

	if (currentDayIndex == 5 || currentDayIndex == 4) {
		daysToSkip = daysToSkip + 2;
	} else if (currentDayIndex == 6) {
		daysToSkip = daysToSkip + 1;
	}

	const adresse = (anlegg && `${anlegg.adresse || ''}, ${anlegg.postnummer || ''} ${anlegg.poststed || ''}`) || '';
	const handleKundeidChange = debounce(async value => {
		if (!(value?.length === 9 || value?.length === 11)) {
			return;
		}

		const { data } = await hentKundenavn({ variables: { kundeId: value } });

		const kundenavnArray = data?.hentKundenavn;

		if (!kundenavnArray) {
			setFoundKundenavn(null);
			return;
		}

		setFoundKundenavn(kundenavnArray);
	}, 600);

	return (
		<Modal
			title="Utmelding av anlegg"
			onClose={() => {
				close();
			}}>
			<StyledForm
				onSubmit={(e: any) => {
					e.preventDefault();
					e.stopPropagation();

					const nyKundeId = e.target.nyKundeId.value;
					const merknad = e.target.merknad.value;
					let hasError = false;
					let newError: any = {};

					if (!currentDate) {
						hasError = true;
						newError.utmeldingsdato = 'Dette feltet er påkrevd.';
					}

					if (nyKundeId && !(nyKundeId?.length == 9 || nyKundeId?.length == 11)) {
						hasError = true;
						newError.orgnummer = 'Må være et fødsels- eller organisasjonsnummer (9 eller 11 siffer)';
					}

					if (hasError) {
						setError(newError);
						return;
					}

					handleSubmit({ merknad, nyKundeId, nyKundeNavn: foundKundenavn });
				}}>
				<LoadingOverlay loading={meldUtAnleggLoading} text="Melder ut anlegg" />
				<DataError error={meldUtAnleggError} />
				<Paragraph>
					{anlegg?.maalerpunktId} med adresse {adresse}
				</Paragraph>

				<FormLabel required label="Ønsket dato for utmelding" error={error?.utmeldingsdato}>
					<StyledDatePicker
						minDate={addDays(new Date(), daysToSkip)}
						dateFormat="d. MMMM yyyy"
						name="utmeldingsdato"
						selected={currentDate}
						autoComplete="off"
						filterDate={date => {
							const day = date.getDay();

							return !(day == 0 || day == 6);
						}}
						onChange={(date, e) => {
							e.preventDefault();

							setCurrentDate(date);
						}}
					/>
				</FormLabel>

				<TextField
					error={error?.nyKundeId}
					onChange={(e: any) => {
						e.target.value = e.target.value?.replace(/\D+/g, '').substring(0, 11);
						setFoundKundenavn(null);
						handleKundeidChange(e.target.value);
					}}
					placeholder="Fødsels- eller organisasjonsnummer"
					hintText="Fødsels- eller organisasjonsnummer til den kunde som overtar anlegget"
					label="Ny kunde"
					name="nyKundeId"
				/>

				{foundKundenavn && <KundenavnLabel>{foundKundenavn}</KundenavnLabel>}

				<TextAreaField label="Mer informasjon om ny kunde" name="merknad" />

				<Paragraph>
					Når du trykker bekreft blir det sendt en melding til kundesenter som vil gjennomføre utmelding av anlegget.
					<br />
					<br /> Videre prosess vil være at netteier blir varslet om oppsigelsen.
					<br />
					<br /> Såfremt det ikke kommer inn ny kunde på anlegget vil netteier kunne koble fra anlegget uten ytterligere varsling.
					<br /> Det kan ta noen dager før endringen er synlig i anleggslisten
				</Paragraph>

				<Push height="1rem" />

				<ButtonWrapper>
					<Button>Bekreft</Button>
					<SecondaryButton
						type="button"
						onClick={() => {
							close();
						}}>
						Avbryt
					</SecondaryButton>
				</ButtonWrapper>
			</StyledForm>
		</Modal>
	);
}

export default {
	component: MeldUtAnleggModal,
	name: MODAL_NAME,
};
