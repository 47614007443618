// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentSpotprisQueryVariables = Types.Exact<{
  from: Types.Scalars['DateTime'];
  to: Types.Scalars['DateTime'];
  energisone: Types.NordpoolArea;
}>;


export type HentSpotprisQuery = { spotpris?: Array<{ pris: number, fra?: string, til?: string }> };

export type SpotprisDetailsFragment = { pris: number, fra?: string, til?: string };

export type HentVarslerQueryVariables = Types.Exact<{
  orgId: Types.Scalars['String'];
}>;


export type HentVarslerQuery = { varslingerForBrukerIOrganisasjon?: Array<{ energisone?: Types.NordpoolArea, epost?: string, id?: string, beskrivelse?: string, orgId?: string, varslingskriterie?: { verdi: number, operator: Types.Operator } }> };

export type LagreVarslingMutationVariables = Types.Exact<{
  varsling?: Types.InputMaybe<Types.VarslingInput>;
}>;


export type LagreVarslingMutation = { lagreVarsling?: { energisone?: Types.NordpoolArea, epost?: string, id?: string, beskrivelse?: string, orgId?: string, varslingskriterie?: { verdi: number, operator: Types.Operator } } };

export type SlettVarslingMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type SlettVarslingMutation = { slettVarsling?: { id?: string } };

export type VarslingsDetaljerFragment = { energisone?: Types.NordpoolArea, epost?: string, id?: string, beskrivelse?: string, orgId?: string, varslingskriterie?: { verdi: number, operator: Types.Operator } };

export const SpotprisDetailsFragmentDoc = gql`
    fragment spotprisDetails on Spotpristime {
  pris
  fra
  til
}
    `;
export const VarslingsDetaljerFragmentDoc = gql`
    fragment varslingsDetaljer on Varsling {
  energisone
  epost
  id
  beskrivelse
  orgId
  varslingskriterie {
    verdi
    operator
  }
}
    `;
export const HentSpotprisDocument = gql`
    query hentSpotpris($from: DateTime!, $to: DateTime!, $energisone: NordpoolArea!) {
  spotpris: hentSpotpris(fra: $from, til: $to, energisone: $energisone) {
    ...spotprisDetails
  }
}
    ${SpotprisDetailsFragmentDoc}`;

/**
 * __useHentSpotprisQuery__
 *
 * To run a query within a React component, call `useHentSpotprisQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentSpotprisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentSpotprisQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      energisone: // value for 'energisone'
 *   },
 * });
 */
export function useHentSpotprisQuery(baseOptions: Apollo.QueryHookOptions<HentSpotprisQuery, HentSpotprisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentSpotprisQuery, HentSpotprisQueryVariables>(HentSpotprisDocument, options);
      }
export function useHentSpotprisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentSpotprisQuery, HentSpotprisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentSpotprisQuery, HentSpotprisQueryVariables>(HentSpotprisDocument, options);
        }
export type HentSpotprisQueryHookResult = ReturnType<typeof useHentSpotprisQuery>;
export type HentSpotprisLazyQueryHookResult = ReturnType<typeof useHentSpotprisLazyQuery>;
export type HentSpotprisQueryResult = Apollo.QueryResult<HentSpotprisQuery, HentSpotprisQueryVariables>;
export const HentVarslerDocument = gql`
    query hentVarsler($orgId: String!) {
  varslingerForBrukerIOrganisasjon: varslingerForBrukerIOrganisasjon(
    orgId: $orgId
  ) {
    ...varslingsDetaljer
  }
}
    ${VarslingsDetaljerFragmentDoc}`;

/**
 * __useHentVarslerQuery__
 *
 * To run a query within a React component, call `useHentVarslerQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentVarslerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentVarslerQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useHentVarslerQuery(baseOptions: Apollo.QueryHookOptions<HentVarslerQuery, HentVarslerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentVarslerQuery, HentVarslerQueryVariables>(HentVarslerDocument, options);
      }
export function useHentVarslerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentVarslerQuery, HentVarslerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentVarslerQuery, HentVarslerQueryVariables>(HentVarslerDocument, options);
        }
export type HentVarslerQueryHookResult = ReturnType<typeof useHentVarslerQuery>;
export type HentVarslerLazyQueryHookResult = ReturnType<typeof useHentVarslerLazyQuery>;
export type HentVarslerQueryResult = Apollo.QueryResult<HentVarslerQuery, HentVarslerQueryVariables>;
export const LagreVarslingDocument = gql`
    mutation lagreVarsling($varsling: VarslingInput) {
  lagreVarsling(varsling: $varsling) {
    ...varslingsDetaljer
  }
}
    ${VarslingsDetaljerFragmentDoc}`;
export type LagreVarslingMutationFn = Apollo.MutationFunction<LagreVarslingMutation, LagreVarslingMutationVariables>;

/**
 * __useLagreVarslingMutation__
 *
 * To run a mutation, you first call `useLagreVarslingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLagreVarslingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lagreVarslingMutation, { data, loading, error }] = useLagreVarslingMutation({
 *   variables: {
 *      varsling: // value for 'varsling'
 *   },
 * });
 */
export function useLagreVarslingMutation(baseOptions?: Apollo.MutationHookOptions<LagreVarslingMutation, LagreVarslingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LagreVarslingMutation, LagreVarslingMutationVariables>(LagreVarslingDocument, options);
      }
export type LagreVarslingMutationHookResult = ReturnType<typeof useLagreVarslingMutation>;
export type LagreVarslingMutationResult = Apollo.MutationResult<LagreVarslingMutation>;
export type LagreVarslingMutationOptions = Apollo.BaseMutationOptions<LagreVarslingMutation, LagreVarslingMutationVariables>;
export const SlettVarslingDocument = gql`
    mutation slettVarsling($id: ID!) {
  slettVarsling(id: $id) {
    id
  }
}
    `;
export type SlettVarslingMutationFn = Apollo.MutationFunction<SlettVarslingMutation, SlettVarslingMutationVariables>;

/**
 * __useSlettVarslingMutation__
 *
 * To run a mutation, you first call `useSlettVarslingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSlettVarslingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [slettVarslingMutation, { data, loading, error }] = useSlettVarslingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSlettVarslingMutation(baseOptions?: Apollo.MutationHookOptions<SlettVarslingMutation, SlettVarslingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SlettVarslingMutation, SlettVarslingMutationVariables>(SlettVarslingDocument, options);
      }
export type SlettVarslingMutationHookResult = ReturnType<typeof useSlettVarslingMutation>;
export type SlettVarslingMutationResult = Apollo.MutationResult<SlettVarslingMutation>;
export type SlettVarslingMutationOptions = Apollo.BaseMutationOptions<SlettVarslingMutation, SlettVarslingMutationVariables>;