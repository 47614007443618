// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
export type FjuttlisteFragment = { __typename: 'Bedrift', fjutter?: Array<{ __typename: 'Fjutt', anleggsId?: string }> };

export const FjuttlisteFragmentDoc = gql`
    fragment fjuttliste on Bedrift {
  __typename
  fjutter {
    anleggsId
    __typename
  }
}
    `;