import { addMinutes, format, isDate } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import { useRef, useState } from 'react';
import { useHentKostnadQuery } from 'web/hooks/useKostnad.graphql-gen';
import useNumbers from 'web/hooks/useNumbers';
import { useTenant } from 'web/hooks/useTenant';
import { useTracking } from 'web/hooks/useTracking';
import { Opploesninger, fyllInnManglendeVerdierKostnad, getDatoFormat, getPeriodeFromOpploesning, getShortDatoFormat } from 'web/lib/dates.utils';
import { Filter } from 'web/lib/filter.utils';

export interface useGraphProps {
	maalere: string[];
	filter?: Filter;
	selectedDate: Date;
	opploesning?: Opploesninger;
	graphZoom?: boolean;
	useShortXLabel?: boolean;
}

interface useKostnadProps extends useGraphProps {}
export default function useKostnad({ maalere, selectedDate, opploesning, graphZoom, useShortXLabel }: useKostnadProps) {
	const n = useNumbers();
	const tenant = useTenant();
	const { track } = useTracking();

	const [isMissingData, setIsMissingData] = useState(false);
	const datoRange = getPeriodeFromOpploesning(new Date(selectedDate), Opploesninger.maned);
	const chartRef = useRef<any>(null);

	// TODO UC: takle at man bare får månedsoppløsning på historiske data

	const { loading, error } = useHentKostnadQuery({
		variables: {
			orgid: tenant?.id,
			fraDato: format(datoRange.fra, 'yyyy-MM-dd'),
			tilDato: format(addMinutes(datoRange.til, 1), 'yyyy-MM-dd'),
			opploesning: opploesning,
			maalere: maalere,
		},
		fetchPolicy: 'cache-and-network',
		onError: error => {
			console.error(error);
			setIsMissingData(true);
			chartRef.current.load({
				unload: true,
				type: 'bar',
			} as any);
		},
		onCompleted: data => {
			const bedrift = data?.bedrift || {};
			const kostnadsdetaljer = bedrift.kostnad;
			let verdier = kostnadsdetaljer?.verdier || [];

			let kostnadsData = [...verdier].sort((a: any, b: any) => {
				const aTime = new Date(a.fraTid);
				const bTime = new Date(b.fraTid);
				if (aTime > bTime) return 1;
				if (bTime > aTime) return -1;
				return 0;
			});

			if (kostnadsData?.length === 0) {
				setIsMissingData(true);
			} else {
				setIsMissingData(false);
			}

			kostnadsData = fyllInnManglendeVerdierKostnad({
				data: kostnadsData || [],
				...datoRange,
			});

			const formatertData =
				kostnadsData?.map((kostnad: any) => {
					return {
						cost: kostnad.kostnad,
						label: format(new Date(kostnad.fraTid), getDatoFormat(Opploesninger.maned), { locale: nbLocale }),
						fromTime: kostnad.fraTid,
					};
				}) || [];

			let kostnad = formatertData.map((element: any) => element.cost);
			let tid = formatertData.map((element: any) => new Date(element.fromTime));

			chartRef?.current?.load({
				unload: true,
				x: 'x',
				columns: [
					['x', ...tid],
					['Kostnad', ...kostnad],
				],
				type: 'bar',
			});
		},
	});

	return {
		ref: chartRef,
		loading,
		error,
		isMissingData,
		data: {
			data: {
				x: 'x',
				xFormat: '%Y-%m-%d',
				columns: [['x'], ['Kostnad']],
				type: 'bar',
			},
			zoom: {
				enabled: graphZoom,
			},
			legend: {
				position: 'bottom',
				item: {
					onclick: function (id: any) {
						track('Graph legend clicked', { id: id, label: 'Graf legend clicked' });
						chartRef.current.toggle(id);
					},
				},
			},
			axis: {
				x: {
					type: 'timeseries',
					tick: {
						format: (x: any) =>
							format(new Date(x), useShortXLabel ? getShortDatoFormat(Opploesninger.maned) : getDatoFormat(Opploesninger.maned), {
								locale: nbLocale,
							}),
					},
				},
				y: {
					label: 'kr',
					tick: {
						format: function (value: any) {
							return n(value, true, '0,0');
						},
					},
				},
			},
			grid: {
				x: {
					show: true,
				},
				y: {
					show: true,
					lines: [{ value: 0, text: '', class: 'dotted-line' }],
				},
			},
			tooltip: {
				format: {
					title: (value: any) => {
						if (!isDate(value)) return null;
						return `${format(value, 'MMMM yyyy', {
							locale: nbLocale,
						})}`;
					},
					value: (value: any) => {
						return `${n(value)} kr`;
					},
				},
			},
		},
	};
}
