// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LagreKategoriMutationVariables = Types.Exact<{
  maalerkategori: Types.MaalergrupperingInput;
}>;


export type LagreKategoriMutation = { brukerprofil?: { id?: string, epost?: string, etternavn?: string, fornavn?: string, moduler?: Array<string>, validerteEposter?: Array<string>, kategorier?: Array<{ id?: string, beskrivelse?: string, maalerpunkter?: Array<string>, navn?: string, opprettetAv?: string, orgnummer?: string }>, innstillinger?: Array<{ navn?: string, verdi?: string }> } };

export type SlettKategoriMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  orgnummer: Types.Scalars['ID'];
}>;


export type SlettKategoriMutation = { brukerprofil?: { id?: string, epost?: string, etternavn?: string, fornavn?: string, moduler?: Array<string>, validerteEposter?: Array<string>, kategorier?: Array<{ id?: string, beskrivelse?: string, maalerpunkter?: Array<string>, navn?: string, opprettetAv?: string, orgnummer?: string }>, innstillinger?: Array<{ navn?: string, verdi?: string }> } };

export type HentBrukerprofilQueryVariables = Types.Exact<{
  orgnummer: Types.Scalars['ID'];
}>;


export type HentBrukerprofilQuery = { brukerprofil?: { id?: string, epost?: string, etternavn?: string, fornavn?: string, moduler?: Array<string>, validerteEposter?: Array<string>, kategorier?: Array<{ id?: string, beskrivelse?: string, maalerpunkter?: Array<string>, navn?: string, opprettetAv?: string, orgnummer?: string }>, innstillinger?: Array<{ navn?: string, verdi?: string }> } };

export type LagreBrukerprofilInnstillingerMutationVariables = Types.Exact<{
  innstillinger?: Types.InputMaybe<Array<Types.InputMaybe<Types.InnstillingInput>> | Types.InputMaybe<Types.InnstillingInput>>;
}>;


export type LagreBrukerprofilInnstillingerMutation = { innstillinger?: Array<{ navn?: string, verdi?: string }> };

export type EpostlisteFragment = { __typename: 'Brukerprofil', validerteEposter?: Array<string> };

export type BrukerProfilDetailsFragment = { id?: string, epost?: string, etternavn?: string, fornavn?: string, moduler?: Array<string>, validerteEposter?: Array<string>, kategorier?: Array<{ id?: string, beskrivelse?: string, maalerpunkter?: Array<string>, navn?: string, opprettetAv?: string, orgnummer?: string }>, innstillinger?: Array<{ navn?: string, verdi?: string }> };

export const EpostlisteFragmentDoc = gql`
    fragment epostliste on Brukerprofil {
  __typename
  validerteEposter
}
    `;
export const BrukerProfilDetailsFragmentDoc = gql`
    fragment brukerProfilDetails on Brukerprofil {
  id
  epost
  etternavn
  fornavn
  kategorier: maalergrupperinger {
    id
    beskrivelse
    maalerpunkter
    navn
    opprettetAv
    orgnummer
  }
  moduler
  validerteEposter
  innstillinger: innstillinger {
    navn
    verdi
  }
}
    `;
export const LagreKategoriDocument = gql`
    mutation lagreKategori($maalerkategori: MaalergrupperingInput!) {
  brukerprofil: lagreMaalergruppering(maalergruppering: $maalerkategori) {
    ...brukerProfilDetails
  }
}
    ${BrukerProfilDetailsFragmentDoc}`;
export type LagreKategoriMutationFn = Apollo.MutationFunction<LagreKategoriMutation, LagreKategoriMutationVariables>;

/**
 * __useLagreKategoriMutation__
 *
 * To run a mutation, you first call `useLagreKategoriMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLagreKategoriMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lagreKategoriMutation, { data, loading, error }] = useLagreKategoriMutation({
 *   variables: {
 *      maalerkategori: // value for 'maalerkategori'
 *   },
 * });
 */
export function useLagreKategoriMutation(baseOptions?: Apollo.MutationHookOptions<LagreKategoriMutation, LagreKategoriMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LagreKategoriMutation, LagreKategoriMutationVariables>(LagreKategoriDocument, options);
      }
export type LagreKategoriMutationHookResult = ReturnType<typeof useLagreKategoriMutation>;
export type LagreKategoriMutationResult = Apollo.MutationResult<LagreKategoriMutation>;
export type LagreKategoriMutationOptions = Apollo.BaseMutationOptions<LagreKategoriMutation, LagreKategoriMutationVariables>;
export const SlettKategoriDocument = gql`
    mutation slettKategori($id: ID!, $orgnummer: ID!) {
  brukerprofil: slettMaalergruppering(id: $id, orgnummer: $orgnummer) {
    ...brukerProfilDetails
  }
}
    ${BrukerProfilDetailsFragmentDoc}`;
export type SlettKategoriMutationFn = Apollo.MutationFunction<SlettKategoriMutation, SlettKategoriMutationVariables>;

/**
 * __useSlettKategoriMutation__
 *
 * To run a mutation, you first call `useSlettKategoriMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSlettKategoriMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [slettKategoriMutation, { data, loading, error }] = useSlettKategoriMutation({
 *   variables: {
 *      id: // value for 'id'
 *      orgnummer: // value for 'orgnummer'
 *   },
 * });
 */
export function useSlettKategoriMutation(baseOptions?: Apollo.MutationHookOptions<SlettKategoriMutation, SlettKategoriMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SlettKategoriMutation, SlettKategoriMutationVariables>(SlettKategoriDocument, options);
      }
export type SlettKategoriMutationHookResult = ReturnType<typeof useSlettKategoriMutation>;
export type SlettKategoriMutationResult = Apollo.MutationResult<SlettKategoriMutation>;
export type SlettKategoriMutationOptions = Apollo.BaseMutationOptions<SlettKategoriMutation, SlettKategoriMutationVariables>;
export const HentBrukerprofilDocument = gql`
    query hentBrukerprofil($orgnummer: ID!) {
  brukerprofil: hentBrukerprofil(orgnummer: $orgnummer) {
    ...brukerProfilDetails
  }
}
    ${BrukerProfilDetailsFragmentDoc}`;

/**
 * __useHentBrukerprofilQuery__
 *
 * To run a query within a React component, call `useHentBrukerprofilQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentBrukerprofilQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentBrukerprofilQuery({
 *   variables: {
 *      orgnummer: // value for 'orgnummer'
 *   },
 * });
 */
export function useHentBrukerprofilQuery(baseOptions: Apollo.QueryHookOptions<HentBrukerprofilQuery, HentBrukerprofilQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentBrukerprofilQuery, HentBrukerprofilQueryVariables>(HentBrukerprofilDocument, options);
      }
export function useHentBrukerprofilLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentBrukerprofilQuery, HentBrukerprofilQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentBrukerprofilQuery, HentBrukerprofilQueryVariables>(HentBrukerprofilDocument, options);
        }
export type HentBrukerprofilQueryHookResult = ReturnType<typeof useHentBrukerprofilQuery>;
export type HentBrukerprofilLazyQueryHookResult = ReturnType<typeof useHentBrukerprofilLazyQuery>;
export type HentBrukerprofilQueryResult = Apollo.QueryResult<HentBrukerprofilQuery, HentBrukerprofilQueryVariables>;
export const LagreBrukerprofilInnstillingerDocument = gql`
    mutation lagreBrukerprofilInnstillinger($innstillinger: [InnstillingInput]) {
  innstillinger: lagreBrukerprofilInnstillinger(innstillinger: $innstillinger) {
    navn
    verdi
  }
}
    `;
export type LagreBrukerprofilInnstillingerMutationFn = Apollo.MutationFunction<LagreBrukerprofilInnstillingerMutation, LagreBrukerprofilInnstillingerMutationVariables>;

/**
 * __useLagreBrukerprofilInnstillingerMutation__
 *
 * To run a mutation, you first call `useLagreBrukerprofilInnstillingerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLagreBrukerprofilInnstillingerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lagreBrukerprofilInnstillingerMutation, { data, loading, error }] = useLagreBrukerprofilInnstillingerMutation({
 *   variables: {
 *      innstillinger: // value for 'innstillinger'
 *   },
 * });
 */
export function useLagreBrukerprofilInnstillingerMutation(baseOptions?: Apollo.MutationHookOptions<LagreBrukerprofilInnstillingerMutation, LagreBrukerprofilInnstillingerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LagreBrukerprofilInnstillingerMutation, LagreBrukerprofilInnstillingerMutationVariables>(LagreBrukerprofilInnstillingerDocument, options);
      }
export type LagreBrukerprofilInnstillingerMutationHookResult = ReturnType<typeof useLagreBrukerprofilInnstillingerMutation>;
export type LagreBrukerprofilInnstillingerMutationResult = Apollo.MutationResult<LagreBrukerprofilInnstillingerMutation>;
export type LagreBrukerprofilInnstillingerMutationOptions = Apollo.BaseMutationOptions<LagreBrukerprofilInnstillingerMutation, LagreBrukerprofilInnstillingerMutationVariables>;