import { format } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import { getBadgeString } from 'web/components/atoms/Badge';
import { ExportStatuses, useExportStatus } from 'web/hooks/useEksportStatus';
import { Kategori } from 'web/hooks/useProfile';
import { useTracking } from 'web/hooks/useTracking';
import asyncWait from 'web/lib/asyncWait';
import { Anlegg } from 'web/models/Anlegg';
import { utils, writeFile } from 'xlsx';

interface AnleggsEksportProps {
	anlegg: Anlegg[];
	kategorier: Kategori[];
}
export default function useAnleggsEksport() {
	const { track } = useTracking();
	const { state, setState } = useExportStatus();

	async function eksporter({ anlegg, kategorier }: AnleggsEksportProps) {
		setState({ ...state, status: ExportStatuses.DOWNLOADING, statusText: 'Forbereder nedlasting', progress: 0 });

		await asyncWait(1);

		let sheetData = [];

		const filename = `Anlegg-eksport-${format(new Date(), 'yyyy-MM-dd', { locale: nbLocale })}`;
		const book = utils.book_new();
		sheetData.push([filename]);

		sheetData.push([]);
		sheetData.push([
			'Status',
			'Oppstart',
			'Produkt',
			'Målernummer',
			'Målepunkt-ID',
			'Estimert Årsforbruk',
			'Fakturamerke',
			'Kategorier',
			'Adresse',
			'Eget navn',
			'Netteier',
			'Næringskode',
		]);

		anlegg?.forEach(anlegg => {
			const salgsordre = anlegg.salgsordrer?.[0];
			const tariffVersjon = salgsordre?.tariffVersjoner[0];
			let anleggsKategorier = kategorier.filter(kategori => kategori.maalerpunkter?.indexOf(anlegg?.maalerpunktId) > -1);

			sheetData.push([
				getBadgeString(anlegg.anleggsstatus),
				tariffVersjon?.fradato,
				tariffVersjon?.produkt,
				anlegg.maalernummer,
				anlegg.maalerpunktId,
				anlegg.aarsforbruk,
				anlegg.fakturamerke,
				anleggsKategorier.map(kategori => kategori.navn).join(', '),
				anlegg.adresse,
				anlegg?.tittel || anlegg?.egenAnleggsbeskrivelse,
				anlegg.netteier,
				`${anlegg.naeringskodeID} ${anlegg.naeringskode}`,
			]);
		});

		const sheet = utils.aoa_to_sheet(sheetData);

		utils.book_append_sheet(book, sheet, 'sheet1');

		await asyncWait(100);

		setState({ ...state, status: ExportStatuses.DOWNLOADING, statusText: 'Laster ned', progress: 0.7 });

		writeFile(book, `${filename}.xls`);

		track('Data Exported', { label: 'anleggsdata' });

		await asyncWait(700);

		setState({ ...state, status: ExportStatuses.DOWNLOADING, statusText: 'Fullført', progress: 1 });

		await asyncWait(500);

		setState({ ...state, status: ExportStatuses.HIDDEN, statusText: '', progress: 0 });
	}

	return { eksporter, state, setState };
}
