import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import Button from 'web/components/atoms/Button';

interface BackdropProps {
	isOpen?: boolean;
}
const Wrapper = styled.div<BackdropProps>`
	position: fixed;
	z-index: 2;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: ${props => (props.isOpen && 'inherit') || 'none'};
`;
const Backdrop = styled.div<BackdropProps>`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: background-color 200ms ease-in-out;
	pointer-events: ${props => (props.isOpen && 'inherit') || 'none'};
	background-color: ${props => (props.isOpen && 'var(--overlay-gray)') || 'transparent'};
`;
interface ModalProps {
	isControlled: boolean;
	open: boolean;
	contentTop?: number;
}
const Modal = styled.div<ModalProps>`
	position: fixed;
	z-index: 2;
	top: ${p => (p.contentTop ? `max(130px, ${p.contentTop}px)` : '130px')};
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 14px 14px 0 0;
	box-shadow: 0 0 10px var(--overlay-gray);
	background-color: var(--white);
	box-sizing: border-box;
	transition: ${props => (props.isControlled ? `transform 0.5s` : `none`)};
`;
const ModalToggle = styled.div`
	width: 55px;
	height: 5px;
	border-radius: var(--border-radius-smallest);
	background: var(--nte-blaa-20);
	margin: 0 auto;
`;
const ModalToggleController = styled.div`
	padding: 33px 0 29px;
	box-sizing: border-box;
`;
export const ModalTitle = styled.div`
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	text-align: center;
	margin-bottom: 20px;
`;
export const ModalCloseButton = styled(Button)`
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
`;
const ModalContent = styled.div`
	position: absolute;
	top: 55px;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
`;

type Coordinate = {
	x: number;
	y: number;
};

interface MobileDrawerProps {
	children?: any;
	drawerContentRef?: React.MutableRefObject<any>;
	fixedContent?: any;
	open?: boolean;
	onClose?: () => void;
	setIsBottom?: (isBottom: boolean) => void;
}
export default function MobileDrawer(props: MobileDrawerProps) {
	const [position, setPosition] = useState<Coordinate>((props.open && { x: 0, y: 0 }) || { x: 0, y: window.innerHeight });
	const [isControlled, setIsControlled] = useState<boolean>(true);
	const [open, setOpen] = useState<boolean>(props.open);
	const [modalContentTop, setModalContentTop] = useState(0);
	const modalContentRef = useRef(null);

	// Add cap for top of drawer, to prevent empty white space at bottom
	useEffect(() => {
		if (!props.drawerContentRef.current) {
			return;
		} else {
			const standardModalTop = 130;
			const toggleControllerHeight = 67;
			const windowHeight = window.innerHeight;
			const contentHeight = props.drawerContentRef.current.offsetHeight;

			const modalContentTop = windowHeight - (contentHeight + toggleControllerHeight);
			setModalContentTop(modalContentTop);

			if (modalContentTop > standardModalTop) {
				checkIfScrolledToBottom(modalContentRef);
			}
		}
	}, [props.drawerContentRef.current]);

	function closeModal() {
		setPosition({ x: 0, y: window.innerHeight });
		setTimeout(() => {
			setOpen(false);
			props.onClose?.();
		}, 150);
	}

	function openModal() {
		setPosition({ x: 0, y: 0 });
		setOpen(true);
	}

	useEffect(() => {
		if (props.open != open) {
			if (open) closeModal();
			else openModal();
		}
	}, [props.open]);

	const checkIfScrolledToBottom = (target: any) => {
		if (!target) return;
		return props.setIsBottom(Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight - 5);
	};

	return (
		<Wrapper isOpen={open}>
			<Backdrop onClick={() => closeModal()} isOpen={open} />
			<Draggable
				handle=".handle"
				axis="y"
				position={position}
				bounds={{
					top: 0,
				}}
				onDrag={(data, newPosition) => {
					setPosition({ x: 0, y: newPosition.y });
				}}
				onStop={(data, newPosition) => {
					setIsControlled(true);
					if (newPosition.y > 100) {
						closeModal();
					} else {
						setPosition({ x: 0, y: 0 });
					}
				}}
				onStart={() => {
					setIsControlled(false);
				}}>
				<Modal open={open} isControlled={isControlled} contentTop={modalContentTop}>
					<ModalToggleController className="handle">
						<ModalToggle />
					</ModalToggleController>
					<ModalContent ref={modalContentRef} onScroll={e => checkIfScrolledToBottom(e.target)}>
						{{ ...props.children }}
					</ModalContent>
					{props.fixedContent}
				</Modal>
			</Draggable>
		</Wrapper>
	);
}
