import { ApolloError } from '@apollo/client';
import { format } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import { InvoiceType as FakturaType, Fakturadetaljer, Source } from 'generated/graphql-types';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Button, { StyledLinkButton } from 'web/components/atoms/Button';
import CircleLoader from 'web/components/atoms/CircleLoader';
import DetailListElement, { DetailListGroup } from 'web/components/atoms/DetailListElement';
import PageTitle from 'web/components/atoms/PageTitle';
import Push from 'web/components/atoms/Push';
import FakturaIcon from 'web/components/molecules/FakturaIcon';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import useAuthHelpers from 'web/hooks/useAuthHelpers';
import useNumbers from 'web/hooks/useNumbers';
import { useTracking } from 'web/hooks/useTracking';
import { getFakturaStatusString, getFakturertString, shouldGetUnknownStatus } from 'web/lib/faktura.utils';

const SingleFakturaWrapper = styled.div`
	background: white;
	height: 100%;
	width: 100%;
`;

const FakturaStatusWrapper = styled.div`
	font-size: 0.75rem;
	color: var(--nte-graa-medium);
	i {
		font-size: 1.125rem;
		margin-right: 0.375rem;
	}
`;

const FakturaInfoWrapper = styled.div`
	padding: 2rem 1rem 3rem;
	margin: 0 1rem;
	border-radius: 1rem;
	background: var(--graa-bg-lys);
`;

const StyledDetailListElement = styled(DetailListElement)`
	line-height: 28px;
`;

const ButtonWrapper = styled.div`
	position: absolute;
	top: -22px;
	width: 100%;
	text-align: center;
`;

const StyledIcon = styled.div`
	i {
		margin: 0;
	}
`;

export interface SingleFaktura {
	detaljer?: Fakturadetaljer;
	pdfUrl?: string;
}

interface SingleFakturaTemplateProps {
	loading?: boolean;
	faktura: SingleFaktura;
	error?: ApolloError;
}
export default function SingleFakturaTemplate({ loading, faktura, error }: SingleFakturaTemplateProps) {
	const n = useNumbers();
	const { track } = useTracking();
	const { accessToken } = useAuthHelpers();
	const [fakturaPdfLoading, setFakturaPdfLoading] = useState(false);
	const detaljer = faktura?.detaljer;
	const isKreditNota = detaljer?.fakturatype === FakturaType.CREDIT_NOTE || detaljer?.fakturatype === FakturaType.CREDITED;
	const forfallsDato = detaljer?.forfallsdato;

	if ((!faktura || error) && !loading) {
		return (
			<SingleFakturaWrapper>
				<PageTitle>Beklager, noe gikk galt ved henting av faktura.</PageTitle>
				<Push />

				<FakturaInfoWrapper>
					<DetailListGroup>
						Vennligst prøv igjen siden. Om problemet forblir, gi gjerne tilbakemelding nede i høyre hjørne eller kontakt kundesenteret.
					</DetailListGroup>
				</FakturaInfoWrapper>
			</SingleFakturaWrapper>
		);
	}

	return (
		<SingleFakturaWrapper>
			<LoadingOverlay loading={loading} text="Laster inn faktura" />

			<PageTitle>{isKreditNota ? 'Kreditnota' : getFakturertString(forfallsDato) || 'Faktura'}</PageTitle>
			<FakturaStatusWrapper>
				{detaljer && <FakturaIcon faktura={detaljer} />}
				{getFakturaStatusString(detaljer)}
			</FakturaStatusWrapper>

			<Push />

			<FakturaInfoWrapper>
				<DetailListGroup>
					<StyledDetailListElement
						label={isKreditNota ? 'Sum' : 'Fakturert'}
						value={detaljer?.belopEksMoms ? n(detaljer?.belopEksMoms, true) + ' kr' : ''}
					/>
					{!shouldGetUnknownStatus(detaljer) && (
						<StyledDetailListElement label="Utestående" value={detaljer?.saldo || detaljer?.saldo === 0 ? n(detaljer?.saldo, true) + ' kr' : ''} />
					)}
					<StyledDetailListElement
						label={isKreditNota ? 'Dato' : 'Betalingsfrist'}
						value={
							forfallsDato
								? format(new Date(forfallsDato), 'd. MMMM yyyy', {
										locale: nbLocale,
								  })
								: ''
						}
					/>
					<StyledDetailListElement label="Fakturanummer" value={detaljer?.fakturanummer} />
				</DetailListGroup>
			</FakturaInfoWrapper>

			{faktura?.pdfUrl && (
				<div style={{ position: 'relative' }}>
					<ButtonWrapper>
						{(faktura?.detaljer?.source === Source.UTILITYCLOUD && (
							<Button
								type="button"
								disabled={fakturaPdfLoading}
								style={{ display: 'inline-block', padding: '8px 12px', margin: '0', fontWeight: 'normal' }}
								onClick={async () => {
									setFakturaPdfLoading(true);
									track('Invoice Downloaded');
									await fetch(faktura?.pdfUrl, {
										method: 'GET',
										headers: {
											'Content-Type': 'application/pdf',
											authorization: accessToken ? `Bearer ${accessToken}` : '',
										},
									})
										.then(response => {
											if (!(200 <= response.status && response.status < 300)) {
												return null;
											}
											return response.blob();
										})
										.then(blob => {
											if (blob === null) {
												toast('Kunne ikke laste ned faktura, prøv igjen senere', {
													position: toast.POSITION.BOTTOM_CENTER,
												});
												setFakturaPdfLoading(false);
												return;
											}
											const url = window.URL.createObjectURL(blob);
											var fileLink = document.createElement('a');
											fileLink.href = url;
											fileLink.download = `invoice-${detaljer?.fakturanummer}`;
											setFakturaPdfLoading(false);
											fileLink.click();
										})
										.catch(() => setFakturaPdfLoading(false));
								}}>
								<span style={{ display: 'flex', gap: '0.5rem' }}>
									Last ned original faktura (PDF){' '}
									{fakturaPdfLoading ? (
										<StyledIcon>
											<CircleLoader size="1rem" />
										</StyledIcon>
									) : (
										<StyledIcon>
											<i className="fal fa-download" />
										</StyledIcon>
									)}
								</span>
							</Button>
						)) || (
							<StyledLinkButton
								type="button"
								href={faktura.pdfUrl}
								style={{ display: 'inline-block', padding: '8px 12px', margin: '0', fontWeight: 'normal' }}
								onClick={() => track('Invoice Downloaded')}>
								<span style={{ display: 'flex', gap: '0.5rem' }}>
									Last ned original faktura (PDF){' '}
									<StyledIcon>
										<i className="fal fa-download" />
									</StyledIcon>
								</span>
							</StyledLinkButton>
						)}
					</ButtonWrapper>
				</div>
			)}
		</SingleFakturaWrapper>
	);
}
