import React from 'react';
import styled from 'styled-components';
import FormLabel from 'web/components/molecules/form/FormLabel';
import { useForm, useFormError, useFormValue } from 'web/hooks/useForm';

interface StyledInputProps {
	error?: boolean;
}
export const StyledInput = styled.input<StyledInputProps>`
	color: var(--nte-sort);
	background: var(--white);
	border: ${p => (p.error ? '1px solid var(--varsel-roed)' : 'var(--nte-border)')};
	border-radius: 3px;
	padding: 9px 16px;
	box-sizing: border-box;
	font-size: 16px;
	width: 100%;
	&:disabled {
		background: var(--nte-graa-lys-50);
	}
`;
interface TextFieldProps {
	name: string;
	label?: string;
	hintText?: string;
	error?: string;
	placeholder?: string;
	required?: boolean;
	disabled?: boolean;
	validate?: any;
	defaultValue?: string;
	pattern?: string;
	id?: string;
	type?: string;
	onChange?: (e: any) => void;
	props?: any;
}
export default function TextField({
	name,
	label,
	hintText,
	error,
	placeholder,
	required,
	disabled,
	validate,
	defaultValue,
	onChange,
	...props
}: TextFieldProps) {
	const form = useForm(name, {
		required,
		validate,
	});
	const formError = useFormError(name);
	const formValue = useFormValue(name);

	return (
		<FormLabel label={label} required={required} hintText={hintText} error={error || formError}>
			<StyledInput
				defaultValue={formValue || defaultValue}
				disabled={disabled || form?.disabled}
				error={error || formError}
				aria-required={required}
				placeholder={placeholder || label}
				name={name}
				onChange={onChange}
				{...props}
			/>
		</FormLabel>
	);
}
