import React from 'react';
import { Direction, Flex } from 'web/components/atoms/Flex';

interface FlexPageWrapperProps {
	children: any;
}
export default function FlexPageWrapper({ children }: FlexPageWrapperProps) {
	return (
		<Flex direction={Direction.column} gap="2rem" mobile={{ gap: '1rem' }}>
			{children}
		</Flex>
	);
}
