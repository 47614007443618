import React from 'react';
import { useParams } from 'react-router-dom';
import { EditMeldingReceiversModal } from 'web/components/organisms/modals/EditMeldingReceiversModal';
import { useSlaaoppBedriftLazyQuery } from 'web/components/organisms/modals/NyKundeModal.graphql-gen';
import apolloClient from 'web/lib/apolloClient';
import { CompaniesArrayFragmentDoc, useUpdateCompaniesForDocumentMutation } from 'web/pages/admin/EditMeldingPage.graphql-gen';
import { useGetDistributedDocumentQuery } from 'web/pages/admin/SingleMeldingPage.graphql-gen';
import { Bedrift } from 'web/templates/admin/MeldingerTemplate';

interface EditMeldingPageProps {
	backUrl: string;
}
export default function EditMeldingPage(props: EditMeldingPageProps) {
	const { backUrl } = props;

	const { meldingId }: { meldingId: string } = useParams() as any;
	const { data, error, loading } = useGetDistributedDocumentQuery({
		variables: {
			id: meldingId,
		},
	});

	const [updateCompaniesForMelding] = useUpdateCompaniesForDocumentMutation({ client: apolloClient });

	const [slaaoppBedriftQuery] = useSlaaoppBedriftLazyQuery();

	function updateMeldingerCache(meldingId: string, newCompanies: Bedrift[]) {
		apolloClient.cache.updateFragment(
			{
				id: `DistributedDocument:${meldingId}`,
				fragment: CompaniesArrayFragmentDoc,
				overwrite: true,
			},
			(melding: any) => {
				return {
					...melding,
					companies: newCompanies.map(company => ({ ...company, __typename: 'Company' })),
				};
			}
		);
	}

	const update = (companies: Bedrift[], meldingId: string) => {
		return updateCompaniesForMelding({
			variables: {
				update: {
					companies: companies,
					documentId: meldingId,
				},
			},
		}).then(value => {
			return updateMeldingerCache(meldingId, companies);
		});
	};

	const slaaoppBedrift = async (orgnummer: string): Promise<{ kilde?: string; navn?: string }> => {
		const {
			data: { slaaoppBedrift },
		} = await slaaoppBedriftQuery({
			variables: { orgnummer: orgnummer },
		});
		return slaaoppBedrift;
	};

	return (
		<EditMeldingReceiversModal
			melding={data?.getDistributedDocument as any}
			loading={loading}
			updateCompaniesForMelding={update}
			slaaoppBedrift={slaaoppBedrift}
			backUrl={backUrl}
		/>
	);
}
