import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Wrapper = styled.div`
	max-height: 160px;
	overflow: auto;
	background: var(--graa-bg);
	padding: 10px;
	margin-bottom: 20px;
	.kc-dropdown {
		display: none;
	}
`;

const ErrorTitle = styled.h3``;

interface DataErrorProps {
	error: any;
}
export default function DataError({ error }: DataErrorProps) {
	const { t } = useTranslation('generic');

	if (!error) {
		return null;
	}
	let isGraphqlError = false;
	let isHtml = false;
	let isJSON = false;
	let isPlainText = false;
	let fullErrorText = '';

	if (typeof error === 'string') {
		fullErrorText = error;
	}

	if (typeof error === 'object') {
		if (error.message) {
			fullErrorText = error.message;
		} else {
			fullErrorText = JSON.stringify(error);
		}
	}

	if (fullErrorText.indexOf('GraphQL error: ') > -1) {
		isGraphqlError = true;
		fullErrorText = fullErrorText.replace('GraphQL error: ', '');
	}

	if (fullErrorText.indexOf('<!DOCTYPE') === 0 || fullErrorText.indexOf('<html') === 0) {
		isHtml = true;
	} else if (fullErrorText.indexOf('{') === 0) {
		isJSON = true;
	} else {
		isPlainText = true;
	}

	return (
		<div>
			{fullErrorText && (
				<div>
					<ErrorTitle>{t('DataError.title')}</ErrorTitle>
					<Wrapper>
						{isHtml && <p>{t('DataError.message')}</p>}
						{isJSON && <pre>{fullErrorText}</pre>}
						{isPlainText && <div>{fullErrorText}</div>}
					</Wrapper>
				</div>
			)}
		</div>
	);
}
