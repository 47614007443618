import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

interface IconProps {
	size?: string;
	color?: string;
}
const Icon = styled(motion.i)<IconProps>`
	font-size: ${props => (props.size ? props.size : '4rem')};
	position: relative;
	top: 0;
	left: 0;
	color: ${props => (props.color ? props.color : 'inherit')};
`;

interface CircleLoaderProps {
	size?: string;
	color?: string;
}
export default function CircleLoader({ size, color }: CircleLoaderProps) {
	const spinTransition = {
		repeat: Infinity,
		duration: 1,
		ease: 'linear',
	};
	return <Icon size={size} color={color} className="far fa-spinner-third" animate={{ rotate: 360 }} transition={spinTransition} />;
}
