import { format } from 'date-fns';
import addDays from 'date-fns/addDays';
import { ElhubAnlegg } from 'generated/graphql-types';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Button from 'web/components/atoms/Button';
import Center from 'web/components/atoms/Center';
import Left from 'web/components/atoms/Left';
import Push from 'web/components/atoms/Push';
import DataError from 'web/components/molecules/form/DataError';
import DatepickerField from 'web/components/molecules/form/DatepickerField';
import FormLabel from 'web/components/molecules/form/FormLabel';
import { StyledSelect } from 'web/components/molecules/form/SelectField';
import { TariffVelger } from 'web/components/molecules/form/TariffVelger';
import TextField from 'web/components/molecules/form/TextField';
import { Bedriftinfo, MeldInnAnleggModalState } from 'web/components/organisms/modals/MeldInnAnleggModal';
import { AnleggInnmeldingsskjemaStepProps } from 'web/components/organisms/modals/MeldInnAnleggModal/Step1';
import {
	useHentKunderForBedriftFraKundesystemQuery,
	useMeldInnAnleggForBedriftMutation,
	useMeldInnAnleggMutation,
} from 'web/components/organisms/modals/MeldInnAnleggModal/Step4.graphql-gen';
import Form, { useForm, useFormError, useFormValue } from 'web/hooks/useForm';
import { Tenant } from 'web/models/Tenant';

interface AnleggsinnmeldingKunde {
	fornavn: string;
	etternavn: string;
	telefonnummer: string;
	epost: string;
	gate: string;
	nummer: string;
	postnummer: string;
	poststed: string;
}
interface Anleggsinnmelding {
	orgId: string;
	malepunktId: string;
	tariffId: string;
	oppstartsdato: string;
	fakturamerke: string;
	kunde: AnleggsinnmeldingKunde;
}

interface KundesystemBedrift {
	orgnummer: string;
	kundeId?: number;
	navn?: string;
	kontaktinfo?: {
		telefonnummer?: string;
		epost?: string;
		adresse1?: string;
		adresse2?: string;
		postnummer?: string;
		poststed?: string;
	};
}
interface FormErrors {
	fakturamerke?: string;
}

function meldInnElhubanlegg(
	state: MeldInnAnleggModalState,
	tenant: Tenant,
	bedriftinfo: Bedriftinfo,
	formData: any,
	meldInnAnleggMutation: (a: any) => Promise<any>
): Promise<any> {
	const elhubAnlegg = state.elhubAnlegg;
	const { kontakt, kontaktEpost } = bedriftinfo;
	const [fornavn, etternavn] = kontakt.split(' ');

	// TODO mye kan flyttes til backend
	const anleggsinnmelding = {
		orgId: tenant.id,
		malepunktId: elhubAnlegg.maalepunktId,
		oppstartsdato: format(new Date(formData.oppstartsdato), 'yyyy-MM-dd'),
		fakturamerke: formData.fakturamerke,
		tariffId: formData.tariffId,
	} as Anleggsinnmelding;

	return meldInnAnleggMutation({
		variables: { anleggsinnmelding },
	});
}

function meldInnAnleggManuell(
	state: MeldInnAnleggModalState,
	elhubAnlegg: ElhubAnlegg,
	tenant: Tenant,
	formData: any,
	meldInnManuell: (a: any) => Promise<any>
) {
	const manueltAnlegg = state.manueltAnlegg;
	const anleggsinnmelding = {
		anlegg: {
			maalepunktId: elhubAnlegg?.maalepunktId,
			maalernummer: elhubAnlegg?.maalernummer,
			avlesning: manueltAnlegg?.avlesning || elhubAnlegg?.avlesning,
			etasje: manueltAnlegg?.etasje || elhubAnlegg?.etasje,
			gateadresse: manueltAnlegg?.gateadresse || elhubAnlegg?.gateadresse,
			poststed: manueltAnlegg?.poststed || `${elhubAnlegg?.postnummer} ${elhubAnlegg?.poststed}`,
		},
		oppstartsdato: format(new Date(formData.oppstartsdato), 'yyyy-MM-dd'),
		fakturamerke: formData.fakturamerke,
		tariffId: formData.tariffId,
	};

	return meldInnManuell({
		variables: { anleggsinnmelding, orgid: tenant?.id },
	});
}

export default function AnleggInnmeldingsskjemaStep4({ tenant, state, onFinish }: AnleggInnmeldingsskjemaStepProps) {
	const [meldInnAnleggMutation, { error, loading }] = useMeldInnAnleggForBedriftMutation();
	const [meldInnAnleggManuellMutation, { error: errorManuell, loading: loadingManuell }] = useMeldInnAnleggMutation();
	const {
		loading: kunderLoading,
		error: kunderError,
		data: { bedriftinfo, tarifferKundesystem } = { bedriftinfo: null, tarifferKundesystem: [] },
	} = useHentKunderForBedriftFraKundesystemQuery({
		variables: {
			orgId: tenant.id,
		},
		// fetchPolicy: 'cache-and-network',
	});

	const [errors, setErrors] = useState<FormErrors>({});
	const [fakturaMerkeCount, setFakturaMerkeCount] = useState(0);

	const godkjenteTariffer = bedriftinfo?.godkjenteTariffer?.map(id => ({ tariffId: id, beskrivelse: id }));
	const valgbareTariffer = godkjenteTariffer?.length ? godkjenteTariffer : tarifferKundesystem;

	const earliestStartDate = (state.elhubAnlegg || state.manueltAnlegg).avlesning == 'Automatisk' ? 2 : 4;
	return (
		<>
			<Form
				loading={loading || kunderLoading || loadingManuell}
				onSubmit={async (formData: any, e: any) => {
					let hasError = false;
					let newErrors = {} as FormErrors;

					if (!fakturaMerkeCount) {
						newErrors.fakturamerke = 'Vennligst skriv inn fakturamerket';
						hasError = true;
					}

					if (hasError) {
						setErrors(newErrors);
						return;
					}

					(state.elhubAnlegg && godkjenteTariffer?.length
						? meldInnElhubanlegg(state, tenant, bedriftinfo, formData, meldInnAnleggMutation)
						: meldInnAnleggManuell(state, state.elhubAnlegg, tenant, formData, meldInnAnleggManuellMutation)
					)
						.then(result => {
							toast('Registreringen har blitt sendt inn og vil bli behandlet snarest! Det kan ta noen dager før anlegget er synlig.', {
								position: toast.POSITION.BOTTOM_CENTER,
							});
							onFinish();
						})
						.catch(error => {
							// The error is propagated through useMutation, so catch here is just to silence the console log
						});
				}}>
				<Center>Her kan du fylle inn informasjon om prosjektet, og tariff</Center>
				<Push />

				<DataError error={error || kunderError || errorManuell} />

				<TextField
					required
					error={errors.fakturamerke}
					label="Fakturamerke"
					hintText="Fakturamerke legges på faktura slik at postering i regnskapet kan automatiseres. Dette kan være prosjektnummer, navn eller en annen referanse."
					placeholder="Fakturamerke"
					name="fakturamerke"
					onChange={(e: any) => {
						setFakturaMerkeCount(e.target.value?.length);
						setErrors({ ...errors, fakturamerke: null });
					}}
				/>

				<TariffVelger required tariffer={valgbareTariffer} name="tariffId" error={false} />

				<DatepickerField required label="Oppstartsdato" name="oppstartsdato" minDate={addDays(new Date(), earliestStartDate)} />

				<Left>Det kan ta noen dager før anlegget er synlig i anleggslisten</Left>

				<Push height="1rem" />

				<Button>Registrer anlegg</Button>
			</Form>
		</>
	);
}

interface KundevelgerProps {
	kunder: KundesystemBedrift[];
	onChange: (value: any) => void;
}
function Kundevelger({ kunder, onChange }: KundevelgerProps) {
	const name = 'kunde';
	const form = useForm(name) as any;
	const formValue = useFormValue(name);
	const formError = useFormError(name);
	const selected = kunder.find(kunde => formValue === kunde.kundeId || kunder?.length === 1);

	return (
		<FormLabel label="Kundenummer" error={null} required={true}>
			<StyledSelect name={name} value={selected?.kundeId} disabled={form?.disabled || kunder?.length === 0} required={true} onChange={onChange}>
				{kunder.map((kunde, i) => (
					<option value={kunde.kundeId} key={i}>
						{kunde.kundeId} {kunde.navn} {kunde.kontaktinfo.epost} {kunde.kontaktinfo.adresse1} {kunde.kontaktinfo.adresse2}
					</option>
				))}
			</StyledSelect>
		</FormLabel>
	);
}
