import React from 'react';
import { Redirect } from 'react-router-dom';
import { AuthAdmin, NotAuthAdmin } from 'web/hooks/useAuthorization';

/**
 * This component is visible when logged in and the user has not selected tenant or admin panel.
 * @name LoggedIn
 */
export default function LandingPage() {
	return (
		<>
			<NotAuthAdmin>
				<Redirect to="/bedrifter/" />
			</NotAuthAdmin>
			<AuthAdmin>
				<Redirect to="/admin/kunder/" />
			</AuthAdmin>
		</>
	);
}
