import { endOfMonth, format, startOfMonth, subYears } from 'date-fns';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import useMineBedrifter from 'web/hooks/useMineBedrifter';
import { TenantProvider } from 'web/hooks/useTenant';
import { useHentHeleBedriftTenantQuery } from 'web/hooks/useTenant.provider.graphql-gen';
import { Anlegg } from 'web/models/Anlegg';
import ErrorTemplate from 'web/templates/ErrorTemplate';

export function processAnlegg(maaler: any): Anlegg {
	const { salgsordrer, ...anlegg } = maaler;
	anlegg.fakturamerke = salgsordrer?.[0]?.fakturamerke;

	const powerSalgsordre =
		salgsordrer
			?.filter((salgsordre: any) => salgsordre.virksomhet == '2')
			.sort((a: any, b: any) => {
				if (a.tilDato < b.tilDato) {
					return 1;
				}
				if (a.tilDato > b.tilDato) {
					return -1;
				}
				return 0;
			}) ?? [];

	const currentTariff = Array.from(powerSalgsordre[0]?.tariffVersjoner || []).sort((a: any, b: any) => {
		if (a.tildato < b.tildato) {
			return 1;
		}
		if (a.tildato > b.tildato) {
			return -1;
		}
		return 0;
	})[0] as any;

	let product = currentTariff?.produkt ?? null;

	return {
		...maaler,
		status: anlegg.anleggsstatus || powerSalgsordre[0]?.status,
		product,
		salgsordrer: powerSalgsordre,
		fraDato: powerSalgsordre[0]?.fraDato,
		tilDato: powerSalgsordre[0]?.tilDato,
		avregnetTil: powerSalgsordre[0]?.avregnetTil,
		aarsforbruk: maaler?.aarsforbruk,
		aarskostnad: currentTariff?.aarskostnad, // No longer available from uc, always null
	};
}

export default function TenantLayout({ children }: any) {
	const { t } = useTranslation('generic');
	const { bedriftId } = useParams() as any;
	const { bedrifter } = useMineBedrifter();

	const {
		loading,
		error,
		data: { bedrift } = { bedrift: null },
		refetch: refetchTenant,
	} = useHentHeleBedriftTenantQuery({
		variables: {
			orgid: bedriftId,
			sisteAarFraDato: format(startOfMonth(subYears(new Date(), 1)), 'yyyy-MM-dd'),
			sisteToAarFraDato: format(startOfMonth(subYears(new Date(), 2)), 'yyyy-MM-dd'),
			tilDato: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
		},
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'ignore',
	});

	const lastetBedrift = bedrifter.find((bedrift: any) => {
		return bedrift.id === bedriftId;
	});

	if (loading && (!bedrift || bedrift.id !== bedriftId)) {
		return <LoadingOverlay loading={loading && (!bedrift || bedrift.id !== bedriftId)} text={`Henter ${lastetBedrift?.navn || 'bedrift'}`} isTemplate />;
	}

	if (!bedrift) {
		return <ErrorTemplate title={t('Tenant.error.title')} message={<span dangerouslySetInnerHTML={{ __html: t('Tenant.error.message') }} />} />;
	}
	//stringify and parse do do deep copy
	const processedBedrift = {
		...bedrift,
		anlegg: bedrift.anlegg?.map((anlegg: any) => processAnlegg(JSON.parse(JSON.stringify(anlegg)))) ?? [],
		refetchTenant,
	};

	return (
		<TenantProvider tenant={processedBedrift}>
			<Suspense fallback={<LoadingOverlay loading={true} isTemplate />}>{children}</Suspense>
		</TenantProvider>
	);
}
