import { addMinutes, format, isDate } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import { Retning } from 'generated/graphql-types';
import { useRef } from 'react';
import { useHentEffekttopperQuery } from 'web/hooks/SingleAnlegg/useForbruksTopp.graphql-gen';
import { useGraphProps } from 'web/hooks/useKostnad';
import useNumbers from 'web/hooks/useNumbers';
import { useTenant } from 'web/hooks/useTenant';
import {
	GrafDataElement,
	Opploesninger,
	fyllInnManglendeVerdierForbruk,
	getDatoFormat,
	getPeriodeFromOpploesning,
	getShortDatoFormat,
} from 'web/lib/dates.utils';

interface useForbruksToppProps extends useGraphProps {}
export default function useForbruksTopp(props: useForbruksToppProps) {
	const tenant = useTenant();
	const n = useNumbers();

	const forbruksToppDatoRange = getPeriodeFromOpploesning(props.selectedDate, Opploesninger.dag);
	const forbruksToppHoyeste = useRef({ mengde: 0, dato: null });

	const forbruksToppChartRef = useRef<any>('');

	const { loading: forbruksToppLoading } = useHentEffekttopperQuery({
		variables: {
			orgid: tenant?.id,
			fraDato: format(new Date(forbruksToppDatoRange.fra), 'yyyy-MM-dd'),
			tilDato: format(addMinutes(new Date(forbruksToppDatoRange.til), 1), 'yyyy-MM-dd'),
			maalere: props.maalere,
			retning: Retning.forbruk,
			opploesning: Opploesninger.time,
		},
		onError: error => {
			console.error(error);
		},
		onCompleted: data => {
			forbruksToppHoyeste.current.mengde = 0;
			const verdier = data?.bedrift?.forbruk?.verdier || [];

			for (let i = 0; i < verdier?.length; i++) {
				if (forbruksToppHoyeste.current.mengde < verdier[i].mengde) {
					forbruksToppHoyeste.current.mengde = verdier[i].mengde;
					forbruksToppHoyeste.current.dato = new Date(verdier[i].fraTid);
				}
			}

			const fullstendigeVerdier = fyllInnManglendeVerdierForbruk({
				data: verdier,
				fra: forbruksToppDatoRange.fra,
				til: forbruksToppDatoRange.til,
				opploesning: Opploesninger.time,
			});

			const forbruk = fullstendigeVerdier.map((element: GrafDataElement) => element.importert - element.eksportert);
			const xAxis = fullstendigeVerdier.map((element: GrafDataElement) => new Date(element.fraTid));

			forbruksToppChartRef.current.load?.({
				unload: true,
				x: 'x',
				columns: [
					['x', ...xAxis],
					['Forbruk', ...forbruk],
				],
			} as any);

			forbruksToppChartRef.current.ygrids?.([
				{
					value: forbruksToppHoyeste.current.mengde,
					text: `Forbrukstopp: ${n(forbruksToppHoyeste.current.mengde)} kWh ${
						forbruksToppHoyeste.current.dato
							? ', ' +
							  format(forbruksToppHoyeste.current.dato, 'd. MMMM', {
									locale: nbLocale,
							  })
							: ''
					}`,
					class: 'redLineBlackTekst',
				},
			]);
		},
	});

	return {
		ref: forbruksToppChartRef,
		loading: forbruksToppLoading,
		data: {
			data: {
				x: 'x',
				columns: [['x'], ['Forbruk']],
				type: 'area-step',
				onclick: (event: any) => {},
			},
			zoom: {
				enabled: props.graphZoom,
			},
			legend: {
				show: false,
			},
			axis: {
				x: {
					type: 'timeseries',
					tick: {
						format: (x: any) => {
							if (!isDate(x)) return null;
							return format(new Date(x), props.useShortXLabel ? getShortDatoFormat(Opploesninger.dag) : getDatoFormat(Opploesninger.dag), {
								locale: nbLocale,
							});
						},
					},
				},
				y: {
					label: 'kWh',
					tick: {
						format: (d: number) => {
							return n(d, false, '0,0.0');
						},
					},
				},
			},
			grid: {
				x: {
					show: true,
				},
				y: {
					show: true,
				},
			},
			tooltip: {
				format: {
					title: (value: any) => {
						if (!isDate(value)) return null;
						return format(new Date(value), "d. MMMM, 'kl.' HH:mm", { locale: nbLocale });
					},
					value: (value: any) => {
						return `${n(value)} kWh`;
					},
				},
			},
		},
	};
}
