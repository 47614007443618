import React from 'react';
import styled from 'styled-components';

const StyledModulIcon = styled.i`
	font-size: 2.375rem;
	margin-top: 1rem;
	color: var(--nte-blaa-logo);
`;

const icons = {
	Systemadmin: 'far fa-cog',
	Bedriftsadmin: 'far fa-user-cog',
	Strøm: 'far fa-plug',
	Klimaregnskap: 'far fa-hand-holding-seedling',
};

interface ModulIconProps {
	name?: string;
	icon?: string;
	rest?: any;
}
export default function ModulIcon({ name, icon, ...rest }: ModulIconProps) {
	const iconName = icon || icons[name as keyof typeof icons] || 'analytics';

	return <StyledModulIcon className={`far fa-${iconName}`} {...rest} />;
}
