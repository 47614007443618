import { Bedrift } from 'generated/graphql-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FlexPageWrapper from 'web/components/atoms/FlexPageWrapper';
import Panel from 'web/components/atoms/Panel';
import PanelTitle from 'web/components/atoms/PanelTitle';
import Push from 'web/components/atoms/Push';
import MobilGrafikk from 'web/components/atoms/svgs/MobilGrafikk';
import ArticlePanel, { ArticlePanelProps } from 'web/components/molecules/ArticlePanel';
import DropdownButton, { DropdownAlign } from 'web/components/molecules/DropdownButton';
import FilterBarSelect from 'web/components/molecules/filter/FilterBarSelect';
import HendelserList from 'web/components/molecules/HendelserList';
import InfoBox, { INFOBOX_COLOR } from 'web/components/molecules/InfoBox';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import SimpleGraph from 'web/components/molecules/SimpleGraph';
import { ValueInfoPanel, ValueInfoPanelProps } from 'web/components/molecules/ValueInfoPanel';
import WideValueInfoPanel, { WideValueInfoPanelProps } from 'web/components/molecules/WideValueInfoPanel';
import { MobileDesktopSwitcher } from 'web/components/organisms/MobileDesktopSwitcher';
import { device } from 'web/GlobalStyling';
import { useTracking } from 'web/hooks/useTracking';
import { SoneVelger } from 'web/templates/SpotprisTemplate';

const Wrapper = styled.div`
	@media ${device.mobile} {
		margin-top: -3rem;
	}
`;

const SkiesWrapper = styled.div`
	display: none;
	margin-top: 1rem;
	margin-bottom: 3rem;

	@media ${device.tablet} {
		display: block;
	}
`;

const BedriftSelectWrapper = styled.div`
	display: none;
	margin-bottom: -1rem;
	order: 1;
	@media ${device.tablet} {
		display: flex;
	}
`;

const SingleBedriftLabel = styled.label`
	display: block;
	padding: 12px 0;
	margin: 0px 6px;

	color: var(--nte-graa-mork);
	font-size: 0.875rem;
	line-height: 1.5;
`;

const StyledBedriftSelectDropdown = styled(DropdownButton)`
	padding: 0.625rem 0;
	background: transparent;
	border: none;
	box-shadow: none;
`;

const BedriftButton = styled.button`
	width: 100%;
	word-break: break-word;
	text-align: left;
	display: flex;
	padding: 0.75rem;
	background: none;
	border: none;
	border-bottom: 1px solid var(--nte-graa-lys);
`;

const BedriftSelectButtonLabel = styled.span`
	max-height: 2.5rem;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`;

const InfoBoxWrapper = styled.div`
	order: 3;
	@media ${device.mobile} {
		order: 4;
	}
`;

const SpotprisWrapper = styled.div`
	order: 4;
	@media ${device.mobile} {
		order: 3;
	}
`;

const ForbrukGrafWrapper = styled.div`
	order: 5;

	@media ${device.mobile} {
		display: none;
	}
`;
const ArticleWrapper = styled.div`
	order: 5;
`;
const EmptyArticle = styled(Panel)`
	min-height: 182px;
`;

const NyheterHeader = styled.h4`
	font-weight: normal;
	font-size: 0.875rem;
	line-height: 1rem;
	color: var(--nte-sort);
	margin: 0.5rem 0 0.75rem 0;
	display: none;
	@media ${device.tablet} {
		display: block;
	}
`;

const ValueInfoPanelWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	max-width: 100%;
	gap: 1rem;
	order: 2;

	@media ${device.mobileS} {
		gap: 0.5rem;
	}
`;

interface HovedsidenTemplateProps {
	bedriftsNavn?: string;
	bedrifter?: Bedrift[];
	onClickBedrift?: (bedrift: Bedrift) => void;
	hasStroemAccess: boolean;
	valueInfoPanels?: ValueInfoPanelProps[];
	spotprisData: any;
	spotprisInfoPanel: WideValueInfoPanelProps;
	chartData?: c3.ChartConfiguration;
	article?: ArticlePanelProps;
	infoBoxAvtalerIsClosed?: boolean;
	setInfoBoxAvtalerIsClosed?: (infoBoxAvtalerIsClosed: boolean) => void;
}
export default function HovedsidenTemplate({
	bedriftsNavn,
	bedrifter,
	onClickBedrift,
	hasStroemAccess = true,
	spotprisData,
	spotprisInfoPanel,
	chartData,
	article,
	infoBoxAvtalerIsClosed,
	setInfoBoxAvtalerIsClosed,
	valueInfoPanels = [
		{
			value: '',
			unit: '',
			description: '',
			icon: <span />,
		},
		{
			value: '',
			unit: '',
			description: '',
			icon: <span />,
		},
		{
			value: '',
			unit: '',
			description: '',
			icon: <span />,
		},
	],
}: HovedsidenTemplateProps) {
	const { t } = useTranslation('forbruk');
	const { track } = useTracking();

	return (
		<Wrapper>
			<FlexPageWrapper>
				<SkiesWrapper>
					<MobilGrafikk
						style={{
							position: 'absolute',
							right: '12px',
							pointerEvents: 'none',
						}}
					/>
				</SkiesWrapper>

				<BedriftSelectWrapper>
					{(bedrifter?.length > 1 && (
						<StyledBedriftSelectDropdown
							label={<BedriftSelectButtonLabel>{bedriftsNavn}</BedriftSelectButtonLabel>}
							align={DropdownAlign.LEFT}
							dropdownWrapperStyle={{
								top: '2.5rem',
								width: 'calc(100vw - 8rem)',
							}}>
							{bedrifter?.map((bedrift: Bedrift, i: number) => (
								<BedriftButton
									key={i}
									onClick={() => {
										onClickBedrift(bedrift);
									}}>
									{bedrift.navn}
								</BedriftButton>
							))}
						</StyledBedriftSelectDropdown>
					)) ||
						(bedrifter?.length == 1 ? <SingleBedriftLabel>{bedrifter[0].navn}</SingleBedriftLabel> : <></>)}
				</BedriftSelectWrapper>

				{hasStroemAccess && (
					<ValueInfoPanelWrapper>
						{valueInfoPanels?.map((infoPanelprops, index) => (
							<ValueInfoPanel key={index} {...infoPanelprops} />
						))}
					</ValueInfoPanelWrapper>
				)}

				{!hasStroemAccess && !infoBoxAvtalerIsClosed && (
					<InfoBoxWrapper>
						<InfoBox
							color={INFOBOX_COLOR.green}
							style={{ width: 'max-content', margin: 'auto' }}
							onButtonClicked={() => {
								window.location.href = 'https://nte.no/bedrift/strom-og-energitjenester/stromavtaler';
							}}
							buttonText={'Se våre strømavtaler'}
							setInfoBoxIsClosed={setInfoBoxAvtalerIsClosed}>
							Du har ikke en aktiv strømavtale og informasjonen du har tilgjengelig vil være begrenset
						</InfoBox>
					</InfoBoxWrapper>
				)}

				{spotprisData && (
					<SpotprisWrapper>
						<MobileDesktopSwitcher
							desktop={() => (
								<Panel
									to={spotprisInfoPanel.to}
									onClick={spotprisInfoPanel.onClick}
									style={{ display: 'inline-block', width: '100%' }}
									arrowStyle={{ position: 'absolute', right: '2rem', top: '50%' }}>
									<LoadingOverlay loading={spotprisData.loading} />
									{spotprisData.energisone && (
										<>
											<div style={{ display: 'flex', justifyContent: 'space-between' }}>
												<PanelTitle>
													Spotpris for {t('Spotpris.energisone.' + spotprisData.energisone, spotprisData.energisone)} i dag
												</PanelTitle>
												<SoneVelger
													onClick={e => {
														e.preventDefault();
													}}>
													<span style={{ fontWeight: 'bold' }}>Velg prisområde</span>
													<FilterBarSelect {...spotprisData.soneVelgerData} dropdownAlign={DropdownAlign.RIGHT} />
												</SoneVelger>
											</div>
											<Push height="0.5rem" />
											<SimpleGraph
												loadingData={spotprisData.loading}
												style={{
													opacity: spotprisData.chartData ? '1' : '0',
													height: '240px',
													width: '95%',
												}}
												chartRef={spotprisData.chartRef}
												chart={
													{
														...spotprisData.chartData,
													} || {
														data: {
															columns: [],
															type: 'area-step',
														},
													}
												}
											/>

											{(spotprisData.numOfAktiveVarsler && (
												<HendelserList
													numOfAktiveVarsler={spotprisData.numOfAktiveVarsler}
													aktiveVarsler={spotprisData.aktiveVarsler}
													selectedDate={new Date()}
												/>
											)) || <></>}
										</>
									)}
								</Panel>
							)}
							mobile={() => (
								<WideValueInfoPanel {...spotprisInfoPanel}>
									<LoadingOverlay loading={spotprisData.loading} />
									{spotprisData.energisone && (
										<SimpleGraph
											loadingData={spotprisData.loading}
											style={{
												opacity: spotprisData.chartData ? '1' : '0',
												height: '120px',
												width: '100%',
											}}
											chartRef={spotprisData.chartRef}
											chart={
												{
													...spotprisData.chartData,
													axis: {
														x: {
															show: false,
														},
														y: {
															show: false,
														},
													},
													grid: {
														x: {
															show: false,
														},
														y: {
															show: false,
														},
													},
													legend: {
														hide: true,
													},
													padding: { right: -8, bottom: -28 },
												} || {
													data: {
														columns: [],
														type: 'area-step',
													},
												}
											}
										/>
									)}
								</WideValueInfoPanel>
							)}
						/>
					</SpotprisWrapper>
				)}
				{hasStroemAccess && (
					<ForbrukGrafWrapper>
						<Panel withBorder={true}>
							<PanelTitle
								style={{
									opacity: (chartData && '1') || '0',
								}}>
								Forbruk
							</PanelTitle>
							<Push height="2rem" />
							<SimpleGraph
								loadingData={false} // Need to get a forbruk.loading here
								style={{
									opacity: (chartData && '1') || '0',
								}}
								chart={
									chartData || {
										data: {
											columns: [],
											type: 'bar',
										},
									}
								}
							/>
						</Panel>
					</ForbrukGrafWrapper>
				)}
				<ArticleWrapper>
					<NyheterHeader>Nyheter</NyheterHeader>
					{(article && (
						<ArticlePanel {...article} onClick={() => track('Article opened', { articleTitle: article.tittel, label: 'Åpnet artikkel' })} />
					)) || <EmptyArticle />}
				</ArticleWrapper>
			</FlexPageWrapper>
		</Wrapper>
	);
}
