// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinnBrukereQueryVariables = Types.Exact<{
  query: Types.Scalars['String'];
}>;


export type FinnBrukereQuery = { brukere?: Array<{ id?: string, fornavn?: string, etternavn?: string, epost?: string }> };

export type LeggTilMedlemMutationVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
  brukerId: Types.Scalars['ID'];
}>;


export type LeggTilMedlemMutation = { leggTilMedlem?: { orgnummer?: string } };


export const FinnBrukereDocument = gql`
    query finnBrukere($query: String!) {
  brukere: finnBrukere(query: $query) {
    id
    fornavn
    etternavn
    epost
  }
}
    `;

/**
 * __useFinnBrukereQuery__
 *
 * To run a query within a React component, call `useFinnBrukereQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinnBrukereQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinnBrukereQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useFinnBrukereQuery(baseOptions: Apollo.QueryHookOptions<FinnBrukereQuery, FinnBrukereQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FinnBrukereQuery, FinnBrukereQueryVariables>(FinnBrukereDocument, options);
      }
export function useFinnBrukereLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinnBrukereQuery, FinnBrukereQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FinnBrukereQuery, FinnBrukereQueryVariables>(FinnBrukereDocument, options);
        }
export type FinnBrukereQueryHookResult = ReturnType<typeof useFinnBrukereQuery>;
export type FinnBrukereLazyQueryHookResult = ReturnType<typeof useFinnBrukereLazyQuery>;
export type FinnBrukereQueryResult = Apollo.QueryResult<FinnBrukereQuery, FinnBrukereQueryVariables>;
export const LeggTilMedlemDocument = gql`
    mutation leggTilMedlem($orgid: ID!, $brukerId: ID!) {
  leggTilMedlem(orgid: $orgid, brukerId: $brukerId) {
    orgnummer
  }
}
    `;
export type LeggTilMedlemMutationFn = Apollo.MutationFunction<LeggTilMedlemMutation, LeggTilMedlemMutationVariables>;

/**
 * __useLeggTilMedlemMutation__
 *
 * To run a mutation, you first call `useLeggTilMedlemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeggTilMedlemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leggTilMedlemMutation, { data, loading, error }] = useLeggTilMedlemMutation({
 *   variables: {
 *      orgid: // value for 'orgid'
 *      brukerId: // value for 'brukerId'
 *   },
 * });
 */
export function useLeggTilMedlemMutation(baseOptions?: Apollo.MutationHookOptions<LeggTilMedlemMutation, LeggTilMedlemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeggTilMedlemMutation, LeggTilMedlemMutationVariables>(LeggTilMedlemDocument, options);
      }
export type LeggTilMedlemMutationHookResult = ReturnType<typeof useLeggTilMedlemMutation>;
export type LeggTilMedlemMutationResult = Apollo.MutationResult<LeggTilMedlemMutation>;
export type LeggTilMedlemMutationOptions = Apollo.BaseMutationOptions<LeggTilMedlemMutation, LeggTilMedlemMutationVariables>;