import { format, isBefore, subMonths } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import { Faktura, InvoiceStatus as FakturaStatus } from 'generated/graphql-types';
import { toCapitalFirstLetter } from 'web/lib/text.utils';

export function shouldGetUnknownStatus(faktura: Faktura) {
	if (!faktura) {
		return true;
	}
	if (faktura.fakturastatus !== FakturaStatus.PAID && isBefore(new Date(faktura.fakturadato), new Date(2024, 1, 8))) {
		// The last Customer-faktura observed in prod is Veidekke Entreprenør, invoiced at 7th feb
		return true;
	}
	return false;
}

export function getFakturertString(fakturadato: Date) {
	if (!fakturadato) {
		return '';
	}
	return toCapitalFirstLetter(
		format(subMonths(new Date(fakturadato), 1), new Date().getFullYear() > subMonths(new Date(fakturadato), 1).getFullYear() ? 'MMMM yyyy' : 'MMMM', {
			locale: nbLocale,
		})
	);
}

export function getFakturaStatusString(faktura: Faktura) {
	if (shouldGetUnknownStatus(faktura)) {
		return 'Avventer status';
	}
	switch (faktura.fakturastatus) {
		case FakturaStatus.PAID:
			return 'Betalt';
		case FakturaStatus.DUE:
			return 'Frist ' + format(new Date(faktura.forfallsdato), 'd. MMMM', { locale: nbLocale });
		case FakturaStatus.OVERDUE:
			return 'Forfalte ' + format(new Date(faktura.forfallsdato), 'd. MMMM', { locale: nbLocale });
		case FakturaStatus.DUNNING:
			return 'Til inkasso';
		default:
			return 'Ukjent';
	}
}

export function getFakturaStatusStringShort(faktura: Faktura) {
	if (shouldGetUnknownStatus(faktura)) {
		return 'Avventer status';
	}
	switch (faktura.fakturastatus) {
		case FakturaStatus.PAID:
			return 'Betalt';
		case FakturaStatus.DUE:
			return 'Ubetalt';
		case FakturaStatus.OVERDUE:
			return 'Forfalt';
		case FakturaStatus.DUNNING:
			return 'Til inkasso';
		default:
			return 'Ukjent';
	}
}
