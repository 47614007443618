import { Faktura } from 'generated/graphql-types';
import React from 'react';
import FlexPageWrapper from 'web/components/atoms/FlexPageWrapper';
import PageTitle, { PageTitleButton } from 'web/components/atoms/PageTitle';
import DatePicker, { DatePickerProps } from 'web/components/molecules/DatePicker';
import FakturaListDesktop from 'web/components/organisms/FakturaListDesktop';
import FakturaListMobile from 'web/components/organisms/FakturaListMobile';
import FilterBar from 'web/components/organisms/FilterBar';
import { MobileDesktopSwitcher } from 'web/components/organisms/MobileDesktopSwitcher';
import MobileFilter from 'web/components/organisms/MobileFilter';
import { FilterDataArray } from 'web/lib/filter.utils';

interface FakturaTemplateProps {
	loading?: boolean;
	loadingFetchMore?: boolean;
	fakturaer: Faktura[];
	filterData: FilterDataArray;
	datePickerData: DatePickerProps;
	onClickExport?: (e: any) => void;
	displayScrollToTopButton?: boolean;
	onClickGoToTop?: () => void;
}

export default function FakturaTemplate({
	loading,
	loadingFetchMore,
	fakturaer,
	filterData,
	datePickerData,
	onClickExport,
	displayScrollToTopButton,
	onClickGoToTop,
}: FakturaTemplateProps) {
	return (
		<FlexPageWrapper>
			<MobileDesktopSwitcher
				desktop={() => (
					<>
						<PageTitle>
							Faktura
							<PageTitleButton
								type="button"
								onClick={e => {
									onClickExport(e);
								}}>
								<i className="fa fa-file-csv" /> Eksporter faktura
							</PageTitleButton>
						</PageTitle>
						<FilterBar filterData={filterData} />
						<DatePicker {...datePickerData} />
						<FakturaListDesktop loading={loading} loadingFetchMore={loadingFetchMore} fakturaer={fakturaer} />
					</>
				)}
				mobile={() => (
					<>
						<PageTitle>Faktura</PageTitle>
						<MobileFilter filterData={filterData} mobileFilterId="fakturaFilter" closeButtonText="Vis fakturaer" />
						<DatePicker {...datePickerData} />
						<FakturaListMobile
							loading={loading}
							loadingFetchMore={loadingFetchMore}
							fakturaer={fakturaer}
							displayScrollToTopButton={displayScrollToTopButton}
							onClickGoToTop={onClickGoToTop}
						/>
					</>
				)}
			/>
		</FlexPageWrapper>
	);
}
