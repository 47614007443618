import React from 'react';
import styled from 'styled-components';

export const DetailListGroup = styled.div`
	margin-bottom: 11px;
`;

export const DetailListElementWrapper = styled.div`
	margin-bottom: 3px;
`;

function DetailListElement({ value, label, ...rest }: { value: any; label: string }) {
	return (
		<DetailListElementWrapper {...rest}>
			<span style={{ fontWeight: 'bold' }}>{label}:</span> {value}
		</DetailListElementWrapper>
	);
}

export default DetailListElement;
