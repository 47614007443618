import styled from 'styled-components';
import { device } from 'web/GlobalStyling';

export enum Justify {
	start = 'start',
	end = 'end',
	flexStart = 'flex-start',
	flexEnd = 'flex-end',
	center = 'center',
	left = 'left',
	right = 'right',
	normal = 'normal',
	baseline = 'baseline',
	spaceBetween = 'space-between',
	spaceAround = 'space-around',
	spaceEvenly = 'space-evenly',
	stretch = 'stretch',
}

export enum Direction {
	row = 'row',
	rowReverse = 'row-reverse',
	column = 'column',
	columnReverse = 'column-reverse',
}

type FlexBaseProps = {
	justifyContent?: Justify;
	alignContent?: Justify;
	alignItems?: Justify;
	direction?: Direction;
	gap?: string;
};

type FlexProps = FlexBaseProps & {
	mobile?: FlexBaseProps;
	tablet?: FlexBaseProps;
	desktop?: FlexBaseProps;
};

const generateStyles = (props: FlexBaseProps) => {
	if (!props) {
		return;
	}
	return `
		${props.direction ? `flex-direction: ${props.direction};` : ``};
		${props.justifyContent ? `justify-content: ${props.justifyContent};` : ``};
		${props.alignContent ? `align-content: ${props.alignContent};` : ``};
		${props.gap ? `gap: ${props.gap};` : ``};
	`;
};
export const Flex = styled.div<FlexProps>`
	display: flex;
	max-width: 1200px;
	${props => generateStyles(props)}
	@media ${device.desktop} {
		${props => generateStyles(props.desktop)}
	}

	@media ${device.tablet} {
		${props => generateStyles(props.tablet)}
	}

	@media ${device.mobile} {
		${props => generateStyles(props.mobile)}
	}
`;
