import { format } from 'date-fns';
import { ExportStatuses, useExportStatus } from 'web/hooks/useEksportStatus';
import { useHentKostnadLazyQuery } from 'web/hooks/useKostnad.graphql-gen';
import { useTracking } from 'web/hooks/useTracking';
import asyncWait from 'web/lib/asyncWait';
import { utils, writeFile } from 'xlsx';

interface useKostnadsEksportProps {
	orgid: string;
	fraDato: string;
	tilDato: string;
	maalere: string[];
	fakturamerker: string[];
	naeringskoder: string[];
}

export default function useKostnadsEksport() {
	const { track } = useTracking();
	const { state, setState } = useExportStatus();
	const [hentKostnad] = useHentKostnadLazyQuery();
	async function eksporter({ orgid, fraDato, tilDato, maalere, fakturamerker, naeringskoder }: useKostnadsEksportProps) {
		setState({ ...state, status: ExportStatuses.DOWNLOADING, progress: 0, statusText: 'Starter nedlasting' });

		function handleError(error: any) {
			console.error(error);
			setState({
				...state,
				status: ExportStatuses.HIDDEN,
			});
		}

		const {
			data: { bedrift },
			error,
		} = await hentKostnad({
			variables: {
				orgid,
				maalere,
				fakturamerker,
				naeringskoder,
				fraDato,
				tilDato,
			},
			fetchPolicy: 'no-cache',
		});

		if (error) {
			handleError(error);
		}
		let kostnadData = bedrift?.kostnad?.verdier || [];

		let maalerLabel = 'for alle målere';
		let filterLabels = [];

		if (maalere && maalere?.length) {
			filterLabels.push(maalere.join(', '));
		}
		if (fakturamerker && fakturamerker?.length) {
			filterLabels.push(fakturamerker.join(', '));
		}
		if (naeringskoder && naeringskoder?.length) {
			filterLabels.push(naeringskoder.join(', '));
		}
		if (filterLabels?.length) {
			maalerLabel = filterLabels.join(', ');
		}

		const filename = `Fakturerte-kostnader-eksport-${fraDato}-${tilDato}`;
		let kostnadSheetData = [[`Fakturerte kostnader fra ${fraDato} til ${tilDato} ${maalerLabel}`], [], ['Måned', 'Strømkostnad']];

		let kostnadSheetRawData: any[] = [];
		kostnadData?.forEach((kostnad: any) => {
			kostnadSheetRawData.push([format(new Date(kostnad.fraTid), 'M'), kostnad.kostnad]);
		});

		kostnadSheetRawData.sort((a, b) => {
			let a0 = Number(a[0]);
			let b0 = Number(b[0]);
			if (a0 > b0) return 1;
			if (a0 < b0) return -1;
			return 0;
		});

		kostnadSheetData = [...kostnadSheetData, ...kostnadSheetRawData];

		setState({
			...state,
			status: ExportStatuses.DOWNLOADING,
			statusText: `Fullfører nedlasting`,
			progress: 1,
		});

		await asyncWait(1500);

		const book = utils.book_new();
		const kostnadSheet = utils.aoa_to_sheet(kostnadSheetData);

		utils.book_append_sheet(book, kostnadSheet, 'kostnad');

		writeFile(book, `${filename}.xls`);

		track('Data Exported', { label: 'kostnadsdata' });

		setState({
			...state,
			status: ExportStatuses.HIDDEN,
		});
	}

	return { eksporter, state, setState };
}
