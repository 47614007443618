import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetDistributedDocumentQuery } from 'web/pages/admin/SingleMeldingPage.graphql-gen';
import { SingleMeldingTemplate } from 'web/templates/admin/SingleMeldingTemplate';

export default function SingleMeldingPage() {
	const { meldingId }: { meldingId: string } = useParams() as any;

	const { data, error, loading } = useGetDistributedDocumentQuery({
		variables: {
			id: meldingId,
		},
	});

	if (error) {
		console.error(error);
	}

	return <SingleMeldingTemplate melding={data?.getDistributedDocument as any} loading={loading} />;
}
