// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentDataForInnloggetBrukerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HentDataForInnloggetBrukerQuery = { innloggetBruker?: { id?: string, tilganger?: Array<string>, trackingId?: string, harkToken?: { accessToken?: string, devices?: Array<{ anleggsId?: string }> } } };


export const HentDataForInnloggetBrukerDocument = gql`
    query hentDataForInnloggetBruker {
  innloggetBruker: innloggetBruker {
    id
    tilganger
    trackingId
    harkToken {
      accessToken
      devices {
        anleggsId
      }
    }
  }
}
    `;

/**
 * __useHentDataForInnloggetBrukerQuery__
 *
 * To run a query within a React component, call `useHentDataForInnloggetBrukerQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentDataForInnloggetBrukerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentDataForInnloggetBrukerQuery({
 *   variables: {
 *   },
 * });
 */
export function useHentDataForInnloggetBrukerQuery(baseOptions?: Apollo.QueryHookOptions<HentDataForInnloggetBrukerQuery, HentDataForInnloggetBrukerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentDataForInnloggetBrukerQuery, HentDataForInnloggetBrukerQueryVariables>(HentDataForInnloggetBrukerDocument, options);
      }
export function useHentDataForInnloggetBrukerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentDataForInnloggetBrukerQuery, HentDataForInnloggetBrukerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentDataForInnloggetBrukerQuery, HentDataForInnloggetBrukerQueryVariables>(HentDataForInnloggetBrukerDocument, options);
        }
export type HentDataForInnloggetBrukerQueryHookResult = ReturnType<typeof useHentDataForInnloggetBrukerQuery>;
export type HentDataForInnloggetBrukerLazyQueryHookResult = ReturnType<typeof useHentDataForInnloggetBrukerLazyQuery>;
export type HentDataForInnloggetBrukerQueryResult = Apollo.QueryResult<HentDataForInnloggetBrukerQuery, HentDataForInnloggetBrukerQueryVariables>;