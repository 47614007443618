import { ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
	children: ReactNode;
}

export default function Portal({ children }: PortalProps) {
	const elementRef = useRef(document.createElement('div'));

	useEffect(() => {
		document.body.appendChild(elementRef.current);

		return function removeElement() {
			elementRef.current.remove();
		};
	}, []);

	return createPortal(children, elementRef.current);
}
