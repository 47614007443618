// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SlaaoppBedriftQueryVariables = Types.Exact<{
  orgnummer: Types.Scalars['ID'];
}>;


export type SlaaoppBedriftQuery = { slaaoppBedrift?: { kilde?: Types.BedriftsoppslagKilde, navn?: string, orgnummer?: string } };

export type HentBedriftFraKundesystemQueryVariables = Types.Exact<{
  orgnummer: Types.Scalars['ID'];
}>;


export type HentBedriftFraKundesystemQuery = { bedrift?: { orgnummer: string, kundeId?: string, navn?: string, kontaktinfo?: { telefonnummer?: string, epost?: string, adresse1?: string, adresse2?: string, postnummer?: string, poststed?: string } } };

export type OpprettBedriftMutationVariables = Types.Exact<{
  opprettBedrift: Types.OpprettBedriftInput;
}>;


export type OpprettBedriftMutation = { opprettBedrift?: { id?: string, navn?: string } };


export const SlaaoppBedriftDocument = gql`
    query slaaoppBedrift($orgnummer: ID!) {
  slaaoppBedrift(orgnummer: $orgnummer) {
    kilde
    navn
    orgnummer
  }
}
    `;

/**
 * __useSlaaoppBedriftQuery__
 *
 * To run a query within a React component, call `useSlaaoppBedriftQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlaaoppBedriftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlaaoppBedriftQuery({
 *   variables: {
 *      orgnummer: // value for 'orgnummer'
 *   },
 * });
 */
export function useSlaaoppBedriftQuery(baseOptions: Apollo.QueryHookOptions<SlaaoppBedriftQuery, SlaaoppBedriftQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SlaaoppBedriftQuery, SlaaoppBedriftQueryVariables>(SlaaoppBedriftDocument, options);
      }
export function useSlaaoppBedriftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlaaoppBedriftQuery, SlaaoppBedriftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SlaaoppBedriftQuery, SlaaoppBedriftQueryVariables>(SlaaoppBedriftDocument, options);
        }
export type SlaaoppBedriftQueryHookResult = ReturnType<typeof useSlaaoppBedriftQuery>;
export type SlaaoppBedriftLazyQueryHookResult = ReturnType<typeof useSlaaoppBedriftLazyQuery>;
export type SlaaoppBedriftQueryResult = Apollo.QueryResult<SlaaoppBedriftQuery, SlaaoppBedriftQueryVariables>;
export const HentBedriftFraKundesystemDocument = gql`
    query hentBedriftFraKundesystem($orgnummer: ID!) {
  bedrift: hentBedriftFraKundesystem(orgnummer: $orgnummer) {
    orgnummer
    kundeId
    navn
    kontaktinfo {
      telefonnummer
      epost
      adresse1
      adresse2
      postnummer
      poststed
    }
  }
}
    `;

/**
 * __useHentBedriftFraKundesystemQuery__
 *
 * To run a query within a React component, call `useHentBedriftFraKundesystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentBedriftFraKundesystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentBedriftFraKundesystemQuery({
 *   variables: {
 *      orgnummer: // value for 'orgnummer'
 *   },
 * });
 */
export function useHentBedriftFraKundesystemQuery(baseOptions: Apollo.QueryHookOptions<HentBedriftFraKundesystemQuery, HentBedriftFraKundesystemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentBedriftFraKundesystemQuery, HentBedriftFraKundesystemQueryVariables>(HentBedriftFraKundesystemDocument, options);
      }
export function useHentBedriftFraKundesystemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentBedriftFraKundesystemQuery, HentBedriftFraKundesystemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentBedriftFraKundesystemQuery, HentBedriftFraKundesystemQueryVariables>(HentBedriftFraKundesystemDocument, options);
        }
export type HentBedriftFraKundesystemQueryHookResult = ReturnType<typeof useHentBedriftFraKundesystemQuery>;
export type HentBedriftFraKundesystemLazyQueryHookResult = ReturnType<typeof useHentBedriftFraKundesystemLazyQuery>;
export type HentBedriftFraKundesystemQueryResult = Apollo.QueryResult<HentBedriftFraKundesystemQuery, HentBedriftFraKundesystemQueryVariables>;
export const OpprettBedriftDocument = gql`
    mutation opprettBedrift($opprettBedrift: OpprettBedriftInput!) {
  opprettBedrift(opprettBedrift: $opprettBedrift) {
    id
    navn
  }
}
    `;
export type OpprettBedriftMutationFn = Apollo.MutationFunction<OpprettBedriftMutation, OpprettBedriftMutationVariables>;

/**
 * __useOpprettBedriftMutation__
 *
 * To run a mutation, you first call `useOpprettBedriftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpprettBedriftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [opprettBedriftMutation, { data, loading, error }] = useOpprettBedriftMutation({
 *   variables: {
 *      opprettBedrift: // value for 'opprettBedrift'
 *   },
 * });
 */
export function useOpprettBedriftMutation(baseOptions?: Apollo.MutationHookOptions<OpprettBedriftMutation, OpprettBedriftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OpprettBedriftMutation, OpprettBedriftMutationVariables>(OpprettBedriftDocument, options);
      }
export type OpprettBedriftMutationHookResult = ReturnType<typeof useOpprettBedriftMutation>;
export type OpprettBedriftMutationResult = Apollo.MutationResult<OpprettBedriftMutation>;
export type OpprettBedriftMutationOptions = Apollo.BaseMutationOptions<OpprettBedriftMutation, OpprettBedriftMutationVariables>;