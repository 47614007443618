import styled from 'styled-components';

export const CheckboxSpan = styled.span`
	height: 16px;
	width: 16px;
	border: 1px solid grey;
	border-radius: var(--border-radius-smallest);
	display: inline-block;
	position: relative;
`;

/*
		opacity: 0;
		position: absolute;
		left: -9999px;
*/

const CheckboxLabel = styled.label`
	input {
		[type='checkbox']&:checked + ${CheckboxSpan}:before {
			content: '\2714';
			position: absolute;
			top: -5px;
			left: 0;
			background: red;
		}
	}
`;

export const CheckboxDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--white);
	border: 1px solid var(--nte-graa-medium);
	border-radius: 2px;
	margin-right: 9px;
	width: 20px;
	height: 20px;
	color: var(--nte-blaa);
	flex-shrink: 0;
	i {
		font-size: 1rem;
	}
`;

export default CheckboxLabel;
