import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import CircleLoader from 'web/components/atoms/CircleLoader';

const LoadingContainer = styled(motion.div)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: var(--nte-blaa);
	gap: 1rem;
`;

const LodingText = styled.span`
	font-size: 1rem;
	font-weight: bold;
`;

interface LoadingElementProps {
	loading: boolean;
	loadingTextEnding?: string;
	text?: string;
}
export default function LoadingElement({ loading, loadingTextEnding, text }: LoadingElementProps) {
	const lodingVariants = {
		hidden: { scale: 0, opacity: 0 },
		visible: { scale: 1, opacity: 1 },
	};
	const loadingTransition = {
		scale: { duration: 0.2, type: 'spring' },
		opacity: { duration: 0.1 },
	};
	return (
		<AnimatePresence>
			{loading && (
				<LoadingContainer variants={lodingVariants} initial="hidden" animate="visible" exit="hidden" transition={loadingTransition}>
					<CircleLoader />
					<LodingText>{text ? text : `Laster inn ${loadingTextEnding ? loadingTextEnding : 'data'}...`}</LodingText>
				</LoadingContainer>
			)}
		</AnimatePresence>
	);
}
