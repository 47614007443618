import c3 from 'c3';
import React from 'react';
import styled from 'styled-components';
import FlexPageWrapper from 'web/components/atoms/FlexPageWrapper';
import Push from 'web/components/atoms/Push';
import FjuttLogo from 'web/components/atoms/svgs/FjuttLogo';
import { SecondaryValueInfoPanel } from 'web/components/molecules/SecondaryValueInfoPanel';
import SimpleGraph from 'web/components/molecules/SimpleGraph';
import { ValueInfoPanelProps } from 'web/components/molecules/ValueInfoPanel';
import { Forbruksvarsel } from 'web/components/organisms/Forbruksvarsel';
import { FjuttProps } from 'web/models/Fjutt';
import { SecondaryValuePanelWrapper } from 'web/templates/GraphTemplate';
import { TabWrapper } from 'web/templates/singleAnlegg/OversiktTabTemplate';

const FjuttGrid = styled.div`
	position: relative;
`;

const DescriptionWrapper = styled.div``;

const Description = styled.p`
	font-size: 0.875rem;
	line-height: 1.5;
`;

const ValueWrapper = styled.div`
	display: flex;
	color: var(--nte-blaa-logo);
	align-self: center;
	align-items: baseline;
	margin: 1rem 0;
`;
const Value = styled.div`
	font-size: 3rem;
`;
const Unit = styled.div`
	font-size: 1rem;
`;
interface FjuttTabTemplateProps {
	fjuttLoading?: boolean;
	fjuttChart?: c3.ChartConfiguration;
	fjuttChartRef?: React.MutableRefObject<c3.ChartAPI>;
	fjuttProps?: FjuttProps;
	secondaryInfoPanels?: ValueInfoPanelProps[];
	maalernummer: string;
	bedriftId?: string;
}

export default function FjuttTabTemplate(props: FjuttTabTemplateProps) {
	return (
		<TabWrapper>
			<FlexPageWrapper>
				<ValueWrapper>
					<Value>{props.fjuttProps?.valueNow}</Value>
					<Unit>kW</Unit>
				</ValueWrapper>

				<FjuttGrid>
					<SimpleGraph
						loadingData={props.fjuttLoading}
						style={{
							height: '300px',
							width: '100%',
						}}
						chartRef={props.fjuttChartRef}
						chart={props.fjuttChart}
					/>
				</FjuttGrid>

				{props.secondaryInfoPanels && (
					<SecondaryValuePanelWrapper>
						{props.secondaryInfoPanels.map((infoPanelprops, index) => (
							<SecondaryValueInfoPanel key={index} {...infoPanelprops} />
						))}
					</SecondaryValuePanelWrapper>
				)}
				<Forbruksvarsel maalernummer={props.maalernummer} orgId={props.bedriftId} />

				<Push height="0" />

				<DescriptionWrapper>
					<FjuttLogo />
					<Description>
						Fjutt gir deg et øyeblikksbilde av hvor mye strøm som brukes akkurat nå. Ustabiliteter kan forekomme, for eksempel dersom internett
						mister forbindelsen. Data fra Fjutt brukes ikke som fakturagrunnlag, men det gjøres daglige avlesinger fra strømmåleren din.
					</Description>
				</DescriptionWrapper>
			</FlexPageWrapper>
		</TabWrapper>
	);
}
