import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import L, { LatLngTuple } from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

const MapWrapper = styled.div`
	width: 100%;
	height: 400px;
`;

interface MapProps {
	latLng?: LatLngTuple;
	zoom?: number;
	aspectRatio?: number;
	height?: number;
	props?: any;
}

export default function Map({ latLng = [64.015, 11.4953], zoom = 16, aspectRatio = 1, height, ...props }: MapProps) {
	const mapLocation = useRef(latLng);
	const mapElement = useRef(null);
	const mapObject = useRef(null);
	const mapMarker = useRef(null);

	useEffect(() => {
		if (!mapElement.current) {
			return;
		}

		if (mapObject.current) {
			return;
		}

		mapObject.current = L.map(mapElement.current, {
			gestureHandling: true,
		} as any);

		L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
		}).addTo(mapObject.current);

		mapMarker.current = L.marker(mapLocation.current).addTo(mapObject.current);
	}, [mapElement.current]);

	useEffect(() => {
		if (!mapObject.current) {
			return;
		}

		mapLocation.current = latLng;
		mapObject.current.setView(mapLocation.current, zoom);
		mapMarker.current.setLatLng(mapLocation.current);

		const aspectHeight = mapElement.current.offsetHeight * 1 + 'px';
		mapElement.current.style.height = (height && height + 'px') || aspectHeight;

		setTimeout(function () {
			mapObject.current.invalidateSize();
		}, 300);
	}, [latLng[0], latLng[1], zoom]);

	return <MapWrapper {...props} ref={mapElement} />;
}
