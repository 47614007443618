import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

import useAuthHelpers from 'web/hooks/useAuthHelpers';
import PageTitle from 'web/components/atoms/PageTitle';
import Push from 'web/components/atoms/Push';
import Paragraph from 'web/components/atoms/Paragraph';
import styled from 'styled-components';
import { appendCurrentContractIdToUrl, getContractIdFromCurrentUrl } from 'web/pages/onboarding/OnboardingStep01';
import { useOpprettBediftFraKundereferanseMutation } from 'web/pages/onboarding/OnboardingStep02.graphql-gen';

export function OnboardingStep02() {
	const [error, setError] = useState("");
	const history = useHistory();
	const { logInFramePath } = useAuthHelpers();
	const auth = useAuth();
	const [opprettBediftFraKundereferanse, { loading }] = useOpprettBediftFraKundereferanseMutation();

	useEffect(() => {
		if (!auth.isAuthenticated) return;

		const contractId = getContractIdFromCurrentUrl();

		opprettBediftFraKundereferanse({
			variables: {
				kunderef: { contractGuid: contractId },
			},
		})
			.then(data => {
				if ((data?.errors?.length ?? 0) > 0) {
					setError("Det skjedde en feil, prøv igjen senere eller kontakt kundeservice")
					console.error(data);
					return;
				}

				history.push(appendCurrentContractIdToUrl('/register-done'));
			})
			.catch(error => {
				console.error(error);
				setError("Det skjedde en feil, prøv igjen senere eller kontakt kundeservice")
			});
	}, [auth.isAuthenticated]);

	if(error){
		return 	<LoginPageWrapper>
			<div>
				<PageTitle style={{ textAlign: 'center' }} isCenter>
					Error
				</PageTitle>
				<Push />
				<Paragraph  style={{ fontWeight: '400', maxWidth: '600px', margin: 'auto' }}>
					{error}
				</Paragraph>
			</div>
		</LoginPageWrapper>;
	}

	if(auth.isAuthenticated){
		return 	<LoginPageWrapper>
			<div>
				<Push />
				<PageTitle style={{ textAlign: 'center' }} isCenter>
					Laster...
				</PageTitle>
				<Push />
			</div>
		</LoginPageWrapper>;
	}

	return (
		<LoginPageWrapper>
			<div>
				<Push />
				<PageTitle style={{ textAlign: 'center' }} isCenter>
					Bekreft din identitet for å starte prøveperioden
				</PageTitle>
				<Push />
				<Paragraph style={{ fontWeight: '400', maxWidth: '600px', margin: 'auto' }}>
					Takk for at du valgte å prøve Bedriftsportalen! For å sikre en trygg og personlig opplevelse, vennligst bekreft din identitet ved å logge
					inn med din BankID under.
				</Paragraph>
			</div>

			{!auth.isAuthenticated && <IFrame frameBorder="none" src={logInFramePath} />}
		</LoginPageWrapper>
	);
}

export const LoginPageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 2rem;
`;

export const IFrame = styled.iframe`
	box-sizing: border-box;
	margin: 2px auto 32px;
	max-width: 100%;
	width: 600px;
	min-height: 330px;
	border: none;
	border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
	padding: 2px;
`;
