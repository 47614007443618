import { isBefore, isToday } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAnlegg from 'web/hooks/useAnlegg';
import { useHasGroup, useIsAdmin } from 'web/hooks/useAuthorization';
import { useHasFjutt } from 'web/hooks/useHasFjutt';
import { useTenant } from 'web/hooks/useTenant';
import { SegmentCategory, useTracking } from 'web/hooks/useTracking';
import { oppdaterEgenAnleggsbeskrivelseMutation } from 'web/lib/anlegg.utils';
import FakturaTab from 'web/pages/SingleAnleggTabs/FakturaTab';
import FjuttTab from 'web/pages/SingleAnleggTabs/FjuttTab';
import KostnadOgForbrukTab from 'web/pages/SingleAnleggTabs/KostnadOgForbrukTab';
import OversiktTab from 'web/pages/SingleAnleggTabs/OversiktTab';
import SingleAnleggTemplate, { TabProps } from 'web/templates/SingleAnleggTemplate';

export default function SingleAnleggPage() {
	const { track, trackPageView } = useTracking();
	trackPageView(SegmentCategory.SingleAnlegg);

	const tenant = useTenant();
	const { anlegg } = useAnlegg({ visOpphoert: true });
	const [oppdaterEgenAnleggsbeskrivelse, { loading: updateTitleLoading, error: updateTitleError }] = oppdaterEgenAnleggsbeskrivelseMutation();
	const [currentTab, setCurrentTab] = useState('oversikt');

	const params = useParams() as any;

	const isAdmin = useIsAdmin();
	const hasTitleEditingAccess = useHasGroup({ group: 'Bedriftsadmin' }) || isAdmin;

	// Bruker ref slik at når router fjerner param.anleggId, så forsvinner ikke anlegg fra visningen før transition er fullført.
	const currentAnlegg = useRef((params.anleggId && anlegg.find((anlegg: any) => anlegg?.maalerpunktId === params?.anleggId)) || null);
	const { probeFjuttExists } = useHasFjutt();
	const omregningsfaktorFradato = currentAnlegg.current?.omregningsfaktorFradato ? new Date(currentAnlegg.current.omregningsfaktorFradato) : new Date();
	const doUseOmregningsFaktor = isBefore(omregningsfaktorFradato, new Date()) || isToday(omregningsfaktorFradato);

	const [tabs, setTabs] = useState([
		{
			value: 'oversikt',
			label: 'Oversikt',
			component: (tabProps: TabProps) => <OversiktTab anlegg={currentAnlegg.current} />,
		},
		{
			value: 'kostnad-forbruk',
			label: 'Kostnad og forbruk',
			component: (tabProps: TabProps) => <KostnadOgForbrukTab anlegg={currentAnlegg.current} />,
		},
		{
			value: 'faktura',
			label: 'Faktura',
			component: (tabProps: TabProps) => <FakturaTab anleggId={currentAnlegg.current?.maalerpunktId} />,
		},
	]);

	useEffect(() => {
		async function addFjuttTab() {
			return await probeFjuttExists(currentAnlegg.current.maalernummer).then(hasFjutt => {
				if (hasFjutt) {
					if (tabs && tabs[tabs?.length - 1].value !== 'sanntid') {
						setTabs([
							...tabs,
							{
								value: 'sanntid',
								label: 'Sanntid',
								component: (tabProps: TabProps) => (
									<FjuttTab
										anleggId={currentAnlegg.current?.maalerpunktId}
										maalernummer={currentAnlegg.current.maalernummer}
										doUseOmregningsFaktor={doUseOmregningsFaktor}
										isTabActive={tabProps.isTabActive}
									/>
								),
							},
						]);
					}
				}
			});
		}
		addFjuttTab();
	}, []);

	return (
		<SingleAnleggTemplate
			anlegg={currentAnlegg.current}
			currentTab={currentTab}
			onChangeTab={setCurrentTab}
			tabs={tabs}
			hasTitleEditingAccess={hasTitleEditingAccess}
			onUpdateTitle={async (newName: string, revert: (name: string) => void) => {
				track('Button Clicked', {
					label: 'Rediger',
				});
				if (newName == '' || newName == null) {
					return;
				}
				const currentName = currentAnlegg.current?.tittel || currentAnlegg.current?.egenAnleggsbeskrivelse;
				if (newName === currentName) {
					return;
				}
				try {
					await oppdaterEgenAnleggsbeskrivelse(newName, tenant?.id, params.anleggId);
				} catch (error) {
					console.error(error);
					revert('');
					toast('Fikk ikke oppdatert navn', {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				}
			}}
		/>
	);
}
