import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Direction, Flex } from 'web/components/atoms/Flex';
import PageTitle from 'web/components/atoms/PageTitle';
import Push from 'web/components/atoms/Push';
import Paragraph from 'web/components/atoms/Paragraph';

import KundeserviceSvg from 'web/components/atoms/svgs/Kundeservice.svg';
import CheckmarkSvg from 'web/components/atoms/svgs/Checkmark.svg';

import Center from 'web/components/atoms/Center';
import CheckboxField from 'web/components/molecules/CheckboxField';
import Button from 'web/components/atoms/Button';

export function getContractIdFromCurrentUrl() {
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.get('contract_id');
}

export function appendCurrentContractIdToUrl(url: string) {
	return `${url}?contract_id=${getContractIdFromCurrentUrl()}`;
}

// Test with: http://localhost:3000/register?contract_id=7850870c-be83-49cc-826c-8099d04ec842

export function OnboardingStep01() {
	const history = useHistory<any>();
	const [terms, setTerms] = useState(false);

	return (
		<ProductSalesPageWrapper direction={Direction.column} gap="16px">
			<div style={{ maxWidth: '600px', margin: 'auto' }}>
				<PageTitle isCenter>Forstå ditt energiforbruk</PageTitle>
				<Push height="10px" />
				<Paragraph style={{ fontWeight: '400' }}>
					Bedriftsportalen gir deg oversikten du trenger for å drive bedriften din mer kostnadseffektivt og miljøvennlig.
				</Paragraph>
			</div>

			<Row>
				<LeftColumn>
					<h2>Med Bedriftsportalen får du</h2>

					<TermsList>
						<li>
							<CheckmarkSvg />
							Enkel energiovervåkning
						</li>
						<li>
							<CheckmarkSvg />
							Full kostnadskontroll
						</li>
						<li>
							<CheckmarkSvg />
							Fleksibelt varslingssystem
						</li>
						<li>
							<CheckmarkSvg />
							Effektiv anleggsadministrasjon
						</li>
						<li>
							<CheckmarkSvg />
							Sømløs tilgangstyring
						</li>
						<li>
							<CheckmarkSvg />
							Ingen bindingstid
						</li>
					</TermsList>

					<KundeserviceSvg style={{ marginTop: '47px' }} />

					<p>
						Har du spørsmål?{' '}
						<a style={{ fontWeight: 'bold' }} href="mailto:kraftsalg@nte.no">
							Kontakt oss
						</a>
					</p>
				</LeftColumn>

				<RightColumn>
					<div>
						<BackgroundPanel direction={Direction.column} gap="40px">
							<Center>
								<h2 style={{ margin: '0' }}>Bedriftsportalen – Månedlig plan</h2>
								<h3 style={{ fontSize: '37px', fontWeight: '400', margin: '10px 0 60px' }}>
									99 kr <span style={{ fontSize: '16px' }}>/per måler</span>
								</h3>

								<ReceiptLine>
									<ReceiptLeft>Til forfall om 14 dager</ReceiptLeft>
									<ReceiptRight>99 kr per måler</ReceiptRight>
								</ReceiptLine>
								<ReceiptHr />
								<ReceiptLine>
									<ReceiptLeft>
										<b>Til forfall i dag</b>
									</ReceiptLeft>
									<ReceiptRight>
										<b>0 kr</b>
									</ReceiptRight>
								</ReceiptLine>
							</Center>
							<CheckboxField
								onChange={value => setTerms(value)}
								name="terms"
								label={
									<TermsText>
										Jeg aksepterer{' '}
										<a href="/register-vilkaar" target="_blank">
											tilleggsvilkår for Bedriftsportalen
										</a>{' '}
										og{' '}
										<a href="https://nte.no/om-nte/personvernerklaering/" target="_blank">
											NTE sin personvernerklæring
										</a>
									</TermsText>
								}
							/>
							<p>
								Ved å fortsette vil du automatisk bli belastet ved slutten av 14-dagers prøveperioden. Ønsker du likevel ikke å betale for
								Bedriftsportalen, kan du avbestille før prøveperioden går ut.
							</p>
							<Button
								style={{ width: '100%' }}
								onClick={async () => {
									if (!terms) {
										return alert(`Du må godta vilkårene for å fullføre bestillingen`);
									}

									history.push(appendCurrentContractIdToUrl('/register-login'));
								}}>
								Start gratis prøveperiode
							</Button>
						</BackgroundPanel>
					</div>
				</RightColumn>
			</Row>
		</ProductSalesPageWrapper>
	);
}

const TermsList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		svg {
			width: 14px;
			margin-right: 12px;
		}

		color: #28292c;
		font-size: 16px;
		font-weight: 400;
		line-height: 32px;
	}
`;

const Row = styled(Flex)`
	width: 100%;
	padding: 56px;
	max-width: 1400px;
	gap: 48px;
	justify-content: space-between;
	margin: auto;
	@media only screen and (max-width: 1200px) {
		padding: 16px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
`;

const LeftColumn = styled.div`
	width: 400px;
`;

const RightColumn = styled.div`
	max-width: 725px;
`;

const BackgroundPanel = styled(Flex)`
	width: auto;
	background: #f0f8fc;
	border-radius: 6px;
	padding: 38px 77px 50px;
	@media only screen and (max-width: 1200px) {
		padding: 16px;
	}
`;

const ReceiptLine = styled(Flex)`
	width: 100%;
`;

const ReceiptHr = styled.hr`
	border-style: dashed;
`;

const ReceiptLeft = styled.div`
	width: 50%;
	text-align: left;
`;

const ReceiptRight = styled.div`
	width: 50%;
	text-align: right;
`;

const TermsText = styled.div`
	font-weight: 400;
	font-size: 16px;

	a {
		font-weight: 700;
	}
`;

const ProductSalesPageWrapper = styled(Flex)`
	width: 100%;
	max-width: 1600px;
	padding: 71px 0;
`;
