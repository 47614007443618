import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import ModulIcon from 'web/components/atoms/ModulIcon';
import ConfirmModalButton from 'web/components/organisms/ConfirmModalButton';

const ModulListWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	gap: 1rem;
`;

interface ModulListProps {
	children?: ReactNode;
}
export default function ModulList({ children, ...props }: ModulListProps) {
	return <ModulListWrapper {...props}>{children}</ModulListWrapper>;
}
interface ModulListElementWrapperProps {
	onClick?: () => void;
	selected?: boolean;
}
const ModulListElementWrapper = styled.div<ModulListElementWrapperProps>`
	width: 7.5rem;
	height: 7.5rem;
	background: var(--white);
	border: var(--nte-border);
	border-radius: var(--border-radius);
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${props =>
		props.onClick &&
		css`
			cursor: pointer;
			color: var(--nte-blaa-uu);
			font-weight: bold;
			box-shadow: var(--clickable-box-shadow);
			&:hover,
			&:active,
			&:focus {
				opacity: 0.9;
				box-shadow: none;
			}
		`}

	${props =>
		props.selected &&
		css`
			opacity: 0.8;
			box-shadow: none;
		`}
`;

const Text = styled.span`
	font-size: 1rem;
`;

const RemoveButton = styled(ConfirmModalButton)`
	position: absolute;
	top: 0;
	right: 0;
	width: 2.5rem;
	height: 2.5rem;
	background: none;
	border: none;
	font-size: 1.25rem;
	color: var(--nte-blaa-logo);
	cursor: pointer;
`;
interface ModulListElementProps {
	name: string;
	icon?: string;
	selected?: boolean;
	onClick?: () => void;
	isRemovable?: boolean;
	onRemove?: () => void;
}
export function ModulListElement({ name, icon, isRemovable, onClick, onRemove, ...props }: ModulListElementProps) {
	return (
		<ModulListElementWrapper {...props} onClick={onClick}>
			<ModulIcon name={name} icon={icon} />
			<br />
			<Text>{name}</Text>
			{isRemovable && (
				<RemoveButton
					onClick={onRemove}
					title="Fjern modul"
					message={`Er du sikker på at du ønsker å fjerne modulen ${name} fra bedriften?`}
					confirmIcon="fa fa-trash"
					confirm="Fjern modul">
					<i className="far fa-times" />
				</RemoveButton>
			)}
		</ModulListElementWrapper>
	);
}
