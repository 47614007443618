import { useApolloClient } from '@apollo/client';
import { Source } from 'generated/graphql-types';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FakturaDataFragment, FakturaDataFragmentDoc } from 'web/hooks/useFakturaer.graphql-gen';
import { SegmentCategory, useTracking } from 'web/hooks/useTracking';
import { useHentFakturaDetaljerQuery } from 'web/pages/SingleFakturaPage.graphql-gen';
import SingleFakturaTemplate, { SingleFaktura } from 'web/templates/SingleFakturaTemplate';

function getSingleFakturaId(fakturaLink: string) {
	// Prevent IS_CUSTOMER from being visible in the URL, invert of getSingleFakturaLink
	if (fakturaLink.indexOf('c') > -1) {
		return fakturaLink.replace('c', Source.IS_CUSTOMER);
	} else {
		return fakturaLink;
	}
}

export default function SingleFakturaPage() {
	const { trackPageView } = useTracking();
	trackPageView(SegmentCategory.SingleFaktura);
	const client = useApolloClient();

	let { bedriftId, fakturaId } = useParams() as any;

	const {
		data: { bedrift: { faktura } } = { bedrift: {} },
		loading,
		error,
	} = useHentFakturaDetaljerQuery({
		variables: {
			orgid: bedriftId,
			guid: getSingleFakturaId(fakturaId),
		},
	});

	const cachedFaktura = useMemo<SingleFaktura | null>(() => {
		if (faktura?.detaljer?.source === Source.IS_CUSTOMER) {
			const cached = client.readFragment({
				id: `Faktura:{"guid":"${faktura.guid}"}`,
				fragment: FakturaDataFragmentDoc,
			}) as FakturaDataFragment | null;

			if (!cached) return null;

			return {
				detaljer: {
					belopEksMoms: cached.beloepInklMoms || faktura?.detaljer?.belopEksMoms,
					belopMoms: faktura?.detaljer?.belopMoms,
					fakturadato: cached.fakturadato,
					fakturanummer: cached.fakturanummer,
					fakturastatus: cached.fakturastatus,
					fakturatype: cached.fakturatype,
					forfallsdato: cached.forfallsdato,
					kid: cached.kundeid,
					saldo: cached.saldo,
					source: faktura?.detaljer?.source,
				},
				pdfUrl: cached.pdf,
			};
		}
		return null;
	}, [faktura]);

	return <SingleFakturaTemplate loading={loading} faktura={cachedFaktura || faktura} error={error} />;
}
