import { ElhubAnlegg, InnmeldtAnleggInput } from 'generated/graphql-types';
import React, { useRef, useState } from 'react';
import Modal from 'web/components/molecules/Modal';
import AnleggInnmeldingsskjemaStep0 from 'web/components/organisms/modals/MeldInnAnleggModal/Step0';
import AnleggInnmeldingsskjemaStep1 from 'web/components/organisms/modals/MeldInnAnleggModal/Step1';
import AnleggInnmeldingsskjemaStep2 from 'web/components/organisms/modals/MeldInnAnleggModal/Step2';
import AnleggInnmeldingsskjemaStep3 from 'web/components/organisms/modals/MeldInnAnleggModal/Step3';
import AnleggInnmeldingsskjemaStep4 from 'web/components/organisms/modals/MeldInnAnleggModal/Step4';
import { useModalSystem } from 'web/hooks/useModalSystem';
import { Anlegg } from 'web/models/Anlegg';
import { Tenant } from 'web/models/Tenant';

const MODAL_NAME = 'MeldInnAnleggModal';

export const ANLEGG_SKJEMA_STAGES = {
	START: 0,
	MALERPUNKT_ID: 1,
	MALERNUMMER: 2,
	STREET: 3,
	PROJECT_INPUT: 4,
};

const SkjemaSteg = [
	AnleggInnmeldingsskjemaStep0,
	AnleggInnmeldingsskjemaStep1,
	AnleggInnmeldingsskjemaStep2,
	AnleggInnmeldingsskjemaStep3,
	AnleggInnmeldingsskjemaStep4,
];

export function useMeldInnAnleggModal() {
	const { openModal, closeModal } = useModalSystem() as any;
	return {
		open: (props: any) => {
			openModal(MODAL_NAME, props);
		},
		close: () => {
			closeModal(MODAL_NAME);
		},
	};
}

export interface Bedriftinfo {
	id?: string;
	kontakt?: string;
	kontaktEpost?: string;
	godkjenteTariffer?: string[];
}
export interface MeldInnAnleggModalState {
	stage: number;
	error?: string;
	elhubAnlegg?: ElhubAnlegg;
	manueltAnlegg?: InnmeldtAnleggInput;
}
interface MeldInnAnleggModalProps {
	tenant: Tenant;
	anlegg: Anlegg[];
	callback: () => void;
}
export function MeldInnAnleggModal(props: MeldInnAnleggModalProps) {
	const { close } = useMeldInnAnleggModal();
	const scrollWindowElementRef = useRef(null);
	const [state, setState] = useState<MeldInnAnleggModalState>({ stage: 0 });
	const CurrentSkjema = SkjemaSteg[state.stage];

	return (
		<Modal
			modalRef={scrollWindowElementRef}
			title="Registrer anlegg"
			onClose={() => {
				close();
			}}>
			<CurrentSkjema
				tenant={props.tenant}
				anlegg={props.anlegg}
				state={state}
				setState={(newState: any) => {
					if (state.stage !== newState.stage) {
						scrollWindowElementRef.current.scrollTop = 0;
					}
					setState(newState);
				}}
				onFinish={() => {
					setState({ stage: 0 });
					props.tenant?.refetchTenant();
					close();
				}}
			/>
		</Modal>
	);
}

export default {
	component: MeldInnAnleggModal,
	name: MODAL_NAME,
};
