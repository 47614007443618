import { Bedrift } from 'generated/graphql-types';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import FlexPageWrapper from 'web/components/atoms/FlexPageWrapper';
import PageTitle, { PageTitleButton } from 'web/components/atoms/PageTitle';
import AdminSearchBar from 'web/components/molecules/AdminSearchBar';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import NyKundeModal from 'web/components/organisms/modals/NyKundeModal';
import useFilter, { DEFAULT_FILTER } from 'web/hooks/useFilter';
import useMineBedrifter from 'web/hooks/useMineBedrifter';
import { useTracking } from 'web/hooks/useTracking';

const StyledLink = styled(Link)`
	padding: 0.5rem;
	white-space: nowrap;
`;

export default function KundeListePage() {
	const [isNewCustomerOpen, setIsNewCustomerOpen] = useState(false);
	const [search, setSearch] = useState('');
	const { t } = useTranslation('admin');
	const { url } = useRouteMatch();
	const { bedrifter: rawBedrifter, loading } = useMineBedrifter();
	const [_, setFilter] = useFilter();
	const { trackGroup } = useTracking();

	const updateSearch = debounce(setSearch, 300);

	const bedrifter =
		(search &&
			rawBedrifter.filter((bedrift: Bedrift) => {
				const searchArray = search.toLowerCase().split(' ');
				const brukere = bedrift.brukere.map(b => `${b.fornavn} ${b.etternavn}`).join(' ');
				const searchString = `${bedrift.navn} ${bedrift.kontakt} ${bedrift.kontaktEpost} ${bedrift.orgnummer} ${brukere}`.toLowerCase();

				for (let i = 0; i < searchArray?.length; i++) {
					if (searchString.indexOf(searchArray[i]) < 0) {
						return false;
					}
				}

				return true;
			})) ||
		rawBedrifter;

	return (
		<FlexPageWrapper>
			<PageTitle>
				Bedrifter
				<PageTitleButton
					type="button"
					onClick={() => {
						setIsNewCustomerOpen(true);
					}}>
					<i className="fa fa-plus" />
					Legg til ny bedrift
				</PageTitleButton>
			</PageTitle>

			<AdminSearchBar onSearchChange={updateSearch} label="Filtrer på bedriftsnavn og brukere" style={{ width: '100%' }} />

			<LoadingOverlay loading={loading} text="Laster inn bedrifter" />

			<table className="stripe">
				<thead>
					<tr>
						<td>{t('Kunder.element.navn')}</td>
						<td>{t('Kunder.element.brukere')}</td>
						<td>{t('Kunder.element.kontaktEpost')}</td>
						<td>{t('Kunder.element.kontaktperson')}</td>
						<td>{t('Kunder.element.orgnummer')}</td>
						<td></td>
					</tr>
				</thead>
				<tbody>
					{(!bedrifter || bedrifter?.length === 0) && (
						<tr>
							<td className="center" colSpan={6}>
								Fant ingen bedrifter
							</td>
						</tr>
					)}
					{bedrifter.map(b => (
						<tr key={b.id}>
							<td>
								<StyledLink
									to={`../../${b.id}/hovedsiden`}
									onClick={() => {
										trackGroup(b.orgnummer, b.navn);
										setFilter(DEFAULT_FILTER);
									}}>
									{b.navn}
								</StyledLink>
							</td>

							<td>{b.brukere?.length}</td>

							<td>{b.kontaktEpost}</td>

							<td>{b.kontakt}</td>

							<td>{b.orgnummer}</td>

							<td className="right">
								<StyledLink
									to={`${url}${b.id}`}
									onClick={() => {
										trackGroup(b.orgnummer, b.navn);
										setFilter(DEFAULT_FILTER);
									}}>
									<i className="fa fa-cog" />
								</StyledLink>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			{isNewCustomerOpen && <NyKundeModal onClose={() => setIsNewCustomerOpen(false)} />}
		</FlexPageWrapper>
	);
}
