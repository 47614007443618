import React from 'react';
import styled from 'styled-components';
import { CheckboxDiv } from 'web/components/atoms/Checkbox';
import FilterDrawerSection from 'web/components/molecules/FilterDrawerSection';
import { FilterDataCheckBoxElement } from 'web/lib/filter.utils';

const CheckboxWrapper = styled.button`
	display: flex;
	align-items: center;
	color: var(--nte-graa-mork);
	font-size: 0.875rem;
	margin: 0;
	padding: 5px;
	border: none;
	box-shadow: none;
	background: transparent;
	border-radius: var(--border-radius-small);
	cursor: pointer;
	& i {
		font-size: 1rem;
	}
`;

export default function FilterDrawerCheckBox(props: FilterDataCheckBoxElement) {
	return (
		<FilterDrawerSection label={props.title} style={props.filterDrawerStyle}>
			<CheckboxWrapper
				style={{ ...props.filterBarStyle, margin: '14px 0 0 9px' }}
				onClick={() => {
					props.onChange(!props.checked);
				}}>
				<CheckboxDiv>{props.checked && <i className="fa fa-check" />}</CheckboxDiv>
				<span>{props.label || props.title}</span>
			</CheckboxWrapper>
		</FilterDrawerSection>
	);
}
