/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */


/*
From https://github.com/open-telemetry/opentelemetry-js
 */

const SPAN_ID_BYTES = 8;
const TRACE_ID_BYTES = 16;

const VERSION = '00';
enum TraceFlags {
  /** Represents no flag set. */
  NONE = 0x0,
  /** Bit to represent whether trace is sampled in trace flags. */
  SAMPLED = 0x1 << 0,
}
const FLAGS_NONE = `0${Number(TraceFlags.NONE).toString(16)}`;

const generateTraceId = getIdGenerator(TRACE_ID_BYTES);
const generateSpanId = getIdGenerator(SPAN_ID_BYTES);

export class TraceContext{
  constructor(readonly traceId: string,
              readonly spanId: string) {

  }
  toHeader(): string {
    return `${VERSION}-${this.traceId}-${this.spanId}-${FLAGS_NONE}`;
  }
}
export function generateTraceContext(): TraceContext {
  try {
    const traceId = generateTraceId();
    const spanId = generateSpanId();
    return new TraceContext(traceId, spanId)
  } catch (e) {
    console.error("Error generating traceContext", e)
    return new TraceContext('18892fa9852aa8de78361347900299e0', '19af7420de5e7579')
  }
}

function buf2hex(buffer: Uint8Array) {
  return [...new Uint8Array(buffer)]
      .map(x => x.toString(16).padStart(2, '0'))
      .join('');
}
function getIdGenerator(bytes: number): () => string {
  const crypto = self.crypto, QUOTA = 65536;
  return function generateId() {
    const a = new Uint8Array(bytes);
    for (let i = 0; i < bytes; i += QUOTA) {
      crypto.getRandomValues(a.subarray(i, i + Math.min(bytes - i, QUOTA)));
    }

    return buf2hex(a);
  };
}
