// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentEntriesFraContentfulQueryVariables = Types.Exact<{
  contentType: Types.Scalars['String'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  gruppeId: Types.Scalars['String'];
}>;


export type HentEntriesFraContentfulQuery = { poster?: Array<{ id?: string, tittel?: string, slug?: string, beskrivelse?: string, bildeUrl?: string }> };


export const HentEntriesFraContentfulDocument = gql`
    query hentEntriesFraContentful($contentType: String!, $limit: Int, $gruppeId: String!) {
  poster: hentEntriesFraContentful(
    contentType: $contentType
    limit: $limit
    gruppeId: $gruppeId
  ) {
    id
    tittel
    slug
    beskrivelse
    bildeUrl
  }
}
    `;

/**
 * __useHentEntriesFraContentfulQuery__
 *
 * To run a query within a React component, call `useHentEntriesFraContentfulQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentEntriesFraContentfulQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentEntriesFraContentfulQuery({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      limit: // value for 'limit'
 *      gruppeId: // value for 'gruppeId'
 *   },
 * });
 */
export function useHentEntriesFraContentfulQuery(baseOptions: Apollo.QueryHookOptions<HentEntriesFraContentfulQuery, HentEntriesFraContentfulQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentEntriesFraContentfulQuery, HentEntriesFraContentfulQueryVariables>(HentEntriesFraContentfulDocument, options);
      }
export function useHentEntriesFraContentfulLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentEntriesFraContentfulQuery, HentEntriesFraContentfulQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentEntriesFraContentfulQuery, HentEntriesFraContentfulQueryVariables>(HentEntriesFraContentfulDocument, options);
        }
export type HentEntriesFraContentfulQueryHookResult = ReturnType<typeof useHentEntriesFraContentfulQuery>;
export type HentEntriesFraContentfulLazyQueryHookResult = ReturnType<typeof useHentEntriesFraContentfulLazyQuery>;
export type HentEntriesFraContentfulQueryResult = Apollo.QueryResult<HentEntriesFraContentfulQuery, HentEntriesFraContentfulQueryVariables>;