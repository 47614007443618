import { LogFrontendDocument, useLogFrontendMutation } from 'web/hooks/useErrorLogging.graphql-gen';
import apolloClient from 'web/lib/apolloClient';

export enum Level {
	ERROR = 'ERROR',
	INFO = 'INFO',
}

export function logError(errorMessage: string) {
	// Non-hook version for calls outside of function components
	if (process.env.NODE_ENV !== 'development') {
		// Do not track if running locally
		const message = `[FRONTEND_ERROR]: ${errorMessage}`;
		try {
			apolloClient.mutate({
				mutation: LogFrontendDocument,
				variables: {
					message: {
						level: Level.ERROR,
						message: message,
						title: 'Frontend error',
					},
				},
			});
			console.log('Reporting error', message);
		} catch (e) {
			console.error('Unable to report error: ', e);
		}
	}
}

export default function useErrorLogging() {
	const [logError, { loading, error }] = useLogFrontendMutation();

	const reportError = async (title: string, level: Level, message: string) => {
		console.log('Reporting error', title, level, message);
		await logError({
			variables: {
				message: {
					level: level,
					message: message,
					title: title,
				},
			},
		});
	};

	return { reportError };
}
