import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Center from 'web/components/atoms/Center';
import Logo from 'web/components/atoms/svgs/Logo';
import Footer, { FooterWithoutLinks } from 'web/components/organisms/Footer';

const GenericWrapper = styled.div`
	overflow: auto;
	position: fixed;
	background: white;
	padding: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	flex-direction: column;
	background: var(--white);
	width: 100%;
	height: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem;
`;

interface PageWrapperProps {
	hasLongContent?: boolean;
	fullWidth?: boolean;
}

const PageWrapper = styled.div<PageWrapperProps>`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	max-width: ${p => (p.fullWidth && '100%') || '600px'};
	height: ${p => (p.hasLongContent ? 'auto' : '100%')};
`;

interface MinimalLayoutProps {
	hasLongContent?: boolean;
	children: ReactNode;
}

export function GenericLayout(props: MinimalLayoutProps) {
	return (
		<GenericWrapper>
			<Center style={{ height: '64px', marginTop: '24px', marginBottom: '24px' }}>
				<Logo style={{ width: '50px' }} />
			</Center>

			<PageWrapper hasLongContent={props.hasLongContent}>{props.children}</PageWrapper>

			<Footer
				style={{
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			/>
		</GenericWrapper>
	);
}

export function GenericLayoutLogoLeft(props: MinimalLayoutProps) {
	return (
		<GenericWrapper>
			<Header>
				<HeaderInner>
					<Logo style={{ width: '70px' }} />
				</HeaderInner>
			</Header>

			<PageWrapper fullWidth hasLongContent={props.hasLongContent}>{props.children}</PageWrapper>

			<FooterWithoutLinks
				style={{
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			/>
		</GenericWrapper>
	);
}

const Header = styled.div`
	height: 87px;
	width: calc(100% + 1rem);
	text-align: left;
	border-bottom: 1px solid #ededed;
	margin: -0.5rem -0.5rem 0;
`;

const HeaderInner = styled.div`
	width: 100%;
	max-width: 1024px;
	margin: auto;
	padding: 24px;
`;
