import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ClearButton from 'web/components/atoms/ClearButton';
import { PageTitleButton } from 'web/components/atoms/PageTitle';
import PanelTitle from 'web/components/atoms/PanelTitle';
import Push from 'web/components/atoms/Push';
import InfoBox, { INFOBOX_ALIGN } from 'web/components/molecules/InfoBox';
import Modal from 'web/components/molecules/Modal';
import {
	useHentForbruksvarslerQuery,
	useLagreForbruksvarselMutation,
	useSlettForbruksvarselMutation,
} from 'web/components/organisms/Forbruksvarsel.graphql-gen';
import ForbruksvarselSkjema from 'web/components/organisms/forms/ForbruksvarselSkjema';
import { ValidateEmailSkjema } from 'web/components/organisms/forms/ValidateEmailSkjema';
import useEmailValidation from 'web/hooks/useEmailValidation';
import { useTracking } from 'web/hooks/useTracking';
import { isForbruksvarselEnabled } from 'web/models/Fjutt';
import { VarselIcon } from 'web/templates/SpotprisTemplate';

export const StyledPanelTitle = styled(PanelTitle)`
	display: flex;
	align-items: center;
	& button {
		margin: 0;
		margin-left: 0.5rem;
	}
`;
const VarslerWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;
const VarselElement = styled.div`
	display: flex;

	background-color: var(--white);
	border: 1px solid var(--nte-graa-lys);
	border-radius: var(--border-radius-smallest);

	padding: 16px;
	margin-bottom: 0.5rem;
`;

export interface Forbruksvarsel {
	id?: string;
	orgId: string;
	beskrivelse: string;
	anleggsId?: string;
	deviceId: string;
	epost: string;
	grenseIWatt: number;
}

interface ForbruksvarselProps {
	maalernummer: string;
	orgId: string;
}
export function Forbruksvarsel({ maalernummer, orgId }: ForbruksvarselProps) {
	if (!isForbruksvarselEnabled()) {
		return <></>;
	}
	const { track } = useTracking();
	const {
		checkIfEmailIsValidated,
		requestValidationCode,
		validateEmail,
		leggTilValidertEpost,
		emailValidationError,
		setEmailValidationError,
		loading: emailLoading,
	} = useEmailValidation();

	const [lagre, { loading: lagreLoading }] = useLagreForbruksvarselMutation();
	const [slett, { loading: slettLoading }] = useSlettForbruksvarselMutation();

	function saveVarsel(varsel: Forbruksvarsel) {
		return lagre({
			variables: {
				varsel,
			},
		}).then(value => refetch());
	}
	function deleteVarsel(varsel: Forbruksvarsel) {
		return slett({
			variables: {
				id: varsel.id,
			},
		}).then(value => refetch());
	}

	const {
		data: { forbruksvarsler } = {
			forbruksvarsler: [],
		},
		loading: hentLoading,
		error,
		refetch,
	} = useHentForbruksvarslerQuery({
		variables: {
			orgId,
			anleggsId: maalernummer,
		},
		// fetchPolicy: 'network-only',
	});
	const [visVarselModal, setVisVarselModal] = useState(false);
	const defaultVarselElement = {
		isEditing: false,
		isValidated: false,
		varsel: null as Forbruksvarsel,
	};
	const [varselElement, setVarselElement] = useState(defaultVarselElement);

	const onOpenEditVarsel = (varsel: Forbruksvarsel) => {
		const { id, orgId, beskrivelse, anleggsId, deviceId, epost, grenseIWatt } = varsel;
		setVarselElement({
			varsel: {
				id,
				orgId,
				beskrivelse,
				anleggsId,
				deviceId,
				epost,
				grenseIWatt,
			},
			isEditing: true,
			isValidated: true,
		});
		setVisVarselModal(true);
	};

	const onSaveVarsel = (varsel: Forbruksvarsel) => {
		if (checkIfEmailIsValidated(varsel.epost)) {
			const isEditing = varselElement.isEditing;
			setVisVarselModal(false);
			saveVarsel(varsel)
				.then(() => {
					if (!varsel.id) {
						// Varsel is new
						track('Price Notification Subscribed', { watt: varsel.grenseIWatt });
					}

					toast(`${isEditing ? 'Varselet ble oppdatert' : 'Nytt varsel ble lagt til'}`, {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				})
				.catch(() => {
					toast(`Feil ved lagring av varsel`, {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				});
		} else {
			setVarselElement({ ...varselElement, varsel: varsel, isValidated: false });
			requestValidationCode(varsel.epost);
		}
	};

	const onValidateEmail = (varsel: Forbruksvarsel, kode: string) => {
		validateEmail(kode, varsel.epost)
			.then(res => {
				const isEditing = varselElement.isEditing;
				if (res) {
					setVisVarselModal(false);
					saveVarsel(varsel)
						.then(() => {
							leggTilValidertEpost(varsel.epost);

							if (!varsel.id) {
								// Varsel is new
								track('Price Notification Subscribed', { watt: varsel.grenseIWatt });
							}

							toast(`${isEditing ? 'Varselet ble oppdatert' : 'Nytt varsel ble lagt til'}`, {
								position: toast.POSITION.BOTTOM_CENTER,
							});
						})
						.catch(() => {
							toast(`Feil ved lagring av varsel`, {
								position: toast.POSITION.BOTTOM_CENTER,
							});
						});
				} else {
					setEmailValidationError('Feil kode oppgitt');
				}
			})
			.catch(() => {
				setEmailValidationError('Feil kode oppgitt');
				toast(`Feil ved lagring av varsel`, {
					position: toast.POSITION.BOTTOM_CENTER,
				});
			});
	};

	const onRemoveVarsel = (varsel: Forbruksvarsel) => {
		deleteVarsel(varsel)
			.then(() => {
				setVisVarselModal(false);
				setVarselElement(defaultVarselElement);

				track('Price Notification Unsubscribed');

				toast(`Varselet ble slettet`, {
					position: toast.POSITION.BOTTOM_CENTER,
				});
			})
			.catch(() => {
				toast(`Feil ved sletting av varsel. Prøv igjen senere.`, {
					position: toast.POSITION.BOTTOM_CENTER,
				});
			});
	};

	const loading = hentLoading || lagreLoading || slettLoading || emailLoading;
	return (
		<div>
			<StyledPanelTitle>
				Forbruksvarsler
				<PageTitleButton
					type="button"
					onClick={() => {
						setVisVarselModal(true);
					}}>
					<i className="fa fa-plus" /> Legg til varsel
				</PageTitleButton>
			</StyledPanelTitle>
			<Push height="1rem" />
			<VarslerWrapper>
				{(forbruksvarsler?.length &&
					forbruksvarsler?.map(varsel => (
						<VarselElement key={varsel.id}>
							<div style={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
								<VarselIcon className="fas fa-bell" />
								<span>{varsel.beskrivelse}</span>
							</div>
							<ClearButton
								onClick={() => {
									onOpenEditVarsel(varsel);
								}}
								style={{ flexGrow: 0, alignSelf: 'flex-end', fontWeight: 'bold', padding: 0, whiteSpace: 'nowrap' }}>
								Rediger <i className="fa fa-chevron-right" />
							</ClearButton>
						</VarselElement>
					))) || (
					<InfoBox
						align={INFOBOX_ALIGN.left}
						buttonText={'Legg til varsel'}
						onButtonClicked={() => {
							setVisVarselModal(true);
						}}>
						Du har ikke satt opp noen varsler for dette anlegget, ønsker du å motta varsler på e-post?
					</InfoBox>
				)}
			</VarslerWrapper>
			{visVarselModal &&
				varselElement &&
				!varselElement?.isEditing && ( //New Varsel
					<Modal
						title="Legg til varsel"
						onClose={() => {
							setVisVarselModal(false);
						}}>
						{!varselElement.varsel && (
							<ForbruksvarselSkjema
								onSubmit={onSaveVarsel}
								description="Her kan du legge til varsel slik at du får epost når strømforbruket ser ut til å gå over en grense."
								orgId={orgId}
								anleggsId={maalernummer}
								loading={loading}
							/>
						)}
						{varselElement.varsel && !varselElement.isValidated && (
							<ValidateEmailSkjema
								onSubmit={(kode: string) => onValidateEmail(varselElement.varsel, kode)}
								description="Før vi kan opprette varselet ditt, må du bekrefte e-postadressen din. Vi har sendt deg en kode på e-postadressen du fylte inn i forrige steg."
								emailValidationError={emailValidationError}
								loading={loading}
							/>
						)}
					</Modal>
				)}

			{visVarselModal &&
				varselElement &&
				varselElement?.isEditing && ( //Edit Varsel
					<Modal
						title={varselElement.varsel.beskrivelse || `Forbruk ser ut til å overstige ${varselElement.varsel.grenseIWatt} Wh`}
						onClose={() => {
							setVarselElement(defaultVarselElement);
							setVisVarselModal(false);
						}}>
						{varselElement.varsel && varselElement.isValidated && (
							<ForbruksvarselSkjema
								onSubmit={onSaveVarsel}
								defaultVarsel={varselElement.varsel}
								onRemove={onRemoveVarsel}
								orgId={orgId}
								anleggsId={maalernummer}
								loading={loading}
							/>
						)}
						{!varselElement.isValidated && (
							<ValidateEmailSkjema
								onSubmit={(kode: string) => onValidateEmail(varselElement.varsel, kode)}
								description="Du endret e-postadressen på varselet til en som ikke er bekreftet. Vi har sendt deg en kode på e-postadressen du fylte inn i forrige steg."
								emailValidationError={emailValidationError}
								loading={loading}
							/>
						)}
					</Modal>
				)}
		</div>
	);
}
