import styled from 'styled-components';

const ClearButton = styled.button`
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.5;
	background: none;
	border: none;
	color: var(--nte-blaa-uu);
	padding: 0.5rem;
`;

export default ClearButton;
