import { Faktura } from 'generated/graphql-types';
import React from 'react';
import FilterDrawerSelect from 'web/components/molecules/filter/FilterDrawerSelect';
import FakturaListDesktop from 'web/components/organisms/FakturaListDesktop';
import FakturaListMobile from 'web/components/organisms/FakturaListMobile';
import { MobileDesktopSwitcher } from 'web/components/organisms/MobileDesktopSwitcher';
import { FilterDataArray, FilterDataSelectElement } from 'web/lib/filter.utils';
import { TabWrapper } from 'web/templates/singleAnlegg/OversiktTabTemplate';

interface FakturaTabTemplateProps {
	loading?: boolean;
	loadingFetchMore?: boolean;
	fakturaer: Faktura[];
	filterData: FilterDataArray;
	displayScrollToTopButton?: boolean;
	onClickGoToTop?: () => void;
}

export default function FakturaTabTemplate({
	loading,
	loadingFetchMore,
	fakturaer,
	filterData,
	displayScrollToTopButton,
	onClickGoToTop,
}: FakturaTabTemplateProps) {
	return (
		<TabWrapper>
			<MobileDesktopSwitcher
				desktop={() => <FakturaListDesktop loading={loading} loadingFetchMore={loadingFetchMore} fakturaer={fakturaer} isTabView={true} />}
				mobile={() => (
					<>
						<FilterDrawerSelect {...(filterData[0] as FilterDataSelectElement)} />
						<FakturaListMobile
							loading={loading}
							loadingFetchMore={loadingFetchMore}
							fakturaer={fakturaer}
							isTabView={true}
							displayScrollToTopButton={displayScrollToTopButton}
							onClickGoToTop={onClickGoToTop}
						/>
					</>
				)}
			/>
		</TabWrapper>
	);
}
