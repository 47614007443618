import _ from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { isMobile } from 'web/GlobalStyling';

interface MobileDesktopSwitcherProps {
	mobile: () => ReactNode;
	desktop: () => ReactNode;
}

export function MobileDesktopSwitcher(props: MobileDesktopSwitcherProps) {
	const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
	const isMobileWidth = isMobile(windowWidth);

	useEffect(() => {
		const updateWindowWidth = _.debounce(() => {
			setWindowWidth(window.innerWidth);
		}, 300);

		window.addEventListener('resize', updateWindowWidth);

		return () => {
			window.removeEventListener('resize', updateWindowWidth);
		};
	}, []);

	if (isMobileWidth) {
		return <>{props.mobile()}</>;
	}

	return <>{props.desktop()}</>;
}
