// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AksepterInvitasjonMutationVariables = Types.Exact<{
  aksepterInvitasjon: Types.AksepterInvitasjonInput;
}>;


export type AksepterInvitasjonMutation = { invitasjon?: { invitasjonId: string, token: string, orgnummer: string, orgId?: string } };

export type HentInvitasjonsstatusQueryVariables = Types.Exact<{
  invitasjonId: Types.Scalars['ID'];
  token: Types.Scalars['ID'];
}>;


export type HentInvitasjonsstatusQuery = { hentInvitasjonsstatus?: { organisasjonsnavn?: string, status: Types.Invitasjonsstatus } };


export const AksepterInvitasjonDocument = gql`
    mutation aksepterInvitasjon($aksepterInvitasjon: AksepterInvitasjonInput!) {
  invitasjon: aksepterInvitasjon(aksepterInvitasjon: $aksepterInvitasjon) {
    invitasjonId
    token
    orgnummer
    orgId
  }
}
    `;
export type AksepterInvitasjonMutationFn = Apollo.MutationFunction<AksepterInvitasjonMutation, AksepterInvitasjonMutationVariables>;

/**
 * __useAksepterInvitasjonMutation__
 *
 * To run a mutation, you first call `useAksepterInvitasjonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAksepterInvitasjonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aksepterInvitasjonMutation, { data, loading, error }] = useAksepterInvitasjonMutation({
 *   variables: {
 *      aksepterInvitasjon: // value for 'aksepterInvitasjon'
 *   },
 * });
 */
export function useAksepterInvitasjonMutation(baseOptions?: Apollo.MutationHookOptions<AksepterInvitasjonMutation, AksepterInvitasjonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AksepterInvitasjonMutation, AksepterInvitasjonMutationVariables>(AksepterInvitasjonDocument, options);
      }
export type AksepterInvitasjonMutationHookResult = ReturnType<typeof useAksepterInvitasjonMutation>;
export type AksepterInvitasjonMutationResult = Apollo.MutationResult<AksepterInvitasjonMutation>;
export type AksepterInvitasjonMutationOptions = Apollo.BaseMutationOptions<AksepterInvitasjonMutation, AksepterInvitasjonMutationVariables>;
export const HentInvitasjonsstatusDocument = gql`
    query hentInvitasjonsstatus($invitasjonId: ID!, $token: ID!) {
  hentInvitasjonsstatus(invitasjonId: $invitasjonId, token: $token) {
    organisasjonsnavn
    status
  }
}
    `;

/**
 * __useHentInvitasjonsstatusQuery__
 *
 * To run a query within a React component, call `useHentInvitasjonsstatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentInvitasjonsstatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentInvitasjonsstatusQuery({
 *   variables: {
 *      invitasjonId: // value for 'invitasjonId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useHentInvitasjonsstatusQuery(baseOptions: Apollo.QueryHookOptions<HentInvitasjonsstatusQuery, HentInvitasjonsstatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentInvitasjonsstatusQuery, HentInvitasjonsstatusQueryVariables>(HentInvitasjonsstatusDocument, options);
      }
export function useHentInvitasjonsstatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentInvitasjonsstatusQuery, HentInvitasjonsstatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentInvitasjonsstatusQuery, HentInvitasjonsstatusQueryVariables>(HentInvitasjonsstatusDocument, options);
        }
export type HentInvitasjonsstatusQueryHookResult = ReturnType<typeof useHentInvitasjonsstatusQuery>;
export type HentInvitasjonsstatusLazyQueryHookResult = ReturnType<typeof useHentInvitasjonsstatusLazyQuery>;
export type HentInvitasjonsstatusQueryResult = Apollo.QueryResult<HentInvitasjonsstatusQuery, HentInvitasjonsstatusQueryVariables>;