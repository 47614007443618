import styled from 'styled-components';

/* Ingress */

const Paragraph = styled.p`
	font-weight: bold;
	font-size: 1rem;
	text-align: center;
	line-height: 1.5;
	margin: 0;
`;

export default Paragraph;
