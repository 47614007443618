// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LagreForbruksvarselMutationVariables = Types.Exact<{
  varsel: Types.ForbruksvarselInput;
}>;


export type LagreForbruksvarselMutation = { lagreForbruksvarsel: { id?: string, anleggsId?: string, beskrivelse: string, deviceId: string, orgId: string, epost: string, grenseIWatt: number } };

export type SlettForbruksvarselMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type SlettForbruksvarselMutation = { slettForbruksvarsel?: { id?: string, anleggsId?: string, beskrivelse: string, deviceId: string, orgId: string, epost: string, grenseIWatt: number } };

export type HentForbruksvarslerQueryVariables = Types.Exact<{
  anleggsId?: Types.InputMaybe<Types.Scalars['String']>;
  deviceId?: Types.InputMaybe<Types.Scalars['String']>;
  orgId: Types.Scalars['String'];
}>;


export type HentForbruksvarslerQuery = { forbruksvarsler: Array<{ id?: string, anleggsId?: string, beskrivelse: string, deviceId: string, orgId: string, epost: string, grenseIWatt: number }> };

export type ForbruksvarselDetailFragment = { id?: string, anleggsId?: string, beskrivelse: string, deviceId: string, orgId: string, epost: string, grenseIWatt: number };

export const ForbruksvarselDetailFragmentDoc = gql`
    fragment forbruksvarselDetail on Forbruksvarsel {
  id
  anleggsId
  beskrivelse
  deviceId
  orgId
  epost
  grenseIWatt
}
    `;
export const LagreForbruksvarselDocument = gql`
    mutation lagreForbruksvarsel($varsel: ForbruksvarselInput!) {
  lagreForbruksvarsel: lagreForbruksvarsel(varsel: $varsel) {
    ...forbruksvarselDetail
  }
}
    ${ForbruksvarselDetailFragmentDoc}`;
export type LagreForbruksvarselMutationFn = Apollo.MutationFunction<LagreForbruksvarselMutation, LagreForbruksvarselMutationVariables>;

/**
 * __useLagreForbruksvarselMutation__
 *
 * To run a mutation, you first call `useLagreForbruksvarselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLagreForbruksvarselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lagreForbruksvarselMutation, { data, loading, error }] = useLagreForbruksvarselMutation({
 *   variables: {
 *      varsel: // value for 'varsel'
 *   },
 * });
 */
export function useLagreForbruksvarselMutation(baseOptions?: Apollo.MutationHookOptions<LagreForbruksvarselMutation, LagreForbruksvarselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LagreForbruksvarselMutation, LagreForbruksvarselMutationVariables>(LagreForbruksvarselDocument, options);
      }
export type LagreForbruksvarselMutationHookResult = ReturnType<typeof useLagreForbruksvarselMutation>;
export type LagreForbruksvarselMutationResult = Apollo.MutationResult<LagreForbruksvarselMutation>;
export type LagreForbruksvarselMutationOptions = Apollo.BaseMutationOptions<LagreForbruksvarselMutation, LagreForbruksvarselMutationVariables>;
export const SlettForbruksvarselDocument = gql`
    mutation slettForbruksvarsel($id: ID!) {
  slettForbruksvarsel: slettForbruksvarsel(id: $id) {
    ...forbruksvarselDetail
  }
}
    ${ForbruksvarselDetailFragmentDoc}`;
export type SlettForbruksvarselMutationFn = Apollo.MutationFunction<SlettForbruksvarselMutation, SlettForbruksvarselMutationVariables>;

/**
 * __useSlettForbruksvarselMutation__
 *
 * To run a mutation, you first call `useSlettForbruksvarselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSlettForbruksvarselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [slettForbruksvarselMutation, { data, loading, error }] = useSlettForbruksvarselMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSlettForbruksvarselMutation(baseOptions?: Apollo.MutationHookOptions<SlettForbruksvarselMutation, SlettForbruksvarselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SlettForbruksvarselMutation, SlettForbruksvarselMutationVariables>(SlettForbruksvarselDocument, options);
      }
export type SlettForbruksvarselMutationHookResult = ReturnType<typeof useSlettForbruksvarselMutation>;
export type SlettForbruksvarselMutationResult = Apollo.MutationResult<SlettForbruksvarselMutation>;
export type SlettForbruksvarselMutationOptions = Apollo.BaseMutationOptions<SlettForbruksvarselMutation, SlettForbruksvarselMutationVariables>;
export const HentForbruksvarslerDocument = gql`
    query hentForbruksvarsler($anleggsId: String, $deviceId: String, $orgId: String!) {
  forbruksvarsler: hentForbruksvarsler(
    anleggsId: $anleggsId
    deviceId: $deviceId
    orgId: $orgId
  ) {
    ...forbruksvarselDetail
  }
}
    ${ForbruksvarselDetailFragmentDoc}`;

/**
 * __useHentForbruksvarslerQuery__
 *
 * To run a query within a React component, call `useHentForbruksvarslerQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentForbruksvarslerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentForbruksvarslerQuery({
 *   variables: {
 *      anleggsId: // value for 'anleggsId'
 *      deviceId: // value for 'deviceId'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useHentForbruksvarslerQuery(baseOptions: Apollo.QueryHookOptions<HentForbruksvarslerQuery, HentForbruksvarslerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentForbruksvarslerQuery, HentForbruksvarslerQueryVariables>(HentForbruksvarslerDocument, options);
      }
export function useHentForbruksvarslerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentForbruksvarslerQuery, HentForbruksvarslerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentForbruksvarslerQuery, HentForbruksvarslerQueryVariables>(HentForbruksvarslerDocument, options);
        }
export type HentForbruksvarslerQueryHookResult = ReturnType<typeof useHentForbruksvarslerQuery>;
export type HentForbruksvarslerLazyQueryHookResult = ReturnType<typeof useHentForbruksvarslerLazyQuery>;
export type HentForbruksvarslerQueryResult = Apollo.QueryResult<HentForbruksvarslerQuery, HentForbruksvarslerQueryVariables>;