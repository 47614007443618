import { format } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import React, { ReactNode } from 'react';
import { InnboksElementProps } from 'web/components/molecules/InnboksElement';
import useMeldinger from 'web/hooks/useMeldinger';
import { useTenant } from 'web/hooks/useTenant';
import { SegmentCategory, useTracking } from 'web/hooks/useTracking';
import { useSetDocumentReadMutation } from 'web/pages/InnboksPage.graphql-gen';
import InnboksTemplate from 'web/templates/InnboksTemplate';

interface InnboksPageProps {
	children?: ReactNode;
}

export default function InnboksPage(props: InnboksPageProps) {
	const { trackPageView } = useTracking();
	trackPageView(SegmentCategory.Innboks);
	const tenant = useTenant();
	const { meldinger } = useMeldinger();

	// Sorter meldinger
	let sorterteMeldinger = [...(meldinger || [])]?.sort((a: InnboksElementProps, b: InnboksElementProps) => {
		if (a.created < b.created) return 1;
		if (a.created > b.created) return -1;
		return 0;
	});

	// Format date
	sorterteMeldinger = sorterteMeldinger?.map((element: InnboksElementProps) => ({
		...element,
		created: format(new Date(element.created), 'd. MMMM yyyy', { locale: nbLocale }),
	}));

	const [setRead] = useSetDocumentReadMutation({
		refetchQueries: ['getAllCompanyDocuments'],
	});

	return (
		<>
			<InnboksTemplate
				setRead={(meldingId: string, read: boolean) => {
					setRead({
						variables: {
							documentId: meldingId,
							read,
							companyId: tenant?.orgnummer,
						},
					});
				}}
				meldinger={sorterteMeldinger}
			/>
			{props.children}
		</>
	);
}
