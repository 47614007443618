import React from 'react';
import SelectField from 'web/components/molecules/form/SelectField';

interface TariffVelgerProps {
	name: string;
	tariffer: string[];
	required?: boolean;
	error?: boolean;
}
export default function TariffVelger2({ name, tariffer, required, error }: TariffVelgerProps) {
	return (
		<SelectField name={name} label="Tariff" value={tariffer[0]} required={required} error={error}>
			{tariffer?.map(tariff => (
				<option value={tariff} key={tariff}>
					{tariff}
				</option>
			))}
		</SelectField>
	);
}
