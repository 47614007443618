// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OpprettInvitasjonMutationVariables = Types.Exact<{
  invitasjon?: Types.InputMaybe<Types.OpprettInvitasjonInput>;
  orgid: Types.Scalars['ID'];
}>;


export type OpprettInvitasjonMutation = { opprettInvitasjon?: { id: string, epost?: string, moduler?: Array<string>, bedriftId?: string } };

export type HentBedriftSendInvitasjonQueryVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
}>;


export type HentBedriftSendInvitasjonQuery = { bedrift?: { id?: string, orgnummer?: string, navn?: string, brukere?: Array<{ id?: string, fornavn?: string, etternavn?: string, epost?: string, moduler?: Array<string> }>, moduler?: Array<{ id?: string, navn?: string }> } };


export const OpprettInvitasjonDocument = gql`
    mutation opprettInvitasjon($invitasjon: OpprettInvitasjonInput, $orgid: ID!) {
  opprettInvitasjon(invitasjon: $invitasjon, orgid: $orgid) {
    id
    epost
    moduler
    bedriftId
  }
}
    `;
export type OpprettInvitasjonMutationFn = Apollo.MutationFunction<OpprettInvitasjonMutation, OpprettInvitasjonMutationVariables>;

/**
 * __useOpprettInvitasjonMutation__
 *
 * To run a mutation, you first call `useOpprettInvitasjonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpprettInvitasjonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [opprettInvitasjonMutation, { data, loading, error }] = useOpprettInvitasjonMutation({
 *   variables: {
 *      invitasjon: // value for 'invitasjon'
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useOpprettInvitasjonMutation(baseOptions?: Apollo.MutationHookOptions<OpprettInvitasjonMutation, OpprettInvitasjonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OpprettInvitasjonMutation, OpprettInvitasjonMutationVariables>(OpprettInvitasjonDocument, options);
      }
export type OpprettInvitasjonMutationHookResult = ReturnType<typeof useOpprettInvitasjonMutation>;
export type OpprettInvitasjonMutationResult = Apollo.MutationResult<OpprettInvitasjonMutation>;
export type OpprettInvitasjonMutationOptions = Apollo.BaseMutationOptions<OpprettInvitasjonMutation, OpprettInvitasjonMutationVariables>;
export const HentBedriftSendInvitasjonDocument = gql`
    query hentBedriftSendInvitasjon($orgid: ID!) {
  bedrift: hentBedrift(orgid: $orgid) {
    id
    orgnummer
    navn
    brukere {
      id
      fornavn
      etternavn
      epost
      moduler
    }
    moduler {
      id
      navn
    }
  }
}
    `;

/**
 * __useHentBedriftSendInvitasjonQuery__
 *
 * To run a query within a React component, call `useHentBedriftSendInvitasjonQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentBedriftSendInvitasjonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentBedriftSendInvitasjonQuery({
 *   variables: {
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useHentBedriftSendInvitasjonQuery(baseOptions: Apollo.QueryHookOptions<HentBedriftSendInvitasjonQuery, HentBedriftSendInvitasjonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentBedriftSendInvitasjonQuery, HentBedriftSendInvitasjonQueryVariables>(HentBedriftSendInvitasjonDocument, options);
      }
export function useHentBedriftSendInvitasjonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentBedriftSendInvitasjonQuery, HentBedriftSendInvitasjonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentBedriftSendInvitasjonQuery, HentBedriftSendInvitasjonQueryVariables>(HentBedriftSendInvitasjonDocument, options);
        }
export type HentBedriftSendInvitasjonQueryHookResult = ReturnType<typeof useHentBedriftSendInvitasjonQuery>;
export type HentBedriftSendInvitasjonLazyQueryHookResult = ReturnType<typeof useHentBedriftSendInvitasjonLazyQuery>;
export type HentBedriftSendInvitasjonQueryResult = Apollo.QueryResult<HentBedriftSendInvitasjonQuery, HentBedriftSendInvitasjonQueryVariables>;