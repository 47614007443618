import React from 'react';
import styled from 'styled-components';
import Panel, { PanelProps } from 'web/components/atoms/Panel';
import { device } from 'web/GlobalStyling';
import { useTracking } from 'web/hooks/useTracking';

const Title = styled.div`
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 1.2;
	color: var(--nte-blaa-mork);
	margin-bottom: 0.5rem;
`;
const Icon = styled.div`
	display: flex;
	align-items: center;

	font-weight: 900;
	font-size: 2rem;
	color: var(--nte-blaa);
	margin-right: 21px;
`;
const Description = styled.div`
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2;
	color: var(--nte-graa-mork);
	margin-bottom: 0.5rem;
`;
const Link = styled.a`
	font-weight: bold;
	font-size: 1rem;
	line-height: 1.2;
	text-decoration-line: underline;
	color: var(--nte-blaa-uu);
`;
const Date = styled.span`
	font-weight: normal;
	font-size: 0.875rem;
	line-height: 1.7;
	color: var(--nte-sort);
	position: absolute;
	top: 1rem;
	right: 2rem;
`;

const Wrapper = styled(Panel)`
	display: flex;
	box-shadow: none;

	@media ${device.tablet} {
		padding: 2rem 1rem;
		${Icon} {
			font-size: 1.1875rem;
			margin-right: 1rem;
		}
		${Date} {
			font-size: 0.75rem;
			line-height: 1.5rem;
			color: var(--nte-graa-mork);
			top: 12px;
			right: 16px;
		}
		${Title} {
			font-size: 1rem;
			line-height: 1.1719rem;
		}
		${Description} {
			font-size: 0.875rem;
			line-height: 1.125rem;
		}
		${Link} {
			font-weight: bold;
			font-size: 0.875rem;
			line-height: 1rem;
		}
	}
`;

export interface InnboksElementProps extends PanelProps {
	id?: string;
	title?: string;
	customTitle?: string;
	description?: string;
	contentType?: string;
	created?: string;
	read: boolean;
	setRead?: (meldingId: string, read: boolean) => void;
	hideIcon?: boolean;
}
export default function InnboksElement(props: InnboksElementProps) {
	const { track } = useTracking();
	return (
		<Wrapper style={props.style} className={props.className} onClick={props.onClick} withBorder>
			<Icon>
				{!props.hideIcon && props.read && <i className="far fa-envelope-open" />}
				{!props.hideIcon && !props.read && <i className="fa fa-envelope" />}
			</Icon>
			<div>
				<Title>{props.customTitle}</Title>

				<Description>{props.description}</Description>
				<Link
					href={props.href}
					target="_blank"
					onClick={() => {
						track('Message Opened', { title: props.customTitle });
						if (!props.read) {
							props.setRead?.(props.id, true);
						}
					}}>
					{props.title}
				</Link>
				<Date>{props.created}</Date>
			</div>
		</Wrapper>
	);
}
