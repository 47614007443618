import { addMinutes, format } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import { Retning } from 'generated/graphql-types';
import { ExportStatuses, useExportStatus } from 'web/hooks/useEksportStatus';
import { HentForbrukDocument } from 'web/hooks/useForbruk.graphql-gen';
import { useTracking } from 'web/hooks/useTracking';
import apolloClient from 'web/lib/apolloClient';
import asyncWait from 'web/lib/asyncWait';
import { Opploesninger, fyllInnManglendeVerdierForbruk, getIntervalFromOpploesning, getPeriodeFromOpploesning, opploesningToString } from 'web/lib/dates.utils';
import { utils, writeFile } from 'xlsx';

interface ForbruksEksportProps {
	orgid: string;
	fraDato: Date;
	tilDato: Date;
	opploesning: Opploesninger;
	maalere: string[];
	fakturamerker: string[];
	naeringskoder: string[];
}
export default function useForbruksEksport() {
	const { track } = useTracking();
	const { state, setState } = useExportStatus();

	async function eksporter({ orgid, fraDato, tilDato, opploesning, maalere, fakturamerker, naeringskoder }: ForbruksEksportProps) {
		setState({ ...state, status: ExportStatuses.DOWNLOADING, statusText: 'Forbereder nedlasting', progress: 0 });

		await asyncWait(100);

		const timeInterval = getIntervalFromOpploesning(fraDato, tilDato, opploesning);
		let combinedData = [];
		for (let i = 0; i < timeInterval?.length; i++) {
			setState({
				...state,
				status: ExportStatuses.DOWNLOADING,
				statusText: `Laster ned ${i + 1} av ${timeInterval?.length} ${opploesningToString(opploesning, true)}`,
				progress: (i + 1) / timeInterval?.length,
			});

			await asyncWait(200);

			let { fra: startTime, til: endTime } = getPeriodeFromOpploesning(timeInterval[i], opploesning);
			if (startTime < fraDato) {
				startTime = fraDato;
			}
			if (endTime > tilDato) {
				endTime = tilDato;
			}

			const { data, errors } = await apolloClient.query({
				query: HentForbrukDocument,
				variables: {
					orgid,
					fraDato: format(startTime, 'yyyy-MM-dd', { locale: nbLocale }),
					tilDato: format(addMinutes(endTime, 1), 'yyyy-MM-dd', { locale: nbLocale }),
					opploesning: Opploesninger.time,
					retning: Retning.forbruk,
					maalere,
					fakturamerker,
					naeringskoder,
				},
				fetchPolicy: 'no-cache',
			});

			if (errors?.length > 0) {
				setState({ ...state, status: ExportStatuses.ERROR, error: errors.toString(), statusText: ``, progress: 0 });
				return;
			}

			const verdier = data?.bedrift?.forbruksdetaljer?.verdier || [];

			for (let v = 0; v < verdier?.length; v++) {
				combinedData.push(verdier[v]);
			}
		}

		setState({ ...state, status: ExportStatuses.PACKAGING, statusText: `Pakker sammen data`, progress: 0 });

		combinedData = fyllInnManglendeVerdierForbruk({ data: combinedData, fra: fraDato, til: tilDato, opploesning: Opploesninger.time });

		let formattedSheetData = [];
		let hasProduksjonsData = false;

		for (let i = 0; i < combinedData?.length; i++) {
			if (combinedData[i].eksportert) {
				hasProduksjonsData = true;
			}
		}

		for (let i = 0; i < combinedData?.length; i++) {
			let elementDato = new Date(combinedData[i].fraTid);

			formattedSheetData.push([
				format(elementDato, 'yyyy', { locale: nbLocale }),
				format(elementDato, 'MM', { locale: nbLocale }),
				format(elementDato, 'dd', { locale: nbLocale }),
				format(elementDato, 'HH', { locale: nbLocale }),
				combinedData[i].importert,
				hasProduksjonsData ? combinedData[i].eksportert : '',
			]);
		}

		await asyncWait(400);

		let maalerLabel = 'for alle målere';

		let filterLabels = [];

		if (maalere && maalere?.length) {
			filterLabels.push(maalere.join(', '));
		}
		if (fakturamerker && fakturamerker?.length) {
			filterLabels.push(fakturamerker.join(', '));
		}
		if (naeringskoder && naeringskoder?.length) {
			filterLabels.push(naeringskoder.join(', '));
		}
		if (filterLabels?.length) {
			maalerLabel = filterLabels.join(', ');
		}

		const filename = `Forbruk-eksport-fra-${format(fraDato, 'yyyy-MM-dd', { locale: nbLocale })} til ${format(tilDato, 'yyyy-MM-dd', {
			locale: nbLocale,
		})}`;

		let sheetData = [[filename], [], ['År', 'Måned', 'Dag', 'Time', 'Kjøpt (kWh)', hasProduksjonsData ? 'Solgt (kWh)' : '']];

		const book = utils.book_new();
		const sheet = utils.aoa_to_sheet([...sheetData, ...formattedSheetData]);

		utils.book_append_sheet(book, sheet, 'sheet1');

		writeFile(book, `${filename.replace(/([^A-Za-z0-9]+)/g, '-')}.xls`);

		track('Data Exported', { label: 'forbruksdata' });

		await asyncWait(400);

		setState({ ...state, status: ExportStatuses.HIDDEN, statusText: ``, progress: 0 });
	}

	return { eksporter, state, setState };
}
