import { format, startOfDay } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import BubbleTabSelect from 'web/components/molecules/BubbleTabSelect';
import { device } from 'web/GlobalStyling';
import { useTracking } from 'web/hooks/useTracking';
import { addTimeFraOpploesning, getDatoPeriodeFormat, Opploesninger } from 'web/lib/dates.utils';
import { SelectItem } from 'web/lib/filter.utils';

const tidsrammer: SelectItem[] = [
	{
		label: 'År',
		value: Opploesninger.maned,
	},
	{
		label: 'Måned',
		value: Opploesninger.dag,
	},
	{
		label: 'Dag',
		value: Opploesninger.time,
	},
];

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const DatePickerWrapper = styled.div`
	background: inherit;
	max-width: 350px;
	width: 90%;
	.react-datepicker__header,
	.react-datepicker__navigation {
		display: none;
	}

	@media ${device.mobile} {
		width: 100%;
		max-width: inherit;
	}
`;
const DatePickerHeader = styled.div`
	display: flex;
	justify-content: space-between;
	justify-items: center;
`;
const StyledNextPeriodButton = styled.button`
	border: none;
	background: transparent;
	padding: 1rem;
	line-height: 1;
	& i {
		color: var(--nte-blaa);
	}
	:disabled {
		opacity: 0.7;
		& i {
			color: var(--nte-graa-mork);
		}
	}
	:focus {
		border-radius: 50%;
	}
`;
const CalendarToggleButton = styled.button`
	background: none;
	border: none;
	font-size: 1rem;
	font-weight: bold;
	:disabled {
		color: var(--nte-sort);
	}
`;
const CalendarIconWrapper = styled.span`
	margin-left: 0.5rem;
`;
const DatePickerStyleBase = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	.react-datepicker {
		font-family: Roboto;
		border: none;
		background: transparent;
		&__header {
			background: none;
			border: none;
			margin-bottom: 12px;
		}
		&__month-text {
			padding: 6px 0;
		}
	}
`;

export interface DatePickerProps {
	selectedDate: Date;
	onChange: (newDate: Date) => void;
	maxDate?: Date;
	opploesning?: Opploesninger;
	onChangeOpploesning?: (nyOpploesning: Opploesninger) => void;
	style?: React.CSSProperties;
}
export default function DatePicker({ selectedDate, onChange, maxDate, opploesning, onChangeOpploesning, style }: DatePickerProps) {
	const { track } = useTracking();
	const [showDatepicker, setShowDatepicker] = useState(false);

	if (opploesning && opploesning !== Opploesninger.maned && opploesning !== Opploesninger.dag && opploesning !== Opploesninger.time) {
		console.error(`Wrong opploesning type: ${opploesning}, can only be maned, dag or time`);
	}

	function skalViseDatovelger() {
		return opploesning !== Opploesninger.maned;
	}

	function trackDateChange(newValue: any, oldValue: any, props: any) {
		const filterProps = { newValue: newValue, oldValue: oldValue, ...props };
		track('Date changed', filterProps);
	}

	return (
		<Wrapper style={style}>
			<DatePickerWrapper>
				{onChangeOpploesning && (
					<BubbleTabSelect
						options={tidsrammer}
						selected={opploesning}
						onChange={(nyOpploesning: Opploesninger) => {
							onChangeOpploesning(nyOpploesning);
							trackDateChange(nyOpploesning, opploesning, {
								label: 'Oppløsning endret',
							});
						}}
						style={{ marginBottom: '0.5rem' }}
					/>
				)}

				<DatePickerHeader>
					<StyledNextPeriodButton
						onClick={() => {
							const newDate = addTimeFraOpploesning(new Date(selectedDate), -1, opploesning);
							onChange(newDate);
							trackDateChange(newDate, selectedDate, { label: 'Valgt dato gått tilbake' });
						}}>
						<i className="fa fa-arrow-left left-push" />
					</StyledNextPeriodButton>

					<CalendarToggleButton disabled={!skalViseDatovelger()} onClick={() => setShowDatepicker(!showDatepicker)}>
						{format(new Date(selectedDate), getDatoPeriodeFormat(opploesning), {
							locale: nbLocale,
						})}
						{skalViseDatovelger() && (
							<CalendarIconWrapper>
								<i className="far fa-calendar-alt" />
							</CalendarIconWrapper>
						)}
					</CalendarToggleButton>

					<StyledNextPeriodButton
						disabled={(maxDate || new Date()) <= addTimeFraOpploesning(startOfDay(new Date(selectedDate)), 1, opploesning)}
						onClick={() => {
							const newDate = addTimeFraOpploesning(new Date(selectedDate), 1, opploesning);
							onChange(newDate);
							trackDateChange(newDate, selectedDate, { label: 'Valgt dato gått fram' });
						}}>
						<i className="fa fa-arrow-right right-push" />
					</StyledNextPeriodButton>
				</DatePickerHeader>

				{skalViseDatovelger() && showDatepicker && (
					<DatePickerStyleBase>
						<ReactDatePicker
							inline
							locale="nb"
							disabledKeyboardNavigation
							onChange={(newDate: Date) => {
								onChange(newDate);
								trackDateChange(newDate, selectedDate, { label: 'Valgt dato endret' });
							}}
							selected={new Date(selectedDate)}
							showMonthYearPicker={opploesning === Opploesninger.dag}
							maxDate={maxDate || new Date()}
						/>
					</DatePickerStyleBase>
				)}
			</DatePickerWrapper>
		</Wrapper>
	);
}
