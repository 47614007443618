import React from 'react';
import styled from 'styled-components';

const ErrorListWrapper = styled.div`
	background-color: var(--varsel-roed-10);
	border-radius: var(--border-radius);
	padding: 0.5rem 1rem;
	position: relative;
	clear: both;
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
	width: max-content;
	max-width: 100%;
`;

const Header = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	font-size: 1rem;

	& i {
		color: var(--varsel-roed);
	}
	& span {
		font-weight: bold;
	}
`;

const Errors = styled.div`
	padding: 0.3rem;
	margin-top: 0.1rem;
`;

interface ErrorListProps {
	formErrors: any;
}

export default function ErrorList({ formErrors }: ErrorListProps) {
	const array = Object.keys(formErrors)
		.map(key => formErrors[key])
		.filter(d => d?.length > 0);
	return (
		<ErrorListWrapper>
			<Header>
				<i className="fas fa-exclamation-circle" />
				<span>Feil og mangler i skjemaet</span>
			</Header>
			<Errors>
				{array.map(error => (
					<li key={error}>{error}</li>
				))}
			</Errors>
		</ErrorListWrapper>
	);
}
