import { useApolloClient } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import { HentFakturaerDocument } from 'web/hooks/useFakturaer.graphql-gen';

interface useFakturaerProps {
	orgid: string;
	fraDato?: string;
	tilDato?: string;
	filter?: string;
	maalere?: string[];
	side?: number;
	antall?: number;
	skip?: boolean;
	fakturamerker?: string[];
	naeringskoder?: string[];
}

export default function useFakturaer({ orgid, fraDato, tilDato, filter, maalere, side = 1, antall = 50 }: useFakturaerProps) {
	const [alleFakturaer, setAlleFakturaer] = useState({ fakturaer: [], antallSider: 1 });
	const [fakturaerLoading, setFakturaerLoading] = useState(false);
	const [fakturaerError, setFakturaerError] = useState(null);
	const client = useApolloClient();

	const defaultVariables = {
		orgid,
		fraDato,
		tilDato,
		antall,
		side,
		filter: filter || undefined,
		maalere,
	};

	async function fetchFakturaer({ variables }: any = {}) {
		setFakturaerLoading(true);
		setFakturaerError(null);
		let response = null;
		try {
			response = await client.query({
				query: HentFakturaerDocument,
				variables: { ...defaultVariables, ...variables },
			});
			if (response.errors) {
				setFakturaerError(response.errors);
			}
		} catch (e) {
			setFakturaerError(e);
			setFakturaerLoading(false);
		}
		return response;
	}

	useEffect(() => {
		async function fetchFaktura() {
			const fetchResult = await fetchFakturaer();
			if (!fetchResult) {
				return;
			}
			const { data } = fetchResult;

			setAlleFakturaer({ fakturaer: data?.bedrift?.fakturaer?.fakturaer, antallSider: data?.bedrift?.fakturaer?.antallSider });
			setFakturaerLoading(false);
		}

		fetchFaktura();
	}, [orgid, fraDato, tilDato, antall, side, filter, maalere?.join()]);

	return {
		loading: fakturaerLoading,
		error: fakturaerError,
		fetchMoreFakturaer: async ({ variables }: any) => {
			const nyeFakturaer = await fetchFakturaer({ variables: { ...defaultVariables, side: variables.side } });
			if (!nyeFakturaer) {
				return;
			}

			setAlleFakturaer({
				fakturaer: [...alleFakturaer?.fakturaer, ...nyeFakturaer?.data?.bedrift?.fakturaer?.fakturaer],
				antallSider: nyeFakturaer?.data?.bedrift?.fakturaer?.antallSider,
			});
			setFakturaerLoading(false);
		},
		fakturaer: alleFakturaer?.fakturaer || [],
		antallSider: alleFakturaer?.antallSider || 1,
	};
}
