import styled from 'styled-components';
import { device } from 'web/GlobalStyling';

const Button = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 1.5rem;

	background: var(--nte-blaa-uu);
	border: 2px solid var(--nte-blaa-uu);
	box-sizing: border-box;
	border-radius: var(--border-radius-small);

	color: var(--white);
	font-size: 1rem;
	font-weight: normal;

	i {
		margin-right: 1rem;
	}

	&:hover,
	&:active,
	&:focus {
		opacity: 0.9;
	}
	&:disabled {
		opacity: 0.7;
		cursor: auto;
	}
`;

export const SecondaryButton = styled(Button)`
	background: var(--white);
	border: 2px solid var(--nte-blaa-uu);
	color: var(--nte-blaa-uu);
	font-weight: 500;

	&:hover,
	&:active,
	&:focus {
		opacity: 1;
		background: var(--nte-blaa-uu);
		color: var(--white);
	}
	&:disabled {
		opacity: 1;
		background: var(--white);
		color: var(--nte-graa-mork);
	}
`;

export const TertiaryButton = styled(Button)`
	background: var(--white);
	border: 2px solid var(--nte-border-color);
	color: var(--nte-sort);
	font-weight: 500;
	border-radius: 0;

	&:hover,
	&:active,
	&:focus {
		opacity: 1;
		background: var(--white);
		border: 2px solid var(--nte-blaa-uu);
		color: var(--nte-blaa-uu);
	}
	&:disabled {
		opacity: 1;
		background: var(--white);
		color: var(--nte-graa-mork);
		border: 2px solid var(--nte-border-color);
	}
`;

export const DeleteButton = styled(Button)`
	background: var(--white);
	border: 2px solid var(--varsel-roed);
	color: var(--varsel-roed);
	font-weight: 500;

	&:hover,
	&:active,
	&:focus {
		opacity: 1;
		background: var(--varsel-roed);
		color: var(--white);
		font-weight: normal;
	}
	&:disabled {
		opacity: 1;
		background: var(--white);
		color: var(--nte-graa-mork);
	}
`;

export const MobilFilterButton = styled(Button)`
	display: flex;
	width: max-content;
	background: var(--white);
	color: var(--nte-blaa);
	border: 1px solid var(--nte-graa-lys);
	padding: 0 7px;
	height: 34px;
	line-height: 34px;
	min-width: 34px;
	border-radius: 34px;
	margin: 0;
	box-shadow: var(--clickable-box-shadow);
	align-self: end;
	&:hover,
	&:active,
	&:focus,
	&:disabled {
		box-shadow: none;
	}
`;

export const MobilFilterButtonCounter = styled.span`
	display: inline-block;
	height: 1rem;
	width: 1rem;
	background: var(--nte-blaa);
	color: var(--white);
	font-size: 0.75rem;
	line-height: 1rem;
	text-align: center;
	border-radius: 50%;
	margin: 0 4px;
`;

// Remember: type: "button"
export const StyledLinkButton = styled.a`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 5px 25px;

	background: var(--nte-blaa-uu);
	border: 2px solid var(--nte-blaa-uu);
	box-sizing: border-box;
	border-radius: var(--border-radius-small);

	color: var(--white);
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.5;
	text-decoration: none;

	i {
		margin-right: 1rem;
	}

	&:hover,
	&:active,
	&:focus {
		opacity: 0.9;
		text-decoration: none;
	}
	&:disabled {
		opacity: 0.7;
		cursor: auto;
		text-decoration: none;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	@media ${device.mobile} {
		gap: 0.5rem;
	}
`;

export default Button;
