// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentTilgjengeligeModulerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HentTilgjengeligeModulerQuery = { moduler?: Array<string> };


export const HentTilgjengeligeModulerDocument = gql`
    query hentTilgjengeligeModuler {
  moduler: hentTilgjengeligeModuler
}
    `;

/**
 * __useHentTilgjengeligeModulerQuery__
 *
 * To run a query within a React component, call `useHentTilgjengeligeModulerQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentTilgjengeligeModulerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentTilgjengeligeModulerQuery({
 *   variables: {
 *   },
 * });
 */
export function useHentTilgjengeligeModulerQuery(baseOptions?: Apollo.QueryHookOptions<HentTilgjengeligeModulerQuery, HentTilgjengeligeModulerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentTilgjengeligeModulerQuery, HentTilgjengeligeModulerQueryVariables>(HentTilgjengeligeModulerDocument, options);
      }
export function useHentTilgjengeligeModulerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentTilgjengeligeModulerQuery, HentTilgjengeligeModulerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentTilgjengeligeModulerQuery, HentTilgjengeligeModulerQueryVariables>(HentTilgjengeligeModulerDocument, options);
        }
export type HentTilgjengeligeModulerQueryHookResult = ReturnType<typeof useHentTilgjengeligeModulerQuery>;
export type HentTilgjengeligeModulerLazyQueryHookResult = ReturnType<typeof useHentTilgjengeligeModulerLazyQuery>;
export type HentTilgjengeligeModulerQueryResult = Apollo.QueryResult<HentTilgjengeligeModulerQuery, HentTilgjengeligeModulerQueryVariables>;