import React from 'react';
import styled from 'styled-components';
import Paragraph from 'web/components/atoms/Paragraph';

const StyledInputLabel = styled.label``;

const StyledInput = styled.input`
	border: var(--nte-border);
	border-radius: var(--border-radius-smallest);
	width: 100%;
	padding: 0.75rem;
	&::placeholder {
		color: var(--nte-graa-medium);
	}
`;

interface InputFieldProps {
	name: string;
	type?: string;
	label?: string;
	placeholder?: string;
}
export default function InputField({ name, type, label, placeholder, ...props }: InputFieldProps) {
	return (
		<label>
			<Paragraph>{label}</Paragraph>
			<StyledInputLabel>
				<StyledInput name={name} type={type} placeholder={placeholder} />
			</StyledInputLabel>
		</label>
	);
}
