import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Dropdown from 'web/components/molecules/Dropdown';
import { useTracking } from 'web/hooks/useTracking';
import { Tag } from 'web/templates/SingleAnleggTemplate';

//#region styling
const TagSelectWrapper = styled.div`
	display: inline;
	color: var(--nte-sort);
`;

const TagButton = styled.button`
	padding: 0.25rem 1rem;
	margin: 0 0.5rem 0.5rem 0;

	font-size: 0.875rem;
	background: var(--white);

	border: 1px solid var(--nte-blaa-uu);
	border-radius: var(--border-radius-small);
	i {
		color: var(--nte-blaa-uu);
	}
`;

const AddTagButton = styled(TagButton)`
	padding: 0.1875rem 0.9375rem; // one px less than tag to make up for thicker border
	border: 2px dashed var(--nte-blaa-uu);
`;

const AddExistingTagButton = styled(TagButton)`
	border: var(--nte-border);
`;

const OptionList = styled.div`
	padding: 0.75rem;
	box-sizing: border-box;
`;

const AddNewTagButton = styled(TagButton)`
	border: none;
	margin: 0;
	white-space: nowrap;
	i {
		margin-right: 0.5rem;
	}
`;

const StyledForm = styled.form`
	display: flex;
	gap: 0.25rem;
`;

const AddNewTagInput = styled.input`
	padding: 0.25rem 1rem;
	max-width: 8.5rem;
	background: var(--white);
	font-size: 0.875rem;
	box-sizing: border-box;
	border: var(--nte-border);
	border-radius: var(--border-radius-smallest);
`;

const AddNewTagSubmitButton = styled.button`
	border: none;
	width: 1.675rem;
	background: var(--nte-blaa);
	color: var(--white);
	border-radius: var(--border-radius-smallest);
`;
//#endregion

interface TagSelectProps {
	values: Tag[];
	options?: Tag[];
	addLabel?: string;
	addNewLabel?: string;
	doAllowAddNewTag?: boolean;
	onRemove: (tag: Tag) => void;
	onAdd: (tag: Tag) => void;
	onAddNew?: (tagLabel: string) => void;
}

export default function TagSelect({
	values = [],
	options = [],
	addLabel = 'Legg til tag',
	addNewLabel = 'Legg til ny tag',
	doAllowAddNewTag = false,
	onRemove = () => {},
	onAdd = () => {},
	onAddNew = () => {},
}: TagSelectProps) {
	const { track } = useTracking();
	const inputElement = useRef(null);
	const addTagButtonElement = useRef(null);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isAddingNewTag, setIsAddingNewTag] = useState(false);

	function doClose() {
		setIsDropdownOpen(false);
		setIsAddingNewTag(false);
	}

	return (
		<TagSelectWrapper>
			{values.map((tag: any) => (
				<TagButton key={tag.value} onClick={() => onRemove(tag)}>
					{tag.label} <i className="far fa-times" style={{ marginLeft: '5px' }} />
				</TagButton>
			))}

			{(options?.length || doAllowAddNewTag) && (
				<AddTagButton
					onClick={() => {
						track('Button Clicked', { label: addLabel });
						setIsDropdownOpen(!isDropdownOpen);
					}}
					ref={addTagButtonElement}>
					{addLabel} <i className="far fa-plus" style={{ marginLeft: '5px' }} />
				</AddTagButton>
			)}

			{isDropdownOpen && (
				<Dropdown onClose={doClose} position={{ top: -10, left: addTagButtonElement.current?.offsetLeft }} buttonRef={addTagButtonElement}>
					<OptionList>
						{options.map((tag: any) => (
							<AddExistingTagButton key={tag.value} onClick={() => onAdd(tag)}>
								{tag.label}
							</AddExistingTagButton>
						))}
						{doAllowAddNewTag && (
							<div>
								{(isAddingNewTag && (
									<StyledForm
										onSubmit={(e: any) => {
											e.preventDefault();
											e.stopPropagation();

											onAddNew(inputElement.current.value);
											inputElement.current.value = '';
										}}>
										<AddNewTagInput
											onKeyDown={(e: any) => {
												if (e.key === 'Enter') {
													e.preventDefault();
													e.stopPropagation();

													onAddNew(e.target.value);
													inputElement.current.value = '';
												}
												if (e.key === 'Escape') {
													setIsAddingNewTag(false);
												}
											}}
											name="name"
											ref={inputElement}
										/>
										<AddNewTagSubmitButton>
											<i className="fa fa-check" />
										</AddNewTagSubmitButton>
									</StyledForm>
								)) || (
									<AddNewTagButton
										onClick={() => {
											setIsAddingNewTag(true);
											track('Button Clicked', { label: addNewLabel });
											setTimeout(() => {
												inputElement.current?.focus();
											}, 1);
										}}>
										<i className="far fa-plus-circle" />
										{addNewLabel}
									</AddNewTagButton>
								)}
							</div>
						)}
					</OptionList>
				</Dropdown>
			)}
		</TagSelectWrapper>
	);
}
