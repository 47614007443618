import React, { useState } from 'react';
import Button, { ButtonWrapper } from 'web/components/atoms/Button';
import Modal from 'web/components/molecules/Modal';
import { useHentTarifferQuery } from 'web/pages/AdminPage.graphql-gen';

const MODAL_NAME = 'LeggTilTariffModal';

interface LeggTilTariffModalProps {
	setIsNewTariffModalOpen: (isOpen: boolean) => void;
	leggTilTariff: (tariff: string) => void;
	godkjenteTariffer: string[];
}
export function LeggTilTariffModal({ setIsNewTariffModalOpen, leggTilTariff, godkjenteTariffer }: LeggTilTariffModalProps) {
	const [addTariffInput, setTariffInput] = useState('');
	const {
		loading,
		error,
		data: { tariffer } = { tariffer: [] },
	} = useHentTarifferQuery({
		fetchPolicy: 'network-only',
	});
	return (
		<Modal
			title="Legg til tariff"
			onClose={() => {
				setIsNewTariffModalOpen(false);
			}}>
			<ButtonWrapper>
				<select title="Tariffer" value={addTariffInput} onChange={e => setTariffInput(e.target.value)}>
					{tariffer
						.filter(t => !godkjenteTariffer?.includes(t.tariffId))
						.map(tariff => (
							<option value={tariff.tariffId} key={tariff.tariffId}>
								{tariff.navn}: {tariff.tariffId}
							</option>
						))}
				</select>

				<Button
					onClick={() => {
						if (addTariffInput) {
							leggTilTariff(addTariffInput);
							setIsNewTariffModalOpen(false);
						}
					}}>
					<i className="fas fa-plus" />
					Legg til
				</Button>
			</ButtonWrapper>
		</Modal>
	);
}

export default {
	component: LeggTilTariffModal,
	name: MODAL_NAME,
};
