import { format } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ClearButton from 'web/components/atoms/ClearButton';
import FlexPageWrapper from 'web/components/atoms/FlexPageWrapper';
import PageTitle from 'web/components/atoms/PageTitle';
import Panel from 'web/components/atoms/Panel';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import { DetailsListElement } from 'web/components/organisms/DetailsListElement';
import { MeldingElement } from 'web/templates/admin/MeldingerTemplate';

const DetailsList = styled.ul`
	padding: 0;
	margin-bottom: 20px;
`;
const EditButton = styled(ClearButton)`
	position: absolute;
	padding: 0.25rem;
	i {
		margin-right: 6px;
	}
`;

interface SingleMeldingProps {
	melding: MeldingElement;
	loading: boolean;
}
export function SingleMeldingTemplate({
	melding = {
		id: '',
		customTitle: '',
		contentType: '',
		description: '',
		title: '',
		url: '',
		created: '',
		companies: [],
	},
	loading,
}: SingleMeldingProps) {
	const { t } = useTranslation('admin');
	const history = useHistory();

	return (
		<FlexPageWrapper>
			<PageTitle>{melding.customTitle}</PageTitle>

			<LoadingOverlay loading={loading} text="Laster inn melding" />

			<Panel withBorder>
				<DetailsList>
					<DetailsListElement
						label={t('SingleMelding.fil')}
						value={
							<a href={melding.url} target="_blank">
								{melding.title}
							</a>
						}
					/>
					<DetailsListElement label={t('SingleMelding.melding')} value={melding.description || ''} />
					<DetailsListElement
						label={t('SingleMelding.tid')}
						value={melding.created ? format(new Date(melding.created), 'dd.MM.yyyy HH:mm', { locale: nbLocale }) : ''}
					/>
					<DetailsListElement
						label={t('SingleMelding.mottakere')}
						value={
							<>
								{melding.companies?.map(org => org.kundeNavn).join(', ')}
								<EditButton
									type="button"
									onClick={() => {
										history.push('./rediger/');
									}}>
									<i className="fas fa-pencil" />
									Rediger
								</EditButton>
							</>
						}
					/>
				</DetailsList>
			</Panel>
		</FlexPageWrapper>
	);
}
