import { WebStorageStateStore } from 'oidc-client-ts';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { ToastContainer } from 'react-toastify';
import { App } from 'web/App';
import { env } from 'web/env';
import { logError } from 'web/hooks/useErrorLogging';
import { persistCacheHook } from 'web/lib/apolloClient';
import Providers from 'web/Providers';

if (env.REACT_APP_SEGMENT_WRITE_KEY) {
	window.analytics.load(env.REACT_APP_SEGMENT_WRITE_KEY);
}

if ('serviceWorker' in navigator) {
	navigator.serviceWorker
		.register('/Serviceworker.js')
		.then(function (reg) {
			//console.log('Successfully registered service worker', reg);
		})
		.catch(function (err) {
			console.warn('Error whilst registering service worker', err);
		});
}

window.onerror = async (msg, url, lineNo, columnNo, error) => {
	const message = `${url} ${msg} [${lineNo}:${columnNo}] ${error?.stack}`;
	logError(message);
	return false; // If returns false, allows firing default event handler
};

async function Start() {
	// Make sure cache is initialized before first query
	await persistCacheHook;
	const container = document.getElementById('app-root');
	const root = createRoot(container);

	const oidcStateStore = new WebStorageStateStore({
		store: window.localStorage,
	});

	root.render(
		<AuthProvider
			authority={`${env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL}/realms/${env.REACT_APP_KEYCLOAK_REALM}`}
			client_id={env.REACT_APP_KEYCLOAK_AUTH_RESOURCE}
			redirect_uri={window.location.href}
			userStore={oidcStateStore}>
			<Providers>
				<App />
				<ToastContainer hideProgressBar={true} />
			</Providers>
		</AuthProvider>
	);
}

Start();
