import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import DropdownButton, { DropdownAlign } from 'web/components/molecules/DropdownButton';
import { device } from 'web/GlobalStyling';

const Wrapper = styled.div<StyledWrapperProps>`
	display: flex;
	margin-right: 5px;
	margin-top: 10px;
	${props =>
		!props.hasManyTenants &&
		css`
			flex-direction: column;
			button {
				font-size: 0.75rem;
				padding-top: 0;
				padding-right: 11px;
			}
		`}
`;

const Name = styled.span`
	font-size: 1rem;
	font-weight: 700;
	color: var(--nte-graa-mork);
	margin-top: 0.25rem;
	margin-right: 11px;
	display: block;
	text-align: end;
	@media ${device.mobile} {
		display: none;
	}
`;

const StyledDropdownButton = styled(DropdownButton)`
	padding: 10px 5px 10px 0;
`;

interface AvatarProps {
	image: string;
}

const Avatar = styled.span<AvatarProps>`
	height: 42px;
	width: 42px;
	display: inline-block;
	border-radius: 50%;
	background: url(${props => props.image}) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
`;

const UserViewBackground = styled.div`
	padding: 1rem;
	text-align: left;
	max-width: 414px;
	min-width: 320px;
	width: 100%;
	box-sizing: border-box;
`;

export const UserViewRouterLink = styled(Link)`
	font-size: 1rem;
	line-height: 1.2;
	text-decoration: underline;
	display: inline-block;
	width: 100%;
	padding: 10px;
`;

export const UserViewLink = styled.a`
	font-size: 1rem;
	line-height: 1.2;
	text-decoration: underline;
	display: inline-block;
	width: 100%;
	padding: 10px;
`;
export const TenantName = styled.span`
	font-size: 0.75rem;
	font-weight: 700;
	color: var(--nte-graa-mork);
	margin-top: 0px;
	margin-right: 11px;
	display: block;
	text-align: right;
	@media ${device.mobile} {
		display: none;
	}
`;

export interface UserHeaderLabelProps {
	name?: string;
	tenantName?: string;
	hasManyTenants?: boolean;
	profileImage?: string;
	children?: ReactNode;
}

interface StyledWrapperProps {
	hasManyTenants?: boolean;
}

export default function UserHeaderLabel({ name = '', tenantName = '', hasManyTenants = false, profileImage = '', children = null }: UserHeaderLabelProps) {
	return (
		<Wrapper hasManyTenants={hasManyTenants}>
			<div>
				<Name>{name}</Name>
				<TenantName>{tenantName}</TenantName>
			</div>
			{hasManyTenants ? (
				<StyledDropdownButton label="" isBlank={true} align={DropdownAlign.RIGHT}>
					<UserViewBackground>{children}</UserViewBackground>
				</StyledDropdownButton>
			) : (
				<div>{children}</div>
			)}
			{profileImage && <Avatar image={profileImage} />}
		</Wrapper>
	);
}
