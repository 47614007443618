import styled from 'styled-components';

/**
 * center text in this component
 *
 * @name Center
 * @example
 * ```jsx
 * 	<Center>
 * 		centered text!
 * 	</Center>
 * ```
 */
const Center = styled.div`
	text-align: center;
`;

export const CenterBox = styled.div`
	margin: auto;
`;

export default Center;
