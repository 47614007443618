import React from 'react';
import DropdownButton, { DropdownAlign } from 'web/components/molecules/DropdownButton';
import FilterBarDropdownSelect from 'web/components/molecules/FilterBarDropdownSelect';
import { FilterDataSelectElement } from 'web/lib/filter.utils';

export default function FilterBarSelect(props: FilterDataSelectElement) {
	return (
		<DropdownButton
			label={props.isMultiselect || props.isSortSelect ? props.title : props.options?.find(o => o.value == props.selected)?.label || props.title}
			active={props.active}
			selectedCount={props.isMultiselect ? props.selected?.length : null}
			align={props.dropdownAlign || DropdownAlign.CENTER}
			isBlank={props.isBlank}
			style={props.filterBarStyle}>
			<FilterBarDropdownSelect
				options={props.options}
				selected={props.selected}
				emptyMsg={props.emptyMsg}
				useSearch={props.useSearch}
				searchPlaceholder={props.searchPlaceholder || (props.title ? `Søk i ${props.title.toLowerCase()}er...` : 'Søk...')}
				isMultiselect={props.isMultiselect}
				isDeselectable={props.isDeselectable}
				isNarrowDropdown={props.isNarrowDropdown}
				onChange={props.onChange}
			/>
		</DropdownButton>
	);
}
