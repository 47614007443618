import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SecondaryButton } from 'web/components/atoms/Button';
import PageTitle from 'web/components/atoms/PageTitle';
import Paragraph from 'web/components/atoms/Paragraph';
import Push from 'web/components/atoms/Push';
import useAuthHelpers from 'web/hooks/useAuthHelpers';
import { GenericLayout } from 'web/layouts/GenericLayout';

const ErrorPageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background: var(--white);
	width: 100%;
	height: 100%;
	text-align: center;
	justify-content: space-between;
	align-items: center;
`;

const ErrorContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;
`;

interface ErrorTemplateProps {
	title?: string;
	message?: any;
	messageHtml?: string;
	hideBackButton?: boolean;
}
export default function ErrorTemplate({ title, message, messageHtml, hideBackButton }: ErrorTemplateProps) {
	const tempMessage = '';
	const { logout } = useAuthHelpers();
	return (
		<GenericLayout>
			<ErrorPageWrapper>
				<ErrorContentWrapper>
					<PageTitle>{title}</PageTitle>
					{tempMessage && (
						<Paragraph
							style={{
								fontWeight: 'bold',
							}}
							dangerouslySetInnerHTML={{ __html: tempMessage }}
						/>
					)}
					{message && <Paragraph>{message}</Paragraph>}
					{messageHtml && <div dangerouslySetInnerHTML={{ __html: messageHtml }} />}
					{!hideBackButton && <Link to={'/'}>Tilbake til Bedriftsportalen</Link>}
					<Push />
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<SecondaryButton
							onClick={() => {
								logout();
							}}>
							Logg ut
						</SecondaryButton>
					</div>
				</ErrorContentWrapper>
			</ErrorPageWrapper>
		</GenericLayout>
	);
}
