import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDashboardLayoutScrollElementRef } from 'web/components/organisms/DashboardLayout';
import useFakturaer from 'web/hooks/useFakturaer';
import { useTenant } from 'web/hooks/useTenant';
import { FakturaFilterOptions, FilterElements } from 'web/lib/filter.utils';
import FakturaTabTemplate from 'web/templates/singleAnlegg/FakturaTabTemplate';

interface FakturaTabProps {
	anleggId: string;
}
export default function FakturaTab({ anleggId }: FakturaTabProps) {
	const PER_PAGE = Math.ceil(window.innerHeight / 50 + 10);
	const history = useHistory<any>();
	const tenant = useTenant();
	const currentPage = useRef(1);
	const lastScrollTop = useRef(0);
	const [fakturaFilter, setFakturaFilter] = useState('all');
	const layoutScrollViewRef = useDashboardLayoutScrollElementRef();
	const [displayScrollToTopButton, setDisplayScrollToTopButton] = useState(false);
	const { fakturaer, loading, fetchMoreFakturaer, antallSider } = useFakturaer({
		orgid: tenant.id,
		maalere: [anleggId],
		filter: fakturaFilter,
		antall: PER_PAGE,
	});
	const [isLoadingFetchMore, setIsLoadingFetchMore] = useState(false);

	// TODO: this exists twice, move to faktura utils?
	useEffect(() => {
		const lastFlereFakturaer = async () => {
			if (loading || currentPage.current >= antallSider) return;

			setIsLoadingFetchMore(true);
			currentPage.current++;
			await fetchMoreFakturaer({
				variables: {
					side: currentPage.current,
				},
			}).then(() => {
				setIsLoadingFetchMore(false);
			});
		};
		const handleScroll = (e: any) => {
			const BUFFER = 200;
			const scrollElement = e.target as HTMLDivElement;
			if (scrollElement.scrollTop === 0) {
				setDisplayScrollToTopButton(false);
				lastScrollTop.current = 0;
			}

			if (lastScrollTop.current > scrollElement.scrollTop && !displayScrollToTopButton) {
				setDisplayScrollToTopButton(true);
			} else if (lastScrollTop.current < scrollElement.scrollTop && displayScrollToTopButton) {
				setDisplayScrollToTopButton(false);
			}

			if (scrollElement.scrollTop > scrollElement.scrollHeight - scrollElement.clientHeight - BUFFER) {
				lastFlereFakturaer();
			}
			lastScrollTop.current = scrollElement.scrollTop;
		};
		layoutScrollViewRef.current?.addEventListener('scroll', handleScroll);

		return () => {
			layoutScrollViewRef.current?.removeEventListener('scroll', handleScroll);
		};
	}, [layoutScrollViewRef, antallSider, fetchMoreFakturaer]);

	return (
		<FakturaTabTemplate
			filterData={[
				{
					type: FilterElements.SELECT,
					title: '',
					options: FakturaFilterOptions,
					selected: fakturaFilter || 'all',
					useFilterDrawerTabSelect: true,
					filterDrawerStyle: { background: 'none', padding: 0 },
					onChange: (fakturaFilter: string) => {
						setFakturaFilter(fakturaFilter);
					},
				},
			]}
			loading={loading}
			loadingFetchMore={isLoadingFetchMore}
			fakturaer={fakturaer}
			onClickGoToTop={() => {
				layoutScrollViewRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
			}}
			displayScrollToTopButton={displayScrollToTopButton}
		/>
	);
}
