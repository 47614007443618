// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddFjuttMutationVariables = Types.Exact<{
  anleggsId: Types.Scalars['String'];
  orgId: Types.Scalars['String'];
}>;


export type AddFjuttMutation = { addFjutt?: { fjutt?: { deviceId?: string, anleggsId?: string, omregningsfaktor?: number }, harkUserToken?: { created?: string, accessToken?: string, harkUserId?: string, userId?: string }, error?: { type?: Types.AddFjuttErrorType, message?: string } } };


export const AddFjuttDocument = gql`
    mutation addFjutt($anleggsId: String!, $orgId: String!) {
  addFjutt(anleggsId: $anleggsId, orgId: $orgId) {
    fjutt {
      deviceId
      anleggsId
      omregningsfaktor
    }
    harkUserToken {
      created
      accessToken
      harkUserId
      userId
    }
    error {
      type
      message
    }
  }
}
    `;
export type AddFjuttMutationFn = Apollo.MutationFunction<AddFjuttMutation, AddFjuttMutationVariables>;

/**
 * __useAddFjuttMutation__
 *
 * To run a mutation, you first call `useAddFjuttMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFjuttMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFjuttMutation, { data, loading, error }] = useAddFjuttMutation({
 *   variables: {
 *      anleggsId: // value for 'anleggsId'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useAddFjuttMutation(baseOptions?: Apollo.MutationHookOptions<AddFjuttMutation, AddFjuttMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFjuttMutation, AddFjuttMutationVariables>(AddFjuttDocument, options);
      }
export type AddFjuttMutationHookResult = ReturnType<typeof useAddFjuttMutation>;
export type AddFjuttMutationResult = Apollo.MutationResult<AddFjuttMutation>;
export type AddFjuttMutationOptions = Apollo.BaseMutationOptions<AddFjuttMutation, AddFjuttMutationVariables>;