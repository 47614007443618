import { format } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import { utils, writeFile } from 'xlsx';

interface useUserEksportProps {
	invitasjoner: any[];
	brukere: any[];
}
export default async function useUserEksport({ invitasjoner = [], brukere = [] }: useUserEksportProps) {
	const filename = `Brukere-eksport-${format(new Date(), 'yyyy-MM-dd', { locale: nbLocale })}`;
	const book = utils.book_new();

	const invitasjonerSheetData = [['Inviterte brukere'], invitasjoner.map(i => i.epost)];
	const invitasjonerSheet = utils.aoa_to_sheet(invitasjonerSheetData);

	const brukereSheetData = [
		['Brukere'],
		['Fornavn', 'Etternavn', 'Epost', 'Moduler'],
		...brukere.map(m => [m.fornavn, m.etternavn, m.epost, m.moduler.join(',')]),
	];
	const brukereSheet = utils.aoa_to_sheet(brukereSheetData);

	utils.book_append_sheet(book, brukereSheet, 'Brukere');
	utils.book_append_sheet(book, invitasjonerSheet, 'Inviterte');

	writeFile(book, `${filename}.xls`);
}
