// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OppdaterEgenAnleggsbeskrivelseMutationVariables = Types.Exact<{
  egenAnleggsbeskrivelse: Types.Scalars['String'];
  maalepunktId: Types.Scalars['ID'];
  orgid: Types.Scalars['ID'];
}>;


export type OppdaterEgenAnleggsbeskrivelseMutation = { oppdaterEgenAnleggsbeskrivelse?: string };

export type AnleggslisteshortFragment = { __typename: 'Bedrift', anlegg?: Array<{ __typename: 'Anlegg', maalerpunktId?: string, tittel?: string, egenAnleggsbeskrivelse?: string }> };

export const AnleggslisteshortFragmentDoc = gql`
    fragment anleggslisteshort on Bedrift {
  __typename
  anlegg {
    maalerpunktId
    tittel
    egenAnleggsbeskrivelse
    __typename
  }
}
    `;
export const OppdaterEgenAnleggsbeskrivelseDocument = gql`
    mutation oppdaterEgenAnleggsbeskrivelse($egenAnleggsbeskrivelse: String!, $maalepunktId: ID!, $orgid: ID!) {
  oppdaterEgenAnleggsbeskrivelse(
    egenAnleggsbeskrivelse: $egenAnleggsbeskrivelse
    maalepunktId: $maalepunktId
    orgid: $orgid
  )
}
    `;
export type OppdaterEgenAnleggsbeskrivelseMutationFn = Apollo.MutationFunction<OppdaterEgenAnleggsbeskrivelseMutation, OppdaterEgenAnleggsbeskrivelseMutationVariables>;

/**
 * __useOppdaterEgenAnleggsbeskrivelseMutation__
 *
 * To run a mutation, you first call `useOppdaterEgenAnleggsbeskrivelseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOppdaterEgenAnleggsbeskrivelseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oppdaterEgenAnleggsbeskrivelseMutation, { data, loading, error }] = useOppdaterEgenAnleggsbeskrivelseMutation({
 *   variables: {
 *      egenAnleggsbeskrivelse: // value for 'egenAnleggsbeskrivelse'
 *      maalepunktId: // value for 'maalepunktId'
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useOppdaterEgenAnleggsbeskrivelseMutation(baseOptions?: Apollo.MutationHookOptions<OppdaterEgenAnleggsbeskrivelseMutation, OppdaterEgenAnleggsbeskrivelseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OppdaterEgenAnleggsbeskrivelseMutation, OppdaterEgenAnleggsbeskrivelseMutationVariables>(OppdaterEgenAnleggsbeskrivelseDocument, options);
      }
export type OppdaterEgenAnleggsbeskrivelseMutationHookResult = ReturnType<typeof useOppdaterEgenAnleggsbeskrivelseMutation>;
export type OppdaterEgenAnleggsbeskrivelseMutationResult = Apollo.MutationResult<OppdaterEgenAnleggsbeskrivelseMutation>;
export type OppdaterEgenAnleggsbeskrivelseMutationOptions = Apollo.BaseMutationOptions<OppdaterEgenAnleggsbeskrivelseMutation, OppdaterEgenAnleggsbeskrivelseMutationVariables>;