import { addMinutes, format, isDate } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import { Retning } from 'generated/graphql-types';
import { useEffect, useRef, useState } from 'react';
import { useHentForbrukQuery } from 'web/hooks/useForbruk.graphql-gen';
import { useGraphProps } from 'web/hooks/useKostnad';
import useNumbers from 'web/hooks/useNumbers';
import { useTenant } from 'web/hooks/useTenant';
import { useTracking } from 'web/hooks/useTracking';
import {
	GrafDataElement,
	Opploesninger,
	fyllInnManglendeVerdierForbruk,
	getDatoFormat,
	getForbrukTooltipFormattedDate,
	getNesteOpploesning,
	getPeriodeFromOpploesning,
	getShortDatoFormat,
} from 'web/lib/dates.utils';
import { EnergiEnheter, energiEnhetTilLabel, kwhTilBesteEnhet, kwhTilEnhet } from 'web/lib/numbers.utils';

interface useForbrukProps extends useGraphProps {
	setDatePickerData?: (newDate: Date, newOpploesning: Opploesninger) => void;
	visProduksjonsGraf?: boolean;
}
export default function useForbruk({
	maalere,
	filter,
	selectedDate,
	opploesning,
	setDatePickerData,
	visProduksjonsGraf,
	graphZoom,
	useShortXLabel,
}: useForbrukProps) {
	const { track } = useTracking();
	const n = useNumbers();
	const tenant = useTenant();

	const datoRange = getPeriodeFromOpploesning(selectedDate, opploesning);

	const chartRef = useRef<any>(null);

	const forbruksEnergiEnhet = useRef(EnergiEnheter.KWH);
	const produksjonsEnergiEnhet = useRef(EnergiEnheter.KWH);

	const naavaerendeOpploesning = useRef('');
	const nesteOpploesning = useRef('');
	const labelFormatting = useRef('');

	naavaerendeOpploesning.current = opploesning;
	nesteOpploesning.current = getNesteOpploesning(opploesning);
	labelFormatting.current = useShortXLabel ? getShortDatoFormat(opploesning) : getDatoFormat(opploesning);

	const xAxis = useRef([]);
	const forbruk = useRef([]);
	const produksjon = useRef([]);

	const [isMissingData, setIsMissingData] = useState(false);
	const [hasProduksjonsData, setHasProduksjonsData] = useState(false);

	function loadDataIntoGraph() {
		if (chartRef.current) {
			if (visProduksjonsGraf) {
				chartRef.current?.load?.({
					x: 'x',
					columns: [
						['x', ...xAxis.current],
						['Solgt', ...produksjon.current],
					],
					unload: 'Kjøpt',
					type: 'bar',
					colors: {
						Solgt: 'var(--nte-groenn)',
						Kjøpt: 'var(--nte-blaa)',
					},
				} as any);
				chartRef.current?.axis.labels({
					y: energiEnhetTilLabel(produksjonsEnergiEnhet.current),
				});
				if (produksjon.current?.length && Math.max(...produksjon.current) < 2) {
					chartRef.current?.axis.max(2);
				} else {
					chartRef.current?.axis.max(undefined);
				}
			} else {
				chartRef.current?.load?.({
					x: 'x',
					columns: [
						['x', ...xAxis.current],
						['Kjøpt', ...forbruk.current],
					],
					unload: 'Solgt',
					type: 'bar',
					colors: {
						Solgt: 'var(--nte-groenn)',
						Kjøpt: 'var(--nte-blaa)',
					},
				} as any);
				chartRef.current?.axis.labels({
					y: energiEnhetTilLabel(forbruksEnergiEnhet.current),
				});
				if (forbruk.current?.length && Math.max(...forbruk.current) < 2) {
					chartRef.current?.axis.max(2);
				} else {
					chartRef.current?.axis.max(undefined);
				}
			}
		}
	}

	useEffect(() => {
		loadDataIntoGraph();
	}, [visProduksjonsGraf]);

	const { loading, error } = useHentForbrukQuery({
		variables: {
			orgid: tenant?.id,
			fraDato: format(datoRange.fra, 'yyyy-MM-dd'),
			tilDato: format(addMinutes(datoRange.til, 1), 'yyyy-MM-dd'),
			maalere: maalere,
			retning: Retning.forbruk,
			opploesning: opploesning,
			fakturamerker: filter?.fakturamerker || [],
			naeringskoder: filter?.naeringskoder || [],
		},
		fetchPolicy: 'cache-and-network',
		onError: error => {
			console.error(error);
			setIsMissingData(true);
			chartRef.current?.load({
				unload: true,
				type: 'bar',
			} as any);
		},
		onCompleted: data => {
			const forbOgProdData = data?.bedrift?.forbruksdetaljer || {};
			let hoyesteForbruksVerdi = 0;
			let hoyesteProduksjonsVerdi = 0;

			let sortertData = [...(forbOgProdData?.verdier || [])].sort((a: any, b: any) => {
				const aTime = new Date(a.fraTid);
				const bTime = new Date(b.fraTid);

				if (aTime > bTime) return 1;
				if (bTime > aTime) return -1;
				return 0;
			});

			if (sortertData?.length === 0) {
				setIsMissingData(true);
			} else {
				setIsMissingData(false);
			}

			sortertData =
				sortertData &&
				fyllInnManglendeVerdierForbruk({
					data: sortertData,
					opploesning,
					...datoRange,
				});

			const forbruksData =
				sortertData?.map((data: GrafDataElement) => {
					if (hoyesteForbruksVerdi < data.importert) {
						hoyesteForbruksVerdi = data.importert;
					}

					return {
						value: data.importert,
						label: format(new Date(data.fraTid), getDatoFormat(opploesning), { locale: nbLocale }),
						fromTime: data.fraTid,
					};
				}) ?? [];

			let hasProduksjonsData = false;
			const produksjonsData =
				sortertData?.map((data: GrafDataElement) => {
					if (hoyesteProduksjonsVerdi < data.eksportert) {
						hoyesteProduksjonsVerdi = data.eksportert;
					}
					if (data.eksportert) {
						hasProduksjonsData = true;
					}
					return {
						value: data.eksportert,
						label: format(new Date(data.fraTid), getDatoFormat(opploesning), { locale: nbLocale }),
						fromTime: data.fraTid,
					};
				}) ?? [];

			setHasProduksjonsData(hasProduksjonsData);

			forbruksEnergiEnhet.current = kwhTilBesteEnhet(hoyesteForbruksVerdi);
			produksjonsEnergiEnhet.current = kwhTilBesteEnhet(hoyesteProduksjonsVerdi);

			xAxis.current = forbruksData.map((element: any) => new Date(element.fromTime));
			forbruk.current = forbruksData.map((element: any) => element.value);
			produksjon.current = produksjonsData.map((element: any) => element.value);
			loadDataIntoGraph();
		},
	});

	function handleOnClick(newDate: Date) {
		if ((naavaerendeOpploesning.current as Opploesninger) === Opploesninger.time) {
			return;
		}
		setDatePickerData(newDate, nesteOpploesning.current as Opploesninger);
	}

	function formatXTick(x: any) {
		return format(new Date(x), labelFormatting.current, { locale: nbLocale });
	}

	function formatYTick(y: number) {
		let value = kwhTilEnhet(y, forbruksEnergiEnhet.current);
		if (visProduksjonsGraf) {
			value = kwhTilEnhet(y, produksjonsEnergiEnhet.current);
		}
		return value == Math.floor(value) ? n(value, false, '0,0') : '';
	}

	return {
		ref: chartRef,
		loading,
		error,
		isMissingData,
		hasProduksjonsData,
		data: {
			data: {
				x: 'x',
				columns: [['x'], ['Kjøpt']],
				type: 'bar',
				onclick: (event: any) => {
					track('Graph clicked', {
						category: `${visProduksjonsGraf ? 'Solgt' : 'Kjøpt'}`,
						label: `Forbruksgraf trykket - ${visProduksjonsGraf ? 'Solgt' : 'Kjøpt'}`,
						event: 'Changes detail level of the viewing data',
					});
					handleOnClick(new Date(event.x));
				},
			},
			zoom: {
				enabled: graphZoom,
			},
			legend: {
				show: false,
			},
			axis: {
				x: {
					type: 'timeseries',
					tick: {
						format: formatXTick,
					},
				},
				y: {
					label: `${visProduksjonsGraf ? produksjonsEnergiEnhet.current : forbruksEnergiEnhet.current}`,
					tick: {
						format: formatYTick,
					},
				},
			},
			grid: {
				x: {
					show: true,
				},
				y: {
					show: true,
				},
			},
			tooltip: {
				format: {
					title: (value: any) => {
						if (!isDate(value)) return null;
						return `${getForbrukTooltipFormattedDate(value, naavaerendeOpploesning.current as Opploesninger)}`;
					},
					value: (value: any) => {
						if (visProduksjonsGraf) {
							return `${n(kwhTilEnhet(value as number, produksjonsEnergiEnhet.current))} ${produksjonsEnergiEnhet.current}`;
						}
						return `${n(kwhTilEnhet(value as number, forbruksEnergiEnhet.current))} ${forbruksEnergiEnhet.current}`;
					},
				},
			},
		},
	};
}
