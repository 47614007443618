import { Faktura, InvoiceType as FakturaType, Source } from 'generated/graphql-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import FakturaIcon from 'web/components/molecules/FakturaIcon';
import useNumbers from 'web/hooks/useNumbers';
import { getFakturaStatusString, getFakturertString } from 'web/lib/faktura.utils';

const FakturaListElementButton = styled(Link)`
	display: flex;
	align-items: center;
	border: 1px solid var(--nte-graa-lys);
	border-radius: var(--border-radius);
	padding: 16px;
	margin-bottom: 12px;
	box-shadow: var(--clickable-box-shadow);
	text-decoration: none;
	&:hover,
	&:active,
	&:focus {
		box-shadow: none;
		text-decoration: none;
		label {
			background: var(--nte-blaa-10);
		}
	}
	&:disabled {
		box-shadow: none;
	}
`;

const IconWrapper = styled.div`
	padding: 1rem;
	padding-left: 0;
`;

const TextContent = styled.div`
	flex-grow: 1;
`;

const Title = styled.span`
	font-weight: bold;
	font-size: 0.875rem;
	color: var(--nte-sort);

	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const Label = styled.span`
	font-size: 0.75rem;
	line-height: 1rem;
	color: var(--nte-graa-uu);
`;

export const ArrowIcon = styled.label`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: var(--nte-graa-lys);
	border: none;
	margin: 0 13px 0 10px;
	color: var(--nte-graa-uu);
	text-decoration: none;
	display: inline-block;
	text-align: center;
	line-height: 24px;
`;

const Cost = styled.span`
	font-weight: bold;
	font-size: 0.875rem;
	line-height: 1rem;
	text-align: right;
	color: var(--nte-sort);
	white-space: nowrap;
`;

export function getSingleFakturaLink(fakturaId: String) {
	// Prevent IS_CUSTOMER from being visible in the URL, invert of getSingleFakturaGuid
	if (fakturaId.indexOf(Source.IS_CUSTOMER) > -1) {
		return `${fakturaId.replace(Source.IS_CUSTOMER, 'c')}/`;
	} else {
		return `${fakturaId}/`;
	}
}

interface FakturaListElementProps {
	faktura: Faktura;
}

export default function FakturaListElement({ faktura }: FakturaListElementProps) {
	const n = useNumbers();

	return (
		<FakturaListElementButton to={getSingleFakturaLink(faktura.guid)}>
			<IconWrapper>
				<FakturaIcon faktura={faktura} />
			</IconWrapper>
			<TextContent>
				<Title>
					{faktura.fakturatype === FakturaType.CREDIT_NOTE || faktura.fakturatype === FakturaType.CREDITED
						? 'Kreditnota'
						: getFakturertString(faktura.fakturadato)}
				</Title>
				<br />
				<Label>{getFakturaStatusString(faktura)}</Label>
			</TextContent>
			<Cost>
				{n(faktura?.beloepInklMoms, true) + ' kr'}
				<ArrowIcon>
					<i className="fal fa-chevron-right" />
				</ArrowIcon>
			</Cost>
		</FakturaListElementButton>
	);
}
