import React from 'react';
import styled from 'styled-components';
import { FilterDataArray, FilterDataSelectElement, FilterElements, SelectItem } from 'web/lib/filter.utils';

const FilterTagWrapper = styled.div`
	display: flex;
	align-items: center;
	background: var(--white);
	border: 1px solid var(--nte-blaa);
	border-radius: var(--border-radius-small);

	height: 2rem;
	min-width: 2rem;
	padding: 0 25px;
	margin-right: 0.5rem;

	font-size: 0.875rem;
	line-height: 1.5;
	color: var(--nte-graa-mork);
`;

const FilterTagButton = styled.button`
	border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
	margin-right: -1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2rem;
	width: 45px;
	background: none;
	color: var(--nte-blaa-uu);
	border: none;
	box-shadow: none;
`;

interface FilterTagProps {
	label?: string;
	onRemove?: () => void;
	onClick?: () => void;
	style?: React.CSSProperties;
}
function FilterTag(props: FilterTagProps) {
	return (
		<div style={{ ...props.style, display: 'inline-block' }} onClick={props.onClick}>
			<FilterTagWrapper>
				{props.label}
				{(!!props.onRemove && (
					<FilterTagButton
						onClick={function (e) {
							props.onRemove();
							e.stopPropagation();
						}}>
						<i className="fa fa-times" />
					</FilterTagButton>
				)) || (
					<FilterTagButton onClick={props.onClick}>
						<i className="fa fa-pencil" />
					</FilterTagButton>
				)}
			</FilterTagWrapper>
		</div>
	);
}

const Wrapper = styled.div`
	display: flex;
	overflow-x: auto;
	white-space: nowrap;
	margin: 0 -16px;
	padding: 8px 16px 0px;
`;

export interface SelectedFilterTagsProps {
	filterData: FilterDataArray;
	style?: React.CSSProperties;
	className?: string;
	onOpenFilter?: () => void;
}
export default function SelectedFilterTags(props: SelectedFilterTagsProps) {
	const checkTagsHaveLength = () => {
		if (!props.filterData?.length) {
			return false;
		}
		for (let i = 0; i < props.filterData?.length; i++) {
			const element = props.filterData[i];
			const type = element.type;

			if (type == FilterElements.SELECT && !element.isSortSelect && element.selected?.length > 0) {
				return true;
			}
			if (type == FilterElements.CHECKBOX && element.checked) {
				return true;
			}
			if (type == FilterElements.TEXTINPUT && element.value) {
				return true;
			}
		}
		return false;
	};

	const createSelectTags = (dataElement: FilterDataSelectElement) => {
		const result = [];
		let optionsValueMap = {} as { [value: string]: SelectItem };

		if (!dataElement.options) {
			return;
		}
		dataElement.options?.forEach(option => {
			optionsValueMap[option.value] = option;
		});

		if (dataElement.isMultiselect) {
			let selected = dataElement.selected as string[];
			selected?.forEach(value =>
				result.push(
					<FilterTag
						style={dataElement.filterDrawerStyle}
						key={value}
						label={optionsValueMap[value].label}
						onClick={props.onOpenFilter}
						onRemove={() => {
							const newSelected = selected?.filter(s => s !== value);

							dataElement.onChange(newSelected);
						}}
					/>
				)
			);
		} else {
			result.push(
				<FilterTag
					style={dataElement.filterDrawerStyle}
					key={dataElement.selected as string}
					label={optionsValueMap[dataElement.selected as string].label}
					onClick={props.onOpenFilter}
					onRemove={
						dataElement.isDeselectable &&
						(() => {
							dataElement.onChange(null);
						})
					}
				/>
			);
		}
		return result;
	};

	if (checkTagsHaveLength()) {
		return (
			<Wrapper style={props.style} className={props.className}>
				{props.filterData.map((dataElement, i) => {
					switch (dataElement.type) {
						case FilterElements.SELECT:
							if (!dataElement.isSortSelect) {
								return createSelectTags(dataElement);
							} else {
								break;
							}

						case FilterElements.CHECKBOX:
							if (dataElement.checked) {
								return (
									<FilterTag
										style={dataElement.filterDrawerStyle}
										key={'checkbox' + i}
										label={dataElement.label || dataElement.title}
										onClick={props.onOpenFilter}
										onRemove={() => {
											dataElement.onChange(false);
										}}
									/>
								);
							} else {
								break;
							}

						case FilterElements.TEXTINPUT:
							if (dataElement.value) {
								return (
									<FilterTag
										style={dataElement.filterDrawerStyle}
										key={'textinput' + i}
										label={'"' + dataElement.value + '"'}
										onClick={props.onOpenFilter}
										onRemove={() => {
											dataElement.onChange('');
										}}
									/>
								);
							} else {
								break;
							}
						default:
							break;
					}
				})}
			</Wrapper>
		);
	} else {
		return <></>;
	}
}
