// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentEffekttopperQueryVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
  fraDato?: Types.InputMaybe<Types.Scalars['Date']>;
  maalere?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  tilDato?: Types.InputMaybe<Types.Scalars['Date']>;
  opploesning?: Types.InputMaybe<Types.Opploesning>;
  retning?: Types.InputMaybe<Types.Retning>;
}>;


export type HentEffekttopperQuery = { bedrift?: { id?: string, forbruk?: { opploesning?: Types.Opploesning, retning?: Types.Retning, verdier?: Array<{ mengde?: number, importert?: number, eksportert?: number, fraTid?: string, tilTid?: string }> } } };


export const HentEffekttopperDocument = gql`
    query hentEffekttopper($orgid: ID!, $fraDato: Date, $maalere: [String], $tilDato: Date, $opploesning: Opploesning, $retning: Retning) {
  bedrift: hentBedrift(orgid: $orgid) {
    id
    forbruk: forbruksdetaljer(
      fraDato: $fraDato
      maalere: $maalere
      tilDato: $tilDato
      opploesning: $opploesning
      retning: $retning
    ) {
      opploesning
      retning
      verdier {
        mengde
        importert
        eksportert
        fraTid
        tilTid
      }
    }
  }
}
    `;

/**
 * __useHentEffekttopperQuery__
 *
 * To run a query within a React component, call `useHentEffekttopperQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentEffekttopperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentEffekttopperQuery({
 *   variables: {
 *      orgid: // value for 'orgid'
 *      fraDato: // value for 'fraDato'
 *      maalere: // value for 'maalere'
 *      tilDato: // value for 'tilDato'
 *      opploesning: // value for 'opploesning'
 *      retning: // value for 'retning'
 *   },
 * });
 */
export function useHentEffekttopperQuery(baseOptions: Apollo.QueryHookOptions<HentEffekttopperQuery, HentEffekttopperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentEffekttopperQuery, HentEffekttopperQueryVariables>(HentEffekttopperDocument, options);
      }
export function useHentEffekttopperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentEffekttopperQuery, HentEffekttopperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentEffekttopperQuery, HentEffekttopperQueryVariables>(HentEffekttopperDocument, options);
        }
export type HentEffekttopperQueryHookResult = ReturnType<typeof useHentEffekttopperQuery>;
export type HentEffekttopperLazyQueryHookResult = ReturnType<typeof useHentEffekttopperLazyQuery>;
export type HentEffekttopperQueryResult = Apollo.QueryResult<HentEffekttopperQuery, HentEffekttopperQueryVariables>;