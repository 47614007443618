// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeOmValideringskodeMutationVariables = Types.Exact<{
  epost: Types.Scalars['String'];
}>;


export type BeOmValideringskodeMutation = { beOmValideringskode?: string };

export type ValiderEpostMutationVariables = Types.Exact<{
  epost: Types.Scalars['String'];
  kode: Types.Scalars['String'];
}>;


export type ValiderEpostMutation = { validerEpost?: { status?: Types.Status } };


export const BeOmValideringskodeDocument = gql`
    mutation beOmValideringskode($epost: String!) {
  beOmValideringskode(epost: $epost)
}
    `;
export type BeOmValideringskodeMutationFn = Apollo.MutationFunction<BeOmValideringskodeMutation, BeOmValideringskodeMutationVariables>;

/**
 * __useBeOmValideringskodeMutation__
 *
 * To run a mutation, you first call `useBeOmValideringskodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeOmValideringskodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beOmValideringskodeMutation, { data, loading, error }] = useBeOmValideringskodeMutation({
 *   variables: {
 *      epost: // value for 'epost'
 *   },
 * });
 */
export function useBeOmValideringskodeMutation(baseOptions?: Apollo.MutationHookOptions<BeOmValideringskodeMutation, BeOmValideringskodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BeOmValideringskodeMutation, BeOmValideringskodeMutationVariables>(BeOmValideringskodeDocument, options);
      }
export type BeOmValideringskodeMutationHookResult = ReturnType<typeof useBeOmValideringskodeMutation>;
export type BeOmValideringskodeMutationResult = Apollo.MutationResult<BeOmValideringskodeMutation>;
export type BeOmValideringskodeMutationOptions = Apollo.BaseMutationOptions<BeOmValideringskodeMutation, BeOmValideringskodeMutationVariables>;
export const ValiderEpostDocument = gql`
    mutation validerEpost($epost: String!, $kode: String!) {
  validerEpost(epost: $epost, kode: $kode) {
    status
  }
}
    `;
export type ValiderEpostMutationFn = Apollo.MutationFunction<ValiderEpostMutation, ValiderEpostMutationVariables>;

/**
 * __useValiderEpostMutation__
 *
 * To run a mutation, you first call `useValiderEpostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValiderEpostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validerEpostMutation, { data, loading, error }] = useValiderEpostMutation({
 *   variables: {
 *      epost: // value for 'epost'
 *      kode: // value for 'kode'
 *   },
 * });
 */
export function useValiderEpostMutation(baseOptions?: Apollo.MutationHookOptions<ValiderEpostMutation, ValiderEpostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValiderEpostMutation, ValiderEpostMutationVariables>(ValiderEpostDocument, options);
      }
export type ValiderEpostMutationHookResult = ReturnType<typeof useValiderEpostMutation>;
export type ValiderEpostMutationResult = Apollo.MutationResult<ValiderEpostMutation>;
export type ValiderEpostMutationOptions = Apollo.BaseMutationOptions<ValiderEpostMutation, ValiderEpostMutationVariables>;