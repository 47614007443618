import React from 'react';
import Center from 'web/components/atoms/Center';
import Paragraph from 'web/components/atoms/Paragraph';
import Push from 'web/components/atoms/Push';
import PageTitle from 'web/components/atoms/PageTitle';

export function OnboardingVilkaar() {
	return (
		<div style={{ maxWidth: '600px', margin: 'auto' }}>
			<Center>
				<Push />
				<PageTitle isCenter>Vilkår</PageTitle>
				<Push />
				<h2>Tilleggsvilkår for Bedriftsportalen</h2>
				<p>
					Bedriftsportalen («Tjenesten») er en betalt tjeneste levert av NTE Marked AS («NTE»), org.nr: 991 854 126. Tjenesten gir Kunden tilgang til
					rapporteringsverktøyet Bedriftsportalen. Bruk av Tjenesten krever at Kunden har Bank-ID for sikker innlogging. Som abonnent kan Kunden
					analysere sitt strømforbruk og kostnader, samt administrere anlegg og brukertilganger. Tjenesten aktiveres automatisk for alle anlegg der
					Kunden har en aktiv strømavtale. For å bruke Tjenesten, må Kunden akseptere disse Tilleggsvilkårene. Ved å akseptere, bekrefter Kunden at de
					har lest, forstått og godtar vilkårene, og forplikter seg til å overholde dem.
				</p>

				<h2>1. Avtalens Lengde og Oppsigelse</h2>
				<p>
					NTE vil utføre en kredittvurdering av Kunden ved avtalens start. Hvis kredittvurderingen er negativ, kan NTE kreve rimelig sikkerhet eller
					forskudd for å sikre sine fordringer. Tilleggstjenesteavtalen gjelder på løpende basis. NTE kan avslutte Tjenesten med minst 14 dagers
					varsel. Videre har NTE rett til å heve avtalen hvis Kunden vesentlig bryter sine forpliktelser, og bruddet ikke rettes innen en rimelig
					frist som NTE skriftlig meddeler. Denne avtalen erstatter ikke Kundens eksisterende strømavtale. Når Kundens strømavtale utløper, opphører
					også tilgangen til Tjenesten.
				</p>
				<h2>2. Pris på Tilleggstjenesten og Bruk av Informasjon i Faktureringen</h2>
				<p>
					Prisen på Tjenesten, som er gjeldende per anlegg/måned, er alltid tilgjengelig og oppdatert på nte.no. Tjenesten faktureres sammen med
					Kundens strømfaktura.
				</p>
				<h2>3. Endringer i Avtalevilkårene, Priser og Tjenesten</h2>
				<p>
					Endringer i skatter eller offentlige avgifter som påvirker prisen trer i kraft umiddelbart ved endringstidspunktet. NTE vil informere Kunden
					om slike endringer så snart de er kjent. Tjenesten kan endres for å forbedre brukervennlighet, tilgjengelighet eller for å møte behov i
					andre tjenester fra NTE. NTE kan legge til nye funksjoner, fjerne mindre anvendelige funksjoner, eller gjøre endringer som er økonomisk
					nødvendige. Slike endringer kan kreve revidering av disse Tilleggsvilkårene. NTE forbeholder seg retten til å tilpasse disse vilkårene i
					henhold til endrede forretningsbehov, teknologier, standarder, lovgiving, eller andre relevante endringer. Endringer i Tilleggsvilkårene vil
					bli publisert på NTEs nettsider med minst 14 dagers varsel.
				</p>
				<h2>4. NTES Ansvar og Ansvarsbegrensninger</h2>
				<p>
					NTE gir ingen garantier knyttet til Tjenesten. NTE er ikke ansvarlig for indirekte skader, som inntektstap eller skade på forholdet mellom
					kunden og tredjeparter, med mindre det skyldes NTEs grove uaktsomhet eller forsettlig forsømmelse. NTEs ansvar er begrenset til det som er
					fastsatt i ufravikelig lovgivning. NTE er ikke ansvarlig for tap eller skader forårsaket av systemfeil, kommunikasjonsfeil, skadelig
					programvare, eller materiale på tredjeparts nettsteder lenket til fra Tjenesten.
				</p>
				<h2>5. Øvrige Vilkår</h2>
				<p>
					Tjenesten er eksklusiv for NTEs bedriftskunder. NTE kan sende informasjon til Kunden via e-post, tekstmelding, eller andre egnede metoder.
					Alternativt kan informasjon sendes til Kundens faktureringsadresse. Disse Tilleggsvilkårene og NTEs generelle salgsvilkår for levering av
					elektrisk kraft utenfor forbrukerområdet gjelder i tillegg.
				</p>
			</Center>
		</div>
	);
}
