import React, { ReactNode } from 'react';
import UserHeaderLabel from 'web/components/molecules/UserHeaderLabel';
import { DashboardLayout } from 'web/components/organisms/DashboardLayout';
import useAuthHelpers from 'web/hooks/useAuthHelpers';
import { useAuthorization } from 'web/hooks/useAuthorization';
import { LayoutHeader, LogoutButton, LogoutButtonWrapper } from 'web/layouts/TenantLayout';

export function AdminLayout({ children }: { children: ReactNode }) {
	const { user } = useAuthorization();
	const { logout } = useAuthHelpers();

	return (
		<DashboardLayout
			mainMenuItems={[
				{
					icon: <i className="far fa-city" />,
					label: 'Bedrifter',
					to: '/admin/kunder/',
				},
				{
					icon: <i className="far fa-paper-plane" />,
					label: 'Meldinger',
					to: '/admin/meldinger/',
				},
			]}
			secondaryMenuItems={[]}>
			<LayoutHeader>
				<UserHeaderLabel name={user.name} tenantName="Systemadmin">
					<LogoutButtonWrapper>
						<LogoutButton
							onClick={() => {
								logout();
							}}
							type="button">
							Logg ut
						</LogoutButton>
					</LogoutButtonWrapper>
				</UserHeaderLabel>
			</LayoutHeader>
			{children}
		</DashboardLayout>
	);
}
