import { createGlobalStyle } from 'styled-components';

export const deviceSize = {
	mobileSmall: 349,
	mobile: 767,
	tablet: 1199,
	desktop: 1499,
	desktopLarge: 2199,
};

export const deviceSizeInPixelString = {
	mobileSmall: `${deviceSize.mobileSmall}px`,
	mobile: `${deviceSize.mobile}px`,
	tablet: `${deviceSize.tablet}px`,
	desktop: `${deviceSize.desktop}px`,
	desktopLarge: `${deviceSize.desktopLarge}px`,
};

export const device = {
	mobileS: `(max-width: ${deviceSizeInPixelString.mobileSmall})`,
	mobile: `(max-width: ${deviceSizeInPixelString.mobile})`,
	tablet: `(max-width: ${deviceSizeInPixelString.tablet})`,
	desktop: `(max-width: ${deviceSizeInPixelString.desktop})`,
	desktopLarge: `(max-width: ${deviceSizeInPixelString.desktopLarge})`,
};

export function isMobile(width?: number) {
	if (width) {
		return width < deviceSize.mobile;
	} else {
		return window.innerWidth < deviceSize.mobile;
	}
}

const GlobalStyling = createGlobalStyle`
 html,
	body {
		margin: 0;
		padding: 0;
		font-family: 'Roboto', sans-serif;
		color: var(--nte-sort);
		line-height: 1.2;
		background: white;
		--max-textbox-width: 700px;
		--sidebar-width: 300px;
		--layout-maxwidth: 1200px;
		--header-height: 86px;
		--logo-width: 70px;
		--varsel-groenn: #007515;
		--varsel-lys-groenn: #D4E8D7;
		--varsel-gul: #8A6402;
		--varsel-lys-gul: #FFF3AE;
		--varsel-orange: #FFBA53;
		--varsel-roed: #AD0000;
		--varsel-roed-10: #AD00001A;
		--fjutt-tekst: #464354;
		--fjutt-lys: #DEEDF0;
		--fjutt-travle-trine: #E9F3E9;
		--fjutt-roed: #FFE5E2;
		--fjutt-groenn: #70C48E;
		--fjutt-groenn-lys: #85C293;
		--fjutt-sort: #0C4763;
		--nte-blaa: #0089C4;
		--nte-blaa-himmel: #F0F8FC;
		--nte-blaa-10: #E5F3F9;
		--nte-blaa-20: #CCE7F3;
		--nte-blaa-50: #80C4E1;
		--nte-blaa-lys: #14AFDD;
		--nte-blaa-logo: #0079ad;
		--nte-blaa-uu: #0571A8;
		--nte-blaa-mork: #094462;
		--nte-korall: #F7C2B5;
		--nte-korall-moerkere: #F29780;
		--nte-korall-morkest: #EC512B;
		--nte-korall-800:#B22727;
		--nte-groenn: #7DC189;
		--nte-groenn-20: #E8F3E8;
		--nte-groenn-moerk: #397F57;
		--nte-graa-lys: #EDEDED;
		--nte-graa-lys-50: #F7F7F7;
		--nte-graa-medium: #71767E;
		--nte-graa-uu: #757575;
		--nte-graa-mork: #505359;
		--nte-sort: #28292C;
        --nte-border-color: rgba(112, 124, 151, 0.25);
		--nte-border: 1px solid var(--nte-border-color);
		--white: white;
		--graa-bg-lys: #F8F8F8;
		--graa-bg: #f5f5f5;
		--overlay-gray: rgba(40, 41, 44, 0.21);
		--overlay-white: rgba(255, 255, 255, 0.5);
		--clickable-box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
		--clickable-box-shadow-float: 0px 4px 10px rgba(20, 106, 255, 0.06);
		--clickable-box-shadow-app:  0px 4px 10px rgba(29, 30, 31, 0.06);//0px 4px 10px rgba(5, 113, 168, 0.06);
		--border-radius-large: 16px;
		--border-radius: 9px;
		--border-radius-small: 6px;
		--border-radius-smallest: 3px;
        --mobile-breakpoint: 750px;
		--mobile-header-height: 64px;
	}
	body * {
		box-sizing: border-box;
		font-smooth: always;
	}
	input,
	textarea {
		font-family: 'Roboto', sans-serif;
	}
	input:focus,
	textarea:focus,
	button:focus {
		outline-color: var(--nte-blaa-logo);
		box-shadow: 0 0 2px 1px var(--nte-blaa-logo);
		font-family: 'Roboto', sans-serif;
	}

	input[type='checkbox'] {
		accent-color: var(--nte-blaa-uu);
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type=number] {
		-moz-appearance: textfield;
	}

	button,
	a {
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		transition: 0.2s;
		:hover {
			opacity: 0.9;
		}
	}

	button {
		cursor: auto;
	}

	a {
		color: var(--nte-blaa-logo);
		:hover {
			text-decoration: underline;
		}
	}

	hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: var(--nte-border);
		margin: 1em 0;
		padding: 0;
	}

	.highlight-text {
		font-weight: 900;
		background: yellow;
	}

	.bold{
		font-weight: bold;
	}

	/* Table */
	table {
		width: 100%;
		border-collapse: collapse;
	}
	table thead td {
		padding: 15px 17px;
		border-bottom: var(--nte-border);
		font-weight: bold;
		font-size: 0.875rem;
		line-height: 2;
		color: var(--nte-graa-mork);
	}
	table thead .right {
		text-align: right;
	}
	table thead .center {
		text-align: center;
	}
	table.stripe tbody tr:nth-child(even) {
		background: var(--graa-bg-lys);
	}
	table tbody td {
		padding: 15px 17px;
		border-bottom: var(--nte-border);
		font-weight: normal;
		font-size: 0.875rem;
		color: var(--nte-graa-mork);
	}
	table tbody .right {
		text-align: right;
	}
	table tbody .center {
		text-align: center;
	}
	table tbody td a {
		text-decoration: none;
		font-weight: bold;
	}

	/* C3 GRAPHS */
	// Make graph background lines filled out
	.c3-grid line {
		stroke: var(--nte-graa-lys);
		stroke-dasharray: 0;
	}
	// Make focus lines (for tooltip more visible)
	.c3 .c3-xgrid-focus {
		stroke: var(--nte-graa-mork);
		stroke-dasharray: 0;
	}
	// Make the spotpris graph not weird
	.c3 .c3-line-Spotpris {
		shape-rendering: auto !important;
	}
	// Make legend wrapper not colored
	.c3 .c3-legend-background {
		fill: none;
		stroke: none;
	}
	// TODO: this doesn't work, is intended to make cursor regular while hovering graph
	.c3 .c3-bar {
		cursor: default !important;
	}

	/* Toasts */
	// Make toast text color black and readable
	
	.Toastify__toast {
		border-radius: 9px;
	}
	.Toastify__toast-body {
		font-family: 'Roboto', sans-serif;
		color: var(--nte-sort);
		font-size: 1rem;
		padding: 0.2rem 0.5rem;
		line-height: 1.8;
	}

	/* Animations for mobile pages */
	.slide-in-enter {
		transform: translateX(100%);
	}
	.slide-in-enter-active {
		transform: translateX(0%);
		transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
	}
	.slide-in-exit {
		transform: translateX(0%);
	}
	.slide-in-exit-active {
		transform: translateX(100%);
		transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
	}
	.slide-from-right-enter {
		transform: translateX(100%);
	}
	.slide-from-right-enter-active {
		transform: translateX(0%);
		transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
	}
	.slide-from-right-exit {
		transform: translateX(0%);
	}
	.slide-from-right-exit-active {
		transform: translateX(100%);
		transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
	}
	/* Could try to use one rem size for desktop and one for mobile, needs a little more from both design and a lot of rewriting */
	/* html {
		font-size: 16px;
		line-height: 24px;
	}

	@media only screen and (max-width: ${deviceSizeInPixelString.mobile}) {
		html {
			font-size: 15px;
			line-height: 22px;
		}
	} */

`;

export default GlobalStyling;
