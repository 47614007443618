import React from 'react';
import styled from 'styled-components';
import Paragraph from 'web/components/atoms/Paragraph';
import { device } from 'web/GlobalStyling';

interface StyledTextareaProps {
	readonly hasError: boolean;
}
const StyledTextarea = styled.textarea<StyledTextareaProps>`
	border: var(--nte-border);
	border-color: ${props => (props.hasError ? 'var(--varsel-roed)' : 'var(--nte-border-color)')};
	border-radius: var(--border-radius-smallest);
	box-sizing: border-box;
	width: 100%;
	height: 211px;
	resize: none;
	padding: 12px;
	font-size: 0.875rem;
	&::placeholder {
		color: var(--nte-graa-medium);
		opacity: 1; /* Firefox */
	}

	@media ${device.mobile} {
		height: 135px;
	}
`;

interface MobileTextareaInterface {
	label?: string;
	placeholder?: string;
	name?: string;
	className?: string;
	style?: React.CSSProperties;
	hasError?: boolean;
}

export default function MobileTextarea({ name, label, hasError, placeholder, ...props }: MobileTextareaInterface) {
	return (
		<label>
			{label && <Paragraph>{label}</Paragraph>}
			<StyledTextarea name={name} {...props} hasError={hasError} placeholder={placeholder} />
		</label>
	);
}
