import { addDays, endOfDay, startOfDay } from 'date-fns';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { isMobile } from 'web/GlobalStyling';
import useEmailValidation from 'web/hooks/useEmailValidation';
import useFilter from 'web/hooks/useFilter';
import useSpotpris from 'web/hooks/useSpotpris';
import { useTenant } from 'web/hooks/useTenant';
import { SegmentCategory, useTracking } from 'web/hooks/useTracking';
import { Opploesninger } from 'web/lib/dates.utils';
import { getSpotprisFilterData } from 'web/lib/filter.utils';
import SpotprisTemplate, { Varsel } from 'web/templates/SpotprisTemplate';

export default function SpotprisPage() {
	const { track, trackPageView } = useTracking();
	trackPageView(SegmentCategory.Spotpris);
	const tenant = useTenant();

	const [filter, setFilter] = useFilter();
	const soneVelgerData = getSpotprisFilterData(filter, setFilter);

	const [selectedDate, setSelectedDate] = useState(new Date());

	const [varselElement, setVarselElement] = useState({ varsel: null, isValidated: false, isEditing: false });
	const [visVarselModal, _setVisVarselModal] = useState(false);
	const setVisVarselModal = (val: boolean) => {
		_setVisVarselModal(val);
		!val && setVarselElement({ varsel: null, isValidated: false, isEditing: false }); //CLEAR VARSELELEMENT ON CLOSE MODAL
		!val && setEmailValidationError('');
	};
	const { checkIfEmailIsValidated, requestValidationCode, validateEmail, leggTilValidertEpost, emailValidationError, setEmailValidationError } =
		useEmailValidation();

	const { saveVarsel, deleteVarsel, ...spotprisBindings } = useSpotpris({
		from: startOfDay(selectedDate),
		to: endOfDay(selectedDate),
		energisone: filter.energisone,
		orgId: tenant?.id,
		roundUpToClosestNumber: 50,
		legendOnBottom: isMobile(),
	});

	const onSaveVarsel = (varsel: Varsel) => {
		if (checkIfEmailIsValidated(varsel.epost)) {
			const isEditing = varselElement.isEditing;
			setVisVarselModal(false);
			saveVarsel(varsel)
				.then(() => {
					if (!varsel.id) {
						// Varsel is new
						track('Price Notification Subscribed', { price: varsel.varslingskriterie.verdi, operator: varsel.varslingskriterie.operator });
					}

					toast(`${isEditing ? 'Varselet ble oppdatert' : 'Nytt varsel ble lagt til'}`, {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				})
				.catch(() => {
					toast(`Feil ved lagring av varsel`, {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				});
		} else {
			setVarselElement({ ...varselElement, varsel: varsel, isValidated: false });
			requestValidationCode(varsel.epost);
		}
	};

	const onValidateEmail = (varsel: Varsel, kode: string) => {
		validateEmail(kode, varsel.epost)
			.then(res => {
				const isEditing = varselElement.isEditing;
				if (res) {
					setVisVarselModal(false);
					saveVarsel(varsel)
						.then(() => {
							leggTilValidertEpost(varsel.epost);

							if (!varsel.id) {
								// Varsel is new
								track('Price Notification Subscribed', { price: varsel.varslingskriterie.verdi, operator: varsel.varslingskriterie.operator });
							}

							toast(`${isEditing ? 'Varselet ble oppdatert' : 'Nytt varsel ble lagt til'}`, {
								position: toast.POSITION.BOTTOM_CENTER,
							});
						})
						.catch(() => {
							toast(`Feil ved lagring av varsel`, {
								position: toast.POSITION.BOTTOM_CENTER,
							});
						});
				} else {
					setEmailValidationError('Feil kode oppgitt');
				}
			})
			.catch(() => {
				setEmailValidationError('Feil kode oppgitt');
				toast(`Feil ved lagring av varsel`, {
					position: toast.POSITION.BOTTOM_CENTER,
				});
			});
	};

	const onRemoveVarsel = (varsel: Varsel) => {
		deleteVarsel(varsel)
			.then(() => {
				setVisVarselModal(false);

				track('Price Notification Unsubscribed');

				toast(`Varselet ble slettet`, {
					position: toast.POSITION.BOTTOM_CENTER,
				});
			})
			.catch(() => {
				toast(`Feil ved sletting av varsel. Prøv igjen senere.`, {
					position: toast.POSITION.BOTTOM_CENTER,
				});
			});
	};

	return (
		<SpotprisTemplate
			orgId={tenant.id}
			energisone={filter.energisone}
			soneVelgerData={soneVelgerData}
			datePickerData={{
				selectedDate: selectedDate,
				onChange: setSelectedDate,
				maxDate: addDays(new Date(), 1),
				opploesning: Opploesninger.time,
			}}
			spotprisBindings={{ ...spotprisBindings }}
			onAddVarsel={onSaveVarsel}
			onUpdateVarsel={onSaveVarsel}
			onValidateEmail={onValidateEmail}
			emailValidationError={emailValidationError}
			onRemoveVarsel={onRemoveVarsel}
			onOpenEditVarsel={varsel => {
				setVarselElement({ varsel: varsel, isEditing: true, isValidated: true });
				setVisVarselModal(true);
			}}
			setVisVarselModal={setVisVarselModal}
			visVarselModal={visVarselModal}
			varselElement={varselElement}
		/>
	);
}
