import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Center from 'web/components/atoms/Center';
import PageTitle from 'web/components/atoms/PageTitle';
import Paragraph from 'web/components/atoms/Paragraph';
import { useHentBedriftsIdOrgnrOgNavnQuery } from 'web/pages/generic/BedriftAccessErrorPage.graphql-gen';

interface BedriftAccessErrorParamProps {
	bedriftId: string;
}

export default function BedriftAccessErrorPage() {
	const { bedriftId } = useParams<BedriftAccessErrorParamProps>();
	const { t } = useTranslation('generic');
	const { data: { bedrift } = { bedrift: null } } = useHentBedriftsIdOrgnrOgNavnQuery({
		variables: {
			orgid: bedriftId,
		},
		fetchPolicy: 'cache-first',
	});

	return (
		<Center>
			<PageTitle>{t('BedriftAccessErrorPage.title')}</PageTitle>
			<Paragraph>
				<span
					dangerouslySetInnerHTML={{
						__html: t('BedriftAccessErrorPage.message', {
							name: bedrift?.navn || 'denne bedriften',
						}),
					}}
				/>
				<br />
				<a href="https://nte.no/bedrift">{t('BedriftAccessErrorPage.backToNte')}</a>
			</Paragraph>
		</Center>
	);
}
