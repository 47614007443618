// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HentBedriftRedigerKundeQueryVariables = Types.Exact<{
  orgid: Types.Scalars['ID'];
}>;


export type HentBedriftRedigerKundeQuery = { bedrift?: { id?: string, orgnummer?: string, navn?: string, kontakt?: string, kontaktEpost?: string } };

export type OppdaterBedriftMutationVariables = Types.Exact<{
  oppdaterBedrift?: Types.InputMaybe<Types.OppdaterBedriftInput>;
}>;


export type OppdaterBedriftMutation = { oppdaterBedrift?: { id?: string, orgnummer?: string, navn?: string, kontakt?: string, kontaktEpost?: string } };


export const HentBedriftRedigerKundeDocument = gql`
    query hentBedriftRedigerKunde($orgid: ID!) {
  bedrift: hentBedrift(orgid: $orgid) {
    id
    orgnummer
    navn
    kontakt
    kontaktEpost
  }
}
    `;

/**
 * __useHentBedriftRedigerKundeQuery__
 *
 * To run a query within a React component, call `useHentBedriftRedigerKundeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHentBedriftRedigerKundeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHentBedriftRedigerKundeQuery({
 *   variables: {
 *      orgid: // value for 'orgid'
 *   },
 * });
 */
export function useHentBedriftRedigerKundeQuery(baseOptions: Apollo.QueryHookOptions<HentBedriftRedigerKundeQuery, HentBedriftRedigerKundeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HentBedriftRedigerKundeQuery, HentBedriftRedigerKundeQueryVariables>(HentBedriftRedigerKundeDocument, options);
      }
export function useHentBedriftRedigerKundeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HentBedriftRedigerKundeQuery, HentBedriftRedigerKundeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HentBedriftRedigerKundeQuery, HentBedriftRedigerKundeQueryVariables>(HentBedriftRedigerKundeDocument, options);
        }
export type HentBedriftRedigerKundeQueryHookResult = ReturnType<typeof useHentBedriftRedigerKundeQuery>;
export type HentBedriftRedigerKundeLazyQueryHookResult = ReturnType<typeof useHentBedriftRedigerKundeLazyQuery>;
export type HentBedriftRedigerKundeQueryResult = Apollo.QueryResult<HentBedriftRedigerKundeQuery, HentBedriftRedigerKundeQueryVariables>;
export const OppdaterBedriftDocument = gql`
    mutation oppdaterBedrift($oppdaterBedrift: OppdaterBedriftInput) {
  oppdaterBedrift(oppdaterBedrift: $oppdaterBedrift) {
    id
    orgnummer
    navn
    kontakt
    kontaktEpost
  }
}
    `;
export type OppdaterBedriftMutationFn = Apollo.MutationFunction<OppdaterBedriftMutation, OppdaterBedriftMutationVariables>;

/**
 * __useOppdaterBedriftMutation__
 *
 * To run a mutation, you first call `useOppdaterBedriftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOppdaterBedriftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oppdaterBedriftMutation, { data, loading, error }] = useOppdaterBedriftMutation({
 *   variables: {
 *      oppdaterBedrift: // value for 'oppdaterBedrift'
 *   },
 * });
 */
export function useOppdaterBedriftMutation(baseOptions?: Apollo.MutationHookOptions<OppdaterBedriftMutation, OppdaterBedriftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OppdaterBedriftMutation, OppdaterBedriftMutationVariables>(OppdaterBedriftDocument, options);
      }
export type OppdaterBedriftMutationHookResult = ReturnType<typeof useOppdaterBedriftMutation>;
export type OppdaterBedriftMutationResult = Apollo.MutationResult<OppdaterBedriftMutation>;
export type OppdaterBedriftMutationOptions = Apollo.BaseMutationOptions<OppdaterBedriftMutation, OppdaterBedriftMutationVariables>;