import React from 'react';
import styled from 'styled-components';
import { device } from 'web/GlobalStyling';

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	width: max-content;
	min-width: 255px;
`;

const Value = styled.span`
	font-size: 1rem;
	font-weight: 500; //medium
	@media ${device.mobile} {
		font-size: 0.875rem;
	}
`;

const Unit = styled.span`
	font-weight: normal;
`;

interface IconProps {
	backgroundColor?: string;
}

const Icon = styled.div<IconProps>`
	--icon-size: 1.5rem;
	--max-size: 4.4rem;
	color: var(--nte-blaa);
	background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'var(--nte-blaa-10)')};
	border-radius: 100%;
	padding: calc(((var(--max-size) - var(--icon-size)) / 2));
	height: var(--max-size);
	width: var(--max-size);
	display: flex;
	justify-content: center;
	align-items: center;
	& i {
		height: var(--icon-size);
		width: var(--icon-size);
		font-size: 1.2rem;
		display: grid;
		justify-content: center;
		align-items: center;
	}
`;

const Description = styled.p`
	margin: 0.5rem 0;
	font-size: 0.875rem;
	max-width: 100%;
	white-space: pre-wrap;
	@media ${device.mobile} {
		font-size: 0.75rem;
	}
`;

export interface SecondaryValueInfoPanelProps {
	value: string;
	unit?: string;
	description: string;
	icon: any;
	backgroundColor?: string;
}

export function SecondaryValueInfoPanel(props: SecondaryValueInfoPanelProps) {
	return (
		<Wrapper className={'secondaryPanel'}>
			<Icon backgroundColor={props.backgroundColor}>{props.icon}</Icon>
			<div>
				<Value>
					{props.value}
					{props.unit && <Unit>{` ${props.unit}`}</Unit>}
				</Value>

				<Description>{props.description}</Description>
			</div>
		</Wrapper>
	);
}
