import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { device } from 'web/GlobalStyling';

const StyledWrapper = styled.div`
	padding: 1rem;
	background: inherit;
	margin-bottom: 6px;
	width: 100%;
	scrollbar-width: none;
	scrollbar-height: none;
	&::-webkit-scrollbar {
		display: none;
	}
`;

const StyledTitle = styled.h3`
	font-weight: bold;
	font-size: 1.125rem;
	line-height: 1.3125rem;
	color: var(--nte-sort);
	margin: 0;
`;

const StyledContent = styled.div`
	margin: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media ${device.mobile} {
		display: block;
	}
`;

interface FilterDrawerSectionProps {
	label?: string;
	children?: ReactNode;
	contentStyle?: React.CSSProperties;
	className?: any;
	style?: React.CSSProperties;
}
export default function FilterDrawerSection(props: FilterDrawerSectionProps) {
	return (
		<StyledWrapper className={props.className + ' filter-element'} style={props.style}>
			{props.label && <StyledTitle>{props.label}</StyledTitle>}
			<StyledContent style={props.contentStyle}>{props.children}</StyledContent>
		</StyledWrapper>
	);
}
