import { Faktura, InvoiceStatus as FakturaStatus } from 'generated/graphql-types';
import React from 'react';
import styled from 'styled-components';
import { getFakturaStatusStringShort, shouldGetUnknownStatus } from 'web/lib/faktura.utils';

interface IconWrapperProps {
	color: FakturaIconColor;
}
const IconWrapper = styled.span<IconWrapperProps>`
	font-size: 1.25rem;
	color: ${props => props.color};
`;

enum FakturaIconColor {
	GREEN = 'var(--nte-groenn)',
	ORANGE = 'var(--varsel-orange)',
	RED = 'var(--nte-korall-morkest)',
	DARK_RED = 'var(--varsel-roed)',
	GRAY = 'var(--nte-graa-mork)',
	BLUE = 'var(--nte-blaa-uu)',
}

function fakturaStatusToColor(faktura: Faktura) {
	if (shouldGetUnknownStatus(faktura)) {
		return FakturaIconColor.BLUE;
	}
	switch (faktura.fakturastatus) {
		case FakturaStatus.PAID:
			return FakturaIconColor.GREEN;
		case FakturaStatus.DUE:
			return FakturaIconColor.ORANGE;
		case FakturaStatus.OVERDUE:
			return FakturaIconColor.RED;
		case FakturaStatus.DUNNING:
			return FakturaIconColor.DARK_RED;
		default:
			return FakturaIconColor.GRAY;
	}
}

function fakturaStatusToIcon(faktura: Faktura) {
	if (shouldGetUnknownStatus(faktura)) {
		return <i className="far fa-file-invoice" />;
	}
	switch (faktura.fakturastatus) {
		case FakturaStatus.PAID:
			return <i className="far fa-check-circle" />;
		case FakturaStatus.DUE:
			return <i className="far fa-minus-circle" />;
		case FakturaStatus.OVERDUE:
			return <i className="far fa-exclamation-circle" />;
		case FakturaStatus.DUNNING:
			return <i className="far fa-exclamation-triangle" />;
		default:
			return <i className="far fa-circle-question" />;
	}
}

interface FakturaIconProps {
	faktura: Faktura;
	style?: React.CSSProperties;
}
export default function FakturaIcon({ faktura, style }: FakturaIconProps) {
	return (
		<IconWrapper color={fakturaStatusToColor(faktura)} style={style} title={getFakturaStatusStringShort(faktura)}>
			{fakturaStatusToIcon(faktura)}
		</IconWrapper>
	);
}
