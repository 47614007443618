import { InnmeldtAnleggInput } from 'generated/graphql-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'web/components/atoms/Button';
import Center from 'web/components/atoms/Center';
import Push from 'web/components/atoms/Push';
import SelectField from 'web/components/molecules/form/SelectField';
import TextField from 'web/components/molecules/form/TextField';
import { ANLEGG_SKJEMA_STAGES } from 'web/components/organisms/modals/MeldInnAnleggModal';
import { AnleggInnmeldingsskjemaStepProps, ArrowLeftIcon, StyledArrowButton } from 'web/components/organisms/modals/MeldInnAnleggModal/Step1';
import Form from 'web/hooks/useForm';

const StyledError = styled.p`
	text-align: center;
	color: red;
`;

export default function AnleggInnmeldingsskjemaStep3({ state, setState }: AnleggInnmeldingsskjemaStepProps) {
	const [gateError, setGateError] = useState(null);
	const [postNummerError, setPostNummerError] = useState(null);

	const [gateCount, setGateCount] = useState(0);
	const [postnummerCount, setPostnummerCount] = useState(0);

	return (
		<Form
			values={state.manueltAnlegg || {}}
			onSubmit={async (manueltAnlegg: InnmeldtAnleggInput) => {
				if (gateCount == 0) {
					setGateError('Vennligst fyll inn gateadresse');
					return;
				}

				if (postnummerCount == 0) {
					setPostNummerError('Vennligst fyll inn postnummer og poststed');
					return;
				}

				setState({ ...state, stage: ANLEGG_SKJEMA_STAGES.PROJECT_INPUT, manueltAnlegg: { ...state.manueltAnlegg, ...manueltAnlegg } });
			}}>
			{state.error && <StyledError>{state.error}</StyledError>}
			<Center>Fyll inn så mye informasjon som mulig om anlegget.</Center>
			<Push />

			<TextField
				required
				error={gateError}
				label="Gateadresse"
				name="gateadresse"
				onChange={(e: any) => {
					setGateCount(e.target.value?.length);
					setGateError(null);
				}}
			/>

			<TextField label="Etasje" name="etasje" />
			<TextField
				required
				error={postNummerError}
				label="Postnummer og sted"
				name="poststed"
				onChange={(e: any) => {
					setPostnummerCount(e.target.value?.length);
					setPostNummerError(null);
				}}
			/>
			<SelectField value="Automatisk" name="avlesning" label="Avlesning">
				<option value="Automatisk">Automatisk</option>
				<option value="IkkeAvlest">Ikke avlest</option>
				<option value="Manuell">Manuell</option>
				<option value="Ukjent">Ukjent</option>
			</SelectField>

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<StyledArrowButton
					type="button"
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						setState({ ...state, stage: ANLEGG_SKJEMA_STAGES.START });
					}}>
					<ArrowLeftIcon className="fas fa-long-arrow-left" />
					Tilbake
				</StyledArrowButton>
				<Button>Neste steg</Button>
			</div>
		</Form>
	);
}
