import { format } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import { Anleggsstatus } from 'generated/graphql-types';
import { LatLngTuple } from 'leaflet';
import React from 'react';
import styled from 'styled-components';
import { AnleggBadge } from 'web/components/atoms/Badge';
import ClearButton from 'web/components/atoms/ClearButton';
import DetailListElement, { DetailListGroup } from 'web/components/atoms/DetailListElement';
import { Direction, Flex } from 'web/components/atoms/Flex';
import Panel from 'web/components/atoms/Panel';
import PanelTitle from 'web/components/atoms/PanelTitle';
import Push from 'web/components/atoms/Push';
import DatePicker from 'web/components/molecules/DatePicker';
import Map from 'web/components/molecules/Map';
import SimpleGraph from 'web/components/molecules/SimpleGraph';
import TagSelect from 'web/components/organisms/TagSelect';
import { useHasGroup, useIsAdmin } from 'web/hooks/useAuthorization';
import { useTracking } from 'web/hooks/useTracking';
import { toLocalDate } from 'web/lib/dates.utils';
import { GraphWrapper } from 'web/templates/GraphTemplate';
import { GrafProps } from 'web/templates/singleAnlegg/KostnadOgForbrukTabTemplate';
import { AnleggDetails, Tag } from 'web/templates/SingleAnleggTemplate';

export const TabWrapper = styled.div`
	width: 100%;
	max-height: 100%;
	position: relative;
`;

const InfoListWrapper = styled.div`
	width: 100%;
`;

const MapWrapper = styled.div`
	width: 100%;
	min-width: 300px;
	height: 100%;
`;

interface OversiktTabTemplateProps {
	anlegg: AnleggDetails;
	anleggDetails: any;
	anleggDetailsLoading?: boolean;
	anleggLokasjon?: LatLngTuple;
	kategorierOptions?: Tag[];
	kategorierValues?: Tag[];
	singleAnleggForbruksTopp?: GrafProps;
	slettKategoriFraMaaler?: (tag: Tag) => void;
	addKategoriTilMaaler?: (tag: Tag) => void;
	lagreKategori?: (name: string) => void;
	onClickMeldUtAnlegg?: () => void;
}
export default function OversiktTabTemplate(props: OversiktTabTemplateProps) {
	const { track } = useTracking();

	const isAdmin = useIsAdmin();
	const isBedriftsadmin = useHasGroup({ group: 'Bedriftsadmin' });

	return (
		<TabWrapper>
			<Push height="2px" />
			<div style={{ fontSize: '0.875rem' }}>
				<span style={{ display: 'inline-block', marginBottom: '1.5rem' }}>
					{props.anlegg?.fraDato && `Oppstart ${toLocalDate(props.anlegg?.fraDato)} `}
					<AnleggBadge status={props.anlegg?.anleggsstatus} />
				</span>
			</div>
			{(isBedriftsadmin || isAdmin) && props.anlegg?.anleggsstatus === Anleggsstatus.AKTIV && (
				<ClearButton
					type="button"
					style={{ textDecoration: 'underline', padding: '0 0 1rem 0' }}
					onClick={e => {
						props.onClickMeldUtAnlegg();
					}}>
					Meld ut anlegg
				</ClearButton>
			)}

			<Flex direction={Direction.row} mobile={{ direction: Direction.column }}>
				<InfoListWrapper>
					<DetailListGroup>
						{!!props.anlegg?.adresse && <DetailListElement label="Adresse" value={props.anlegg?.adresse} />}
						{!!props.anlegg?.egenAnleggsbeskrivelse && <DetailListElement label="Beskrivelse" value={props.anlegg?.egenAnleggsbeskrivelse} />}
					</DetailListGroup>

					{(!!props.anlegg?.product || !!props.anlegg?.maalernummer || !!props.anlegg?.maalerpunktId) && (
						<DetailListGroup>
							{!!props.anlegg?.product && <DetailListElement label="Produkt" value={props.anlegg?.product} />}
							{!!props.anlegg?.maalernummer && <DetailListElement label="Målernummer" value={props.anlegg?.maalernummer} />}
							{!!props.anlegg?.maalerpunktId && <DetailListElement label="Målepunkt-ID" value={props.anlegg?.maalerpunktId} />}
						</DetailListGroup>
					)}

					<DetailListGroup>
						{!!props.anlegg?.netteier && <DetailListElement label="Netteier" value={props.anlegg?.netteier} />}
						{!!props.anlegg?.leveringspunkt && <DetailListElement label="Netteiers beskrivelse" value={props.anlegg?.leveringspunkt} />}
					</DetailListGroup>

					<DetailListGroup>
						<DetailListElement
							label="Kostnad så langt i år"
							value={
								(props.anleggDetailsLoading && 'Laster...') ||
								`${props.anleggDetails?.kostnadForNow?.toLocaleString(['no-NO', 'fr-CA'], { maximumFractionDigits: 2 })} kr`
							}
						/>
						<DetailListElement
							label="Forbruk så langt i år"
							value={
								(props.anleggDetailsLoading && 'Laster...') ||
								`${props.anleggDetails?.forbrukForNow?.toLocaleString(['no-NO', 'fr-CA'], { maximumFractionDigits: 2 })} kWh`
							}
						/>
						{!!props.anlegg?.aarsforbruk && (
							<DetailListElement label="Estimert årsforbruk" value={`${props.anlegg?.aarsforbruk?.toLocaleString(['no-NO', 'fr-CA'])} kWh`} />
						)}
					</DetailListGroup>

					<DetailListGroup>
						{!!props.anlegg?.naeringskode && (
							<DetailListElement label="Næringskode" value={`${props.anlegg?.naeringskodeID} ${props.anlegg?.naeringskode}`} />
						)}
						{!!props.anlegg?.fakturamerke && <DetailListElement label="Fakturamerke" value={props.anlegg?.fakturamerke} />}
						<DetailListElement
							label="Kategorier"
							value={
								<TagSelect
									doAllowAddNewTag
									addLabel="Legg til ny"
									addNewLabel="Legg til ny kategori"
									onRemove={tag => {
										track('Button Clicked', { category: 'removed', label: tag?.label });
										props.slettKategoriFraMaaler(tag);
									}}
									onAdd={tag => {
										track('Button Clicked', { category: 'added', label: tag?.label });
										props.addKategoriTilMaaler(tag);
									}}
									onAddNew={tagLabel => {
										track('Button Clicked', { category: 'added new', label: tagLabel });
										props.lagreKategori(tagLabel);
									}}
									options={props.kategorierOptions}
									values={props.kategorierValues}
								/>
							}
						/>
					</DetailListGroup>
				</InfoListWrapper>
				<MapWrapper>{props.anleggLokasjon?.length && <Map latLng={props.anleggLokasjon} height={300} />}</MapWrapper>
			</Flex>

			<Panel style={{ border: 'none', padding: '0', marginTop: '2rem' }}>
				<PanelTitle
					style={{
						opacity: (props.singleAnleggForbruksTopp.data && '1') || '0',
					}}>
					Forbrukstopper i {format(props.singleAnleggForbruksTopp.datePickerData.selectedDate, 'MMMM', { locale: nbLocale })}
				</PanelTitle>
				<Push height="1rem" />
				<div style={{ position: 'relative' }}>
					<DatePicker {...props.singleAnleggForbruksTopp.datePickerData} />
					<Push height="1rem" />
					<GraphWrapper>
						<SimpleGraph
							loadingData={props.singleAnleggForbruksTopp.loading}
							chartRef={props.singleAnleggForbruksTopp.ref}
							style={{
								opacity: (props.singleAnleggForbruksTopp.data && '1') || '0',
							}}
							chart={
								props.singleAnleggForbruksTopp.data || {
									data: {
										columns: [],
										type: 'bar',
									},
								}
							}
						/>
					</GraphWrapper>
				</div>
			</Panel>
		</TabWrapper>
	);
}
