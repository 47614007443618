import { useEffect, useState } from 'react';

export default function useAddressSearch(props: any, { skip }: { skip: boolean }) {
	const [state, setState] = useState({
		loading: !skip,
		error: null,
		data: {
			adresse: [],
			metadata: {},
		},
	});
	let uriComponent = Object.keys(props)
		.map(key => `${key}=${encodeURIComponent(props[key])}`)
		.join('&');

	async function doSearch(props: any) {
		if (skip) {
			return;
		}
		setState({
			...state,
			loading: true,
		});

		const response = await fetch(`https://ws.geonorge.no/adresser/v1/sok?side=0&${uriComponent}&treffPerSide=10&asciiKompatibel=true&utkoordsys=4258`);
		const data = await response.json();

		setState({
			...state,
			loading: false,
			error: null,
			data,
		});
	}

	useEffect(() => {
		doSearch(props);
	}, [uriComponent, skip]);

	return state;
}
