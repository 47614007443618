import { format, startOfYear, sub } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'web/GlobalStyling';
import useAnlegg from 'web/hooks/useAnlegg';
import useFilter from 'web/hooks/useFilter';
import useForbruk from 'web/hooks/useForbruk';
import useNumbers from 'web/hooks/useNumbers';
import { useTenant } from 'web/hooks/useTenant';
import { SegmentCategory, useTracking } from 'web/hooks/useTracking';
import { Opploesninger, getPeriodeFromOpploesning } from 'web/lib/dates.utils';
import useForbruksEksport from 'web/lib/exports/useForbruksEksport';
import { getForbrukFilterData } from 'web/lib/filter.utils';
import { kwhTilBesteVerdi, kwhTilLabel } from 'web/lib/numbers.utils';
import GraphTemplate from 'web/templates/GraphTemplate';

export default function ForbrukPage() {
	const { trackPageView } = useTracking();
	trackPageView(SegmentCategory.Forbruk);
	const n = useNumbers();
	const { eksporter } = useForbruksEksport();
	const tenant = useTenant();
	const { forbrukSisteToAar } = tenant;

	const [visProduksjonsGraf, setVisProduksjonsGraf] = useState(false);

	const [filter, setFilter] = useFilter();
	const { meterPointIds } = useAnlegg(filter);
	const filterData = getForbrukFilterData(filter, setFilter);

	const [selectedDate, setSelectedDate] = useState(new Date());
	const [opploesning, setOpploesning] = useState(Opploesninger.maned);
	const datoRange = getPeriodeFromOpploesning(new Date(selectedDate), opploesning);

	let monthForbrukMap: { [key: string]: number } = {};

	let totalThisYear = 0;
	const startOfYearDate = startOfYear(new Date());

	forbrukSisteToAar?.verdier?.forEach((forbruk: any) => {
		const date = new Date(forbruk.fraTid);
		if (new Date(forbruk.fraTid) >= startOfYearDate) {
			totalThisYear += forbruk.importert;
		}
		monthForbrukMap[format(date, 'yyyy-MM', { locale: nbLocale })] = forbruk.importert;
	});

	const lastMonth = sub(new Date(), { months: 1 });
	const lastMonthLabel = format(lastMonth, 'yyyy-MM', { locale: nbLocale });
	const currentMonthLabel = format(new Date(), 'yyyy-MM', { locale: nbLocale });

	const { ref, data, loading, error, isMissingData, hasProduksjonsData } = useForbruk({
		maalere: meterPointIds,
		filter: filter,
		selectedDate: selectedDate,
		opploesning: opploesning,
		setDatePickerData: (selectedDate, opploesning) => {
			setSelectedDate(selectedDate);
			setOpploesning(opploesning);
		},
		visProduksjonsGraf: visProduksjonsGraf,
		useShortXLabel: isMobile(),
	});

	useEffect(() => {
		if (!hasProduksjonsData) {
			setVisProduksjonsGraf(false);
		}
	}, [hasProduksjonsData]);

	const valueInfoPanels = [
		{
			value: n(kwhTilBesteVerdi(monthForbrukMap[lastMonthLabel] || 0)),
			unit: kwhTilLabel(monthForbrukMap[lastMonthLabel] || 0),
			description: `forbruk i ${format(lastMonth, 'MMMM', { locale: nbLocale }).toLowerCase()}`,
			icon: <i className="fa fa-calendar" />,
		},
		{
			value: n(kwhTilBesteVerdi(monthForbrukMap[currentMonthLabel] || 0)),
			unit: kwhTilLabel(monthForbrukMap[currentMonthLabel] || 0),
			description: `forbruk i ${format(new Date(), 'MMMM', { locale: nbLocale })} så langt`,
			icon: <i className="fa fa-signal" />,
		},
		{
			value: n(kwhTilBesteVerdi(totalThisYear)),
			unit: kwhTilLabel(totalThisYear),
			description: `forbruk så langt i ${new Date().getFullYear()}`,
			icon: <i className="fa fa-chart-pie-alt" />,
		},
	];

	return (
		<GraphTemplate
			title="Forbruk"
			loading={loading}
			primaryInfoPanels={valueInfoPanels}
			filterData={filterData}
			datePickerData={{
				selectedDate: selectedDate,
				onChange: setSelectedDate,
				opploesning: opploesning,
				onChangeOpploesning: setOpploesning,
			}}
			onClickExport={() => {
				eksporter({
					orgid: tenant.id,
					fraDato: datoRange.fra,
					tilDato: datoRange.til,
					opploesning: opploesning,
					maalere: meterPointIds,
					fakturamerker: filter.fakturamerker,
					naeringskoder: filter.naeringskoder,
				});
			}}
			isMissingData={isMissingData}
			missingDataElement={error ? <span>Beklager, det oppstod en teknisk feil ved henting av data</span> : ''}
			chartRef={ref}
			chartData={data as any}
			hasSecondaryGraph={hasProduksjonsData}
			tabSelectData={{
				options: [
					{ label: 'Kjøpt', value: 'forbruk' },
					{ label: 'Solgt', value: 'produksjon' },
				],
				selected: visProduksjonsGraf ? 'produksjon' : 'forbruk',
				onChange: newValue => {
					if (newValue == 'produksjon') {
						setVisProduksjonsGraf(true);
					} else {
						setVisProduksjonsGraf(false);
					}
				},
			}}
		/>
	);
}
