import React, { createContext, ReactNode, useContext, useRef, useState } from 'react';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';

const formContext = createContext(null);

export function useFormError(name: string) {
	const context = useContext(formContext);

	return context && context.errors && context.errors[name];
}

export function useForm(name: string, options?: any) {
	const fieldRef = useRef({ registered: false });
	const context = useContext(formContext);

	if (name && !fieldRef.current.registered) {
		context && context._registerField && context._registerField(name, options);
		fieldRef.current.registered = true;
	}

	return context;
}

export function useFormValue(name: string) {
	const context = useContext(formContext);

	return context?.values?.[name];
}

interface FormProps {
	children?: ReactNode;
	values?: any;
	loading?: boolean;
	errors?: any;
	onSubmit?: (value: any, e?: any) => void;
	disabled?: boolean;
	formRef?: React.MutableRefObject<any>;
}
export default function Form({ children, values, loading, errors, onSubmit, disabled, formRef }: FormProps) {
	const [fieldErrors, setFieldErrors] = useState({});
	const data = useRef({
		fields: {},
	}) as any;

	return (
		<formContext.Provider
			value={{
				_registerField: (name: string, options?: any) => {
					data.current.fields[name] = options || {};
				},
				setFieldError: (error: string, name: string) => {
					setFieldErrors({
						...fieldErrors,
						[name]: error,
					});
				},
				errors: { ...errors, ...fieldErrors },
				values,
				disabled,
			}}>
			<form
				ref={formRef}
				onSubmit={(e: any) => {
					e.preventDefault();
					e.stopPropagation();

					if (disabled) {
						return;
					}

					let fieldNames = Object.keys(data.current.fields);
					let values = {} as any;
					let newFieldErrors = {} as any;
					let hasErrors = false;

					fieldNames?.forEach(name => {
						const option = data.current.fields[name];
						values[name] = e.target[name]?.value;

						let requireError = false;

						if (option.required && !values[name]) {
							newFieldErrors[name] = 'Dette feltet er obligatorisk';
							hasErrors = true;
							requireError = true;
						}

						if (option.validate && !requireError) {
							let error = option.validate(values[name]);

							if (error) {
								newFieldErrors[name] = error;
								hasErrors = true;
							}
						}

						if (option.checkbox) {
							values[name] = e.target[name]?.checked;
						}

						if (option && option.isJson && values[name]) {
							try {
								values[name] = JSON.parse(values[name]);
							} catch (e) {}
						}
					});

					setFieldErrors(newFieldErrors);

					if (hasErrors) return;

					onSubmit && onSubmit(values, e);
				}}>
				<LoadingOverlay loading={loading} text="Laster inn skjema" />
				{children}
			</form>
		</formContext.Provider>
	);
}
