import { ReactNode } from 'react';
import styled from 'styled-components';

interface PushProps {
	className?: string;
	style?: React.CSSProperties;
	height?: string;
	children?: ReactNode;
}

const Push = styled.div<PushProps>`
	display: inline-block;
	width: 100%;
	clear: both;
	height: ${props => props.height || '2rem'};
`;

export default Push;
