// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from 'generated/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeasurementsQueryVariables = Types.Exact<{
  meterId: Types.Scalars['String'];
  from: Types.Scalars['String'];
  to: Types.Scalars['String'];
  iDagStart: Types.Scalars['String'];
  iDagStop: Types.Scalars['String'];
}>;


export type MeasurementsQuery = { measurements: Array<{ time: string, activePowerImport?: number, activePowerExport?: number, currentPhase1?: number }>, reports: Array<{ averageActivePowerImport?: number, activeEnergyImported?: number, averageActivePowerExport?: number, activeEnergyExported?: number, to: string, from: string }> };

export type RealtimeMeasurementSubscriptionVariables = Types.Exact<{
  deviceId?: Types.InputMaybe<Types.Scalars['String']>;
  meterId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type RealtimeMeasurementSubscription = { realtimeMeasurement?: { time: string, activePowerImport?: number, activePowerExport?: number, currentPhase1?: number } };


export const MeasurementsDocument = gql`
    query measurements($meterId: String!, $from: String!, $to: String!, $iDagStart: String!, $iDagStop: String!) {
  measurements(from: $from, to: $to, meterId: $meterId) {
    time
    activePowerImport
    activePowerExport
    currentPhase1
  }
  reports(interval: DAILY, meterId: $meterId, from: $iDagStart, to: $iDagStop) {
    averageActivePowerImport
    activeEnergyImported
    averageActivePowerExport
    activeEnergyExported
    to
    from
  }
}
    `;

/**
 * __useMeasurementsQuery__
 *
 * To run a query within a React component, call `useMeasurementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeasurementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeasurementsQuery({
 *   variables: {
 *      meterId: // value for 'meterId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      iDagStart: // value for 'iDagStart'
 *      iDagStop: // value for 'iDagStop'
 *   },
 * });
 */
export function useMeasurementsQuery(baseOptions: Apollo.QueryHookOptions<MeasurementsQuery, MeasurementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeasurementsQuery, MeasurementsQueryVariables>(MeasurementsDocument, options);
      }
export function useMeasurementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeasurementsQuery, MeasurementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeasurementsQuery, MeasurementsQueryVariables>(MeasurementsDocument, options);
        }
export type MeasurementsQueryHookResult = ReturnType<typeof useMeasurementsQuery>;
export type MeasurementsLazyQueryHookResult = ReturnType<typeof useMeasurementsLazyQuery>;
export type MeasurementsQueryResult = Apollo.QueryResult<MeasurementsQuery, MeasurementsQueryVariables>;
export const RealtimeMeasurementDocument = gql`
    subscription realtimeMeasurement($deviceId: String, $meterId: String) {
  realtimeMeasurement(deviceId: $deviceId, meterId: $meterId) {
    time
    activePowerImport
    activePowerExport
    currentPhase1
  }
}
    `;

/**
 * __useRealtimeMeasurementSubscription__
 *
 * To run a query within a React component, call `useRealtimeMeasurementSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRealtimeMeasurementSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRealtimeMeasurementSubscription({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      meterId: // value for 'meterId'
 *   },
 * });
 */
export function useRealtimeMeasurementSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RealtimeMeasurementSubscription, RealtimeMeasurementSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RealtimeMeasurementSubscription, RealtimeMeasurementSubscriptionVariables>(RealtimeMeasurementDocument, options);
      }
export type RealtimeMeasurementSubscriptionHookResult = ReturnType<typeof useRealtimeMeasurementSubscription>;
export type RealtimeMeasurementSubscriptionResult = Apollo.SubscriptionResult<RealtimeMeasurementSubscription>;