import { ApolloError } from '@apollo/client';
import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Route } from 'react-router-dom';
import LoadingOverlay from 'web/components/molecules/LoadingOverlay';
import { useTenant } from 'web/hooks/useTenant';
import ErrorTemplate from 'web/templates/ErrorTemplate';

// Contains info about the current user, and if is authenticated
export const authContext = createContext({
	user: null,
	refetchTilganger: () => { },
	harkToken: null,
	tilganger: null,
	tilgangerLoading: false,
	tilgangerError: null,
});

// Wrapper for getting the authContext
export function useAuthorization() {
	return useContext(authContext);
}

function getMessage(error: ApolloError) {
	if (!!error.networkError) {
		return 'Nettverksfeil eller brukeren har ikke tilstrekkelige tilgangsrettigheter';
	} else {
		return `${error?.toString()}`.replace('Error: ', '');
	}
}

interface AuthorizedRouteProps {
	path: string;
	exact?: boolean;
	resource?: string;
	group?: string;
	ignorerTilgangsfeil?: boolean;
	children?: any;
}
export function AuthorizedRoute({ resource, group, children, ignorerTilgangsfeil, ...props }: AuthorizedRouteProps) {
	const { t } = useTranslation('generic');
	const { tilganger, tilgangerLoading, tilgangerError } = useAuthorization();
	const access = useIsAuthorized({ resource, group });
	const hasTilganger = tilganger?.length > 0 || access;

	if (ignorerTilgangsfeil) {
		return (
			<Route {...props}>
				{access && children}
				{!access && <ErrorTemplate title={t('MineBedrifter.error.title')} messageHtml={t('MineBedrifter.error.message')} hideBackButton />}
			</Route>
		);
	}

	return (
		<Route {...props}>
			{tilgangerLoading && <LoadingOverlay loading={tilgangerLoading} text={`Henter dine data`} isTemplate />}
			{!tilgangerLoading && tilgangerError && <ErrorTemplate title="Teknisk feil" message={getMessage(tilgangerError)} />}
			{!tilgangerLoading && !tilgangerError && !hasTilganger && (
				<ErrorTemplate title={t('MineBedrifter.error.title')} messageHtml={t('MineBedrifter.error.message')} hideBackButton />
			)}
			{!tilgangerLoading && !tilgangerError && hasTilganger && children}
		</Route>
	);
}

interface AdminRouteProps {
	path: string;
	exact?: boolean;
	children?: any;
}
export function AdminRoute({ children, ...props }: AdminRouteProps) {
	const { t } = useTranslation('generic');
	const { tilgangerLoading, tilgangerError } = useAuthorization();

	const isAdmin = useIsAdmin();

	if (!isAdmin) {
		return (
			<Route {...props}>
				<ErrorTemplate title="Ikke tilgang" message="Du har ikke tilgang til denne siden" />
			</Route>
		);
	}

	return (
		<Route {...props}>
			{tilgangerLoading && <LoadingOverlay loading={tilgangerLoading} text={`Henter dine data`} isTemplate />}
			{!tilgangerLoading && tilgangerError && <ErrorTemplate title="Teknisk feil" message={getMessage(tilgangerError)} />}
			{!tilgangerLoading && !tilgangerError && (
				<ErrorTemplate title={t('MineBedrifter.error.title')} messageHtml={t('MineBedrifter.error.message')} hideBackButton />
			)}
			{!tilgangerLoading && !tilgangerError && children}
		</Route>
	);
}

interface useIsAuthorizedProps {
	resource?: string;
	group?: string;
}
export function useIsAuthorized({ resource, group }: useIsAuthorizedProps) {
	const { user, tilganger } = useAuthorization();
	const { isAuthenticated } = useAuth();
	const tenant = useTenant();

	const roles = user?.resourceAccess?.['bedriftsportal']?.roles || [];

	if (roles.includes('SYSTEM') || roles.includes('ADMIN')) {
		return true;
	}

	let hasAccess = true;

	if (!isAuthenticated) {
		hasAccess = false;
	}

	if (resource) {
		let resourceArray = resource.split(':');
		let foundResource = user?.resourceAccess?.[resourceArray[0]];

		if (foundResource) {
			if (resourceArray[1]) {
				if (!foundResource.roles) {
					hasAccess = false;
				}

				if (foundResource.roles && foundResource.roles.indexOf(resourceArray[1]) < 0) {
					hasAccess = false;
				}
			}
		} else {
			hasAccess = false;
		}
	}

	if (group) {
		const tenantName = tenant?.navn;
		const groupCheck = tenantName ? `${tenantName}/${group}` : group;

		if (tilganger?.indexOf(groupCheck) < 0) {
			hasAccess = false;
		}
	}

	return hasAccess;
}

// TODO: clean up the logic here, both system and admin may mean system admin
export function useIsAdmin() {
	const { user } = useAuthorization();
	const roles = user?.resourceAccess?.['bedriftsportal']?.roles || [];

	if (roles.includes('SYSTEM') || roles.includes('ADMIN')) {
		return true;
	}

	return false;
}

export function useIsSystem() {
	const { user } = useAuthorization();
	const roles = user?.resourceAccess?.['bedriftsportal']?.roles || [];

	if (roles.includes('SYSTEM')) {
		return true;
	}

	return false;
}

export function useHasGroup({ group = '', defaultTenant }: { group: string; defaultTenant?: any }) {
	const { tilganger } = useAuthorization();
	const tenant = useTenant() || defaultTenant;

	if (group) {
		const tenantName = tenant?.navn;
		const groupCheck = tenantName ? `${tenantName}/${group}` : group;

		if (tilganger?.includes(groupCheck) ?? false) {
			return true;
		}
	}

	return false;
}

interface AutorizedProps {
	group?: string;
	resource?: string;
	children?: any;
}
export function Authorized(props: AutorizedProps) {
	const authorized = useIsAuthorized(props);
	const { children } = props;

	if (!authorized) {
		return null;
	}

	return children;
}

export function UnAuthorized(props: AutorizedProps) {
	const authorized = useIsAuthorized(props);
	const { children } = props;

	if (authorized) {
		return null;
	}

	return children;
}

export function AuthAdmin({ children }: any) {
	const { user } = useAuthorization();
	const roles = user?.resourceAccess?.['bedriftsportal']?.roles || [];

	if (roles.includes('SYSTEM') || roles.includes('ADMIN')) {
		return children;
	}

	return null;
}

export function AuthSystem({ children }: any) {
	const { user } = useAuthorization();
	const roles = user?.resourceAccess?.['bedriftsportal']?.roles || [];

	if (roles.includes('SYSTEM')) {
		return children;
	}

	return null;
}

export function NotAuthAdmin({ children }: any) {
	const { user } = useAuthorization();
	const roles = user?.resourceAccess?.['bedriftsportal']?.roles || [];

	if (roles.includes('SYSTEM') || roles.includes('ADMIN')) {
		return null;
	}

	return children;
}
