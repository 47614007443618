import React from 'react';
import styled from 'styled-components';
import FormLabel from 'web/components/molecules/form/FormLabel';
import { useForm, useFormError } from 'web/hooks/useForm';

const StyledTextArea = styled.textarea`
	color: var(--nte-sort);
	background: var(--white);
	border: var(--nte-border);
	border-radius: 3px;
	padding: 9px 16px;
	box-sizing: border-box;
	font-size: 16px;
	width: 100%;
	resize: vertical;
	min-height: 150px;
	&:disabled {
		background: var(--nte-graa-lys-50);
	}
`;

interface TextAreaFieldProps {
	name: string;
	label?: string;
	placeholder?: string;
	required?: boolean;
	style?: React.CSSProperties;
}
export default function TextAreaField({ name, label, placeholder, required, ...props }: TextAreaFieldProps) {
	const form = useForm(name);
	const error = useFormError(name);

	return (
		<FormLabel label={label} error={error} required={required}>
			<StyledTextArea disabled={form?.disabled} placeholder={placeholder || label} name={name} {...props} />
		</FormLabel>
	);
}
