import { useEffect } from 'react';

interface OnClickOutsideProps {
	ref: React.MutableRefObject<any>;
	buttonRef?: React.MutableRefObject<any>;
	handler: (event: any) => void;
}

export default function useOnClickOutside({ ref, buttonRef, handler }: OnClickOutsideProps) {
	useEffect(() => {
		const listener = (event: any) => {
			if (!ref?.current || ref.current?.contains(event.target)) {
				return;
			}
			if (buttonRef && (!buttonRef?.current || buttonRef.current?.contains(event.target))) {
				return;
			}

			handler(event);
		};

		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);

		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [ref, buttonRef, handler]);
}
