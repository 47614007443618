import React, { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';
import { useForm, useFormError } from 'web/hooks/useForm';

const HiddenInput = styled.input`
	opacity: 0;
	position: absolute;
	left: -9999px;
`;
const Label = styled.label``;

interface CheckMarkProps {
	isChecked: boolean;
}
const CheckMark = styled.button<CheckMarkProps>`
	background: var(--white);
	border: var(--nte-border);
	border-radius: 3px;
	display: inline-block;
	width: 20px;
	height: 20px;
	text-align: center;
	color: var(--white);
	padding: 0;
	${props =>
		props.isChecked &&
		css`
			background: var(--nte-blaa-20);
			border-color: var(--nte-blaa-logo);
			color: var(--nte-blaa-logo);
		`}
`;

const CheckMarkIcon = styled.i`
	font-size: 16px;
`;

const LabelTextWrapper = styled.div`
	width: calc(100% - 40px);
	float: right;
	text-align: left;
	word-break: break-word;
	margin-left: 10px;
`;

const LabelText = styled.span``;

const SubLabelText = styled.span`
	font-size: 14px;
	font-weight: 300;
`;
interface CheckboxFieldProps {
	name: string;
	label: ReactNode;
	subLabel?: string;
	placeholder?: string;
	onChange?: (isChecked: boolean) => void;
	defaultChecked?: boolean;
	checked?: boolean;
}
export default function CheckboxField({
	name,
	label,
	subLabel = '',
	placeholder = '',
	onChange = () => { },
	defaultChecked,
	checked,
	...props
}: CheckboxFieldProps) {
	const form = useForm(name, { checkbox: true });
	const error = useFormError(name);
	const [isChecked, _setIsChecked] = useState(defaultChecked);

	function setIsChecked(isChecked: boolean) {
		_setIsChecked(isChecked);

		onChange && onChange(isChecked);
	}

	let tmpChecked = isChecked;

	if (typeof checked !== 'undefined') {
		tmpChecked = checked;
	}

	return (
		<div {...props}>
			<Label>
				<CheckMark onClick={() => setIsChecked(!tmpChecked)} isChecked={tmpChecked} type="button">
					<CheckMarkIcon className="fal fa-check" />
				</CheckMark>

				<LabelTextWrapper>
					{label && <LabelText>{label}</LabelText>}
					{(subLabel && (
						<>
							<br />
							<SubLabelText>{subLabel}</SubLabelText>
						</>
					)) || <></>}
				</LabelTextWrapper>
			</Label>
			<HiddenInput name={name} checked={tmpChecked} type="checkbox" />
		</div>
	);
}
