import { Bruker } from 'generated/graphql-types';
import React from 'react';
import Button, { ButtonWrapper, SecondaryButton } from 'web/components/atoms/Button';
import ModulChecboxList from 'web/components/molecules/form/ModulCheckboxList';
import Modal from 'web/components/molecules/Modal';

interface EndreTilgangModalProps {
	bruker: Bruker;
	moduler: any[];
	setVisEndreTilgangModal: (visModal: boolean) => void;
	settBrukerModuler?: (id: string, valgteModuler: string[]) => void;
}

export function EndreTilgangModal({ bruker, moduler, setVisEndreTilgangModal, settBrukerModuler }: EndreTilgangModalProps) {
	return (
		<Modal
			title={`Endre tilganger for ${bruker.fornavn} ${bruker.etternavn}`}
			onClose={() => {
				setVisEndreTilgangModal(false);
			}}>
			<form
				onSubmit={(event: any) => {
					event.preventDefault();
					let valgteModuler: string[] = [];
					for (let index = 0; index < event.target.modulCheckbox?.length; index++) {
						const element = event.target.modulCheckbox[index];
						if (element.checked) {
							valgteModuler.push(element.value);
						}
					}
					settBrukerModuler?.(bruker.id, valgteModuler);
					setVisEndreTilgangModal(false);
				}}>
				<ModulChecboxList moduler={moduler} checkedModuler={bruker.moduler} labelStyle={{ fontSize: '1.125rem' }} />

				<div style={{ display: 'flex' }}>
					<ButtonWrapper>
						<Button>
							<i className="far fa-save" />
							Lagre endringer
						</Button>
						<SecondaryButton
							type="button"
							onClick={() => {
								setVisEndreTilgangModal(false);
							}}>
							Avbryt
						</SecondaryButton>
					</ButtonWrapper>
				</div>
			</form>
		</Modal>
	);
}
