import { FetchResult } from '@apollo/client/link/core';
import { MutationResult } from '@apollo/client/react/types/types';
import apolloClient from 'web/lib/apolloClient';
import { AnleggslisteshortFragmentDoc, useOppdaterEgenAnleggsbeskrivelseMutation } from 'web/pages/SingleAnleggPage.graphql-gen';

export declare type oppdaterEgenAnleggsbeskrivelseTuple = [
	(newTitle: string, orgid: string, maalepunktId: string) => Promise<FetchResult<any>>,
	MutationResult<any>
];
export function oppdaterEgenAnleggsbeskrivelseMutation(): oppdaterEgenAnleggsbeskrivelseTuple {
	const [oppdaterEgenAnleggsbeskrivelse, result] = useOppdaterEgenAnleggsbeskrivelseMutation();

	const mutation = (newTitle: string, orgid: string, maalerpunktId: string) =>
		oppdaterEgenAnleggsbeskrivelse({ variables: { egenAnleggsbeskrivelse: newTitle, orgid, maalepunktId: maalerpunktId } }).then(value =>
			oppdaterMaalerCache(newTitle, orgid, maalerpunktId)
		);
	return [mutation, result];
}

function oppdaterMaalerCache(newTitle: string, orgid: string, maalerpunktId: string) {
	return apolloClient.cache.updateFragment(
		{
			id: `Bedrift:${orgid}`,
			fragment: AnleggslisteshortFragmentDoc,
		},
		(bedrift: any) => {
			return {
				...bedrift,
				anlegg: bedrift.anlegg.map((anlegg: any) => {
					if (maalerpunktId === anlegg?.maalerpunktId) {
						return { ...anlegg, egenAnleggsbeskrivelse: newTitle };
					} else {
						return anlegg;
					}
				}),
			};
		}
	);
}
