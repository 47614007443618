import { Anleggsstatus, Innstilling, Maalergruppering } from 'generated/graphql-types';
import { useEffect, useState } from 'react';
import {
	EpostlisteFragmentDoc,
	useHentBrukerprofilQuery,
	useLagreBrukerprofilInnstillingerMutation,
	useLagreKategoriMutation,
	useSlettKategoriMutation,
} from 'web/hooks/useProfile.graphql-gen';
import { useTenant } from 'web/hooks/useTenant';
import apolloClient from 'web/lib/apolloClient';

export const INFOBOX_BANKID_ISCLOSED = 'infoBox_1_bankId_isClosed'; // This one is not in use anymore
export const INFOBOX_AVTALER_ISCLOSED = 'infoBox_2_avtaler_isClosed';
export const INFOBOX_NEW_DESIGN_ISCLOSED = 'infoBox_2_new_design_isClosed'; // This one is not in use anymore
export const INFOBOX_APP_TILGANG_ISCLOSED = 'infoBox_3_app_tilgang_isClosed'; // This one is not in use anymore
export const INFOBOX_NY_NETTLEIE_ISCLOSED = 'infoBox_4_ny_nettleie_isClosed'; // This one is not in use anymore
export const INFOBOX_UC_GOLIVE_ISCLOSED = 'infoBox_5_UC_GoLive_isClosed'; // This one is not in use anymore

export interface Kategori extends Maalergruppering {
	alleAnlegg?: number;
	aktiveAnlegg?: number;
	malerpunkter?: string[];
}

export default function useProfile() {
	const tenant = useTenant();
	const {
		data: { brukerprofil } = { brukerprofil: null },
		loading: queryLoading,
		error: queryError,
	} = useHentBrukerprofilQuery({
		skip: !tenant?.orgnummer,
		variables: {
			orgnummer: tenant?.orgnummer,
		},
	});

	const [lagreBrukerInnstilling, { loading: loadingLagreInnstilling }] = useLagreBrukerprofilInnstillingerMutation();

	const [lagreMaalerKategori, { loading: lagreLoading }] = useLagreKategoriMutation({
		// update: (cache, { data }) => {
		// 	cache.writeQuery({ query: HENT_BRUKERPROFIL, data });
		// },
	});
	const [slettMaalerKategori, { loading: slettLoading }] = useSlettKategoriMutation({
		// update: (cache, { data }) => {
		// 	cache.writeQuery({ query: HENT_BRUKERPROFIL, data });
		// },
	});

	async function lagreInnstilling(nyInnstilling: Innstilling) {
		const eksisterendeInnstillinger =
			brukerprofil?.innstillinger
				?.map((innstilling: Innstilling) => {
					return { navn: innstilling.navn, verdi: innstilling.verdi };
				})
				.filter((i: Innstilling) => i.navn !== nyInnstilling.navn) || [];

		return lagreBrukerInnstilling({
			variables: {
				innstillinger: [...eksisterendeInnstillinger, nyInnstilling],
			},
		}).catch(e => console.error('Error saving settings', e));
	}

	function leggTilValidertEpost(epost: string) {
		apolloClient.cache.updateFragment(
			{
				id: `Brukerprofil:${brukerprofil.id}`,
				fragment: EpostlisteFragmentDoc,
				overwrite: true,
			},
			brukerprofil => {
				return {
					...brukerprofil,
					validerteEposter: brukerprofil.validerteEposter.concat(epost),
				};
			}
		);
	}

	const kategorier =
		brukerprofil?.kategorier
			?.map((kategori: Kategori) => {
				if (!kategori.alleAnlegg) {
					kategori = { ...kategori, alleAnlegg: 0 };
				}
				if (!kategori.aktiveAnlegg) {
					kategori = { ...kategori, aktiveAnlegg: 0 };
				}
				return kategori;
			})
			?.filter((kategori: Kategori) => kategori.orgnummer == tenant?.orgnummer)
			.sort((a: Kategori, b: Kategori) => {
				return a.navn.localeCompare(b.navn, undefined, {
					numeric: true,
				});
			}) ?? [];

	let anlegg = tenant?.anlegg || [];

	function getKategorierOptions() {
		anlegg?.forEach(a => {
			kategorier?.forEach((kategori: Kategori) => {
				kategori.maalerpunkter?.forEach(maalepunkt => {
					if (a?.maalerpunktId == maalepunkt) {
						kategori.alleAnlegg += 1;
						if (a.anleggsstatus != Anleggsstatus.OPPHOERT) {
							kategori.aktiveAnlegg += 1;
						}
					}
				});
			});
		});

		const options = kategorier.map((kategori: Kategori) => {
			let subLabel = '';
			if (kategori?.aktiveAnlegg > 0) {
				subLabel = kategori?.aktiveAnlegg == 0 ? 'Ingen aktive' : `${kategori.aktiveAnlegg} aktiv${kategori?.aktiveAnlegg == 1 ? 't' : 'e'}`;

				subLabel += kategori?.aktiveAnlegg < kategori.alleAnlegg ? ` av totalt ${kategori.alleAnlegg} anlegg` : ' anlegg';
			}

			return {
				value: kategori.id,
				label: kategori.navn,
				subLabel: subLabel,
			};
		});
		return options;
	}

	const [kategorierOptions, setKategorierOptions] = useState<any>();
	useEffect(() => {
		if (anlegg?.length > 0 && !kategorierOptions && kategorier?.length > 0) {
			setKategorierOptions(getKategorierOptions());
		}
	}, [anlegg, kategorier]);

	function getValgteKategorier(filterKategorier: string[]) {
		return kategorier.filter((kategori: Kategori) => filterKategorier.indexOf(kategori.id) > -1);
	}

	function lagreKategori(nyKategori: any, maalerId: string) {
		const kategoriExists = kategorier.find((k: Kategori) => {
			return nyKategori.navn == k.navn;
		});
		if (kategoriExists) {
			const kategoriIsAlreadyAddedToMaaler = kategoriExists.maalerpunkter.indexOf(maalerId) > -1;
			if (kategoriIsAlreadyAddedToMaaler) {
				return;
			} else {
				addKategoriTilMaaler({ kategoriId: kategoriExists.id, maalerId: maalerId });
			}
		} else {
			lagreMaalerKategori({ variables: { maalerkategori: { ...nyKategori, orgnummer: tenant?.orgnummer } } });
		}
	}

	function addKategoriTilMaaler({ kategoriId, maalerId }: { kategoriId: string; maalerId: string }) {
		const kategori = kategorier.find((kategori: Kategori) => kategori.id == kategoriId);

		const maalerkategori = {
			beskrivelse: kategori.beskrivelse,
			id: kategori.id,
			maalerpunkter: [...kategori.maalerpunkter, maalerId],
			navn: kategori.navn,
			opprettetAv: kategori.opprettetAv,
			orgnummer: kategori.orgnummer,
		};

		lagreMaalerKategori({
			variables: {
				maalerkategori: maalerkategori,
			},
		});
	}

	function slettKategoriFraMaaler({ kategoriId, maalerId }: { kategoriId: string; maalerId: string }) {
		const kategori = kategorier.find((kategori: Kategori) => kategori.id == kategoriId);
		const maalerpunkter = kategori.maalerpunkter.filter((m: string) => m !== maalerId);

		if (maalerpunkter?.length === 0) {
			return slettKategori(kategoriId);
		}

		const maalerkategori = {
			beskrivelse: kategori.beskrivelse,
			id: kategori.id,
			maalerpunkter,
			navn: kategori.navn,
			opprettetAv: kategori.opprettetAv,
			orgnummer: kategori.orgnummer,
		};

		lagreMaalerKategori({
			variables: {
				maalerkategori,
			},
		});
	}

	function slettKategori(kategoriId: string) {
		slettMaalerKategori({ variables: { id: kategoriId, orgnummer: tenant?.orgnummer } });
	}

	return {
		brukerprofil,
		lagreLoading,
		slettLoading,
		queryLoading,
		anyLoading: lagreLoading || slettLoading || queryLoading,
		kategorier,
		kategorierOptions,
		getValgteKategorier,
		lagreKategori,
		slettKategori,
		addKategoriTilMaaler,
		slettKategoriFraMaaler,
		lagreInnstilling,
		leggTilValidertEpost,
	};
}
