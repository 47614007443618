import { endOfYear, format, startOfYear } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDashboardLayoutScrollElementRef } from 'web/components/organisms/DashboardLayout';
import useAnlegg from 'web/hooks/useAnlegg';
import useFakturaer from 'web/hooks/useFakturaer';
import useFilter from 'web/hooks/useFilter';
import { SegmentCategory, useTracking } from 'web/hooks/useTracking';
import { Opploesninger } from 'web/lib/dates.utils';
import useFakturaEksport from 'web/lib/exports/useFakturaeksport';
import { getFakturaFilterData } from 'web/lib/filter.utils';
import FakturaTemplate from 'web/templates/FakturaTemplate';

export default function FakturaPage() {
	const { trackPageView } = useTracking();
	trackPageView(SegmentCategory.Faktura);
	const { eksporter } = useFakturaEksport();
	const PER_PAGE = Math.ceil(window.innerHeight / 50 + 10);
	const currentPage = useRef(1);
	const lastScrollTop = useRef(0);
	const { bedriftId } = useParams() as any;
	const layoutScrollViewRef = useDashboardLayoutScrollElementRef();
	const [displayScrollToTopButton, setDisplayScrollToTopButton] = useState(false);
	const [filter, _setFilter] = useFilter();

	function setFilter(newFilter: any) {
		currentPage.current = 1;
		_setFilter(newFilter);
	}

	const filterData = getFakturaFilterData(filter, setFilter);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [isLoadingFetchMore, setIsLoadingFetchMore] = useState(false);

	const fraDato = format(startOfYear(new Date(selectedDate)), 'yyyy-MM-dd', { locale: nbLocale });
	const tilDato = format(endOfYear(new Date(selectedDate)), 'yyyy-MM-dd', { locale: nbLocale });

	const { meterPointIds } = useAnlegg({ ...filter, visOpphoert: true });

	const { fakturaer, loading, fetchMoreFakturaer, antallSider } = useFakturaer({
		orgid: bedriftId,
		maalere: meterPointIds,
		fraDato,
		tilDato,
		filter: filter.fakturaFilter,
		antall: PER_PAGE,
	});

	useEffect(() => {
		const lastFlereFakturaer = async () => {
			if (loading || currentPage.current >= antallSider) return;

			setIsLoadingFetchMore(true);
			currentPage.current++;
			await fetchMoreFakturaer({
				variables: {
					side: currentPage.current,
				},
			}).then(() => {
				setIsLoadingFetchMore(false);
			});
		};
		const handleScroll = (e: any) => {
			const BUFFER = 200;
			const scrollElement = e.target as HTMLDivElement;
			if (scrollElement.scrollTop === 0) {
				setDisplayScrollToTopButton(false);
				lastScrollTop.current = 0;
			}

			if (lastScrollTop.current > scrollElement.scrollTop && !displayScrollToTopButton) {
				setDisplayScrollToTopButton(true);
			} else if (lastScrollTop.current < scrollElement.scrollTop && displayScrollToTopButton) {
				setDisplayScrollToTopButton(false);
			}

			if (scrollElement.scrollTop > scrollElement.scrollHeight - scrollElement.clientHeight - BUFFER) {
				lastFlereFakturaer();
			}
			lastScrollTop.current = scrollElement.scrollTop;
		};
		layoutScrollViewRef.current.addEventListener('scroll', handleScroll);

		return () => {
			layoutScrollViewRef.current?.removeEventListener('scroll', handleScroll);
		};
	}, [layoutScrollViewRef, antallSider, fetchMoreFakturaer]);

	return (
		<FakturaTemplate
			loading={loading}
			loadingFetchMore={isLoadingFetchMore}
			fakturaer={fakturaer}
			filterData={filterData}
			datePickerData={{
				selectedDate: selectedDate,
				onChange: setSelectedDate,
				opploesning: Opploesninger.maned,
			}}
			onClickGoToTop={() => {
				layoutScrollViewRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
			}}
			displayScrollToTopButton={displayScrollToTopButton}
			onClickExport={() => {
				eksporter({
					orgid: bedriftId,
					fraDato,
					tilDato,
					maalere: meterPointIds,
					naeringskoder: filter.naeringskoder,
					fakturamerker: filter.fakturamerker,
					filter: filter.fakturaFilter,
				});
			}}
		/>
	);
}
