import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AnleggBadge } from 'web/components/atoms/Badge';
import { ArrowIcon } from 'web/components/molecules/FakturaListElement';
import { Anlegg } from 'web/models/Anlegg';

const AnleggListElementWrapper = styled(Link)`
	width: 100%;
	display: flex;
	align-content: center;
	align-items: center;
	padding: 20px 16px;
	border: 1px solid var(--nte-graa-lys);
	border-radius: var(--border-radius);
	box-shadow: var(--clickable-box-shadow);
	text-decoration: none;
	color: inherit;
	&:hover,
	&:active,
	&:focus {
		box-shadow: none;
		text-decoration: none;
		label {
			background: var(--nte-blaa-10);
		}
	}
`;
const Title = styled.span`
	display: flex;
	font-size: 1rem;
	color: var(--nte-sort);
	font-weight: bold;
`;

const Status = styled.div`
	display: flex;
	font-size: 0.75rem;
`;

const TextWrapper = styled.div`
	min-width: calc(100% - 84px);
	flex-grow: 1;
`;

const StatusList = styled.ul`
	list-style-type: none;
	color: var(--nte-graa-medium);
	padding: 0;
	margin: 0;
	padding: 4px 0 0 0;
	li {
		overflow: visible;
		white-space: nowrap;
		padding-bottom: 3px;
	}
`;

const ArrowWrapper = styled.span`
	font-weight: bold;
	font-size: 0.875rem;
	line-height: 1rem;
	text-align: right;
	color: var(--nte-sort);
	min-width: 24px;
`;

const StyledArrowIcon = styled(ArrowIcon)`
	margin: 0;
`;

const BadgeWrapper = styled.div`
	position: relative;
	min-width: 60px;
	align-self: start;
`;

export interface AnleggListElementProps {
	to: string;
	anlegg: Anlegg;
}

export default function AnleggListElement({ to, anlegg }: AnleggListElementProps) {
	return (
		<AnleggListElementWrapper to={to}>
			<TextWrapper>
				<Title>{anlegg.egenAnleggsbeskrivelse || anlegg.tittel}</Title>
				<Status>
					<StatusList>
						<li>Målernummer: {anlegg.maalernummer}</li>
						<li>Estimert årsforbruk: {anlegg.aarsforbruk} kWh</li>
					</StatusList>
				</Status>
			</TextWrapper>
			<BadgeWrapper>
				<AnleggBadge status={anlegg.anleggsstatus} />
			</BadgeWrapper>
			<ArrowWrapper>
				<StyledArrowIcon>
					<i className="fal fa-chevron-right" />
				</StyledArrowIcon>
			</ArrowWrapper>
		</AnleggListElementWrapper>
	);
}
